<div class="dashboard-wrapper" style="overflow-x:auto">
    <div class="v-card">
        <div class="workflow-background" *ngIf="showWorkflow"></div>

        <div class="user-workflow-component" [ngClass]="{'user-workflow-on-mobile' : isMobile}"  *ngIf="showWorkflow">
            <div class="workflow-box" cdkDrag  handleResize  [(height)]="boxHeight" [(width)]="boxWidth" 
            [style.width.px]="boxWidth" [style.height.px]="boxHeight" [maxWidth]="700" [maxHeight]="700" [minWidth]="400" [minHeight]="300">
                <div cdkDragHandle style="cursor: move;border-bottom: solid 1px #eeeeee;
                padding-bottom: 5px;">
                   
                    <div class="d-flex align-items-center">
                        <div class="col-2 h-100 text-start ps-3">
                            <img src="assets/img/amblem.svg" alt="" style="height: 26px;">
                        </div>
                        <div class="col-8 text-center">
                            <label class="cursor-move text-grey" *ngIf="wfName">{{wfName}}</label>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-end">
                            <div *ngIf="isStepFileAvailable && isStart" class="me-2">
                                <img src="assets/img/step-files-icon.svg" alt="" class="cursor-pointer" (click)="showStepFiles()">
                            </div>
                            <div class="me-2" [ngStyle]="{'pointer-events': isStart ? '' : 'none'}" *ngIf="workflow.showContentPage || isEwers || (workflow.expertsToCall?.length > 0)">
                                <img src="assets/img/list-content.svg" class="cursor-pointer" alt="" height="13px" (click)="switchWorkflowMenu()">
                            </div>
                            <div class="close text-grey" (click)="close()">
                                <img src="assets/img/close-workflow.svg" height="14px">
                            </div>
                        </div>
                    </div>
                </div>
                <app-user-workflow style="display:block; height:100%; width: 100%;margin-top: 20px;min-width: 200px;min-height:200px;"
                    [workflow]="workflow"
                    (workflowSubmitted)= "workflowSubmitted($event)"
                    [(savedDraft)]= "savedDraft" 
                    (savedDraftChage)= "savedDraftChage($event)" 
                    [results]= "results" 
                    [report]= "report" 
                    [reportId]= "reportId"
                    (isWorkflowStart)="isWorkflowStart($event)"
                    (langChanged)="langChanged($event)"
                    [(workflowMenuClicked)]="workflowMenuClicked"
                    (workflowMenuClickedChange)= "workflowMenuClickedChange($event)"
                    [(stepFilesClicked)]="stepFilesClicked"
                    (isStepFileAvailable)="stepFileAvailability($event)"
                ></app-user-workflow>
                <div class="handler"></div>
            </div>
        </div>

        <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="d-flex">
                <div class="header fs-20 font-weight-700  pt-1 me-2">{{ 'MAIN.CONTENT.DASHBOARD.TITLE' | translate }}</div>
                <div *ngIf="('electron' | env)">
                    <button (click)="exportPublishedWorkflows()" class="v-btn btn btn-primary fs-12 font-weight-600 text-white" style="border-radius:7px; height: 30px;padding-top:5px">{{ 'MAIN.CONTENT.DASHBOARD.EXPORT-WORKFLOWS' | translate }}</button>
                </div>
            </div>
            <div><button class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" (click)="selectContentLanguage()">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.CONTENT-LANGUAGE' | translate | titlecase }}</button></div>
        </div>

        <div class="card tab-area d-flex flex-row first-area">
            <div class="tab-item right fs-16 font-weight-600 ms-4 selected-tab">
                <span class="tab-title">My workflows</span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" *ngIf="isAdmin || hasEditorAccess" (click)="onAllWorkflows()">
                <span class="tab-title">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.MANAGE-WORKFLOWS' | translate }}</span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" (click)="onSubmittedWorkflows()">
                <span class="tab-title">Submitted workflows</span>
            </div>
        </div>

        <div class="card list-area">
            <app-dashboard-filter [allLabels]="allLabels" (onFilter)="onFilterChange($event)"></app-dashboard-filter>

            <div class="user-table-header d-flex align-items-center">
                <div class="col-4 fs-14 d-flex align-items-center cursor-pointer fs-14 text-dark-grey" style="padding-left: 20px;" (click)="sortByName()">
                    {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.NAME' | translate }}

                    <img src="assets/img/down-icon-grey.svg" *ngIf="order == 'asc'" alt="" class="folder-icon">
                    <img src="assets/img/down-icon-grey.svg" *ngIf="order == 'desc'" style="transform:rotate(180deg)" alt="" class="folder-icon">
                    <div class="p-relative ms-1 cursor-pointer" style="height:24px;" *ngIf="!order" (click)="sortByName()">
                        <img src="assets/img/down-icon-grey.svg" style="transform:rotate(180deg)" alt="" class="sort-icon up">
                        <img src="assets/img/down-icon-grey.svg" alt="" class="sort-icon down">
                    </div>
                </div>
                <div class="col-2 fs-14 text-dark-grey">Version</div>
                <div class="col-3 fs-14 text-dark-grey d-flex align-items-center cursor-pointer" (click)="sortByPublishDate()">
                    {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.PUBLISHED-AT' | translate }}
                    <img src="assets/img/down-icon-grey.svg" *ngIf="publishDateOrder == 'asc'"  alt="" class="folder-icon">
                    <img src="assets/img/down-icon-grey.svg" *ngIf="publishDateOrder == 'desc'" style="transform:rotate(180deg)" alt="" class="folder-icon">
                    <div class="p-relative ms-1 cursor-pointer" style="height:24px;" *ngIf="!publishDateOrder" (click)="sortByName()">
                        <img src="assets/img/down-icon-grey.svg" style="transform:rotate(180deg)" alt="" class="sort-icon up">
                        <img src="assets/img/down-icon-grey.svg" alt="" class="sort-icon down">
                    </div>
                </div>
                <div class="col-3 fs-14 text-dark-grey">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.LANGUAGES' | translate }}</div>
            </div>
            <div class="user-table-body">
                <div *ngIf="!folders || !folders.length || !workflowList || !workflowList?.workflowList?.length" class="empty-list fs-14 text-grey">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.NO-WORKFLOW-FOUND' | translate }}</div>

                <div *ngFor="let folder of filteredWorkflowList['folders']">
                    <div  class="folder-card fs-15 font-weight-700 ps-3 d-flex align-items-center cursor-pointer" (click)="changeFolderStatus(folder)" *ngIf="folders?.length && folders?.length > 1">
                        <img *ngIf="folder && folder['openStatus']" src="assets/img/down-icon-black.svg" style="transform:rotate(360deg)" alt="" class="folder-icon">
                        <img *ngIf="folder && !folder['openStatus']" src="assets/img/down-icon-black.svg"  style="transform: rotate(270deg)" alt="" class="folder-icon">
                        {{folder.name}}</div>
                    
                    <div class="workflow-table-body" *ngIf="folder.workflowList && (folder['openStatus'] ||  folders?.length == 1)">
                        <div *ngFor="let workflow of folder.workflowList" class="workflow-card d-flex align-items-center">
                            <div class="col-4 fs-15 font-weight-700" [ngStyle]="{'padding-left': folders?.length == 1 ? '20px' : '40px'}">{{workflow.listName}}</div>
                            <div class="col-2 fs-14">v{{workflow.version}}</div>
                            <div class="col-3 fs-14 font-weight-400" title="{{workflow.publishedAt | localizedDate | async}}">{{ workflow.publishedAt | localizedDateShort | async}}</div>
                            <div class="col-1 fs-14 font-weight-400">
                                <ng-container  *ngIf="workflow.translations">
                                    <span *ngFor="let code of workflow.translations | keyvalue;let index = index">
                                        <span *ngIf="code?.key && index<4">{{$any(code.key) | uppercase}}<span *ngIf="index !== (workflow.translations | keyvalue).length-1 && index<3">,</span>
                                    </span>
                                    </span>
                                    <span *ngIf="(workflow.translations | keyvalue).length>=4">...</span>
                                </ng-container>
                               
                            </div>
                            <div class="col-2 d-flex justify-content-end align-items-center" style="padding-right: 30px;">
                                <button class="v-btn btn btn-secondary-outline fs-16 font-weight-600" (click)="selectSingleWorkflow(workflow)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.START' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
