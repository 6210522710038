import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-open-ai-modal',
  templateUrl: './open-ai-modal.component.html',
  styleUrls: ['./open-ai-modal.component.scss']
})
export class OpenAiModalComponent implements OnInit {

  onClose: EventEmitter<any> = new EventEmitter();

  promptText = new UntypedFormControl('', Validators.required)

  prompt: string

  isLoading = false

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.promptText.valueChanges.subscribe(res => {
      this.prompt = res
    })
  }

  close(): void {
    this.modalRef.hide();
  }
  confirm(result: any): void {
    if (result) {
      this.isLoading = true
      this.onClose.next(true);
    } else {
      this.onClose.next(false);
      this.modalRef.hide();
    }
  }

}
