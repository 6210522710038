import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-textarea',
  template: `
  <div style="position: absolute;top:0;bottom:0;left:0;right:0;padding: 10px;" [ngClass]="{'image-with-label': to.label}" >
      <img src="assets/img/pdf-preview.svg" class="form-image" *ngIf="to['pdfUrl']">
  </div>
  `,
})
export class FormlyFieldCustomPdf extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}