import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-archive-permission-modal',
  templateUrl: './archive-permission-modal.component.html',
  styleUrls: ['./archive-permission-modal.component.scss']
})
export class ArchivePermissionModalComponent {

  onClose: EventEmitter<any> = new EventEmitter();

  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
  }

  deny() {
    this.close()
  }

  allow() {
    this.onClose.emit(true)
    this.close()
  }

}
