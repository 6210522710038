import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-guest-access',
  templateUrl: './guest-access.component.html',
  styleUrls: ['./guest-access.component.scss']
})
export class GuestAccessComponent implements OnInit {

  @Input() workflowId
  @Input() workflow
  @Output() easyAccessUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  isQrAvailable: boolean = false;
  qrData: string = "";
  qrCodeDownloadLink: SafeUrl = "";
  guestLink = new UntypedFormControl()

  passwordEnabled = new UntypedFormControl()
  password = new UntypedFormControl({ value: '', disabled: true })

  passwordRequired

  constructor(
    private workflowService: WorkflowService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    if (this.workflow.publicAccess) {
      this.isQrAvailable = true
      this.qrData = this.workflow.publicAccess.qr ? this.workflow.publicAccess.qr : ''
      this.passwordRequired = this.workflow.publicAccess.passwordRequired
      this.patchGuestLink()

    }
    this.passwordEnabled.valueChanges.subscribe(res => {
      if (res) {
        this.password.enable()
      } else {
        this.password.disable()
      }
    })
  }

  patchGuestLink(link?: string) {
    let baseUrl = environment.endPoints.workflowBase
    const isVideoFlow = (this.workflow.draft && this.workflow.draft.isVideoFlow) || (this.workflow.published && this.workflow.published.isVideoFlow)
    const workflowguestLink = `${baseUrl}/${isVideoFlow ? 'videoflow?' : 'share?'}`
    if (this.workflow.publicAccess?.linkId) {
      let workflowLink = this.workflow.publicAccess.linkId
      const obj = { id: workflowLink }
      
      const publicLink = `${workflowguestLink}` + new URLSearchParams(obj).toString()
      this.guestLink.patchValue(publicLink)
    } else {
      const obj = { id: link }
      const publicLink = `${workflowguestLink}` + new URLSearchParams(obj).toString()
      this.guestLink.patchValue(publicLink)
    }
  }

  onGenerateQR() {
    this.loaderService.show();
    let password = this.passwordEnabled.value ? this.password.value : ''
    this.passwordRequired = this.passwordEnabled.value

    this.workflowService.generateWorkflowQr(this.workflowId, environment.endPoints.workflowBase, password)
    .then((res) => {
      if (res) {
        this.qrData = res.data?.qr
        this.isQrAvailable = true;
        this.patchGuestLink(res.data.linkId)
      }
    })
    .catch(error => {
      if(error.error.message == 'permission-denied') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.EASY-ACCESS.GUEST-ACCESS.TOKEN-MISSING'))
      }
      if(error.error.message == 'missing-parameters') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.EASY-ACCESS.GUEST-ACCESS.ID-MISSING')) 
      }
      if(error.error.message == 'workflow-not-found') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.WORKFLOW-NOT-FOUND'))
      }
      if(error.error.message == 'workflow-not-published') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.EASY-ACCESS.GUEST-ACCESS.WORKFLOW-NOT-PUBLISHED'))
      }
      if(error.error.message == 'public-access-already-exists') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.EASY-ACCESS.GUEST-ACCESS.PUBLIC-ACCESS'))
      }
      if(error.error.message == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.easyAccessUpdated.emit(true)
      this.loaderService.hide();
    })
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  onDeleteQR() {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE-QR-OR-NOT'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.loaderService.show();
        this.workflowService.removeWorkflowQR(this.workflowId)
        .then(res => {
          this.isQrAvailable = false;
          this.guestLink.patchValue(null)
          this.password.patchValue(null)
          this.qrData = ''
          this.passwordRequired = false
          this.qrCodeDownloadLink = null
          this.workflow.publicAccess = null
          this.passwordEnabled.patchValue(false)
        })
        .catch(error => {
          if(error.error.message == 'permission-denied') {
            this.snackBarService.error(this.translateService.instant('LOGIN.ID-OR-NAME-MISSING'))
          }
          if(error.error.message == 'workflow-not-found') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-NOT-FOUND'))
          }
          if(error.error.message == 'no-public-access') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.EASY-ACCESS.GUEST-ACCESS.NO-PUBLIC-ACCESS'))
          }
          if(error.error.message == 'internal-error') {
            this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
          }
        })
        .finally(() => {
        this.easyAccessUpdated.emit(true)
        this.loaderService.hide()
        })
      } else {
        alertmodalRef.hide();
      }
    })
  }

  copyGuestLinkToClipboard(input) {
    input.select()
    document.execCommand('copy');
  }

  downloadFile(pdfUrl: string){
    this.http.get(pdfUrl, { responseType: 'blob' }).subscribe(
      (blob) => saveAs(blob, 'qr-code.png'))
  }

}
