import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {

  approverEmail = new UntypedFormControl('', Validators.required)
  approverEditEmail = new UntypedFormControl('', Validators.required)

  approvers = []

  subscriptions: Subscription[] = []
  editNameMode = false;
  selectedStepIndex

  constructor(private modalService: BsModalService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.approvers.push('approver@vsight.io', 'approver2@vsight.io', 'approver3@vsight.io', 'approver4@vsight.io')
  }

  addNewApprover() {
    this.approvers.push(this.approverEmail.value)
  }

  deleteApprover(index) {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.REPORT.APPROVAL.DELETE-APPROVAL'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.REPORT.APPROVAL.DELETE'),
      confirmButtonStyle: "danger"
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe(res => {
      if(res == true) {
        this.approvers.splice(index, 1)
      }
    }))
  }

  edit(index, approver):void {
    this.selectedStepIndex = index
    this.approverEditEmail.patchValue(approver)
   
  }
  update(approver,index) {
    this.approvers[index] = this.approverEditEmail.value
    this.selectedStepIndex = null
  }

}
