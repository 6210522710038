<!-- <div class="step-files-header fs-14 font-weight-600 ps-2 mt-2">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-FILES.TITLE' | translate }}</div>
<div class="d-flex flex-column align-items-center">
    <div class="mt-2">
        <input type="file" multiple class="d-none" accept="video/*,image/*,application/pdf,audio/*" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput>
        <div class="upload-drop-zone" filedrop fileType="image" (filesDropped)="dragDropUpload($event)" accept="video/*,image/*,application/pdf,audio/*" id="drop-zone">
            <div class="upload-icon mt-1"><img src="assets/img/upload.svg"></div>
            <div class="fs-14 font-weight-500 top-gap-16">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
            <div class="fs-12 text-muted">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
            <ng-container *ngIf="!('electron' | env)">
                <div class="upload-button mb-0">
                    <button class="select-button v-btn btn btn-primary fs-12 font-weight-500 top-gap-12 mb-2 text-white" style="border-radius: 10px;height: 40px;" (click)="imageInput.click()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}</button>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="selectedStepFiles?.length" class="w-100 mt-3">
        <div *ngFor="let file of selectedStepFiles; let index = index" class="fs-12 font-weight-500 mb-1">
            <div class="d-flex justify-content-between mt-2">
                <div class="col-9 d-flex align-items-center me-1">
                    <span class="ms-2 long-text-settings fs-13">{{file.name}}</span>
                </div>
                <div class="col-3 d-flex">
                    <button class="gallery-buttons cursor-pointer" (click)="downloadStepFile(file)" [disabled]="isDownloading">
                        <img src="assets/img/download-step-file-white.svg" class="mb-1" alt="">
                    </button>
                    <button class="gallery-buttons cursor-pointer ms-2" (click)="deleteStepFile(index)">
                        <img src="assets/img/delete-image.svg" class="mb-1" alt="">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div style="padding-inline: 10px;padding-top:25px;">
    <label class="image-label fs-16 font-weight-600 mb-4">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-FILES.TITLE' | translate }}
    </label>

    <app-file-upload [id]="workflowId" [accept]="" (uploadedFiles)="uploadedFilesChange($event)"></app-file-upload>


    <!-- <input type="file" class="d-none" accept="video/*,image/*,application/pdf,audio/*" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput multiple>
    <div class="upload-drop-zone" filedrop fileType="image" (filesDropped)="dragDropUpload($event)" accept="video/*,image/*,application/pdf,audio/*" id="drop-zone">
        <div class="upload-icon mt-3"><img src="assets/img/upload-icon.svg"></div>
        <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
        <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
        <div class="upload-button mb-4 mt-1">
            <button *ngIf="!('electron' | env)" class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="imageInput.click()" >{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}</button>
        </div>
    </div> -->

    <div class="d-flex flex-wrap files-area mt-3">
        <div class="file-badge d-flex align-items-center mt-2 fs-14 me-2 mb-2" *ngFor="let file of selectedStepFiles; let index = index">
            <a (click)="downloadStepFile(file)" class="file-attach"><img src="assets/img/download-step-file.svg" alt="" class="download-file"></a>
            <label class="fs-14 font-weight-400">{{file.name}}</label>
            <img src="assets/img/close.svg" alt="" class="close-file" (click)="deleteStepFile(index)">
        </div>
    </div>
</div>