<div class="filter-area" [formGroup]="filterForm">
    <div class="row">

        <div class="col-3" *ngIf="selectedTabIndex == 0">
            <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 100%; min-width: auto;border: none;">
                <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 text-dark-grey fs-13"  #toggleButton dropdownToggle data-bs-auto-close="inside" style="background-color: white !important;
                height: 37px;
                color: #707070;
                width: 80%;">
                {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-STATUS' | translate }}
                </button>
                <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                        aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false" style="background-color: white;
                        border-color: #eff1f5;
                        border-left-color: white;
                        height: 37px;
                        width: 15px;">
                </button>
                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                overflow-y: scroll;" [formGroup]="statusGroup" aria-labelledby="dropdownMenuClickableInside">
                    
                    <div *ngFor="let status of statusOptions; let index = index" class="ps-2">
                        <div class="custom-control custom-checkbox custom-checkbox-green" [ngClass]="{'mt-2':index == 0}">
                            <input
                                type="checkbox"
                                class="form-check-input ms-0"
                                [id]="index"
                                (click) = selectStatus(status)
                                [formControlName]="status"
                            />
                            <label class="custom-control-label label-form fs-14" [for]="index">
                                {{ status | titlecase }}
                            </label>
                        </div>
                    </div>
                    
                </ul>
            </div>
            
        </div>

        <div class="col-3">
            <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 100%; min-width: auto;border: none;">
                <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 text-dark-grey fs-13"  #toggleButton dropdownToggle data-bs-auto-close="inside" style="background-color: white !important;
                height: 37px;
                color: #707070;
                width: 80%;">
                Select Priority
                </button>
                <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                        aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false" style="background-color: white;
                        border-color: #eff1f5;
                        border-left-color: white;
                        height: 37px;
                        width: 15px;">
                </button>
                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                overflow-y: scroll;" [formGroup]="priorityGroup" aria-labelledby="dropdownMenuClickableInside">
                    
                    <div *ngFor="let priority of priorityOptions; let index = index" class="ps-2">
                        <div class="custom-control custom-checkbox custom-checkbox-green" [ngClass]="{'mt-2':index == 0}">
                            <input
                                type="checkbox"
                                class="form-check-input ms-0"
                                [id]="index"
                                (click) = selectPriority(priority)
                                [formControlName]="priority"
                            />
                            <label class="custom-control-label label-form fs-14" [for]="index">
                                {{ priority | titlecase }}
                            </label>
                        </div>
                    </div>
                    
                </ul>
            </div>
            
        </div>

        <div class="col-3">
            <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 100%; min-width: auto;border: none;">
                <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 text-dark-grey fs-13"  #toggleButton dropdownToggle data-bs-auto-close="inside" style="background-color: white !important;
                height: 37px;
                color: #707070;
                width: 80%;">
                Select Assignee
                </button>
                <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                        aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false" style="background-color: white;
                        border-color: #eff1f5;
                        border-left-color: white;
                        height: 37px;
                        width: 15px;">
                </button>
                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                overflow-y: scroll;" aria-labelledby="dropdownMenuClickableInside">
                    
                    <div *ngFor="let assignee of userList; let index = index" class="ps-2">
                        <div class="custom-control custom-checkbox custom-checkbox-green" [ngClass]="{'mt-2':index == 0}">
                            <input
                                type="checkbox"
                                class="form-check-input ms-0"
                                [id]="index"
                                (click) = selectAssignee(assignee)
                            />
                            <label class="custom-control-label label-form fs-14" [for]="index">
                                {{ assignee.name | titlecase }}
                            </label>
                        </div>
                    </div>
                    
                </ul>
            </div>
            
        </div>

        <div class="col-3">
            <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 100%; min-width: auto; border: none;">
                <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
                    {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-LABEL' | translate }}
                </button>
                <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                        aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                </button>
                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                overflow-y: scroll;" [formGroup]="labelGroup" aria-labelledby="dropdownMenuClickableInside">
                    <div class="search-box p-2">
                        <input  type="text" class="form-control fs-14" [formControl]="labelSearchControl" style="height: 38px;"  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                        <div class="search-icon me-2"> <img src="assets/img/search-copy.svg" alt=""></div>
                    </div>
                    <div *ngFor="let label of filteredLabelList; let index = index" class="ps-2">
                        <div class="custom-control custom-checkbox custom-checkbox-green">
                            <input
                                type="checkbox"
                                class="form-check-input ms-0"
                                [id]="index"
                                (click) = selectLabels(label)
                                [formControlName]="label"
                            />
                            <label class="custom-control-label label-form fs-14" [for]="index">
                                {{ label }}
                            </label>
                        </div>
                    </div>
                </ul>
            </div>
        </div>

        <div class="col-3 mt-2">
            <div class="search-box">
                <input type="text" [formControl]="name" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14 w-100" style="min-width: auto;">
                <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
              </div>
        </div>
        
        <div class="col-3 mt-2">
            <input #startDate="bsDatepicker" [formControl]="dueDate"  name="startDate" class="form-control fs-14" id="form-control" style="width: 100%;min-width: auto; height: 38px;margin-left: 20px;" bsDatepicker 
                autocomplete="off" placeholder="Select Due Date"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" >
        </div>
    </div>

</div>