import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-number',
  template: `
          <div class="d-flex align-items-center justify-content-center">
          <input type="number"  [formControl]="formControl" class="form-control number custom-form-control" id="form-control"  placeholder="{{to.placeholder}}" 
          autocomplete="off" description="xx" [ngStyle]="to['precision'] && {'min-width':'80px'}">
          <span style="margin-left:6px;margin-right:6px;padding-top: 29px;" class="label-preview" *ngIf="to['precision']!== null && to['precision']">.</span>
          <input type="number"  [formControl]="formControl" class="form-control number" id="form-control" 
          autocomplete="off" *ngIf="to['precision']!== null && to['precision']" style="margin-left:2px;min-width:80px;">
          <span style="margin-left:2px;" class="label-preview" *ngIf="to['unit']!== null && to['unit']">{{to['unit']}}</span>
          </div>

          <div style="position: absolute;bottom:2px;left:0;right:0;display:flex">
            <label *ngIf="to.min || to.min == 0" class="label-preview-title pb-3 ps-4">Min : {{to.min}}</label>
            <label *ngIf="to.max || to.max == 0" class="label-preview-title ps-2 pb-3">Max : {{to.max}}</label>
          </div>
         
          
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
})
export class FormlyFieldCustomNumber extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}