import { Component, OnInit } from '@angular/core';
import { OwnRoom } from '@models/Room';
import { AuthService } from '@services/auth.service';
import { UserRoomService } from '@services/remote/user-room.service';
import { Subscription } from 'rxjs';

import { DetailPageService } from '@services/detail-page.service';
import { RoomWrapperComponent } from '../room-wrapper/room-wrapper.component';

@Component({
  selector: 'app-user-rooms',
  templateUrl: './user-rooms.component.html',
  styleUrls: ['./user-rooms.component.scss']
})
export class UserRoomsComponent implements OnInit {

  rooms: OwnRoom[] = [];
  roomsLoading: boolean = true;
  roomsSub: Subscription = null;

  canJoin = true
  showRoomUsers: any = {};


  constructor(
    private roomService: UserRoomService,
    private authService: AuthService,
    private detailPageService: DetailPageService
  ) {}

  ngOnInit(): void {
    this.roomsSub = this.roomService.allOwnRooms.subscribe(rooms => {
      if (rooms) {
        console.log(rooms, 'rooms')
        this.rooms = rooms.filter(a => !a.room_data.meeting_room && !a.room_data.personal_room).sort((a, b) => {
          if (a.room_data.in_room.count === 0 && b.room_data.in_room.count > 0) {
            return 1;
          } else if (a.room_data.in_room.count > 0 && b.room_data.in_room.count === 0) {
            return -1;
          } else {
            return this.alphabeticalSort(a, b);
          }
        });
        if(this.authService.currentUser?.default_room && this.canJoin && !this.roomService.isJoinedRoom) {
          let room = this.rooms.find(room => room.id == this.authService.currentUser.default_room)
          if(room) {
            this.onJoin(room.id)
            this.roomService.changeJoinStatus(true)
          }
        }
        this.roomsLoading = false;
      }
    })

  }

  alphabeticalSort(a, b) {
    return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
  }

  onJoin(roomId: string) {
    this.openRoomInterface(roomId)
  }

  openRoomInterface(roomId: string) {
    const [instance, onClose] = this.detailPageService.loadComponent(RoomWrapperComponent, {})
    instance.roomId = roomId
    instance.users = null
    instance.linkId = null
    instance.guestName = null
    onClose.then(() => {
      console.log(closed)
    })
  }

}
