<div class="btn-group dropdown-wrapper-list" dropdown style="width: 100%" [ngClass]="{'pointer-events-none': type=='published' || isVideoFlow}">
    <button type="button" class="btn btn-primary action-dropdown p-0" [ngStyle]="{'width' : width ? width : '55%'}" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
        <img src="assets/img/check_icon.svg" alt="" *ngIf="imageExist" class="ps-3">
        <img [src]="imgSrc" alt="" *ngIf="imgSrc" style="width: 21px;">
        <span *ngIf="!imgSrc" [ngClass]="{'ps-3': !imageExist}">{{ title }}</span>
        
    </button>
    <button id="button-split" type="button"  class="btn btn-danger dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
            aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
    </button>
    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
        <ng-content></ng-content>
    </ul>
</div>
