import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { WorkOrder } from '@models/WorkOrder';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/support/app.service';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';

@Component({
  selector: 'app-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.scss']
})
export class WorkOrdersComponent implements OnInit {
  @ViewChild('stepListStepOptions', {static: false}) stepListStepOptions: ElementRef;

  mode = null
  tabIndex = 0
  order
  selectedWorkOrder = null
  statusOptions = ['open', 'in-progress', 'on-hold', 'done']
  selectedStatus = 'open'


  workOrders = []

  isLoading = false

  subscriptions: Subscription[] = []
  isWebClient = false
  isAdmin
  isEditorMode = false
  filteredWorkOrderList = []

  allLabels

  nameOrder
  dueDateOrder
  priorityOrder
  filterReset

  constructor(
    private router: Router,
    private workflowService: WorkflowService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    public loaderService: LoaderService,
    private authService: AuthService,
    private appService: AppService) { }

  ngOnInit(): void {
    this.appService.isEditorMode.subscribe(res => {
      this.isEditorMode = res
    })
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin || user.editorAccess
    }))
    this.isWebClient = !this.isAdmin || (this.isAdmin && !this.isEditorMode)
    this.isLoading = true
    this.loaderService.show()
    if(this.isWebClient) {
      this.getMyWorkOrders()
    }else{
      this.getWorkOrders()
    }
  }

  onTabClicked(index) {
    this.tabIndex = index
    this.resetFilter()
    if(index == 1) {
      this.filteredWorkOrderList = this.workOrders.filter(workOrder => workOrder.status == 'done')
    }else{
      this.filteredWorkOrderList = this.workOrders.filter(workOrder => workOrder.status !== 'done')
    }
  }

  resetFilter() {
    this.filterReset = true
  }

  changeStatus(status, workOrderIndex) {
    this.workOrders[workOrderIndex].status = status
    if(this.isWebClient) {
      this.updateMyWorkOrderStatus(this.workOrders[workOrderIndex])
    }else{
      this.updateWorkOrder(this.workOrders[workOrderIndex], true)
    }
  }

  selectWorkOrder(index) {
    this.selectedWorkOrder = this.filteredWorkOrderList[index]
  }

  createWorkOrder() {
    this.mode = 'create'
  }

  selectedWorkOrderChange(workOrder) {
    if(!workOrder) {
      this.selectedWorkOrder = null
      this.mode = null
    }

  }

  getWorkOrders() {
    this.workflowService.getWorkOrders().then(workOrders => {
      if(workOrders?.workorders?.length) {
        this.workOrders = workOrders.workorders
        this.filteredWorkOrderList = this.workOrders.filter(wo => wo.status !== 'done')
      }
    })
    .catch(err => {})
    .finally(() => {
      this.loaderService.hide()
      this.isLoading = false
    })
  }

  getMyWorkOrders() {
    this.workflowService.getMyWorkOrders().then(workOrders => {
      if(workOrders?.workorders?.length) {
        this.workOrders = workOrders.workorders
        this.filteredWorkOrderList = this.workOrders.filter(wo => wo.status !== 'done')
      }
    })
    .catch(err => {})
    .finally(() => {
      this.loaderService.hide()
      this.isLoading = false
    })
  }

  updateWorkOrder(workOrder, listUpdate?) {
    const id = listUpdate ? workOrder.id : this.selectedWorkOrder.id
    this.loaderService.show()
    const data = workOrder
    this.workflowService.updateWorkOrder(data, id).then(resp => {
      if(resp) {
        const updatedItemIndex = this.workOrders.findIndex(wo => wo.id == id)
        this.workOrders[updatedItemIndex] = workOrder
      }
    })
    .catch(err => {})
    .finally(() => this.loaderService.hide())
  }

  updateMyWorkOrderStatus(workOrder) {
    this.loaderService.show()
    const data = {
      status : workOrder.status
    }
    this.workflowService.updateWorkOrderForClient(data, workOrder.id).then(resp => {
      if(resp) {
        const updatedItemIndex = this.workOrders.findIndex(wo => wo.id == workOrder.id)
        this.workOrders[updatedItemIndex] = workOrder
      }
    })
    .catch(err => {})
    .finally(() => this.loaderService.hide())
  }

  deleteWorkOrder(id) {
    const initialState= {
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORK-ORDERS.WARNING.DELETE-WORK-ORDER-TITLE'),
      textMessage: this.translateService.instant('MAIN.CONTENT.WORK-ORDERS.WARNING.DELETE-WORK-ORDER-TEXT'),
      // confirmButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.CONTINUE-UPPERCASE'),
      instantClose:false
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.loaderService.show()
        this.workflowService.deleteWorkOrder(id).then(resp => {
          confirmModalRef.hide();
          const deletedItemIndex = this.workOrders.findIndex(wo => wo.id == id)
          this.workOrders.splice(deletedItemIndex, 1)
          this.filteredWorkOrderList = this.workOrders.filter(wo => wo.status !== 'done')
          this.selectedWorkOrder = null
        })
        .catch(err => {})
        .finally(() => this.loaderService.hide())
      }
    }))
  }

  workOrderCreated(event) {
    this.workOrders.push(event)
    this.filteredWorkOrderList = this.workOrders.filter(wo => wo.status !== 'done')
    this.selectedWorkOrder = null
    this.selectedWorkOrder = event
  }


  // stop(event) {
  //   event.stopPropagation()
  // }

  goDetail() {
    this.router.navigate(['/work-order-detail'])
  }

  sortByName() {
    this.dueDateOrder = ''
    this.priorityOrder = ''
    this.order = !this.order ? 'asc' : (this.order == 'asc' ? this.order = 'desc' : this.order = 'asc')
    this.filteredWorkOrderList.sort((a, b) => {
      if (this.order === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
  }

  sortByPriority() {
    this.dueDateOrder = ''
    this.order = ''
    this.priorityOrder = !this.priorityOrder ? 'asc' : (this.priorityOrder == 'asc' ? this.priorityOrder = 'desc' : this.priorityOrder = 'asc')
    const priorityOrder = this.priorityOrder === 'asc' 
      ? ['low', 'medium', 'high'] 
      : ['high', 'medium', 'low'];

    this.filteredWorkOrderList.sort((a, b) => {
      return priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority);
    });
  }

  sortByDueDate() {
    this.nameOrder = ''
    this.order = ''
    this.dueDateOrder = !this.dueDateOrder ? 'asc' : (this.dueDateOrder == 'asc' ? this.dueDateOrder = 'desc' : this.dueDateOrder = 'asc')
    
    this.filteredWorkOrderList.sort((a, b) => {
      if (this.dueDateOrder === 'asc') {
        return a.dueDate - b.dueDate
      } else {
        return b.dueDate - a.dueDate
      }
    });
  }

  toggle(): void {
    
  }

  filterChanged(filters) {
    // if(filters.priority) {
    //   this.filteredWorkOrderList = this.workOrders.filter(wo => wo.status !== 'done' && )

    // }
    this.filteredWorkOrderList = this.filterWorkOrders(this.workOrders, filters)
    

  }

  filterWorkOrders(workOrders: WorkOrder[], filters: any): WorkOrder[] {
    return workOrders.filter(workOrder => {
      return Object.keys(filters).every(key => {
        // Eğer filtreleme yapılacak kategori bir array ise ve boş değilse, her bir değeri kontrol et
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          if (key === 'assignee') {
            return filters[key].includes(workOrder.assignedTo);
          }
          return filters[key].includes(workOrder[key]);
        }
        if (key === 'status' && filters[key].length === 0) {
          if(this.tabIndex == 0) {
            return workOrder.status !== 'done';
          }else{
            return workOrder.status == 'done';
          }
        }
        if (key === 'name' && filters[key]) {
          return workOrder.name.toLowerCase().includes(filters[key].toLowerCase());
        }
        // Boş array ise bu filtreyi pas geç
        return true;
      });
    });
  }

}
