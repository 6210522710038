import { Injectable } from '@angular/core';
import { DbService } from '@services/db.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  static timezones = { "UTC-12": -43200000, "UTC-11": -39600000, "UTC-10": -36000000, "UTC-9": -32400000, "UTC-8": -28800000, "UTC-7": -25200000, "UTC-6": -21600000, "UTC-5": -18000000, "UTC-4": -14400000, "UTC-3": -10800000, "UTC-2": -7200000, "UTC-1": -3600000, "UTC": 0,
  "UTC+1": 3600000, "UTC+2": 7200000, "UTC+3": 10800000, "UTC+4": 14400000, "UTC+5": 18000000, "UTC+6": 21600000, "UTC+7": 25200000, "UTC+8": 28800000, "UTC+9": 32400000, "UTC+10": 36000000, "UTC+11": 39600000, "UTC+12": 43200000, "UTC+13": 46800000, "UTC+14": 50400000 }
  static timezoneOffset = (new Date).getTimezoneOffset()*60000;

  constructor(
    private dbService: DbService
  ) { }

  repeatUntilDone(callback: () => boolean, max: number, interval: number = 100) {
    let counter = 0;
    const i = setInterval(() => {
      counter++;
      if (counter > max || callback()) {
        clearInterval(i)
      }
    }, interval);
  }

  getServerTimeOffset(): Promise<number> {
    return this.dbService.get<number>('.info/serverTimeOffset', 'value');
    //return this.afdb.database.ref(".info/serverTimeOffset").once("value").then(snap => snap.val());
  }
}
