<div  style="position: absolute;top: 20px;right: 0;left: 0;bottom: 0;/* background: grey; */" *ngIf="selectedCell">
    <app-table-cell-modal 
    [selectedCell]="selectedCell" 
    [cellControl]="previewForm.controls[selectedCell.key]" 
    (onCellUpdated)="onCellUpdated($event)"
    (onClose)="closeModal($event)"></app-table-cell-modal>
</div>
<div style="position:relative">
    
    <form class="w-100 h-100" [ngStyle]="{'opacity': selectedCell ? 0.5 : 1}" [formGroup]="previewForm">
        <table class="w-100 h-100">
           
            <tbody>
                <tr *ngIf="tableData?.rows?.length > 0">
                    
                    <td class="text-center has-border" *ngFor="let column of tableData?.columns[0].cells; let columnIndex = index;">
                       <strong> {{ column.templateOptions.label || 'Column ' + columnIndex + 1 }}</strong>
                    </td>
                </tr>
                <!-- Column Header Info Row -->
    
                <tr *ngFor="let row of tableData?.rows; let rowIndex = index;">
                    
                    <td *ngFor="let column of row.cells; let columnIndex = index;"
                        [ngClass]="{'is-selected' : column.key === selectedCell?.key, 'has-border' : column.key !== selectedCell?.key}"
                        (click)="selectCell(column)">
                        <div class="d-flex align-items-center justify-content-center"
                            *ngIf="column.templateOptions?.label && column.templateOptions.label">
                            {{ column.templateOptions.label }}
                        </div>
                        <div class="d-flex align-items-center justify-content-center" *ngIf="column.type=='input'" style="padding:10px 5px">
                            <div class="input-group me-1 ms-1" style="height:36px;min-width: 148px">
                                <input type="text" style="height: 40px;height: 50%;"
                                class="form-control border-right-none"
                                [formControlName]="column.key" id="form-control"
                                placeholder="{{ column.templateOptions.placeholder}}"
                                autocomplete="off">
                                <span class="input-group-text" id="basic-addon2"><img src="assets/img/input-icon.svg" height="9px"></span>
                            </div>
                        </div>

                        <div *ngIf="column.type=='datepicker'" class="d-flex align-items-center justify-content-center">
                            <div class="input-group me-1 ms-1" style="height:36px;min-width: 148px">
                                <input [formControlName]="column.key" #startDate="bsDatepicker" name="startDate"
                                class="form-control custom-form-control border-right-none" id="form-control" bsDatepicker autocomplete="off"
                                placeholder="{{column.templateOptions.placeholder}}"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }"
                                onkeypress="return false;" style="height: 36px;">
                                <span class="input-group-text" id="basic-addon2"><img src="assets/img/datepicker-icon.svg"></span>
                            </div>
                        
                        </div>
    
                        <div class="flex-column" style="position:relative;display: flex;" *ngIf="column.type=='number'" [formGroupName]="column.key">
                            <div class="d-flex justify-content-center align-items-center mt-1" [ngClass]="{'mt-4' : column.templateOptions.min || column.templateOptions.max}">
                                <div class="input-group me-1 ms-1" style="height:36px;min-width: 135px">
                                    <input type="number" formControlName="numberFirst"  class="form-control number border-right-none" id="form-control"  placeholder="{{column.templateOptions.placeholder}}" 
                                    autocomplete="off" description="xx" [ngStyle]="column.templateOptions['precision'] && {'min-width':'50px'}">
                                    <span class="input-group-text" id="basic-addon2"><img src="assets/img/number-icon.svg"></span>
                                </div>
                                <span style="margin-left:6px;margin-right:6px;padding-top: 29px;" class="label-preview" *ngIf="column.templateOptions['precision']!== null && column.templateOptions['precision']">.</span>
                                <div class="input-group me-1 ms-1" style="height:36px;min-width: 135px" *ngIf="column.templateOptions['precision']!== null && column.templateOptions['precision']">
                                    <input type="number" formControlName="numberSecond"  class="form-control number border-right-none" id="form-control" 
                                    autocomplete="off" style="min-width: 50px;">
                                    <span class="input-group-text" id="basic-addon2"><img src="assets/img/number-icon.svg"></span>
                                </div>
                                
                                <span style="margin-left:2px;width: auto;" class="label-preview fs-12" *ngIf="column.templateOptions['unit']!== null && column.templateOptions['unit']">{{column.templateOptions['unit']}}</span>
                            </div>
                            <div class="d-flex align-items-start">
                                <label *ngIf="column.templateOptions.min || column.templateOptions.min == 0" class="label-preview-title fs-12 pb-1">Min : {{column.templateOptions.min}}</label>
                                <label *ngIf="column.templateOptions.max || column.templateOptions.max == 0" class="label-preview-title fs-12 ps-2 pb-1">Max : {{column.templateOptions.max}}</label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center" *ngIf="column.type=='empty'">
                            <span style="min-width: 90px"></span>
                        </div>
    
                        <div *ngIf="column.type == 'multicheckbox'" class="d-flex align-items-center justify-content-start ms-2">
                            <div class="form-check text-start">
                                <div [formGroup]="optionGroup" *ngFor="let optionGroup of getMultiCheckboxOptions(column.key); let i=index;">
                                    <input type="checkbox" class="form-check-input" [id]="'input-'+optionGroup.value.option"
                                    formControlName="value"/>
                                    <label class="form-check-label" [for]="'input-'+optionGroup.value.option"
                                        *ngIf="optionGroup.value.option">
                                        {{ optionGroup.value.option }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="column.type=='radio'" class="d-flex align-items-center justify-content-start ms-2">
                            <div class="form-check text-start">
                                <div *ngFor="let option of column.templateOptions?.options">
                                    <input type="radio" class="form-check-input" [id]="'input-'+column.key+option.value"
                                        [value]="option.value"
                                        [formControlName]="column.key"/>
                                    <label class="form-check-label" [for]="'input-'+column.key+option.value"
                                        *ngIf="option.label">
                                        {{ option.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
