import { Injectable } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import languagesJson from '../../assets/languages.json';

@Injectable({
  providedIn: 'root'
})
export class WorkflowLanguageService {

  currentLang = { code: 'en', name: 'English' };
  allLangs = [];

  onLangChange: Observable<{ lang: any, translations: any }>;
  private onLangChangeSource = new BehaviorSubject<{ lang: any, translations: any }>(null)

  constructor() { 
    this.allLangs = languagesJson.languages
    this.onLangChange = this.onLangChangeSource.asObservable().pipe(filter(l => l !== null))

    this.onLangChange.subscribe((change: LangChangeEvent) => {
    });

  }

  setCurrentLanguage(lang) {
    if (lang && this.allLangs.findIndex(l => l.code == lang.code) > -1) {
      this.onLangChangeSource.next({lang: lang,translations: ''})
    }
  }
}
