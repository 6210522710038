import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { first, Subscription } from 'rxjs';

declare var Android: any;

@Component({
  selector: 'app-webview-login',
  templateUrl: './webview-login.component.html',
  styleUrls: ['./webview-login.component.scss']
})
export class WebviewLoginComponent implements OnInit, OnDestroy {

  authSubscription: Subscription = null;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    // Navigate main if user already authenticated
    /*this.authSubscription = this.authService.userForGuard.subscribe(auth => {
      if (auth) {
        this.router.navigate(['/'])
      }
    })*/

    this.postReadyMessageToMobileDevices()
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
  }

  postReadyMessageToMobileDevices() {
    try {
      let w: any = window;
      if (w.webkit && w.webkit.messageHandlers) {
        w.webkit.messageHandlers.editorReady.postMessage("EDITOR_READY")
      }
    } catch (error) {
      console.log(error)
    }

    try {
      if (Android) {
        Android.editorReady("EDITOR_READY")
      }
    } catch (error) {
      console.log(error)
    }
  }

  authenticate(customToken: string) {
    return this.authService.loginToFirebase(customToken)
    .then(() => this.authService.userForGuard.pipe(first()).toPromise())
  }

  @HostListener('window:mobileMessage', ['$event'])
  mobileMessage(event) {
    if (event.detail.action === "AUTHENTICATE") {
      const customToken = event.detail.token
      this.authenticate(customToken)
      .then(() => {
        this.router.navigate(['/work-order-detail-mobile'])
      })
    }/* else if (event.detail.action === "OPEN_WORKORDER_LIST") {

      // TODO redirect to actual pages
      this.router.navigate(['/workorder'], { state: event.detail })
    }*/
  }

}
