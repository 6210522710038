import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-not-supported-modal',
  templateUrl: './not-supported-modal.component.html',
  styleUrls: ['./not-supported-modal.component.scss']
})
export class NotSupportedModalComponent {

  @Input() ios
  @Input() ios_version_low?

  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
  }


}
