<div class="user-wrapper">
    <div class="user-detail-container">
      <div class="v-card">
        <div class="card list-header flex-row justify-content-between align-items-center pe-4 ps-2">
          <div class="header font-weight-700 fs-20 pt-1">
              <img class="back-button ps-1 pe-3 py-2" (click)="onBackButton()" src="assets/img/back-blue.svg" alt="">
              {{ 'MAIN.SESSIONS.SESSION_DETAILS.TITLE' | translate }}
          </div>
          <div *ngIf="sessionExportEnabled && !webdavEnabled && !session.session_files_deleted">
            <button class="v-btn btn btn-primary vsight-button" (click)="onGetExport()">
              Get Session Export
            </button>
          </div>
      </div>
        <div class="card-body row">
          <div class="col-7" style="padding-left: 45px;padding-right: 45px;">
            <div class="row">
              <div *ngIf="userSessions" class="col-12 mt-2 mb-3"><span class="fs-16 font-weight-600">{{ session.room.name }}</span></div>
              <div *ngIf="!userSessions" class="col-12 mt-2 mb-3"><span class="fs-16 font-weight-600">{{ session.room_id | roomName | async }}</span></div>
              <div class="col-12 mt-3 fs-14 text-dark-grey font-weight-600 row"><div class="key col-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.DATE' | translate }} </div><div class="value fs-14 text-dark-grey font-weight-600 col-10">{{ session.create_time | utcCalcAccount:true | async }}</div></div>
              <div class="col-12 mt-3 fs-14 text-dark-grey font-weight-600 row"><div class="key col-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.DURATION' | translate }}</div><div class="value fs-14 text-dark-grey font-weight-600 col-10">{{ session | sessionDuration }}</div></div>

              <!-- session.users and session.guest_users are array -->
              <div class="col my-3" *ngIf="userSessions">
                <div class="d-flex flex-row">
                  <div class="key fs-14 text-dark-grey font-weight-600 col-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.PARTICIPANTS' | translate }} </div>
                  <div class="participants">
                    <div *ngFor="let suser of session.users" class="d-flex">
                      <div class="me-3 fs-14 text-dark-grey"> {{ suser.name }} </div> 
                      <div *ngFor="let user of users">
                        <div class="d-flex flex-column value ml-2" *ngIf="!session.user_durations && user.id === suser.id">
                          <div> <a class="email-text core-blue fs-14" href="mailto:{{ user.email }}">{{ user.email }}</a></div>
                        </div>
                        <div *ngFor="let duration of (session.user_durations | keyvalue)">
                          <div class="d-flex value ml-2" *ngIf="user.id === suser.id && duration.key === user.id">
                            <div class="ml-auto pl-3 me-3 fs-14 text-dark-grey">
                            {{ duration.value | durationFormat: 'ms':'hhmmss' }}
                            </div>
                          </div>
                          <div class="d-flex value ml-2 mb-3" *ngIf="user.id === suser.id && duration.key === user.id">
                            <a class="email-text core-blue fs-14" href="mailto:{{ user.email }}">{{ user.email }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span *ngFor="let guest of session.guest_users">
                      <span *ngFor="let duration of (session.user_durations | keyvalue)">
                        <div class="d-flex value ml-2 mb-3 fs-14 text-dark-grey" *ngIf="duration.key === guest.id">
                          {{ guest.name }} &nbsp;  <span class="fs-14 text-dark-grey me-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.GUEST' | translate }}</span> <div  class="ml-auto pl-3">{{ duration.value | durationFormat: 'ms':'hhmmss' }}</div>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <!-- session.users and session.guest_users are objects -->
              <div class="col my-3" *ngIf="!userSessions">
                <div class="d-flex flex-row">
                  <div class="key fs-14 text-dark-grey font-weight-600 col-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.PARTICIPANTS' | translate }} </div>
                  <div class="participants">
                    <div *ngFor="let name of (session.users | getIds | filterGuests:session.guest_users | userNames | async)" class="d-flex">
                      <div class="me-3 fs-14 text-dark-grey"> {{ name }} </div> 
                      <div *ngFor="let user of users">
                        <div class="d-flex flex-column value ml-2" *ngIf="!session.user_durations && user.name === name">
                          <div> <a class="email-text core-blue fs-14" href="mailto:{{ user.email }}">{{ user.email }}</a></div>
                        </div>
                        <div *ngFor="let duration of (session.user_durations | keyvalue)">
                          <div class="d-flex value ml-2" *ngIf="user.name === name && duration.key === user.id">
                            <div class="ml-auto pl-3 me-3 fs-14 text-dark-grey">
                            {{ duration.value | durationFormat: 'ms':'hhmmss' }}
                            </div>
                          </div>
                          <div class="d-flex value ml-2 mb-3" *ngIf="user.name === name && duration.key === user.id">
                            <a class="email-text core-blue fs-14" href="mailto:{{ user.email }}">{{ user.email }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span *ngFor="let guest of (session.guest_users | objectToArray)">
                      <span *ngFor="let duration of (session.user_durations | keyvalue)">
                        <div class="d-flex value ml-2 mb-3 fs-14 text-dark-grey" *ngIf="duration.key === guest.id">
                          {{ guest.name }} &nbsp;  <span class="fs-14 text-dark-grey me-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.GUEST' | translate }}</span> <div  class="ml-auto pl-3">{{ duration.value | durationFormat: 'ms':'hhmmss' }}</div>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>

            <div class="row top-gap-30">
              
              <!-- <ng-container *ngIf="ticketAvailable">
                <div class="col-12" [ngClass]="{'col-md-6': sessionExportEnabled && !webdavEnabled && !session.session_files_deleted}">
                  <button *ngIf="!attachedTicketId" class="btn btn-outline-primary detail-button mt-3" (click)="onAttachToTicket()">
                    <i class="mi mi-style dark-blue-color button-icon mr-2"></i><span>{{ 'MAIN.SESSIONS.SESSION_DETAILS.ATTACH_TO_TICKET' | translate }}</span>   
                  </button>
                  <div *ngIf="attachedTicket" class="mt-4"><span class="key">{{ 'MAIN.SESSIONS.SESSION_DETAILS.ATTACHED_TICKET' | translate }}: </span><span class="values">{{ attachedTicket.title }}</span></div>
                </div>
              </ng-container> -->
            </div>
  
            <app-archives [room_id]="session.room_id" [session_id]="session.id"></app-archives>

            <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
  
            <div class="top-gap-30" *ngIf="!webdavEnabled && !session.session_files_deleted">
              <div class="">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="fs-14 font-weight-700">{{ 'MAIN.SESSIONS.SESSION_DETAILS.CHAT' | translate }}</span>
                </div>
                <div class="card-body">
                  <app-session-chat [session]="session"></app-session-chat>
                </div>
              </div>      
            </div>
          </div>

          <div class="col-5" style="padding-right: 60px;" *ngIf="!webdavEnabled && !session.session_files_deleted">
            <div class="files-chat">
              <div class="mb-3">
                <div class="file-area">

                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <span class="fs-14 font-weight-700">{{ 'MAIN.SESSIONS.SESSION_DETAILS.FILES' | translate }}</span>
                    <div>
                      <button *ngIf="!showDownloadButton" class="v-btn btn btn-primary-outline vsight-button-outline-primary"  (click)="toggleSelection()">{{ 'MAIN.LOBBY.SESSIONS.SESSION_FILES.MULTIPLE_SELECTION' | translate | uppercase }}</button>
                      <button *ngIf="showDownloadButton"  class="v-btn btn btn-primary-outline vsight-button-outline-secondary me-3"  (click)="toggleSelection()">{{ 'MODALS.ALERT.CANCEL' | translate }}</button>

                      <button *ngIf="showDownloadButton" class="v-btn btn btn-primary vsight-button fs-13" (click)="onDownloadStarted()">{{ 'MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD' | translate | uppercase }}</button>
                    </div>                  
                  </div>

                  <div>
                    <app-session-file 
                      *ngIf="session"
                      [session]="session"
                      [session_id]="session.id"
                      [showCheckboxes]="showCheckboxes"
                      [downloadSelectedFiles]="downloadSelectedFiles">
                    </app-session-file>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  <!--
      <div class="card mt-4" *ngIf="sessionExportEnabled && !webdavEnabled && !session.session_files_deleted">
        <div class="card-header d-flex justify-content-between align-items-center">
          <span class="card-header-title">{{ 'MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.TITLE' | translate }}</span>
        </div>
        <div class="card-body py-4 px-4 px-md-5">
          <div class="recipients-message mb-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.MESSAGE' | translate:('design' | env) }}</div>
          <tag-input #tagInput [(ngModel)]="exportRecipients" [placeholder]="emailPholder" [secondaryPlaceholder]="emailSecPholder"
            theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError" [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
  
            <div class="recipients-warning-text mt-2">{{ 'MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.WARNING' | translate }}</div>
  
          <button class="btn btn-info btn-block mt-2" [ngStyle]="{'cursor': exportRecipients.length !== 0 ? 'pointer' : 'default'}" [fakeDisabled]="exportRecipients.length === 0" (enabledClick)="onSessionExport()">{{ 'MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.SEND' | translate }}</button>
        </div>
      </div>
    -->
  
      
    </div>
  </div>
  
  <!-- <ng-template #pickTicketTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog-wide modal-dialog" role="document">
      <div class="modal-content">
          <div class="card">
              <div class="card-header py-3 d-flex align-items-center justify-content-between">
                <h5 class="modal-title d-flex align-items-center">{{ 'MAIN.SESSIONS.SESSION_DETAILS.ATTACH_SESSION_TO_TICKET' | translate }}</h5>
              </div>
              <div class="card-body">
                <div class="m-body" style="height: 350px; overflow: scroll;">
                  <div class="ticket-list t-container container-fluid">
                    <div class="t-head row sm-hide">
                      <div class="t-cell col-1 font-weight-bold">#</div>
                      <div class="t-cell col-5 font-weight-bold">{{ 'MAIN.TICKETS.TICKET_TITLE' | translate }}</div>
                      <div class="t-cell col-3 font-weight-bold">{{ 'MAIN.TICKETS.CREATED_BY' | translate }}</div>
                      <div class="t-cell col-2 font-weight-bold">{{ 'MAIN.TICKETS.CREATED_ON' | translate }}</div>
                      <div class="t-cell col-1 font-weight-bold">{{ 'MAIN.TICKETS.STATUS' | translate }}</div>
                    </div>
  
                    <div class="t-row row bg-white" style="cursor: default;" *ngIf="dataModel.tickets.length === 0">
                      <div class="no-contact-message text-muted py-2">{{ 'MAIN.TICKETS.NO_TICKETS' | translate }}</div>    
                    </div>
  
                    <div class="t-row row" *ngFor="let t of dataModel.tickets; let i = index" [ngClass]="{'selected': selectedTicket === t.id}" (click)="onTicketClicked(t.id);">
                      <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">#</div>
                      <div class="t-cell col-6 col-md-4 col-lg-1">{{ i+1 }}</div>
                      <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'MAIN.TICKETS.TICKET_TITLE' | translate }}</div>
                      <div class="t-cell col-6 col-md-4 col-lg-5">{{ t.title }}</div>
                      <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'MAIN.TICKETS.CREATED_BY' | translate }}</div>
                      <div class="t-cell col-6 col-md-4 col-lg-3">{{ getUsersName(t.creator) }}</div>
                      <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'MAIN.TICKETS.CREATED_ON' | translate }}</div>
                      <div class="t-cell col-6 col-md-4 col-lg-2">{{ t.create_time | utcCalcAccount | async }}</div>
                      <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'MAIN.TICKETS.STATUS' | translate }}</div>
                      <div class="t-cell col-6 col-md-4 col-lg-1" [ngSwitch]="t.status">
                        <div *ngSwitchCase="'open'" class="badge badge-success">{{ 'MAIN.TICKETS.STATUS_OPEN' | translate }}</div>
                        <div *ngSwitchCase="'in-progress'" class="badge badge-warning">{{ 'MAIN.TICKETS.STATUS_IN_PROGRESS' | translate }}</div>
                        <div *ngSwitchCase="'closed'" class="badge badge-secondary">{{ 'MAIN.TICKETS.STATUS_CLOSED' | translate }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="m-footer">
                  <a class="text-dark" (click)="callbacks.close()">{{ 'MAIN.SESSIONS.SESSION_DETAILS.CLOSE' | translate }}</a>
                  <a class="core-blue" [class.disabled]="!selectedTicket" (click)="selectedTicket ? callbacks.attach() : null">{{ 'MAIN.SESSIONS.SESSION_DETAILS.ATTACH' | translate }}</a>
                </div>
              </div>
          </div>
      </div>
    </div>
  </ng-template> -->