import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-form-widget-view',
  templateUrl: './form-widget-view.component.html',
  styleUrls: ['./form-widget-view.component.scss']
})
export class FormWidgetViewComponent implements OnInit {

  @Output() formOutputData: EventEmitter<any> = new EventEmitter<any>();
  @Input() formData

  @ViewChild('widgetTableForm', { static: true }) widgetTableForm: NgForm

  mappedData : any = {}


  constructor() { }

  ngOnInit(): void {
    this.widgetTableForm.valueChanges.subscribe(res => {
        Object.keys(this.widgetTableForm.form.controls).forEach(key => {
          this.mappedData[key] = this.widgetTableForm.form.controls[key].value
        })
    })
  }

}
