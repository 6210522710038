<div class="guest-wrapper-background"></div>
<div [ngClass]="{'guest-wrapper-mobile': isMobile, 'guest-wrapper': !isMobile}">
    <div class="guest-box">
        <div *ngIf="showWorkflow" [ngClass]="{'mobile-guest': isMobile, 'guest-workflow': !isMobile}">
            <div class="workflow-box" cdkDrag  handleResize  [(height)]="boxHeight" [(width)]="boxWidth" 
            [style.width.px]="boxWidth" [style.height.px]="boxHeight" [maxWidth]="700" [maxHeight]="700" [minWidth]="400" [minHeight]="300">
                <div cdkDragHandle style="cursor: move;border-bottom: solid 1px #eeeeee;
                padding-bottom: 5px;">
                    <!-- <div class="move-handle">
                        <svg width="20px" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </div> -->
                    <div class="d-flex align-items-center">
                        <div class="col-2 d-flex h-100 align-items-center justify-content-center">
                            <img src="assets/img/amblem.svg" alt="" style="height: 26px;">
                        </div>
                        <div class="col-8">
                            <label class="cursor-move text-grey" *ngIf="wfName">{{wfName}}</label>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-end">
                            <div *ngIf="isStepFileAvailable && isStart" class="me-2">
                                <img src="assets/img/step-files-icon.svg" alt="" class="cursor-pointer" (click)="showStepFiles()">
                            </div>
                            <div  [ngStyle]="{'pointer-events': isStart ? '' : 'none'}" *ngIf="publicWorkflow?.showContentPage || (publicWorkflow?.expertsToCall?.length > 0)" class="guest-menu">
                                <img src="assets/img/list-content.svg" class="cursor-pointer" alt="" height="13px" (click)="switchWorkflowMenu()">
                            </div>
                        </div>
                    </div>
                </div>
                <app-user-workflow
                    [ngClass]="{'app-workflow': !isMobile}"
                    [workflow]="publicWorkflow"
                    (workflowSubmitted)="workflowSubmitted($event)"
                    [guestData]="data"
                    [report]="report"
                    [reportId]="report?.id"
                    [results]="results"
                    [isGuest]="true"
                    (langChanged)="langChanged($event)"
                    (isWorkflowStart)="isWorkflowStart($event)"
                    [(workflowMenuClicked)]="workflowMenuClicked"
                    (workflowMenuClickedChange)= "workflowMenuClickedChange($event)"
                    [(stepFilesClicked)]="stepFilesClicked"
                    (isStepFileAvailable)="stepFileAvailability($event)"
                 >
                </app-user-workflow>
                <div class="handler"></div>
            </div>
        </div>
    </div>
    
</div>
