import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[filedrop]'
})
export class FileDropDirective {

  @Input('fileType') fileType?: string = '';

  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesHovered =  new EventEmitter<boolean>();
  @HostBinding("style.background") private background = "white";

  constructor() { }

  @HostListener('drop', ['$event'])
  onDrop($event) {
    if(this.fileType == $event.dataTransfer.files[0].type.split('/')[0]){
        $event.preventDefault();
        this.filesDropped.emit($event.dataTransfer.files);
        this.filesHovered.emit(false);
    }else if(this.fileType == $event.dataTransfer.files[0].type.split('/')[1] ) {
      $event.preventDefault();
      this.filesDropped.emit($event.dataTransfer.files);
      this.filesHovered.emit(false);
    } else if(this.fileType === '*') {
      this.filesDropped.emit($event.dataTransfer.files);
      this.filesHovered.emit(false);
    } else{
        window.alert('Wrong type')
    }
    this.background = "white";
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    event.preventDefault();
    this.background = "#eee";

    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    this.background = "white";
    this.filesHovered.emit(false);
  }
}
