<div class="v-card card modal-card">
    <div class="card-header modal-card-header fs-16 font-weight-600">{{ 'MODALS.WORKFLOW-USERS.TITLE' | translate }}</div>
    
    <div class="card-body modal-card-body p-0">
        <div class="d-flex justify-content-center py-4">
            <div class="col-5 px-2">
                <div class="fs-14 font-weight-500 mb-2">{{ 'MODALS.WORKFLOW-USERS.USERS' | translate }}</div>
                <div class="card" style="height: 250px; overflow: scroll;">
                    <div style="border-bottom: 1px solid #dfdfdf;padding: 15px;padding-top: 10px;padding-bottom: 10px;position: relative;" class="d-flex">
                        <input type="text" class="form-control" style="height: 40px;" [(ngModel)]="filterValue" (ngModelChange)="filterChanged($event)">
                        <div style="position:absolute;right:20px;bottom:15px;right: 36px;bottom: 18px;">
                            <img src="assets/img/search-copy.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <div *ngFor="let user of filteredUsers" style="border-bottom: 1px solid #dfdfdf;padding: 15px;padding-top: 10px;padding-bottom: 10px;cursor: pointer;" class="d-flex" [ngClass]="{'selected': isSelected(user)}" (click)="selectUser(user.id)">
                            <div class="col-2"><img style="padding-bottom: 2px;" src="assets/img/Profile.svg" alt=""></div>
                            <div class="col-9 fs-14 font-weight-500" style="padding-top: 1px;"><span>{{user.name}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 px-2">
                <div class="d-flex align-items-center">
                    <div class="fs-14 font-weight-500 mb-2">{{ 'MODALS.WORKFLOW-USERS.SELECTED' | translate }}</div>
                </div>
                <div #selectedUserList class="d-flex flex-column" style="height: 250px; overflow: scroll;">
                    <div class="col-12 mb-1" *ngFor="let user of selectedUsers">
                        <div class="card" style="min-width: 150px;max-width: 440px;max-height: 222px;overflow: auto;">
                            <div>
                                <div style="padding: 15px;padding-top: 10px;padding-bottom: 10px;" class="d-flex">
                                    <div class="col-2"><img style="padding-bottom: 2px;" src="assets/img/Profile.svg" alt=""></div>
                                    <div class="col-9 fs-14 font-weight-500" style="padding-top: 1px;"><span>{{user.name}}</span></div>
                                    <div class="col-2" (click)="deleteUser(user.id)">
                                        <a style="cursor: pointer;"><img src="assets/img/trash.svg" alt=""></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer modal-card-footer">
            <button class="v-btn btn btn-sm btn-outline-secondary" (click)="close()">{{ 'MODALS.WORKFLOW-USERS.CLOSE' | translate }}</button>
            <button class="v-btn btn btn-sm btn-primary" (click)="saveWorkflowUsers()">{{ 'MODALS.WORKFLOW-USERS.SAVE' | translate }}</button>
        </div>
    </div>
</div>