<div class="room-lis">
    <div class="">
        <div class="pe-4 ps-3 filter-area">
            <div *ngIf="detailed">
                <div class="d-flex align-items-center">
                    <span class="me-2 fs-14 font-weight-600 ms-1">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.FILTER-BY' | translate }}</span>
                    <div class="me-2 col-4 filter-input">
                        <input 
                        class="field form-control" 
                        placeholder="{{ 'MAIN.ROOMS.ROOM_LIST.ROOM_NAME' | translate }}"
                        #name="ngModel" 
                        [(ngModel)]="roomName" 
                        name="roomName" 
                        [ngClass]="{'is-invalid':name.errors && name.touched}"
                        type="text" 
                        (ngModelChange)="onSearchTextChange($event)"
                    >
                    </div>

                    <div class="col-4 filter-input ms-1">
                        <ng-select 
                            [items]="userNames" 
                            name="users" 
                            [clearSearchOnAdd]="true" 
                            [closeOnSelect]="false"
                            placeholder="{{ 'MAIN.ROOMS.ROOM_LIST.ALL_USERS' | translate }}" 
                            [multiple]="true" 
                            [(ngModel)]="selectedUserFilter" 
                            (change)="onFilterChanged()"
                            style="font-size: 13px;"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
          </div>

        

        <div class="room-body top-gap-30">
            <div class="mt-5 fs-14 text-grey" *ngIf="rooms.length === 0 && !isLoading">{{ 'MAIN.ROOMS.ROOM_LIST.NO_ROOM_MESSAGE' | translate }}</div>
            <div class="" *ngIf="rooms.length !== 0">

                <div class="room-card" *ngFor="let room of rooms; index as i;" [ngClass]="{'button-pad': detailed}">
                    <div class="row">
                        <div class="col-7">
                            <div class="fs-18 font-weight-700">{{ room.room_data.name }}
                                <span *ngIf="room?.room_data?.session && room.room_data.session.active" class="badge bg-success fs-12 font-weight-400 ms-2">{{ 'MAIN.ROOMS.ROOM_LIST.ACTIVE' | translate }}</span>
                                <span *ngIf="room?.room_data?.session && !room.room_data.session.active && !room.room_data.disabled" class="badge badge-secondary fs-12 font-weight-400  ms-2">{{ 'MAIN.ROOMS.ROOM_LIST.PASSIVE' | translate }}</span>
                                <span *ngIf="room?.room_data && room.room_data.disabled" class="badge badge-danger ml-2">{{ 'MAIN.ROOMS.ROOM_LIST.DISABLED' | translate }}</span>
                            </div>
                            <div class="fs-14 text-dark-grey">{{ room.room_data.description }}</div>
                            
                        </div>

                        <div class="col-3">

                            <ng-container *ngIf="!room.room_data.isExpanded">
                                <div  *ngFor="let user of getRoomUsers(room.room_data.users) | slice:0:4">
                                    <!--<i class="mi mi-fiber-manual-record" style="font-size: 9px"
                                        [ngClass]="{ 'text-danger': !user.in_room, 'text-success': user.in_room }"></i>-->
                                    <span class="ms-1 fs-14 font-weight-700" [ngClass]="{'in-room-user': user.in_room, 'not-in-room-user': !user.in_room}">{{ user.name }}</span>
                                    <span *ngIf="user.guest" class="text-info ml-1">{{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }}</span>
                                    </div>
                            </ng-container>
                           
                            <ng-container *ngIf="room.room_data.isExpanded">
                                <div *ngFor="let user of getRoomUsers(room.room_data.users) ; let i = index" [ngClass]="{'in-room-user': user.in_room, 'not-in-room-user': !user.in_room}">
                                    <!--<i class="mi mi-fiber-manual-record" style="font-size: 9px"
                                    [ngClass]="{ 'text-danger': !user.in_room, 'text-success': user.in_room }"></i>-->
                                    <span class="ml-1" [ngClass]="{'in-room-user': user.in_room, 'not-in-room-user': !user.in_room}">{{ user.name }}</span>
                                    <span *ngIf="user.guest" class="text-info ml-1">{{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }}</span>
                                </div>
                              </ng-container>
    
                              <!-- <div class="pt-2">
                                <a  (click)="toggleItems($event,room.room_data)" class="text-muted pt-2 show-more"  *ngIf="getRoomUsers(room.room_data.users).length > 4 && !room.room_data.isExpanded">
                                    {{ 'MAIN.SHARED.SHOW_MORE' | translate:{count:getRoomUsers(room.room_data.users).length-4} }} <i class="mi mi-keyboard-arrow-down"></i>
                                </a>
                               
                              </div>
                                 <div>
                                  <a  (click)="toggleItems($event,room.room_data)" class="text-muted pt-2 show-more"  *ngIf="getRoomUsers(room.room_data.users).length > 4 && room.room_data.isExpanded">
                                    {{ 'MAIN.SHARED.SHOW_LESS' | translate }}  <i class="mi mi-keyboard-arrow-up"></i>
                                  </a>
                                 </div> -->
                        </div>

                        <div class="col-2 d-flex align-items-center justify-content-end">
                            <app-dropdown title="{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.TITLE' | translate }}" style="width: 120px;">
                                <li role="menuitem" *ngIf="!room.room_data.disabled">
                                    <a class="dropdown-item" (click)="onRoomToggle($event, room)">{{ 'MAIN.ROOMS.ROOM_LIST.DISABLE' | translate }}</a>
                                </li>
                                <li role="menuitem" *ngIf="room.room_data.disabled">
                                    <a class="dropdown-item" (click)="onRoomToggle($event, room)">{{ 'MAIN.ROOMS.ROOM_LIST.ENABLE' | translate }}</a>
                                </li>
                            </app-dropdown>

                            <!--<div class="dropdown-wrapper" dropdown>
                                <a class="btn btn-default name-wrapper" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                                    <img src="assets/img/more.svg" alt="" height="20px">
                                </a>
                                <div #stepListStepOptions class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink">
                                    <div>-->
                                        <!-- <a class="dropdown-item folder-item" (click)="edit(workOrder)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.RENAME' | translate }}</a> -->
                                        <!-- <a class="dropdown-item folder-item" (click)="onRoomDetails($event, room)">{{ 'MAIN.ROOMS.ROOM_LIST.DETAILS' | translate }}</a> -->
                                        <!--<a class="dropdown-item folder-item" (click)="onRoomEdit($event, room)" [class.disabled]="room.room_data.disabled">{{ 'MAIN.ROOMS.ROOM_LIST.EDIT' | translate }}</a>
                                        <a class="dropdown-item folder-item" (click)="onRoomToggle($event, room)">
                                            <span *ngIf="!room.room_data.disabled">{{ 'MAIN.ROOMS.ROOM_LIST.DISABLE' | translate }}</span>
                                            <span *ngIf="room.room_data.disabled">{{ 'MAIN.ROOMS.ROOM_LIST.ENABLE' | translate }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                   
                    <!-- <div class="col-12 col-lg-1" *ngIf="detailed">
                        <div class="btn-group d-flex">

                            <button type="button" class="btn btn-sm btn-warning" (click)="onRoomDetails($event, room)">
                                <i class="mi mi-remove-red-eye"></i><span class="d-inline d-lg-none">{{ 'MAIN.ROOMS.ROOM_LIST.DETAILS' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-info" (click)="onRoomEdit($event, room)" [class.disabled]="room.room_data.disabled">
                                <i class="mi mi-edit"></i><span class="d-inline d-lg-none">{{ 'MAIN.ROOMS.ROOM_LIST.EDIT' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm" [ngClass]="{'btn-secondary': !room.room_data.disabled, 'btn-primary': room.room_data.disabled}" (click)="onRoomToggle($event, room)">
                                <i class="mi mi-block" [ngStyle]="{ opacity: !room.room_data.disabled ? 0.4 : 1.0 }"></i>
                                <span *ngIf="!room.room_data.disabled" class="d-inline d-lg-none">{{ 'MAIN.ROOMS.ROOM_LIST.DISABLE' | translate }}</span>
                                <span *ngIf="room.room_data.disabled" class="d-inline d-lg-none">{{ 'MAIN.ROOMS.ROOM_LIST.ENABLE' | translate }}</span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #toggleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="v-card">

            <div class="modal-header d-flex align-items-center">
                <div class="modal-title font-weight-600" *ngIf="dataModel.disabled">{{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_ENABLE' | translate }}</div>
                <div class="modal-title font-weight-600" *ngIf="!dataModel.disabled">{{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_DISABLE' | translate }}</div>
                <div class="cursor-pointer"  (click)="callbacks.no()">
                    <img src="assets/img/close_white.svg" alt="">
                </div>
              </div>

            <div class="card-body mt-4">
                <div class="m-body padded mx-5">
                    <div class="fs-14" *ngIf="dataModel.disabled" [innerHTML]="'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_ENABLE' | translate:dataModel"></div>
                    <div class="fs-14" *ngIf="!dataModel.disabled" [innerHTML]="'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_DISABLE' | translate:dataModel"></div>
                </div>

                <div class="d-flex top-gap-30 justify-content-center">
                    <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary"
                    (click)="callbacks.no()">
                    {{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.CANCEL' | translate }}
                    </button>
                    <button type="button" *ngIf="dataModel.disabled"
                        class="v-btn vsight-button btn btn-primary"
                        (click)="callbacks.yes()">
                        {{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLE' | translate }}
                    </button>
                    <button type="button" *ngIf="!dataModel.disabled"
                    class="v-btn vsight-button btn btn-primary"
                    (click)="callbacks.yes()">
                    {{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLE' | translate }}
                    </button>
                </div>

            </div>
        </div>
        </div>
    </div>
</ng-template>