import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackbarService } from '@services/support/snackbar.service';
import { debounceTime, of, Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { WorkflowReport } from 'src/app/models/WorkflowReport';
import { LoaderService } from 'src/app/services/support/loader.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import {TranslateService} from "@ngx-translate/core";
import { BsModalService } from 'ngx-bootstrap/modal';
import { DetailPageService } from '@services/detail-page.service';
import { ReportComponent } from '../report/report.component';
import { environment } from 'src/environments/environment';
import { QrLinkModalComponent } from 'src/app/components/modals/qr-link-modal/qr-link-modal.component';
import languagesJson from '../../../../../assets/languages.json';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/support/app.service';

@Component({
  selector: 'app-user-reports',
  templateUrl: './user-reports.component.html',
  styleUrls: ['./user-reports.component.scss']
})
export class UserReportsComponent implements OnInit {

  @Output() workflowSubmit  = new EventEmitter<boolean>()

  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [
    "name",
    "version",
    "lastSavedDate",
    "submittedDate",
    "status",
    "statusIcon"
  ];

  reportList: WorkflowReport[] = []
  isLoading = false

  nameFilter = new UntypedFormControl()

  allUsers: User[] = []
  usersLoading: boolean = true
  subscriptions: Subscription[] = []
  reportUser = ''
  reportSelected
  loadedReport;

  myWorkflows: boolean = false
  showWorkflow: boolean = false
  boxHeight = 410;
  boxWidth = 700;
  workflow : any;

  isSubmitted = false;
  isAdmin: boolean = false
  hasEditorAccess: boolean = false

  savedDraft = false
  results = []
  reportId
  report

  isEwers = environment.design.continueWithQr
  savedPrefilled

  isStart

  statusGroup = new UntypedFormGroup({
    submitted: new UntypedFormControl(),
    prefilled: new UntypedFormControl(),
    incomplete: new UntypedFormControl()
  })
  filteredStatusReports = []
  filteredNameReports = []
  isLoadingFilter = false
  statusList = ['submitted', 'prefilled', 'incomplete']
  filteredStatusList = []

  isLoadingSubmit

  filterForm: UntypedFormGroup = new UntypedFormGroup({
    nameFilter: new UntypedFormControl(),
    formFieldFilter: new UntypedFormControl(),
    // statusFilter: new FormGroup({})
  })

  duplicatedReportId
  reportStatus
  isPrefilledUpdate

  isMenuOpened
  workflowMenuClicked
  contentListClicked
  wfName

  stepFilesClicked = false
  isStepFileAvailable = false

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private workflowService: WorkflowService,
    private loaderService:LoaderService,
    private snackBarService: SnackbarService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private detailPageService: DetailPageService,
    private router: Router
  ) {
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin
      this.hasEditorAccess = user.editorAccess
    }))
  }

  ngOnInit(): void {
    this.loaderService.show()

    this.getReportList();
    this.subscriptions.push(this.nameFilter.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.filterReports(res.toLowerCase());
      } else {
        this.dataSource = new MatTableDataSource(this.reportList)
      }
    }))

    this.subscriptions.push(this.statusGroup.valueChanges.subscribe(filterStatusRes => {
      this.filteredStatusReports = []
      this.filterStatus()
      
    }))

    this.subscriptions.push(this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      this.filterUserReports(res);
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  onAllWorkflows() {
    this.appService.isEditorModeSource.next(true)
    this.router.navigate(['/']);
  }

  onUserWorkflows() {
    this.appService.isEditorModeSource.next(false)
    this.router.navigate(['/']);
  }

  toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  contentListClickedChange(event) {
    this.contentListClicked = event
  }

  workflowSubmitted(event) {
    this.isSubmitted = event
  }

  close() {
    this.workflowMenuClicked = false
    if(this.isSubmitted){
      this.showWorkflow = false
      this.workflow = {}
      this.isSubmitted = false
      this.savedPrefilled = false
      this.workflowMenuClicked = false
      this.isStart = false
    }else if(this.reportStatus == 'prefilled') {
      const initialState= {
        textMessage: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-CLOSE-WORKFLOW`),
        confirmButtonText: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.CLOSE`),
        cancelButtonText:this.translateService.instant('MAIN.CONTENT.DASHBOARD.CANCEL'),
        instantClose:false
      };
      let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
      confirmModalRef.content.onClose.subscribe((res) => {
        if (res == true) {
          confirmModalRef.hide();
          this.showWorkflow = false
          this.workflow = {}
          this.isSubmitted = false
          this.isStart = false
          this.showWorkflow = false
          this.savedPrefilled = false
          this.workflowMenuClicked = false
          this.isStart = false
        }
      })
    }
    else{
      const initialState= {
        textMessage: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-CLOSE-WORKFLOW`),
        confirmButtonText: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.CLOSE`),
        cancelButtonText:this.translateService.instant('MAIN.CONTENT.DASHBOARD.SAVE-AS-DRAFT'),
        instantClose:false
      };
      let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
      confirmModalRef.content.onClose.subscribe((res) => {
        if (res == true) {
          confirmModalRef.hide();
          this.showWorkflow = false
          this.workflow = {}
          this.isSubmitted = false
          this.isStart = false
        }else{
          this.savedDraft = true
          this.isStart = false
        }
        this.savedPrefilled = false
      })
    }
    this.loaderService.show()
    this.getReportList()
  }

  langChanged(lang) {
    this.wfName = lang
  }

  setWorkflowName(workflow) {
    let workflowLangs = []
    let userDefaultLang = localStorage.getItem('userDefaultLanguage')
    if(workflow.translations) {
      Object.keys(workflow.translations).forEach(res => {
        let workflowLang = languagesJson.languages.find(lang => lang.code == res)
        if(workflowLang) {
          workflowLangs.push(workflowLang)
        }
      })
      let isLang = workflowLangs.find(lang => lang.code == userDefaultLang)
      if(isLang) {
        this.wfName = workflow.translations[isLang.code][workflow.name]
      }else{
        this.wfName = workflow.translations[workflow.defaultLanguage][workflow.name]
      }
    }else{
      this.wfName = workflow.name
    }
  }


  openWorkflow(row) {
    if(row.status == 'incomplete' || row.status == 'prefilled') {
      this.loaderService.show()
      this.results = row.results
      this.reportId = row.id
      this.report = row
      this.reportStatus = row.status

      let wf;
      this.workflowService.getMyWorkflows(true).then(workflows => {
        this.loaderService.hide()
        wf = workflows.find(res => res.id == row.workflowId)
        this.workflow = wf
        this.showWorkflow = true
        this.setWorkflowName(wf)
      })
      .catch(err=> this.loaderService.hide())
    }
  }

  openPrefilledWorkflow(row) {

    const initialState= {
      headerTitle: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-PREFILLED-EDIT-TITLE`),
      textMessage: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-PREFILLED-EDIT`),
      confirmButtonText: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.CONTINUE`),
      cancelButtonText:this.translateService.instant('MAIN.CONTENT.DASHBOARD.CANCEL'),
      confirmButtonStyle: 'primary'
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        confirmModalRef.hide();
        this.loaderService.show()
        this.results = row.results
        this.reportId = row.id
        this.report = row

        let wf;
        this.workflowService.getMyWorkflows(true).then(workflows => {
          this.loaderService.hide()
          wf = workflows.find(res => res.id == row.workflowId)
          this.workflow = wf
          this.showWorkflow = true
        })
        .catch(err=> this.loaderService.hide())
        
      }else{
        confirmModalRef.hide();
      }
    })

    
  }

  getSingleReport(reportId) {
    this.loaderService.show()
    return this.workflowService.getUserWorkflowReports().then(reports => reports.find((report) => reportId == report.id))
    .catch(err=> this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.ERROR')))
    .finally(() => this.loaderService.hide())
  }

  getReportList(status?) {
    this.isLoading = true
    this.workflowService.getUserWorkflowReports()
    .then((reports) => {
      this.loaderService.hide()
      this.isLoading = false
      this.reportList = reports
      this.reportList.forEach(report => {
        report.createdAt = new Date(report.createdAt)
      })
      this.reportList.sort(function(a, b) { 
        if (a.updatedAt < b.updatedAt) {return 1}
        else if (a.updatedAt > b.updatedAt){return -1}
        return 0;
      })
      this.dataSource = new MatTableDataSource(this.reportList)
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case "name":
              return item.workflowName;
          default:
            if(item.updatedAt){
              return item.updatedAt;
            }
        }
      };
      this.dataSource.sort = this.sort;
    })
  }

  filterReports(filterData?){
    let filteredData = []
    filteredData = this.reportList.filter(report => {
      if(report){
        if(report.workflowName){
          return report.workflowName.toLowerCase().includes(filterData.toLowerCase())
        }
        return null
      }
      else{
       return filteredData
      }
       })
    this.dataSource = new MatTableDataSource(filteredData);
  }

  filterUserReports(filterResponse) {
    let filteredData = this.reportList.filter((report) => (!filterResponse.nameFilter || (report.workflowName ? report.workflowName.toLocaleLowerCase().includes(filterResponse.nameFilter.toLocaleLowerCase())  : null)) && (!filterResponse.formFieldFilter || (report['filterValue'] ? report['filterValue'].toLocaleLowerCase().includes(filterResponse.formFieldFilter.toLocaleLowerCase())  : null)))
    if(this.filteredStatusList.length) {
      filteredData = filteredData.filter(report => this.filteredStatusList.includes(report.status))
    }
    this.dataSource = new MatTableDataSource(filteredData);
  }

  savedDraftChage(event) {
    this.savedDraft = event
  }

  openReport(report) {
    if(report.status == 'submitted') {
      this.loaderService.show()
      this.reportSelected = true
      this.workflowService.getWorkflowReport(report.id)
      .then(result => {
        this.loadedReport = report.id;
          const [instance, onClose] = this.detailPageService.loadComponent(ReportComponent, { report: report.id, reportUser: this.getUsersName(report.userId)})
          instance.report = report
          instance.reportUser = this.getUsersName(report.userId)
          instance.workflowPublishedVersion = result?.workflow
          onClose.then(() => {
            this.reportSelected = false
            this.loadedReport = null;
          })
      })
      .finally(() => this.loaderService.hide())
    }
  }

  getUsersName(uid) {
    const user = this.allUsers.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }

  sendPrefilledWorfklow() {
    this.savedPrefilled = true
  }

  savedPrefilledChange(event) {
    this.savedPrefilled = event
  }

  isWorkflowStart(event) {
    this.isStart = event
  }

  contentListStatus() {
    this.contentListClicked = !this.contentListClicked
  }

  deleteStatus(status) {
    let statusIndex = this.filteredStatusList.findIndex(res => res == status)
    this.filteredStatusList.splice(statusIndex, 1)
    this.statusGroup.controls[status].patchValue(false)
    
  }

  clearAll() {
     this.filteredStatusList = []
     Object.keys(this.statusGroup.controls).forEach(statusControl => {
      this.statusGroup.controls?.[statusControl].patchValue(false)
    })
  }

  filterStatus(){
    let filteredData = []
    if(!this.filteredStatusList.length) {
      filteredData = this.reportList
    }else{
      filteredData = this.reportList.filter(report => {
        if(report){
          if(report.status){
            return this.filteredStatusList.includes(report.status)
          }else{
            return null
          }
        }
        else{
         return filteredData
        }
         })
    }

    if(this.filterForm.controls['nameFilter'].value || this.filterForm.controls['formFieldFilter'].value) {
      filteredData = filteredData.filter((report) => (!this.filterForm.controls['nameFilter']?.value || (report.workflowName ? report.workflowName.toLocaleLowerCase().includes(this.filterForm.controls['nameFilter']?.value.toLocaleLowerCase())  : null)) && (!this.filterForm.controls['formFieldFilter']?.value || (report['filterValue'] ? report['filterValue'].toLocaleLowerCase().includes(this.filterForm.controls['formFieldFilter']?.value.toLocaleLowerCase())  : null)))
    }
    this.dataSource = new MatTableDataSource(filteredData);
  }

  selectStatus(status) {
    if( this.filteredStatusList.includes(status) ) {
      let statusIndex = this.filteredStatusList.findIndex(res => res == status)
      this.filteredStatusList.splice(statusIndex, 1)
    }else{
      this.filteredStatusList.push(status)
    }
  }

  increaseFilterValue(report, result, res) {
    let count = 1
    Object.keys(result.data[res].value).forEach(formValue => {
      if(result.data[res].value[formValue] == report.filterValue) {
        let reportFilterValue
        if(report.filterValue.includes('/')) {
          reportFilterValue = report.filterValue.split('/')[0]
        }else{
          reportFilterValue = report.filterValue
        }

        this.reportList.forEach(singleReport => {
            if(singleReport.filterValue) {
              if(singleReport.filterValue.includes('/')) {
                let singleReportVal = singleReport.filterValue.split('/')[0]
                if(reportFilterValue == singleReportVal) {
                  count++
                }
              }else{
                if(reportFilterValue == singleReport.filterValue) {
                  count++
                }
              }
            }
        })

        if(count){
          let val = report.filterValue.split('/')
          let newVal = val[0]+ '/' + count
          return result.data[res].value[formValue] = newVal
        }else{
          return report.filterValue
        }
      }
    })
  }

  duplicateReport(report) {
    this.isLoading = true
    this.loaderService.show()
    // increase serial number
    report.results.forEach(result => {
       Object.keys(result.data).forEach(res => {
        if(result.data[res]?.type == 'form') {
          if(report.filterValue) {
            let val = this.increaseFilterValue(report, result, res)

            Object.keys(result.data[res].value).forEach(formValue => {
              if(result.data[res].value[formValue] == report.filterValue) {
                result.data[res].value[formValue] = val

              }
            })
          }
        }
       })
    })

    let saveObject = {
      accountId : report.accountId ,
      updatedAt : '',
      startTime: report.startTime,
      endTime: report.endTime,
      duration : report.duration,
      results : report.results,
      userId: '',
      version : report.version,
      workflowId : report.workflowId,
      status : 'incomplete',
      lastSeenStep: report.lastSeenStep,
      guest : {
        email : report.guestData?.email ? report.guestData.email : null
      },
      contentLanguage: report.contentLanguage,
      continueTimes: []
    }
    this.saveReport(saveObject)
    this.workflowSubmit.emit(true)
  }

  private saveReport(saveObject) {
    
    return this.workflowService.saveWorkflowReport(saveObject, null, true, null).then(res => {
      if(res) {
        this.duplicatedReportId = res.data.reportId
      }
    })
    .catch((error) => {
      this.isSubmitted = false
      if(error.error?.message == 'permission-denied') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-NOT-FOUND'))
      }
      if(error.error?.message == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.getReportList()
      this.isLoadingSubmit = false
    })
  }

  deleteReport(report) {
    const initialState= {
      textMessage: this.translateService.instant('MAIN.CONTENT.DASHBOARD.DELETE-REPORT-TEXT'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.DELETE'),
      cancelButtonText:this.translateService.instant('MODALS.ALERT.CANCEL'),
      confirmButtonStyle: 'danger',
      headerTitle: this.translateService.instant('MAIN.CONTENT.DASHBOARD.DELETE-REPORT-TITLE'),
      instantClose:false
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        this.loaderService.show()
        this.workflowService.deleteWorkflowReport(report.id).then(res=> {
          if(res) {
            confirmModalRef.hide();
            this.getReportList()
            // let deletedReport = this.reportList.find(rep => rep.id == report.id)
            // const newList = this.reportList.filter(repo => repo.id !== deletedReport.id)
            // this.dataSource = new MatTableDataSource(newList);
            // this.loaderService.hide()
            // this.reportId = null
            // this.results = null
          }
        })
      }
    })
  }

  showQr(report) {
    const initialState= {
      qrData : report.continueWithQr?.qr ? report.continueWithQr?.qr : null,
      link : report.continueWithQr?.link ? report.continueWithQr?.link : null,
    };
    let confirmModalRef = this.modalService.show(QrLinkModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    confirmModalRef.content.onClose.subscribe((res) => {
      confirmModalRef.hide();

    })
  }

  changeToPrefilled(report) {
    const initialState= {
      textMessage: this.translateService.instant('MAIN.CONTENT.DASHBOARD.SUBMITTED-REPORT-TO-PREFILLED-TEXT'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.CONTINUE'),
      confirmButtonStyle: 'primary',
      headerTitle: this.translateService.instant('MAIN.CONTENT.DASHBOARD.SUBMITTED-REPORT-TO-PREFILLED-TITLE'),
      instantClose:false
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    confirmModalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.loaderService.show()
        this.workflowService.workflowReportToPrefilled(report.id).then(response => {
          report.status = 'prefilled'
          this.getReportList()
        })
        .catch(err => console.log(err))
        .finally(() => {})
      }
      confirmModalRef.hide();
    })
  }

  editPrefilledReport(element) {
    this.openWorkflow(element)
  }

  switchWorkflowMenu() {
    this.workflowMenuClicked = !this.workflowMenuClicked
  }

  workflowMenuClickedChange(event) {
    this.workflowMenuClicked = event
  }

  stepFileAvailability(event){
    this.isStepFileAvailable = event
  }

  showStepFiles() {
    this.stepFilesClicked = true
  }

}