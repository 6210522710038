import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-empty',
  template: `<div class="d-flex fs-12 justify-content-center" style="padding-top: 5%;color: #314B76;font-weight:400">
  <div class="d-flex align-items-end">
     <img src="assets/img/info_icon.svg" style="margin-right: 5px;">
     <div style="margin-bottom:1px">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.EMPTY' | translate }}</div>
  </div>
  </div>`
})
export class FormlyFieldCustomEmpty extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}