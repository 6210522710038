import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-date',
  template: `
  <div class="d-flex fs-15 font-weight-600" style="height: 90%;" *ngIf="to['detail']">{{to.label}}</div>
  <div class="d-flex align-items-center" style="height: 90%;">
      <input #startDate="bsDatepicker"  [formControl]="formControl" name="startDate" class="form-control custom-form-control" id="form-control" bsDatepicker placeholder="{{to.placeholder}}" 
      autocomplete="off"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" >
      <span style="margin-left:-21px">
      <img src="assets/img/datepicker-black.svg">
      </span>
</div>
       
  `,
})
export class FormlyFieldCustomDate extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}