import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DBConnections } from './electron-services/DBConnections';
import { BehaviorSubject, filter, firstValueFrom, from, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '@models/User';

import { BlockBlobClient } from '@azure/storage-blob';
import { DBSource } from '@models/DBSource';
import { FirebaseSource } from '@models/FirebaseSource';
import { SocketioSource } from '@models/SocketioSource';

import { AuthUser } from '@models/AuthUser';

@Injectable({
    providedIn: 'root'
})
export class DbService implements DBConnections {

  private source: DBSource = null;

  isAuthenticated: Observable<boolean>;
  private isAuthenticatedSource = new BehaviorSubject<boolean>(null);
  authState: Observable<AuthUser>;
  private authStateSource = new BehaviorSubject<AuthUser>(null);
  isConnected: Observable<boolean>;
  private isConnectedSource = new BehaviorSubject<boolean>(null);

  private stateSubscriptions: Subscription = null;

  currentUser: User = null;
  currentUserData: Observable<User>;
  currentUserSource: BehaviorSubject<User> = new BehaviorSubject(null);

  userForGuardSource: Subject<User> = new Subject();
  guestForGuardSource: Subject<any> = new Subject();

  private storage;
  private isFirefox: boolean;

  constructor(
    private http: HttpClient,
  ) {
    this.isAuthenticated = this.isAuthenticatedSource.asObservable().pipe(filter(c => c !== null));
    this.authState = this.authStateSource.asObservable();
    this.currentUserData = this.currentUserSource.asObservable();
    this.isConnected = this.isConnectedSource.asObservable().pipe(filter(c => c !== null));

    const type = sessionStorage.getItem("sourceType");
    this.setSourceType(type ? <"firebase"|"socket">type : "firebase");
  }

  setSourceType(sourceType: "firebase" | "socket") {
    if (this.stateSubscriptions) { this.stateSubscriptions.unsubscribe() }

    if (this.source) {
      this.source.destroy();
    }

    this.source = sourceType === "socket" ? new SocketioSource(this.http) : new FirebaseSource(this.http, this.isFirefox);

    this.stateSubscriptions = this.source.isAuthenticated.subscribe(ia => this.isAuthenticatedSource.next(ia));
    this.stateSubscriptions.add(this.source.authState.subscribe(as => this.authStateSource.next(as)));
    this.stateSubscriptions.add(this.source.currentUser.subscribe(cu => {
      this.currentUser = cu;
      this.currentUserSource.next(cu);
      this.userForGuardSource.next(cu);
      this.guestForGuardSource.next(cu ? true : false);
    }));
    this.stateSubscriptions.add(this.source.isConnected.subscribe(ic => this.isConnectedSource.next(ic)));
  }

  getSourceType(): "firebase" | "socket" {
    return this.source ? this.source.type : null;
  }

  getToken() {
    return this.currentUser.token
  }

  loginToFirebase(token: string): Promise<User> {
    return this.source.login(token, true, false);
    // return this.afAuth.signInWithCustomToken(token).then(userCredential => userCredential.user);
  }

  getAuth(): Observable<boolean> {
    return this.isAuthenticated
  }

  login(account_name: string, username: string, password: string): Promise<any> {
    const url = environment.endPoints.login;
    return this.http.post<any>(url, { account_name: account_name, username: username, password: password, for_workflow: true}).toPromise();
  }

  logout(): Promise<void> {
    return this.source.logout();
  }

  verifyLoginCode(account_name: string, username: string, password: string, code: string): Promise<any> {
    const url = environment.endPoints.login;
    return this.http.post<any>(url, { account_name: account_name, username: username, password: password, verification: code }).toPromise();
  }

  getAccountData() {
    const url = environment.endPoints.getAccountData
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token }))
  }

  getAllWorkflows(returnLicense?) {
    const url = environment.endPoints.getAllWorkflows
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, returnLicense: returnLicense })).then(res => res.data)
  }

  getMyWorkflows(returnVideoFlowWorkflows?, returnLicense?, token?) {
    const url = environment.endPoints.getWorkflowList
    return firstValueFrom(this.http.post<any>(url, { token: token ? token : this.currentUser?.token , returnLicense: returnLicense, returnVideoFlowWorkflows: returnVideoFlowWorkflows }, { headers: { token: token ? token : this.currentUser?.token } })).then(res => res.data)
  }

  getAllWorkflowReports(returnLicense?) {
    const url = environment.endPoints.getAllWorkflowReports
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, returnLicense: returnLicense })).then(res => res.data)
  }

  getUserWorkflowReports() {
    const url = environment.endPoints.getUserWorkflowReports
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  getAllUsersForWorkflow() {
    const url = environment.endPoints.getAllUsersForWorkflow
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  saveDisabledUsers(insertedUsers, deletedUsers) {
    const url = environment.endPoints.updateWorkflowAccountUsers
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, insertedUsers: insertedUsers, deletedUsers: deletedUsers }))
  }

  createWorkflow(workflow) {
    const url = environment.endPoints.createWorkflow
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflow: workflow })).then(res => res.data)
  }

  workflowGenerate(prompt) {
    const url = environment.endPoints.workflowGenerate
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, prompt: prompt })).then(res => res.data)
  }

  createWorkflowDraft(workflowId: string) {
    const url = environment.endPoints.createWorkflowDraft
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId })).then(res => res.data)
  }

  publishWorkflowDraft(workflowId: string) {
    const url = environment.endPoints.publishWorkflowDraft
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId }))
  }

  getPublishedWorkflowVersion(workflowId: string, version: number) {
    const url = environment.endPoints.getPublishedWorkflowVersion
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId, version: version })).then(res => res.data)
  }

  getAllPublishedWorkflowVersions(workflowId: string) {
    const url = environment.endPoints.getAllPublishedWorkflowVersions
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId })).then(res => res.data)
  }

  getWorkflowReport(reportId: string) {
    const url = environment.endPoints.getWorkflowReport
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, reportId: reportId, returnWorkflow: true })).then(res => res.data)
  }

  setWorkflowDraft(workflow: any) {
    const url = environment.endPoints.setWorkflowDraft
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflow.id, workflowDraft: workflow.draft }))
  }

  updateWorkflowUsers(workflowId: string, users: string[]) {
    const url = environment.endPoints.updateWorkflowUsers
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId, users: users }))
  }

  deleteWorkflow(workflowId: string) {
    const url = environment.endPoints.deleteWorkflow
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId }))
  }

  translateWorkflow(source,target, texts) {
    const url = environment.endPoints.translate
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, source: source, target: target, texts:texts, isHtml: true }))
  }

  getWorkflowAccountLabels() {
    const url = environment.endPoints.getWorkflowAccountLabels
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  setWorkflowAccountLabels(workflowLabels: string[]) {
    const url = environment.endPoints.setWorkflowAccountLabels
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowLabels: workflowLabels }))
  }

  saveWorkflowReport(workflow, token?, returnLicense?, baseUrl?) {
    const url = environment.endPoints.createWorkflowReport
    return firstValueFrom(this.http.post<any>(url, { token: token ? token : this.currentUser.token, data: workflow, returnLicense: returnLicense, baseUrl: baseUrl }))
  }

  updateWorkflowReport(workflow, reportId, returnLicense?, token?, baseUrl?) {
    const url = environment.endPoints.updateWorkflowReport
    return firstValueFrom(this.http.post<any>(url, { token: token ? token : this.currentUser.token, id: reportId, data: workflow, returnLicense: returnLicense, baseUrl: baseUrl }))
  }

  deleteWorkflowReport(reportId) {
    const url = environment.endPoints.deleteWorkflowReport
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token,reportId: reportId }))
  }

  getWorkflowAccountExtraDetails() {
    const url = environment.endPoints.getWorkflowAccountExtraDetails
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  uploadWorkflowFiles(workflowId, formData, token?) {
    const url = `https://cdn.vsight.app/upload-workflow-files/${workflowId}?env=${environment.name}`
    if(token) {
      return this.http.post(url, formData, {headers: {'authorization': `Bearer ${token}`}})
    }else{
      return this.http.post(url, formData, {headers: {'authorization': `Bearer ${this.currentUser.token}`}})
    }
  }

  uploadWorkflowPpeFiles(workflowId, formData, token?) {
    const url = `https://cdn.vsight.app/workflow-ppe-recognition/${workflowId}?env=${environment.name}`
    if(token) {
      return this.http.post(url, formData, {headers: {'authorization': `Bearer ${token}`}})
    }else{
      return this.http.post(url, formData, {headers: {'authorization': `Bearer ${this.currentUser.token}`}})
    }
  }

  uploadWorfklowFile(file: File, workflowId: string, stepId: string, fileId: string): Promise<string> {
    const extension = file.name.split('.').pop().toLowerCase()
    const storageRef = this.storage.ref(`accounts/${this.currentUser.account_id}/workflows/${workflowId}/steps/${stepId}`)
    const uploadTask = storageRef.child(fileId + '.' + extension).put(file, {customMetadata: { name: file.name }, contentType: file.type})
    return uploadTask.then(snapshot => snapshot.ref.getDownloadURL())
  }

  uploadVideoForWorkflow(formData) {
    const url = `https://cdn.vsight.app/upload-video-for-workflow?env=${environment.name}`
    return this.http.post(url, formData, { headers: {'authorization': `Bearer ${this.currentUser.token}`}})
  }

  uploadVideoForWorkflowProgress2(file: File, progressCallback: (progress: number) => void) {
    const devUrl = `https://vs-video-split-ai-vs-video-split-dev.azurewebsites.net/generateSASToken`
    const prodUrl = `https://vs-video-split-ai.azurewebsites.net/generateSASToken`
    const url = environment.name == 'dev' ? devUrl : prodUrl

    const extension = file.name.split('.').pop().toLowerCase();
    const fileName = this.createPushId() + "." + extension
    return this.http.post<any>(url, { token: this.currentUser.token, env: environment.name, fileName: fileName, reportProgress: true, })
    .pipe(switchMap(response => {
      const blockBlobClient = new BlockBlobClient(response.data.sasUrl);
      return from(blockBlobClient.uploadBrowserData(file, {
        onProgress: (progress) => {
          if (progressCallback) {
            const percentage = Math.round((progress.loadedBytes / file.size) * 100);
            progressCallback(percentage);
          }
        }
      })).pipe(map(rs => {
        return {
          data: {
            files: [{ url: response.data.sasUrlPublic, key: fileName }]
          }
        }
      }))
    }));
  }

  uploadVideoForWorkflowProgress(formData: FormData, progressCallback: (progress: number) => void): Observable<any> {
    const url = `https://cdn.vsight.app/upload-video-for-workflow?env=${environment.name}`;
    return this.http.post(url, formData, {
      headers: new HttpHeaders({
        'authorization': `Bearer ${this.currentUser.token}`
      }),
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (event.total) {
              const progress = Math.round((100 * event.loaded) / event.total);
              progressCallback(progress);
            }
            break;
          case HttpEventType.Response:
            return event.body;
        }
      })
    );
  }

  workflowQrGenerate(workflowId: string, baseUrl: string, password: string){
    const url = environment.endPoints.workflowQrGenerate
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId, baseUrl:baseUrl, password: password  }))
  }

  workflowQrGetData(linkId: string){
    const url = environment.endPoints.workflowQrGetData
    return firstValueFrom(this.http.post<any>(url, { linkId: linkId }))
  }

  workflowQrGetCredentials(linkId: string, email: string, password?:string){
    const url = environment.endPoints.workflowQrGetCredentials
    let obj = {
      linkId: linkId,
      email: email 
    }
    if(password && password !== '') {
      obj['password'] = password
    }
    return firstValueFrom(this.http.post<any>(url, obj))
  }

  workflowQrDelete(workflowId: string){
    const url = environment.endPoints.workflowQrDelete
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId }))
  }

  switchToWorkflow(idToken: string){
    const url = environment.endPoints.switchToWorkflow
    return this.http.post<any>(url,{ token: idToken}).toPromise();
  }

  sendWorkflowReportToSign(baseUrl, reportId, emails, token?){
    const url = environment.endPoints.sendWorkflowReportToSign
    return firstValueFrom(this.http.post<any>(url, { token: token ? token : this.currentUser.token, baseUrl: baseUrl, reportId: reportId, emails: emails }))
  }

  sendWorkflowReportAfterApproval(baseUrl, reportId, emails, token?){
    const url = environment.endPoints.sendWorkflowReportAfterApproval
    return firstValueFrom(this.http.post<any>(url, { token: token ? token : this.currentUser.token, baseUrl: baseUrl, reportId: reportId, emails: emails }))
  }

  getWorkflowReportLinkData(uniqueId){
    const url = environment.endPoints.getWorkflowReportLinkData
    return firstValueFrom(this.http.post<any>(url, { linkId: uniqueId }))
  }

  signWorkflowReport(formData, linkId) {
    const envUrl = environment.endPoints.signWorkflowReport
    const url = `${envUrl}?linkId=${linkId}`
    return this.http.post(url, formData)
  }

  workflowRemoteIntegrationGetId(baseUrl, workflowId){
    const url = environment.endPoints.workflowRemoteIntegrationGetId
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowId: workflowId, remoteUrl: baseUrl }))
  }

  workflowRemoteIntegrationUsers(linkId, experts) {
    const url = environment.endPoints.workflowRemoteIntegrationUsers
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, integration_id: linkId, users: experts }))
  }

  workflowRemoteIntegrationInfo(linkId) {
    const url = environment.endPoints.workflowRemoteIntegrationInfo
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, integration_id: linkId}))
  }

  workflowReportToPrefilled(reportId) {
    const url = environment.endPoints.workflowReportToPrefilled
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, reportId: reportId}))
  }
  // createWorkflowFromVideo(workflowId, videoUrl?) {
  //   const url = `https://vs-video-split-ai.azurewebsites.net/transcribe`
  //   const data = {
  //     language : 'en-US',
  //     videoUrl : videoUrl,
  //     webhookUrl: '123',
  //     workflowId: workflowId,
  //     token: this.currentUser.token
  //   }
  //   return this.http.post(url, data)
  // }

  // getTextResult(transcriptionId, transcriptions, workflowId, systemPrompt) {
  //   const url = `https://vs-video-split-ai.azurewebsites.net//createGuideWithAI`
  //   let transcriptionMockData = [
  //     {
  //         "start": "00:00:00",
  //         "end": "00:00:33",
  //         "text": "Whether you're new to cycling and about to purchase your first bike, or an experienced rider treating yourself to an upgrade, it's not unlikely that your next bike purchase could well be from an online retailer. Online retailers selling direct to consumer are growing and sell thousands of bikes globally. When your bike arrives, it'll often be in a box like this in a partially assembled state, which often means that it's been built and serviced by a professional mechanic and then partially disassembled so that it will fit inside the box for shipping."
  //     },
  //     {
  //         "start": "00:00:34",
  //         "end": "00:00:40",
  //         "text": "Now, in this video we're going to show you the last few steps of the process so that you can go from this to this."
  //     },
  //     {
  //         "start": "00:00:42",
  //         "end": "00:00:58",
  //         "text": "Now we know that not everyone owns a work stand, and while using a work stand will make building your new bike a little bit easier, you can absolutely do it without one. So that's what I'm going to show you how to do. It's really straightforward, should only take about 20 minutes. So let's get going."
  //     },
  //     {
  //         "start": "00:01:11",
  //         "end": "00:01:33",
  //         "text": "You only need a few basic tools to do this job. A set of Allen keys so that you can adjust the various bolts on the bike. A torque wrench so that you can get things to the correct torque. Particularly important on carbon bikes. Some cutters or scissors so that you can remove packaging easily. A tape measure is useful for setting up your bike measurements, especially if you know them already."
  //     },
  //     {
  //         "start": "00:01:33",
  //         "end": "00:01:48",
  //         "text": "And also some grease or carbon assembly paste can also come in handy too. If you're new to cycling and don't currently own any of these tools then I would strongly recommend that you invest in them as their basic tools that are incredibly useful and will save you money in the long run."
  //     },
  //     {
  //         "start": "00:01:50",
  //         "end": "00:02:11",
  //         "text": "When you first open your box top tip, look out for industrial staples that are used to clamp it shut. These can be very sharp and you don't want to catch your thumb on them. Now inside you'll typically find one of the wheels removed and strapped to the side of the bike. You'll also probably have a box with important bits in, so I'm just going to take that out."
  //     },
  //     {
  //         "start": "00:02:14",
  //         "end": "00:02:16",
  //         "text": "Put that to one side."
  //     },
  //     {
  //         "start": "00:02:17",
  //         "end": "00:02:23",
  //         "text": "We'll need that later and then lift the entire bike out-of-the-box."
  //     },
  //     {
  //         "start": "00:02:28",
  //         "end": "00:02:46",
  //         "text": "Nice colour, isn't it? Now going to set about removing the packaging from the bike, and you might need your scissors or cutters for this. Fortunately on this bike a lot of the bits are connected by these Velcro pads and straps, which is great because they're reusable. I'd also recommend that you carefully remove the packaging."
  //     },
  //     {
  //         "start": "00:02:47",
  //         "end": "00:02:47",
  //         "text": "Keep it to what?"
  //     }
  // ]
  //   const data = {
  //     transcription : {
  //       transcriptions : transcriptions
  //     },
  //     transcriptionId: transcriptionId,
  //     isGPT4: true,
  //     systemPrompt: systemPrompt,
  //     workflowId: workflowId,
  //     token: this.currentUser.token
  //   }
  //   return this.http.post(url, data)
  // }

  // checkTranscriptionStatus(transcriptionId, workflowId) {
  //   const url = `https://vs-video-split-ai.azurewebsites.net/checkTranscriptionStatus`
  //   const data = {
  //     transcriptionId : transcriptionId,
  //     workflowId : workflowId,
  //     token: this.currentUser.token
  //   }
  //   return this.http.post(url, data)
  // }

  // getWorkflowById(workflowId) {
  //   const url = environment.endPoints.getWorkflowWithDraft
  //   const data = {
  //     workflowId : workflowId,
  //     token: this.currentUser.token
  //   }
  //   return this.http.post(url, data)
  // }

  createWorkflowFromVideo(workflowId, videoUrl?) {
    const devUrl = `https://vs-video-split-ai-vs-video-split-dev.azurewebsites.net/transcribe`
    const prodUrl = `https://vs-video-split-ai.azurewebsites.net/transcribe`
    const url = environment.name == 'dev' ? devUrl : prodUrl
    const data = {
      language : 'en-US',
      videoUrl : videoUrl,
      webhookUrl: '123',
      workflowId: workflowId,
      token: this.currentUser.token,
      env: environment.name
    }
    return this.http.post(url, data)
  }

  getTextResult(transcriptionId, transcriptions, workflowId, systemPrompt) {
    const devUrl = `https://vs-video-split-ai-vs-video-split-dev.azurewebsites.net/createGuideWithAI`
    const prodUrl = `https://vs-video-split-ai.azurewebsites.net/createGuideWithAI`
    const url = environment.name == 'dev' ? devUrl : prodUrl

    let transcriptionMockData = [
      {
          "start": "00:00:00",
          "end": "00:00:33",
          "text": "Whether you're new to cycling and about to purchase your first bike, or an experienced rider treating yourself to an upgrade, it's not unlikely that your next bike purchase could well be from an online retailer. Online retailers selling direct to consumer are growing and sell thousands of bikes globally. When your bike arrives, it'll often be in a box like this in a partially assembled state, which often means that it's been built and serviced by a professional mechanic and then partially disassembled so that it will fit inside the box for shipping."
      },
      {
          "start": "00:00:34",
          "end": "00:00:40",
          "text": "Now, in this video we're going to show you the last few steps of the process so that you can go from this to this."
      },
      {
          "start": "00:00:42",
          "end": "00:00:58",
          "text": "Now we know that not everyone owns a work stand, and while using a work stand will make building your new bike a little bit easier, you can absolutely do it without one. So that's what I'm going to show you how to do. It's really straightforward, should only take about 20 minutes. So let's get going."
      },
      {
          "start": "00:01:11",
          "end": "00:01:33",
          "text": "You only need a few basic tools to do this job. A set of Allen keys so that you can adjust the various bolts on the bike. A torque wrench so that you can get things to the correct torque. Particularly important on carbon bikes. Some cutters or scissors so that you can remove packaging easily. A tape measure is useful for setting up your bike measurements, especially if you know them already."
      },
      {
          "start": "00:01:33",
          "end": "00:01:48",
          "text": "And also some grease or carbon assembly paste can also come in handy too. If you're new to cycling and don't currently own any of these tools then I would strongly recommend that you invest in them as their basic tools that are incredibly useful and will save you money in the long run."
      },
      {
          "start": "00:01:50",
          "end": "00:02:11",
          "text": "When you first open your box top tip, look out for industrial staples that are used to clamp it shut. These can be very sharp and you don't want to catch your thumb on them. Now inside you'll typically find one of the wheels removed and strapped to the side of the bike. You'll also probably have a box with important bits in, so I'm just going to take that out."
      },
      {
          "start": "00:02:14",
          "end": "00:02:16",
          "text": "Put that to one side."
      },
      {
          "start": "00:02:17",
          "end": "00:02:23",
          "text": "We'll need that later and then lift the entire bike out-of-the-box."
      },
      {
          "start": "00:02:28",
          "end": "00:02:46",
          "text": "Nice colour, isn't it? Now going to set about removing the packaging from the bike, and you might need your scissors or cutters for this. Fortunately on this bike a lot of the bits are connected by these Velcro pads and straps, which is great because they're reusable. I'd also recommend that you carefully remove the packaging."
      },
      {
          "start": "00:02:47",
          "end": "00:02:47",
          "text": "Keep it to what?"
      }
  ]
    const data = {
      transcription : {
        transcriptions : transcriptions
      },
      transcriptionId: transcriptionId,
      isGPT4: true,
      systemPrompt: systemPrompt,
      workflowId: workflowId,
      token: this.currentUser.token,
      env: environment.name
    }
    return this.http.post(url, data)
  }

  checkTranscriptionStatus(transcriptionId, workflowId) {
    const devUrl = `https://vs-video-split-ai-vs-video-split-dev.azurewebsites.net/checkTranscriptionStatus`
    const prodUrl = `https://vs-video-split-ai.azurewebsites.net/checkTranscriptionStatus`
    const url = environment.name == 'dev' ? devUrl : prodUrl

    const data = {
      transcriptionId : transcriptionId,
      workflowId : workflowId,
      token: this.currentUser.token,
      env: environment.name
    }
    return this.http.post(url, data)
  }

  getWorkflowById(workflowId) {
    const url = environment.endPoints.getWorkflowWithDraft
    const data = {
      workflowId : workflowId,
      token: this.currentUser.token
    }
    return this.http.post(url, data)
  }

  setWorkflowFolders(folders) {
    const url = environment.endPoints.setWorkflowAccountFolders
    const folderSystem = { folders : folders}
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workflowFolders: folders }))
  }

  createWorkOrder(workOrder) {
    const url = environment.endPoints.createWorkOrder
    return firstValueFrom(this.http.post<any>(url, { workorder : workOrder, token: this.currentUser.token, returnWorkorder: true })).then(res => res.data)
  }

  deleteWorkOrder(workOrderId) {
    const url = environment.endPoints.deleteWorkOrder
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workorderId: workOrderId })).then(res => res.data)
  }
  getWorkOrder(workOrderId) {
    const url = environment.endPoints.getWorkOrder
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workorderId: workOrderId })).then(res => res.data)
  }

  getWorkOrders() {
    const url = environment.endPoints.getWorkOrders
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token})).then(res => res.data)
  }

  updateWorkOrder(workOrder, workOrderId) {
    const url = environment.endPoints.updateWorkOrder
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workorderId: workOrderId, changes: workOrder, returnWorkorder: true })).then(res => res.data)
  }

  getAllWorkflowsWithId() {
    const url = environment.endPoints.getAllWorkflowsMeta
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token})).then(res => res.data)
  }

  setWorkOrderLabels(labels) {
    const url = environment.endPoints.setWorkorderLabels
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workorderLabels: labels })).then(res => res.data)
  }

  getWorkOrderLabels() {
    const url = environment.endPoints.getWorkorderLabels
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  sendComment(comment, workOrderId) {
    const url = environment.endPoints.sendWorkorderComment
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workorderId: workOrderId, comment: comment, returnWorkorder: true })).then(res => res.data)
  }

  updateWorkOrderForClient(workOrder, workOrderId) {
    const url = environment.endPoints.updateWorkorderForClient
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token, workorderId: workOrderId, changes: workOrder, returnWorkorder: true })).then(res => res.data)
  }

  getMyWorkOrders() {
    const url = environment.endPoints.getMyWorkorders
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  getUsers() {
    const url = environment.endPoints.getAllUsersForWorkorder
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data)
  }

  getUserContacts(): Promise<User[]> {
    const url = environment.endPoints.getUserContacts
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token })).then(res => res.data.users)
  }

  chooseAndCopyWorkflowFile(workflowId: string, type: 'image' | 'video' | 'pdf'): Promise<[string, string]> {
    throw new Error('Method not implemented.');
  }

  chooseFileAndGetPath(type: 'image' | 'video' | 'pdf'): Promise<[string, string]> {
    throw new Error('Method not implemented.');
  }

  saveWorkflowFile(file, filename, workflowId): Promise<[string, string]> {
    throw new Error('Method not implemented.');
  }

  chooseFolderAndSaveFile(filename: string, filePath: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  importReports(): Promise<string> {
    throw new Error('Method not implemented.');
  }

  exportPublishedWorkflows(): Promise<string> {
    throw new Error('Method not implemented.');
  }

  getAccountLabels(): Observable<string[]> {
    return this.listen<any>(`accounts/${this.currentUser.account_id}/account_data/labels`)
  }

  setAccountLabels(labels: string[]): Promise<void> {
    return this.set(`accounts/${this.currentUser.account_id}/account_data/labels`, labels)
  }

  twoFactorSendCode(action: string) {
    return this.http.post<any>(environment.endPoints.twofactorauth, {
      token: this.currentUser.token, action: action
    }).toPromise();
  }

  twoFactorProceed(action: string, code: string) {
    return this.http.post<any>(environment.endPoints.twofactorauth, {
      token: this.currentUser.token, action: action, verification: code.toString()
    }).toPromise();
  }

  authenticateSSO(id: string) {
    return this.http.post<any>(environment.endPoints.admin_sso, { sso_id: id }).toPromise();
  }

  createPushId(): string {
    return this.source.createPushId()
  }

  timestamp(): any {
    return this.source.timestamp();
  }

  listenSnap<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{ data: T; key: string; }> {
    return this.source.listenSnap<T>(path, event);
  }

  listen<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
    return this.source.listen<T>(path, event);
  }

  get<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Promise<T> {
    return this.source.get<T>(path, event);
  }
  set(path: string, data: any): Promise<void> {
    return this.source.set(path, data);
  }
  push(path: string, data: any): Promise<string> {
    return this.source.push(path, data);
  }
  update(path: string, data: any): Promise<void> {
    return this.source.update(path, data);
  }
  remove(path: string): Promise<void> {
    return this.source.remove(path);
  }
  query<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[], event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
    return this.source.query<T>(path, queries, event);
  }
  querySnap<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[], event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{ data: T; key: string; }> {
    return this.source.querySnap<T>(path, queries, event);
  }
}