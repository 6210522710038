import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datepicker-widget',
  templateUrl: './datepicker-widget.component.html',
  styleUrls: ['./datepicker-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerWidgetComponent),
      multi: true,
    },
  ],
})
export class DatepickerWidgetComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() item //type tanımla

  subscriptions : Subscription[] = []

  dateControl = new UntypedFormControl()

  constructor() { }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.dateControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.dateControl.valid ? null : this.dateControl.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    if(this.item.templateOptions.required) {
      this.dateControl.setValidators(Validators.required)
    }

    this.subscriptions.push(this.dateControl.valueChanges.subscribe(res => this.onChange(res)))

  }

  cancelDate() {
    this.dateControl.reset()
  }

}
