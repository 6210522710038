import { Injectable } from '@angular/core';
import { Folder } from '@models/Folder';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private dbService: DbService) {}

  getAllWorkflows(returnLicence?) {
    return this.dbService.getAllWorkflows(returnLicence)
  }

  getMyWorkflows(returnVideoFlowWorkflows?, returnLicence?, token?) {
    return this.dbService.getMyWorkflows(returnVideoFlowWorkflows, returnLicence, token)
  }

  getAllWorkflowReports(returnLicence?) {
    return this.dbService.getAllWorkflowReports(returnLicence)
  }

  getUserWorkflowReports() {
    return this.dbService.getUserWorkflowReports()
  }

  getAllUsersForWorkflow() {
    return this.dbService.getAllUsersForWorkflow()
  }

  saveDisabledUsers(insertedUsers, deletedUsers) {
    return this.dbService.saveDisabledUsers(insertedUsers, deletedUsers)
  }

  createWorkflow(workflow) {
    return this.dbService.createWorkflow(workflow)
  }

  workflowGenerate(promt) {
    return this.dbService.workflowGenerate(promt)
  }

  createWorkflowDraft(workflowId: string) {
    return this.dbService.createWorkflowDraft(workflowId)
  }

  publishWorkflowDraft(workflowId: string) {
    return this.dbService.publishWorkflowDraft(workflowId)
  }

  getPublishedWorkflowVersion(workflowId: string, version: number) {
    return this.dbService.getPublishedWorkflowVersion(workflowId, version)
  }

  getAllPublishedWorkflowVersions(workflowId: string) {
    return this.dbService.getAllPublishedWorkflowVersions(workflowId)
  }

  getWorkflowReport(reportId: string) {
    return this.dbService.getWorkflowReport(reportId)
  }

  setWorkflowDraft(workflow: any) {
    return this.dbService.setWorkflowDraft(workflow)
  }

  updateWorkflowUsers(workflowId: string, users: string[]) {
    return this.dbService.updateWorkflowUsers(workflowId, users)
  }

  deleteWorkflow(workflowId: string) {
    return this.dbService.deleteWorkflow(workflowId)
  }

  getWorkflowAccountLabels() {
    return this.dbService.getWorkflowAccountLabels()
  }

  setWorkflowAccountLabels(workflowLabels: string[]) {
    return this.dbService.setWorkflowAccountLabels(workflowLabels)
  }

  saveWorkflowReport(workflow, token?, returnLicence?, baseuRL?) {
    return this.dbService.saveWorkflowReport(workflow, token, returnLicence, baseuRL)
  }

  updateWorkflowReport(workflow, reportId, returnLicence?, token?, baseUrl?) {
    return this.dbService.updateWorkflowReport(workflow, reportId, returnLicence, token, baseUrl)
  }

  deleteWorkflowReport(reportId) {
    return this.dbService.deleteWorkflowReport(reportId)
  }

  getWorkflowAccountExtraDetails() {
    return this.dbService.getWorkflowAccountExtraDetails()
  }

  uploadWorkflowFiles(workflowId, formData, token?) {
    return this.dbService.uploadWorkflowFiles(workflowId, formData, token)
  }

  uploadWorkflowPpeFiles(workflowId, formData, token) {
    return this.dbService.uploadWorkflowPpeFiles(workflowId, formData, token)
  }

  uploadVideoForWorkflow(formData) {
    return this.dbService.uploadVideoForWorkflow(formData)
  }

  uploadVideoForWorkflowProgress(formData, pr) {
    return this.dbService.uploadVideoForWorkflowProgress(formData, pr)
  }

  uploadVideoForWorkflowProgress2(file, pr) {
    return this.dbService.uploadVideoForWorkflowProgress2(file, pr)
  }

  generateWorkflowQr(workflowId, baseUrl, password) {
    return this.dbService.workflowQrGenerate(workflowId, baseUrl, password)
  }

  workflowQrGetData(linkId) {
    return this.dbService.workflowQrGetData(linkId)
  }

  workflowQrGetCredentials(linkId, email, password?) {
    return this.dbService.workflowQrGetCredentials(linkId, email, password)
  }

  removeWorkflowQR(workflowId) {
    return this.dbService.workflowQrDelete(workflowId)
  }

  translateWorkflow(source,target, texts) {
    return this.dbService.translateWorkflow(source,target, texts)
  }

  switchToWorkflow(idToken) {
    return this.dbService.switchToWorkflow(idToken)
  }

  sendWorkflowReportToSign(baseUrl, reportId, emails, token?) {
    return this.dbService.sendWorkflowReportToSign(baseUrl, reportId, emails, token)
  }

  getWorkflowReportLinkData(uniqueId) {
    return this.dbService.getWorkflowReportLinkData(uniqueId)
  }

  signWorkflowReport(formData, linkId) {
    return this.dbService.signWorkflowReport(formData, linkId)
  }

  sendWorkflowReportAfterApproval(baseUrl, reportId, emails, token?) {
    return this.dbService.sendWorkflowReportAfterApproval(baseUrl, reportId, emails, token)
  }

  workflowRemoteIntegrationGetId(baseUrl, workflowId) {
    return this.dbService.workflowRemoteIntegrationGetId(baseUrl, workflowId)
  }

  workflowRemoteIntegrationUsers(linkId, experts) {
    return this.dbService.workflowRemoteIntegrationUsers(linkId, experts)
  }

  workflowRemoteIntegrationInfo(linkId) {
    return this.dbService.workflowRemoteIntegrationInfo(linkId)
  }

  workflowReportToPrefilled(reportId) {
    return this.dbService.workflowReportToPrefilled(reportId)
  }
  // createWorkflowFromVideo(workflowId, video?) {
  //   return this.dbService.createWorkflowFromVideo(workflowId, video)
  // }

  createWorkflowFromVideo(workflowId, video?) {
    return this.dbService.createWorkflowFromVideo(workflowId, video)
  }

  setWorkflowFolders(workflowFolders: Folder[]) {
    return this.dbService.setWorkflowFolders(workflowFolders)
  }
  
  // getTextResult(transcriptionId, transcriptions, workflowId, systemPrompt?) {
  //   return this.dbService.getTextResult(transcriptionId, transcriptions, workflowId, systemPrompt)
  // }

  // checkTranscriptionStatus(transcriptionId, workflowId) {
  //   return this.dbService.checkTranscriptionStatus(transcriptionId, workflowId)
  // }

  // getWorkflowById(workflowId) {
  //   return this.dbService.getWorkflowById(workflowId)
  // }

  getTextResult(transcriptionId, transcriptions, workflowId, systemPrompt?) {
    return this.dbService.getTextResult(transcriptionId, transcriptions, workflowId, systemPrompt)
  }

  checkTranscriptionStatus(transcriptionId, workflowId) {
    return this.dbService.checkTranscriptionStatus(transcriptionId, workflowId)
  }

  getWorkflowById(workflowId) {
    return this.dbService.getWorkflowById(workflowId)
  }

  createWorkOrder(workOrder) {
    return this.dbService.createWorkOrder(workOrder)
  }

  deleteWorkOrder(workOrderId) {
    return this.dbService.deleteWorkOrder(workOrderId)
  }

  getWorkOrder(workOrderId) {
    return this.dbService.getWorkOrder(workOrderId)
  }

  getWorkOrders() {
    return this.dbService.getWorkOrders()
  }

  updateWorkOrder(workOrder, workOrderId) {
    return this.dbService.updateWorkOrder(workOrder, workOrderId)
  }

  getAllWorkflowsWithId() {
    return this.dbService.getAllWorkflowsWithId()
  }

  setWorkOrderLabels(labels) {
    return this.dbService.setWorkOrderLabels(labels)
  }

  getWorkOrderLabels() {
    return this.dbService.getWorkOrderLabels()
  }

  sendComment(comment, workOrderId) {
    return this.dbService.sendComment(comment, workOrderId)
  }

  updateWorkOrderForClient(workOrder, workOrderId) {
    return this.dbService.updateWorkOrderForClient(workOrder, workOrderId)
  }

  getMyWorkOrders() {
    return this.dbService.getMyWorkOrders()
  }

  getUsers() {
    return this.dbService.getUsers()
  }

}