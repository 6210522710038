import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-image',
  template: `
  <div style="position: absolute;top:0;bottom:0;left:0;right:0;padding: 10px;padding-top:25px" [ngClass]="{'image-with-label': to.label}" >
      <img [src]="to?.['imgUrl'] | safeHtml" class="form-image" *ngIf="to['imgUrl']">
  </div>
  `,
})
export class FormlyFieldCustomImage extends FieldType<FieldTypeConfig> {
 
  get type() {
    return this.to.type || 'text';
  }
}