import { Injectable } from '@angular/core';


import { Observable, of, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { OwnRoom, Room } from '@models/Room';
import { RoomData } from '@models/RoomData';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { OwnRoomData } from '@models/OwnRoomData';

@Injectable({
  providedIn: 'root'
})
export class UserRoomService {

  // This observables' lifecycle connected to parent lobby component
  allOwnRooms: Observable<any>;
  private allOwnRoomsSource: BehaviorSubject<any> = new BehaviorSubject(null);
  private allOwnRoomsSub: Subscription = null;

  isJoinedRoom = false;

  constructor(
    private authService: AuthService,
    private dbService: DbService
  ) {
    this.startListeningOwnRooms()
    this.allOwnRooms = this.allOwnRoomsSource.asObservable();
  }

  changeJoinStatus(status: boolean) {
    this.isJoinedRoom = status
  }

  startListeningOwnRooms() {
    if (this.allOwnRoomsSub) { this.allOwnRoomsSub.unsubscribe() }

    this.allOwnRoomsSub = this.authService.user.pipe(
      map(user => user && user.rooms ? user.rooms : null),
      switchMap(roomsObj => {
        if (roomsObj) {
          let roomObsList:Observable<OwnRoom>[] = [];
          Object.keys(roomsObj)
          .forEach(roomId => {
            const roomObs = this.getRoom(roomId, true);
            roomObsList.push(roomObs);
          });
          return combineLatest(roomObsList);
        } else {
          return of([]);
        }
      })
    )
    .subscribe(rooms => this.allOwnRoomsSource.next(rooms));
  }

  getInRoomNotification() {
    return new Observable<string>(observer => {
      let isFirstTime = true;
      let inRoomUsers: any = {};
      const sub = this.allOwnRooms.subscribe(rooms => {
        if (!rooms) {
          return;
        }

        // get all users of all rooms
        const allUsers = rooms.reduce((users, room) => {
          room.room_data.users.forEach(u => users.push(u));
          return users;
        }, []);

        // find users in room
        const usersInRoom = allUsers.filter(u => u.in_room);

        const inRoomUsersTemp: any = {};
        for (const u of usersInRoom) {
          inRoomUsersTemp[u.user_id] = true;
        }

        if (!isFirstTime) {
          for (const u of usersInRoom) {
            if (!inRoomUsers[u.user_id]){
              observer.next(u.user_id);
            }
          }
        }
        isFirstTime = false;
        inRoomUsers = inRoomUsersTemp;
      })
      return () => sub.unsubscribe();
    });
  }

  stopListeningOwnRooms() {
    if (this.allOwnRoomsSub) { this.allOwnRoomsSub.unsubscribe() }
    this.allOwnRoomsSource.next(null);
  }

  getCurrentRoom(roomId: string): Observable<OwnRoom> {
    return this.dbService.listen<OwnRoomData>(`accounts/${this.authService.currentUser.account_id}/rooms/${roomId}/room_data`, 'value')
    .pipe( map(roomData => this.getRoomFromRoomData(roomData, roomId, false)) );
  }

  getRoom(roomId: string, excludeItself: boolean = false): Observable<OwnRoom> {
    return this.dbService.listen<OwnRoomData>(`accounts/${this.authService.currentUser.account_id}/rooms/${roomId}/room_data`, 'value')
    .pipe(
      map(roomData => this.getRoomFromRoomData(roomData, roomId, excludeItself)),
      catchError(error => of({
        id: "NaN",
        room_data: {
          name: "Deleted Room",
          description: "Deleted Room",
          icon: "",
          in_room: { active: false, count: 0, training_room: false },
          session: { active: false },
          users: []
        }
      }))
    );
  }

  getArchivedMeetings(): Observable<Room[]> {
    return new Observable<Room[]>(subscriber => {
      const meetings: Room[] = [];
      // when this method used with combineLatest,
      // and if there is no archived meeting,
      // blocks other observable(s) without following:
      subscriber.next(meetings);

      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/users_meetings/${this.authService.currentUser.id}`, "child_added")
        .subscribe(snp => {
          meetings.push(this.getRoomFromMeetingData(snp.key, snp.data));
          subscriber.next(meetings);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/users_meetings/${this.authService.currentUser.id}`, "child_changed")
        .subscribe(snp => {
          const i = meetings.findIndex(m => m.room_data.link_id === snp.key);
          if (i>-1) {
            meetings[i] = this.getRoomFromMeetingData(snp.key, snp.data);
          } else {
            meetings.push(this.getRoomFromMeetingData(snp.key, snp.data));
          }
          subscriber.next(meetings);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/users_meetings/${this.authService.currentUser.id}`, "child_removed")
        .subscribe(snp => {
          const i = meetings.findIndex(m => m.room_data.link_id === snp.key);
          if (i>-1) {
            meetings.splice(i, 1);
            subscriber.next(meetings);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getRoomFromMeetingData(linkId: string, meetingData: any): Room {
    const room: any = {
      id: meetingData.room_id,
      room_data: {
        allow_archiving: true,
        archive_perm_needed: false,
        auto_archive: false,
        create_time: meetingData.create_time,
        description: meetingData.room_description,
        end: meetingData.end,
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAE8klEQVR4Xu2ab2hbVRjGn/feNI1WbOumUxhYWWeSuumHMXQftIXa2xYRh65tEnV/FFSYf/ZNZOoEJ4iIytQPBUG2uSamjk1BttyuOFEsdExw0vbGdXNqQdd2Yiu6dknuK7drQJq0uSdJk9v0ni/5kOfkPM/vvufk3HMvYZk3Wub5YQOwK2CZE7CnwDIvAHsRtKeAPQWWOYGspkDDVw2OP39zlsddV+bt75hy8hlX5RTauxNWZiwMoC7cdp2emBgG4wbQgosoEzB87eSlu04/fTpmVQjCANzBpn4CbTQbiIFXon51r1l9oXXCADxBZRTAjaaNEjo1n/qMaX2BhdkA+BlAjYDP/Zpf3S6gL6g0GwDfAdhk1iURPh/yqZvN6gutEwbgDjYdINDjZo0y0B/1q3eb1RdaJwzAG2rexczvmjdKY5o/sgoAm+9TOKUwgNtDjRsllvtFLMZJrh32HTsn0qdQWmEAYJAnqEyDUGbWJDG/OBToecusvpA6cQAAPF3KRRBuEjD6teZXGwT0BZNmByCknAFjvYDLAc2vrhPQF0yaFQBvl7KXCbvNu+QPNH/Pc+b1hVNmBaD2k9bVDjlxAYCc0SqjU3NU7rTqTVFWAIzQnqAyZHwsACDOzLujFl38kr6zBxBS9oExX1n/w0xbooHI8YwVUmRB1gC8h5tv4WmMgFiam0GWae1Ae2S4yNlMDZ81gNlpcAxAS8pIjJe0gPqmKQdFFuUEwN3V3ELEBoS5LS7J+prB9hO/FjlfxuFzAoA9eyS3p+8kAfemjEQ4rPnULRkdFFmQGwAAdcGWRh36iXQ5WKKOaEckXOSMCw6fM4CZtSCkfAbGI2lGGk3oiU1nH+09b1UIeQFQG2pd4+DEAIDy1KB8qlququ9r775sRQh5AWAE8waVJxn4KF1IAh8ZkqvarLgbzBsAY0H0ePp+AJD2pocZ3dGA2m61KsgfAAB3HlAqrjgwDoIrTVDjRCio+dXHrHQ6lFcARug7ws21iQT/CKSBQMaxGKkVE+MPmnlYsqFzQ9m/lSu3QQf+dlx/aGQR1pG8A7j6r9DyMLMepvnuFhl9sTg2n9uqGs8Y0rdwm+xJTHwL4J5ZQQzMZ4noF2Y+pRMNgqTzFMMk6bFxbVvvpWym16IAuLooNu1kSPuA1HsF43tmjMjE2wf9Pb3pjLuDykECjOlipjGIVM0XSd2WZ+i9aABmIHQ172Lit+c9N2DoBH7/8nTstQs7Tv6V9OoOKs8T8B4g+AKHJAW0juNBM8SSmkUFYAziDipPEPBh2jUh6YLxOyTp2ZtXTX0xOla2Tk9Q34L6eRIS0xtDgcjLlgIwUwmfKvexjqMAqjOYGwNQBZg/cf7/71kWgGFy/aEHqmNS/CeAV4pcIRGtpQEYQYwXK/646HxngZMkkbwpWssDSDpeG7rfK0P6Eozbcko8p/OSAWD4rvm4wVXucgYIMJ4YrcgHiCUFIBm45khD1TVTzh3M/AKIbs0FxJIEkAxcF25zcmzSBwlbGVwPwCEKg0GvRv2R10X6Lfo+QMRMUuvZ37gCZfJTRGhlkNfkPwdLoPpBf+QbkTEtCWBuAPfBFjec/BAYCum8GoQKgFwAO2d3mQzG91pANSpHqC0JAEKJBMU2AEFgJSe3K6DkLqlgILsCBIGVnNyugJK7pIKB7AoQBFZycrsCSu6SCgb6D6pOgVCPV/yLAAAAAElFTkSuQmCC",
        in_room: {},
        license: {},
        link_id: linkId,
        meeting_room: meetingData.creator,
        meeting_type: "scheduled",
        name: meetingData.room_name,
        peer_to_peer: false,
        session: {},
        start: meetingData.start,
        training_room: false,
        users: [],
        archive: true
      }
    }

    return room;
  }

  getRoomFromRoomData(roomData: OwnRoomData, roomId: string, excludeItself: boolean): OwnRoom {
    const currentUser = this.authService.currentUser;

    if (roomData.users) {
      let users = [];
      Object.keys(roomData.users).forEach(user => {
        const ru: any = {
          user_id: user,
          in_room: roomData.users[user].in_room,
          name: roomData.users[user].name,
          ar_color: roomData.users[user].ar_color,
          role: roomData.users[user].role
        };
        if (roomData.users[user].status) {
          ru.status = roomData.users[user].status;
        }
        if (roomData.users[user].in_room_status) {
          ru.in_room_status = roomData.users[user].in_room_status;
        }
        if (roomData.users[user].platform) {
          ru.platform = roomData.users[user].platform;
        }
        if (roomData.users[user].app_version) {
          ru.app_version = roomData.users[user].app_version;
        }
        users.push(ru);
      });
      if (excludeItself) {
        users = users.filter(user => user.user_id !== currentUser.id);
      }
      roomData.users = users.sort((a, b) => {
        if (a.in_room && !b.in_room) {
          return -1;
        } else if (!a.in_room && b.in_room) {
          return 1;
        } else {
          return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        }
      });
    } else {
      roomData.users = [];
    }
    return {
      id: roomId,
      room_data: roomData
    }
  }
}
