export const QUILL_DEFAULT_FONT_SIZES = ['14px', '12px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '36px', '48px', '72px'];
export const QUILL_DEFAULT_ALIGNMENTS = ['', 'center', 'right'];

// export const  CustomOptions = [{
//   import: 'formats/font',
//   whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace']
// }]

export const ToolOptions = [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'size': QUILL_DEFAULT_FONT_SIZES }], [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'align': QUILL_DEFAULT_ALIGNMENTS }]
]

export const Formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'image'
];


