import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-upload-modal',
  templateUrl: './video-upload-modal-2.component.html',
  styleUrls: ['./video-upload-modal-2.component.scss']
})
export class VideoUploadModalComponent implements OnInit {

  createWorkflow : EventEmitter<any> = new EventEmitter

  isUpload
  isLoading
  subscriptions: Subscription[] = []

  uploadedFile
  fileName
  videoDuration: number | null = null;
  showTimeLimitError

  uploadProgress

  isFileUploading = false

  constructor(
    private workflowService: WorkflowService,
    public loaderService: LoaderService,
    public modalRef: BsModalRef,
    private translateService: TranslateService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
  }

  chooseButtonUpload( event: any): void {
    this.isUpload = true
    let selectedFile =  event.target.files
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];
      const video = document.createElement('video');

      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
      const durationLimit = 1860

        this.videoDuration = video.duration;
        if(this.videoDuration <= durationLimit) {
          this.previewUpload(selectedFile)
        }else{
          this.showVideoTimeLimitError()
        }
      };
       video.src = URL.createObjectURL(file);
    }


    // if (input.files && input.files[0]) {
    //   const file = input.files[0];
    //   const video = document.createElement('video');

    //   video.preload = 'metadata';
    //   // video.src = URL.createObjectURL(file);
    //   video.onloadedmetadata = () => {
    //     URL.revokeObjectURL(video.src);  // Kullanıldıktan sonra URL'yi iptal edin
    // this.videoDuration = video.duration;
    // console.log(this.videoDuration, 'duration');
    //   };
    //   const durationLimit = 1860
    //   console.log(this.videoDuration, 'duration')

    //   if(this.videoDuration <= durationLimit) {
    //     this.previewUpload(selectedFile)
    //   }else{
    //     this.showVideoTimeLimitError()
    //   }
    // }

  }

  showVideoTimeLimitError() {
    this.showTimeLimitError = true
  }

  dragDropUpload(event) {

  }

  private previewUpload(selectedFile: File): void {
    if (selectedFile) {
      this.isFileUploading = true
      this.isLoading = true;
      this.loaderService.show()
      this.subscriptions.push(this.uploadfile(selectedFile).subscribe({
        next: (resp) => {
          if(resp){
            this.uploadedFile = {url : resp['data'].files[0].url, key : resp['data'].files[0].key}
            this.isUpload = false
            this.fileName = selectedFile[0].name
            this.isLoading = false;
            this.isFileUploading = false
            this.loaderService.hide()
          }
        },
        error: (e) => {
          this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR'))
          this.isFileUploading = false
          this.loaderService.hide()
        }
      }))
    }
  }

  uploadfile(file) {
    /*
    let formData = new FormData();
    
      if(file.length){
        for (let i = 0; i < file.length; i++) {
          formData.append(file[i].name, file[i])
        }
      }else{
        formData.append(file.name, file)
      }

      return this.workflowService.uploadVideoForWorkflowProgress(formData, (progress: number) => {
        this.uploadProgress = progress;
      });
      
      // return this.workflowService.uploadVideoForWorkflow(formData)
    */

    return this.workflowService.uploadVideoForWorkflowProgress2(file[0], (progress: number) => {
      this.uploadProgress = progress;
    });

  }

  close(): void {
    this.modalRef.hide();
  }

  createWorkflowWithVideoFile() {
    this.createWorkflow.emit(this.uploadedFile)
    this.modalRef.hide();
  }

}
