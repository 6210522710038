import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery-widget',
  templateUrl: './gallery-widget.component.html',
  styleUrls: ['./gallery-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GalleryWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GalleryWidgetComponent),
      multi: true,
    },
  ],
})
export class GalleryWidgetComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() item //type tanımla

  subscriptions : Subscription[] = []

  galleryControl = new UntypedFormControl()
  selectedImage


  constructor() { }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.galleryControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.galleryControl.valid ? null : this.galleryControl.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
  }

  openImageFullscreen(image) {
    this.selectedImage = image
  }

  exitFullscreen() {
    this.selectedImage = null
  }

}
