
<div class="d-flex guest-container">
    <div class="col-12 w-50" [ngClass]="{'d-flex align-items-center justify-content-center w-100': !isQrAvailable}">
        <div class="guest-wrapper  d-flex flex-column align-items-center justify-content-center h-100" *ngIf="!isQrAvailable">
            <ng-container >
                <img src="assets/img/public-access-icon.svg" alt="">
                <div class="fs-14 mt-3 text-center w-75">{{ 'MAIN.CONTENT.EASY-ACCESS.GENEATE-ACCESS-TEXT' | translate }}</div>
                <div class="d-flex align-items-center">
                    <div class="form-group d-flex flex-column align-items-center mt-3">
                        <div class="d-flex align-items-center mb-2">
                            <input type="checkbox" class="form-check-input mt-0 me-2" id="passwordEnabled" name="passwordEnabled" [formControl]="passwordEnabled">
                            <label class="form-check-label fs-14 font-weight-400 text-dark-grey" for="passwordEnabled">{{ 'MAIN.CONTENT.EASY-ACCESS.ENABLE-PASSWORD' | translate }}</label>
                            <img src="assets/img/info_icon.svg" alt="" class="ms-2" [dynamicTooltip]="('MAIN.CONTENT.EASY-ACCESS.PASSWORD-PROTECTED-TEXT' | translate)">
                        </div>
                        <div>
                            <label class="fs-14 text-dark-grey">{{'LOGIN.PASSWORD-PLACEHOLDER' | translate}}</label>
                            <input type="text" class="form-control password fs-14" [formControl]= "password" [disabled]="true">
                        </div>
                    </div>
                </div>
                <div><button class="v-btn btn btn-primary vsight-button create-button mb-4 top-gap-30" (click)="onGenerateQR()">{{ 'MAIN.CONTENT.EASY-ACCESS.GENERATE-ACCESS' | translate }}</button></div>
        </ng-container>
        </div>
        
        <div *ngIf="isQrAvailable" class="guest-wrapper-with-qr">
            <label class="fs-16 font-weight-600 mb-2">{{ 'MAIN.CONTENT.EASY-ACCESS.LINK' | translate }}</label>
            <div class="d-flex mb-5">
                <input type="text" class="form-control guest fs-14" [formControl]="guestLink" #guestLinkp>
                <button class="v-btn btn btn-primary vsight-button creaate-button ms-2" (click)="copyGuestLinkToClipboard(guestLinkp)">{{ 'MAIN.CONTENT.EASY-ACCESS.COPY' | translate }}</button>
            </div>
            
            <hr>
            <label class="fs-16 font-weight-600 mt-3">{{ 'MAIN.CONTENT.EASY-ACCESS.QR-CODE' | translate }}</label>

            <div class="qr-img">
                <img class="img-fluid" [src]="qrData" alt="QR" style="cursor: pointer;" style="width: 200px;">
            </div>

            <a class="core-blue fs-14 font-weight-700 download" (click) = "downloadFile(qrData)">
                <img src="assets/img/download-step-file-blue.svg" class="download-img">
                {{ 'MAIN.CONTENT.EASY-ACCESS.DOWNLOAD' | translate }}
            </a>

            <hr>

            <label class="fs-16 font-weight-600 mt-3">{{ 'MAIN.CONTENT.EASY-ACCESS.SECURITY' | translate }}</label>
            <img src="assets/img/info_icon.svg" alt="" class="ms-2 mb-1" [dynamicTooltip]="('MAIN.CONTENT.EASY-ACCESS.DELETE-QR-TEXT' | translate)">

            <div class="d-flex align-items-center fs-14 font-weight-400 mt-1">
                    <p *ngIf="passwordRequired">{{ 'MAIN.CONTENT.EASY-ACCESS.PASSWORD-PROTECTED' | translate }}</p>
                    <p *ngIf="!passwordRequired">{{ 'MAIN.CONTENT.EASY-ACCESS.PUBLIC' | translate }}</p>
            </div>
        </div>

    </div>

    <div *ngIf="isQrAvailable">
        <div class="qr-container">
            <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.EASY-ACCESS.MANAGE-PUBLIC-ACCESS' | translate }}</label>
            <p class="fs-14 font-weight-400 top-gap-16 mb-0">{{ 'MAIN.CONTENT.EASY-ACCESS.MANAGE-PUBLIC-ACCESS-TEXT' | translate }}</p>
            <div class="d-flex align-items-center justify-content-center">
                <button class="v-btn btn btn-primary vsight-button create-button top-gap-16" (click)="onDeleteQR()">{{ 'MAIN.CONTENT.EASY-ACCESS.DELETE-ACCESS' | translate }}</button>
            </div>
        </div>
    </div>
    
</div>