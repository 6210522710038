<div class="d-flex flex-column h-100" *ngIf="item?.type =='image' || item?.type =='video'">
    <label class="h-50 label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="h-50 d-flex justify-content-center">
        <div *ngIf="item.type == 'video' && item.templateOptions['videoUrl']" [ngClass]="{'image-with-label': item.templateOptions.label}" class="d-flex position-absolute-aligned p-1">
            <div class="position-relative form-image-wrapper me-1 w-100">
                <video [src]="item.templateOptions.videoUrl | safeHtml" *ngIf="item.templateOptions.videoUrl && !isVideoFlow" controls class="form-video"></video>
                <video [src]="url | safeHtml" *ngIf="isVideoFlow"  controls class="form-video" #videoPlayer></video>

            </div>
        </div>

        <div *ngIf="item.type == 'image' && item.templateOptions.imgUrl" [ngClass]="{'image-with-label': item.templateOptions.label}" class="d-flex position-absolute-aligned p-1">
            <div class="position-relative form-image-wrapper me-1 w-100">
                <img [src]="item.templateOptions.imgUrl | safeHtml" class="form-image" *ngIf="item.templateOptions.imgUrl">
            </div>
        </div>
    </div>
</div>

<div style="padding: 10px;" class="position-absolute-aligned" [ngClass]="{'image-with-label': item.templateOptions.imgUrl}" *ngIf="item?.type =='pdf'">
    <div class="pdf-header">
        <div class="d-flex align-items-center justify-content-center loading-upload" *ngIf="isUpload">
            <ng-container *ngIf="isUpload" [ngTemplateOutlet]="loadingTemplateUpload"></ng-container>
        </div>
        <img src="assets/img/left-arrow.svg" alt="" class="me-2" (click)="pdfPrevious()">
        <input type="text" class="form-control mb-1 pdf-input me-2 text-white" [formControl]="pdfPage" (keyup.enter)="pageSearch()">
         <label class="" style="color:white">/ {{totalPages}}</label>
        <img src="assets/img/right-arrow.svg" alt="" class="ms-2" (click)="pdfNext()">

        <div class="pdf-download-button">
            <label class="cursor-pointer" style="color: #4f5053" (click)="downloadPdf(item.templateOptions)">
                <img src="assets/img/download-file.svg" alt="">
            </label>
        </div>
    </div>
    <pdf-viewer 
        [src]="item.templateOptions.pdfUrl"
        [render-text]="true"
        [original-size]="false"
        [autoresize]="true"
        [page]="pdfPage.value"
        (pageChange)= "pageChanged($event)"
        (after-load-complete)="afterLoadComplete($event)"
        style="height: 100%"
    ></pdf-viewer>
</div>

<ng-template #loadingTemplateUpload>
    <div class="spinner-border m-5" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </ng-template>