import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { CallService } from '@services/remote/call.service';
import { ModalService } from '@services/remote/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ContactListModalComponent } from 'src/app/components/modals/contact-list-modal/contact-list-modal.component';

@Component({
  selector: 'app-remote-assistance',
  templateUrl: './remote-assistance.component.html',
  styleUrls: ['./remote-assistance.component.scss']
})
export class RemoteAssistanceComponent implements OnInit {

  @ViewChild("scheduleMeetingTemplate", { static: true }) private scheduleMeetingTemplate: TemplateRef<any>;

  linkOrFieldId = new UntypedFormControl('', Validators.required)

  showMenu = false
  tabIndex = 0

  subscriptions: Subscription[] = []

  roommates = [];
  licenseType: string = "concurrent_user_based";

  invalidEmailError
  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);

  meetingCreationInProgress = false

  constructor(
    private router: Router,
    private bsmodalService: BsModalService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private authService: AuthService,
    private callService: CallService,
    private multilanguageService: MultilanguageService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService ) {}


  ngOnInit(): void {
    this.callService.getUserContacts().then(users => {
      console.log(users)
      const roommates = users ? users : []
      roommates.forEach(user => {
        user['username'] = user.ad_user ? user.user_principal_name :  user.auth?.username
      })
      this.roommates = roommates
    })
  }

  newSession() {
    this.showMenu = !this.showMenu
  }

  instantCall() {
    this.showMenu = false
    this.loaderService.show()
    const initialState= {
      
    };
    let modalRef = this.bsmodalService.show(ContactListModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(modalRef.content.onClose.subscribe((res) => {
      if(res) {
        

        // call denince yapılacak işlem
      }
    }))
  }

  onTabClicked(index) {
    this.tabIndex = index
  }

  viewAllMeetings() {
    this.tabIndex = 1
  }

  async scheduleMeeting() {
    this.showMenu = false

    // this.translateService.get('APP.MAIN.EMAIL_RECIPIENTS.INVALID_EMAIL').toPromise().then(tr => {
    //   this.invalidEmailError.pattern = tr;
    // });

    const timeSlotMinutes = 15;
    const now = new Date();
    
    const startD = new Date(now);
    startD.setHours(startD.getHours(), startD.getMinutes() + timeSlotMinutes - (startD.getMinutes() % timeSlotMinutes), 0, 0);

    const endD = new Date(startD);
    endD.setHours(endD.getHours(), endD.getMinutes() + timeSlotMinutes, 0, 0);

    const emailLangList = ['en', 'de', 'tr']
    let emailLang = 'en'
    if (this.authService.currentUser?.translation_lang_email) {
      emailLang = this.authService.currentUser.translation_lang_email
    } else if (emailLangList.includes(this.multilanguageService.currentLang.code)) {
      emailLang = this.multilanguageService.currentLang.code
    }
    const dataModel = {
      title: "",
      start: { date: startD, time: startD.getHours()*60+startD.getMinutes() }, startSlots: [],
      end: { date: endD, time: endD.getHours()*60+endD.getMinutes() }, endSlots: [],
      duration: `0h ${timeSlotMinutes}m`,
      users: [],
      emailRecipients: [],
      emailLang: emailLang,
      emailLangList: emailLangList
    }; 

    dataModel.users = this.roommates
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
      .map(roommate => {
        const username = roommate.ad_user ? roommate.user_principal_name : roommate.username;
        switch (roommate.role) {
          case "admin":
            return {name: `${roommate.name} (${username} / Admin)`, id: roommate.id};
          case "coadmin":
            return {name: `${roommate.name} (${username} / Co-Admin)`, id: roommate.id};
          case "expert":
            return {name: `${roommate.name} (${username} / Expert)`, id: roommate.id};
          default:
            return {name: `${roommate.name} (${username} / ${this.licenseType === "concurrent_user_based" ? "User" : "Subscriber"})`, id: roommate.id};
        }
      });

    for (let i = 0; i < 1440; i=i+timeSlotMinutes) {
      const h1 = Math.floor(i/60);
      const m1 = i % 60;
      dataModel.startSlots.push({time: i, label: `${("0"+h1).slice(-2)}:${("0"+m1).slice(-2)}`});

      const i2 = (i+dataModel.start.time+timeSlotMinutes);
      const h2 = Math.floor(i2/60) % 24;
      const m2 = i2 % 60;

      const i3 = i2 - dataModel.start.time;
      const h3 = Math.floor(i3/60);
      const m3 = i3 % 60;

      dataModel.endSlots.push({time: i2, label: `${("0"+h2).slice(-2)}:${("0"+m2).slice(-2)} - ${h3}h ${m3}m`});
    }

    let userList: string[] = [];
    const modalId = this.modalService.show({
      template: this.scheduleMeetingTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          startDateChanged: (d: Date) => {
            dataModel.start.time = dataModel.start.date.getHours()*60+dataModel.start.date.getMinutes();
            const e = new Date(dataModel.start.date);
            e.setHours(e.getHours(), e.getMinutes() + timeSlotMinutes, 0, 0);
            dataModel.end.date = e;
            dataModel.end.time = dataModel.start.time+timeSlotMinutes;
            dataModel.endSlots = [];
            for (let i = dataModel.end.time; i < dataModel.end.time+1440; i=i+timeSlotMinutes) {
              const h = Math.floor(i/60) % 24;
              const m = i % 60;

              const i3 = i - dataModel.start.time;
              const h3 = Math.floor(i3/60);
              const m3 = i3 % 60;

              dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          endDateChanged: (d: Date) => {
            const difference = d.getTime() - dataModel.start.date.getTime();

            if (difference <= 0) {
              const e = new Date(dataModel.start.date);
              e.setHours(e.getHours(), e.getMinutes() + timeSlotMinutes, 0, 0);
              dataModel.end.date = e;
              dataModel.end.time = dataModel.start.time+timeSlotMinutes;

              dataModel.endSlots = [];
              for (let i = dataModel.end.time; i < dataModel.end.time+1440; i=i+timeSlotMinutes) {
                const h = Math.floor(i/60) % 24;
                const m = i % 60;

                const i3 = i - dataModel.start.time;
                const h3 = Math.floor(i3/60);
                const m3 = i3 % 60;
  
                dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
              }
            } else {
              const diffInMinutes = Math.round(difference / 60000);
              dataModel.end.time = dataModel.start.time + diffInMinutes;
              let days = Math.round(dataModel.end.time/1440);

              dataModel.endSlots = [];
              if (days === 0) {
                for (let i = dataModel.start.time+timeSlotMinutes; i < dataModel.start.time+timeSlotMinutes+1440; i=i+timeSlotMinutes) {
                  const h = Math.floor(i/60) % 24;
                  const m = i % 60;

                  const i3 = i - dataModel.start.time;
                  const h3 = Math.floor(i3/60);
                  const m3 = i3 % 60;
    
                  dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
                }
              } else {
                if ((dataModel.start.time % 1440) < (dataModel.end.time % 1440)) {
                  days = days - 1;
                }
                for (let i = days*1440; i < (days+1)*1440; i=i+timeSlotMinutes) {
                  const h = Math.floor(i/60) % 24;
                  const m = i % 60;

                  const i3 = i - dataModel.start.time;
                  const h3 = Math.floor(i3/60);
                  const m3 = i3 % 60;
    
                  dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
                }
              }
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          startTimeChanged: (t: any) => {
            const h1 = Math.floor(t.time/60);
            const m1 = t.time % 60;
            dataModel.start.date = new Date(dataModel.start.date.setHours(h1, m1, 0, 0));
            const tmp = new Date(dataModel.start.date);
            tmp.setHours(h1, m1+timeSlotMinutes, 0, 0);
            dataModel.end.date = tmp;
            dataModel.end.time = t.time+timeSlotMinutes;

            dataModel.endSlots = [];
            for (let i = dataModel.end.time; i < dataModel.end.time+1440; i=i+timeSlotMinutes) {
              const h = Math.floor(i/60) % 24;
              const m = i % 60;

              const i3 = i - dataModel.start.time;
              const h3 = Math.floor(i3/60);
              const m3 = i3 % 60;

              dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          endTimeChanged: (t: any) => {
            const t2 = t.time % 1440;
            const h1 = Math.floor(t2/60);
            const m1 = t2 % 60;
            dataModel.end.date = new Date(dataModel.end.date.setHours(h1, m1, 0, 0));

            if (dataModel.start.date.getDate() === dataModel.end.date.getDate() && t.time >= 1440 && t.time <= dataModel.start.time+1440) {
              dataModel.end.date.setDate(dataModel.end.date.getDate() + 1);
              dataModel.endSlots = [];
              for (let i = 1440; i < 2880; i=i+timeSlotMinutes) {
                const h = Math.floor(i/60) % 24;
                const m = i % 60;

                const i3 = i - dataModel.start.time;
                const h3 = Math.floor(i3/60);
                const m3 = i3 % 60;

                dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
              }
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          schedule: () => {
            this.loaderService.show();
            this.meetingCreationInProgress = true;
            setTimeout(() => {
              const emails = dataModel.emailRecipients.map(email => email.value);
              this.callService.scheduleMeeting(userList, emails, dataModel.title, dataModel.start.date.getTime(), dataModel.end.date.getTime(), dataModel.emailLang)
              .then(() => {
                this.modalService.hide(modalId);
                dataModel.emailRecipients = [];
              })
              .catch(error => this.snackBarService.error(this.translateService.instant('MAIN.LOBBY.SCHEDULE_MEETING_MODAL.CANNOT_SCHEDULE')))
              .finally(() => {
                this.loaderService.hide();
                this.meetingCreationInProgress = false;
              });
            }, 1000);
          },
          close: () => {
            this.modalService.hide(modalId)
            dataModel.emailRecipients = [];
          },
          userListChanged: (users: any) => {
            userList = [];
            users.forEach(u => userList.push(u.id));
          },
          onLanguageSelected: (lang: string) => {
            dataModel.emailLang = lang
            this.multilanguageService.changePreferedTranslationLangEmail(lang)
          }
        }
      }
    });
  }

  onLinkOrFieldId() {

    this.snackBarService.error("Not implemented yet")
    /*const value: string = this.linkOrFieldId.value
    // check if value is field id
    if (new RegExp("\\d{6}").test(value)) {

    } else if (value.startsWith("https://")) {
      try {
        const url = new URL(value)
        const linkId = url.searchParams.get("id")
      } catch (error) {
      }
    }*/
  }
}
