import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { QRCodeModule } from 'angularx-qrcode';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { DynamicTooltipDirective } from './directives/dynamic-tooltip.directive';
import { InputSelectOnFocusDirective } from './directives/input-select-on-focus.directive';
import { WidgetPropertiesComponent } from './components/main/content/workflow/workflow-steps/widget-properties/widget-properties.component';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/main/content/dashboard/dashboard.component';
import { NavbarComponent } from './components/main/navbar/navbar.component';
import { WorkflowComponent } from './components/main/content/workflow/workflow.component';
import { StepPreviewComponent } from './components/main/content/workflow/workflow-steps/step-preview/step-preview.component';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldCustomVideo } from './models/field-templates/video.component';
import { FormlyFieldCustomDate } from './models/field-templates/date.component';
import { FormlyFieldCustomLabel } from './models/field-templates/label.component';
import { FormlyFieldCustomImage } from './models/field-templates/image.component';
import { SafeHtml } from './pipes/safehtml.pipe';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { TutorialDirective } from './directives/tutorial.directive';
import { AddNativeElementDirective } from './directives/add-native-element.directive';
import { InputSelectOnCreateDirective } from './directives/input-select-on-create.directive';
import { FormlyFieldCustomRadio } from './models/field-templates/radio.component';
import { FormlyFieldCustomQR } from './models/field-templates/qr.component';
import { FormlyFieldCustomCaptureImage } from './models/field-templates/capture-image.component';
import { FormlyFieldCustomCaptureVideo } from './models/field-templates/capture-video.component';
import { FormlyFieldCustomCheckbox } from './models/field-templates/checkbox.component';
import { CustomTypes } from './models/CustomTypes';
import { AlertModalComponent } from './components/modals/alert-modal/alert-modal.component';
import { FormlyFieldCustomEmpty } from './models/field-templates/empty.component';
import { StepListComponent } from './components/main/content/workflow/workflow-steps/step-list/step-list.component';
import { TemplateModalComponent } from './components/modals/template-modal/template-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginFromAdminComponent } from './components/externals/login-from-admin/login-from-admin.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatSortModule } from '@angular/material/sort';
import { SidebarComponent } from './components/main/sidebar/sidebar.component';
import { MobileReportComponent } from './components/externals/mobile-report/mobile-report.component';
import { ReportListComponent } from './components/main/content/report-list/report-list.component';
import { ReportComponent } from './components/main/content/report/report.component';
import { SettingsComponent } from './components/main/content/settings/settings.component';
import { AddWebhookModalComponent } from './components/modals/add-webhook-modal/add-webhook-modal.component';
import { FormlyFieldCustomNumber } from './models/field-templates/number.component';
import { ImageModalComponent } from './components/modals/image-modal/image-modal.component';
import { LinebreaksPipe } from './pipes/linebreaks.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WorkflowDetailComponent } from './components/main/content/workflow/workflow-detail/workflow-detail.component';
import { WorkflowConnectionsComponent } from './components/main/content/workflow/workflow-connections/workflow-connections.component';
import { WorkflowUsersModalComponent } from './components/modals/workflow-users-modal/workflow-users-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FileDropDirective } from './directives/file-drop.directive';
import { SuccessMessageComponent } from './models/field-templates/success-message-component';
import { ErrorMessageComponent } from './models/field-templates/error-message.component';
import { ImageCollaborationComponent } from './components/main/content/remote/image-collaboration/image-collaboration.component';
import { ImageAnnotationCanvasComponent } from './components/main/content/remote/image-collaboration/image-annotation-canvas/image-annotation-canvas.component';
import { ImageViewerComponent } from './components/main/content/remote/image-collaboration/image-viewer/image-viewer.component';
import { ImageEditorComponent } from './components/modals/image-editor/image-editor.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormlyFieldCustomInput } from './models/field-templates/input.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './components/login/login.component';
import { EnvPipe } from './pipes/env.pipe';
import { LocalizedDatePipe } from './pipes/localize-date.pipe';
import { DatePipe } from '@angular/common';
import localeTR from '@angular/common/locales/tr';
import localeDE from '@angular/common/locales/de';
import localeCS from '@angular/common/locales/cs';
import localeSK from '@angular/common/locales/sk';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { UserListComponent } from './components/main/content/user-list/user-list.component';
import { WorkflowsModalComponent } from './components/modals/workflows-modal/workflows-modal.component';
import { PreviewModalComponent } from './components/modals/preview-modal/preview-modal.component';
import { TypeofPipe } from './pipes/typeof.pipe';
import { UserDetailComponent } from './components/main/content/user-detail/user-detail.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './components/main/content/remote/image-cropper/image-cropper.component';
import { ReportFileComponent } from './components/externals/report-file/report-file.component';
import { FormlyFieldCustomPdf } from '@models/field-templates/pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SimplePasswordValidatorDirective } from './directives/simple-password.directive';
import { UserWorkflowComponent } from './components/modals/user-workflow/user-workflow.component';
import { UserReportsComponent } from './components/main/content/user-reports/user-reports.component';
import { SectionModalComponent } from './components/modals/section-modal/section-modal.component';
import { FilerobotIimageEditorComponent } from './components/modals/filerobot-iimage-editor/filerobot-iimage-editor.component';
import { LicenceWarningModalComponent } from './components/modals/licence-warning-modal/licence-warning-modal.component';
import { GuestAccessComponent } from './components/main/content/workflow/guest-access/guest-access.component';
import { GuestLoginWithLinkComponent } from './components/externals/guest-login-with-link/guest-login-with-link.component';
import { GuestWorkflowComponent } from './components/externals/guest-workflow/guest-workflow.component';
import { JsonViewerComponent } from './components/modals/json-viewer/json-viewer.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { HtmlToPlaintextPipe } from './pipes/html-to-plain-text.pipe';
import { QuillModule } from 'ngx-quill';
import { FormlyFieldCustomRichText } from '@models/field-templates/rich-text.component';
import { DefaultLanguageModalComponent } from './components/modals/default-language-modal/default-language-modal.component';
import { WorkflowNewLanguageModalComponent } from './components/modals/workflow-new-language-modal/workflow-new-language-modal.component';
import { UserDefaultContentLanguageModalComponent } from './components/modals/user-default-content-language-modal/user-default-content-language-modal.component';
import { FormlyFieldCustomGallery } from '@models/field-templates/gallery.component';
import { OpenAiModalComponent } from './components/modals/open-ai-modal/open-ai-modal.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { RemoteWorkflowComponent } from './components/externals/remote-workflow/remote-workflow.component';
import { SignaturePadModalComponent } from './components/modals/signature-pad-modal/signature-pad-modal.component';
import { ApprovalComponent } from './components/main/content/workflow/approval/approval.component';
import { NgxSignaturePadModule } from '@eve-sama/ngx-signature-pad';
import { SignatureReportComponent } from './components/externals/signature-report/signature-report.component';
import { TableFormComponent } from './models/field-templates/table-form/table-form.component';
import { TableFormPropertiesComponent } from './components/main/content/workflow/workflow-steps/widget-properties/table-form-properties/table-form-properties.component';
import { WidgetPropertiesFormComponent } from './components/main/content/workflow/workflow-steps/widget-properties/widget-properties-form/widget-properties-form.component';
import { TableReportComponent } from './components/main/content/report/table-report/table-report.component';
import { TableModalComponent } from './components/modals/table-modal/table-modal.component';
import { TableWidgetViewComponent } from './components/modals/user-workflow/table-widget-view/table-widget-view.component';
import { TableCellModalComponent } from './components/modals/user-workflow/table-cell-modal/table-cell-modal.component';
import { FormlyFieldCustomEwersForm } from '@models/field-templates/ewers-form.component';
import { FormWidgetViewComponent } from './components/modals/user-workflow/form-widget-view/form-widget-view.component';
import { whiteLabelStylesInitializer } from './models/PrimaryColor';
import { InputWidgetComponent } from './components/modals/user-workflow/widget-components/input-widget/input-widget.component';
import { NumberWidgetComponent } from './components/modals/user-workflow/widget-components/number-widget/number-widget.component';
import { DatepickerWidgetComponent } from './components/modals/user-workflow/widget-components/datepicker-widget/datepicker-widget.component';
import { RadioWidgetComponent } from './components/modals/user-workflow/widget-components/radio-widget/radio-widget.component';
import { CheckboxWidgetComponent } from './components/modals/user-workflow/widget-components/checkbox-widget/checkbox-widget.component';
import { GalleryWidgetComponent } from './components/modals/user-workflow/widget-components/gallery-widget/gallery-widget.component';
import { PdfWidgetComponent } from './components/modals/user-workflow/widget-components/pdf-widget/pdf-widget.component';
import { MediaWidgetComponent } from './components/modals/user-workflow/widget-components/media-widget/media-widget.component';
import { QrWidgetComponent } from './components/modals/user-workflow/widget-components/qr-widget/qr-widget.component';
import { MediaInstructionsWidgetComponent } from './components/modals/user-workflow/widget-components/media-instructions-widget/media-instructions-widget.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { QrLinkModalComponent } from './components/modals/qr-link-modal/qr-link-modal.component';
import { FormlyFieldCustomWorkflow } from '@models/field-templates/workflow.component';
import { FormlyFieldCustomID } from '@models/field-templates/id.component';
import { HtmlWidgetComponent } from './components/modals/user-workflow/html-widget/html-widget.component';
import { FolderInfoModalComponent } from './components/modals/folder-info-modal/folder-info-modal.component';
import { FolderListModalComponent } from './components/modals/folder-list-modal/folder-list-modal.component';
import { LocalizedDateShortPipe } from './pipes/localize-date-short.pipe';
import { UserDashboardComponent } from './components/main/content/user-dashboard/user-dashboard.component';
import { ResizeDirective } from './components/main/content/user-dashboard/resize.directive';
import { StepFilesComponent } from './components/main/content/workflow/workflow-steps/step-files/step-files.component';
import { DashboardFilterComponent } from './components/common/dashboard-filter/dashboard-filter.component';
// import { VideoUploadModalComponent } from './components/modals/video-upload-modal/video-upload-modal.component';
// import { VideoFlowDashboardComponent } from './components/main/content/video-flow-dashboard/video-flow-dashboard.component';
// import { WorkflowFromVideoComponent } from './components/main/content/workflow/workflow-from-video/workflow-from-video.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { WebviewLoginComponent } from './components/externals/webview-login/webview-login.component';
import { WorkOrdersComponent } from './components/main/content/work-orders/work-orders.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { WorkOrderDetailComponent } from './components/main/content/work-orders/work-order-detail/work-order-detail.component';
import { FoldersComponent } from './components/main/content/folders/folders.component';
import { RoomWrapperComponent } from './components/main/content/room-wrapper/room-wrapper.component';
import { WorkflowFromVideoComponent } from './components/main/content/workflow/workflow-from-video-2/workflow-from-video-2.component';
import { VideoFlowDashboardComponent } from './components/main/content/video-flow-dashboard-2/video-flow-dashboard-2.component';
import { VideoUploadModalComponent } from './components/modals/video-upload-modal-2/video-upload-modal-2.component';
import { WorkOrderFilterComponent } from './components/common/work-order-filter/work-order-filter.component';
import { CommentComponent } from './components/common/comment/comment.component';
import { QUILL_DEFAULT_ALIGNMENTS, QUILL_DEFAULT_FONT_SIZES } from '@models/QuillConfig';
import { RoomsComponent } from './components/main/content/rooms/rooms.component';
import { SessionsComponent } from './components/main/content/sessions/sessions.component';
import { MeetingsComponent } from './components/main/content/meetings/meetings.component';
import { RoomDetailComponent } from './components/main/content/rooms/room-detail/room-detail.component';
import { ArchivePermissionModalComponent } from './components/modals/remote-control/archive-permission-modal/archive-permission-modal.component';
import { NotSupportedModalComponent } from './components/modals/remote-control/not-supported-modal/not-supported-modal.component';
import { NoDeviceModalComponent } from './components/modals/remote-control/no-device-modal/no-device-modal.component';
import { JoinModalComponent } from './components/modals/remote-control/join-modal/join-modal.component';
import { ConvertUtcPipe } from './pipes/convert-utc.pipe';
import { FakeDisabledDirective } from './directives/fake-disabled.directive';
import { SessionDurationPipe } from './pipes/session-duration.pipe';
import { UserNamesPipe } from './pipes/user-names.pipe';
import { UtcCalcPipe } from './pipes/utc-calc.pipe';
import { UtcCalcAccountPipe } from './pipes/utc-calc-account.pipe';
import { RoomNamePipe } from './pipes/room-name.pipe';
import { FilterGuestsPipe } from './pipes/filter-guests.pipe';
import { GetIdsPipe } from './pipes/GetIdsPipe';
import { SessionDetailsComponent } from './components/main/content/sessions/session-details/session-details.component';
import { SessionChatComponent } from './components/main/content/sessions/session-chat/session-chat.component';
import { SessionFileComponent } from './components/main/content/sessions/session-file/session-file.component';
import { ArchivesComponent } from './components/main/content/sessions/archives/archives.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { CallUsersComponent } from './components/main/content/call-users/call-users.component';
import { RemoteAssistanceComponent } from './components/main/content/remote-assistance/remote-assistance.component';
import { UserSessionsComponent } from './components/main/content/user-sessions/user-sessions.component';
import { SessionListComponent } from './components/main/content/session-list/session-list.component';
import { TagInputModule } from 'ngx-chips';
import { StorageComponent } from './components/main/content/storage/storage.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { ContactListModalComponent } from './components/modals/contact-list-modal/contact-list-modal.component';
import { UserRoomsComponent } from './components/main/content/user-rooms/user-rooms.component';
import { UserListModalComponent } from './components/modals/user-list-modal/user-list-modal.component';
import { JoinWithLinkComponent } from './components/externals/join-with-link/join-with-link.component';

registerLocaleData(localeTR);
registerLocaleData(localeDE);
registerLocaleData(localeCS);
registerLocaleData(localeSK);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DashboardComponent,
    NavbarComponent,
    WorkflowComponent,
    StepListComponent,
    StepPreviewComponent,
    FormlyFieldCustomImage,
    FormlyFieldCustomLabel,
    FormlyFieldCustomDate,
    FormlyFieldCustomVideo,
    FormlyFieldCustomNumber,
    InputSelectOnFocusDirective,
    ObjectToArrayPipe,
    DynamicTooltipDirective,
    SafeHtml,
    CopyClipboardDirective,
    TutorialDirective,
    AddNativeElementDirective,
    InputSelectOnCreateDirective,
    FormlyFieldCustomRadio,
    FormlyFieldCustomQR,
    FormlyFieldCustomCaptureImage,
    FormlyFieldCustomCaptureVideo,
    FormlyFieldCustomCheckbox,
    AlertModalComponent,
    FormlyFieldCustomEmpty,
    StepListComponent,
    ImageCollaborationComponent,
    ImageAnnotationCanvasComponent,
    ImageViewerComponent,
    TemplateModalComponent,
    LoginFromAdminComponent,
    NotFoundComponent,
    SidebarComponent,
    MobileReportComponent,
    ReportListComponent,
    ReportComponent,
    SettingsComponent,
    AddWebhookModalComponent,
    ImageModalComponent,
    LinebreaksPipe,
    WorkflowDetailComponent,
    WorkflowConnectionsComponent,
    WorkflowUsersModalComponent,
    WidgetPropertiesComponent,
    FileDropDirective,
    SuccessMessageComponent,
    ErrorMessageComponent,
    ImageEditorComponent,
    FormlyFieldCustomInput,
    LoginComponent,
    EnvPipe,
    LocalizedDatePipe,
    UserListComponent,
    WorkflowsModalComponent,
    PreviewModalComponent,
    TypeofPipe,
    UserDetailComponent,
    ResizeDirective,
    ImageCropperComponent,
    ReportFileComponent,
    FormlyFieldCustomPdf,
    SimplePasswordValidatorDirective,
    UserWorkflowComponent,
    UserReportsComponent,
    SectionModalComponent,
    FilerobotIimageEditorComponent,
    LicenceWarningModalComponent,
    GuestAccessComponent,
    GuestLoginWithLinkComponent,
    GuestWorkflowComponent,
    JsonViewerComponent,
    HtmlToPlaintextPipe,
    FormlyFieldCustomRichText,
    DefaultLanguageModalComponent,
    WorkflowNewLanguageModalComponent,
    UserDefaultContentLanguageModalComponent,
    FormlyFieldCustomGallery,
    OpenAiModalComponent,
    RemoteWorkflowComponent,
    SignaturePadModalComponent,
    ApprovalComponent,
    SignatureReportComponent,
    TableFormComponent,
    TableFormPropertiesComponent,
    WidgetPropertiesFormComponent,
    TableReportComponent,
    TableModalComponent,
    TableWidgetViewComponent,
    TableCellModalComponent,
    FormlyFieldCustomEwersForm,
    FormWidgetViewComponent,
    InputWidgetComponent,
    NumberWidgetComponent,
    DatepickerWidgetComponent,
    RadioWidgetComponent,
    CheckboxWidgetComponent,
    GalleryWidgetComponent,
    PdfWidgetComponent,
    MediaWidgetComponent,
    QrWidgetComponent,
    MediaInstructionsWidgetComponent,
    DropdownComponent,
    QrLinkModalComponent,
    FormlyFieldCustomWorkflow,
    FormlyFieldCustomID,
    HtmlWidgetComponent,
    FolderInfoModalComponent,
    FolderListModalComponent,
    LocalizedDateShortPipe,
    UserDashboardComponent,
    StepFilesComponent,
    DashboardFilterComponent,
    VideoUploadModalComponent,
    VideoFlowDashboardComponent,
    WorkflowFromVideoComponent,
    WebviewLoginComponent,
    WorkOrdersComponent,
    FileUploadComponent,
    WorkOrderDetailComponent,
    FoldersComponent,
    RoomWrapperComponent,
    WorkflowFromVideoComponent,
    VideoFlowDashboardComponent,
    VideoUploadModalComponent,
    DashboardFilterComponent,
    WorkOrderFilterComponent,
    CommentComponent,
    RoomsComponent,
    SessionsComponent,
    MeetingsComponent,
    // ReportsChartComponent,
    RoomDetailComponent,
    ArchivePermissionModalComponent,
    NotSupportedModalComponent,
    NoDeviceModalComponent,
    JoinModalComponent,
    FakeDisabledDirective,
    ConvertUtcPipe,
    SessionDurationPipe,
    UserNamesPipe,
    UtcCalcPipe,
    UtcCalcAccountPipe,
    RoomNamePipe,
    FilterGuestsPipe,
    GetIdsPipe,
    SessionDetailsComponent,
    SessionChatComponent,
    SessionFileComponent,
    ArchivesComponent,
    FileSizePipe,
    DurationFormatPipe,
    CallUsersComponent,
    RemoteAssistanceComponent,
    UserSessionsComponent,
    SessionListComponent,
    StorageComponent,
    ModalComponent,
    ContactListModalComponent,
    UserRoomsComponent,
    UserListModalComponent,
    JoinWithLinkComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    QuillModule.forRoot({
      customOptions: [{
        import: 'formats/size',
        whitelist: QUILL_DEFAULT_FONT_SIZES,
      }, {
        import: 'formats/align',
        whitelist: QUILL_DEFAULT_ALIGNMENTS,
      }]
    }),

    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule,
    FormlyModule.forRoot(CustomTypes),
    FormlyBootstrapModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    QRCodeModule,
    TooltipModule.forRoot(),
    DragDropModule,
    MatSnackBarModule,
    NgxJsonViewerModule,
    TimepickerModule.forRoot(),
    TranslateModule.forRoot(),
    ImageCropperModule,
    PdfViewerModule,
    NgxScannerQrcodeModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxSignaturePadModule,
    ProgressbarModule.forRoot(),
    TagInputModule
  ],
  providers: [ 
    DatePipe,
    HtmlToPlaintextPipe,
    ConvertUtcPipe,
    SessionDurationPipe,
    UserNamesPipe,
    UtcCalcPipe,
    UtcCalcAccountPipe,
    RoomNamePipe,
    FilterGuestsPipe,
    GetIdsPipe,
    FileSizePipe,
    DurationFormatPipe, { provide: APP_INITIALIZER, useFactory: whiteLabelStylesInitializer, multi: true },],
  bootstrap: [AppComponent]
})

export class AppModule { }
