import { Directive, Input, ElementRef, OnInit, OnDestroy, Renderer2, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TutorialService } from '@services/support/tutorial.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[tutorial]',
  exportAs: 'tutorial'
})
export class TutorialDirective implements OnInit, OnDestroy, OnChanges {
    @Input('tutorial') tutorial: string;
    @Input('ignored') ignored: boolean = false;
    @Output() change =  new EventEmitter<boolean>();

    private tutorialSub: Subscription = null;
    private tutorialOpen: boolean = false;
    private tutorialActive: boolean = false;

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2,
        private tutorialService: TutorialService
    ) { }

    ngOnInit() {
        this.tutorialSub = this.tutorialService.tutorial
        .subscribe(t => {
            this.tutorialActive = t && t[this.tutorial];

            if (this.tutorialActive) {
                if (!(this.ignored || this.tutorialOpen)) {
                    this.tutorialOpen = true;
                    this.change.emit(true);
                    this.renderer.addClass(this.elRef.nativeElement, "tutorial-active");
                }
            } else if (this.tutorialOpen) {
                this.tutorialOpen = false;
                this.change.emit(false);
                this.renderer.removeClass(this.elRef.nativeElement, "tutorial-active");
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ignored']) {
            this.ignored = changes['ignored'].currentValue;

            if (this.ignored) {
                if (this.tutorialOpen) {
                    this.tutorialOpen = false;
                    this.change.emit(false);
                    this.renderer.removeClass(this.elRef.nativeElement, "tutorial-active");
                }
            } else if (this.tutorialActive) {
                this.tutorialOpen = true;
                this.change.emit(true);
                this.renderer.addClass(this.elRef.nativeElement, "tutorial-active");
            }
        }
    }

    ngOnDestroy() {
        if (this.tutorialSub) { this.tutorialSub.unsubscribe() }
    }

    next(tags: {[tag: string]: true}) {
        if (this.tutorialActive) { this.tutorialService.currentController.next(tags) }
    }

    end() {
        if (this.tutorialActive) { this.tutorialService.currentController.end() }
    }
}
