import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-cell-modal',
  templateUrl: './table-cell-modal.component.html',
  styleUrls: ['./table-cell-modal.component.scss']
})
export class TableCellModalComponent implements OnInit {
  @Input() selectedCell?
  @Input() cellControl?

  @Output() onCellUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  cellFormControl= new UntypedFormControl()
  cellFormGroup= new UntypedFormGroup({})

  formData: any = {}

  subscriptions: Subscription[] = []


  constructor() { }

  ngOnInit(): void {

    this.cellFormControl.patchValue(this.cellControl.value)
    if(this.selectedCell.type == 'multicheckbox') {
    }
    else if(this.selectedCell.type == 'datepicker') {
      this.cellFormControl.patchValue(this.cellControl.value)
    }
    else if(this.selectedCell.type == 'number') {
      this.subscriptions.push(this.cellControl.valueChanges.subscribe((value) => {
        const numberOutput = value.numberSecond ? (value.numberFirst + '.' + value.numberSecond) : value.numberFirst
        if (numberOutput !== value.numberOutput) {
          this.cellControl.controls.numberOutput.patchValue(numberOutput)
        }
      }))
    }else{
      this.cellFormControl.patchValue(this.cellControl.value)
    }
  }

  saveCell() {
    if(this.selectedCell.type == 'multicheckbox' ||  this.selectedCell.type == 'number') {
      this.onCellUpdated.emit(this.cellControl.value)
    }else{
      this.onCellUpdated.emit(this.cellFormControl.value)
    }
    // this.onClose.emit(true)

  }

  cancel() {
    if(this.selectedCell.type == 'multicheckbox' || this.selectedCell.type == 'number') {
      this.cellControl.patchValue(this.cellFormControl.value)
    }
    this.onClose.emit(true)
  }

  clear() {
    this.cellFormControl.patchValue(null)
  }

}
