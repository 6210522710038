<div class="main-wrapper" [ngClass]="{'isMobile' : userWfIsOpen || reportIsOpen}" [ngStyle]="{'padding-left': sidebarClosed ? '0' : ''}">
    <app-sidebar [ngStyle]="{'left': sidebarClosed ? '-220px' : '', 'display':userWfIsOpen ? 'none' : '' }" [isAdmin]="isAdmin" [isEditorMode]="isEditorMode"></app-sidebar>
    <router-outlet></router-outlet>

    <template #detailContainer></template>
</div>

<ng-template #sessionExportTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog modal-dialog-wide session-export-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header px-4">
                <h5 class="modal-title">{{ 'MODALS.GET_EXPORT_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-4">
                <div class="mb-3 ml-1" style="font-size: 14px;">{{ 'MODALS.GET_EXPORT_MODAL.MESSAGE1' | translate }}</div>
                <div class="mb-3 ml-1" style="font-size: 14px;" [innerHTML]="'MODALS.GET_EXPORT_MODAL.MESSAGE2' | translate"></div>
                <div class="form-group mb-1">
                    <div class="input-group">
                        <input type="text" class="form-control" [class.is-invalid]="dataModel.exportName.length === 0" [disabled]="isExportNameLocked" style="font-size: 14px;" maxlength="72" required
                            placeholder="{{ 'MODALS.GET_EXPORT_MODAL.EXPORT_NAME_PLACEHOLDER' | translate }}" [(ngModel)]="dataModel.exportName" (ngModelChange)="onExportNameChange($event)">
                        <div class="input-group-append">
                            <span class="input-group-text" style="font-size: 14px; cursor: default;">.zip</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isExportNameLocked" class="ml-1" style="font-size: 14px;">{{ 'MODALS.GET_EXPORT_MODAL.EXPORT_NAME_NOT_LOCKED' | translate }} <span *ngIf="exportNameAuthor"> {{ 'MODALS.GET_EXPORT_MODAL.EXPORT_NAME_RENAMED' | translate }} {{ exportNameAuthor }}</span></div>
                <div *ngIf="isExportNameLocked" class="ml-1" style="font-size: 14px;">{{ 'MODALS.GET_EXPORT_MODAL.EXPORT_NAME_LOCKED' | translate }} <span *ngIf="exportNameAuthor"> {{ 'MODALS.GET_EXPORT_MODAL.EXPORT_NAME_RENAMED' | translate }} {{ exportNameAuthor }}</span></div>
                <div class="form-group mt-3 mb-0">
                    <tag-input #tagInput name="emailRecipients" style="font-size: 14px;" [(ngModel)]="dataModel.emailRecipients" placeholder="{{ 'MODALS.EMAIL_RECIPIENTS.PLACEHOLDER' | translate }}"
                        secondaryPlaceholder="{{ 'MODALS.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER' | translate }}" theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError"
                        [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
                </div>

                <div class="mt-3 ml-1 text-info" style="font-size: 14px;"><span>{{ 'MODALS.GET_EXPORT_MODAL.NOTE' | translate }}</span><!--<a (click)="callbacks.openProfile()" class="ml-1" style="cursor: pointer; text-decoration: underline;">{{ 'MODALS.GET_EXPORT_MODAL.OPEN_PROFILE' | translate }}</a>--></div>
            </div>
            <div class="modal-footer px-4 d-flex justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary" (click)="callbacks.close()">
                    {{ 'MODALS.GET_EXPORT_MODAL.CLOSE' | translate }}
                </button>
                <button type="button" class="v-btn btn vsight-button btn-primary create-button" [disabled]="dataModel.emailRecipients.length === 0 || dataModel.exportName.length === 0" (click)="callbacks.getexport()">
                    {{ 'MODALS.GET_EXPORT_MODAL.SEND' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>