<div>
    <div class="d-flex justify-content-center">
        <button class="btn btn-primary fs-13 font-weight-700 folder-create-button" (click)="createNewFolder()">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.CREATE-FOLDER' | translate }}</button>
    </div>
    <div class="fs-14 font-weight-400 cursor-pointer ps-4 folder-name-wrapper not-foldered-item d-flex align-items-center" [ngClass]="{'selected-folder': !selectedFolder?.name }" (click)="showNotFolderedWorkflows()">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.NOT-FOLDERED' | translate }}</div>
    <div *ngFor="let folder of folders" class="fs-14 font-weight-400 mt-2 d-flex justify-content-between align-items-center folder-name-wrapper" (click)="selectFolder(folder)" [ngClass]="{'selected-folder': selectedFolder?.name == folder.name}">
        <div class="d-flex cursor-pointer" >
            <img src="assets/img/folder-list.svg" alt="">
            <span class="ms-2 folder-name"  title="{{folder.name}}">{{folder.name}} 
                <span class="fs-14 text-light-grey">({{folder.workflows?.length}})</span>
            </span>
        </div>
    
        <div class="dropdown-wrapper" dropdown>
            <a id="stepListStepOptionsButton{{folder.name}}" class="btn btn-default name-wrapper" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                <img src="assets/img/more.svg" alt="" height="20px">
            </a>
            <div #stepListStepOptions class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink">
                <div >
                    <a class="dropdown-item folder-item" (click)="editFolder(folder)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.RENAME' | translate }}</a>
                    <a class="dropdown-item folder-item text-red" (click)="deleteFolder(folder)">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>