<form [formGroup]="radioGroup">
  <div class="d-flex flex-column h-100">
    <label class="label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="d-flex align-items-start mt-2 flex-wrap" style="height: 100%; overflow-y: scroll;">
      <div class="form-check w-100 text-start">
        <div class="row">
          <ng-container *ngFor="let option of item.templateOptions.options; let i=index">
            <div [ngClass]="{'col-md-6': item.templateOptions.options.length > 2, 'col-md-12': item.templateOptions.options.length <= 2}">
              <div class="custom-control ps-0 custom-radio" (click)="changeRadioCheck(option)">
                <input 
                type="radio" 
                class="form-check-input ms-0" 
                [value]="option.value" 
                formControlName="selection"
                [id]="'input-'+item.key+option.value"/>
                <label class="form-check-label label-form" [for]="'input-'+item.key+option.value">{{ option.label }}</label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <p class="validation-text pb-0 text-start" *ngIf="radioControl.invalid && radioControl.errors['required'] && radioControl.touched">{{ 'MAIN.CONTENT.WORKFLOW.REQUIRED' | translate }}</p>
  </div>
</form>