<input type="file" class="d-none" [accept]="acceptTypes" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput multiple>
<div class="upload-drop-zone" filedrop fileType="acceptTypes" (filesDropped)="dragDropUpload($event)" accept="*" id="drop-zone">
    <div class="upload-icon mt-3"><img src="assets/img/upload-icon.svg"></div>
    <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate}}</div>
    <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
    <div class="upload-button mb-4 mt-1">
        <button *ngIf="!('electron' | env)" class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="imageInput.click()" [disabled]="isDisabled">
            {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}
        </button>
    </div>
</div>