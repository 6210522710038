import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';

import { ImageCollaborationService } from '@services/remote/image-collaboration.service';

import { Subscription, Observable } from 'rxjs';

import { CollaborationData } from '@models/CollaborationData';
 
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  @Input('controlInput') controlInput: Observable<{ type: string, value: any }>;
  @Input('collaborationData') collaborationData: CollaborationData;

  @Output() img = new EventEmitter<HTMLImageElement>();

  @ViewChild('image', { static: true }) image:ElementRef;
  @ViewChild('container', { static: true }) container:ElementRef;

  controlSub: Subscription = null;
  resizeTimeout: any;

  // image related variables
  default_image_width: number;
  default_image_height: number;
  natural_image_width: number;
  natural_image_height: number;
  image_width: number;
  image_height: number;
  image_left: number = 0;
  image_top: number = 0;
  default_left: number;
  default_top: number;
  zoom_ratio: number;

  // container related variables
  container_width: number;
  container_height: number;
  pre_container_width: number;
  pre_container_height: number;
  isLoaded: boolean = false;
  pre_x: number;
  pre_y: number;
  first_x: number;
  first_y: number;
  temp_left: number;
  temp_top: number;

  old_xratio: number;               // old scroll x ratio
  old_yratio: number;               // old scroll y ratio
  mouseHold: boolean = false;

  constructor(
    private imageCollaborationService: ImageCollaborationService
  ) {
    this.zoom_ratio = 1;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
         
    // right
    if (event.keyCode === 39 && this.isLoaded) {
      this.imageCollaborationService.moveRight("keyboard");
    }

    // left
    if (event.keyCode === 37 && this.isLoaded) {
      this.imageCollaborationService.moveLeft("keyboard");
    }

    // up
    if (event.keyCode === 38 && this.isLoaded) {
      this.imageCollaborationService.moveUp("keyboard");
    }

    // down
    if (event.keyCode === 40 && this.isLoaded) {
      this.imageCollaborationService.moveDown("keyboard");
    }

    // plus zoom in
    if (event.keyCode === 107 && this.isLoaded) {
      this.imageCollaborationService.zoomIn();
    }

    // minus zoom out
    if (event.keyCode === 109 && this.isLoaded) {
      this.imageCollaborationService.zoomOut();
    }
  }

  ngOnDestroy(){
    if(this.controlSub) { this.controlSub.unsubscribe(); }

    // set collaboration control to null, because even if this component is destroyed, the service is still running in the background. 
    // So we need to make all the controls null in order to use later for different collaboration from scratch
    this.imageCollaborationService.setCollaborationControlToNull();

  }

  ngOnInit() {

    // set loading state 
    this.imageCollaborationService.setLoaded(false);

    // listen controls (subscribe)
    this.controlSub = this.controlInput.subscribe(control => {
      const method = control.type;
      const val = control.value;
      if(method=="zoomIn"){
        this.zoomIn(val);
      }
      else if(method=="zoomOut"){
        this.zoomOut(val);
      }
      else if(method=="scrollX"){
        this.scrollX(val);
      }
      else if(method=="scrollY"){
        this.scrollY(val);
      }
      else if(method=="resize"){
        this.resize();
      }
      else if(method=="sendInitialize"){
        this.sendInitialSize();
      }
      else if(method=="refresh" || method=="remove"){
        this.refresh();
      }
      else if(method=="calculateX"){
        this.calculateX(val);
      }
      else if(method=="calculateY"){
        this.calculateY(val);
      }
      else if(method=="mouseVariable"){
        this.changeMouseRelatedVariable();
      }
      else if(method=="moveFinished"){
        this.completeMove(val.dx, val.dy);
      }
      else if(method=="scrollImageFromMouse"){
        this.scrollImageFromMouse(val.left, val.top);
      }

    });
  }

  onImageLoad() {
    this.initialize();
    this.imageCollaborationService.setLoaded(true);
    this.imageCollaborationService.setImageLoaded();
    this.isLoaded = true;

    this.img.emit(this.image.nativeElement);
  }

  onProgress(event: any){
    
  }

  // this function calculates the left and top values which bring the image to the center of the container.
  centralize(){
    let x = this.container_width/2 - this.image_width/2;
    let y = this.container_height/2 - this.image_height/2;
    this.image.nativeElement.style.left = x+"px";
    this.image.nativeElement.style.top = y+"px";
    this.default_left = x;
    this.default_top = y;
    this.image_left = x;
    this.image_top = y;

  }
  resizeImage(){
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
        this.resize();
    }).bind(this), 100);
  }

  resize(){

   
    // Take Left and top values before resize
    let left = this.image.nativeElement.style.left;
    let top = this.image.nativeElement.style.top;
 
    // Take new height of the container and find out the width by using height
    this.container_height = this.container.nativeElement.clientHeight;
    this.container_width = this.container_height*4/3;

    // set the width 
    this.container.nativeElement.style.width=this.container_width+"px";
    this.container.nativeElement.style.margin="0 auto";

    // calculate the change ratio in container sizes
    let y_ratio = this.container_height/this.pre_container_height;
    let x_ratio = this.container_width/this.pre_container_width;


    // recalculate the default image width and height assuming this container sizes are the initial.
    let xRatio = this.natural_image_width / this.container_width;
    let yRatio = this.natural_image_height / this.container_height;

    if(xRatio > yRatio){

      this.default_image_width = this.container_width;
      this.default_image_height = this.natural_image_height*(this.container_width/this.natural_image_width);
    }
    else if(xRatio <= yRatio){

      this.default_image_width = this.natural_image_width*(this.container_height/this.natural_image_height);
      this.default_image_height = this.container_height;

    }

    // set image width and height
    this.image_width = this.default_image_width * this.zoom_ratio;
    this.image_height = this.default_image_height * this.zoom_ratio;

    this.image.nativeElement.style.width = ""+this.image_width+"px";
    this.image.nativeElement.style.height = ""+this.image_height+"px";

    // calculate the new left and top
    this.image.nativeElement.style.left = parseFloat(left)*x_ratio+"px";
    this.image.nativeElement.style.top = parseFloat(top)*y_ratio+"px";
    this.pre_container_width = this.container_width;
    this.pre_container_height = this.container_height;
    this.image_left = parseFloat(left)*x_ratio;
    this.image_top = parseFloat(top)*y_ratio;

    left = parseFloat(this.image.nativeElement.style.left);
    top = parseFloat(this.image.nativeElement.style.top);

    setTimeout(() =>{
      this.scrollX(this.old_xratio);
      this.scrollY(this.old_yratio);
    },100);

    // send new sizes to annotation canvas
    this.imageCollaborationService.resize({width: this.image_width, height: this.image_height, left: left, top: top, zoom_ratio: this.zoom_ratio, container_width: this.container_width});
  }

  sendInitialSize(){

    this.imageCollaborationService.setInitializeImage({width: this.default_image_width, height: this.default_image_height, 
      container_width: this.container_width, container_height: this.container_height});
    
  }

  initialize(){

    // take the height of the container and derive the container width
    this.container_height = this.container.nativeElement.clientHeight;
    this.container_width = this.container_height*4/3;

    this.pre_container_width = this.container_width;
    this.pre_container_height = this.container_height;

    // take the natural width and height of the image
    this.natural_image_height = this.image.nativeElement.naturalHeight;
    this.natural_image_width = this.image.nativeElement.naturalWidth;

    // calculate the ratios of the sides to fit the image to the center of the container (horizontal and vertical)
    let xRatio = this.natural_image_width / this.container_width;
    let yRatio = this.natural_image_height / this.container_height;

    // if x is bigger, horizontally fit
    if(xRatio > yRatio){

      this.default_image_width = this.container_width;
      this.default_image_height = this.natural_image_height*(this.container_width/this.natural_image_width);

    }

    // if y is bigger, vertically fit
    else if(xRatio <= yRatio){

      this.default_image_width = this.natural_image_width*(this.container_height/this.natural_image_height);
      this.default_image_height = this.container_height;

    }

    this.image_width=this.default_image_width;
    this.image_height=this.default_image_height;

    // set the image width and height
    this.image.nativeElement.style.width = ""+this.image_width+"px";
    this.image.nativeElement.style.height = ""+this.image_height+"px";

    // send sizes to the annotation canvas component
    this.sendInitialSize();

    // bring image to the center of the container
    this.centralize();

    // if collaboration is already in used, then get the final state of the image.
    this.imageCollaborationService.getOldChanges();
  }

  onDocumentMouseEnter(e){
    this.mouseHold = false;
    this.container.nativeElement.style.cursor = "grab";
  }
  onDocumentMouseLeave(e){
    if(this.mouseHold){
      this.calculateX(this.pre_x-this.first_x);
      this.calculateY(this.pre_y - this.first_y)
      this.container.nativeElement.style.cursor = "default";
    }

    this.changeMouseRelatedVariable();
  }
  onDocumentMouseUp(e){
    this.container.nativeElement.style.cursor = "grabbing";
    if(this.mouseHold){
      this.calculateX(this.pre_x - this.first_x);
      this.calculateY(this.pre_y - this.first_y)
    }

    this.changeMouseRelatedVariable();
  }
  onDocumentMouseDown(e){
    this.mouseHold = true;
    this.container.nativeElement.style.cursor = "grab";

  }
  onDocumentMouseMove(e){

    if(this.mouseHold){
      this.container.nativeElement.style.cursor = "grabbing";
      if(!this.first_x || !this.first_y){
        this.first_x = e.layerX;
        this.first_y = e.layerY;
  
        this.pre_x = e.layerX;
        this.pre_y = e.layerY;
      }
  
      if(!this.temp_left || !this.temp_top){
        this.temp_left = parseFloat(this.image.nativeElement.style.left);
        this.temp_top = parseFloat(this.image.nativeElement.style.top);
  
      }
      this.temp_left += (e.layerX - this.pre_x);
      this.temp_top += (e.layerY - this.pre_y);
      this.image.nativeElement.style.left = this.temp_left+"px";
      this.image.nativeElement.style.top = this.temp_top+"px";
      
  
      this.pre_x = e.layerX;
      this.pre_y = e.layerY;   
    }
    else    
      this.container.nativeElement.style.cursor = "grab";

  }


  scrollImageFromMouse(left, top){
    this.image.nativeElement.style.left = left+"px";
    this.image.nativeElement.style.top = top+"px";
  }

  completeMove(dx,dy){
    this.calculateX(dx);
    this.calculateY(dy);
  }
  changeMouseRelatedVariable(){
    this.mouseHold = false;
    this.pre_x = null;
    this.pre_y = null;
    this.first_x = null;
    this.first_y = null;
    this.temp_left = null;
    this.temp_top = null;
  }

  onDocumentMouseWheel(e){
    if(e.wheelDelta>0){
      //zoom-in
      this.imageCollaborationService.zoomIn(1.2);

      /*
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        let left = this.image_left;
        let width = this.image_width;
    
        // calculate mouse x_ratio
        let x_ratio = (-left+e.layerX)/width;
        this.imageCollaborationService.updateXRatio(x_ratio);
  
        let top = this.image_top;
        let height = this.image_height;
  
        // calculate current y_ratio
        let y_ratio = (-top+this.container_height/2)/height;
        this.imageCollaborationService.updateYRatio(y_ratio);

        console.log("update");
      }).bind(this), 50);
      */

      
    }
    else if(e.wheelDelta<0){
      // zoom-out
      this.imageCollaborationService.zoomOut(1.2);
    }
  }

  calculateCurrentX(){

    let left = this.image_left;
    let width = this.image_width;

    // calculate current x_ratio
    let x_ratio = (-left+this.container_width/2)/width;
    return x_ratio;

  }

  calculateX(value: number){

    // take the left margin of the image
    let left = this.image_left;
    let width = this.image_width;

    // add the scroll amount to the left. Result is new left
    left +=  value;

    // checks whether the image fits to the container. If not update the left to fit to container
    if(left>0){
      if(width<this.container_width)
        left-=value;
      else{
        left = 0;
      }
    }
    else if(this.container_width - (left+width) > 0 ){
      if(width<this.container_width)
        left-=value;
      else
        left = this.container_width - width;
    } 

    // calculate the x_ratio with the new value of the left    
    let x_ratio = (-left+this.container_width/2)/width;

    this.scrollX(x_ratio);

    // update database
    this.imageCollaborationService.updateXRatio(x_ratio);
  
  }

  scrollX(x_ratio: number){

    if(!x_ratio){
      x_ratio = 0.5;
    }
    let width = this.image_width;

    // calculate the new left margin with the given ratio
    let left = x_ratio*width-(this.container_width/2);

    // change the left margin
    this.image.nativeElement.style.left = -left+"px";
    this.old_xratio = x_ratio;

    this.image_left = -left;
  }

  calculateCurrentY(){

    let top = this.image_top;
    let height = this.image_height;

    // calculate current y_ratio
    let y_ratio = (-top+this.container_height/2)/height;
    return y_ratio;

  }

  calculateY(value: number){

    // take the left margin of the image
    let top = this.image_top;
    let height = this.image_height;

    // add the scroll amount to the left. Result is new left
    top +=  value;

    // checks whether the image fits to the container. If not update the top to fit to container
    if(top>0){
      if(height<this.container_height)
        top-=value;
      else
        top = 0;
    }
    else if(this.container_height - (top+height) > 0 ){
      if(height<this.container_height)
        top-=value;
      else
        top = this.container_height - height;
    }

    // calculate the x_ratio with the new value of the left    
    let y_ratio = (-top+this.container_height/2)/height;

    this. scrollY(y_ratio);
     
    // update database
    this.imageCollaborationService.updateYRatio(y_ratio);
  
  }
 
  scrollY(y_ratio: number){

    if(!y_ratio){
      y_ratio = 0.5;
    }
    let height = this.image_height;
    // calculate the new top margin with the given ratio
    let top = y_ratio*height-(this.container_height/2);

    // change the top margin
    this.image.nativeElement.style.top = -top+"px";
    this.old_yratio = y_ratio;

    this.image_top = -top;
  }
  
   zoomIn(value: number){

    this.zoom_ratio = value;

    // calculate the current ratios. We need them after width and height are changed.
    const curr_x_ratio = this.calculateCurrentX();
    const curr_y_ratio = this.calculateCurrentY();
    
    // change the width and height of the image.
    this.image_width = this.default_image_width * value;
    this.image_height = this.default_image_height * value;
    this.image.nativeElement.style.width = ""+this.image_width+"px";
    this.image.nativeElement.style.height = ""+this.image_height+"px";

    // focus the image to the point before the zoom. This point is the center of the container.
    this.scrollX(curr_x_ratio);
    this.scrollY(curr_y_ratio);

   }
 
   zoomOut(value: number){

    this.zoom_ratio = value;

    // calculate the current ratios.
    let curr_x_ratio = this.calculateCurrentX();
    let curr_y_ratio = this.calculateCurrentY();

    // calculate the new ratios after zoom out. The logic is that the ratios should get close to default values which is 0.5
    curr_x_ratio  = curr_x_ratio + (0.5 - curr_x_ratio)/value;
    curr_y_ratio  = curr_y_ratio + (0.5 - curr_y_ratio)/value;

    // change the width and height of the image.
    this.image_width = this.default_image_width * value;
    this.image_height = this.default_image_height * value;
    this.image.nativeElement.style.width = ""+this.image_width+"px";
    this.image.nativeElement.style.height = ""+this.image_height+"px";

    // scroll the image to the new point
    this.scrollX(curr_x_ratio);
    this.scrollY(curr_y_ratio);

    // write this new ratios to the database.
    this.imageCollaborationService.updateXRatio(curr_x_ratio);
    this.imageCollaborationService.updateYRatio(curr_y_ratio);

   }
 
   refresh(){
    
    // set the dafault values
    this.image_width = this.default_image_width;
    this.image_height = this.default_image_height;
    this.image.nativeElement.style.width = ""+this.image_width+"px";
    this.image.nativeElement.style.height = ""+this.image_height+"px";
 
   }
 
}
