<form class="w-100 h-100">
    <table class="w-100 h-100">
        <thead>
            <th></th>
            <th *ngFor="let column of tableData?.columns[0].cells;let columnIndex = index;">
                <button class="btn btn-light btn-column" style="height: 25px !important;" (click)="deleteColumn(columnIndex)">
                    <span>{{ columnIndex + 1 }}</span>
                    <img src="assets/img/trash-icon.svg" width="13" height="13" alt="">
                </button>
            </th>
        </thead>
        <tbody>
            <!-- Column Header Info Row -->
            <tr *ngIf="tableData?.rows?.length > 0">
                <td>
                    <button class="btn btn-light btn-row-column" >
                    </button>
                </td>
                <td class="text-center has-border" *ngFor="let column of tableData?.columns[0].cells; let columnIndex = index;"
                (click)="selectCell(0, columnIndex, column, true)">
                   <strong> {{ workflowTranslations[selectedLang]?.[column.templateOptions.label] || 'Column ' + columnIndex + 1 }}</strong>
                </td>
            </tr>
            <!-- Column Header Info Row -->

            <tr *ngFor="let row of tableData?.rows; let rowIndex = index;">
                <td>
                    <button class="btn btn-light btn-row-column" (click)="deleteRow(rowIndex)">
                        <span>{{ rowIndex + 1 }}</span>
                        <img src="assets/img/trash-icon.svg" width="13" height="13" alt="">
                    </button>
                </td>
                <td *ngFor="let column of row.cells; let columnIndex = index;"
                    [ngClass]="{'is-selected' : column.key === selectedCell?.key, 'has-border' : column.key !== selectedCell?.key}"
                    (click)="selectCell(rowIndex, columnIndex, column)" class="p-relative">
                    <div class="d-flex align-items-center justify-content-center"
                        *ngIf="column.templateOptions?.label && workflowTranslations[selectedLang]?.[column.templateOptions.label]">
                        {{ workflowTranslations[selectedLang]?.[column.templateOptions.label] }}
                    </div>
                    <div class="input-area" (click)="selectCell(rowIndex, columnIndex, column)"></div>
                    <div class="d-flex align-items-center justify-content-center me-1 ms-1" *ngIf="column.type=='input'"
                        style="padding:10px 5px" >
                        <input type="text" style="width: 125px;;
                        height: 40px;     height: 50%; " class="form-control custom-form-control" id="form-control"
                            placeholder="{{ workflowTranslations[selectedLang]?.[column.templateOptions.placeholder]}}"
                            autocomplete="off" disabled>
                    </div>
                    <!-- <span
                        *ngIf="column.templateOptions?.placeholder && workflowTranslations[selectedLang]?.[column.templateOptions.placeholder]"
                        class="text-grey fs-12">
                        {{ workflowTranslations[selectedLang]?.[column.templateOptions.placeholder]}}
                    </span> -->
                    <div *ngIf="column.type=='datepicker'" class="d-flex align-items-center justify-content-center"
                        >
                        <div class="input-group me-1 ms-1" style="height:36px;min-width: 125px;width: 125px;">
                            <input  #startDate="bsDatepicker" name="startDate"
                            class="form-control custom-form-control" id="form-control" bsDatepicker autocomplete="off"
                            placeholder="{{workflowTranslations[selectedLang]?.[column.templateOptions.placeholder]}}"
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }"
                            onkeypress="return false;" disabled style="height: 36px;">
                            <span class="input-group-text" id="basic-addon2"><img src="assets/img/datepicker-black.svg"></span>
                          </div>
                        
                    </div>

                    <div class="flex-column" style="position:relative;display: flex;" *ngIf="column.type=='number'">
                        <div class="d-flex justify-content-center align-items-center me-1 ms-1" [ngClass]="{'mt-4' : column.templateOptions.min || column.templateOptions.max}">
                            <input type="number"  class="form-control number custom-form-control" id="form-control"  placeholder="{{workflowTranslations[selectedLang]?.[column.templateOptions.placeholder]}}" 
                            autocomplete="off" description="xx" [ngStyle]="column.templateOptions['precision'] && {'min-width':'50px'}" disabled>
                            <span style="margin-left:6px;margin-right:6px;padding-top: 29px;" class="label-preview" *ngIf="column.templateOptions['precision']!== null && column.templateOptions['precision']">.</span>
                            <input type="number"  class="form-control number" id="form-control" 
                            autocomplete="off" *ngIf="column.templateOptions['precision']!== null && column.templateOptions['precision']" style="min-width: 50px;" disabled>
                            <span style="margin-left:2px;width: auto;" class="label-preview fs-12" *ngIf="column.templateOptions['unit']!== null && column.templateOptions['unit']">{{column.templateOptions['unit']}}</span>
                        </div>
                        <div class="d-flex align-items-start">
                            <label *ngIf="column.templateOptions.min || column.templateOptions.min == 0" class="label-preview-title fs-12 pb-1">Min : {{column.templateOptions.min}}</label>
                            <label *ngIf="column.templateOptions.max || column.templateOptions.max == 0" class="label-preview-title ps-2 fs-12 pb-1">Max : {{column.templateOptions.max}}</label>
                        </div>
                    </div>
                   
                    <div class="d-flex align-items-center justify-content-center" *ngIf="column.type=='empty'">
                        <span style="min-width: 90px"></span>
                    </div>


                    <div *ngIf="column.type=='multicheckbox'" class="d-flex align-items-center justify-content-start ms-2">
                        <div class="form-check">
                            <div *ngFor="let option of column.templateOptions?.options">
                                <input type="checkbox" class="form-check-input" [id]="'input-'+option.value"
                                    [value]="option.value" disabled />
                                <label class="form-check-label" [for]="'input-'+option.value"
                                    *ngIf="workflowTranslations[selectedLang]?.[option.label]">
                                    {{ workflowTranslations[selectedLang]?.[option.label] }}
                                </label>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="column.type=='radio'" class="d-flex align-items-center justify-content-start ms-2">
                        <div class="form-check">
                            <div *ngFor="let option of column.templateOptions?.options">
                                <input
                                type="radio"
                                class="form-check-input"
                                [id]="'input-'+option.value"
                                [value]="option.value"
                                disabled
                              />
                              <label class="form-check-label" [for]="'input-'+option.value">
                                {{ workflowTranslations[selectedLang]?.[option.label] }}
                              </label>
                            </div>
                        </div>

                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</form>