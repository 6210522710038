import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, first, timeout, switchMap } from 'rxjs/operators';
import { RoomData } from '../../models/RoomData';
import { OwnRoom, Room } from '../../models/Room';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@services/auth.service';
import { OwnRoomData } from '@models/OwnRoomData';
import { DbService } from '@services/db.service';


@Injectable({
  providedIn: 'root'
})
export class RoomService {

  allRooms: Observable<Room[]>;
  rooms: Observable<Room[]>;
  private allRoomsSource = new BehaviorSubject<Room[]>(null);
  private allRoomsSub: Subscription = null;

  allOwnRooms: Observable<any>;
  myRooms: Observable<Room[]>;
  private allOwnRoomsSource: BehaviorSubject<any> = new BehaviorSubject(null);
  private allOwnRoomsSub: Subscription = null;

  defaultRoomIcon: string = null;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private dbService: DbService
  ) {
    this.allRooms = this.allRoomsSource.asObservable().pipe(filter(rooms => !!rooms));
    this.rooms = this.allRooms.pipe(map(rooms => rooms.filter(r => !r.room_data.deleted && !r.room_data.personal_room && !r.room_data.meeting_room)));
    this.allOwnRooms = this.allOwnRoomsSource.asObservable();
    this.myRooms = this.allOwnRooms.pipe(map(rooms => rooms.filter(r => !r.room_data.deleted && !r.room_data.personal_room && !r.room_data.meeting_room)));
    // this.onLogin('MxcH7kykXMXqge2gknE')
    setTimeout(() => {
      this.startListennigAllRooms(this.authService.currentUser.account_id);
      this.startListeningOwnRooms()
    }, 1000);
    
    // this.authService.on("login", this.onLogin);
    // this.authService.on("logout", this.onLogout);

    // Load and minimize default room icon before needed
    this.loadDefaultRoomIcon();
  }

  private onLogin = (account_id: string) => {
    this.startListennigAllRooms(account_id);
  }

  private onLogout = () => {
    this.stopListenningAllRooms();
  }

  startListennigAllRooms(account_id: string) {
    if (this.allRoomsSub) { this.allRoomsSub.unsubscribe() }

    this.allRoomsSub = this.dbService.listenSnap<any>(`accounts/${account_id}/rooms`).pipe(
      map(roomSnaps => {
        const roomIds = roomSnaps.data ? Object.keys(roomSnaps.data) : [];
        return roomIds.map(rid => this.getRoomFromRoomData(roomSnaps.data[rid].room_data, rid));
      })
    ).subscribe(rooms => {
      this.allRoomsSource.next(rooms);
    });
  }

  stopListenningAllRooms() {
    if (this.allRoomsSub) { this.allRoomsSub.unsubscribe() }
    this.allRoomsSource.next(null);
  }

  getRoomFromRoomData(roomData: RoomData, roomId: string): Room {
    if (!roomData.users) {
      roomData.users = {}
    }
    return {
      id: roomId,
      room_data: roomData
    }
  }

  startListeningOwnRooms() {
    if (this.allOwnRoomsSub) { this.allOwnRoomsSub.unsubscribe() }

    this.allOwnRoomsSub = this.authService.user.pipe(
      map(user => user && user.rooms ? user.rooms : null),
      switchMap(roomsObj => {
        if (roomsObj) {
          let roomObsList:Observable<OwnRoom>[] = [];
          Object.keys(roomsObj)
          .forEach(roomId => {
            const roomObs = this.getOwnRoom(roomId, true);
            // roomObsList.push(roomObs);
          });
          return combineLatest(roomObsList);
        } else {
          return of([]);
        }
      })
    )
    .subscribe(rooms => this.allOwnRoomsSource.next(rooms));
  }

  getInRoomNotification() {
    return new Observable<string>(observer => {
      let isFirstTime = true;
      let inRoomUsers: any = {};
      const sub = this.allOwnRooms.subscribe(rooms => {
        if (!rooms) {
          return;
        }

        // get all users of all rooms
        const allUsers = rooms.reduce((users, room) => {
          room.room_data.users.forEach(u => users.push(u));
          return users;
        }, []);

        // find users in room
        const usersInRoom = allUsers.filter(u => u.in_room);

        const inRoomUsersTemp: any = {};
        for (const u of usersInRoom) {
          inRoomUsersTemp[u.user_id] = true;
        }

        if (!isFirstTime) {
          for (const u of usersInRoom) {
            if (!inRoomUsers[u.user_id]){
              observer.next(u.user_id);
            }
          }
        }
        isFirstTime = false;
        inRoomUsers = inRoomUsersTemp;
      })
      return () => sub.unsubscribe();
    });
  }

  stopListeningOwnRooms() {
    if (this.allOwnRoomsSub) { this.allOwnRoomsSub.unsubscribe() }
    this.allOwnRoomsSource.next(null);
  }

  getRoom(roomId: string): Promise<Room> {
    return this.allRooms.pipe(first(), map(rooms => rooms ? rooms.find(room => room.id === roomId) : null)).toPromise()
  }

  getOwnRoom(roomId: string, excludeItself: boolean = false) {
    // return this.dbService.listen<OwnRoomData>(`accounts/${this.authService.currentUser.account_id}/rooms/${roomId}/room_data`, 'value')
    // .pipe(
    //   map(roomData => this.getOwnRoomFromRoomData(roomData, roomId, excludeItself)),
    //   catchError(error => of({
    //     id: "NaN",
    //     room_data: {
    //       name: "Deleted Room",
    //       description: "Deleted Room",
    //       icon: "",
    //       in_room: { active: false, count: 0, training_room: false },
    //       session: { active: false },
    //       users: []
    //     }
    //   }))
    // );
  }

  getOwnRoomFromRoomData(roomData: OwnRoomData, roomId: string, excludeItself: boolean): OwnRoom {
    const currentUser = this.authService.currentUser;

    if (roomData.users) {
      let users = [];
      Object.keys(roomData.users).forEach(user => {
        const ru: any = {
          user_id: user,
          in_room: roomData.users[user].in_room,
          name: roomData.users[user].name,
          ar_color: roomData.users[user].ar_color,
          role: roomData.users[user].role
        };
        if (roomData.users[user].status) {
          ru.status = roomData.users[user].status;
        }
        if (roomData.users[user].in_room_status) {
          ru.in_room_status = roomData.users[user].in_room_status;
        }
        if (roomData.users[user].platform) {
          ru.platform = roomData.users[user].platform;
        }
        if (roomData.users[user].app_version) {
          ru.app_version = roomData.users[user].app_version;
        }
        users.push(ru);
      });
      if (excludeItself) {
        users = users.filter(user => user.user_id !== currentUser.id);
      }
      roomData.users = users.sort((a, b) => {
        if (a.in_room && !b.in_room) {
          return -1;
        } else if (!a.in_room && b.in_room) {
          return 1;
        } else {
          return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        }
      });
    } else {
      roomData.users = [];
    }
    return {
      id: roomId,
      room_data: roomData
    }
  }

  updateRoom(changes) {
    return this.http.post(environment.endPoints.updateroom,
      { token: this.authService.currentUser.token, changes: changes }).pipe(first()).toPromise()
    .then(async(result) => {
      return (await new Promise<void>(resolve => {
        const sub = this.allRooms.pipe(
          timeout(5000),
          map(rooms => rooms ? rooms.find(room => room.id === changes.room_id) : null)
        ).subscribe(
          room => {
            if (room) {
              const roomUsers = room.room_data.users ? Object.keys(room.room_data.users) : [];
              if (Object.keys(changes.room_data).every(p => changes.room_data[p] === room.room_data[p]) &&
                      changes.deleted_users.every(d => roomUsers.indexOf(d) < 0)  && changes.inserted_users.every(i => roomUsers.indexOf(i) > -1)) {
                if (sub) { sub.unsubscribe() }
                resolve();
              }
            }
          },
          error => { resolve() });
      }));
    });
  }

  createRoom(newRoom) {
    console.log(newRoom, 'room')
    return this.http.post(environment.endPoints.createroom,
      { token: this.authService.currentUser.token, room: newRoom }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.room;
    });
  }

  deleteRoom(roomId: string) {
    return this.http.post(environment.endPoints.deleteroom,
      { token: this.authService.currentUser.token, room: roomId }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.room;
    });
  }

  toggleRoomStatus(roomId: string): Promise<boolean> {
    return this.http.post(environment.endPoints.toggleroom, { token: this.authService.currentUser.token, room: roomId }).pipe(first()).toPromise()
    .then(async(result: any) => {
      return (await new Promise<boolean>(resolve => {
        const sub = this.allRooms.pipe(
          timeout(5000),
          map(rooms => rooms ? rooms.find(room => room.id === roomId) : null)
        ).subscribe(
          room => {
            if (room) {
              if (!room.room_data.disabled == !result.disabled) {
                if (sub) { sub.unsubscribe() }
                resolve(result.disabled);
              }
            }
          }, error => {
            resolve(result.disabled);
          }
        );
      }));
    });
  }

  getArchivedMeetings(): Observable<Room[]> {
    return new Observable<Room[]>(subscriber => {
      const meetings: Room[] = [];
      // when this method used with combineLatest,
      // and if there is no archived meeting,
      // blocks other observable(s) without following:
      subscriber.next(meetings);

      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/users_meetings/${this.authService.currentUser.id}`, "child_added")
        .subscribe(snp => {
          meetings.push(this.getRoomFromMeetingData(snp.key, snp.data));
          subscriber.next(meetings);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/users_meetings/${this.authService.currentUser.id}`, "child_changed")
        .subscribe(snp => {
          const i = meetings.findIndex(m => m.room_data.link_id === snp.key);
          if (i>-1) {
            meetings[i] = this.getRoomFromMeetingData(snp.key, snp.data);
          } else {
            meetings.push(this.getRoomFromMeetingData(snp.key, snp.data));
          }
          subscriber.next(meetings);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/users_meetings/${this.authService.currentUser.id}`, "child_removed")
        .subscribe(snp => {
          const i = meetings.findIndex(m => m.room_data.link_id === snp.key);
          if (i>-1) {
            meetings.splice(i, 1);
            subscriber.next(meetings);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getRoomFromMeetingData(linkId: string, meetingData: any): Room {
    const room: any = {
      id: meetingData.room_id,
      room_data: {
        allow_archiving: true,
        archive_perm_needed: false,
        auto_archive: false,
        create_time: meetingData.create_time,
        description: meetingData.room_description,
        end: meetingData.end,
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAE8klEQVR4Xu2ab2hbVRjGn/feNI1WbOumUxhYWWeSuumHMXQftIXa2xYRh65tEnV/FFSYf/ZNZOoEJ4iIytQPBUG2uSamjk1BttyuOFEsdExw0vbGdXNqQdd2Yiu6dknuK7drQJq0uSdJk9v0ni/5kOfkPM/vvufk3HMvYZk3Wub5YQOwK2CZE7CnwDIvAHsRtKeAPQWWOYGspkDDVw2OP39zlsddV+bt75hy8hlX5RTauxNWZiwMoC7cdp2emBgG4wbQgosoEzB87eSlu04/fTpmVQjCANzBpn4CbTQbiIFXon51r1l9oXXCADxBZRTAjaaNEjo1n/qMaX2BhdkA+BlAjYDP/Zpf3S6gL6g0GwDfAdhk1iURPh/yqZvN6gutEwbgDjYdINDjZo0y0B/1q3eb1RdaJwzAG2rexczvmjdKY5o/sgoAm+9TOKUwgNtDjRsllvtFLMZJrh32HTsn0qdQWmEAYJAnqEyDUGbWJDG/OBToecusvpA6cQAAPF3KRRBuEjD6teZXGwT0BZNmByCknAFjvYDLAc2vrhPQF0yaFQBvl7KXCbvNu+QPNH/Pc+b1hVNmBaD2k9bVDjlxAYCc0SqjU3NU7rTqTVFWAIzQnqAyZHwsACDOzLujFl38kr6zBxBS9oExX1n/w0xbooHI8YwVUmRB1gC8h5tv4WmMgFiam0GWae1Ae2S4yNlMDZ81gNlpcAxAS8pIjJe0gPqmKQdFFuUEwN3V3ELEBoS5LS7J+prB9hO/FjlfxuFzAoA9eyS3p+8kAfemjEQ4rPnULRkdFFmQGwAAdcGWRh36iXQ5WKKOaEckXOSMCw6fM4CZtSCkfAbGI2lGGk3oiU1nH+09b1UIeQFQG2pd4+DEAIDy1KB8qlququ9r775sRQh5AWAE8waVJxn4KF1IAh8ZkqvarLgbzBsAY0H0ePp+AJD2pocZ3dGA2m61KsgfAAB3HlAqrjgwDoIrTVDjRCio+dXHrHQ6lFcARug7ws21iQT/CKSBQMaxGKkVE+MPmnlYsqFzQ9m/lSu3QQf+dlx/aGQR1pG8A7j6r9DyMLMepvnuFhl9sTg2n9uqGs8Y0rdwm+xJTHwL4J5ZQQzMZ4noF2Y+pRMNgqTzFMMk6bFxbVvvpWym16IAuLooNu1kSPuA1HsF43tmjMjE2wf9Pb3pjLuDykECjOlipjGIVM0XSd2WZ+i9aABmIHQ172Lit+c9N2DoBH7/8nTstQs7Tv6V9OoOKs8T8B4g+AKHJAW0juNBM8SSmkUFYAziDipPEPBh2jUh6YLxOyTp2ZtXTX0xOla2Tk9Q34L6eRIS0xtDgcjLlgIwUwmfKvexjqMAqjOYGwNQBZg/cf7/71kWgGFy/aEHqmNS/CeAV4pcIRGtpQEYQYwXK/646HxngZMkkbwpWssDSDpeG7rfK0P6Eozbcko8p/OSAWD4rvm4wVXucgYIMJ4YrcgHiCUFIBm45khD1TVTzh3M/AKIbs0FxJIEkAxcF25zcmzSBwlbGVwPwCEKg0GvRv2R10X6Lfo+QMRMUuvZ37gCZfJTRGhlkNfkPwdLoPpBf+QbkTEtCWBuAPfBFjec/BAYCum8GoQKgFwAO2d3mQzG91pANSpHqC0JAEKJBMU2AEFgJSe3K6DkLqlgILsCBIGVnNyugJK7pIKB7AoQBFZycrsCSu6SCgb6D6pOgVCPV/yLAAAAAElFTkSuQmCC",
        in_room: {},
        license: {},
        link_id: linkId,
        meeting_room: meetingData.creator,
        meeting_type: "scheduled",
        name: meetingData.room_name,
        peer_to_peer: false,
        session: {},
        start: meetingData.start,
        training_room: false,
        users: [],
        archive: true
      }
    }

    return room;
  }

  loadDefaultRoomIcon() {
    // Load rounded white background
    const loadBg = new Promise<HTMLImageElement>((resolve, reject) => {
      const bgImage = new Image();
      bgImage.src = "assets/img/rounded_bg.png";
      bgImage.onload = () => { resolve(bgImage) }
      bgImage.onerror = () => { reject() }
    });

    // Load transparent square icon
    const loadIcon = new Promise<HTMLImageElement>((resolve, reject) => {
      const iconImage = new Image();
      iconImage.src = "assets/img/square_logo.png";
      iconImage.onload = () => { resolve(iconImage) }
      iconImage.onerror = () => { reject() }
    });

    // Create default room icon with rounded white background
    loadBg.then(bgImage => loadIcon.then(iconImage => [bgImage, iconImage]))
    .then(([bgImage, iconImage]) => {
      const cnv: HTMLCanvasElement = document.createElement("canvas");
      cnv.width = 64;
      cnv.height = 64;
      const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
      ctx.drawImage(bgImage, 0, 0, 64, 64);
      ctx.drawImage(iconImage, 0, 0, 64, 64);
      this.defaultRoomIcon = cnv.toDataURL("image/png", 1);
    });
  }
}