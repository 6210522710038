import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-radio-widget',
  templateUrl: './radio-widget.component.html',
  styleUrls: ['./radio-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioWidgetComponent),
      multi: true,
    },
  ],
})
export class RadioWidgetComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {

  @Input() item //type tanımla

  subscriptions : Subscription[] = []

  radioGroup = new UntypedFormGroup({
    selection: new UntypedFormControl('')
  })

  constructor() { }

  get radioControl(): AbstractControl {
    return this.radioGroup.get('selection');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.item.templateOptions.required) {
      this.radioControl.setValidators(this.radioValidator())
      this.radioControl.updateValueAndValidity()
    }
  }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.radioControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.radioControl.valid ? null : this.radioControl.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.radioControl.valueChanges.subscribe(res => {
      this.onChange(res)}))
  }

  private radioValidator(): ValidatorFn {
    return (FormControl:UntypedFormControl): ValidationErrors |  null => {
      if (FormControl.value) {
        return null
      } else {
        return { required: true }
      }
    }
  }

  changeRadioCheck(option) {
    if(this.radioControl.value == option.value){
      this.radioControl.reset()
    }
  }

}
