<div class="dashboard-wrapper">
    <div class="workflow-background" *ngIf="selectedWorkOrder || mode === 'create'"></div>
    <div class="v-card">
        
        <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="d-flex">
                <div class="header fs-20 font-weight-700  pt-1 me-2">{{ 'MAIN.CONTENT.WORK-ORDERS.TITLE'| translate }}</div>
            </div>
            
            <div>
                <div class="d-flex flex-column">
                    <button *ngIf="isAdmin" class="v-btn btn btn-primary vsight-button create-button" (click)="createWorkOrder()">{{ 'MAIN.CONTENT.WORK-ORDERS.CREATE-WORK-ORDER'| translate }}</button>
                    <!-- <a class="" (click)="goDetail()">Detail</a> -->
                </div>
            </div>
        </div>

        <div class="work-orders" *ngIf="!isLoading">
            <div class="card tab-area d-flex flex-row">
                <div class="tab-item left fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 0 }" (click)="onTabClicked(0)">
                    <span class="tab-title">
                        {{ 'MAIN.CONTENT.WORK-ORDERS.TO-DO'| translate }}
                    </span>
                </div>
                <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 1 }" (click)="onTabClicked(1)">
                    <span class="tab-title">
                        {{ 'MAIN.CONTENT.WORK-ORDERS.DONE'| translate }}
                    </span>
                </div>
            </div>

            <div class="card list-area">
                <app-work-order-filter
                    [allLabels]="allLabels"
                    [filterReset]="filterReset"
                    [selectedTabIndex]="tabIndex"
                    (filterChanged)="filterChanged($event)">
                </app-work-order-filter>

                <div class="user-table-header d-flex align-items-center">
                    <div class="col-5 fs-14 font-weight-400 text-dark-grey d-flex align-items-center cursor-pointer" style="padding-left: 20px;" (click)="sortByName()">
                        {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.NAME' | translate }}
    
                        <img src="assets/img/down-icon-grey.svg" *ngIf="order == 'asc'" alt="" class="folder-icon">
                        <img src="assets/img/down-icon-grey.svg" *ngIf="order == 'desc'" style="transform:rotate(180deg)" alt="" class="folder-icon">
                        <div class="p-relative ms-1 cursor-pointer" style="height:24px;" *ngIf="!order">
                            <img src="assets/img/down-icon-grey.svg" style="transform:rotate(180deg)" alt="" class="sort-icon up">
                            <img src="assets/img/down-icon-grey.svg" alt="" class="sort-icon down">
                        </div>
                    </div>
                    <div class="col-2 fs-14 font-weight-400 text-dark-grey d-flex align-items-center cursor-pointer" (click)="sortByPriority()">
                        {{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.PRIORITY'| translate }}

                        <img src="assets/img/down-icon-grey.svg" *ngIf="priorityOrder == 'asc'" alt="" class="folder-icon">
                        <img src="assets/img/down-icon-grey.svg" *ngIf="priorityOrder == 'desc'" style="transform:rotate(180deg)" alt="" class="folder-icon">
                        <div class="p-relative ms-1 cursor-pointer" style="height:24px;" *ngIf="!priorityOrder">
                            <img src="assets/img/down-icon-grey.svg" style="transform:rotate(180deg)" alt="" class="sort-icon up">
                            <img src="assets/img/down-icon-grey.svg" alt="" class="sort-icon down">
                        </div>
                    </div>
                    <div class="col-2 fs-14 font-weight-400 text-dark-grey d-flex align-items-center cursor-pointer" (click)="sortByDueDate()">
                        {{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.DUE-DATE'| translate }}

                        <img src="assets/img/down-icon-grey.svg" *ngIf="dueDateOrder == 'asc'" alt="" class="folder-icon">
                        <img src="assets/img/down-icon-grey.svg" *ngIf="dueDateOrder == 'desc'" style="transform:rotate(180deg)" alt="" class="folder-icon">
                        <div class="p-relative ms-1 cursor-pointer" style="height:24px;" *ngIf="!dueDateOrder">
                            <img src="assets/img/down-icon-grey.svg" style="transform:rotate(180deg)" alt="" class="sort-icon up">
                            <img src="assets/img/down-icon-grey.svg" alt="" class="sort-icon down">
                        </div>
                    </div>

                    <div class="col-2 fs-14 font-weight-600">
                        
                    </div>

                </div>
                <div class="user-table-body">
                    <div *ngIf="!workOrders?.length" class="empty-list fs-14 text-grey">{{ 'MAIN.CONTENT.WORK-ORDERS.EMPTY-LIST'| translate }}</div>
    
                    <div class="workflow-table-body">
                        <div *ngFor="let workOrder of filteredWorkOrderList;let i = index" class="workflow-card d-flex align-items-center">
                            <div class="col-5 fs-15 font-weight-700 cursor-pointer" style="padding-left: 20px;" (click)="selectWorkOrder(i)">{{workOrder.name}}</div>
                            <div class="col-2 fs-14 font-weight-400 text-light-black p-relative">
                                <span class="fs-20 priority-status {{workOrder.priority}}">.</span>
                                <span class="ps-3">{{workOrder.priority | titlecase}}</span>
                            </div>
                            <div class="col-2 fs-14 font-weight-400 text-light-black">
                                {{workOrder.dueDate | localizedDateShort | async}}
                            </div>
                            <div class="col-2 d-flex justify-content-start ps-4 align-items-center">
                                <div class="btn-group dropdown-wrapper-list status-wrapper-list ps-0 pe-0" dropdown style="width: 180px;border: none;">
                                    <button type="button" class="btn btn-primary action-dropdown status-dropdown {{workOrder.status}} p-0 text-start ps-2 hover-style fs-15" (click)="toggle()"  #toggleButton dropdownToggle data-bs-auto-close="inside">
                                        <img src="assets/img/{{workOrder.status}}.svg" alt="" class="status-dropdown-icon">
                                        {{ 'MAIN.CONTENT.WORK-ORDERS.STATUS.' + workOrder.status | translate | titlecase }}
                                    </button>
                                    <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split status-toggle {{workOrder.status}}"  (click)="$event.stopPropagation(); toggleButton.click()"
                                            aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                                    overflow-y: scroll; min-width: 130px;" aria-labelledby="dropdownMenuClickableInside">
                                        <li role="menuitem" *ngFor="let status of statusOptions">
                                            <a class="dropdown-item fs-14 lato-regular" (click)="changeStatus(status, i)">
                                                {{ 'MAIN.CONTENT.WORK-ORDERS.STATUS.' + status | translate | titlecase }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-2 d-flex justify-content-start ps-4 align-items-center">
                                <div class="dropdown-wrapper" dropdown>
                                    <a class="btn btn-default name-wrapper" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                                        <img src="assets/img/more.svg" alt="" height="20px">
                                    </a>
                                    <div #stepListStepOptions class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink">
                                        <div (click)="deleteWorkOrder(workOrder.id)">
                                            <!-- <a class="dropdown-item folder-item" (click)="edit(workOrder)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.RENAME' | translate }}</a> -->
                                            <a class="dropdown-item folder-item">{{ 'MAIN.CONTENT.WORKFLOW.DELETE' | translate }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
    <div class="col-6 work-order-detail" *ngIf="selectedWorkOrder || mode === 'create'">
        <app-work-order-detail
            [(selectedWorkOrder)] = "selectedWorkOrder"
            [mode] = "mode"
            [isWebClient]="isWebClient"
            (selectedWorkOrderChange) = "selectedWorkOrderChange($event)"
            (deletedWorkOrder) = "deleteWorkOrder($event)"
            (workOrderUpdated) = "updateWorkOrder($event)"
            (workOrderCreated) = "workOrderCreated($event)">
        </app-work-order-detail>
    </div>
</div>

