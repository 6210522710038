<form [formGroup]="form">
    <div *ngIf="properties.label" class="form-group mt-2">
        <label class="fs-16 font-weight-600">
            {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.TITLE' | translate }}
            <span>*</span>
        </label>
        <input type="text" class="form-control fs-14" formControlName="label" inputSelectOnFocus>
        <p *ngIf="label.errors?.['required']" class="validation-text mb-0">
            {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.NO-LABEL' | translate }}
        </p>
    </div>

    <div *ngIf="properties.placeholder" class="form-group mt-2">
        <label class="fs-16 font-weight-600">
            {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PLACEHOLDER' | translate }}
        </label>
        <input type="text" class="form-control fs-14" formControlName="placeholder" inputSelectOnFocus>
    </div>

    <div *ngIf="properties.empty" class="form-group mt-2">
        <label class="fs-14 font-weight-600">
            
        </label>
    </div>

    <div *ngIf="properties.timepicker" class="form-group mt-3">
        <div class="form-check mb-2">
            <label for="timepicker" class="form-check-label fs-14 me-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TIME-PICKER' | translate }}</label>
            <input id="timepicker" type="checkbox" class="form-check-input" formControlName="timepicker">
            <img src="assets/img/schedule.svg" alt="">
        </div>
    </div>

    <div *ngIf="properties.numberFields" formGroupName="numberFields" class="form-group mt-2">
        <div class="d-flex">
            <div class="col-6 me-2">
                <label class="fs-16 font-weight-600 mt-2">
                    {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.MIN' | translate }}
                </label>
                <div class="d-flex me-2">
                    <input type="number" class="form-control fs-14" formControlName="min" inputSelectOnFocus>
                </div>
            </div>
            <div class="col-6 me-2">
                <label class="fs-16 font-weight-600 mt-2">
                    {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.MAX' | translate }}
                </label>
                <div class="d-flex me-2">
                    <input type="number" class="form-control fs-14" formControlName="max" inputSelectOnFocus>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="col-6 me-2">
                <label class="fs-16 font-weight-600 mt-2">{{
                    'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.UNIT' | translate }}
                </label>
                <div class="d-flex">
                    <input type="text" class="form-control fs-14" formControlName="unit" inputSelectOnFocus>
                </div>
            </div>
            <div class="col-6 me-2">
                <label class="fs-16 font-weight-600 mt-2">
                    {{'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.PRECISION' | translate}}
                </label>
                <div class="d-flex me-2">
                    <input type="number" min="0" class="form-control fs-14" formControlName="precision" inputSelectOnFocus
                        (wheel)="preventDefaults($event)">
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="properties.options" formGroupName="options" class="form-group mt-2">
        <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.TITLE' | translate }}</label>
        
        <div *ngFor="let optionFormGroup of optionsFormGroups; let i = index;">
            <div class="d-flex mt-1" [formGroupName]="i">
                <input type="text" class="init form-control mt-0 fs-14" formControlName="option"  inputSelectOnFocus [inputSelectOnCreate]="!templateOptions" >
                <a class="d-flex align-items-center ms-2 delete" style="cursor: pointer" (click)="deleteOption(i)">
                    <img src="assets/img/delete-widget.svg" alt="" class="normal-icon">
                    <img src="assets/img/delete-widget-hover.svg" alt="" class="hover-icon">
                </a>
            </div>
            <p *ngIf="optionFormGroup.controls['option']?.errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.NO-LABEL' | translate }}</p>
        </div>
        <p *ngIf="options.errors?.['uniqueError']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.MUST-UNIQUE' | translate }}</p>

        <a (click)="addOption()" class="d-flex align-items-center add-text cursor-pointer fs-14 font-weight-600 top-gap-16">
            <img src="assets/img/add_icon.svg" alt="" class="normal-icon">
            <img src="assets/img/add_icon_hover.svg" alt="" class="hover-icon">
            <span class="ms-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.ADD' | translate }}</span>
        </a>
    </div>

    <div *ngIf="properties.required" class="form-group mt-2">
        <div class="form-check form-switch d-flex align-items-start">
            <div>
                <input class="form-check-input" type="checkbox" formControlName="required">
            </div>
            <div class="fs-15 font-weight-600">
                {{'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.REQUIRED.TEXT'| translate }}
            </div>
            <div class="fs-12">
                {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.REQUIRED.INFORM' | translate}}
            </div>
        </div>
    </div>

</form>