import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@services/support/loader.service';
import * as bowser from 'bowser';
import languagesJson from '../../../../assets/languages.json';

@Component({
  selector: 'app-guest-workflow',
  templateUrl: './guest-workflow.component.html',
  styleUrls: ['./guest-workflow.component.scss']
})
export class GuestWorkflowComponent implements OnInit {

  @Input() publicWorkflow

  showWorkflow = false
  isSubmitted
  workflow
  boxHeight = 400;
  boxWidth = 605;

  data
  browser
  isMobile: boolean = false

  report
  reportId
  results
  wfName : string

  workflowMenuClicked
  isStart
  contentListClicked

  stepFilesClicked = false
  isStepFileAvailable = false

  constructor(private loaderService:LoaderService,
    public activatedRoute: ActivatedRoute,
    public router: Router) {
        if(this.router.getCurrentNavigation().extras.state['publicWorkflow']) {
          this.publicWorkflow = this.router.getCurrentNavigation().extras.state['publicWorkflow']
          this.data = this.router.getCurrentNavigation().extras.state['data']
          this.report = this.data.report
          this.results = this.data.report?.results
          this.showWorkflow = true
        }
        this.browser = bowser.getParser(window.navigator.userAgent);
        if(this.browser.is('mobile')) {
          this.isMobile = true
          this.boxHeight = null;
          this.boxWidth = null;
        }
     }

  ngOnInit(): void {

    let workflowLangs = []
    let userDefaultLang = localStorage.getItem('userDefaultLanguage')
    if(this.publicWorkflow.translations) {
      Object.keys(this.publicWorkflow.translations).forEach(res => {
        let workflowLang = languagesJson.languages.find(lang => lang.code == res)
        if(workflowLang) {
          workflowLangs.push(workflowLang)
        }
      })
      let isLang = workflowLangs.find(lang => lang.code == userDefaultLang)
      if(isLang) {
        this.wfName = this.publicWorkflow.translations[isLang.code][this.publicWorkflow.name]
      }else{
        this.wfName = this.publicWorkflow.translations[this.publicWorkflow.defaultLanguage][this.publicWorkflow.name]
      }
    }else{
      this.wfName = this.publicWorkflow.name
    }

  }

  contentListClickedChange(event) {
    this.contentListClicked = event
  }

  workflowSubmitted(event) {
    this.isSubmitted = event
  }

  switchWorkflowMenu() {
    this.workflowMenuClicked = !this.workflowMenuClicked
  }

  workflowMenuClickedChange(event) {
    this.workflowMenuClicked = event
  }

  isWorkflowStart(event) {
    this.isStart = event
  }
  langChanged(lang) {
    this.wfName = lang
  }

  contentListStatus() {
    this.contentListClicked = !this.contentListClicked
  }

  stepFileAvailability(event){
    this.isStepFileAvailable = event
  }

  showStepFiles() {
    this.stepFilesClicked = true
  }

}
