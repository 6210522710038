import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Step } from '@models/Step';
import { DbService } from '@services/db.service';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { WorkflowService } from '@services/workflow.service';
import { interval, Subscription, switchMap, takeWhile, tap } from 'rxjs';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpResponse } from '@angular/common/http';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

interface StepTranscript {
  start: string;
  end: string;
  text: string;
}

type AIStepTranscription = StepTranscript & { title?: string };

interface WorkflowVideoStep {
  title?: string;
  steps: StepTranscript[];
}

@Component({
  selector: 'app-workflow-from-video',
  templateUrl: './workflow-from-video-2.component.html',
  styleUrls: ['./workflow-from-video-2.component.scss']
})
export class WorkflowFromVideoComponent implements OnInit {

  @ViewChild('myVideoElement') myVideoElement: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  @Input() workflow?
  @Input() workflowId?
  @Input() workflowTranslations?
  @Input() selectedLang?
  @Input() type!: string;
  @Input() status: string
  @Input() initialStep
  @Input() workflowSteps?: Step[]
  @Output() workflowTranslationsChange: EventEmitter<string> = new EventEmitter<any>();
  @Output() workflowStepsChange: EventEmitter<Step[]> = new EventEmitter<Step[]>();
  @Output() initialStepChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() workflowChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() workflowTranscriptionIdChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() workflowAIResultChanged: EventEmitter<boolean> = new EventEmitter<boolean>();


  systemPrompt = `Generate Step-by-Step Instructions from Video Transcript

        Objective: Convert a provided video transcript into structured, detailed step-by-step instructions.
        
        Instructions to the Model:
        1. Divide the Transcript: Break down the video transcript into distinct sections where each section represents a clear step or part of the instructional process.
        2. Extract Information: For each step, identify and extract:
           - The title, which briefly summarizes the step.
           - A concise summary, using 3-4 words to describe the main action or instruction.
           - The first sentence from the step in the transcript to set the context.
           - The timestamp marking the beginning and ending of this step in the video.
        3. Output Format: Structure the response in JSON format with an array of steps. Each step should include:
           - title: A brief, descriptive title for the step.
           - summary: A short summary of the action or instruction.
           - first_sentence: The first sentence of this section of the transcript.
           - timestampStart: The timestamp from the video where this step starts.
           - timestampEnd: The timestamp from the video where this step ends.

        
        JSON Structure Example:
        {
          "steps": [
            {
              "title": "Opening the Program",
              "summary": "Initiate software",
              "first_sentence": "To start, open the application by double-clicking the icon.",
              "timestampStart": "00:01:15"
              "timestampEnd": "00:01:33"
            },
            
            ...
          ]
        }
        
        Expected Use Case:
        - Provide the model with a transcript of an instructional video on a technical process, such as software installation. The model should return a JSON formatted list of instructional steps, each clearly marked with a timestamp, making it easy for users to follow along with the video.`;

  mockSteps = [
    {
        "start": "00:00:00",
        "end": "00:00:33",
        "text": "Whether you're new to cycling and about to purchase your first bike, or an experienced rider treating yourself to an upgrade, it's not unlikely that your next bike purchase could well be from an online retailer. Online retailers selling direct to consumer are growing and sell thousands of bikes globally. When your bike arrives, it'll often be in a box like this in a partially assembled state, which often means that it's been built and serviced by a professional mechanic and then partially disassembled so that it will fit inside the box for shipping."
    },
    {
        "start": "00:00:34",
        "end": "00:00:40",
        "text": "Now, in this video we're going to show you the last few steps of the process so that you can go from this to this."
    },
    {
        "start": "00:00:42",
        "end": "00:00:58",
        "text": "Now we know that not everyone owns a work stand, and while using a work stand will make building your new bike a little bit easier, you can absolutely do it without one. So that's what I'm going to show you how to do. It's really straightforward, should only take about 20 minutes. So let's get going."
    },
    {
        "start": "00:01:11",
        "end": "00:01:33",
        "text": "You only need a few basic tools to do this job. A set of Allen keys so that you can adjust the various bolts on the bike. A torque wrench so that you can get things to the correct torque. Particularly important on carbon bikes. Some cutters or scissors so that you can remove packaging easily. A tape measure is useful for setting up your bike measurements, especially if you know them already."
    },
    {
        "start": "00:01:33",
        "end": "00:01:48",
        "text": "And also some grease or carbon assembly paste can also come in handy too. If you're new to cycling and don't currently own any of these tools then I would strongly recommend that you invest in them as their basic tools that are incredibly useful and will save you money in the long run."
    },
    {
        "start": "00:01:50",
        "end": "00:02:11",
        "text": "When you first open your box top tip, look out for industrial staples that are used to clamp it shut. These can be very sharp and you don't want to catch your thumb on them. Now inside you'll typically find one of the wheels removed and strapped to the side of the bike. You'll also probably have a box with important bits in, so I'm just going to take that out."
    },
    {
        "start": "00:02:14",
        "end": "00:02:16",
        "text": "Put that to one side."
    },
    {
        "start": "00:02:17",
        "end": "00:02:23",
        "text": "We'll need that later and then lift the entire bike out-of-the-box."
    },
    {
        "start": "00:02:28",
        "end": "00:02:46",
        "text": "Nice colour, isn't it? Now going to set about removing the packaging from the bike, and you might need your scissors or cutters for this. Fortunately on this bike a lot of the bits are connected by these Velcro pads and straps, which is great because they're reusable. I'd also recommend that you carefully remove the packaging."
    },
    {
        "start": "00:02:47",
        "end": "00:02:47",
        "text": "Keep it to what?"
    }
]

  isUpload
  isLoading

  videoUrl

  subscriptions: Subscription[] = []
  videoSteps = []

  mockStepsCopy = []
  finalSteps = []
  videoChapters = []
  videoChapterSteps = [];
  dropListConnections: string[] = [];

  stepSelectedByVideoTimeline = null

  mainVideoUrl
  intervalId
  transcriptionId
  transcription
  captionUrl: string = 'assets/subtitles/subtitle.vtt';

  splitVideoLoading = false
  transcriptionCheck = false
  aiLoading = false

  optionsi = {
    path: '/assets/img/video-2.json',
    
    style: {
      width: '100%',
      height: '300px'  // İstediğiniz yükseklik
    }
  };

  constructor(
    private workflowService: WorkflowService,
    private dbService: DbService,
    public loaderService: LoaderService,
    private workflowLanguageService: WorkflowLanguageService,
    private cdr: ChangeDetectorRef
    ) {
        this.selectedLang = this.workflowLanguageService.currentLang.code
        this.workflowLanguageService.onLangChange.subscribe(res => {
          this.selectedLang = res.lang.code
        })
     }

  ngOnInit(): void {
    this.videoUrl = JSON.parse(JSON.stringify(this.workflow[this.type]?.videoFlowData?.url))
    this.mainVideoUrl = this.workflow[this.type]?.videoFlowData?.url

    const transcriptionData = this.workflow[this.type]?.videoFlowData?.transcript
    this.transcriptionId = this.workflow[this.type]?.videoFlowData?.transcriptionId
    
    if(this.videoUrl && !this.workflowSteps.length && !transcriptionData) {
      if(!this.transcriptionId) {
        this.splitVideo(this.videoUrl)
      }else{
        this.subscriptions.push(this.startTranscriptionChecking().subscribe(response => {
          // console.log(response, 'response')
        }))
      }
    }else if(!this.workflowSteps.length && transcriptionData){
      // this.transcription = transcriptionData.transcriptions
      this.transcription = transcriptionData.transcriptions.filter(res => res.speaker==0)

      this.getResultFromAI()
    }else{
      this.mapWorkflowStepsToVideoChapters()
    }

    if(this.workflowSteps?.length) {
      this.workflowSteps.forEach(res => {
        res['valid'] = true
      })
      this.videoSteps = this.workflowSteps
    }

  }

  onSelectStep(step) {
    this.mockSteps.forEach(res => {
      res['selected'] = false
    })
    step['selected'] = true
  }

  onStepRename(event) {
    if(event.order || event.order == 0) {
      this.videoChapters[event.order].title = this.workflowTranslations[this.selectedLang][event.name]
    }
  }

  mapWorkflowStepsToVideoChapters() {
    let videoChapters = []
    this.workflowSteps.forEach(step => {
      const templateOptions = step.data.layouts[0].items[0].templateOptions
      const steps =  templateOptions.steps?.map((item) => ({ ...item, uuid: Math.random().toString(16).slice(2) }))
      videoChapters.push({ uuid: Math.random().toString(16).slice(2), title: this.workflowTranslations[this.selectedLang][step.data.name], timestampStart: templateOptions.startTime, timestampEnd: templateOptions.endTime, steps });
    })
    this.videoChapters = videoChapters
    this.dropListConnections = this.videoChapters.map((item) => item.uuid.toString());
  }

  createWorkflowStep(stepValue, order?) {
    const layouts = []
    const template = {
      count: 1,
      name: "fullscreen",
      orientation: "row",
      weights: [1]
    }
    let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
    if(!this.workflowTranslations[this.selectedLang]) {
      this.workflowTranslations[this.selectedLang] = {}
    }
    this.workflowTranslations[this.selectedLang][labelTranslationId] = ''
    const templateOptions = {
      label: labelTranslationId, required: false, videoUrl: this.videoUrl, startTime: stepValue.timestampStart, endTime: stepValue.timestampEnd, steps: stepValue.steps
    }
    layouts.push({items: [{ key: this.dbService.createPushId(), type: 'video', templateOptions: templateOptions }]})
    let step = {
      data: {
        name: stepValue.title ? stepValue.title : "Step "+  (this.workflowSteps.length + 1),
        layouts: layouts,
        template: template,
        order: (order || order == 0) ? order : this.workflowSteps.length,
        connections: [],
        parentId: null
      },
      id: this.dbService.createPushId(),
    }
    step['valid'] = true
    let stepNameTranslationId = `$~#${this.dbService.createPushId()}$~#`
    this.workflowTranslations[this.selectedLang][stepNameTranslationId]= step.data.name
    step.data.name = stepNameTranslationId
    if(order == 0) {
      this.initialStep = step.id
      this.initialStepChange.emit(step.id)
    }
    

    this.videoSteps.push(step)
    if(this.videoSteps.length>1) {
      this.videoSteps[this.videoSteps.length-2].data['connections'] = [{next:step.id}]
    }

    this.finalSteps.push(step)
    this.workflowSteps = this.videoSteps
    this.workflowStepsChange.emit(this.videoSteps)
    this.mapWorkflowStepsToVideoChapters()
  }

  stepListChange(event) {

  }

  mergeData(originalSteps, enhancedSteps) {
    let aiStepIndex = -1;
    let videoChapterIndex = 0;
    const videoChapters = [{ uuid: Math.random().toString(16).slice(2), title: 'Default Start', timestampStart: '00:00', timestampEnd: '00:00', steps: [] }];

    originalSteps.forEach((transcript, index: number) => {
      aiStepIndex = enhancedSteps.steps.findIndex((ai) => {
        if (
          ai.timestampStart === transcript.startTime ||
          ai.timestampEnd === transcript.endTime ||
          (transcript.startTime > ai.timestampStart && transcript.endTime < ai.timestampEnd)
        ) {
          if (videoChapters[videoChapterIndex].title !== ai.title) {
            videoChapterIndex++;
            videoChapters[videoChapterIndex] = { uuid: Math.random().toString(16).slice(2), title: ai.title, timestampStart: ai.timestampStart, timestampEnd: ai.timestampEnd, steps: [] };
          }
          videoChapters[videoChapterIndex].steps.push({ ...transcript, uuid: Math.random().toString(16).slice(2) });
          return true;
        }
        return false;
      });

      const isStepIncluded = aiStepIndex > -1;
      if (!isStepIncluded) {
        videoChapterIndex++;
        videoChapters[videoChapterIndex] = { uuid: Math.random().toString(16).slice(2), title: 'Step' + videoChapterIndex, timestampStart: transcript.startTime, timestampEnd: transcript.endTime, steps: [{ ...transcript, uuid: Math.random().toString(16).slice(2) }] };
      }
    });

    videoChapters.splice(0, 1);
    return videoChapters;
  }

  splitVideo(videoUrl?) {
    // this.loaderService.show()
    this.splitVideoLoading = true
    this.subscriptions.push(this.workflowService.createWorkflowFromVideo(this.workflow.id,this.videoUrl).subscribe((res) => {
      if(res?.['data']) {
        this.transcriptionId = res['data'].transcriptionId
        this.workflow[this.type].videoFlowData.transcriptionId = this.transcriptionId
        this.workflowTranscriptionIdChanged.emit(true)
        this.subscriptions.push(this.startTranscriptionChecking().subscribe(response => {
          // console.log(response, 'response')
        }))
      }
    }))
    // })
  }

  mapAIResult(aiSteps) {
    let steps = aiSteps.steps
    steps.forEach((step,index) => {
      if((steps.length - 1) !== index) {
        step.timestampEnd = steps[index+1].timestampStart
      }else{
        step.timestampEnd = this.transcription[this.transcription.length-1].endTime ? this.transcription[this.transcription.length-1].endTime : step.timestampEnd
      }
    })
    aiSteps.steps = steps
    return aiSteps
  }

  startTranscriptionChecking() {
    // this.loaderService.show()
    this.splitVideoLoading = false
    this.transcriptionCheck = true
    return interval(30000).pipe(
      switchMap(() => this.workflowService.checkTranscriptionStatus(this.transcriptionId, this.workflow.id)),
      takeWhile((response: HttpResponse<any>) => response['status'] !== 200, true),
      tap((response: HttpResponse<any>) => {
        if (response['status'] === 200) {
          this.getWorkflow()
        }
      })
    );
  }

  getWorkflow() {
    this.subscriptions.push(this.workflowService.getWorkflowById(this.workflow.id).subscribe((resp) => {
      if(resp?.['data']?.workflow?.draft?.videoFlowData?.transcript) {
        this.transcription = resp?.['data']?.workflow?.draft?.videoFlowData?.transcript.transcriptions.filter(res => res.speaker==0)
        this.workflow.draft.videoFlowData = resp?.['data']?.workflow?.draft?.videoFlowData
        this.getResultFromAI()
      }
    }))
  }

  getResultFromAI() {
    this.transcriptionCheck = false
    this.aiLoading = true

    // this.loaderService.show()
    // this.transcription = this.workflow[this.type].videoFlowData.transcript.transcriptions
    this.subscriptions.push(this.workflowService.getTextResult(this.transcriptionId, this.transcription, this.workflow.id, this.systemPrompt).subscribe({
      next: (result) => {
        if(result) {
          try {
            this.workflow[this.type].videoFlowData.aiResult = result['data'].result;
            this.workflowAIResultChanged.emit(true);
            const AISteps = JSON.parse(result['data'].result);
            this.mockStepsCopy = AISteps;
            this.mockStepsCopy = this.mapAIResult(AISteps);
            this.videoChapters = this.mergeData(this.transcription, this.mockStepsCopy);
            this.dropListConnections = this.videoChapters.map((item) => item.uuid.toString());
            this.videoChapters.forEach((chapter, index) => {
              this.createWorkflowStep(chapter, index);
            });
            this.aiLoading = false
          } catch (error) {
            // Hata durumunda loading'i kapat
            this.aiLoading = false;
            this.loaderService.hide();
          }
          
          // const AISteps = JSON.parse(result['data'].result)
          // this.workflow[this.type].videoFlowData.aiResult = AISteps
          // this.workflowAIResultChanged.emit(true)
          // this.mockStepsCopy = AISteps
          // this.mockStepsCopy = this.mapAIResult(AISteps)
          // this.videoChapters = this.mergeData(this.transcription, this.mockStepsCopy)
          // this.dropListConnections = this.videoChapters.map((item) => item.uuid.toString())
          // this.videoChapters.forEach((chapter,index) => {
          //   this.createWorkflowStep(chapter,index)
          // })
          // this.aiLoading = false
          // this.loaderService.hide()
        }
      },
      error: (err) => this.loaderService.hide()
    }))
  }

  selectedStepChange(step: any) {
    if(step) {
      const startTime = step.data.layouts[0].items[0].templateOptions.startTime
      const startTimeSeconds = this.convertTimeToSeconds(startTime)
      this.setVideoTime(startTimeSeconds)
      this.scrollToSpecificIndex(step.data.order)
    }
  }

  dropStep(event: CdkDragDrop<any>) {
    if (event.container.id === event.previousContainer.id) {
      if (event.currentIndex !== event.previousIndex) {
        const chapterIndex = this.videoChapters.findIndex((item) => item.uuid === event.previousContainer.data.uuid)
        // steps.forEach(response => {
        //   this.videoChapters[chapterIndex +1].steps.unshift(response)
        // })
        if(this.videoChapters.length !== chapterIndex +1) {
          const steps = event.container.data.steps.splice(event.currentIndex+1, event.previousIndex);

          this.videoChapters[chapterIndex +1].steps = steps.concat(this.videoChapters[chapterIndex +1].steps);
        }

        this.videoChapters.forEach(chapter => {
          const timeStampStart = chapter.steps[0].startTime
          const timeStampEnd = chapter.steps[chapter.steps.length-1].endTime
          chapter.timestampStart = timeStampStart
          chapter.timestampEnd = timeStampEnd
        })
      }
    } else {
      let targetChapterIndex, previousChapterIndex;
      
      this.videoChapters.forEach((item, index) => {
        if (item.uuid === event.container.data.uuid) {
          targetChapterIndex = index;
        } else if (item.uuid === event.previousContainer.data.uuid) {
          previousChapterIndex = index;
        }
      });

      // It means user pulled out the chapter
      if (targetChapterIndex > previousChapterIndex) {

        const chapterIndexesToBeRemoved = [];
        for(let chapterIndex = previousChapterIndex+1; chapterIndex < targetChapterIndex+1; chapterIndex++) {
          const stepCount = chapterIndex === targetChapterIndex ? event.currentIndex + 1 : this.videoChapters[chapterIndex].steps.length;
          const stepsToInclude = this.videoChapters[chapterIndex].steps.splice(0, stepCount);
          this.videoChapters[previousChapterIndex].steps = this.videoChapters[previousChapterIndex].steps.concat(stepsToInclude);
          
          if (this.videoChapters[chapterIndex].steps.length === 0) {
            chapterIndexesToBeRemoved.push(chapterIndex);
          }
        }

        this.videoChapters = this.videoChapters.filter((item, index) => !chapterIndexesToBeRemoved.includes(index));
        this.videoChapters.forEach(chapter => {
          const timeStampStart = chapter.steps[0].startTime
          const timeStampEnd = chapter.steps[chapter.steps.length-1].endTime
          chapter.timestampStart = timeStampStart
          chapter.timestampEnd = timeStampEnd
        })
      } else {
        const chapterIndexesToBeRemoved = [];
        let stepsToIncludeTotal = []

        if(previousChapterIndex + 1 !== this.videoChapters.length) {

          for(let chapterIndex = targetChapterIndex+1; chapterIndex <= previousChapterIndex; chapterIndex++) {
            const stepCount = chapterIndex === targetChapterIndex+1 ? event.currentIndex + 1 : this.videoChapters[chapterIndex].steps.length;
            const stepsToInclude = this.videoChapters[chapterIndex].steps.splice(0, stepCount);
            // this.videoChapters[previousChapterIndex+1].steps = stepsToInclude.concat(this.videoChapters[previousChapterIndex+1].steps)
            stepsToIncludeTotal = stepsToIncludeTotal.concat(stepsToInclude)
            
            if (this.videoChapters[chapterIndex].steps.length === 0) {
              chapterIndexesToBeRemoved.push(chapterIndex);
            }
          }
          this.videoChapters[previousChapterIndex+1].steps = stepsToIncludeTotal.concat(this.videoChapters[previousChapterIndex+1].steps)
          this.videoChapters = this.videoChapters.filter((item, index) => !chapterIndexesToBeRemoved.includes(index));
          this.videoChapters.forEach(chapter => {
            const timeStampStart = chapter.steps[0].startTime
            const timeStampEnd = chapter.steps[chapter.steps.length-1].endTime
            chapter.timestampStart = timeStampStart
            chapter.timestampEnd = timeStampEnd
          })

        }
      }

    }
    this.videoSteps = []
    this.videoChapters.forEach((chapter,index) => {
      this.createWorkflowStep(chapter,index)
    })
  }

  divideSteps(chapterIndex, stepIndex) {
    const steps = this.videoChapters[chapterIndex].steps.splice(stepIndex+1, this.videoChapters[chapterIndex].steps.length);

    const uuid = Math.random().toString(16).slice(2);
    this.videoChapters.splice(chapterIndex+1, 0, { uuid, title: 'Step ' + (chapterIndex+2), steps });
    this.dropListConnections = [...this.dropListConnections, uuid]; 

    this.videoChapters.forEach(chapter => {
      const timeStampStart = chapter.steps[0].startTime
      const timeStampEnd = chapter.steps[chapter.steps.length-1].endTime
      chapter.timestampStart = timeStampStart
      chapter.timestampEnd = timeStampEnd
    })

    this.videoSteps = []
    this.videoChapters.forEach((chapter,index) => {
      this.createWorkflowStep(chapter,index)
    })

  }

  chapterTrackByFn(index, chapter): number {
    return chapter.uuid
  }

  trackByFn(index, step): string {
    return step.uuid
  }

  customizeSteps() {
    
  }

  setVideoTime(seconds: number) {
    if (this.myVideoElement && this.myVideoElement.nativeElement) {
      this.myVideoElement.nativeElement.currentTime = seconds;
    }
  }

  convertTimeToSeconds(timeString: string): number {
    const timeParts = timeString.split(':');
    if (timeParts.length !== 3) {
      throw new Error('Invalid time format');
    }
  
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
  
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      throw new Error('Invalid time format');
    }
  
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }

  convertSecondsToTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds.toString();
  
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return formattedTime;
  }

  scrollToIndex(index: number) {
    if(this.scrollContainer) {
      const element = this.scrollContainer.nativeElement.children[index];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    
  }

  scrollToSpecificIndex(index) {
    const indexToScroll = index; // Change this to the desired index
    this.scrollToIndex(indexToScroll);
  }

  playVideo(chapter) {
    let totalSecondsStart = this.mapTimeStamp(chapter.timestampStart)
    const totalSecondsEnd = this.mapTimeStamp(chapter.timestampEnd) + 2
    this.mainVideoUrl = this.videoUrl + `#t=${totalSecondsStart},${totalSecondsEnd}`
    this.cdr.detectChanges()
    if (this.myVideoElement) {
      const videoElement: HTMLVideoElement = this.myVideoElement.nativeElement;
      videoElement.play(); // Videoyu başlat
    }

  }

  mapTimeStamp(time) {
    const timeString = time;
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds
   }

   animationCreated(animationItem: AnimationItem): void {
  }

}
