<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header">
            <div style="position: absolute;top: 3px;right:12px;cursor: pointer;" (click) = "modalRef.hide()">
                <img src="assets/img/close_white.svg" alt="">
                 
             </div>
        </div>
        <div class="modal-body custom-table-body" style="position: relative;">
            <app-table-report [tableData]="tableData" [workflowPublishedVersion]="workflowPublishedVersion"
            [workflowTranslations]="workflowTranslations" [selectedLang]="selectedLang"></app-table-report>
          </div>
        </div>
    </div>