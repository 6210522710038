import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { filter, first, map, distinctUntilChanged } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private http: HttpClient) { }

  checkArchiveStatuses() {
    return this.http.post<any>(environment.endPoints.changeazure,{
      token: this.authService.currentUser.token,
      action: "check"
    }).pipe(first()).toPromise();
  }

  connectAzureAccount(account_name: string, container_name: string, account_key: string) {
    return this.http.post(environment.endPoints.changeazure,{
      token: this.authService.currentUser.token,
      action: "connect",
      account_name: account_name,
      container_name: container_name,
      account_key: account_key
    }).pipe(first()).toPromise();
  }

  disconnectAzureAccount() {
    return this.http.post(environment.endPoints.changeazure, {
      token: this.authService.currentUser.token,
      action: "disconnect"
    }).pipe(first()).toPromise();
  }

  changeAlwaysRecordSetting(alwaysRecord: boolean) {
    return this.http.post(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      action: "alwaysArchive",
      setting: alwaysRecord
    }).pipe(first()).toPromise();
  }

  changeAlwaysFullHdSetting(alwaysFullHd: boolean) {
    return this.http.post(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      action: "alwaysFullHd",
      setting: alwaysFullHd
    }).pipe(first()).toPromise();
  }

  changeEmailRecipientsSetting(emailList: string[]) {
    return this.http.post(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      action: "emailRecipients",
      setting: emailList
    }).pipe(first()).toPromise();
  }

  changeTimezoneSetting(timezone: string) {
    return this.http.post(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      action: "timezone",
      setting: timezone
    }).pipe(first()).toPromise();
  }

  changeContactSetting(setting: any) {
    return this.http.post(environment.endPoints.changesettings, {
      token: this.authService.currentUser.token,
      action: "changecontacts",
      setting: setting
    }).pipe(first()).toPromise();
  }

  createContactID() {
    return this.dbService.createPushId();
  }

  setServiceEmail(credentials: any) {
    return this.http.post(environment.endPoints.setserviceemail,{
      token: this.authService.currentUser.token,
      action: "setserviceemail",
      credentials: credentials
    }).pipe(first()).toPromise();
  }

  verifyServiceEmail(verification: string) {
    return this.http.post(environment.endPoints.setserviceemail,{
      token: this.authService.currentUser.token,
      action: "verifyserviceemail",
      verification: verification
    }).pipe(first()).toPromise();
  }

  disableServiceEmail() {
    return this.http.post(environment.endPoints.setserviceemail,{
      token: this.authService.currentUser.token,
      action: "disableserviceemail"
    }).pipe(first()).toPromise();
  }

  addSamlConfiguration(name: string, ssoUrl: string, cert: string) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: { name: name, url: ssoUrl, cert: cert },
      action: "addsamlconfig"
    }).pipe(first()).toPromise();
  }

  removeSamlConfiguration(name: string) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: { name: name },
      action: "removesamlconfig"
    }).pipe(first()).toPromise();
  }

  addScimConfiguration(name: string) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: { name: name },
      action: "addscimconfig"
    }).pipe(first()).toPromise();
  }

  removeScimConfiguration(name: string) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: { name: name },
      action: "removescimconfig"
    }).pipe(first()).toPromise();
  }

  addOidcConfiguration(name: string, discoveryEndpoint: string, clientId: string, clientSecret: string, responseTypes: string[], logoutUrl: string, redirectUrls: string[]) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: { name: name, discoveryEndpoint: discoveryEndpoint, clientId: clientId, clientSecret: clientSecret, responseTypes: responseTypes, logoutUrl: logoutUrl, redirectUrls: redirectUrls },
      action: "addoidcconfig"
    }).pipe(first()).toPromise();
  }

  removeOidcConfiguration(name: string) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: { name: name },
      action: "removeoidcconfig"
    }).pipe(first()).toPromise();
  }

  addWebhook(webhook: any) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: webhook,
      action: "addwebhook"
    }).pipe(first()).toPromise();
  }

  removeWebhook(webhook: any) {
    return this.http.post<any>(environment.endPoints.changesettings,{
      token: this.authService.currentUser.token,
      setting: webhook,
      action: "removewebhook"
    }).pipe(first()).toPromise();
  }

  savePasswordSettings(AZSelected: boolean, azSelected: boolean, numberSelected: boolean, specialCharacterSelected: boolean, passwordLength: number, strongEnabled: boolean) {
    return this.http.post<any>(environment.endPoints.passwordstrength,{
      token: this.authService.currentUser.token,
      AZSelected: AZSelected,
      azSelected: azSelected,
      numberSelected: numberSelected,
      specialCharacterSelected: specialCharacterSelected,
      passwordlength: passwordLength,
      strongEnabled: strongEnabled
    }).pipe(first()).toPromise();
  }

  getAccountLabels(): Observable<string[]> {
    return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/account_data/labels`)
  }

  setAccountLabels(labels: string[]): Promise<void> {
    return this.dbService.set(`accounts/${this.authService.currentUser.account_id}/account_data/labels`, labels)
  }
}
