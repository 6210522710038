import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'objectToArray'})
export class ObjectToArrayPipe implements PipeTransform {

    transform(value: any, keyName: string = "id"): any[] {
        return value ? Object.keys(value).map(id => {
            const u = value[id];
            u[keyName] = id;
            return u;
        }) : [];
    }
}