<div class="workflow-wrapper" (mouseup)="onMouseUp($event)">
    <!--<div *ngIf="showPreviewOverlay" class="step-preview-overlay" (click)="makeFit()"></div>-->
    <div class="workflow-container">

        <div class="workflow-navbar list-header d-flex align-items-center justify-content-between" #navbarElement>
            <div class="d-flex align-items-center">
                <img class="logo" [src]="('design' | env).navbarLogo" height="45px">
                <div class="fs-20 font-weight-700 detail-name">{{ workflowDetail?.name }}</div>
            </div>

            <div class="d-flex align-items-center">
                <ng-container *ngIf="(workflow.draft && !workflow.draft.isVideoFlow) || (workflow.publihed && !workflow.publihed.isVideoFlow)">
                    <a class="set-default fs-12 font-weight-500 text-grey me-2" (click)="changeDefaultLanguage()" *ngIf="workflow.draft && selectedLang !== workflow.draft.defaultLanguage">Set as default</a>
                <app-dropdown [imageExist]="true" [type]="type" title="{{selectedLangName ? selectedLangName : 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SELECT-LANGUAGE' | translate}}" style="width: 200px" class="me-2">
                    <div class="search-box">
                        <input  type="text" #searchInput class="form-control filter fs-14" [formControl]="filterLang" (click)="stop($event)" placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                        <div class="search-icon"> <img src="assets/img/search-copy.svg" alt=""></div>
                    </div>
                    <label class="fs-12 ps-3 text-grey font-weight-600" style="font-size:10px !important">{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.ADDED' | translate }}</label>
                    <div *ngFor="let lang of allLangs" (click)="changeCurrentLang(lang)" >
                        <div *ngIf="workflowTranslations?.[lang.code]" class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer">
                            <div class="d-flex justify-content-between lang-line">
                                <div>
                                    <img src="assets/img/check_icon.svg" alt="" *ngIf="selectedLang == lang.code" style="margin-left:-22px;">
                                    {{lang.name}} <span *ngIf="lang.code== workflow?.draft?.defaultLanguage" class=" ps-3 fs-12 font-weight-600" style="color:#314B76">{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.DEFAULT' | translate }}</span>
                                </div>
                                <div class="remove cursor-pointer" (click)="deleteWorkflowLanguage(lang,$event)" *ngIf="workflow.draft && lang.code !== workflow.draft.defaultLanguage">
                                    <img src="assets/img/remove_icon.svg" alt="" class="pe-3 remove-lang">
                                    <img src="assets/img/remove_hover_icon.svg" alt="" class="pe-3 remove-lang-hover">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0" style="color:grey">
                    <label class="fs-12 ps-3 text-grey font-weight-600" style="font-size:10px !important">{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.NOT-ADDED' | translate }}</label>
                    <div *ngFor="let lang of filteredLangs" (click)="addNewLang(lang)">
                        <div *ngIf="!workflowTranslations[lang.code]" class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer">
                            <div class="d-flex justify-content-between lang-line">
                                <div>{{lang.name}}</div>
                                <img src="assets/img/add_icon.svg" alt="" class="pe-3 add-lang">
                            </div>
                            
                        </div>
                    </div>
                </app-dropdown> 

                <img class="me-3" src="assets/img/info_icon.svg" alt="" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.LANGUAGE.LANGUAGE-INFO-TEXT' | translate )">

                </ng-container>
                
                <button class="v-btn btn btn-primary vsight-button create-button me-2" [ngClass]="{'save-button-disabled':!(isWorkflowDetailValid && isAllStepsValid && isWorkflowConnectionValid)}" *ngIf="type=='draft'" [disabled]="!(isWorkflowDetailValid && isAllStepsValid && isWorkflowConnectionValid)" (click)="publishWorkflow()" style="min-width: 80px;">{{ 'MAIN.CONTENT.WORKFLOW.PUBLISH' | translate | titlecase }}</button>
                <button class="v-btn btn btn-primary vsight-button create-button me-2" [ngClass]="{'save-button-disabled':!(isWorkflowDetailValid && isAllStepsValid && isWorkflowConnectionValid)}" *ngIf="type=='draft'" [disabled]="!(isWorkflowDetailValid && isAllStepsValid && isWorkflowConnectionValid)" (click)="save()" style="min-width: 80px;">{{ 'MAIN.CONTENT.WORKFLOW.SAVE' | translate }}</button>
                <button class="v-btn btn btn-primary vsight-button create-button me-2" *ngIf="type=='published'" (click)="editWorkflow()" style="min-width: 80px;">{{ 'MAIN.CONTENT.WORKFLOW.EDIT-WORKFLOW' | translate }}</button>
                <a (click)="onClose()" class="close">
                    <img src="assets/img/close-icon.svg" alt="" style="margin-bottom: 3px;margin-left: 30px;">
                </a>
            </div>
        </div>

        <div class="workflow-inner-container">
                   
            <div class="workflow-sidebar" #sidebarElement>
                <div class="d-flex flex-column align-items-center" style="padding-top: 5px;">
                    <div class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[0]}" (click)="onTabClicked(0)">
                        <img src="assets/img/details.svg"
                        [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.SIDEBAR.DETAILS' | translate)" tooltipPosition="right" [tooltipDelay]="0">
                    </div>
                    <div *ngIf="!isVideoFlow" class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[1]}" (click)="onTabClicked(1)"
                    [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.SIDEBAR.EDITOR' | translate)" tooltipPosition="right" [tooltipDelay]="0">
                        <img src="assets/img/workflows-icon.svg">
                    </div>
                    <div *ngIf="isVideoFlow" class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[1]}" (click)="onTabClicked(1)">
                        <img src="assets/img/workflows-icon.svg" alt=""
                        [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.SIDEBAR.EDITOR' | translate)" tooltipPosition="right" [tooltipDelay]="0">
                    </div>
                    <div *ngIf="!('electron' | env) && !isVideoFlow" class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[2]}" (click)="changeTab(2)">
                        <img src="assets/img/connections.svg" alt=""
                        [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.SIDEBAR.CONNECTIONS' | translate)" tooltipPosition="right" [tooltipDelay]="0">
                    </div>
                    <!-- <div class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[3]}" (click)="onTabClicked(3)"><img src="assets/img/connections.svg" alt="">Approval</div> -->
                    <div class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[3]}" (click)="onTabClicked(3)" *ngIf="isQrAvailable">
                        <img src="assets/img/access.svg"
                        [dynamicTooltip]="'Easy Access'" tooltipPosition="right" [tooltipDelay]="0"></div>
                </div>
            </div>
            
            <div class="workflow-content"  [ngStyle]="{'overflow-y': tabs[2] || tabs[0] ? 'hidden' : 'scroll'}">

                <app-workflow-detail *ngIf="tabs[0]" [type]="type" [(workflowDetail)]="workflowDetail" [connectionEnabled]="connectionEnabled" (formValid)="isWorkflowDetailValid = $event" (workflowDetailChange)="workflowDetailChange($event)" [workflowId]="workflow.id"></app-workflow-detail>

                <div class="workflow-steps" *ngIf="tabs[1] && !isVideoFlow">
                    <div class="step-list">
                        <app-step-list 
                        [type]="type" 
                        [(stepList)]="workflowSteps" 
                        [connectionEnabled]="connectionEnabled"
                        [invalidSteps]="isStepLayoutValid"
                        [(initialStep)]="initialStep"
                        (selectedStepChange)="selectedStepChange($event)"
                        [stepDelete]="stepDelete"
                        (onStepClone)="onStepClone($event)"
                        (onStepListClone)="onStepListClone($event)"
                        (onStepMove)="onStepMove($event)"
                        (onStepListMove)="onStepListMove($event)"
                        (onStepDelete)="onStepDelete($event)"
                        (stepListChange)="stepListChange($event)"
                        [(workflowTranslations)]="workflowTranslations"
                        (deleteSubWorkflow)= "deleteSubWorkflow($event)"
                        [(workflowStepFiles)]= "workflowStepFiles"
                        [addOns]="addOns"
                        ></app-step-list>
                    </div>

                    <div class="step-preview-wrapper">
                        <div class="step-preview-container" #stepPreviewContainer *ngIf="selectedStepForPreview">
                            <div class="step-preview-header" [ngStyle]="previewHeaderFooterStyle">
                                <div class="step-preview-title text-dark-grey fs-12 font-weight-400">{{ 'MAIN.CONTENT.WORKFLOW.STEP-PREVIEW' | translate }}</div>
                                <ng-select class="step-select text-secondary ml-3 fs-14" [searchable]="false" [clearable]="false" (change)="onResolutionChange($event)" [(ngModel)]="resolution">
                                    <ng-option *ngFor="let opt of resolutions" [value]="opt">{{ opt.name }}</ng-option>
                                </ng-select>
                            </div>
                            <div class="step-preview-outer" [ngStyle]="previewOuterStyle">
                                <div class="step-preview-inner" [ngStyle]="previewInnerStyle">
                                    <app-step-preview [stepData]="selectedStepForPreview.data" [type]="type" [selectedStepOrder]="selectedStepOrder" [totalSectionCount]="totalSectionCount"
                                     [sectionStepsCount]="sectionStepsLength" [sectionStepOrder]="sectionStepOrder" [(selectedLayoutNo)]="selectedLayoutNo" 
                                     (selectedLayoutNoChange)="selectedLayoutNoChange($event)" [(template)]="selectedStepForPreview.data.template" 
                                     (templateChange)="templateChange($event)" (templateChangeAvailable)="templateChangeAvailable($event)" [stepListCount]="workflowSteps.length" [workflowName]="workflowDetail.name" 
                                     (emptyLayout)="emptyLayout($event)" (onPreviewEvent)="onPreviewEvent($event)" (onPreviewRowDelete)="previewRowEventData = $event" (onPreviewColumnDelete)="previewColumnEventData = $event" [(workflowTranslations)]="workflowTranslations"></app-step-preview>
                                </div>    
                            </div>
                            <div class="step-preview-footer" [ngStyle]="previewHeaderFooterStyle">
                                <a class="change-layout-link fs-14 font-weight-600 text-blue" (click)="changeStepLayout(selectedStepForPreview.data)" [ngStyle]="{'pointer-events': type=='published' ? 'none' : ''}">
                                    {{ 'MAIN.CONTENT.WORKFLOW.CHANGE-STEP-LAYOUT' | translate }}
                                </a>
                                <!--<ng-select class="step-select text-secondary me-1" #selectedOption [searchable]="false" [clearable]="false" (ngModelChange)="onOrientationChange($event)" [(ngModel)]="orientationValue">
                                    <ng-option *ngFor="let opt of orientations" [value]="opt.value">
                                        <img style="width:30px; height:20px;" src="assets/img/fullscreen.svg">
                                        <div *ngIf="opt.name === 'vertical-equal'" style="position:absolute;transform:rotate(-90deg);margin-top:-20px;margin-left:2px;">
                                            <img src="assets/img/vertical.svg" style="width:25px; height:15px">
                                        </div>
                                        <div *ngIf="opt.name === 'horizontal-equal'" style="position:absolute;margin-top:-21.5px;">
                                            <img src="assets/img/horizontal.svg" style="width:30px; height:15px">
                                        </div>
                                        {{ opt.value }}
                                    </ng-option>
                                </ng-select>-->
                                <button class="percent-btn btn btn-sm btn-secondary" disabled>%{{ (previewScalePercent * 100) | number: '1.0-0' }}</button>
                            </div>
                        </div>
                    </div>

                    <div class="widget-properties">
                        <div class="d-flex flex-column" *ngIf="!(selectedLayoutNo || selectedLayoutNo === 0) && selectedWidgetData && type=='draft'">
                            <div class="rectangle d-flex">
                                <div class="info-p">
                                    <img src="assets/img/info.svg">
                                </div>
                                <div class="inform-text">{{ 'MAIN.CONTENT.WORKFLOW.WIDGET-PROPERTIES' | translate }}</div>
                            </div>
                        </div>
                        <div class="d-flex flex-column" *ngIf="type=='published'">
                            <div class="rectangle d-flex">
                                <div class="info-p">
                                    <img src="assets/img/info.svg">
                                </div>
                                <div class="inform-text">{{ 'MAIN.CONTENT.WORKFLOW.EDIT-MODE-TEXT-MESSAGE' | translate }}</div>
                            </div>
                        </div>
                        <app-widget-properties 
                            *ngIf="(selectedLayoutNo || selectedLayoutNo === 0) && type=='draft'" 
                            [(widgetData)]="selectedWidgetData" 
                            [layoutCount]="selectedStep?.data?.template?.count" 
                            (widgetDataChange)="widgetDataChange($event)" 
                            (widgetFormValidChange)="widgetFormValidChange($event)"
                            [workflowId]="workflow.id" 
                            [stepId]="selectedStep?.id" 
                            [step]="selectedStep" 
                            [selectedLayoutNo]="selectedLayoutNo"
                            [(deleteWidget)]="deleteWidget" 
                            (deleteWidgetChange)="deleteWidgetChange($event)"
                            [(workflowTranslations)]="workflowTranslations" 
                            [previewEventData]="previewEventData"
                            [previewRowEventData]="previewRowEventData"
                            [previewColumnEventData]="previewColumnEventData"
                            (selectWorkflowToCopySteps)="selectWorkflowToCopySteps($event)"
                            (deleteSubWorkflow)= "deleteSubWorkflow($event)"
                            (connectionEnabledChanged)="connectionEnabledChanged($event)"
                            (viewStepFiles) = "viewStepFiles($event)"
                            ></app-widget-properties>
                            <app-step-files
                                *ngIf="(!selectedLayoutNo && selectedLayoutNo !== 0) && type=='draft' && showStepFiles"
                                [workflowId]="workflow.id"
                                [(selectedStepFiles)]="selectedStep?.data.files"
                                (selectedStepFilesChange)="selectedStepFilesChange($event)">
                            </app-step-files>

                    </div>
                </div>

                <!-- remove hideConnections to enable connections on prod (dont forget to remove [class.p-relative]="hideConnections") -->
                <div *ngIf="tabs[2] && hideConnections" style="width: 100%; height: 100%; z-index: 1001; position: absolute; background-color: white;" class="d-flex justify-content-center align-items-center">
                    <div>Coming Soon!</div>
                </div>

                <div *ngIf="type=='published' && !tabs[3] && !isVideoFlow" style="width: 100%; height: 100%; z-index: 1001; position: absolute;" class="d-flex justify-content-center align-items-center">
                </div>

                <div *ngIf="tabs[2]" style="width: 100%; height: 100%;" class="d-flex justify-content-center align-items-center">
                    <app-workflow-connections [workflowName]="workflowDetail.name" [(stepList)]="workflowSteps" [stepAreaPosition]="workflowStepsAreaPosition" [mouseUp]="mouseUp" (stepsAreaPositionChange)="workflowConnectionStepsAreaPosition($event)" (availableChange)="workflowConnectionChange()" [sidebarOpen]="sidebarOpen"
                        [sidebar]="sidebar" [navbar]="navbar" [outOfConnection]="outOfConnection" style="width: 100%;height: 100%;" (stepListChange)="stepListChange($event)" (allFormIsValid)="isWorkflowConnectionValid = $event" [(saveButtonClicked)]="saveButtonClicked"
                        (saveButtonClickedChange)="saveButtonClickedChange($event)" (changesSaved)="goOutOfConnection($event)" [(pageClosed)]="pageClosed" (pageClosedChange)="pageClosedChange($event)" [connectionEnabled]="connectionEnabled" (connectionEnabledChanged)="connectionEnabledChanged($event)" [(initialStep)]="initialStep"
                        [(zoomOutValue)]="zoomOutValue" (zoomOutValueChange)="zoomOutValueChange($event)" [(zoomInValue)]="zoomInValue" (zoomInValueChange)="zoomInValueChange($event)" [(fullMode)]="fullMode" (fullModeChange)="fullModeChange($event)" [workflowTranslations]="workflowTranslations"></app-workflow-connections>
                </div>
                <div class="zoom-group" [ngStyle]="{'width': fullMode && isWorkflowConnectionValid ? '100%' : '50%'}" *ngIf="tabs[2]">
                    <div class="zoom-outline me-1" (click)="zoomOut()" [dynamicTooltip]="'Zoom out'">
                        <img src="assets/img/zoom_out.svg" alt="" class="zoom">
                    </div>
                    <div class="zoom-outline me-1" (click)="zoomIn()" [dynamicTooltip]="'Zoom in'">
                        <img src="assets/img/zoom_in.svg" alt="" class="zoom">
                    </div>
                    <div class="zoom-outline" (click)="fullSecreen()" *ngIf="!fullMode || !isWorkflowConnectionValid" [dynamicTooltip]="'Fullscreen'">
                        <img src="assets/img/full.svg" alt="" class="zoom">
                    </div>
                    <div class="zoom-outline" (click)="fullSecreenOff()" *ngIf="fullMode && isWorkflowConnectionValid" [dynamicTooltip]="'Exit Fullscreen'">
                        <img src="assets/img/full_off.svg" alt="" class="zoom">
                    </div>
                </div>

                <!-- <div  style="width: 100%; height: 100%" *ngIf="isQrAvailable && tabs[3]">
                    <app-approval style="width: 100%;height: 100%;" ></app-approval>
                </div> -->

                <div  style="width: 100%; height: 100%" *ngIf="isQrAvailable && tabs[3]">
                    <app-guest-access style="width: 100%;height: 100%;" [workflowId]= "workflow.id" [workflow]= "workflow" (easyAccessUpdated)= "updateEasyAccess()"></app-guest-access>
                </div>

                <div class="video-flows"  style="width: 100%; height: 100%;overflow-y: hidden; overflow-x: hidden;" *ngIf="tabs[1] && isVideoFlow">
                    <app-workflow-from-video style="width: 100%;height: 100%;"
                     [(workflowSteps)]="workflowSteps" 
                     [workflowId]= "workflow.id" 
                     [(workflow)]= "workflow" 
                     [type]="type"
                     [(initialStep)]="initialStep"
                     (workflowStepsChange)="stepListChange($event)"
                     [(workflowTranslations)]="workflowTranslations"
                     [status] = "status"
                     (workflowTranscriptionIdChanged)="workflowTranscriptionIdChanged($event)"
                     (workflowAIResultChanged)="workflowAIResultChanged($event)"
                     ></app-workflow-from-video>
                </div>
            </div>
        </div>
    </div>
</div>