<div class="storage-container">
    <div class="">
      <div class="d-flex justify-content-between align-items-center">
        <span class="fs-14 font-weight-600">Remote Assistance Storage</span>
      </div>
      <div class="">
        <div *ngIf="azureInfo">
          <ng-container *ngIf="!webdavEnabled">
            <div>
              <div class="mt-4">
                <progressbar class="progress mb-3" [value]="sharedAzureProgress" [max]="100"></progressbar>

                <div class="custom-control centered-checkbox custom-checkbox mr-1 mr-md-3 mt-4" [class.disabled]="true">
                  <input type="radio" class="custom-control-input form-check-input" id="shared-checkbox" [disabled]="true" [value]="azureInfo.storage_type.shared" [(ngModel)]="azureInfo.storage_type.shared">
                  <label class="fs-14 font-weight-400 text-dark-grey custom-control-label" for="shared-checkbox">{{('design' | env).appName}} Cloud</label>
                </div>

                <div *ngIf="azureInfo.shared_storage.dedicated > 0">
                  <div class="mt-1 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.STORAGE.SHARED_DEDICATED_SIZE' | translate:azureInfo.shared_storage }}. {{ 'MAIN.STORAGE.SHARED_DEDICATED_DELETE' | translate }}</div>
                </div>
                <div *ngIf="azureInfo.shared_storage.expire > 0">
                  <div class="mt-1 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.STORAGE.SHARED_EXPIRE_TIME' | translate:azureInfo.shared_storage }}. {{ 'MAIN.STORAGE.SHARED_EXPIRE_DELETE' | translate }}</div>
                </div>
                <div *ngIf="azureInfo.shared_storage.dedicated === 0 && azureInfo.shared_storage.expire === 0">
                  <div class="mt-1 fs-14 font-weight-600 text-dark-grey" [innerHTML]="'MAIN.STORAGE.SHARED_UNLIMITED' | translate"></div>
                </div>
              </div>
            </div>
            <div>
              <div class=" mt-5">
                <div class="custom-control centered-checkbox custom-checkbox mr-1 mr-md-3" [class.disabled]="true">
                  <input type="radio" class="custom-control-input form-check-input" id="private-checkbox" [disabled]="true" [value]="!azureInfo.storage_type.shared" [(ngModel)]="!azureInfo.storage_type.shared">
                  <label class="fs-14 font-weight-400 text-dark-grey custom-control-label" for="private-checkbox">Azure Cloud</label>
                </div>
                
                <ng-container *ngIf="azureInfo.storage_type.shared">
                  <div class="mt-1 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.STORAGE.PRIVATE_STORAGE_MESSAGE' | translate:('design' | env) }} {{ 'MAIN.STORAGE.PRIVATE_STORAGE_EXPLANATION' | translate }}</div>
                  <div class="fs-14 font-weight-700 text-danger mt-4">{{ 'MAIN.STORAGE.PRIVATE_NO_AZURE' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="!azureInfo.storage_type.shared">
                  <div>
                    <div class="fs-14 font-weight-700 text-success mb-3">{{ 'MAIN.STORAGE.PRIVATE_AZURE' | translate }}</div>
                    <div [innerHTML]="'MAIN.STORAGE.PRIVATE_AZURE_NAME' | translate:azureInfo.storage_type"></div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="allowArchiving && !allowAzureIntegration" class="azure-integration-disabled-overlay" [dynamicTooltip]="'MAIN.STORAGE.ARCHIVE_STORAGE.PRIVATE_STORAGE_UNAVAILABLE'" [tooltipDisabled]="allowAzureIntegration" [tooltipDelay]="200"></div>
            </div>

            <ng-container *ngIf="!webdavEnabled">
                <button [class.disabled]="!allowAzureIntegration" *ngIf="allowArchiving && azureInfo && azureInfo.storage_type.shared" class="v-btn btn btn-primary vsight-button mt-4" (click)="onCheckAzure(true)"
                  [dynamicTooltip]="'MAIN.STORAGE.ARCHIVE_STORAGE.PRIVATE_STORAGE_UNAVAILABLE'" [tooltipDisabled]="allowAzureIntegration" [tooltipDelay]="200">{{ 'MAIN.STORAGE.CONNECT_AZURE' | translate }}</button>
                <button [class.disabled]="!allowAzureIntegration" *ngIf="allowArchiving && azureInfo && !azureInfo.storage_type.shared" class="v-btn btn btn-primary vsight-button mt-4" (click)="onCheckAzure(false)"
                  [dynamicTooltip]="'MAIN.STORAGE.ARCHIVE_STORAGE.PRIVATE_STORAGE_UNAVAILABLE'" [tooltipDisabled]="allowAzureIntegration" [tooltipDelay]="200">{{ 'MAIN.STORAGE.DISCONNECT_AZURE' | translate }}</button>
              </ng-container>
          </ng-container>
  
          <div class="webdav-storage border border-light bg-light rounded d-flex flex-row mt-2" *ngIf="webdavEnabled">
            <div class="d-flex align-items-center justify-content-center px-3 px-md-4">
              <div class="custom-control centered-checkbox custom-checkbox mr-1 mr-md-3 disabled">
                <input type="checkbox" class="custom-control-input" id="private-checkbox" [disabled]="true" [(ngModel)]="webdavEnabled">
                <label class="custom-control-label" for="private-checkbox"></label>
              </div>
              <div class="text-info font-weight-bold" style="font-size: 12px;"><span style="color: #4a688f">WEB</span><span style="color: #e6646b">DAV</span></div>
            </div>
            <div class="">{{ 'MAIN.STORAGE.WEBDAV_MESSAGE' | translate }}</div>
          </div>
  
        </div>
        <div *ngIf="!azureInfo" class="text-center">{{ 'MAIN.STORAGE.AZURE_INFO_LOADING' | translate }}</div>
        <div *ngIf="!allowArchiving" class="disabled-overlay" [dynamicTooltip]="'MAIN.STORAGE.ARCHIVE_STORAGE.ARCHIVE_UNAVAILABLE'" [tooltipDisabled]="allowArchiving" [tooltipDelay]="200"></div>
      </div>
    </div><!-- card -->
  </div>
  
  <ng-template #checkAzureTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card">
          <div class="card-header py-3">{{ (dataModel.connect ? 'MAIN.STORAGE.CHECK_AZURE_TEMPLATE.CONNECT_AZURE_CLOUD_STORAGE' : 'MAIN.STORAGE.CHECK_AZURE_TEMPLATE.DISCONNECT_AZURE_CLOUD_STORAGE') | translate }}</div>
          <div class="card-body">
            <div class="m-body pt-4 px-5">
              <div class="font-weight-bold text-danger mb-3">{{ 'MAIN.STORAGE.CHECK_AZURE_TEMPLATE.CAUTION' | translate }}</div>
            </div>
            <div class="d-flex top-gap-30 justify-content-center">
              <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                {{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CANCEL' | translate }}
              </button>
              <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.continue()">
                {{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONNECT' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #connectAzureTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card">
          <div class="card-header py-3">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONNECT_AZURE_CLOUD_STORAGE' | translate }}</div>
          <div class="card-body">
            <div class="m-body pt-4 px-5">
              <form #azureForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.connect(azureForm)">
  
                <div class="form-group">
                  <label for="accountName">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_NAME' | translate }}<span class="required-field">*</span></label>
                  <input #accountName="ngModel" class="form-control" type="text" name="accountName" placeholder="{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_NAME' | translate }}" 
                      [ngClass]="{'is-invalid':accountName.errors && accountName.touched}" [(ngModel)]="dataModel.accountName" required>
                  <small class="invalid-feedback mx-2" [hidden]="!accountName.errors?.['required']">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_NAME_IS_REQUIRED' | translate }}</small>
                </div>
  
                <div class="form-group">
                  <label for="containerName">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONTAINER_NAME' | translate }}<span class="required-field">*</span></label>
                  <input #containerName="ngModel" class="form-control" type="text" name="containerName" placeholder="{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONTAINER_NAME' | translate }}" 
                      [ngClass]="{'is-invalid':containerName.errors && containerName.touched}" [(ngModel)]="dataModel.containerName" required>
                  <small class="invalid-feedback mx-2" [hidden]="!containerName.errors?.['required']">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONTAINER_NAME_IS_REQUIRED' | translate }}</small>
                </div>
  
                <div class="form-group">
                  <label for="accountKey">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_KEY' | translate }}<span class="required-field">*</span></label>
                  <input #accountKey="ngModel" class="form-control" type="text" name="accountKey" placeholder="{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_KEY' | translate }}" 
                      [ngClass]="{'is-invalid':accountKey.errors && accountKey.touched}" [(ngModel)]="dataModel.accountKey" required>
                  <small class="invalid-feedback mx-2" [hidden]="!accountKey.errors?.['required']">{{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_KEY_IS_REQUIRED' | translate }}</small>
                </div>
              </form>
            </div>
            <div class="d-flex top-gap-30 justify-content-center">
              <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                {{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CANCEL' | translate }}
              </button>
              <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.connect(azureForm)" [class.disabled]="!azureForm.valid">
                {{ 'MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONNECT' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #disconnectAzureTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card">
          <div class="card-header py-3">{{ 'MAIN.STORAGE.DISCONNECT_AZURE_MODAL.TITLE' | translate }}</div>
          <div class="card-body">
            <div class="m-body padded">
              <p class="text-secondary">{{ 'MAIN.STORAGE.DISCONNECT_AZURE_MODAL.MESSAGE' | translate }}</p>
              <p class="text-danger font-weight-bold mt-2">{{ 'MAIN.STORAGE.DISCONNECT_AZURE_MODAL.CAUTION' | translate:('design' | env) }}</p>
            </div>
            <div class="d-flex top-gap-30 justify-content-center">
              <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                {{ 'MAIN.STORAGE.DISCONNECT_AZURE_MODAL.CANCEL' | translate }}
              </button>
              <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.disconnect()">
                {{ 'MAIN.STORAGE.DISCONNECT_AZURE_MODAL.DISCONNECT' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>