<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{headerTitle ? headerTitle : ('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.CLOSE-WORKFLOW' | translate) }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            
            <div style="top: 27px;left: 31px;">
                    <img src="assets/img/warning.svg" alt="">
                </div>

            <div *ngIf="!errorMessage" [innerHTML]="textMessage" class="fs-14 top-gap-8 ps-4 pe-4 text-center"></div>
            <div *ngIf="importantTextMessage" [innerHTML]="importantTextMessage" class="fs-14 font-weight-700 top-gap-8 ps-4 pe-4 text-center"></div>
            <div *ngIf="regularTextMessage" [innerHTML]="regularTextMessage" class="fs-14 top-gap-8 ps-4 pe-4 text-center"></div>
            <div *ngIf="warningList && warningList.length">
                <ul *ngFor="let listItem of warningList">
                    <li>{{listItem}}</li>
                </ul>
            </div>
            <div class="d-flex top-gap-30 justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-{{errorMessage ? 'danger' : 'outline-secondary'}}"
                [ngClass]="{'vsight-danger' : errorMessage}" (click)="confirm(0)">
                    {{ cancelButtonText ? cancelButtonText : ('MODALS.ALERT.CANCEL' | translate) }}
                </button>
                <button *ngIf="!errorMessage && !oneButtonAvailable" type="button"
                    class="v-btn vsight-button btn btn-{{confirmButtonStyle ? confirmButtonStyle : 'danger'}}"
                    (click)="confirm(1)" [ngClass]="{'vsight-danger' : !confirmButtonStyle}">
                    {{confirmButtonText ? (confirmButtonText | titlecase) : ('MODALS.ALERT.CONFIRM' | translate)}}
                </button>
            </div>
        </div>
    </div>
</div>