import { Component, Input, OnInit } from '@angular/core';
import { StepData } from '@models/Step';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {

  @Input('stepData') stepData?: StepData
  @Input('stepListCount') stepListCount?
  @Input('workflowName') workflowName?
  @Input() template?
  @Input() workflowTranslations?
  @Input() selectedStepOrder?: number

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
