import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowVersion } from '@models/Workflow';
import { first, Subscription } from 'rxjs';
import { WorkflowReport } from 'src/app/models/WorkflowReport';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/support/loader.service';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-mobile-report',
  templateUrl: './mobile-report.component.html',
  styleUrls: ['./mobile-report.component.scss']
})
export class MobileReportComponent implements OnInit {ü

  loading: boolean = true;
  authSubscription: Subscription = null;

  reportData: WorkflowReport
  workflowPublishedVersion: WorkflowVersion

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private workflowService: WorkflowService
  ) {
   }

  ngOnInit() {
      // Navigate main if user already authenticated
      this.authSubscription = this.authService.userForGuard.subscribe(auth => {
        if (auth) {
          this.router.navigate(['/'])
        }
      })
  
    this.loaderService.show()
    this.authenticate()
      .then(reportId => this.workflowService.getWorkflowReport(reportId))
      .then(result => {
        this.reportData = result?.report;
        this.workflowPublishedVersion = result?.workflow
      })
      .catch((err) => console.log(err))
      .finally(() => this.loaderService.hide())
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise()
    if (!(params && params['token'] && params['id'])) {
      throw new Error('missing-parameters')
    }

    const token = atob(decodeURIComponent(params['token']))
    const reportId = params['id']

    return this.authService.loginToFirebase(token)
    .then(() => this.authService.userForGuard.pipe(first()).toPromise())
    .then(() => reportId)
  }
}
