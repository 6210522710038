import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-from-admin',
  templateUrl: './login-from-admin.component.html',
  styleUrls: ['./login-from-admin.component.scss']
})
export class LoginFromAdminComponent implements OnInit {

  loading = true

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authenticate()
  }

  async authenticate() {
    const params = await this.activatedRoute.queryParams.pipe(first()).toPromise()
    if (!(params && params['token'])) {
      this.loading = false
      this.router.navigate(['/'])
      return
    }
    const token = atob(decodeURIComponent(params['token']));
    this.authService.loginToFirebase(token)
    .then(() => this.router.navigate(['/']))
    .catch(error => { this.loading = false; })
  }

}
