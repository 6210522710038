import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, TemplateRef, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

// import * as OT from '@opentok/client';

// import { OpentokService } from '@services/core/opentok.service';
// import { ModalService } from '@services/support/modal.service';
// import { UtilityService } from '@services/support/utility.service';
import { LoaderService } from '@services/support/loader.service';

import { combineLatest, combineLatestWith, interval, Subscription, timer } from 'rxjs';

import { OwnRoom, Room } from '@models/Room';
import { User } from '@models/User';

import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// import countryCodesJson from '@assets/countryCodes.json';
// import { RoomSessionService } from '@services/core/room-session.service';
import { RoomService } from '@services/remote/room.service';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { CallService } from '@services/remote/call.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRoomService } from '@services/remote/user-room.service';
import { UtilityService } from '@services/remote/utility.service';

import { RoomWrapperComponent } from '../room-wrapper/room-wrapper.component';
import { DetailPageService } from '@services/detail-page.service';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit, OnDestroy {

  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild("smsInputTemplate", { static: true }) private smsInputTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;

  @ViewChild('videoElement') videoElement: ElementRef;

  @Input() isOverview

  @Output() onMeetingCreate: EventEmitter<void> = new EventEmitter<void>();

  meetings= [];

  roomsLoading: boolean = true;
  roomsSub: Subscription = null;

  upcomingMeetings= [];
  previousMeetings= [];
  archivedMeetings= [];

  visiblePreviousMeetings= [];
  showMorePrevious: boolean = false;

  previousDefaultVisible: number = 1;

  @Output() joinRoom = new EventEmitter<OwnRoom>();

  colors: string[] = ['bg-primary', 'bg-secondary', 'bg-info', 'bg-warning', 'bg-danger'];

  timeSlotDuration = 1000*60*30; // 30minutes

  today = new Date();
  currentTimestamp = (new Date()).getTime();
  timerSub: Subscription = null;

  websiteOrigin: string = environment.design.useBaseUrlInsteadOfWindowOrigin ? environment.endPoints.remoteBase : window.location.origin;

  isUserExpert: boolean = false;
  userDataSub: Subscription = null;
  scheduleMeetingAvailable: boolean = false;
  featuresSub: Subscription = null;

  showRoomUsers: any = {};
  currentUser: User = null;

  joinSMS: boolean = true;
  joinSMSForm: boolean = false;
  joinWhatsapp: boolean = true;
  joinMail: boolean = true;

  joinLinkId: string = null;
  joinLink: string = null;
  joinLinkAccountAllowed: boolean = false;
  joinLinkUserAllowed: boolean = false;
  joinLinkGenerating: boolean = false;

  joinSMSAccountAllowed: boolean = true;
  joinSMSUserAllowed: boolean = true;

  sendButtonClicked: boolean = false;
  resendPermissionEnabled: boolean = false;
  timerSubs: Subscription = null;

  sendEnabled: boolean = true;
  smsAccountEnabled: boolean = true;
  mailToUrl: string;
  whatsappToUrl: string;
  qrcode: string = null;

  // Login form binded data
  mobilePhone = {
    selectedCountry: null,
    number: null
  }

  numOfPhonesNumbersToStore = 3;
  savedPhoneNums = []

  languages: { code: string, name: string }[] = [];
  countryCodes: { number: string, flag: string }[] = [];

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private userRoomService: UserRoomService,
    // private opentokService: OpentokService,
    // private utilityService: UtilityService,
    // private router: Router,
    // private modalService: ModalService,
    private loaderService: LoaderService,
    private detailPageService: DetailPageService,
    private multilanguageService: MultilanguageService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    // private roomSessionService: RoomSessionService
  ) { }

  ngOnInit() {
    this.loaderService.show()
    this.languages = this.multilanguageService.allLangs;
    // this.countryCodes = countryCodesJson.countrycodes.sort((a,b) => a.number > b.number ? 1 : -1);

    this.roomsSub = combineLatest([this.userRoomService.allOwnRooms, this.userRoomService.getArchivedMeetings()])
    .subscribe(([rooms, meetings]) => {
      this.sortMeetings(rooms ? rooms.filter(a => a.room_data.meeting_room).concat(meetings) : meetings)
    })
    this.authService.getAccountData().then(account => {
      this.scheduleMeetingAvailable = account.data.accountData.features.schedulemeeting
    })
    this.authService.user.subscribe(userData => {
      this.isUserExpert = userData && userData.role === 'expert';
    });

    // this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
    //   this.cameraMirror = mirror;
    // });

    this.timerSub = interval(5000).subscribe(() => {
      this.currentTimestamp = (new Date()).getTime();
      this.sortMeetings(this.meetings);
    });
    this.currentUser = this.authService.currentUser;
    
    const savedPhones = localStorage.getItem("phoneNums");
    if (savedPhones) { this.savedPhoneNums = JSON.parse(savedPhones) }
  }

   cameraMirrorChanged(mirror: boolean) {
  //   this.opentokService.changeCameraMirror(mirror);
   }

  formatDurationHHMM(ms: number): string {
    const minutes: any = Math.floor((ms / 60000) % 60);
    const hours: any   = Math.floor(ms / 3600000);

    return `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0'+minutes : minutes}`;
  }

  sortMeetings(rooms: any) {
    this.meetings = rooms
    this.loaderService.hide()
    

    this.upcomingMeetings = this.meetings.filter(a => a.room_data.end >= this.currentTimestamp).sort((a, b) => a.room_data.start - b.room_data.start);
    this.previousMeetings = this.meetings.filter(a => a.room_data.end < this.currentTimestamp).sort((a, b) => b.room_data.start - a.room_data.start);
    this.roomsLoading = false;

    this.checkShowMorePrevious();
  }

  checkShowMorePrevious() {
    // if (this.showMorePrevious) {
    //   this.visiblePreviousMeetings = this.previousMeetings;
    // } else {
    //   this.visiblePreviousMeetings = this.previousMeetings.length > this.previousDefaultVisible ? this.previousMeetings.slice(0, this.previousDefaultVisible) : this.previousMeetings;
    // }
  }

  /*personalRoomSort(a, b) {
    return a.room_data.personal_room ? (b.room_data.personal_room ? this.meetingRoomSort(a, b) : -1) : (b.room_data.personal_room ? 1 : this.meetingRoomSort(a, b));
  }

  meetingRoomSort(a, b) {
    return a.room_data.meeting_room ? (b.room_data.meeting_room ? this.alphabeticalSort(a, b) : -1) : (b.room_data.meeting_room ? 1 : this.alphabeticalSort(a, b));
  }

  alphabeticalSort(a, b) {
    return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
  }*/

  getMeetingDuration(start: number, end: number) {
    const i3 = Math.floor((end - start) / 60000);
    const h3 = Math.floor(i3/60);
    const m3 = i3 % 60;

    return `${h3}h ${m3}m`;
  }

  joinLinkCopied(event) {
    this.snackBarService.success(this.translateService.instant('MAIN.ROOM.SIDEBAR.SETTINGS.LINK_COPIED'))
  }

  scheduleMeeting() {
    this.callService.scheduleMeetingRequest.next();
  }

  cancelMeeting(room: Room, previous: boolean) {
    this.callService.cancelMeetingRequest.next([room, previous]);
  }

  createMeetingClicked() {
    this.onMeetingCreate.emit()
  }

  ngOnDestroy() {
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    if (this.userDataSub) { this.userDataSub.unsubscribe() }
    if (this.timerSub) { this.timerSub.unsubscribe() }
    if (this.timerSubs) { this.timerSubs.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
  }

  onJoin(room: Room) {
    this.loaderService.show()
    this.authService.getJoinLinkData(room.room_data.link_id)
      .then(() => this.openRoomInterface(room.id))
  }

  openRoomInterface(roomId: string) {
    const [instance, onClose] = this.detailPageService.loadComponent(RoomWrapperComponent, {})
    instance.roomId = roomId
    instance.users = null
    instance.linkId = null
    instance.guestName = null
    onClose.then(() => {
      console.log(closed)
    })
  }

  //onJoin(room: Room) {
  //   const parser = this.utilityService.getBrowserParser();
  //   const isTouchDevice = 'ontouchstart' in window;
  //   // Is Device iOS or ipadOS (detected as touch macOS)
  //   const isIOS = parser.is("iOS");
  //   const isIpad = parser.is("macOS") && isTouchDevice;

  //   if (isIOS || isIpad) {
  //     if (!parser.is("Safari")) {
  //       this.showNotSupportedModal({ ios: true });
  //       return;
  //     } else if (!parser.satisfies({safari: ">=12"})) {
  //       this.showNotSupportedModal({ ios: true, ios_version_low: true });
  //       return;
  //     }
  //   }
  //   if (parser.is("Microsoft Edge") && !parser.satisfies({edge: '>=79' })) {
  //     this.showNotSupportedModal({ ios: false });
  //     return;
  //   }

  //   if (!(environment.design.supportedBrowserList.some(browserName => parser.is(browserName)) && this.utilityService.checkOpentokRequirements())) {
  //     this.showNotSupportedModal({ ios: false });
  //     return;
  //   }

  //   this.loaderService.show();
  //   this.authService.getJoinLinkData(room.room_data.link_id)
  //     .finally(() => {
  //       this.loaderService.hide();
  //       this.showJoinModal(room);
  //     });
  // }

  // showNotSupportedModal(dataModel: any) {
  //   const modalId = this.modalService.show({
  //     template: this.notSupportedTemplate,
  //     context: {
  //       dataModel: dataModel,
  //       callbacks: {
  //         close: () => this.modalService.hide(modalId)
  //       }
  //     }
  //   });
  // }

  // showJoinModal(room: Room) {
  //   // @ts-ignore
  //   const AContext = window.AudioContext || window.webkitAudioContext;
  //   const model = {
  //     publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
  //     currentStream: null,
  //     audios: [],
  //     videos: [],
  //     selectedVideo: null,
  //     selectedAudio: null,
  //     audioEnabled: true,
  //     videoEnabled: false,
  //           //@ts-ignore
  //     audioContext: new AContext(),
  //     audioLevel: 0,
  //     audioLevelSub: null,
  //     settingsOn: false,
  //     status: "waiting",
  //     enableJoin: false,
  //     enableCancel: false
  //   };
  //   const modalId = this.modalService.show({
  //     template: this.joinTemplate,
  //     context: {
  //       dataModel: model,
  //       callbacks: {
  //         toggleAudio: () => this.opentokService.togglePreviewAudio(model),
  //         toggleVideo: () => this.opentokService.togglePreviewVideo(model),
  //         changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
  //         changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
  //         close: () => {
  //           this.opentokService.destroyPreview(model, this.videoElement);
  //           this.modalService.hide(modalId);
  //         },
  //         join: () => {
  //           this.opentokService.destroyPreview(model, this.videoElement);
  //           if (model.status === 'no-devices-found') {
  //             this.showNoDeviceModal(modalId, room, model);
  //           } else {
  //             this.modalService.hide(modalId);
  //             this.join(room, model, false);
  //           }
  //         }
  //       }
  //     }
  //   });

  //   setTimeout(() => {
  //     this.opentokService.startPreview(model, this.videoElement);
  //   }, 1000);
  // }

  // showNoDeviceModal(joinModalId: number, room: Room, model: any) {
  //   const modalId = this.modalService.show({
  //     template: this.noDeviceTemplate,
  //     context: {
  //       dataModel: null,
  //       callbacks: {
  //         cancel: () => {
  //           this.modalService.hide(modalId);
  //         },
  //         join: () => {
  //           this.modalService.hide(modalId);
  //           this.modalService.hide(joinModalId);
  //           this.join(room, model, false);
  //         }
  //       }
  //     }
  //   });
  // }

  // join(room: Room, dataModel: any, archiveAllowed: boolean) {
  //   this.loaderService.show();
  //   this.callService.joinRoom(room.id, archiveAllowed)
  //   .then(() => {
  //     this.loaderService.hide();
  //     this.router.navigate(['/room']);
  //   })
  //   .catch(error => {
  //     this.loaderService.hide();
  //     if (error.message === 'concurrent-limit-reached') {
  //       this.snackBarService.error(this.translateService.instant('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR'));
  //     } else if (error.message === 'expert-concurrent-limit-reached') {
  //       this.snackBarService.error(this.translateService.instant('APP.MAIN.LOBBY.JOIN_EXPERT_CONCURRENT_ERROR'));
  //     } else if (error.message === 'no-training-license') {
  //       this.snackBarService.error(this.translateService.instant('APP.MAIN.LOBBY.NO_TRAINING_LICENSE'));
  //     } else if (error.message === 'training-license-expired') {
  //       this.snackBarService.error(this.translateService.instant('APP.MAIN.LOBBY.TRAINING_LICENSE_EXPIRED'));
  //     } else if (error.message === 'training-license-error') {
  //       this.snackBarService.error(this.translateService.instant('APP.MAIN.LOBBY.ASK_TRAINING_HOST'));
  //     } else if (error.message === 'archive-permission-needed') {
  //       this.showArchivePermissionModal(room, dataModel);
  //     } else {
  //       this.snackBarService.error(this.translateService.instant('APP.MAIN.LOBBY.JOIN_UNKNOwN_ERROR'));
  //     }
  //   });
  // }

  // showArchivePermissionModal(room: Room, dataModel: any) {
  //   const modalId = this.modalService.show({
  //     template: this.archivePermissionModal,
  //     context: {
  //       dataModel: null,
  //       callbacks: {
  //         deny: () => this.modalService.hide(modalId),
  //         allow: () => {
  //           this.join(room, dataModel, true);
  //           this.modalService.hide(modalId);
  //         }
  //       }
  //     }
  //   });
  //}

  generateMailToUrl(link: any) {
    const name = environment.design.addRemoteToName ? (environment.design.appName+" Remote") : environment.design.appName;
    const title = `${name} Session Invitation`;
    var body = `You are invited to ${name} Session: ${link}`;
    this.mailToUrl = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`;
  }

  generateWhatsappToUrl(link: any) {
    const name = environment.design.addRemoteToName ? (environment.design.appName+" Remote") : environment.design.appName;
    var body = `You are invited to ${name} Session: ${link}`;
    this.whatsappToUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(body)}`;
  }

  onSMSGenerating(link: any, linkId: any) {
    this.joinLinkId = linkId;
    this.joinLink = link;
    if (!this.smsAccountEnabled) {
      this.snackBarService.error(this.translateService.instant('MAIN.ROOM.SIDEBAR.SETTINGS.SMS_LICENSE_PERMISSION'))
      return;
    }

    this.joinSMS = true;
    this.joinSMSForm = true;
    this.joinWhatsapp = false;
    this.joinMail = false;

    // const modalId = this.modalService.show({
    //   template: this.smsInputTemplate,
    //   context: {
    //     dataModel: null,
    //     callbacks: {
    //       close: () => this.modalService.hide(modalId)
    //     }
    //   }
    // });
  }

  onSMSRegenerating() {
    if (!this.smsAccountEnabled) {
      this.snackBarService.error(this.translateService.instant('MAIN.ROOM.SIDEBAR.SETTINGS.SMS_LICENSE_PERMISSION'))
      return;
    }

    if (!this.resendPermissionEnabled) {
      this.snackBarService.error(this.translateService.instant('MAIN.ROOM.SIDEBAR.SETTINGS.RESHARE'))
      return;
    }

    this.joinSMS = true;
    this.joinSMSForm = true;
    this.joinWhatsapp = false;
    this.joinMail = false;
    
    this.onSendSMS();
  }

  onWhatsappGenerating(link: any) {
    this.joinSMS = false;
    this.joinSMSForm = false;
    this.joinWhatsapp = true;
    this.joinMail = false;

    this.generateWhatsappToUrl(link);
  }

  onMailGenerating(link: any) {
    this.joinSMS = false;
    this.joinSMSForm = false;
    this.joinWhatsapp = false;
    this.joinMail = true; 

    this.generateMailToUrl(link);
  }

  sendJoinRoomLinkSMS(phoneNumber: string, linkId: string, smsContent: string) {
    // const url = this.dbService.getEndPoint("joinwithlink");
    // return this.http.post<any>(url, { action: 'sms', token: this.authService.currentUser.token, phone_number: phoneNumber, link_id: linkId, sms_content: smsContent }).toPromise()
  }

  onSendSMS(val?) {
    // if (!this.smsAccountEnabled) {
    //   return;
    // }

    // const name = environment.design.addRemoteToName ? (environment.design.appName+" Remote") : environment.design.appName;
    // var smsContent = `You are invited to ${name} Session: ${this.joinLink}.`;

    // this.loaderService.show();
    // this.roomSessionService.sendJoinRoomLinkSMS(this.mobilePhone.selectedCountry, this.mobilePhone.number, this.joinLinkId, smsContent)
    //   .then(() => {
    //     this.updateSavedPhoneNumbers();
    //     this.mobilePhone.number = "";
    //     this.snackBarService.success(this.translateService.instant('APP.MAIN.ROOM.SIDEBAR.SETTINGS.SMS_SEND'));
    //     this.sendButtonClicked = true;
    //     this.resendPermissionEnabled = false;
    //     this.timerSubs = timer(60000).subscribe(() => {
    //       this.resendPermissionEnabled = true;
    //     })
    //     this.sendEnabled = false;
    //   })
    //   .catch(error => {
    //     if (error instanceof HttpErrorResponse) {
    //       if (error.error === 'sms-limit-reached') {
    //         this.snackBarService.error(this.translateService.instant('APP.MAIN.ROOM.SIDEBAR.SETTINGS.SMS_LIMIT_REACHED'));
    //       } else {
    //         this.snackBarService.error(this.translateService.instant('APP.MAIN.ROOM.SIDEBAR.SETTINGS.ERROR_OCCURED'));
    //       }
    //     } else {
    //       this.snackBarService.error(this.translateService.instant('APP.MAIN.ROOM.SIDEBAR.SETTINGS.ERROR_OCCURED'));
    //     }
    //   })
    //   .finally(() => {
    //     this.loaderService.hide();
    //   });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  onMobilePhoneChange() {
    if (this.mobilePhone.selectedCountry && this.mobilePhone.number) {
      if (this.mobilePhone.number.toString().length >= 4 && this.mobilePhone.number.toString().length <= 20) {
        this.sendEnabled = true;
      } else {
        this.sendEnabled = false;
      }
    } else {
      this.sendEnabled = false;
    }
  }

  onPhoneNumClick(phoneNum: any) {
    this.mobilePhone.selectedCountry = phoneNum.selectedCountry;
    this.mobilePhone.number = phoneNum.number;
    this.sendEnabled = true;
  }

  isPhoneNumsSame(num1: any, num2: any){
    if (num1.selectedCountry === num2.selectedCountry && num1.number === num2.number) { return true }
    return false;
  }

  updateSavedPhoneNumbers() {
    let currentPhoneNums = [];
    const currentPhones = localStorage.getItem("phoneNums");
    if (currentPhones){ currentPhoneNums = JSON.parse(currentPhones) }

    for (let i=0; i < currentPhoneNums.length; i++) {
      if (this.isPhoneNumsSame(this.mobilePhone, currentPhoneNums[i])){
        currentPhoneNums.splice(i,1);
        break;
      }
    }
    currentPhoneNums.unshift(Object.assign({}, this.mobilePhone));
    if (currentPhoneNums.length > this.numOfPhonesNumbersToStore) { currentPhoneNums.pop() }

    localStorage.setItem("phoneNums", JSON.stringify(currentPhoneNums));
    this.savedPhoneNums = currentPhoneNums;
  }
}
