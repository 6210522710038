import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@models/User';
import { WorkflowVersion } from '@models/Workflow';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowService } from '@services/workflow.service';
import { first } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-signature-report',
  templateUrl: './signature-report.component.html',
  styleUrls: ['./signature-report.component.scss']
})
export class SignatureReportComponent implements OnInit {

  reportData
  workflowPublishedVersion: WorkflowVersion

  users : User[] = []

  signatureReportData = {
    type: '',
    signer: '',
    report: {},
    workflowPublishedVersion: {},
    userList: [],
    accountName: ''
  }
  linkId

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private workflowService: WorkflowService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).toPromise().then(res => {
      this.workflowService.getWorkflowReportLinkData(res['id']).then(response => {
        this.signatureReportData.type = response.data.type
        this.signatureReportData.signer = response.data.signer
        this.signatureReportData.report = response.data.report
        this.signatureReportData.workflowPublishedVersion  = response.data.workflowVersion
        this.signatureReportData.userList = response.data.userList
        this.signatureReportData.accountName = response.data.accountName
        this.workflowPublishedVersion = response.data.workflowVersion
        this.linkId = res['id']
      })
      .catch(error => {
        if(error.error == 'missing-parameters') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.MISSING-PARAMETERS'))
        }
        if(error.error == 'report-not-found') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.NO-REPORT.NO-REPORT-FOUND')) 
        }
        if(error.error == 'internal-error') {
          this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
        }
      })
    })
  }

}
