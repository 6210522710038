<div class="session-dashboard" [ngStyle]="{'display': sessionDetailOpen ? 'none' : 'block'}">
    <div>
        <table mat-table [dataSource]="dataSource" matSort  matTableResponsive>
        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell data-column-name="#" *matCellDef="let i = index">
            
            </td>
        </ng-container>
        <ng-container matColumnDef="room.name">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by name"
                class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-3"
                style="padding-left: 20px;"
            >
            {{ 'MAIN.LOBBY.SESSIONS.ROOM_NAME' | translate }}
            </th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.ROOM_NAME' | translate" *matCellDef="let element"
            class="d-flex align-items-start fs-15 font-weight-700 justify-content-center flex-column col-3 cursor-pointer" style="padding-left: 20px;">{{ element.room.name }}</td>
        </ng-container>

        <ng-container matColumnDef="create_time">
            <th class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-2" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'MAIN.LOBBY.SESSIONS.DATE' | translate }} ({{ timezone }})
            </th>
            <td
            mat-cell
            data-column-name="Created On"
            *matCellDef="let element"
            class="col-2 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-light-black"
            >
            {{ element.create_time | localizedDateShort | async }}
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by duration"
                class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-2"
            >
            {{ 'MAIN.LOBBY.SESSIONS.DURATION' | translate }}
            </th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.DURATION' | translate" *matCellDef="let element"
            class="col-2 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-light-black">
                {{ element | sessionDuration }}
            </td>
            </ng-container>
        
        <ng-container matColumnDef="participants">
            <th mat-header-cell *matHeaderCellDef class="centered table-fs-14 fs-14 text-dark-grey font-weight-400 col-3">{{ 'MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate }}</th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate" *matCellDef="let element" class="col-3 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-700 text-dark-grey">
                <div *ngFor="let user of element.participants | slice: 0 : 3" class="participant">
                    {{ user['name'] }}
                    <span class="text-grey ms-1" *ngIf="user['isGuest']">({{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }})</span>
                </div>
                <span *ngIf="element.participants.length > 3" class="core-blue fs-14 font-weight-600 more" (click)="$event.stopPropagation(); showAllUsers(element.participants)">+ {{ element.participants.length - 3}} more</span>
                <!-- old code for participants -->
            <!-- <div *ngFor="let user of element.users" class="participant">
                {{ user.name }}
            </div>
            <div *ngFor="let guestUser of element.guest_users" class="participant">
                {{ guestUser.name }} <span class="text-grey ms-1">({{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }})</span>
            </div> -->
            </td>
        </ng-container>
        
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="centered table-fs-14 fs-14 text-dark-grey font-weight-400 col-2">{{ 'MAIN.LOBBY.SESSIONS.STATUS' | translate }}</th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.STATUS' | translate" *matCellDef="let element"
            class="col-2 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-dark-grey">
            <span [ngStyle]="{'color': !element.end_time ? 'green' : ''}">{{ (element.end_time ? 'MAIN.LOBBY.SESSIONS.ENDED' : 'MAIN.LOBBY.SESSIONS.ON_GOING') | translate }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="export" *ngIf="isUserExpert && sessionExportEnabled && !webdavEnabled">
            <th mat-header-cell *matHeaderCellDef>
            {{ 'MAIN.LOBBY.SESSIONS.EXPORT' | translate }}
            </th>
            <td
            mat-cell
            *matCellDef="let element"
            >
            <img *ngIf="!element.session_files_deleted" src="assets/export_session.svg" alt="" />
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <div *ngIf="!isLoading && isUserExpert">
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSessionDetails(row)"></tr>
        </div>
        <div *ngIf="!isLoading && !isUserExpert">
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSessionDetails(row)"></tr>
        </div>
        </table>
        <div class="bg-white empty-list" *ngIf="dataSource && (!dataSource.filteredData || dataSource.filteredData.length === 0) && !isLoading">
        <div class="mt-4 fs-14 text-grey"> {{ "MAIN.LOBBY.SESSIONS.NO_SESSIONS" | translate }}</div>
    </div>
        <mat-paginator
        [pageSizeOptions]="[10, 5, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>
  