import { HttpClient } from '@angular/common/http';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-media-instructions-widget',
  templateUrl: './media-instructions-widget.component.html',
  styleUrls: ['./media-instructions-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaInstructionsWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MediaInstructionsWidgetComponent),
      multi: true,
    },
  ],
})
export class MediaInstructionsWidgetComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() item //type tanımla
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef<HTMLVideoElement>;

  subscriptions : Subscription[] = []

  mediaControl
  pdfControl = new UntypedFormControl()
  pdfPage = new UntypedFormControl(1)
  totalPages
  isUpload
  url
  isVideoFlow = false

  videoStart
  videoEnd

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.item = changes['item'].currentValue
    if(this.item.templateOptions.videoUrl) {
      if(this.item.templateOptions.startTime) {
        const totalSecondsStart = this.mapTimeStamp(this.item.templateOptions.startTime)
        const totalSecondsEnd = this.mapTimeStamp(this.item.templateOptions.endTime)
        this.videoStart = totalSecondsStart
        this.videoEnd = totalSecondsEnd
        this.isVideoFlow = true

        this.isVideoFlow = true

        this.url = this.item.templateOptions.videoUrl + `#t=${totalSecondsStart},${totalSecondsEnd + 1}`
      }
    }
  }
  onChange = (value) => {
  };

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return null
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngAfterViewInit(): void {
    if(this.isVideoFlow) {
      const video = this.videoPlayer.nativeElement;
      video.addEventListener('play', this.onPlay.bind(this));
    }
  }

  ngOnInit(): void {

    if(this.item.templateOptions.videoUrl) {
      if(this.item.templateOptions.startTime) {
        const totalSecondsStart = this.mapTimeStamp(this.item.templateOptions.startTime)
        const totalSecondsEnd = this.mapTimeStamp(this.item.templateOptions.endTime) + 1
        this.videoStart = totalSecondsStart
        this.videoEnd = totalSecondsEnd
        this.isVideoFlow = true
        this.url = this.item.templateOptions.videoUrl + `#t=${totalSecondsStart},${totalSecondsEnd}`
      }
    }
   }

   onPlay(): void {
    const video = this.videoPlayer.nativeElement;
    const secondsEnd = this.mapTimeStamp(this.item.templateOptions.endTime) + 1

    video.addEventListener('timeupdate', () => {
      if (video.currentTime >= this.videoEnd + 1) {
        video.pause();
        // video.currentTime = totalSecondsStart; // Videoyu başa döndür
        // video.play(); // Oynat
      }
    });

    if(video.currentTime >= secondsEnd) {

      video.pause();

      this.url = null
      this.url = this.item.templateOptions.videoUrl + `#t=${this.videoStart},${this.videoEnd + 1}`
      video.currentTime = this.videoStart
      this.cdr.detectChanges();
      video.play();
      
    }else{
      this.url = null
      this.url = this.item.templateOptions.videoUrl + `#t=${this.videoStart},${this.videoEnd + 1}`
      video.currentTime = this.videoStart;
    }
  }



   mapTimeStamp(time) {
    const timeString = time;
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds
   }

  pageChanged(event) {
    //this.pdfPage = event
  }
  pdfNext() {
    this.pdfPage.patchValue(this.pdfPage.value +  1) 
  }
  pdfPrevious() {
    if(this.pdfPage.value !==1) {
    this.pdfPage.patchValue(this.pdfPage.value -  1) 
    }
  }

  pageSearch() {
    this.pdfPage.patchValue(this.pdfPage.value)
  }

  afterLoadComplete(pdf): void {
    this.totalPages = pdf.numPages;
  }

  downloadPdfItem(pdfUrl: string): Observable<Blob> {
    return this.http.get(pdfUrl, { responseType: 'blob' });
  }

  downloadPdf(item) {
    if(item.pdfUrl) {
      this.downloadPdfItem(item.pdfUrl).subscribe((data: Blob) => {
        const blob = new Blob([data], { type: 'application/pdf' });
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
  
        link.download = item.pdfName ? item.pdfName : 'file.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
    }
    
  }

}