<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.MOVE-TO-FOLDER' | translate | titlecase }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            
            <div class="folder-wrapper w-100">
                <div class="d-flex flex-column mt-3" style="width:100% !important">
                    <label class="fs-14 font-weight-500 mb-2 w-100 text-dark-grey">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.MOVE-TO' | translate }}:</label>
                    <app-dropdown title="{{ selectedFolder ? selectedFolder : 'Select Folder' }}">
                        <div class="search-box">
                            <input type="text" #searchInput class="form-control filter fs-14" [formControl]="filterFolder" (click)="stop($event)" placeholder="Search" inputSelectOnFocus>
                            <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                        </div>
                        <div class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer" (click)="selectFolder('', true)" *ngIf="showDefault">
                            <label class="fs-14 font-weight-400 cursor-pointer">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.NOT-FOLDERED' | translate }}</label>
                        </div>
                        <div  class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer" *ngFor="let folder of folders" (click)="selectFolder(folder)">
                            <label class="fs-14 font-weight-400 cursor-pointer">{{folder.name}}</label>
                        </div>
                    </app-dropdown> 
                </div>

                <div class="d-flex top-gap-30 justify-content-center">
                    <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="close()">
                 {{ 'MODALS.ALERT.CANCEL' | translate | titlecase }}
                </button>
                <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="moveToFolder()">
                    {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.MOVE' | translate | titlecase }}
                </button>
                </div>
            </div>

        </div>
    </div>
</div>