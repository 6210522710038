<div class="room-wrapper">
    <div class="room-detail-container">
      <div class="v-card">
        <div class="card list-header flex-row justify-content-between align-items-center pe-4 ps-2">
          <div *ngIf="action === ACTION_NEW" class="header font-weight-700 fs-20 pt-1"><img class="back-button ps-1 pe-3 py-2 cursor-pointer" (click)="onBack()" src="assets/img/back-blue.svg" alt="">Create Work Group</div>
          <div  class="header font-weight-700 fs-20 pt-1" *ngIf="action === ACTION_EDIT">
              <img class="back-button ps-1 pe-3 py-2 cursor-pointer" (click)="onBack()" src="assets/img/back-blue.svg" alt="">
              Edit Work Group Details
          </div>
          <div>
              <button *ngIf="action === ACTION_EDIT" class="v-btn btn btn-primary vsight-button vsight-secondary-btn" type="button" (click)="onToggle()">
                {{room.room_data.disabled ? 'Enable Work Group' : 'Disable Work Group'}}
              </button>
              <button *ngIf="action === ACTION_EDIT"  class="v-btn btn btn-primary vsight-button ms-3" type="button" (click)="onDelete()">
                  Delete Work Group
              </button>
              <!-- <button [disabled]="!roomForm.valid || formPristine || formLoading"> -->
              <button *ngIf="action === ACTION_EDIT"  class="v-btn btn btn-primary vsight-button ms-3" type="submit" (click)="onSubmit(roomForm)">
                  {{ 'MAIN.CONTENT.USER-DETAIL.SAVE_CHANGES' | translate | titlecase }}
              </button>
              <!-- <button [disabled]="!roomForm.valid || formPristine || formLoading"> -->
              <button *ngIf="action === ACTION_NEW" type="submit" (click)="onSubmit(roomForm)"  class="v-btn btn btn-primary vsight-button ms-3">
                Create Work Group
            </button>
           
          </div>
          <!-- <button *ngIf="detailMode === 'create'" class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" type="submit" [disabled]="!userDetailForm.valid" > {{ 'MAIN.CONTENT.USER-LIST.CREATE' | translate | titlecase }}</button> -->
      </div>

        <!-- <div class="card-header d-flex justify-content-between align-items-center">
          <span class="card-header-title">{{ actionTitle }}</span>
          <span *ngIf="room.room_data.disabled" class="card-header-title text-danger fs-13">{{ 'MAIN.ROOMS.ROOM_DETAILS.ROOM_DISABLED' | translate }}</span>
          <button *ngIf="action === ACTION_DETAILS && !formLoading" class="btn btn-info" (click)="onEdit()" [disabled]="room.room_data.disabled">
            {{ 'MAIN.ROOMS.ROOM_DETAILS.EDIT' | translate }}
          </button>
          <button *ngIf="action === ACTION_NEW && !formLoading" class="btn btn-danger"
                (click)="resetForm()" [disabled]="formPristine || roomForm.control.disabled">
            {{ 'MAIN.ROOMS.ROOM_DETAILS.RESET' | translate }}
          </button>
          <ng-container *ngIf="action === ACTION_EDIT && !formLoading">
              <button *ngIf="!formPristine" class="btn btn-danger" (click)="resetForm()" [disabled]="roomForm.control.disabled">
                {{ 'MAIN.ROOMS.ROOM_DETAILS.REVERT' | translate }}
              </button>
            <button *ngIf="formPristine" class="btn btn-secondary" (click)="cancelEdit()">
              {{ 'MAIN.ROOMS.ROOM_DETAILS.CANCEL' | translate }}
            </button>
          </ng-container>
        </div> -->
        <div class="card-body px-lg-5 mb-5">
  
          <form #roomForm="ngForm" (ngSubmit)="onSubmit(roomForm)">
            <div class="form-row mt-4">

              <div class="form-group col-8">
                <label class="fs-14 text-dark-grey mb-2" for="name">{{ 'MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME' | translate }}</label>
                <input #name="ngModel" class="form-control" type="text" name="name" placeholder="{{ 'MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME' | translate }}" 
                    [ngClass]="{'is-invalid':name.errors && name.touched}"
                    [(ngModel)]="room.room_data.name" minlength="1" maxlength="60" required>
                <small class="invalid-feedback mx-2" [hidden]="!name.errors?.['required']">{{ 'MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME_REQUIRED' | translate }}</small>
                <small class="invalid-feedback mx-2" [hidden]="!name.errors?.['minlength']">{{ 'MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME_LENGTH' | translate }}</small>
              </div>
  
              <div class="form-group mt-4">
                <label class="fs-14 text-dark-grey mb-2" for="iconName">{{ 'MAIN.ROOMS.ROOM_DETAILS.ICON' | translate }}</label>
  
                <div class="icon-view d-flex align-items-center">
                  <img class="icon-img me-3" [src]="room.room_data.icon">
                  <div class="file-badge fs-14 me-3 ms-1" *ngIf="fileName">
                    <label>{{fileName}}</label>
                  </div>
                  <a class="core-blue fs-14 font-weight-600 link ms-1" #iconName (click)="iconFile.click()">Upload Image</a>
  
                  <!-- <input class="icon-name form-control" #iconName type="text" name="iconName" placeholder="{{ 'MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="iconFile.click()"> -->
                  <input class="icon-file d-none" #iconFile type="file" name="iconFile" placeholder="{{ 'MAIN.ROOMS.ROOM_DETAILS.ICON' | translate }}" accept="image/png,image/jpeg" (change)="onIconChange($event)" hidden>
                  <input class="icon-content d-none" [(ngModel)]="room.room_data.icon" #icon="ngModel" type="text" name="icon" hidden>
                  <input class="d-none" [(ngModel)]="room.room_data.icon_default" type="checkbox" #icon_default="ngModel" name="icon_default" hidden>
                </div>
              </div>
  
              
            </div>
  
            <div class="form-group mt-4 col-8">
              <label class="fs-14 text-dark-grey mb-2" for="description">{{ 'MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION' | translate }}</label>
              <input #description="ngModel" class="form-control" type="text" name="description" placeholder="{{ 'MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION' | translate }}" 
                    [ngClass]="{'is-invalid':description.errors && description.touched}"
                    [(ngModel)]="room.room_data.description" minlength="1" maxlength="100" required>
              <small class="invalid-feedback mx-2" [hidden]="!description.errors?.['required']">{{ 'MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION_REQUIRED' | translate }}</small>
              <small class="invalid-feedback mx-2" [hidden]="!description.errors?.['minlength']">{{ 'MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION_LENGTH' | translate }}</small>
            </div>
  
            <div class="form-row mt-4">
              <div class="form-group col-12">
                <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                  <input #auto_archive="ngModel" type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="auto_archive" name="auto_archive" [(ngModel)]="room.room_data.auto_archive"
                  [disabled]="!accountArchiveAllowed || accountAlwaysArchive || (peerToPeerAllowed && room.room_data.peer_to_peer)">
                  <label class="custom-control-label fs-14 text-dark-grey " for="auto_archive">{{ 'MAIN.ROOMS.ROOM_DETAILS.AUTO_ARCHIVE' | translate }}</label>
                </div>
                <ng-container *ngIf="accountArchiveAllowed">
                  <label *ngIf="!(peerToPeerAllowed && room.room_data.peer_to_peer) && accountAlwaysArchive" class="text-danger fs-13" for="auto_archive">{{ 'MAIN.ROOMS.ROOM_DETAILS.ALWAYS_ARCHIVE_ON' | translate }}</label>
                  <label *ngIf="(peerToPeerAllowed && room.room_data.peer_to_peer)" class="text-danger fs-13" for="auto_archive">{{ 'MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER_NO_ARCHIVE' | translate }}</label>
                </ng-container>
                <label *ngIf="!accountArchiveAllowed" class="text-danger fs-13" for="auto_archive">{{ 'MAIN.ROOMS.ROOM_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label>
              </div>
  
              <div class="form-group col-12 mt-3" *ngIf="('design' | env).showArchivePermission">
                <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                  <input #archive_perm_needed="ngModel" type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="archive_perm_needed" name="archive_perm_needed" [(ngModel)]="room.room_data.archive_perm_needed"
                  [disabled]="!accountArchiveAllowed || (peerToPeerAllowed && room.room_data.peer_to_peer)">
                  <label class="custom-control-label fs-14 text-dark-grey " for="archive_perm_needed">{{ 'MAIN.ROOMS.ROOM_DETAILS.ARCHIVE_PERMISSION_NEEDED' | translate }}</label>
                </div>
                <label *ngIf="peerToPeerAllowed && room.room_data.peer_to_peer" class="text-danger fs-13" for="archive_perm_needed">{{ 'MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER_NO_ARCHIVE' | translate }}</label>
                <label *ngIf="!accountArchiveAllowed" class="text-danger fs-13" for="archive_perm_needed">{{ 'MAIN.ROOMS.ROOM_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label>
              </div>
  
              <div class="form-group col-12 mt-3">
                <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                  <input #peer_to_peer="ngModel" type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="peer_to_peer" name="peer_to_peer" [(ngModel)]="room.room_data.peer_to_peer"
                    (change)="onPeerToPeerChange($event)" [disabled]="!peerToPeerAllowed || (trainingRoomAllowed && room.room_data.training_room)">
                  <label class="custom-control-label fs-14 text-dark-grey " for="peer_to_peer">{{ 'MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER' | translate }}
                    <img src="assets/img/info_icon.svg" height="20" class="ms-1" [dynamicTooltip]="( 
                      ('MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.BETWEEN_CLIENTS' | translate) + ' ' +
                      ('MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.REDUCED_LATENCY' | translate) 
                    )" placement="right" style="margin-bottom: 1px;">
                  </label>
                </div>
                <label *ngIf="(peerToPeerAllowed && room.room_data.training_room)" class="text-danger fs-13" for="peer_to_peer">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_ROOM_NO_PEER_TO_PEER' | translate }}</label>
                <label *ngIf="!peerToPeerAllowed" class="text-danger fs-13" for="peer_to_peer">{{ 'MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER_UNAVAILABLE' | translate }}</label>
              </div>
  
              <div class="form-group col-12 mt-3" *ngIf="accountFullHdAllowed">
                <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                  <input #full_hd="ngModel" type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="full_hd" name="full_hd" [(ngModel)]="room.room_data.full_hd" [disabled]="accountAlwaysFullHd">
                  <label class="custom-control-label fs-14 text-dark-grey " for="full_hd">{{ 'MAIN.ROOMS.ROOM_DETAILS.FULL_HD' | translate }}</label>
                </div>
                <label *ngIf="accountAlwaysFullHd" class="text-danger fs-13" for="full_hd">{{ 'MAIN.ROOMS.ROOM_DETAILS.ALWAYS_FULL_HD_ON' | translate }}</label>
                <label *ngIf="room.room_data.full_hd && !accountAlwaysFullHd" class="text-info" for="full_hd">{{ 'MAIN.ROOMS.ROOM_DETAILS.FULL_HD_DISADVANTAGES' | translate }}</label>
              </div>
            </div>
  
            <div class="form-row mt-3">
              <div class="form-group col-12">
                <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                  <input #training_room="ngModel" type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="training_room" name="training_room" [(ngModel)]="room.room_data.training_room"
                    (change)="onTrainingRoomChange($event)" [disabled]="!trainingRoomAllowed">
                  <label class="custom-control-label fs-14 text-dark-grey" for="training_room">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_ROOM' | translate }}</label>
                </div>
                <label *ngIf="!trainingRoomAllowed" class="text-danger fs-13" for="training_room">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_ROOM_UNAVAILABLE' | translate }}</label>
              </div>
  
              <div class="form-group col-8 mt-2" *ngIf="room.room_data.training_room">
                <label for="training_license" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_LICENSE' | translate }}</label>
                <select #training_license [(ngModel)]="room.room_data.training_license" class="form-control" name="training_license" required>
                  <option *ngFor="let tLicense of trainingLicenses" [ngValue]="tLicense.id">{{ tLicense.name }}</option>
                </select>
                <label *ngIf="!room.room_data.training_license" class="text-danger fs-13" for="training_license">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_LICENSE_REQUIRED' | translate }}</label>
              </div>
  
              <div class="form-group col-8 mt-4">
                <label for="users" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.ROOMS.ROOM_DETAILS.USERS' | translate }}</label>
                <ng-select [items]="users" name="users" bindLabel="name2" bindValue="id" [multiple]="true" [clearSearchOnAdd] = "true" (change)="roomUserListChanged($event)"
                  placeholder="{{ 'MAIN.ROOMS.ROOM_DETAILS.SELECT_USERS' | translate }}" [(ngModel)]="room.room_data.users" [closeOnSelect]="false" [loading]="usersLoading">
                </ng-select>
              </div>
  
              <div class="form-group col-8 mt-2" *ngIf="room.room_data.training_room">
                <label for="training_master" class="fs-14 text-dark-grey ">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_MASTER' | translate }}</label>
                <select #training_master [(ngModel)]="room.room_data.training_master" class="form-control fs-14" name="training_master" required>
                  <!-- <option *ngFor="let user of room.room_data.users" [ngValue]="user">{{ user | userName | async }}</option> -->
                </select>
                <label *ngIf="!room.room_data.training_master" class="text-danger fs-13" for="training_master">{{ 'MAIN.ROOMS.ROOM_DETAILS.TRAINING_MASTER_REQUIRED' | translate }}</label>
              </div>
            </div>
            <!-- <button class="btn btn-info btn-md-inline btn-block" type="submit" [disabled]="!roomForm.valid || formPristine || formLoading">{{ 'MAIN.ROOMS.ROOM_DETAILS.SAVE' | translate }}</button> -->
          </form>
  
        </div>
      </div>
  
      <!-- <ng-container *ngIf="action === ACTION_EDIT">
        <div class="mx-4 mx-lg-5">
          <button  class="btn btn-outline-secondary btn-md-inline btn-block mt-3" (click)="onToggle()">{{ 'MAIN.ROOMS.ROOM_DETAILS.DISABLE_ROOM' | translate }}</button>
        </div>
        <div class="mx-4 mx-lg-5">
          <button  class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()">{{ 'MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM' | translate }}</button>
        </div>
      </ng-container> -->
  
      <!-- <ng-container *ngIf="action === ACTION_DETAILS && room.room_data.disabled">
        <div class="mx-4 mx-lg-5">
          <button  class="btn btn-primary btn-md-inline btn-block mt-3" (click)="onToggle()">{{ 'MAIN.ROOMS.ROOM_DETAILS.ENABLE_ROOM' | translate }}</button>
        </div>
        <div class="mx-4 mx-lg-5">
          <button  class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()">{{ 'MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM' | translate }}</button>
        </div>
      </ng-container> -->
    </div>
  </div>
  
  <ng-template #archiveChangeTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card">
          <div class="card-header py-3">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.TITLE' | translate }}</div>
          <div class="card-body">
            <div class="m-body padded">
              <div *ngIf="dataModel.p2p_changed || dataModel.auto_archive_changed">
                <span>{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.MESSAGE_START' | translate }}</span>
                
                <strong *ngIf="dataModel.auto_archive_changed">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.AUTO_ARCHIVE' | translate }}</strong>
                <span *ngIf="dataModel.auto_archive_changed && dataModel.p2p_changed">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.AND' | translate }}</span>
                
                <strong *ngIf="dataModel.p2p_changed">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.PEER_TO_PEER' | translate }}</strong>
                <span >{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.MESSAGE_END' | translate }}</span>
              </div>
              <div class=" text-danger fs-13 mt-2" *ngIf="dataModel.p2p_on">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.CAUTION' | translate:dataModel }}</div>
              <div class=" text-danger fs-13 mt-2" *ngIf="dataModel.training_room_changed">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.TRAINING_CAUTION' | translate }}</div>
            </div>
            <div class="m-footer">
              <a class="text-secondary" (click)="callbacks.no()">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.CANCEL' | translate }}</a>
              <a class="text-info" (click)="callbacks.yes()">{{ 'MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.SAVE' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #toggleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="v-card">
          <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600" *ngIf="dataModel.disabled">{{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_ENABLE' | translate }}</div>
            <div class="modal-title font-weight-600" *ngIf="!dataModel.disabled">{{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_DISABLE' | translate }}</div>
            <div class="cursor-pointer"  (click)="callbacks.cancel()">
                <img src="assets/img/close_white.svg" alt="">
            </div>
          </div>

          <div class="card-body mt-4">
            <div class="m-body padded mx-5">
              <div class="fs-14" *ngIf="dataModel.disabled" [innerHTML]="'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_ENABLE' | translate:dataModel"></div>
              <div class="fs-14" *ngIf="!dataModel.disabled" [innerHTML]="'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_DISABLE' | translate:dataModel"></div>
            </div>

            <div class="d-flex top-gap-30 justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary"
                (click)="callbacks.cancel()">
                {{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.CANCEL' | translate }}
                </button>
                <button type="button" *ngIf="dataModel.disabled"
                    class="v-btn vsight-button btn btn-primary"
                    (click)="callbacks.yes()">
                    {{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLE' | translate }}
                </button>
                <button type="button" *ngIf="!dataModel.disabled"
                class="v-btn vsight-button btn btn-primary"
                (click)="callbacks.yes()">
                {{ 'MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLE' | translate }}
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #delRoomTemplate let-dataModel="dataModel" let-callbacks="callbacks">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="v-card">
                <div class="modal-header d-flex align-items-center">
                  <div class="modal-title font-weight-600">{{ 'MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.TITLE' | translate }}</div>
                  <div class="cursor-pointer"  (click)="callbacks.cancel()">
                      <img src="assets/img/close_white.svg" alt="">
                  </div>
                </div>
                  <div class="card-body mt-3">
                      <div class="m-body padded mx-5">
                        <div class="fs-14" [innerHTML]="'MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.MESSAGE' | translate:dataModel.room_data"></div>
                      </div>

                      <div class="d-flex top-gap-30 justify-content-center">
                        <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary"
                         (click)="callbacks.cancel()">
                         {{ 'MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.CANCEL' | translate }}
                        </button>
                        <button type="button"
                            class="v-btn vsight-button btn btn-danger"
                            (click)="callbacks.delete()">
                            {{ 'MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.DELETE' | translate }}
                        </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </ng-template>
  
  <ng-template #peerToPeerTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card">
          <div class="card-header py-3">{{ 'MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.TITLE' | translate }}</div>
          <div class="card-body">
            <div class="m-body">
              <div class="pt-3 px-3 ">{{ 'MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.BETWEEN_CLIENTS' | translate }}</div>
              <div class="pt-2 px-3 ">{{ 'MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.REDUCED_LATENCY' | translate }}</div>
              <img alt="Peer to Peer" class="w-100" src="assets/img/peertopeer.png">
              <div class="px-3 text-danger fs-13 ">{{ 'MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.ARCHIVE_UNAVAILABLE' | translate }}</div>
            </div>
            <div class="m-footer">
              <a class="text-info" (click)="callbacks.close()">{{ 'MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.CLOSE' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>