import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-qr-link-modal',
  templateUrl: './qr-link-modal.component.html',
  styleUrls: ['./qr-link-modal.component.scss']
})
export class QrLinkModalComponent implements OnInit {

  onClose: EventEmitter<any> = new EventEmitter();

  @Input() qrData
  @Input() link

  guestLink = new UntypedFormControl()

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.guestLink.patchValue(this.link)
  }

  copyGuestLinkToClipboard(input) {
    input.select()
    document.execCommand('copy');
  }

  close(): void {
    this.modalRef.hide();
  }

}
