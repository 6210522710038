import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-workflow-new-language-modal',
  templateUrl: './workflow-new-language-modal.component.html',
  styleUrls: ['./workflow-new-language-modal.component.scss']
})
export class WorkflowNewLanguageModalComponent implements OnInit {
  onClose: EventEmitter<any> = new EventEmitter();

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  addNewLanguage(saveType) {
    if(saveType) {
      this.onClose.next(true)
    }else{
      this.onClose.next(false)
    }
    this.modalRef.hide()
  }

  close() {
    this.modalRef.hide()
  }

}
