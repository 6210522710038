import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of, Subscription } from 'rxjs';
import { AlertModalComponent } from '../../../../../modals/alert-modal/alert-modal.component';
import { TemplateModalComponent } from '../../../../../modals/template-modal/template-modal.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Step } from 'src/app/models/Step';
import {TranslateService} from "@ngx-translate/core";
import { SectionModalComponent } from 'src/app/components/modals/section-modal/section-modal.component';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.scss']
})
export class StepListComponent implements OnInit,OnChanges {

  @Input('stepList') stepList?: Step[]
  @Output() stepListChange: EventEmitter<Step[]> = new EventEmitter<Step[]>();
  @Output() selectedStepChange: EventEmitter<any> = new EventEmitter<any>();

  @Input('initialStep') initialStep?
  @Output() initialStepChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() stepDelete:EventEmitter<void>
  @Input() connectionEnabled?
  @Input() type:string
  @Input() invalidSteps

  @Input() workflowTranslations
  @Output() workflowTranslationsChange: EventEmitter<string> = new EventEmitter<any>();

  @Input() workflowStepFiles
  @Output() workflowStepFilesChange: EventEmitter<string> = new EventEmitter<any>();
  @Input() isVideoFlow?: boolean
  @Input() stepSelected?: Step

  @Input() addOns?

  @Output() onStepCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStepDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStepRename: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStepClone: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStepMove: EventEmitter<any> = new EventEmitter<any>();

  @Output() onStepListClone: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStepListMove: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteSubWorkflow = new EventEmitter<any>()

  @ViewChild('stepDropdown') stepDropdown: any;
  @ViewChild('menu') menu: any;
  @ViewChild('stepsWrapper') stepsWrapper: ElementRef;
  @ViewChild('stepListStepOptions', {static: false}) stepListStepOptions: ElementRef;

  editNameMode = false;
  stepName = new UntypedFormControl('',Validators.required);

  editMode = false;

  selectedStepId: string
  subscriptions: Subscription[] = [];
  invalidStepList = []
  sectionAvailable = false
  workflowSections
  selectedLang

  stepNameLangId

  selectMode
  selectedStepList = []

  constructor(
    private modalService: BsModalService,
    private renderer: Renderer2,
    private dbService: DbService,
    private translateService: TranslateService,
    private authService: AuthService,
    private workflowLanguageService: WorkflowLanguageService
    ) {
      this.selectedLang = this.workflowLanguageService.currentLang.code
      this.workflowLanguageService.onLangChange.subscribe(res => {
        this.selectedLang = res.lang.code
      })

    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.stepDropdown ?.nativeElement && e.target !== this.menu ?.nativeElement) {
        if(this.stepList.length) {
          this.stepList.forEach(res => {
            if(res?.data){
              res.data['show'] = false
            }
          })
        }
      }
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['invalidSteps']) {
      this.invalidStepList = []
      Object.keys(this.invalidSteps).forEach(step => {
        Object.keys(this.invalidSteps[step]).forEach(layout => {
          if(this.invalidSteps[step][layout] == false) {
            this.invalidStepList.push(step)
          }
        })
      })
      this.stepList.forEach(step => {
        let invalidStep = this.invalidStepList.find(res => res == step.id)
        if(invalidStep) {
          step['valid'] = false
        }else{
          step['valid'] = true
        }
      })
    }
    if(changes['workflowTranslations']) {
      if(changes['workflowTranslations'].currentValue) {
        this.workflowTranslations = changes['workflowTranslations'].currentValue
      }
    }
    if(changes['stepSelected']) {
      if(changes['stepSelected'].currentValue) {
        this.onSelectStep(changes['stepSelected'].currentValue)
      }
    }
  }

  ngOnInit(): void {
    this.authService.getAccountData().then(account => {
      this.workflowSections = account.data.accountData.add_ons?.workflowsections
    })

    if(this.stepDelete){
      this.stepDelete.subscribe(res => {
        if(res['stepData']) {
          let index = this.stepList.findIndex(data => data.id == res?.['stepData']?.id )
          this.deleteStep(index,res['stepData'])
        }
        else if(res['stepDataList']) {
          res['stepDataList'].forEach(step => {
          let index = this.stepList.findIndex(data => data.id == step.id )
          this.deleteStep(index,this.stepList[index], true)
          })
        }
      })
    }
    
    if(this.stepList.length>0){
      this.onSelectStep(this.stepList[0])
    }

    let sectionData = this.stepList.find(res => res.data.isSection)
    this.sectionAvailable = sectionData ? true : false
    this.selectMode = false
  }

  onScrollStepList(): void {
    const stepList: HTMLDivElement = this.stepsWrapper.nativeElement;
    if(this.stepListStepOptions){
      const dropdownMenu = this.stepListStepOptions.nativeElement;

      if(dropdownMenu.getBoundingClientRect().top < stepList.getBoundingClientRect().top){
        document.getElementById(`stepListStepOptionsButton${this.selectedStepId}`).click();
      } else{
        this.renderer.setStyle(dropdownMenu, 'top', `${dropdownMenu.scrollTop}px`);
      }
      
    }
  }

  onSelectStep(step: any): void {
    if(this.selectMode) {
      if(!step.data.isSection && step.data.layouts[0]?.items[0].type !== 'workflow') {
        if(step['selectModeActive']) {
        
          let index = this.selectedStepList.findIndex(selectedStep => selectedStep.id == step.id)
          this.selectedStepList.splice(index, 1)
          delete step.selectModeActive
        }else{
          this.selectedStepList.push(step)
          step['selectModeActive'] = true
        }
      }
    }else{
      this.selectedStepId = step.id
      this.selectedStepChange.emit(step);
    }
  }

  private generateStepData(oldStep,action?,id?) {
    let filteredSection = []
    let index
    let cloneStep = JSON.parse(JSON.stringify(oldStep))
    if(oldStep.parentId) {
      filteredSection = this.stepList.filter(response => response.data.parentId == oldStep.parentId)
      index = this.stepList.findIndex(res => res.id == oldStep.parentId)
    }
    let step = {
      data: {
        name: (action =='clone' || action =='move') ? oldStep.name : this.workflowTranslations[this.selectedLang][oldStep.name] +  (' (2)'),
        layouts: cloneStep.layouts ? cloneStep.layouts : [],
        template: cloneStep.template,
        order: index || index == 0 ? index + filteredSection.length +1 : this.stepList.length,
        connections: [],
        parentId: (action =='clone' || action =='move') ? null : (cloneStep.parentId ? cloneStep.parentId : null),
      },
      valid:true,
      id: this.dbService.createPushId()
    }
    if(step.data.layouts && step.data.layouts.length>0) {
      step.data.layouts.forEach(response => {
        response.items[0].key = this.dbService.createPushId()
        if(this.workflowTranslations) {
          if(response.items[0].templateOptions?.label) {
            let oldLabel = response.items[0].templateOptions.label
            response.items[0].templateOptions.label = `$~#${this.dbService.createPushId()}$~#`
            this.workflowTranslations[this.selectedLang][response.items[0].templateOptions.label] = this.workflowTranslations[this.selectedLang][oldLabel]
          }
        }
      })
    }
    return step
  }

  createStep(status?,parentStep?,order?,sectionOrder?):void {
    if(this.sectionAvailable && status) {
      const initialState= {
        stepList : this.stepList,
        workflowTranslations : this.workflowTranslations,
        selectedLang : this.selectedLang
      };
      let alertmodalRef = this.modalService.show(SectionModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      })
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if(res) {
          this.addStepToActivity(res)
        }
      }))

    }else{
      let templateModalRef = this.modalService.show(TemplateModalComponent, {
        backdrop: 'static',
        class: 'modal-dialog-centered modal-lg modal-template',
        animated: false
      })
      this.subscriptions.push(templateModalRef.content.onClose.subscribe(res => {
        const layouts = []
        let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
        if(!this.workflowTranslations[this.selectedLang]) {
          this.workflowTranslations[this.selectedLang] = {}
        }
        this.workflowTranslations[this.selectedLang][labelTranslationId] = 'Title'
        for (let i = 0; i < res.count; i++) {
          layouts.push({items: [{ key: this.dbService.createPushId(), type: 'empty', templateOptions: { label: labelTranslationId, required: false } }]})
        }
        let step = {
          data: {
            name: "Step "+  (sectionOrder ? sectionOrder : (this.stepList.length + 1)),
            layouts: layouts,
            template: res,
            order: order ? order : this.stepList.length,
            connections: [],
            parentId: parentStep?.id ? parentStep?.id : null,
          },
          id: this.dbService.createPushId()
        }
        if(parentStep?.id) {
          step['notShow']  = parentStep.hide
        }
        let stepNameTranslationId = `$~#${this.dbService.createPushId()}$~#`
        this.workflowTranslations[this.selectedLang][stepNameTranslationId]= step.data.name
        step.data.name = stepNameTranslationId
        templateModalRef.hide()
        parentStep ? this.createSecitonStep(step,parentStep,order) : this.applyCreateStep(step)
      }));
    }
    
  }

  createSection() {
    let sectionAvailable = this.stepList.find(res => res.data.isSection)
    let stepNameTranslationId = `$~#${this.dbService.createPushId()}$~#`
    
    let step = {
      data: {
        name: stepNameTranslationId,
        layouts: [],
        template: {
        },
        order: this.stepList.length,
        connections: [],
        isSection: true
      },
      
      id: this.dbService.createPushId()
    }
    step.data.template = {
      count: 1,
      name: "fullscreen",
      orientation: "row",
      weights: [1]
    }
    this.workflowTranslations[this.selectedLang][stepNameTranslationId]= 'Section'
    let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
    this.workflowTranslations[this.selectedLang][labelTranslationId]= 'Title'
    step.data.layouts.push({items: [{ key: this.dbService.createPushId(), type: 'label', templateOptions: { label: labelTranslationId, required: false, vPosition:'center',  hPosition:'center' } }]})
    
    if(!sectionAvailable && this.stepList.length>0) {
      const initialState= {
        textMessage: this.translateService.instant(`MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.SECTION-TEXT-MESSAGE`),
        headerTitle: this.translateService.instant(`MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.CREATE-SECTION`),
        confirmButtonText: this.translateService.instant(`MODALS.ALERT.CONTINUE`),
        confirmButtonStyle:'primary',
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      })
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if(res) {
          this.sectionAvailable = true
          step.data.order = 0
          step['valid'] = true
          this.stepList.forEach(response => response.data.parentId = step.id)
          this.stepList.unshift(step)
          this.stepListNextOrder()
          if(!this.connectionEnabled) {
            this.initialStepChange.emit(step.id)
          }
        }
      }))
      
    }else{
      this.sectionAvailable = true
      this.applyCreateStep(step)
    }
  }

  addStepToActivity(parentStep) {
    let stepGroup = []
    this.stepList.forEach(stepdata => {
      if(stepdata.data.parentId && stepdata.data.parentId == parentStep.id){
        stepGroup.push(stepdata)
      }
    })
    let order = parentStep.data.order +  stepGroup.length + 1
    this.createStep(false,parentStep,order,(stepGroup.length>0 ? stepGroup.length + 1 : 1))
  }

  private applyCreateStep(step: any):void {
    this.selectedStepId = step.id
    if(this.stepList.length > 0){
      this.stepList[this.stepList.length-1].data.next = step.id
      if(!this.connectionEnabled){
        this.stepList[this.stepList.length-1].data['connections'] = [{next:step.id}]
      }
    }
    this.rename(step.data)
    if (this.stepList?.length === 0) {
      this.initialStepChange.emit(step.id)
    }
    this.stepList.push(step)
    this.stepListChange.emit(this.stepList)
    this.selectedStepChange.emit(step)
    this.onSelectStep(this.stepList[this.stepList.length - 1])
    this.onStepCreate.emit(step)
    step['valid'] = true
  }

  private stepConnectionControl(step) {
    let x = this.stepList.map(stepData => stepData.data?.connections?.find(res => res.next == step.id))
    return of(x)
  }

  createSecitonStep(step, parentStep, order) {
    this.selectedStepId = step.id
    this.stepList[order-1].data.next = step.id
    if(!this.connectionEnabled){
      this.stepList[order-1].data['connections'] = [{next:step.id}]
    }
    this.rename(step.data)
    this.stepList.splice(order,0,step)
    this.stepListChange.emit(this.stepList)
    this.onSelectStep(this.stepList[order])
    this.onStepCreate.emit(step)
    step['valid'] = true
    this.stepListNextOrder()
  }

  private deleteSection(index, step):void {
    let alertmodalRef
    const initialState= {
      textMessage : this.translateService.instant(`MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.SECTION-DELETE-TEXT-MESSAGE`),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-SECTION')
    };
    alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.stepList.forEach(stepData => {
          if(stepData.data?.connections){
            stepData.data?.connections.forEach((e,i) => {
              if(e.next == step.id){
                stepData.data?.connections.splice(i,1)
              }
            })
          }
        })
        this.deleteAndPatchList(index)
        let childSteps = this.stepList.filter(res => res.data.parentId == step.id)
       
        childSteps.forEach(res => {
          let index = this.stepList.findIndex(response => response.id == res.id)
          this.stepList.forEach(stepData => {
            if(stepData.data?.connections){
              stepData.data?.connections.forEach((e,i) => {
                if(e.next == res.id){
                  stepData.data?.connections.splice(i,1)
                }
              })
              stepData.data?.connections.find(resp => resp.next == res.id)
            }
          })
          this.deleteAndPatchList(index)
        })
      }
    }))
  }

  deleteStep(index,step, bulkDelete?):void {
    if(this.initialStep == step.id && this.connectionEnabled && this.stepList?.length > 1) {
      const initialState= {
        textMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.INITIAL-STEP-WARNING-MESSAGE'),
        headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-STEP'),
        confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
        confirmButtonStyle: 'primary'
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      })
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if(res){
        }
      }))
    }else{
      if(step.data.isSection) {
        this.deleteSection(index,step)
      }else{
        let alertmodalRef
        this.stepConnectionControl(step).subscribe(res => {
        let stepsValid = res.find(item => item)
  
          const initialState= {
            textMessage : this.connectionEnabled && stepsValid ? this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.CONNECTED-STEPS-WARNING-MESSAGE') : 
            this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.TEXT-MESSAGE'),
            headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-STEP')
          };
          if(!bulkDelete) {
            alertmodalRef = this.modalService.show(AlertModalComponent, {
              initialState,
              backdrop: 'static',
              class: 'modal-dialog-centered',
              animated: false
            })
            this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
              if (res && stepsValid) {
                this.arrangeStepOrder(step)
              }
              if(res){
                if(step.data.layouts[0].items[0].type == 'workflow') {
                  this.deleteSubWorkflow.emit(step.data.layouts[0].items[0].templateOptions?.workflow?.workflowId)
                }
                this.deleteAndPatchList(index)
              }
            }))
          }else {
            if (stepsValid) {
              this.arrangeStepOrder(step)
            }
            this.deleteAndPatchList(index, true)
          }
        })
      }

    }
    
  }

  arrangeStepOrder(step) {
    this.stepList.forEach(stepData => {
      if(stepData.data?.connections){
        stepData.data?.connections.forEach((e,index) => {
          if(e.next == step.id){
            stepData.data.connections = []
          }
        })
        stepData.data?.connections.find(res => res.next == step.id)
      }
    })
  }

  deleteAndPatchList(index, bulkDelete?) {
    const [deletedStep] = this.stepList.splice(index, 1)
    if (this.stepList.length > 0) {
      if (index !== 0) {
        if (this.stepList[index]) {
          this.stepList[index - 1].data.next = this.stepList[index].id
        }
        if (index == this.stepList.length) {
          this.stepList[index - 1].data.next = null
            if(this.stepList[index - 1].data.connections && this.stepList[index - 1].data.connections[0])
            this.stepList[index - 1].data.connections[0]['next'] = null
        }
        this.onSelectStep(this.stepList[index - 1])
      } else {
        this.initialStepChange.emit(this.stepList[index].id)
        this.onSelectStep(this.stepList[index])
      }
    } else {
      this.selectedStepId = null
      this.selectedStepChange.emit()
    }
    this.stepList.forEach((stp, i) => { stp.data.order = i })
    this.stepListChange.emit(this.stepList)
    this.onStepDelete.emit(deletedStep)
    if(!this.connectionEnabled) {
      this.stepListNextOrder()
    }
  }

  stepListNextOrder() {
    this.stepList.forEach((stp, i) => { 
      stp.data.order = i
      if (i !== this.stepList.length-1) {
        this.stepList[i].data['next'] = this.stepList[i+1].id
        if(!this.connectionEnabled) {
          if(this.stepList[i].data['connections']?.length>0){
            this.stepList[i].data['connections'][0].next = this.stepList[i+1].id
          }else{
            this.stepList[i].data['connections'] = [{next:this.stepList[i+1].id}]
          }
        }
      } else {
        delete stp.data.next
        stp.data['connections'] = []
      }
    })
  }

  rename(step):void {
    this.stepList.forEach(res => res.data['editNameMode'] = false)
    step.editNameMode = true
    this.stepNameLangId = step.name
    let stepName = this.workflowTranslations[this.selectedLang][step.name]
    this.stepName.patchValue(stepName)
  }

  updateName(step):void {
    
    if(this.stepName.valid){
      this.workflowTranslations[this.selectedLang][this.stepNameLangId] = this.stepName.value
      step.name = this.stepNameLangId
      step.editNameMode = !step.editNameMode
      this.stepName.reset()
    }
    else{
      step.name = step.name
      step.editNameMode = !step.editNameMode
      this.stepName.reset()
    }
    this.stepListChange.emit(this.stepList)
    this.onStepRename.emit(step)
  }

  duplicateStepDataWithContentTranslation(newStepData) {
    let step = JSON.parse(JSON.stringify(newStepData))

    let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
    step.data.name = nameTranslationId
    Object.keys(this.workflowTranslations).forEach(key => {
      this.workflowTranslations[key][nameTranslationId] = newStepData.data.name
    })

    newStepData.data.layouts.forEach((layout,index) => {
      let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
      let placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
      Object.keys(this.workflowTranslations).forEach(key => {
        step.data.layouts[index].items[0].templateOptions.label = labelTranslationId
        this.workflowTranslations[key][labelTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.label]
        if(step.data.layouts[index].items[0].templateOptions.placeholder || step.data.layouts[index].items[0].templateOptions.placeholder == '') {
          step.data.layouts[index].items[0].templateOptions.placeholder = placeholderTranslationId
          this.workflowTranslations[key][placeholderTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.placeholder]
        }
      })
    if(layout.items[0].templateOptions.options) {
      layout.items[0].templateOptions.options.forEach((option,optionIndex) => {
        let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
        let optionKey = this.dbService.createPushId()
        Object.keys(this.workflowTranslations).forEach(key => {
          this.workflowTranslations[key][optionTranslationId] = this.workflowTranslations[this.selectedLang][option.label]
          step.data.layouts[index].items[0].templateOptions.options[optionIndex].label = optionTranslationId
          step.data.layouts[index].items[0].templateOptions.options[optionIndex].value = optionKey
        })
      })
    }

    if(layout.items[0].templateOptions.html || layout.items[0].templateOptions.html == '') {
      let htmlTranslationId = `$~#${this.dbService.createPushId()}$~#`

      Object.keys(this.workflowTranslations).forEach(key => {
        this.workflowTranslations[key][htmlTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.html]
        step.data.layouts[index].items[0].templateOptions.html = htmlTranslationId
      })
    }
    if(layout.items[0].templateOptions.tableData) {
      layout.items[0].templateOptions.tableData.columns[0].cells.forEach((cell,cellIndex) => {
        let cellTranslationId = `$~#${this.dbService.createPushId()}$~#`
        let cellKey = this.dbService.createPushId()
        Object.keys(this.workflowTranslations).forEach(key => {
          this.workflowTranslations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.label]
          step.data.layouts[index].items[0].templateOptions.tableData.columns[0].cells[cellIndex].templateOptions.label = cellTranslationId
          step.data.layouts[index].items[0].templateOptions.tableData.columns[0].cells[cellIndex].key = cellKey
        })
      })

      layout.items[0].templateOptions.tableData.rows.forEach((row, rowIndex) => {
        row.cells.forEach((cell, cellIndex) => {
          let cellTranslationId = `$~#${this.dbService.createPushId()}$~#`
          let cellKey = this.dbService.createPushId()
          step.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].key = cellKey
          
          if(cell.templateOptions.label) {
            Object.keys(this.workflowTranslations).forEach(key => {
              this.workflowTranslations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.label]
              step.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.label = cellTranslationId
            })
          }
          if(cell.templateOptions.placeholder) {
            Object.keys(this.workflowTranslations).forEach(key => {
              this.workflowTranslations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.placeholder]
              step.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.placeholder = cellTranslationId
            })
          }
          if(cell.templateOptions.options) {
            cell.templateOptions.options.forEach((option,optionIndex) => {
              let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
              let optionKey = this.dbService.createPushId()
              Object.keys(this.workflowTranslations).forEach(key => {
              this.workflowTranslations[key][optionTranslationId] = this.workflowTranslations[this.selectedLang][option.label]
                step.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.options[optionIndex].label = optionTranslationId
                step.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.options[optionIndex].value = optionKey
              })
            })
          }
        })
      })
    }
  })

    return step
  }

  duplicateStep(oldStep):void {
    if(oldStep.parentId) {
    const newStepData = this.generateStepData(oldStep)
    const newStep = this.duplicateStepDataWithContentTranslation(newStepData)
    this.stepList.splice(newStep.data.order,0,newStep)
    this.stepList.join()
    this.stepListNextOrder()
    }else{
      const newStepData = this.generateStepData(oldStep)
      const newStep = this.duplicateStepDataWithContentTranslation(newStepData)
      this.applyCreateStep(newStep)
    }
  }

  cloneStep(sourceStep,action):void {
    this.onStepClone.emit(this.generateStepData(sourceStep,action))
  }
  moveStep(sourceStep,id,action):void {
    this.onStepMove.emit(this.generateStepData(sourceStep,action,id))
  }

  drop(event: CdkDragDrop<string[]>,step?):void {
    if(this.stepList[event.previousIndex].data.parentId){
      if(event.currentIndex !== 0) {
        if(event.currentIndex > event.previousIndex) {
          if(this.stepList[event.currentIndex].data.isSection) {
            this.stepList[event.previousIndex].data.parentId = this.stepList[event.currentIndex].id
          }
          if(this.stepList[event.currentIndex].data.parentId) {
            this.stepList[event.previousIndex].data.parentId = this.stepList[event.currentIndex].data.parentId
          }
        }else{
          if(this.stepList[event.currentIndex-1].data.isSection) {
            this.stepList[event.previousIndex].data.parentId = this.stepList[event.currentIndex-1].id
          }
          if(this.stepList[event.currentIndex-1].data.parentId) {
            this.stepList[event.previousIndex].data.parentId = this.stepList[event.currentIndex-1].data.parentId
          }
        }
        
        moveItemInArray(this.stepList, event.previousIndex, event.currentIndex);
        if(event.previousIndex == 0 || event.currentIndex == 0){
          this.initialStepChange.emit(this.stepList[0].id)
        }
        this.stepListNextOrder()
      }else{
        const initialState= {
          textMessage : this.translateService.instant(`MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.INDEPENDENT`),
          confirmButtonText: this.translateService.instant(`MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE-OK`),
          confirmButtonStyle:'primary'
        };
        let alertmodalRef = this.modalService.show(AlertModalComponent, {
          initialState,
          backdrop: 'static',
          class: 'modal-dialog-centered',
          animated: false
        })
      }
    }else{
      moveItemInArray(this.stepList, event.previousIndex, event.currentIndex);
      if(event.previousIndex == 0 || event.currentIndex == 0){
        this.initialStepChange.emit(this.stepList[0].id)
      }
      this.stepListNextOrder()
    }
    
  }

  handleSectionSteps(step) {
    this.stepList.forEach(res => {
      if(res.data.parentId == step.id) {
        res['notShow'] = !res['notShow']
      }
      
    })
    step['hide'] = !step['hide']

  }

  selectModeChange() {
    this.selectedStepId = null
    this.selectedStepList = []
    if(this.selectMode) {
      this.stepList.forEach(step => {
        if(step.selectModeActive) {
          delete step.selectModeActive
        }
      })
      this.selectedStepList = []
    }
    this.selectMode = !this.selectMode
  }

  deleteSelectedSteps() {
    let selectedSteps = []
    this.selectedStepList.forEach(step => {
      selectedSteps.push(this.workflowTranslations[this.selectedLang][step.data.name])
    })
    const initialState= {
      textMessage : this.translateService.instant(`MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.DELETE-STEP`),
      confirmButtonText: this.translateService.instant(`MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.DELETE`),
      confirmButtonStyle:'danger',
      warningList: selectedSteps
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe(res => {
      if(res) {
        this.selectMode = false
        this.selectedStepList.forEach(selectedStep => {
          let deletedStepIndex = this.stepList.findIndex(step => step.id == selectedStep.id)
          if(deletedStepIndex || deletedStepIndex == 0) {
            this.deleteStep(deletedStepIndex, this.stepList[deletedStepIndex], true)
          }
        })
        this.stepList.forEach(step => {
          if(step.selectModeActive) {
            delete step.selectModeActive
          }
        })
        this.selectedStepList = []
        this.selectMode = false
      }
    }))
  }

  duplicateSelectedSteps() {
    this.selectedStepList.forEach(selectedStep => {
      let deletedStepIndex = this.stepList.findIndex(step => step.id == selectedStep.id)
      if(deletedStepIndex || deletedStepIndex == 0) {
        this.duplicateStep(this.stepList[deletedStepIndex].data)
      }
    })
    this.stepList.forEach(step => {
      if(step.selectModeActive) {
        delete step.selectModeActive
      }
    })
    this.selectedStepList = []
    this.selectMode = false
  }

  cloneSelectedSteps() {
    let newClonedStepList = []
    this.selectedStepList.forEach(selectedStep => {
      let deletedStepIndex = this.stepList.findIndex(step => step.id == selectedStep.id)
      if(deletedStepIndex || deletedStepIndex == 0) {
        let newStep = this.generateStepData(this.stepList[deletedStepIndex].data,'clone')
        newClonedStepList.push(newStep)
      }
    })
    this.onStepListClone.emit(newClonedStepList)
    this.stepList.forEach(step => {
      if(step.selectModeActive) {
        delete step.selectModeActive
      }
    })
    this.selectedStepList = []
    this.selectMode = false
  }

  moveSelectedSteps() {
    let newMovedStepList = []
    this.selectedStepList.forEach(selectedStep => {
      let deletedStepIndex = this.stepList.findIndex(step => step.id == selectedStep.id)
      if(deletedStepIndex || deletedStepIndex == 0) {
        let newStep = this.generateStepData(this.stepList[deletedStepIndex].data,'move',this.stepList[deletedStepIndex].id )
        newMovedStepList.push(newStep)
      }
    })
    this.onStepListMove.emit(newMovedStepList)
    this.stepList.forEach(step => {
      if(step.selectModeActive) {
        delete step.selectModeActive
      }
    })
    this.selectedStepList = []
    this.selectMode = false
  }

  selectAll() {
    this.selectedStepList = []
    this.stepList.forEach(step => {
      if(!step.data.isSection && step.data.layouts[0]?.items[0].type !== 'workflow') {
        if(!step.selectModeActive) {
          step['selectModeActive'] = true
        }
        this.selectedStepList.push(step)
      }
    })
  }

  unselectAll() {
    this.stepList.forEach(step => {
      if(step.selectModeActive) {
        delete step.selectModeActive
      }
    })
    this.selectedStepList = []
  }

}