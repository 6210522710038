import { Component, EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  onClose: EventEmitter<any> = new EventEmitter();
  instantClose = true
  textMessage?: string
  importantTextMessage?: string
  regularTextMessage?: string
  confirmButtonText?: string
  confirmButtonStyle?: string
  errorMessage?: string
  cancelButtonText?: string
  headerTitle: string
  warningList?: any
  oneButtonAvailable?: boolean

  constructor(public modalRef: BsModalRef, private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.renderer.setStyle(document.body, 'overflow', 'hidden')
  }

  close(): void {
    this.modalRef.hide();
  }
  confirm(result: any): void {
    if (result) {
      if (this.instantClose) {
        this.onClose.next(true);
        this.modalRef.hide();
        return;
      }
      this.onClose.next(true);
    } else {
      this.onClose.next(false);
      this.modalRef.hide();
    }
  }
}
