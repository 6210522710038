
export const environment = {
  production: false,
  dataVersion: 5,
  version: "1.7.6",
  name: "dev",
  electron: false,
  defaultEditor: true,
  showSourceBtn: true,
  firebase: {
    apiKey: "AIzaSyDMmqISyiiWwgugYZ7XdJCSQL65W0unfbk",
    authDomain: "vsight-remote-angular-dev.firebaseapp.com",
    databaseURL: "https://vsight-remote-angular-dev-4d310.europe-west1.firebasedatabase.app/",
    projectId: "vsight-remote-angular-dev",
    storageBucket: "hangaarlab-remote",
    messagingSenderId: "848344207337"
  },
  endPoints: {
    remoteBase: "https://dev.vsight.io",
    adminBase: "https://admin-dev.vsight.io",
    workflowBase: "https://workflow-dev.vsight.io",
    login: "https://workflow-dev.vsight.io/api/login",
    joinwithlink: "https://workflow-dev.vsight.io/api/joinwithlink",
    createuser: "https://workflow-dev.vsight.io/api/createuser",
    updateuser: "https://workflow-dev.vsight.io/api/updateuser",
    deleteuser: "https://workflow-dev.vsight.io/api/deleteuser",
    changepsd: "https://workflow-dev.vsight.io/api/changepsd",
    generateremoveqr: "https://workflow-dev.vsight.io/api/generateremoveqr",
    getAllWorkflows: "https://workflow-dev.vsight.io/api/getAllWorkflows",
    getAllWorkflowReports: "https://workflow-dev.vsight.io/api/getAllWorkflowReports",
    getAllUsersForWorkflow: "https://workflow-dev.vsight.io/api/getAllUsersForWorkflow",
    createWorkflow: "https://workflow-dev.vsight.io/api/createWorkflow",
    createWorkflowDraft: "https://workflow-dev.vsight.io/api/createWorkflowDraft",
    publishWorkflowDraft: "https://workflow-dev.vsight.io/api/publishWorkflowDraft",
    getPublishedWorkflowVersion: "https://workflow-dev.vsight.io/api/getPublishedWorkflowVersion",
    getWorkflowReport: "https://workflow-dev.vsight.io/api/getWorkflowReport",
    setWorkflowDraft: "https://workflow-dev.vsight.io/api/setWorkflowDraft",
    updateWorkflowUsers: "https://workflow-dev.vsight.io/api/updateWorkflowUsers",
    deleteWorkflow: "https://workflow-dev.vsight.io/api/deleteWorkflow",
    getWorkflowAccountLabels: "https://workflow-dev.vsight.io/api/getWorkflowAccountLabels",
    setWorkflowAccountLabels: "https://workflow-dev.vsight.io/api/setWorkflowAccountLabels",
    updateWorkflowAccountUsers: "https://workflow-dev.vsight.io/api/updateWorkflowAccountUsers",
    createWorkflowReport: "https://workflow-dev.vsight.io/api/createWorkflowReport",
    getWorkflowList: "https://workflow-dev.vsight.io/api/getworkflowlist",
    getAllPublishedWorkflowVersions: "https://workflow-dev.vsight.io/api/getAllPublishedWorkflowVersions",
    updateWorkflowReport: "https://workflow-dev.vsight.io/api/updateWorkflowReport",
    getUserWorkflowReports: "https://workflow-dev.vsight.io/api/getUserWorkflowReports",
    deleteWorkflowReport: "https://workflow-dev.vsight.io/api/deleteWorkflowReport",
    getWorkflowAccountExtraDetails: "https://workflow-dev.vsight.io/api/getWorkflowAccountExtraDetails",
    workflowQrGenerate: "https://workflow-dev.vsight.io/api/workflowQrGenerate",
    workflowQrGetData: "https://workflow-dev.vsight.io/api/workflowQrGetData",
    workflowQrGetCredentials: "https://workflow-dev.vsight.io/api/workflowQrGetCredentials",
    workflowQrDelete: "https://workflow-dev.vsight.io/api/workflowQrDelete",
    translate: "https://workflow-dev.vsight.io/api/translate",
    workflowGenerate: "https://workflow-dev.vsight.io/api/workflowGenerate",
    switchToWorkflow: "https://workflow-dev.vsight.io/api/switchToWorkflow",
    switchToRemote: "https://workflow-dev.vsight.io/api/switchToRemote",
    saveApprover: "https://workflow-dev.vsight.io/api/saveApprover",
    sendWorkflowReportToSign: "https://workflow-dev.vsight.io/api/sendWorkflowReportToSign",
    getWorkflowReportLinkData: "https://workflow-dev.vsight.io/api/getWorkflowReportLinkData",
    signWorkflowReport: "https://workflow-dev.vsight.io/api/signWorkflowReport",
    sendWorkflowReportAfterApproval: "https://workflow-dev.vsight.io/api/sendWorkflowReportAfterApproval",
    getAccountData: "https://workflow-dev.vsight.io/api/getAccountData",
    getId: "https://workflow-dev.vsight.io/api/getId",
    workflowRemoteIntegrationGetId: "https://workflow-dev.vsight.io/api/workflowRemoteIntegrationGetId",
    workflowRemoteIntegrationUsers: "https://workflow-dev.vsight.io/api/workflowRemoteIntegrationUsers",
    workflowRemoteIntegrationInfo: "https://workflow-dev.vsight.io/api/workflowRemoteIntegrationInfo",
    workflowReportToPrefilled: "https://workflow-dev.vsight.io/api/workflowReportToPrefilled",
    setWorkflowAccountFolders: "https://workflow-dev.vsight.io/api/setWorkflowAccountFolders",
    changeWebhook: "https://workflow-dev.vsight.io/api/changeWebhook",
    getWorkflowWithDraft: "https://workflow-dev.vsight.io/api/getWorkflowWithDraft",
    createWorkOrder: "https://workflow-dev.vsight.io/api/createWorkorder",
    deleteWorkOrder: "https://workflow-dev.vsight.io/api/deleteWorkorder",
    getWorkOrder: "https://workflow-dev.vsight.io/api/getWorkorder",
    getWorkOrders: "https://workflow-dev.vsight.io/api/getWorkorders",
    updateWorkOrder: "https://workflow-dev.vsight.io/api/updateWorkorder",
    getAllWorkflowsMeta: "https://workflow-dev.vsight.io/api/getAllWorkflowsMeta",
    setWorkorderLabels: "https://workflow-dev.vsight.io/api/setWorkorderLabels",
    getWorkorderLabels: "https://workflow-dev.vsight.io/api/getWorkorderLabels",
    sendWorkorderComment: "https://workflow-dev.vsight.io/api/sendWorkorderComment",
    updateWorkorderForClient: "https://workflow-dev.vsight.io/api/updateWorkorderForClient",
    getMyWorkorders: "https://workflow-dev.vsight.io/api/getMyWorkorders",
    getAllUsersForWorkorder: "https://workflow-dev.vsight.io/api/getAllUsersForWorkorder",
    updateroom: "https://workflow-dev.vsight.io/api/updateroom",
    createroom: "https://workflow-dev.vsight.io/api/createroom",
    deleteroom: "https://workflow-dev.vsight.io/api/deleteroom",
    toggleroom: "https://workflow-dev.vsight.io/api/toggleroom",
    exportsession: "https://workflow-dev.vsight.io/api/exportsession",
    schedulemeeting: "https://workflow-dev.vsight.io/api/schedulemeeting",
    cancelmeeting: "https://workflow-dev.vsight.io/api/cancelmeeting",
    callusers: "https://workflow-dev.vsight.io/api/callusers",
    getExportPDF: "https://workflow-dev.vsight.io/api/generateSessionExportPdf",
    changearchive: "https://workflow-dev.vsight.io/api/changearchive",
    getUserContacts: "https://workflow-dev.vsight.io/api/getUserContacts",
    getUserSessions: "https://workflow-dev.vsight.io/apiV2/getUserSessions",
    changeazure: "https://workflow-dev.vsight.io/api/changeazure",
    changesettings: "https://workflow-dev.vsight.io/api/changesettings",
    setserviceemail: "https://workflow-dev.vsight.io/api/setserviceemail",
    passwordstrength: "https://workflow-dev.vsight.io/api/passwordstrength",
    twofactorauth: "https://workflow-dev.vsight.io/api/twofactorauth",
    admin_sso: "https://workflow-dev.vsight.io/api/admin_sso",
  },
  cnEndPoints: {
    socketserver: "https://cn.vsight.io",
    getidtoken: "https://cn.vsight.io/api/getidtoken"
  },
  design: {
    appName: "VSight",
    shortName: "VSight",
    addWorkflowToName: true,
    showSettings: true,
    webviewEnvironment: false,
    showLabels: true,
    supportedEvents: ["WorkflowReportSubmitted"],
    showVSightRemoteWidget: true,
    showFormWidget: true,
    continueWithQr: true,
    guestLoginLinkAvailable: false,
    remoteCallAvailable: true,
    idWidgetTranslation: false,
    wideLogo: false,
    logo: "assets/img/logo.png",
    sidebarLogo: "assets/img/vsight-logo.svg",
    navbarLogo: "assets/img/vsight-navbar-logo.svg",
    logoStyle: {height:'48px','margin':'50px 0 50px'},
    wideLegalLogo: false,
    squareLogo: "assets/img/square_logo.png",
    showMsLogin: true,
    msLoginSquareLogo: false,
    msLoginText: "Sign in with Microsoft",
    msLoginTextDE: "Mit Microsoft anmelden",
    showAdamosLogin: true,
    adamosLoginText: "Sign in with ADAMOS",
    adamosLoginTextDE: "Mit ADAMOS anmelden",
    addRemoteToName: false,
    useBaseUrlInsteadOfWindowOrigin: false,
    loginColor: "#00142E",
    linkColor: "#14389B",
    sidebarColor: "#003DA6",
    primaryColor: "#002E7D",
    primaryColorLight: "#2059BF",
    contactMail: "info@vsight.io",
    showPoweredBy: false,
    showArchivePermission: true,
    showAzureAdIntegration: true,
    showWorkflowEditorLink: true,
    appStoreLink: "https://apps.apple.com/us/app/vsight-workflow/id1606358495",
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.remote.app.workflow&referrer=utm-source%3Dvsight%26utm_medium%3Dguest%26utm_content%3Dwid-",
    remoteAppStoreLink: "https://apps.apple.com/us/app/vsight-remote-ar-help/id1460130334",
    remoteGooglePlayLink: "https://play.google.com/store/apps/details?id=com.hangaarlab.vsight.remoteapp",
    remoteApkLink: "https://dev.vsight.io/downloads/remote/apk"
  },
  azureAdConfig: {
    samlEntityId: "https://dev.vsight.io/${name}/saml",
    samlReplyUrl: "https://dev.vsight.io/${name}/saml/login",
    samlReplyUrl2: "https://admin-dev.vsight.io/${name}/saml/login",
    samlReplyUrl3: "https://dev.ar-remote-assistance.com/${name}/saml/login",
    scimEndpoint: "https://dev.vsight.io/${name}/scim",
    msLoginUrl: "https://dev.ar-remote-assistance.com/${name}/saml/login?RelayState=admin"
  },
  oauthOIDCConfig: {
    initiateLoginUrl: "https://dev.ar-remote-assistance.com/oauth/${name}/login",
    redirectUrl: "https://dev.ar-remote-assistance.com/oauth/${name}/callback"
  }
};