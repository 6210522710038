<div style="padding: 10px;" class="position-absolute-aligned" [ngClass]="{'image-with-label': item.templateOptions.imgUrl}">
    <div class="pdf-header">
        <div class="d-flex align-items-center justify-content-center loading-upload" *ngIf="isUpload">
            <ng-container *ngIf="isUpload" [ngTemplateOutlet]="loadingTemplateUpload"></ng-container>
        </div>
        <img src="assets/img/left-arrow.svg" alt="" class="me-2" (click)="pdfPrevious()">
        <input type="text" class="form-control mb-1 pdf-input me-2 text-white" formControlName="pdfPage" (keyup.enter)="pageSearch()">
         <label class="" style="color:white">/ {{totalPages}}</label>
        <img src="assets/img/right-arrow.svg" alt="" class="ms-2" (click)="pdfNext()">
    </div>
    <pdf-viewer 
        [src]="item.templateOptions.pdfUrl"
        [render-text]="true"
        [original-size]="false"
        [autoresize]="true"
        [page]="pdfPage"
        (pageChange)= "pageChanged($event)"
        (after-load-complete)="afterLoadComplete($event)"
        style="height: 100%"
    ></pdf-viewer>
</div>

<ng-template #loadingTemplateUpload>
    <div class="spinner-border m-5" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </ng-template>