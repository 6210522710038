<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header">
            <div style="position: absolute;top: 3px;right:12px;cursor: pointer;" (click) = "modalRef.hide()">
                <img src="assets/img/close_white.svg" alt="">
                 
             </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
             <img [src]="url | safeHtml" *ngIf="type == 'image'" alt="" class="media-image">
             <video [src]="url | safeHtml" *ngIf="type == 'video'" controls class="media-video"></video>
             <div *ngIf="type == 'pdf'" class="media-pdf">
                <div class="pdf-header">
                    <!-- <div class="d-flex align-items-center justify-content-center loading-upload" *ngIf="isUpload">
                        <ng-container *ngIf="isUpload" [ngTemplateOutlet]="loadingTemplateUpload"></ng-container>
                    </div> -->
                    <img src="assets/img/left-arrow.svg" alt="" class="me-2" (click)="pdfPrevious()">
                    <input type="text" class="form-control mb-1 pdf-input me-2 text-white" [formControl]="pdfPage" (keyup.enter)="pageSearch()">
                     <label class="" style="color:white" *ngIf="totalPages">/ {{totalPages}}</label>
                    <img src="assets/img/right-arrow.svg" alt="" class="ms-2" (click)="pdfNext()">
                </div>
                <pdf-viewer 
                    [src]="url"
                    [render-text]="true"
                    [original-size]="false"
                    [autoresize]="true"
                    [page]="currentPdfPage"
                    (pageChange)= "pageChanged($event)"
                    (after-load-complete)="afterLoadComplete($event)"
                    style="height: 100%;min-height: 350px;"
                ></pdf-viewer>
            </div>
             

          </div>
        </div>
    </div>