import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-workflow',
  template: `
          <div class="d-flex justify-content-center text-center fs-15 mt-3 font-weight-600" style="height: 90%;" *ngIf="to['workflow']">{{to['workflow'].workflowName}}</div>
  `,
})
export class FormlyFieldCustomWorkflow extends FieldType<any> {
  get type() {
    return this.to.type || 'text';
  }
}