import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

import { simplePasswordValidator } from '../validators/simple-password.validator';

@Directive({
  selector: '[simplePasswordValidator]',
  providers: [
    {
        provide: NG_VALIDATORS, 
        useExisting: forwardRef(() => SimplePasswordValidatorDirective), 
        multi: true 
    }
  ]
})

export class SimplePasswordValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return simplePasswordValidator(control);
  }
}