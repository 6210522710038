<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_TITLE' | translate }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <p *ngIf="!ios" class="mx-3">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_MESSAGE' | translate }}</p>
                <ng-container *ngIf="ios">
                    <p *ngIf="!ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.NO_IOS_MESSAGE' | translate:('design' | env) }}</p>
                    <p *ngIf="ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.IOS_VERSION_MESSAGE' | translate:('design' | env) }}</p>
                    <div class="d-flex justify-content-center">
                        <a [href]="('design' | env).appStoreLink" target="_blank" rel="noopener">
                            <img class="d-block mx-auto" style="width: 80%; max-width: 200px; object-fit: contain;" src="assets/badges/applestore.png">
                        </a>
                    </div>
                </ng-container>
        </div>

        <div class="d-flex mb-5 top-gap-30 justify-content-center">
            <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary font-weight-600 me-1"(click)="close()">
                {{ 'MAIN.CONTENT.DASHBOARD.CLOSE' | translate | titlecase }}
            </button>
        </div>
    </div>
</div>