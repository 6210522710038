import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[inputSelectOnFocus]'
})
export class InputSelectOnFocusDirective {

    constructor(private el: ElementRef) { }

    @HostListener('focus', ['$event'])
    onFocus(event: any) {
        (this.el.nativeElement as HTMLInputElement)?.select();
    }

}