import { Component, ElementRef, EventEmitter, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@services/support/snackbar.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from 'src/app/models/User';
import { Workflow } from 'src/app/models/Workflow';
import { LoaderService } from 'src/app/services/support/loader.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-workflow-users-modal',
  templateUrl: './workflow-users-modal.component.html',
  styleUrls: ['./workflow-users-modal.component.scss']
})
export class WorkflowUsersModalComponent implements OnInit {
  onClose : EventEmitter<any> = new EventEmitter

  @ViewChild('selectedUserList', { static: true }) selectedUserList: ElementRef;
  @Input('workflow') workflow!: Workflow

  filterValue: string = ""

  users: User[] = []
  filteredUsers: User[] = []
  selectedUsers: User[] = []
  availableWorkflowUsers = []

  usersInfo

  constructor(
    private workflowService: WorkflowService,
    private modalRef: BsModalRef,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getWorkflowUsers();
  }

  getWorkflowUsers(): void {
    this.loaderService.show()
    this.workflowService.getAllUsersForWorkflow()
    .then(result => {
      this.usersInfo = result
      const activeUsers = result.users.filter(user => user.auth && !user.auth.deleted)
      this.users = activeUsers.sort((u1, u2) => this.alphabeticalSort(u1, u2))
      this.users.forEach(u => this.filteredUsers.push(u));
      if (this.workflow.users) {
        this.availableWorkflowUsers = this.workflow.users.filter(id => this.users.find(user => user.id === id))
        this.selectedUsers = this.availableWorkflowUsers.map(uid => this.users.find(user => user.id === uid))
      }
    })
    .finally(() => this.loaderService.hide())
  }

  isSelected(u) {
    return this.selectedUsers.find(user => user.id === u.id)
  }

  alphabeticalSort(a, b) {
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  }

  close() {
    if(this.selectedUsers.length == this.availableWorkflowUsers.length) {
      let x = this.selectedUsers.filter(responseUser => {
        return this.availableWorkflowUsers.find(res=> res == responseUser.id)
      })
      if(x.length !== this.availableWorkflowUsers.length) {
        this.showAlertModal()
      }else {
        this.modalRef.hide()
      }
    }
    else {
      this.showAlertModal()
    }
  }

  showAlertModal() {
    const initialState = { 
      textMessage : this.translateService.instant('MAIN.CONTENT.USER-LIST.CHANGES-WARNING'),
      headerTitle: ' ',
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.CONFIRM'),
    }
    let alertmodalRef = this.modalService.show(AlertModalComponent, {initialState, backdrop:'static', class: 'modal-dialog-centered', animated:false});
    const sub = alertmodalRef.content.onClose.subscribe((res) => {
      sub.unsubscribe()
      if (res) {
        alertmodalRef.hide()
        this.modalRef.hide()

      }
    })
  }

  selectUser(userId: string) {
    const user = this.users.find(us => us.id === userId)
    if(user) {
      if(!user.workflow_user && this.usersInfo.workflowUserCount == this.usersInfo.workflowUserLimit && this.usersInfo.workflowUserLimit !== 0) {
        this.snackBarService.error(this.translateService.instant('MODALS.WORKFLOW-USERS.LIMIT-FULL'))
      }else if(!user.workflow_user && this.usersInfo.workflowUserCount < this.usersInfo.workflowUserLimit) {
        this.usersInfo.workflowUserCount += 1
        this.selecterUsersManage(user,userId)
      }else{
          this.selecterUsersManage(user,userId)
      }
    }
  }

  selecterUsersManage(user,userId) {
    const i = this.selectedUsers.findIndex(us => us.id === userId)
    if (i < 0) {
      this.selectedUsers.push(user)
      this.filterValue = ""
      this.filteredUsers = []
      this.users.forEach(u => this.filteredUsers.push(u));
      setTimeout(() => this.renderer.setProperty(this.selectedUserList.nativeElement, "scrollTop", this.selectedUserList.nativeElement.scrollHeight), 500);
    }
  }

  deleteUser(userId: string) {
    const i = this.selectedUsers.findIndex(us => us.id === userId)
    if (i > -1) {
      this.selectedUsers.splice(i, 1)
    }
  }

  filterChanged(filter) {
    this.filteredUsers = this.users.filter(user => user.name.toLowerCase().includes(this.filterValue.toLowerCase()))
  }

  saveWorkflowUsers() {
    this.loaderService.show()
    this.workflowService.updateWorkflowUsers(this.workflow.id, this.selectedUsers.map(u => u.id))
    .then(() => {
      this.onClose.next(this.selectedUsers)
    })
    .finally(() => this.loaderService.hide())
  }

}
