<app-report 
*ngIf="signatureReportData.report && signatureReportData.workflowPublishedVersion && workflowPublishedVersion"
[report]="signatureReportData.report"
[workflowPublishedVersion]="workflowPublishedVersion"
[signatureReportData]= "signatureReportData"
[users]="signatureReportData.userList"
[linkId]="linkId"
[accountInfo]="signatureReportData?.accountName"
[showSubmittedBy]="true"
>
</app-report>
