import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-html-widget',
  templateUrl: './html-widget.component.html',
  styleUrls: ['./html-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HtmlWidgetComponent),
      multi: true,
    },
  ],
})
export class HtmlWidgetComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() item //type tanımla
  @Input() dataVersion

  subscriptions : Subscription[] = []

  htmlControl = new UntypedFormControl()
  htmlItem

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['item'].currentValue) {
      this.item = changes['item'].currentValue
      if(this.dataVersion >= 4) {
        this.getFileContentFromUrl(this.item.templateOptions.html).subscribe(res => {
          this.htmlItem = res
          this.htmlControl.patchValue(res)
        })
      }
    }
  }
  onChange = (value) => {};
  onTouched = () => {};

  ngOnInit(): void {
    if(this.dataVersion >= 4) {
      this.getFileContentFromUrl(this.item.templateOptions.html).subscribe(res => {
        this.htmlItem = res
        this.htmlControl.patchValue(res)
      })
    }
  }

  byPassHTML(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
  getFileContentFromUrl(url: string) {
     return this.http.get(url, { responseType: 'text' });
  }

  writeValue(value: any) {
    this.htmlControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
  }

  validate(): ValidationErrors | null {
    return null
  }

}
