import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

// import { ModalService } from '@services/support/modal.service';

// import { JoinRoomResponse } from '@models/JoinRoomResponse';

import { fromEvent, Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged, filter } from 'rxjs/operators';

import { Room } from '@models/Room';
import { environment } from 'src/environments/environment';
import { AuthService } from '@services/auth.service';
import { User } from '@models/User';
import { DbService } from '@services/db.service';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  inRoom: boolean = false;

  // Current room, roomId -> filled when joined room, directly accessable
  currentRoomId: string = null;
  currentRoomPath: string = null;

  inRoomSignalInterval: any = null;

  incomingCall: Observable<any>;

  scheduleMeetingRequest = new Subject<void>();
  cancelMeetingRequest = new Subject<[Room, boolean]>();

  sessionExportModalId: number = null;

  private listeners: {
    "joinRoom": any[],
    "beforeEndCall": any[],
    "endCall": any[],
    "otSessionError": any[],
    "otSubscriberError": any[],
    "otPublisherError": any[]
  };

  visibilityStatus: { lastChange: Date, hidden: boolean } = null;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private dbService: DbService,
    // private modalService: ModalService
  ) {
    this.listeners = {
      "joinRoom": [],
      "beforeEndCall": [],
      "endCall": [],
      "otSessionError": [],
      "otSubscriberError": [],
      "otPublisherError": []
    };

    this.authService.on("multipleLogin", () => {
      this.inRoom = false;
    });

    this.incomingCall = this.authService.user.pipe(
      filter(us => authService.isFeatureAvailable("calling")),
      map(us => (us ? us.incoming : null) ? us.incoming.db_call : null),
      distinctUntilChanged((prev, cur) => (!prev && !cur) || (prev && cur && prev.session_id === cur.session_id))
    );

    fromEvent(document, "visibilitychange").subscribe(() => {
      this.visibilityStatus = { lastChange: new Date(), hidden: document.hidden };
    });
  }

  on( event: "joinRoom" | "beforeEndCall" | "endCall" | "otSessionError" | "otSubscriberError" | "otPublisherError", callback) {
    this.listeners[event].push(callback);
  }

  off(event: "joinRoom" | "beforeEndCall" | "endCall" | "otSessionError" | "otSubscriberError" | "otPublisherError", callback?) {
    if (callback == undefined) {
      this.listeners[event] = [];
    } else {
      const index = this.listeners[event].indexOf(callback);
      this.listeners[event].splice(index, 1);
    }
  }

  getUserContacts(): Promise<User[]> {
    return this.dbService.getUserContacts()
  }

  scheduleMeeting(participants: string[], emails: string[], title: string, startTimestamp: number, endTimestamp: number, emailLang: string): Promise<any> {
    const url = environment.endPoints.schedulemeeting;
    let base = environment.endPoints.remoteBase;
    return this.http.post<any>(url, {
      token: this.authService.currentUser.token,
      base_url: base,
      title: title,
      start: startTimestamp,
      end: endTimestamp,
      email_list: emails,
      participants: participants,
      locale: emailLang
    }).toPromise()
  }

  cancelMeeting(room: any) {
    const url = environment.endPoints.cancelmeeting;
    return this.http.post<any>(url, { token: this.authService.currentUser.token, room: room.id, link_id: room.room_data.link_id }).toPromise()
  }

//   async joinRoom(roomId: string, archiveAllowed: boolean): Promise<void> {
//     let currentUser = this.authService.currentUser;
//     if (this.sessionExportModalId) { this.modalService.hide(this.sessionExportModalId) }
//     this.sessionExportModalId = null
//     return this.http.post<JoinRoomResponse>(this.dbService.getEndPoint("joinroom"),
//       { platform: "web", app_version: environment.version, room_id: roomId, token: currentUser.token, archive_allowed: archiveAllowed, send_log: "ok" }).toPromise()
//     .then(joinResponse => this.onJoinRoom(joinResponse))
//     .catch(error => {
//       if (error.error) {
//         if (error.error === 'concurrent-limit-reached') {
//           throw new Error('concurrent-limit-reached');
//         } else if (error.error === 'expert-concurrent-limit-reached') {
//           throw new Error('expert-concurrent-limit-reached');
//         } else if (error.error === 'user-in-another-room') {
//           throw new Error('user-in-another-room');
//         } else if (error.error === 'archive-permission-needed') {
//           throw new Error('archive-permission-needed');
//         } else if (error.error === 'no-training-license') {
//           throw new Error('no-training-license');
//         } else if (error.error === 'training-license-expired') {
//           throw new Error('training-license-expired');
//         } else if (error.error === 'training-license-error') {
//           throw new Error('training-license-error');
//         }
//       }
//       throw new Error('unable-to-join-room');
//     });
//   }

//   public onJoinRoom(joinResponse: JoinRoomResponse) {
//     let currentUser = this.authService.currentUser;
//     this.currentRoomId = joinResponse.room_id;
//     this.currentRoomPath = `accounts/${currentUser.account_id}/rooms/${joinResponse.room_id}`;
//     this.inRoom = true;

//     if (this.inRoomSignalInterval) { clearInterval(this.inRoomSignalInterval) }
//     this.inRoomSignalInterval = setInterval(() => {
//       this.dbService.update(this.currentRoomPath+'/room_data/users/'+currentUser.id, {
//         control: true,
//         control_time: this.dbService.timestamp()
//       });
//     }, 10000);

//     this.listeners.joinRoom.forEach(callback => callback(joinResponse, this.currentRoomPath));
//   }

//   async endCall(endForAll: boolean = false, removeMeetingLink?: boolean): Promise<void> {
//     if (this.inRoomSignalInterval) { clearInterval(this.inRoomSignalInterval) }

//     this.listeners.beforeEndCall.forEach(callback => callback());

//     let currentUser = this.authService.currentUser;
//     return this.http.post<any>(this.dbService.getEndPoint("endcall"), 
//       { room_id: this.currentRoomId, token: currentUser.token, send_log: "ok", kick_all: endForAll, invalidate_meeting_link: removeMeetingLink }).toPromise()
//     .then(response => {
//       this.currentRoomId = null;
//       this.currentRoomPath = null;
//       this.inRoom = false;

//       this.listeners.endCall.forEach(callback => callback(response.room_id, response.session_id, "frontend/leave-session", false, response.session_ended));
//     })
//     .catch(error => {
//       throw new Error('unable-to-end-call');
//     });
//   }

  evaluateReconnectionRequired(kickRoomStatus: string) {
    if (this.authService.currentUser?.guest) {
      return false;
    }

    /*const reconnectionNotRequiredStatuses = [
      "frontend/leave-session",
      "callusers/called-while-in-room",
      "callusers/internal-error",
      "cancelmeeting/meeting-cancelled",
      "deleteroom/room-deleted",
      "endcall/leave-session",
      "endcall/session-ended",
      "getssolinkdata/login-another-session",
      "joinroom/join-another-room",
      "joinroom/internal-error",
      "login/login-another-session",
      "remote_sso/login-another-session",
      "schedulemeeting/schedule-while-in-room",
      "toggleroom/room-disabled",
      "updateroom/user-deleted-from-room",
      "updateroom/normal-to-training-room",
      "training-to-normal-room",
      "deleteUser/user-deleted",
      "disableAccount/account-disabled",
      "toggleUser/user-disabled",
      "updateUser/user-deleted-from-room"
    ]*/
    const reconnectionRequiredStatuses = [
      "frontend/ot-connection-problem",
      "frontend/no-status",
      "frontend/connection-problem",
      "frontend/no-internet-connection",
      "onUserInRoomControlChange/in-room-check-failed",
      "onUserStatusChanged/user-offline"
    ];
    const rRequired = reconnectionRequiredStatuses.includes(kickRoomStatus);
    const pageHiddenMoreThanOneMin = this.visibilityStatus && this.visibilityStatus.hidden && (Date.now() - this.visibilityStatus.lastChange.getTime()) > 1000*60;

    return rRequired && !pageHiddenMoreThanOneMin;
  }

  otSessionConnectFailed(name: string, code: number) {
    if (this.currentRoomId) {
      this.listeners.otSessionError.forEach(callback => callback(name, this.currentRoomId, code));
    }
  }

  otSubscriberFailed(name: string, code: number) {
    if (this.currentRoomId) {
      this.listeners.otSubscriberError.forEach(callback => callback(name, this.currentRoomId, code));
    }
  }

  otPublisherFailed(name: string, code: number) {
    if (this.currentRoomId) {
      this.listeners.otPublisherError.forEach(callback => callback(name, this.currentRoomId, code));
    }
  }

  async simulateEndcallForFrontend(roomId: string, sessionId: string, kickRoomStatus: string) {
    // Already call ended
    if (!this.currentRoomId) {
      return null;
    }

    if (this.inRoomSignalInterval) { clearInterval(this.inRoomSignalInterval) }
    this.listeners.beforeEndCall.forEach(callback => callback());

    await (new Promise<void>(resolve => setTimeout(() => resolve(), 500)));
    // If call ended while waiting, do not endcall again
    if (!this.currentRoomId) {
      return roomId;
    }
    this.currentRoomId = null;
    this.currentRoomPath = null;
    this.inRoom = false;
    this.listeners.endCall.forEach(callback => callback(roomId, sessionId, kickRoomStatus, this.evaluateReconnectionRequired(kickRoomStatus), false));
    return roomId;
  }

//   callUsers(users: string[], archiveAllowed: boolean): Promise<void> {
//     const url = this.dbService.getEndPoint("callusers");
//     return this.http.post<JoinRoomResponse>(url, {
//       token: this.authService.currentUser.token,
//       platform: "web",
//       app_version: environment.version,
//       calling: users,
//       archive_allowed: archiveAllowed
//     })
//     .toPromise()
//     .then(joinResponse => this.onJoinRoom(joinResponse));
//   }

  callReceived(callData: any): Promise<any> {
    return this.http.post<any>(environment.endPoints.callusers, { action: "call-received", call_data: callData }).toPromise();
  }

  denyCall(callData: any): Promise<any> {
    return this.http.post<any>(environment.endPoints.callusers, { action: "call-denied", call_data: callData }).toPromise();
  }

  getOutgoingCall() {
    // return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/users_call/${this.authService.currentUser.id}/outgoing`, "value");
  }

  setSessionExportModalId(id: number) {
    this.sessionExportModalId = id;
  }
}
