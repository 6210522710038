import { Component, EventEmitter, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { DbService } from '@services/db.service';
import { FileShareService } from '@services/support/file-share.service';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, Subscription } from 'rxjs';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-workflows-modal',
  templateUrl: './workflows-modal.component.html',
  styleUrls: ['./workflows-modal.component.scss']
})
export class WorkflowsModalComponent implements OnInit,OnDestroy {

  dataSource = new MatTableDataSource<any>()

  @Input() stepData;
  @Input() action;
  @Input() selectedLang;
  @Input() workflowTranslations;
  @Input() workflow?;
  @Input() stepDataList;
  @Input() workflowId?;
  @Input() title?;

  dataLoaded = false
  workflows = []
  filteredWorkflows = []

  filter = new UntypedFormControl('')

  newStepData

  displayedColumns =[
    "draft.name",
    "actions"
  ]

  subscriptions: Subscription[] = []

  onWorkflowSelect: EventEmitter<any> = new EventEmitter();
  onWorkflowSelectToConnect: EventEmitter<any> = new EventEmitter();

  workflowConnectionStatusChanged: EventEmitter<any> = new EventEmitter();

  currentWorkflow

  constructor(
    public loaderService: LoaderService,
    public modalRef: BsModalRef,
    private workflowService: WorkflowService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private dbService: DbService,
    private fileShareService: FileShareService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe)
  }

  ngOnInit(): void {
    this.newStepData = this.stepData
    this.getWorkflowList()
    this.subscriptions.push(this.filter.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.filterWorkflows(res.toLowerCase())
      } else {
        this.filteredWorkflows = this.workflows
      }
    }))
  }

  getWorkflowList() {
    this.loaderService.show()
    return this.workflowService.getAllWorkflows().then(workflowList => {
      this.dataLoaded = true
      this.currentWorkflow = workflowList.find( workflow => workflow.id == this.workflowId)

      this.workflows = workflowList.filter( workflow => this.workflowId ? workflow.id !== this.workflowId : workflow.id !== this.workflow.id)
      this.workflows.forEach(res => {
        if (res['show']) {
          res['show'] = false
        }
        if(res.createdAt){
          res.createdAt = new Date(res.createdAt)
        }
        if(res.draft?.updatedAt){
          res.draft.updatedAt = new Date(res.draft.updatedAt)
        }
        if(res.published?.updatedAt){
          res.published.updatedAt = new Date(res.published.updatedAt)
        }
        if(res.draft?.translations) {
          res.draft['listName'] = res.draft.defaultLanguage ? res.draft.translations[res.draft.defaultLanguage][res.draft.name] : res.draft.translations['en'][res.draft.name]
        }else {
          if(res.draft) {
            res.draft['listName'] = res.draft.name

          }
        }
        if(res.published?.translations) {
          res.published['listName'] = res.published.defaultLanguage ? res.published.translations[res.published.defaultLanguage][res.published.name] : res.published.translations['en'][res.published.name]
        }else{
          if(res.published) {
            res.published['listName'] = res.published ? res.published.name : null
          }
        }
      })
      this.workflows.sort(function(a, b) {
        if(a?.draft?.['listName'] && b?.draft?.['listName']){
          if(a.draft['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
          else if(a.draft['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
        }
        if(a?.published?.['listName'] && b?.published?.['listName']){
          if(a.published['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
          else if(a.published['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
        }
        if(a?.published?.['listName'] && b?.draft?.['listName']){
          if(a.published['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
          else if(a.published['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
        }
        if(a?.draft?.['listName'] && b?.published?.['listName']){
          if(a.draft['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
          else if(a.draft['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
        }
        return 0;
      })
      
      if (this.filter.value) {
        this.filterWorkflows(this.filter.value.toLowerCase())
      }else{
        this.filteredWorkflows = this.workflows
        if(this.workflowId) {
      this.filterParentWorkflows(this.workflowId)
          
          // this.filteredWorkflows = this.workflows.filter(workflow => {
          //   if(workflow.published?.subWorkflows) {
          //    return !Object.keys(workflow.published.subWorkflows).includes(this.workflowId)
          //   }else{
          //     return []
          //   }
          // }) 
        }
      }
      this.loaderService.hide()
    })
  }

  filterWorkflows(filterData?){
    let filteredData =[]
    filteredData = this.workflows.filter(w => {
      if(w.published){
        return w.published?.['listName'].toLowerCase().includes(filterData.toLowerCase())
      }
      if(w.draft){
        return w.draft?.['listName'].toLowerCase().includes(filterData.toLowerCase())
      }
      else{
       return filteredData
      }
    })
    this.filteredWorkflows = filteredData
    if(this.workflowId) {
      // this.filteredWorkflows = filteredData.filter(workflow => {
      //   if(workflow.published?.subWorkflows) {
      //    return !Object.keys(workflow.published.subWorkflows).includes(this.workflowId)
      //   }else{
      //     return []
      //   }
      // }) 
      this.filterParentWorkflows(this.workflowId)
    }
  }
  filteredIds = []
  

  filterParentWorkflows(workflowId) {
    let recursiveFilteredIds = []
    this.workflows.forEach(workflow => {
      if(workflow?.published && workflow.published['subWorkflows'] && workflow.published['subWorkflows'][workflowId]){
        this.filteredIds.push(workflow.id)
        recursiveFilteredIds.push(workflow.id)
        
      }
    })
    
    if(recursiveFilteredIds.length) {
      recursiveFilteredIds.forEach(res => {
        this.filterParentWorkflows(res)
      })
    }
    if(this.currentWorkflow) {
      this.filteredWorkflows = this.filteredWorkflows.filter(res => !this.filteredIds.includes(res.id) && !this.currentWorkflow?.draft?.['subWorkflows']?.[res.id])
    }
  }

  selectWorkflow(workflow){
    const initialState= {
      headerTitle:' ',
      textMessage : this.action == 'move' ? this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MOVE-TEXT-MESSAGE') : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.CLONE-TEXT-MESSAGE'),
      confirmButtonText: this.action == 'move' ?  this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.MOVE') : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.CLONE'),
      confirmButtonStyle: 'primary',
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        this.loaderService.show()
        if(!workflow.draft){
          this.workflowService.createWorkflowDraft(workflow.id).then((workflowWithDraftVersion)=> {
            if(this.stepDataList) {
              this.newStepListCloneData(workflowWithDraftVersion, this.stepDataList)
            }else{
              this.newStepListData(workflowWithDraftVersion,this.stepData, true)
            }
          })
        }else{
          if(this.stepDataList) {
            this.newStepListCloneData(workflow, this.stepDataList)
          }else{
            this.newStepListData(workflow, this.stepData, true)
          }
        }
      }else{
        alertmodalRef.hide()
        this.loaderService.hide()
      }
    }))
  }

  selectWorkflowToConnect(workflow) {
    if(workflow.published.connectionEnabled && (this.currentWorkflow?.draft && !this.currentWorkflow.draft.connectionEnabled)) {
      const initialState= {
        headerTitle:' ',
        textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.NESTED-WORKFLOWS-CONNECTION-ENABLE-WARNING'),
        confirmButtonText:  this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
        confirmButtonStyle: 'primary',
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      });
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if(res) {
          this.connectWorkflow(workflow)
          this.workflowConnectionStatusChanged.emit(true)
        }
      }))
    }else{
      this.connectWorkflow(workflow)
    }
    
  }

  connectWorkflow(workflow) {
    let steps = this.mapWorkflow(workflow)
    let initialStep = workflow.published.initialStep

    steps.forEach(step => {
      let newStepId = this.dbService.createPushId()
      steps.forEach(res => {
        if(res.data.connections?.length) {
          res.data.connections.forEach(connection => {
            if(connection.next == step.id) {
              connection.next = newStepId
            }
          })
        }else{
          res.data['lastStep'] = true
        }
        if(res.data.next == step.id) {
          res.data.next = newStepId
        }
      })
      if(workflow.published.initialStep == step.id) {
        initialStep = newStepId
      }
        step.id = newStepId
        step.data['parentWorkflow'] = workflow.id
    })
    let childWf = {connectionEnabled : workflow.published.connectionEnabled}
    let copiedWorkflow = {
        workflowName : workflow.published.listName,
        steps : steps,
        workflowId : workflow.id,
        translations: workflow.published.translations,
        workflow : childWf,
        initialStep : initialStep,
        defaultLanguage : workflow.published.defaultLanguage
    }
   
    this.onWorkflowSelectToConnect.emit(copiedWorkflow)
  }

  mapWorkflow(workflow) {
    const stepIds = workflow.published?.steps ? Object.keys(workflow.published?.steps) : []
    const workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(workflow.published?.steps[sid]))
      stp.id = sid
      return stp
    }).sort((a,b) => a.data.order - b.data.order)
    return workflowSteps
  }

  uploadFile(content: string, stepId: string) {
    const blob = new Blob([content], { type: 'text/html' });
    var file = new File([blob], "name.html");

    return new Promise((resolve, reject) => {
      this.fileShareService.uploadWorfklowFile(file, this.workflow.id, stepId, this.dbService.createPushId())
        .then(url => {
          resolve(url);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async newStepListData(workflow, clonedStepData, isSingleStep?){
    let data = {}
    const stepIds = workflow.draft?.steps ? Object.keys(workflow.draft?.steps) : []
    const workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(workflow.draft?.steps[sid]))
      stp.id = sid
      return stp
    }).sort((a,b) => a.data.order - b.data.order)
    if(workflowSteps.length == 0){
      workflow['draft'].initialStep = clonedStepData.id
    }
    let sectionAvailable = workflowSteps.find(res => res.data.isSection)
    
    let stpData = JSON.parse(JSON.stringify(clonedStepData))
    if(sectionAvailable) {
      if(workflowSteps[workflowSteps.length-1].data.parentId) {
        stpData.data.parentId = workflowSteps[workflowSteps.length-1].data.parentId
      }else{
        stpData.data.parentId = workflowSteps[workflowSteps.length-1].id
      }
    }
    stpData.data.order = workflowSteps.length
    workflowSteps.length !== 0 ? workflowSteps[workflowSteps.length-1].data['next'] = clonedStepData.id : null
    workflowSteps.length !== 0 ? workflowSteps[workflowSteps.length-1].data.connections.push({next: clonedStepData.id}) : null

    stpData.data['next'] = null
    let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
    stpData.data.name = nameTranslationId
    Object.keys(workflow.draft.translations).forEach(key => {
      workflow.draft.translations[key][nameTranslationId] = this.workflowTranslations[this.selectedLang][clonedStepData.data.name]
    })

    let stepDataList = []
    let versionDifference = false

    for (const [index,layout] of clonedStepData.data.layouts.entries())  {
    
      let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
      let placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
      Object.keys(workflow.draft.translations).forEach(key => {
        stpData.data.layouts[index].items[0].templateOptions.label = labelTranslationId
        workflow.draft.translations[key][labelTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.label]
        if(stpData.data.layouts[index].items[0].templateOptions.placeholder || stpData.data.layouts[index].items[0].templateOptions.placeholder == '') {
          stpData.data.layouts[index].items[0].templateOptions.placeholder = placeholderTranslationId
          workflow.draft.translations[key][placeholderTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.placeholder]
        }
      })

      if(layout.items[0].templateOptions.html || layout.items[0].templateOptions.html == '') {
          let htmlTranslationId = `$~#${this.dbService.createPushId()}$~#`

          if(workflow.draft.dataVersion !== this.workflow.draft.dataVersion) {
            versionDifference = true
            const initialState = {
              textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.ERROR-MESSAGE.DATA-VERSION-NOT-MATCHED'),
              confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
              confirmButtonStyle: 'primary'
            };
            this.modalService.show(AlertModalComponent, {
              initialState,
              backdrop: 'static',
              class: 'modal-dialog-centered',
              animated: false
            });
          }else{
            for (const key of Object.keys(workflow.draft.translations)) {
              if(workflow.draft.dataVersion >= 4) {
                workflow.draft.translations[key][htmlTranslationId] = await this.uploadFile(this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.html], clonedStepData.id)
                stpData.data.layouts[index].items[0].templateOptions.html = htmlTranslationId
              }else {
                workflow.draft.translations[key][htmlTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.html]
                stpData.data.layouts[index].items[0].templateOptions.html = htmlTranslationId
              }
            }
          }
        }

      if(layout.items[0].templateOptions.options) {
        layout.items[0].templateOptions.options.forEach((option,optionIndex) => {
          let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
          Object.keys(workflow.draft.translations).forEach(key => {
            workflow.draft.translations[key][optionTranslationId] = this.workflowTranslations[this.selectedLang][option.label]
            stpData.data.layouts[index].items[0].templateOptions.options[optionIndex].label = optionTranslationId
          })
        })
      }

      if(layout.items[0].templateOptions.tableData) {
        layout.items[0].templateOptions.tableData.columns[0].cells.forEach((cell,cellIndex) => {
          let cellTranslationId = `$~#${this.dbService.createPushId()}$~#`
          let cellKey = this.dbService.createPushId()
          Object.keys(workflow.draft.translations).forEach(key => {
            workflow.draft.translations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.label]
            stpData.data.layouts[index].items[0].templateOptions.tableData.columns[0].cells[cellIndex].templateOptions.label = cellTranslationId
            stpData.data.layouts[index].items[0].templateOptions.tableData.columns[0].cells[cellIndex].key = cellKey
          })
        })
  
        layout.items[0].templateOptions.tableData.rows.forEach((row, rowIndex) => {
          row.cells.forEach((cell, cellIndex) => {
            let cellTranslationId = `$~#${this.dbService.createPushId()}$~#`
            let cellKey = this.dbService.createPushId()
            stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].key = cellKey
            
            if(cell.templateOptions.label) {
              Object.keys(workflow.draft.translations).forEach(key => {
                workflow.draft.translations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.label]
                stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.label = cellTranslationId
              })
            }
            if(cell.templateOptions.placeholder) {
              Object.keys(workflow.draft.translations).forEach(key => {
                workflow.draft.translations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.placeholder]
                stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.placeholder = cellTranslationId
              })
            }
            if(cell.templateOptions.options) {
              cell.templateOptions.options.forEach((option,optionIndex) => {
                let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
                let optionKey = this.dbService.createPushId()
                Object.keys(workflow.draft.translations).forEach(key => {
                  workflow.draft.translations[key][optionTranslationId] = this.workflowTranslations[this.selectedLang][option.label]
                stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.options[optionIndex].label = optionTranslationId
                stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.options[optionIndex].value = optionKey
                })
              })
            }
          })
        })
      }

      if(layout.items[0].templateOptions.formData) {
        layout.items[0].templateOptions.formData.forEach((response,fieldIndex) => {
          let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
          Object.keys(workflow.draft.translations).forEach(key => {
            workflow.draft.translations[key][labelTranslationId] = this.workflowTranslations[this.selectedLang][response.label]
            stpData.data.layouts[index].items[0].templateOptions.formData[fieldIndex].label = labelTranslationId
          })

          response.fields.forEach((field,fIndex) => {
            let fieldLabelTranslationId = `$~#${this.dbService.createPushId()}$~#`
            Object.keys(workflow.draft.translations).forEach(key => {
              workflow.draft.translations[key][fieldLabelTranslationId] = this.workflowTranslations[this.selectedLang][field.templateOptions.label]
              stpData.data.layouts[index].items[0].templateOptions.formData[fieldIndex].fields[fIndex].templateOptions.label = fieldLabelTranslationId
            })
          })

        })
      }


      if(layout.items[0].templateOptions.workflow) {
        let subWorkflow = layout.items[0].templateOptions.workflow
        let stepData = {
          connections : [],
          order : clonedStepData.data.order,
          name : nameTranslationId,
          stepId: clonedStepData.id,
          parentId: clonedStepData.data.parentId
        }
        let childWf = {connectionEnabled : subWorkflow.workflow.connectionEnabled}

        let subWorkflowObject = {
          workflowName : subWorkflow.workflowName,
          steps : subWorkflow.steps,
          workflowId : subWorkflow.workflowId,
          translations: subWorkflow.translations,
          workflow : childWf,
          initialStep : subWorkflow.initialStep,
          defaultLanguage : subWorkflow.defaultLanguage,
          stepData : stepData
        }
        if(!workflow.draft['subWorkflows']) {
          workflow.draft['subWorkflows'] = {}
          workflow.draft['subWorkflows'][subWorkflow.workflowId] = subWorkflowObject
        }else{
          workflow.draft['subWorkflows'][subWorkflow.workflowId] = subWorkflowObject
        }
        stepDataList = subWorkflow.steps
        workflowSteps.length > 1 ? workflowSteps[workflowSteps.length-2].data.connections['next'] =  subWorkflowObject.initialStep: null
        stepDataList.forEach(stp => {
          stp.data['parentWorkflow'] = subWorkflowObject['workflowId']
        })

        if(workflow.draft.translations && Object.keys(workflow.draft.translations).length > 0) {
          Object.keys(workflow.draft.translations).forEach(translationKey => {
            workflow.draft.translations[translationKey] = Object.assign({}, workflow.draft.translations[translationKey], (subWorkflowObject.translations[translationKey] ? subWorkflowObject.translations[translationKey] : subWorkflowObject.translations[subWorkflowObject.defaultLanguage]));
          })
        }
        
      }
    }
    let finalSteps = []
    if(stpData.data.layouts[0].items[0].type == 'workflow') {
      stepDataList.forEach(stp => {
        workflowSteps.push(stp)
      })
    }else{
      workflowSteps.push(stpData)
    }
    finalSteps = workflowSteps.filter(step => step.data.layouts[0].items[0].type !== 'workflow')

    if(!versionDifference) {
      workflow.draft.steps = finalSteps
      if(this.action=='clone'){
        data = {action:'clone',workflow:workflow, isSingleStep : stepDataList.length ? false : isSingleStep}
      }else{
        data = {action:'move',workflow:workflow, isSingleStep : stepDataList.length ? false : isSingleStep }
      }
      this.onWorkflowSelect.emit(data)
    }else{
      this.onWorkflowSelect.emit(null)
    }
  }

  async newStepListCloneData(workflow, clonedStepList) {
    let data = {}
    const stepIds = workflow.draft?.steps ? Object.keys(workflow.draft?.steps) : []
    const workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(workflow.draft?.steps[sid]))
      stp.id = sid
      return stp
    }).sort((a,b) => a.data.order - b.data.order)
    if(workflowSteps.length == 0){
      workflow['draft'].initialStep = clonedStepList[0].id
    }
    let sectionAvailable = workflowSteps.find(res => res.data.isSection)
    let versionDifference = false

    for (const [index,clonedStepData] of clonedStepList.entries())  {
      let stpData = JSON.parse(JSON.stringify(clonedStepData))
      if(sectionAvailable) {
        if(workflowSteps[workflowSteps.length-1].data.parentId) {
          stpData.data.parentId = workflowSteps[workflowSteps.length-1].data.parentId
        }else{
          stpData.data.parentId = workflowSteps[workflowSteps.length-1].id
        }
      }
      stpData.data.order = workflowSteps.length
      workflowSteps.length !== 0 ? workflowSteps[workflowSteps.length-1].data['next'] = clonedStepData.id : null
      workflowSteps.length !== 0 ? workflowSteps[workflowSteps.length-1].data.connections.push({next: clonedStepData.id}) : null

      stpData.data['next'] = null
      let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
      stpData.data.name = nameTranslationId
      Object.keys(workflow.draft.translations).forEach(key => {
        workflow.draft.translations[key][nameTranslationId] = this.workflowTranslations[this.selectedLang][clonedStepData.data.name]
      })

    for (const [index,layout] of clonedStepData.data.layouts.entries())  {

        let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
        let placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
        Object.keys(workflow.draft.translations).forEach(key => {
          stpData.data.layouts[index].items[0].templateOptions.label = labelTranslationId
          workflow.draft.translations[key][labelTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.label]
          if(stpData.data.layouts[index].items[0].templateOptions.placeholder || stpData.data.layouts[index].items[0].templateOptions.placeholder == '') {
            stpData.data.layouts[index].items[0].templateOptions.placeholder = placeholderTranslationId
            workflow.draft.translations[key][placeholderTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.placeholder]
          }
        })

        if(layout.items[0].templateOptions.options) {
          layout.items[0].templateOptions.options.forEach((option,optionIndex) => {
            let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
            Object.keys(workflow.draft.translations).forEach(key => {
              workflow.draft.translations[key][optionTranslationId] = this.workflowTranslations[this.selectedLang][option.label]
              stpData.data.layouts[index].items[0].templateOptions.options[optionIndex].label = optionTranslationId
            })
          })
        }

        if(layout.items[0].templateOptions.html || layout.items[0].templateOptions.html == '') {
          let htmlTranslationId = `$~#${this.dbService.createPushId()}$~#`
  
          if(workflow.draft.dataVersion !== this.workflow.draft.dataVersion) {
            versionDifference = true
            const initialState = {
              textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.ERROR-MESSAGE.DATA-VERSION-NOT-MATCHED'),
              confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
              confirmButtonStyle: 'primary'
            };
            this.modalService.show(AlertModalComponent, {
              initialState,
              backdrop: 'static',
              class: 'modal-dialog-centered',
              animated: false
            });
          }else{
            for (const key of Object.keys(workflow.draft.translations)) {
              if(workflow.draft.dataVersion >= 4) {
                workflow.draft.translations[key][htmlTranslationId] = await this.uploadFile(this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.html], clonedStepData.id)
                stpData.data.layouts[index].items[0].templateOptions.html = htmlTranslationId
              }else {
                workflow.draft.translations[key][htmlTranslationId] = this.workflowTranslations[this.selectedLang][layout.items[0].templateOptions.html]
                stpData.data.layouts[index].items[0].templateOptions.html = htmlTranslationId
              }
            }
          }
        }

        if(layout.items[0].templateOptions.tableData) {
          layout.items[0].templateOptions.tableData.columns[0].cells.forEach((cell,cellIndex) => {
            let cellTranslationId = `$~#${this.dbService.createPushId()}$~#`
            let cellKey = this.dbService.createPushId()
            Object.keys(workflow.draft.translations).forEach(key => {
              workflow.draft.translations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.label]
              stpData.data.layouts[index].items[0].templateOptions.tableData.columns[0].cells[cellIndex].templateOptions.label = cellTranslationId
              stpData.data.layouts[index].items[0].templateOptions.tableData.columns[0].cells[cellIndex].key = cellKey
            })
          })
    
          layout.items[0].templateOptions.tableData.rows.forEach((row, rowIndex) => {
            row.cells.forEach((cell, cellIndex) => {
              let cellTranslationId = `$~#${this.dbService.createPushId()}$~#`
              let cellKey = this.dbService.createPushId()
              stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].key = cellKey
              
              if(cell.templateOptions.label) {
                Object.keys(workflow.draft.translations).forEach(key => {
                  workflow.draft.translations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.label]
                  stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.label = cellTranslationId
                })
              }
              if(cell.templateOptions.placeholder) {
                Object.keys(workflow.draft.translations).forEach(key => {
                  workflow.draft.translations[key][cellTranslationId] = this.workflowTranslations[this.selectedLang][cell.templateOptions.placeholder]
                  stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.placeholder = cellTranslationId
                })
              }
              if(cell.templateOptions.options) {
                cell.templateOptions.options.forEach((option,optionIndex) => {
                  let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
                  let optionKey = this.dbService.createPushId()
                  Object.keys(workflow.draft.translations).forEach(key => {
                    workflow.draft.translations[key][optionTranslationId] = this.workflowTranslations[this.selectedLang][option.label]
                  stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.options[optionIndex].label = optionTranslationId
                  stpData.data.layouts[index].items[0].templateOptions.tableData.rows[rowIndex].cells[cellIndex].templateOptions.options[optionIndex].value = optionKey
                  })
                })
              }
            })
          })
        }
  
        if(layout.items[0].templateOptions.formData) {
          layout.items[0].templateOptions.formData.forEach((response,fieldIndex) => {
            let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
            Object.keys(workflow.draft.translations).forEach(key => {
              workflow.draft.translations[key][labelTranslationId] = this.workflowTranslations[this.selectedLang][response.label]
              stpData.data.layouts[index].items[0].templateOptions.formData[fieldIndex].label = labelTranslationId
            })
  
            response.fields.forEach((field,fIndex) => {
              let fieldLabelTranslationId = `$~#${this.dbService.createPushId()}$~#`
              Object.keys(workflow.draft.translations).forEach(key => {
                workflow.draft.translations[key][fieldLabelTranslationId] = this.workflowTranslations[this.selectedLang][field.templateOptions.label]
                stpData.data.layouts[index].items[0].templateOptions.formData[fieldIndex].fields[fIndex].templateOptions.label = fieldLabelTranslationId
              })
            })
  
          })
        }
      }

      workflowSteps.push(stpData)
      workflow.draft.steps = workflowSteps
      if(this.action=='clone'){
        data = {action:'clone',workflow:workflow, isSingleStep:false}
      }else{
        data = {action:'move',workflow:workflow, isSingleStep:false }
      }
    }
    this.onWorkflowSelect.emit(data)
    
  }

}
