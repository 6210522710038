import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {
  @ContentChild('searchInput') searchInput: ElementRef;

  @Output() onLabelDelete: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Output() onFilter: EventEmitter<any> = new EventEmitter();


  @Input() allLabels?


  labelGroup = new UntypedFormGroup({})
  filter = new UntypedFormControl()
  labelSearchControl = new UntypedFormControl()

  filteredLabels = []
  filteredLabelList = []
  subscriptions: Subscription[] = []

  constructor() { }

  ngOnInit(): void {
    this.filteredLabelList = this.allLabels
    if(this.allLabels) {
      this.allLabels.forEach(label => {
        let labelControl = new UntypedFormControl (false)
        this.labelGroup.addControl(label, labelControl)
      })
    }
    this.subscriptions.push(this.filter.valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.filterChanged()
    }))
  }

  deleteLabel(label) {
    let labelIndex = this.filteredLabels.findIndex(res => res == label)
    this.filteredLabels.splice(labelIndex, 1)
    this.labelGroup.controls[label].patchValue(false)
    this.filterChanged()
  }

  clearAll() {
    this.filteredLabels = []
    Object.keys(this.labelGroup.controls).forEach(labelControl => {
      this.labelGroup.controls?.[labelControl].patchValue(false)
    })
    this.filterChanged()
    this.clear.emit(true)
  }

  selectLabels(label) {
    if( this.filteredLabels.includes(label) ) {
      let labelIndex = this.filteredLabels.findIndex(res => res == label)
      this.filteredLabels.splice(labelIndex, 1)
    }else{
      this.filteredLabels.push(label)
    }
    this.filterChanged()

  }

  toggle(): void {
    if (this.searchInput?.nativeElement) {
      setTimeout(() => {
        (this.searchInput.nativeElement as HTMLInputElement).focus();
      });
    }
  }

  filterChanged() {
    const filterData = {
      labels : this.filteredLabels,
      name : this.filter.value
    }
    this.onFilter.emit(filterData)
  }

}
