import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { WorkflowService } from '@services/workflow.service';
import { Subscription } from 'rxjs';
import * as bowser from 'bowser';
import { SnackbarService } from '@services/support/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-media-widget',
  templateUrl: './media-widget.component.html',
  styleUrls: ['./media-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MediaWidgetComponent),
      multi: true,
    },
  ],
})
export class MediaWidgetComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() item //type tanımla
  @Input() token
  @Input() guestToken
  @Input() workflowId

  subscriptions : Subscription[] = []

  ppeControl
  captureImageControl
  captureVideoControl

  mediaControl = new UntypedFormControl();

  isSafari
  isMobile
  browser
  isUpload
  isLoading = true;

  showPpeAlert = false
  private selectedImageIndex: number | null = null; 
  selectedImage

  constructor(
    private workflowService: WorkflowService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService
    ) { }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.mediaControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.mediaControl.valid ? null : this.mediaControl.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    if(this.item.templateOptions.required) {
      this.mediaControl.setValidators(Validators.required)
    }

    this.browser = bowser.getParser(window.navigator.userAgent);
    if (this.browser.is("Safari")) {
      this.isSafari = true
    }
    if(this.browser.is('mobile')) {
      this.isMobile = true
    }
    this.subscriptions.push(this.mediaControl.valueChanges.subscribe(res => this.onChange(res)))
  }

  deleteImage(type, index?: number) {
    if (type == 'image') {
      const currentImages = this.mediaControl.value || [];
      currentImages.splice(index, 1);
      this.mediaControl.patchValue(currentImages);
    } 
    else if (type == 'ppe' || 'video'){
      this.mediaControl.patchValue('')
    }
}

chooseButtonUpload(type: "image" | "video" | "ppe", event: any): void {
  this.isUpload = true;
  let selectedFiles;

  if (type === 'image') {
    selectedFiles = event.target.files;
  } else {
    selectedFiles = event.target.files[0];
  }

  const filesToUpload = selectedFiles;

  this.previewUpload(type, filesToUpload, this.mediaControl);
}

private previewUpload(type: "image" | "video" | "ppe", selectedFiles, control: UntypedFormControl): void {

  this.isLoading = true;
  if (type === 'image') {
    this.uploadMultipleFiles(selectedFiles).subscribe({
      next: (res) => {
        const currentFiles = control.value || [];
        let imageObject
        if(res?.['data']) {
          res['data'].forEach(uploadedImage => {
            imageObject = {url: uploadedImage.url}
            currentFiles.push(imageObject)
          })
        }
        control.patchValue(currentFiles);
        this.isLoading = false;
        this.isUpload = false;
      },
      error: (err) => {
        this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR'))
        this.isLoading = false;
        this.isUpload = false;
      }
    })
  } else {
    const selectedFile = selectedFiles;
    
    this.subscriptions.push(this.uploadFile(selectedFile, type).subscribe({
      next: (resp) => {
        if (resp) {
          this.isUpload = false;
          this.isLoading = false;
          let image;
  
          if (type === 'ppe') {
            image = {};
            image = { url: resp['data'].file['url'], detected: resp['data'].Summary.PersonsWithRequiredEquipment.length > 0 };
  
            if (resp['data'].Summary.PersonsWithRequiredEquipment.length > 0) {
            } else {
              this.showPpeAlert = true;
            }
          } 
          else if (type === 'video') {
            image = resp['data'][0]?.url;
          }
          
          if (type === 'ppe' || type === 'video') {
            control.patchValue(image);
          }
        }
      },
      error: (e) => {
        this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR'))
        this.isUpload = false;
        this.isLoading = false;
      }
    }));
  }
}

private uploadMultipleFiles(selectedFiles) {
  let data = []
  let formData = new FormData();
  Array.from(selectedFiles).forEach(res => {
    data.push(res)
  })
  data.forEach(file => {
    formData.append(file.name, file)
  })
  return this.workflowService.uploadWorkflowFiles(this.workflowId, formData, (this.token ? this.token : this.guestToken))
}

  uploadFile(file,type) {
    let formData = new FormData();
    if(type =='ppe'){
      let options = []
      let ppeOptions = this.item.templateOptions.ppeOptions
      if(ppeOptions.head) {
        options.push("HEAD_COVER")
      }
      if(ppeOptions.hand) {
        options.push("HAND_COVER")
      }
      if(ppeOptions.face) {
        options.push("FACE_COVER")
      }
      formData.append(file.name, file)
      formData.append('options', JSON.stringify(options));

      //options.forEach((item) => formData.append(item))
      return this.workflowService.uploadWorkflowPpeFiles(this.workflowId, formData, (this.token ? this.token : this.guestToken))
    }else{
      if(file.length){
        for (let i = 0; i < file.length; i++) {
          formData.append(file[i].name, file[i])
        }
      }else{
        formData.append(file.name, file)
      }
      return this.workflowService.uploadWorkflowFiles(this.workflowId, formData, (this.token ? this.token : this.guestToken))
    }
    
  }

private previewEditUpload(type: "image" | "video" | "ppe" | "qr", selectedFile: File, control: UntypedFormControl, index: number): void {
  if (selectedFile) {
      this.isLoading = true;
      this.subscriptions.push(this.uploadFile(selectedFile, type).subscribe({
        next: (resp) => {
          if (resp) {
            this.isUpload = false;
            const imageObject = { url: resp['data'][0]?.url };

            const currentImages = control.value || [];
            if (this.selectedImageIndex !== null) {
                currentImages[this.selectedImageIndex] = imageObject;
            }

            this.selectedImageIndex = null; 
            this.isLoading = false;
            control.patchValue(currentImages);
          }
        },
        error: (e) => {
          this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR'))
          this.isLoading = false;
        }
      }));
  }
}

  closeAlert() {
    this.showPpeAlert = false
  }

  changeImage(inputElement: HTMLInputElement, index: number) {
    inputElement.click();
    this.selectedImageIndex = index;
    inputElement.addEventListener('change', (event: Event) => {
        this.isUpload = true;
        const selectedFile = inputElement.files[0];

        if (selectedFile) {
            this.previewEditUpload('image', selectedFile, this.mediaControl, index);
        }
    });
  }

  editUpload(type: "image" | "video" | "ppe" | "qr", event: any): void {
    this.isUpload = true
    let selectedFile = event.target.files[0]
    this.previewEditUploadVideo(type, selectedFile, this.mediaControl)
  }

  private previewEditUploadVideo(type: "image" | "video" | "ppe" | "qr", selectedFile: File, control: UntypedFormControl): void {
    if (selectedFile) {
      this.isLoading = true;
      this.subscriptions.push(this.uploadFile(selectedFile,type).subscribe(resp => {
        if(resp){
          this.isUpload = false
          control.patchValue(resp['data'][0].url)
        }
      }))
      this.isLoading = false;
    }
  }


  changeVideo(inputElement:HTMLElement) {
    inputElement.click()
  }

  openImageFullscreen(image) {
    this.selectedImage = image
  }

  exitFullscreen() {
    this.selectedImage = null

  }
}
