import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, Output, EventEmitter } from '@angular/core';

import { of, Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';

import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { UtilityService } from '@services/remote/utility.service';
import { CallService } from '@services/remote/call.service';
import { environment } from 'src/environments/environment';
import { WorkflowService } from '@services/workflow.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-call-users',
  templateUrl: './call-users.component.html',
  styleUrls: ['./call-users.component.scss']
})
export class CallUsersComponent implements OnInit, OnDestroy {

  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;

  @ViewChild('videoElement') videoElement: ElementRef;

  @Output() onSelectedUsersChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() onCallUser: EventEmitter<string> = new EventEmitter();

  users: any[] = [];
  usersSub: Subscription = null;
  callingAllowed: boolean = true;
  //accountCallingAllowed: boolean = true;
  //userCallingAllowed: boolean = true;

  licenseSub: Subscription = null;
  licenseType: string = "concurrent_user_based";

  //selectMultiple: boolean = true;
  selectedUsers = {};
  
  nameUserName: string = "";
  
  roles: any[] = [];
  selectedFilters: string[] = [];
  
  allUsers: any[] = null;

  searchTextChangedSource: Subject<string> = new Subject<string>();
  searchTextChangedSub: Subscription = null;

  langSub: Subscription = null;
  textfieldInput: any = false;

  subscriber: string = "";
  user: string = "";
  admin: string = "";
  coadmin: string = "";
  expert: string = "";

  currentUserId: string = '';

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  selectedUsersToCall = new FormControl()

  isLoading = false

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private router: Router,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService,
    private workflowService: WorkflowService
  ) {
    // Code piece runs whenever user touches the textfield search.
    this.searchTextChangedSub = this.searchTextChangedSource.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(text => {
        this.textfieldInput = text;
        
        var roles = [...this.selectedFilters];
        roles = roles.map(item => 
          item === this.subscriber  ? "user" : 
          item === this.user  ? "user" :
          item === this.admin  ? "admin" :
          item === this.coadmin  ? "coadmin" :
          item === this.expert  ? "expert" : ""
        )

        this.users = this.allUsers
          .filter(user => {
            let nameSelected      = text ? user.name.toLowerCase().match(text.toLowerCase())          : true;
            // let userNameSelected  = text ? (user.user_principal_name ? user.user_principal_name : user.username).toLowerCase().match(text.toLowerCase()) : true;
            let roleSelected      = roles.length > 0 ? roles.includes(user.role)                      : true;
            return roleSelected && (nameSelected);
          })
          .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

          this.roles = this.users
          .map(user => this.licenseType === "expert_user_based"     && user.role === "user" ? this.subscriber  : user)
          .map(user => this.licenseType === "concurrent_user_based" && user.role === "user" ? this.user        : user)
          .map(user => 
            user.role === "admin"   ? this.admin   :
            user.role === "coadmin" ? this.coadmin :
            user.role === "expert"  ? this.expert  : user
          )
          .reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);  
      })
  }
  
  ngOnInit() {
    this.isLoading = true
    // this.showNotSupportedModal({ ios: true })

    this.langSub =this.multilanguageService.onLangChange.pipe(startWith(this.multilanguageService.currentLang)).subscribe(change => {
      this.subscriber = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.SUBSCRIBER')
      this.user = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.USER')
      this.admin = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.ADMIN')
      this.coadmin = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.COADMIN')
      this.expert = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.EXPERT')
    })
    
    // this.licenseSub = this.authService.license.subscribe(license => {
    //   this.licenseType = license.type;
    // });

    // this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
    //   this.cameraMirror = mirror;
    // });

    this.currentUserId = this.authService.currentUser.id;

    this.workflowService.getAllUsersForWorkflow().then(result => {
      if(result) {
        this.allUsers = result.users
        
      }
      
    })

    this.authService.getAccountData().then(account => {
      this.licenseType = account.data.accountData.license.type;
      const features = account.data.accountData.features;

      if(features && features.calling) {
        this.callService.getUserContacts().then(users => {
          this.loaderService.hide()
          this.users = users
            .filter(user => {
              let nameSelected = this.textfieldInput ? user.name.toLowerCase().includes(this.textfieldInput.toLowerCase()) : true;
              return  (nameSelected);
            })
            .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
          })
      }else{
        this.loaderService.hide()
      }

      // account.data.accountData.features.pipe(
      //   distinctUntilChanged((prev, cur) => prev['calling'] === cur['calling']),
      //   switchMap(f => (f && f['calling']) ? this.authService.user : of(null)),
      //   distinctUntilChanged((prev, cur) => {
      //     if (prev === null && cur === null) {
      //       return true;
      //     }
      //     if ((prev === null && cur !== null) || (prev !== null && cur === null)) {
      //       return false;
      //     }
      //     return prev['allow_calling'] === cur['allow_calling'] && prev['allow_seeing_all_users'] === cur['allow_seeing_all_users']
      //   }))
      
      })
  

    // this.usersSub = this.authService.features.pipe(
    //   distinctUntilChanged((prev, cur) => prev.calling === cur.calling),
    //   switchMap(f => (f && f.calling) ? this.authService.user : of(null)),
    //   distinctUntilChanged((prev, cur) => {
    //     if (prev === null && cur === null) {
    //       return true;
    //     }
    //     if ((prev === null && cur !== null) || (prev !== null && cur === null)) {
    //       return false;
    //     }
    //     return prev.allow_calling === cur.allow_calling && prev.allow_seeing_all_users === cur.allow_seeing_all_users
    //   }),
    //   switchMap(u => (u && u.allow_calling) ? this.authService.getUserContacts() : of(null))
    // )
    // .subscribe(contacts => {
    //   if (contacts) {
    //     var roles = [...this.selectedFilters];
    //     roles = roles.map(item => 
    //       item === this.subscriber  ? "user" : 
    //       item === this.user  ? "user" :
    //       item === this.admin  ? "admin" :
    //       item === this.coadmin  ? "coadmin" :
    //       item === this.expert  ? "expert" : ""
    //     )

    //     this.users = contacts
    //       .filter(user => {
    //         let nameSelected      = this.textfieldInput ? user.name.toLowerCase().match(this.textfieldInput.toLowerCase())          : true;
    //         let userNameSelected  = this.textfieldInput ? (user.user_principal_name ? user.user_principal_name : user.auth.username).toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
    //         let roleSelected      = roles.length > 0 ? roles.includes(user.role)                      : true;
    //         return roleSelected && (nameSelected || userNameSelected);
    //       })
    //       .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

    //     this.roles = this.users
    //       .map(user => this.licenseType === "expert_user_based"     && user.role === "user" ? this.subscriber  : user)
    //       .map(user => this.licenseType === "concurrent_user_based" && user.role === "user" ? this.user        : user)
    //       .map(user => 
    //         user.role === "admin"   ? this.admin   :
    //         user.role === "coadmin" ? this.coadmin :
    //         user.role === "expert"  ? this.expert  : user
    //       )
    //       .reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);      

    //     this.allUsers = contacts.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    //     this.callingAllowed = true;
    //     this.isLoading = false
    //   } else {
    //     this.callingAllowed = false;
    //     this.users = [];
    //     this.roles = [];
    //     this.isLoading = false
    //   }
    // });
  }

  cameraMirrorChanged(mirror: boolean) {
    // this.opentokService.changeCameraMirror(mirror);
  }

  replaceSpecialCharactersWithSpace(text: string): string {
    return text.replace(/([.?*+^$[\]\\(){}|-])/g, ' ');
  }

  onSearchTextChange(text: string): void {
    this.searchTextChangedSource.next(this.replaceSpecialCharactersWithSpace(text));
  }

  onFilterChanged() {
    var roles = [...this.selectedFilters];
    roles = roles.map(item => 
      item === this.subscriber  ? "user" : 
      item === this.user  ? "user" :
      item === this.admin  ? "admin" :
      item === this.coadmin  ? "coadmin" :
      item === this.expert  ? "expert" : ""
    )

    this.users = this.allUsers
      .filter(user => {
        let nameSelected      = this.textfieldInput ? user.name.toLowerCase().match(this.textfieldInput.toLowerCase())          : true;
        let userNameSelected  = this.textfieldInput ? (user.user_principal_name ? user.user_principal_name : user.username).toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
        let roleSelected      = roles.length > 0 ? roles.includes(user.role)                                                    : true;
        return roleSelected && (nameSelected || userNameSelected);
      })
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));

      this.roles = this.users
      .map(user => this.licenseType === "expert_user_based"     && user.role === "user" ? this.subscriber  : user)
      .map(user => this.licenseType === "concurrent_user_based" && user.role === "user" ? this.user        : user)
      .map(user => 
        user.role === "admin"   ? this.admin   :
        user.role === "coadmin" ? this.coadmin :
        user.role === "expert"  ? this.expert  : user
      )
      .reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);  
  } 

  ngOnDestroy() {
    if (this.usersSub) { this.usersSub.unsubscribe() }
    if (this.licenseSub) { this.licenseSub.unsubscribe() }
    if (this.searchTextChangedSub) { this.searchTextChangedSub.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
  }

  callSingleUser(userId: string) {
    //this.selectedUsers = { [userId]: true };
    this.onCallUser.emit(userId);
  }

  callUsersSelected() {
    return Object.keys(this.selectedUsers).filter(u => this.selectedUsers[u]).length > 0;
  }

  /*onToggleMultiSelect() {
    this.selectMultiple = !this.selectMultiple;
    if (this.selectMultiple) {
      this.selectedUsers = {};
    }
  }*/

  onContactClicked(userId: string) {
    if (userId === this.currentUserId) {
      return;
    }

    this.selectedUsers[userId] = !this.selectedUsers[userId];

    const users = Object.keys(this.selectedUsers).filter(uid => this.selectedUsers[uid] === true)
    this.onSelectedUsersChanged.emit(users)
  }

  // onCallUsers(users: string[]) {
  //   const isExpert = this.authService.currentUser.role === 'expert';
  //   if (!isExpert) {
  //     const anyExpertCalled = users.some(uid => {
  //       const user = this.users.find(u => u.id === uid);
  //       return user ? user.role === 'expert' : false;
  //     });

  //     if (!anyExpertCalled) {
  //       this.flashMessageService.showTranslated('APP.MAIN.LOBBY.CONTACTS.NO_EXPERT');
  //       return;
  //     }
  //   }

  //   const parser = this.utilityService.getBrowserParser();
  //   const isTouchDevice = 'ontouchstart' in window;
  //   // Is Device iOS or ipadOS (detected as touch macOS)
  //   const isIOS = parser.is("iOS");
  //   const isIpad = parser.is("macOS") && isTouchDevice;

  //   if (isIOS || isIpad) {
  //     if (!parser.is("Safari")) {
  //       this.showNotSupportedModal({ ios: true });
  //       return;
  //     } else if (!parser.satisfies({safari: ">=12"})) {
  //       this.showNotSupportedModal({ ios: true, ios_version_low: true });
  //       return;
  //     }
  //   }
  //   if (parser.is("Microsoft Edge") && !parser.satisfies({edge: '>=79' })) {
  //     this.showNotSupportedModal({ ios: false });
  //     return;
  //   }

  //   if (!(environment.design.supportedBrowserList.some(browserName => parser.is(browserName)) && this.utilityService.checkOpentokRequirements())) {
  //     this.showNotSupportedModal({ ios: false });
  //     return;
  //   }
  //   this.showJoinModal(users);
  // }

  // showNotSupportedModal(dataModel: any) {
  //   const modalId = this.modalService.show({
  //     template: this.notSupportedTemplate,
  //     context: {
  //       dataModel: dataModel,
  //       callbacks: {
  //         close: () => this.modalService.hide(modalId)
  //       }
  //     }
  //   });
  // }

  // showJoinModal(users: string[]) {
  //   // @ts-ignore
  //   const AContext = window.AudioContext || window.webkitAudioContext;
  //   const model = {
  //     publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
  //     currentStream: null,
  //     audios: [],
  //     videos: [],
  //     selectedVideo: null,
  //     selectedAudio: null,
  //     audioEnabled: true,
  //     videoEnabled: false,
  //           //@ts-ignore
  //     audioContext: new AContext(),
  //     audioLevel: 0,
  //     audioLevelSub: null,
  //     settingsOn: false,
  //     selectedUsers: this.users.filter(u => this.selectedUsers[u.id] === true),
  //     status: "waiting",
  //     enableJoin: false,
  //     enableCancel: false
  //   };
  //   const modalId = this.modalService.show({
  //     template: this.joinTemplate,
  //     context: {
  //       dataModel: model,
  //       callbacks: {
  //         toggleAudio: () => this.opentokService.togglePreviewAudio(model),
  //         toggleVideo: () => this.opentokService.togglePreviewVideo(model),
  //         changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
  //         changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
  //         close: () => {
  //           this.opentokService.destroyPreview(model, this.videoElement);
  //           this.modalService.hide(modalId);
  //         },
  //         join: () => {
  //           this.opentokService.destroyPreview(model, this.videoElement);
  //           if (model.status === 'no-devices-found') {
  //             this.showNoDeviceModal(modalId, users);
  //           } else {
  //             this.modalService.hide(modalId);
  //             this.callUsers(users, false);
  //           }
  //         }
  //       }
  //     }
  //   });

  //   setTimeout(() => {
  //     this.opentokService.startPreview(model, this.videoElement);
  //   }, 1000);
  // }

  // showNoDeviceModal(joinModalId: number, users: any) {
  //   const modalId = this.modalService.show({
  //     template: this.noDeviceTemplate,
  //     context: {
  //       dataModel: null,
  //       callbacks: {
  //         cancel: () => {
  //           this.modalService.hide(modalId);
  //         },
  //         join: () => {
  //           this.modalService.hide(modalId);
  //           this.modalService.hide(joinModalId);
  //           this.callUsers(users, false);
  //         }
  //       }
  //     }
  //   });
  // }

  // callUsers(users: string[], archiveAllowed: boolean) {
  //   this.loaderService.show();
  //   this.callService.callUsers(users, archiveAllowed)
  //   .then(() => this.router.navigate(['/room']))
  //   .catch(error => {
  //     if (error.error === 'call-in-progress') {
  //       this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CALL_IN_PROGRESS', { timeout: 10000 });
  //     } else if (error.error === 'concurrent-limit-reached') {
  //       this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR', { timeout: 10000 });
  //     } else if (error.error === 'expert-concurrent-limit-reached') {
  //       this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_EXPERT_CONCURRENT_ERROR', { timeout: 10000 });
  //     } else if (error.error === 'archive-permission-needed') {
  //       this.showArchivePermissionModal(users);
  //     } else {
  //       this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_UNKNOwN_ERROR');
  //     }
  //   })
  //   .finally(() => this.loaderService.hide());
  // }

  // showArchivePermissionModal(users: string[]) {
  //   const modalId = this.modalService.show({
  //     template: this.archivePermissionModal,
  //     context: {
  //       dataModel: null,
  //       callbacks: {
  //         deny: () => this.modalService.hide(modalId),
  //         allow: () => {
  //           this.callUsers(users, true);
  //           this.modalService.hide(modalId);
  //         }
  //       }
  //     }
  //   });
  // }

  getStatusColor(webStatus: string): string {
    switch (webStatus) {
      case 'available':
        return 'status-circle status-online';
      case 'offline':
        return 'status-circle status-offline';
      case 'busy':
        return 'status-circle status-busy';
      case 'away':
        return 'status-circle status-away';
      default:
        return 'status-circle status-offline';
    }
  }
}
