import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-checkbox',
  template: `
    <div class="form-check w-100 text-start" style= "pointer-events:none">
        <div class="row">
          <ng-container *ngFor="let option of checkboxOptions; let i=index;">
            <div [ngClass]="{'col-md-6': checkboxOptions.length > 2, 'col-md-12': checkboxOptions.length <= 2}">
              <div class="custom-control custom-checkbox custom-checkbox-green" style="align-items: start;">
                <input
                type="checkbox"
                class="form-check-input"
                [id]="'input-'+option.value"
                [formControl]="formControl"
                [formlyAttributes]="field"
                [value]="option.value"
                />
                <label class="form-check-label ps-1" [for]="'input-'+option.value" style="overflow: auto; margin-top:2px;">
                {{ option.label }}
                </label>
              </div>
            </div>
          </ng-container>
        </div>
     </div>
  `,
  styles: ['.form-group label { display: none; }'],
})
export class FormlyFieldCustomCheckbox extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }

  get checkboxOptions(): any[] {
    return this.to.options as any[];
  }
}
