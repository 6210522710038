<div class="modal-dialog-alert" role="document" style="min-width: 750px;">
    <div class="modal-content v-card card modal-card" style="position:relative">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.NEW_WEBHOOK' | translate }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">
            </div>
        </div>
        <div class="modal-body d-flex flex-column" style="position: relative;">
            <form #newWebhookForm="ngForm" autocomplete="off" class="py-4">
                <div class="form-group d-flex align-items-center w-100 pl-0 mb-1">
                    <div class="fs-14 text-dark-grey me-2" style="width: 200px;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
                    <div class="fs-14 text-dark-grey" style="flex-grow: 1;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.URL' | translate }}</div>
                </div>
                <div class="form-group d-flex align-items-center w-100 pl-0 mb-2">
                    <select class="form-control fs-14 me-2" style="width: 200px; min-width: 200px;" [(ngModel)]="webhook.event" name="webhookEvent">
                        <option *ngFor="let event of ('design' | env).supportedEvents" [ngValue]="event">{{ event }}</option>
                    </select>
                    <input class="form-control fs-14" [(ngModel)]="webhook.url" style="flex-grow: 1;" type="text" name="webhookUrl" required
                        placeholder="{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.PLACEHOLDER' | translate }}">
                </div>
                <div class="form-group d-flex align-items-center w-100 pl-0 mb-1">
                    <div class="fs-14 text-dark-grey me-2">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.AUTH' | translate }}</div>
                </div>
                <div class="form-group d-flex align-items-center w-100 pl-0 mb-2">
                    <select class="form-control fs-14 me-2" [(ngModel)]="webhook.authType" name="authType">
                        <option *ngFor="let authModel of authModels" [ngValue]="authModel.type">{{ authModel.name }}</option>
                    </select>
                </div>
                <ng-container *ngIf="webhook.authType !== 'none'">
                    <div class="form-group d-flex align-items-center w-100 pl-0 mb-1">
                        <div class="fs-14 text-dark-grey me-2" style="width: 200px;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.KEY' | translate }}</div>
                        <div class="fs-14 text-dark-grey" style="flex-grow: 1;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.VALUE' | translate }}</div>
                    </div>
                    <div class="form-group d-flex align-items-center w-100 pl-0 mb-2" *ngIf="webhook.authType === 'token-on-body'">
                        <input class="form-control fs-14 me-2" [(ngModel)]="webhook.authKey" style="width: 200px; min-width: 200px;" type="text" name="authKey" required
                            placeholder="{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.KEY_PLACEHOLDER1' | translate }}">
                        <input class="form-control fs-14" [(ngModel)]="webhook.authValue" style="flex-grow: 1;" type="text" name="authValue" required
                            placeholder="{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.VALUE_PLACEHOLDER1' | translate }}">
                    </div>
                    <div class="form-group d-flex align-items-center w-100 pl-0 mb-2" *ngIf="webhook.authType === 'token-on-header'">
                        <input class="form-control fs-14 me-2" [(ngModel)]="webhook.authKey" style="width: 200px; min-width: 200px;" type="text" name="authKey" required
                            placeholder="{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.KEY_PLACEHOLDER2' | translate }}">
                        <input class="form-control fs-14" [(ngModel)]="webhook.authValue" style="flex-grow: 1;" type="text" name="authValue" required
                            placeholder="{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.VALUE_PLACEHOLDER2' | translate }}">
                    </div>
                </ng-container>

                <div class="form-group d-flex align-items-center w-100 pl-0">
                    <a class="fs-14 cursor-pointer" [ngClass]="{'text-primary': newWebhookForm.valid, 'text-secondary': !newWebhookForm.valid}" (click)="newWebhookForm.valid ? showExampleNotification(webhook) : showWarning()">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.SHOW_EXAMPLE' | translate }}</a>
                    <span *ngIf="showWarningVal" class="ms-2 fs-14 font-weight-bold text-danger">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.FORM_WARNING' | translate }}</span>
                </div>
            </form>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <div class="font-weight-bold fs-14">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.WARNING' | translate }}</div>

            <button type="button" class="v-btn btn btn-primary vsight-button" [disabled]="!newWebhookForm.valid" (click)="addNewWebhook()">
                {{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.NEW_WEBHOOK' | translate }}
            </button>
        </div>
    </div>
</div>