import { Pipe, PipeTransform } from "@angular/core";
import { UtilityService } from "@services/remote/utility.service";
import { AuthService } from "@services/auth.service";

@Pipe({name: 'utcCalcAccount'})
export class UtcCalcAccountPipe implements PipeTransform {

    constructor(private authService: AuthService ) {}

    transform(timestamp: number, appendTimezone?: boolean){
        return this.authService.getAccountData().then(resp => {
            const accountTimezone = resp.data.accountData.timezone
            const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[accountTimezone]);
            const formatted = `${("0"+d.getDate()).slice(-2)}.${("0"+(d.getMonth()+1)).slice(-2)}.${d.getFullYear()} ${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
            return appendTimezone ? `${formatted} ${accountTimezone}` : formatted;
        })
    }
}