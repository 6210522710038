<form class="w-100 h-100" *ngIf="workflowPublishedVersion">
    <table class="w-100 h-100">
        <thead style="background: #e1e1e1;" class="font-weight-400 fs-14">
            <th *ngFor="let column of workflowTableData?.columns[0].cells" style="font-weight: 500;
            font-size: 15px;">
                {{ workflowTranslations[selectedLang][column.templateOptions?.label] }}
            </th>
        </thead>
        <tbody style="border-width:none ;">
            <tr *ngFor="let row of workflowTableData?.rows; let rowIndex = index;" style="border: solid 1px;">
                <td *ngFor="let column of row.cells; let columnIndex = index;">
                   
                    <div *ngIf="tableData?.[column.key] && !column.templateOptions?.options" class="ps-1" [ngStyle]="{'min-width': column.type == 'datepicker' ? '125px' : ''}">
                        <label for="">{{tableData?.[column.key]}}</label>
                        <label *ngIf="column.type == 'number' && column.templateOptions?.['unit']" class="ms-2">{{column.templateOptions?.['unit']}}</label>
                    </div>
                    <div *ngIf="column.type == 'multicheckbox'">
                        <div *ngFor="let option of column.templateOptions?.options">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              [id]="'input-'+option.value"
                              [value]="option.value"
                              disabled
                              [checked]="tableData?.[column.key] ? tableData?.[column.key].includes(option.value) : false"
                            />
                            <label class="form-check-label" [for]="'input-'+option.value" *ngIf="workflowTranslations[selectedLang]?.[option.label]">
                            {{ workflowTranslations[selectedLang]?.[option.label] }}
                            </label>
                          </div>
                    </div>
                    <div *ngIf="column.type == 'radio'">
                        <div *ngFor="let option of column.templateOptions?.options">
                            <input
                              type="radio"
                              class="form-check-input"
                              [id]="'input-'+option.value"
                              [value]="option.value"
                              disabled
                              [checked]="tableData?.[column.key] ? tableData?.[column.key].includes(option.value) : false"
                            />
                            <label class="form-check-label" [for]="'input-'+option.value" *ngIf="workflowTranslations[selectedLang]?.[option.label]">
                            {{ workflowTranslations[selectedLang]?.[option.label] }}
                            </label>
                          </div>
                    </div>
                    <div *ngIf="!tableData?.[column.key] && column.type == 'label'">
                        <label for="">{{workflowTranslations[selectedLang]?.[column.templateOptions.label]}}</label>
                    </div>
                    <div *ngIf="!tableData?.[column.key] && (column.type == 'input' && column.type == 'number' && column.type == 'datepicker')">
                        <label for="" style="color: #A1A1A1;">N/A</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</form>