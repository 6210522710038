import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, forkJoin, lastValueFrom, map, Observable, of, Subscription } from 'rxjs';
import { WorkflowReport } from 'src/app/models/WorkflowReport';
import { ImageModalComponent } from '../../../modals/image-modal/image-modal.component';
import { DetailPageService } from 'src/app/services/detail-page.service';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowVersion } from '@models/Workflow';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { ReportService } from '@services/report.service';
import { environment } from 'src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { JsonViewerComponent } from 'src/app/components/modals/json-viewer/json-viewer.component';
import languagesJson from '../../../../../assets/languages.json';
import { User } from '@models/User';
import { WorkflowService } from '@services/workflow.service';
import * as bowser from 'bowser';
import { AppService } from '@services/support/app.service';
import { SignaturePadModalComponent } from 'src/app/components/modals/signature-pad-modal/signature-pad-modal.component';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { TableModalComponent } from 'src/app/components/modals/table-modal/table-modal.component';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { LogoList } from '@models/LogoList';


import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  @Input('report') report!: any
  @Input('workflowPublishedVersion') workflowPublishedVersion!: WorkflowVersion
  @Input() signatureReportData?
  @Input() users?
  @Input() linkId?
  @Input() accountInfo?
  @Input() showSubmittedBy?

  @Input() reportUser!: any
  @Input() step!: any
  @Input() key!: any
  @Input() index!: any

  closeModal : EventEmitter<any> = new EventEmitter

  subscriptions: Subscription[] = []
  stepList
  wfReportData: WorkflowReport;
  reportDuration
  link

  pdfPage = new UntypedFormControl()
  currentPdfPage = 1
  totalPages

  currentLang;
  allLangs = []
  workflowLangs = []
  selectedLang = 'en'
  showLangs: boolean = false
  langSub: Subscription = null

  selectedLangName
  file

  allUsers: User[] = []

  isMobile
  browser

  isAdmin

  tableData

  title = 'htmltopdf';

  logoList = LogoList
  
  @ViewChild('pdfTable') pdfTable: ElementRef;

  initialized = false

  isWhitelabel = false
  dropdownWidth
  accountName

  remoteCalls = {}
  remoteCallsLoading = false

  isEditorMode

  constructor(
    private modal: BsModalService,
    private detailPageService: DetailPageService,
    private loaderService: LoaderService,
    public multiLanguageService: MultilanguageService,
    private reportService: ReportService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private workflowService: WorkflowService,
    private appService: AppService,
    private authService: AuthService,
    private snackBarService: SnackbarService,
    private workflowLanguageService: WorkflowLanguageService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {
    this.selectedLang = this.workflowLanguageService.currentLang.code
    this.workflowLanguageService.onLangChange.subscribe(res => {
      this.selectedLang = res.lang.code
    })
   }
  ngOnDestroy(): void {
    this.closeModal.emit(true)
  }

  ngOnInit(): void {
    this.appService.isEditorMode.subscribe(res => {
      this.isEditorMode = res
    })

    if(this.accountInfo) {
      this.accountName = this.accountInfo
    }else{
      this.authService.getAccountData().then(account => {
        this.accountName = account.data.accountData.account_name
      })
    }

    this.dropdownWidth = '80%'
    this.isWhitelabel = (environment.design.shortName == 'ewers')
    this.loaderService.show()
    this.browser = bowser.getParser(window.navigator.userAgent);
    const isTouchDevice = 'ontouchstart' in window;

    if(this.browser.is('mobile') || this.browser.is('tablet') || (this.browser.is("macOS") && isTouchDevice)) {
      this.isMobile = true
    }
    if(this.isMobile) {
      this.appService.reportisOpenSource.next(true)
    }
    this.allLangs = languagesJson.languages
    this.wfReportData = this.report

    this.wfReportData.results.forEach(res => {

      if(this.workflowPublishedVersion.subWorkflows && Object.keys(this.workflowPublishedVersion.subWorkflows).length > 0) {
        Object.keys(this.workflowPublishedVersion.subWorkflows).forEach(wfId => {
          let initialSubWorkflowIndex = this.wfReportData.results.findIndex(result => this.workflowPublishedVersion.subWorkflows[wfId]['initialStep'] == result.stepId)
          if(initialSubWorkflowIndex>=0) {
            this.wfReportData.results[initialSubWorkflowIndex]['parentWorkflow'] = this.workflowPublishedVersion.subWorkflows[wfId].workflowName
          }

          if(this.workflowPublishedVersion.steps[res.stepId].data['parentWorkflow']) {
            res['parentWorkflowId'] = this.workflowPublishedVersion.steps[res.stepId].data['parentWorkflow']
          }
        })

      }

      // if(this.workflowPublishedVersion.steps[res.stepId].data['subWorkflow']) {
      //   let initialSubWorkflowIndex = this.wfReportData.results.findIndex(result => this.workflowPublishedVersion.steps[res.stepId].data['subWorkflow']?.initialStep == result.stepId)
      //   if(initialSubWorkflowIndex>=0) {
      //     this.wfReportData.results[initialSubWorkflowIndex]['parentWorkflow'] = this.workflowPublishedVersion.steps[res.stepId].data['subWorkflow'].workflowName
      //   }
      // }
      // if(this.workflowPublishedVersion.steps[res.stepId].data['parentWorkflow']) {
      //   res['parentWorkflowId'] = this.workflowPublishedVersion.steps[res.stepId].data['parentWorkflow']
      // }
    })

    if(this.workflowPublishedVersion?.translations) {
      Object.keys(this.workflowPublishedVersion.translations).forEach(res => {
        let workflowLang = this.allLangs.find(lang => lang.code == res)
        if(workflowLang) {
          this.workflowLangs.push(workflowLang)
        }
      })
      let defaultLang = this.workflowLangs.find(res => res.code == this.wfReportData.contentLanguage)
      this.selectedLangName = defaultLang ? defaultLang.name : null
      this.selectedLang = this.wfReportData.contentLanguage
    }
    this.reportDuration = this.convertMsToMinutesAndSecons(this.wfReportData.duration)
    if(this.wfReportData.results && this.wfReportData.results.length ) {
      this.wfReportData.results.forEach((reportData) => {
        if(!Object.keys(reportData.data).length){
          reportData.data = {isValid:true,labels:[]}
        }
        let duration = 0;
        reportData.durations.forEach(durationData => {
          if(durationData.duration) {
            duration += durationData.duration
          }
        })
        if (this.workflowPublishedVersion) {
          
          
          const stepData = this.workflowPublishedVersion?.steps?.[reportData.stepId]?.data;
          if(stepData) {
            let firstLayout = stepData.layouts[0]?.items[0].key
            let secondLayout = stepData.layouts[1]?.items[0].key
            let reportArray = []
            reportArray.push({
              key: firstLayout,
              data: reportData.data[firstLayout],
              index:0
            })
            if(secondLayout){
              reportArray.push({
                key: secondLayout,
                data: reportData.data[secondLayout],
                index:1
              })
            }
            reportData['reportArray'] = reportArray
            reportData['stepName']= stepData.name
            reportData['isSection']= stepData.isSection
            reportData['parentId']= stepData.parentId
            reportData['duration'] = duration && duration>0 ?  '~' + this.convertMsToMinutesAndSecons(duration) : 'Less than 1s';

            let htmlAvailable = stepData.layouts.find(res => res.items[0].type == 'html')

            if(htmlAvailable && this.workflowPublishedVersion['dataVersion'] >= 4) {
              stepData.layouts.forEach(res => {
                if(res.items[0].type == 'html' ) {
                  if(this.workflowPublishedVersion?.translations) {
                    this.mapRichTextWidget(res).then(rest => {
                        this.reportService.mappingReport(reportData, stepData)
                        this.initialized = true;
                        this.loaderService.hide()
                    })
                  }
                }
              })
            }else{
              this.reportService.mappingReport(reportData, stepData, this.workflowPublishedVersion)
              this.initialized = true;
              this.loaderService.hide()
            }
          }
        }
      });
    }
    this.setEmptyDataLabels()
    if(this.step && this.key) {
      let type = this.wfReportData.results[this.step].data[this.key].type
      let customType
      if(type == 'video' || type == 'captureVideo') {
        customType = 'video'
      }
      else if(type == 'pdf') {
        customType = 'pdf'
      }
      else{
        customType = 'image'
      }

      if(this.index) {
        if(this.wfReportData.results[this.step].data[this.key].type == 'gallery') {
          this.openMedia(this.wfReportData.results[this.step].data[this.key].value[this.index].imgUrl,customType)
        }else{
          this.openMedia(this.wfReportData.results[this.step].data[this.key].value[this.index].url,customType)
        }
      }else{
        if(type == 'ppe'){
          this.openMedia(this.wfReportData.results[this.step].data[this.key].value.link,customType)
        }else{
          this.openMedia(this.wfReportData.results[this.step].data[this.key].value,customType)
        }
      }
    }
    this.link = environment.endPoints.workflowBase
    // this.loaderService.hide()
    if (!environment.electron) {
      if(this.users?.length) {
        this.allUsers = this.users
      }else{
        // if(!this.reportUser) {
          this.workflowService.getAllUsersForWorkflow().then(result => {
            this.allUsers = result.users
          })
        // }
      }
    }

    if(this.wfReportData.remoteIntegration) {
      if(Object.keys(this.wfReportData.remoteIntegration).length) {
      this.remoteCallsLoading = true
        Object.keys(this.wfReportData.remoteIntegration).forEach(remoteId => {
          this.workflowService.workflowRemoteIntegrationInfo(remoteId).then(remoteCallInfo => {
            if(remoteCallInfo?.data?.session) {
              if(remoteCallInfo.data.session?.users?.length) {
                let guestUsers = []
                if(remoteCallInfo.data.session?.guest_users){
                  remoteCallInfo.data.session?.guest_users.forEach(user => {
                    user['isGuest'] = true
                    guestUsers.push(user)
                  })
                  remoteCallInfo.data.session['joinedUsers'] = remoteCallInfo.data.session?.users.concat(guestUsers)
                }else{
                  remoteCallInfo.data.session['joinedUsers'] = remoteCallInfo.data.session?.users
                }
              }
              remoteCallInfo.data.session['sessionDuration'] = this.convertMsToMinutesAndSecons(remoteCallInfo.data.session.concurrent_duration)
            }
            if(remoteCallInfo?.data) {
              const stepData = this.workflowPublishedVersion?.steps?.[this.wfReportData.remoteIntegration[remoteId].step]?.data;
              remoteCallInfo.data['stepName'] = stepData.name
              this.remoteCalls[remoteId] = remoteCallInfo.data
            }
            this.remoteCallsLoading = false
          })
          .catch(err => {
            this.remoteCallsLoading = false
          })
        })
      }
    }

    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin || user.editorAccess
    }))
    // this.loaderService.hide()
  }

  async mapRichTextWidget(layout) {
    let languages = Object.keys(this.workflowPublishedVersion?.translations)
    for (const lang of languages) {
      await lastValueFrom(this.getUrlRichTextWidget(layout,lang))
    }
  }

  public downloadPDF() {
    window.print()
  }

  getUrlRichTextWidget(layout, lang): Observable<boolean> {
    const requests = {};
    if (layout.items[0].type == 'html') {
      const uniqueKey = layout.items[0].templateOptions.html
      requests[uniqueKey] = this.getFileContentFromUrl(this.workflowPublishedVersion.translations[lang][uniqueKey])
    }

    return forkJoin(requests).pipe(
      map((results) => {
        Object.keys(results).forEach((key) => {
          this.workflowPublishedVersion.translations[lang][key] = results[key]
        })
        return true;
      }),
      catchError((error) => {
        return of(false)
      })
    )
  }

//   toPdf() {
//     const dashboard = document.getElementById('dashboard');

//     const dashboardHeight = 2500;
//     const dashboardWidth = dashboard.clientWidth;
//     const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

//     domtoimage.toPng(dashboard, options).then((imgData) => {
//          const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
//          const imgProps = doc.getImageProperties(imgData);
//          const pdfWidth = doc.internal.pageSize.getWidth();
//          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//          doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//          doc.text('My PDF Tutorial', 120, 20);
//          doc.save('Dashboard for hyperpanels.pdf');
//     });
    
// }

// onConfirm() {
//   let card = document.querySelector('.card') as HTMLElement;
//   const pages = document.querySelector('.report-wrapper') as HTMLElement;
//   this.exportAllToPDF(pages);
// }

// exportAllToPDF(pages: HTMLElement) {
//   const doc = new jsPDF({
//     unit: 'px',
//     format: [842, 1191]
  
//   });
//   let elementHTML = document.querySelector("report-wrapper") as HTMLElement;
 


//   doc.setDisplayMode('original', 'continuous', 'FullScreen')

//   doc.html(pages, {
//     callback: (doc: jsPDF) => {
//       doc.deletePage(doc.getNumberOfPages());
      


//       doc.save('pdf-export')
//       doc.cellAddPage()
//       console.log(doc, 'doc3')
//       console.log(doc.getPageInfo(2))

//     },
//     width:1000
//   });
// }



  print(){
    let popupWin = window.open('', '_blank', 'width=1080,height=595');
    popupWin.document.open();
    let printContents = document.body.innerHTML;
    let printHead = document.head.innerHTML;
    popupWin.document
      .write(`<html>
         ${printHead}
        <body onload="window.print();">${printContents}</body></html>`);
    popupWin.document.close();}

  public downloadAsPDF() {
  
  }

  showJsonSource(event, report) {
    event.stopPropagation()

    this.modalService.show(JsonViewerComponent, {
      initialState: {
        title: report.workflowName,
        json: report,
        expanded: false
      },
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false
    });
  }

  setEmptyDataLabels() {
    this.wfReportData.results.forEach(res => {
      const stepData = this.workflowPublishedVersion?.steps?.[res.stepId]?.data;
      if(res.data.isValid){
        let labelsForEmptyData = []
        stepData.layouts.forEach(item => {
          labelsForEmptyData.push(item.items[0].templateOptions.label)
        })
        res.data['labels'] = labelsForEmptyData
        res.reportArray.forEach(report => {
          let item = stepData.layouts.find(layout => layout.items[0].key == report.key)
          report['labels'] = item.items[0].templateOptions.label
        })
      }
    })
  }

  openMedia(url,type):void {
    const initialState= {
      url:url,
      type:type,
      close:this.closeModal
    };
    let imageModalRef = this.modal.show(ImageModalComponent,  {initialState, backdrop:'static', class: 'modal-lg modal-dialog-centered', animated:false});
    this.subscriptions.push(imageModalRef.content.onClose.subscribe((res) => {
      if (res) {
        imageModalRef.hide()
      }
    }))
  }

  openTable(tableData, workflowPublishedVersion) {
    const initialState= {
      close:this.closeModal,
      tableData,
      workflowPublishedVersion,
      workflowTranslations: this.workflowPublishedVersion?.translations,
      selectedLang: this.selectedLang
    };
    let tableModalRef = this.modal.show(TableModalComponent,  {initialState, backdrop:'static', class: 'modal-lg modal-dialog-centered', animated:false});
    this.subscriptions.push(tableModalRef.content.onClose.subscribe((res) => {
      if (res) {
        tableModalRef.hide()
      }
    }))
  }

  onClose():void {
    this.detailPageService.removeComponent()
  }

  convertMsToMinutesAndSecons(ms){
    let minutes = (ms / 1000) / 60;
    let seconds = (ms / 1000) % 60;
    if (minutes >= 1) {
      return  (Math.floor(minutes) + " m " + Math.ceil(seconds) + " s")
    } else {
      return  Math.ceil(seconds) + "s"
    }
  }

  isArray(val): boolean { return Array.isArray(val); }


  download() {
    let mappedReportData = JSON.parse(JSON.stringify(this.wfReportData))

    if(this.workflowPublishedVersion.translations){
      mappedReportData.workflowName = this.workflowPublishedVersion.translations[this.selectedLang][this.workflowPublishedVersion.name]
      mappedReportData.workflowDescription = this.workflowPublishedVersion.translations[this.selectedLang][this.workflowPublishedVersion.description]
      mappedReportData.results.forEach(result => {
        result.stepName = this.workflowPublishedVersion.translations[this.selectedLang][result.stepName]
        result.reportArray.forEach(report => {
          report.data.label = this.workflowPublishedVersion.translations[this.selectedLang][report.data.label]
          if(report.data.type == 'label') {
            report.data.value = this.workflowPublishedVersion.translations[this.selectedLang][report.data.value]
          }
          if(report.data.type == 'radio') {
            let radioVal 
            if(Array.isArray(report.data.value)) {
                radioVal = report.data.options.find(option => option.value == report.data.value[0])
            }else{
              radioVal = report.data.options.find(option => option.label == report.data.value)
            }
            if(radioVal) {
              report.data.value = this.workflowPublishedVersion.translations[this.selectedLang][radioVal.label]
            }
          }
          if(report.data.type == 'multicheckbox') {
            let multiVal = []
            report.data.values.forEach(res => {
              let val = report.data.options.filter(option => option.label == res)
              if(val.length) {
                multiVal.push(this.workflowPublishedVersion.translations[this.selectedLang][res])
              }
            })
            report.data.value = multiVal
          }
          if(report.data.type == 'html') {
            const regex = /[$~#]/;
            report.data.value = regex.test(report.data.html) ? this.workflowPublishedVersion.translations[this.selectedLang][report.data.html] : report.data['html']
          }
        })
      })
    }

    const submittedBy = this.wfReportData.guest?.email ? this.wfReportData.guest?.email : (this.reportUser ? this.reportUser : this.getUsersName(this.wfReportData.userId))
    
    const reportData = this.reportService.mapReportDataForCsvExport(mappedReportData,this.workflowPublishedVersion, submittedBy, this.wfReportData.id)
    this.reportService.download(reportData)
  }

  pageChanged(event) {
    this.pdfPage.patchValue(event)
    this.currentPdfPage = event
  }
  pdfNext() {
    this.pdfPage.patchValue(this.currentPdfPage+1)
    this.currentPdfPage +=  1
  }
  pdfPrevious() {
    if(this.currentPdfPage !==1) {
      this.pdfPage.patchValue(this.currentPdfPage-1)
      this.currentPdfPage -=  1
    }
  }

  pageSearch() {
    this.currentPdfPage = this.pdfPage.value
  }

  afterLoadComplete(pdf): void {
    this.totalPages = pdf.numPages;
  }

  getFileContentFromUrl(url: string) {
    return this.http.get(url, { responseType: 'text' });
  }

  byPassHTML(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
  changeCurrentLang(lang) {
    this.selectedLang = lang.code
    this.selectedLangName = lang.name
  }

  getUsersName(uid) {
    const user = this.allUsers.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }
  openSignatureModal(signer) {
    const initialState= {
     linkId: this.linkId,
     signer: signer
    };
    let confirmModalRef = this.modalService.show(SignaturePadModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {

      }
    }))
    this.subscriptions.push(confirmModalRef.content.signed.subscribe(res => {
      if(res) {
        let signerIndex = this.wfReportData.signature.signers.findIndex(matchSigner => matchSigner.email == confirmModalRef.content.matchedSigner.email )
        this.wfReportData.signature.signers[signerIndex] = confirmModalRef.content.matchedSigner
        this.wfReportData.signature.signers[signerIndex]['signedAt'] = new Date(this.wfReportData.signature.signers[signerIndex]['signedAt'])
        confirmModalRef.hide()
      }
    this.loaderService.hide()
    }))
  }

  sendRequest(signer) {
    this.loaderService.show()
    let baseUrl = environment.endPoints.workflowBase
    this.workflowService.sendWorkflowReportToSign(baseUrl, this.report.id, [signer.email]).then(res => {
      if(res) {
        window.alert(this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.SUCCESSFULLY-SENDED'))
        )
      }
    })
    .catch(error => {
      if(error.error == 'permission-denied') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.INVALID-TOKEN'))
      }
      if(error.error == 'missing-parameters') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.MISSING-PARAMETERS'))
      }
      if(error.error == 'invalid-parameters') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.USER-DETAIL.INVALID_EMAIL'))
      }
      if(error.error == 'report-not-found') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.REPORT-NOT-FOUND'))
      }
      if(error.error == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
      }
    })
    .finally(() => this.loaderService.hide() )
  }

}