import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Folder } from '@models/Folder';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-folder-list-modal',
  templateUrl: './folder-list-modal.component.html',
  styleUrls: ['./folder-list-modal.component.scss']
})
export class FolderListModalComponent implements OnInit {
  @Input() folders: Folder[]
  @Input() selectedFolderFromDashboard: Folder
  @Input() tabIndex?
  @Input() workflowId?

  filterFolder: UntypedFormControl = new UntypedFormControl()
  onClose: EventEmitter<any> = new EventEmitter();
  onSelectFolder: EventEmitter<any> = new EventEmitter();

  selectedFolder: string
  showDefault= true


  constructor(public modalRef: BsModalRef, private translateService: TranslateService) { }

  ngOnInit(): void {
    if(this.tabIndex == 0) {
      let workflowFolder = this.folders.find(folder => folder.workflows && folder.workflows.includes(this.workflowId))
      if(workflowFolder) {
        this.folders = this.folders.filter(res => res.name !== workflowFolder.name)
      }else{
        this.showDefault = false
      }
    }else{
      if(this.selectedFolderFromDashboard) {
        this.folders = this.folders.filter(res => res.name !== this.selectedFolderFromDashboard.name)
      }else{
        this.showDefault = false
      }
    }
  }

  stop(event) {
    event.stopPropagation()
  }

  close() {
    this.modalRef.hide()
  }

  selectFolder(folder, isDefault?) {
    this.selectedFolder = isDefault ? this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.NOT-FOLDERED') : folder.name
  }

  moveToFolder() {
    this.onSelectFolder.emit(this.selectedFolder)
    this.modalRef.hide()
  }

}
