<div #container class="image-annotation-container" (mousedown)="onContainerMouseDown($event)" (mouseup)="onContainerMouseUp($event)" (mouseleave)="onContainerMouseLeave($event)"
        [class.cursor-none]="pointerCollaborationActive">
    <div class="annotation-viewer" #viewer 
    (mouseup)="onViewerMouseUp($event)"
    (mouseleave)="onViewerMouseLeave($event)"
    (mouseenter)="onViewerMouseEnter($event)"
    (mousemove)="onViewerMouseMove($event)"
    (mousedown)="onViewerMouseDown($event)"
    (touchstart)="onDocumentTouchStart($event)"
    (touchmove)="onDocumentTouchMove($event)"
    (touchend)="onDocumentTouchEnd($event)">
        
        <div class="widget" [ngClass]="widget.class" *ngFor="let widget of widgets;" [ngStyle]="widget.style">
            <img style="width:100%; height: 100%; object-fit: contain" class="widgetImage" [attr.data-length]="widget.length" [attr.data-x]="widget.x" [attr.data-y]="widget.y" [src]="widget.src"/>
        </div>  
    </div>
    <div class="textContainer" [ngClass]="{'d-none': !showText}" #textContainer >
        <button type="button"  class="btn drag"
        (mousedown)="onTextMouseDown($event)"
        (mouseup)="onTextMouseUp($event)" 
        (mouseleave)="onTextMouseLeave($event)"
        (touchstart)="onTextTouchStart($event)"
        (touchmove)="onTextTouchMove($event)"
        (touchend)="onTextTouchEnd($event)"><i class="mi mi-drag-handle"></i></button>
        <input #textField [style.font-size]="font_size" style="font-family: 'Arial Narrow Bold', Helvetica, sans-serif" [style.color]="color" maxlength="1000" [value]="" class="text" type="text" (keyup)="onEnterPressed($event)">
        <button type="button" class="btn tick" (click)="textDone()" (touchend)="onTextTickButtonTouchEnd($event)"><i class="mi mi-done" ></i></button>
    </div>

    <div class="shapeContainer" [ngClass]="{'d-none': !onRect && !onEllipse && !onArrow && onWidget==null}" #shapeContainer
            (mousedown)="onShapeMouseDown($event)"
            (mouseup)="onShapeMouseUp($event)" 
            (mouseleave)="onShapeMouseLeave($event)"
            (touchstart)="onShapeTouchStart($event)"
            (touchmove)="onShapeTouchMove($event)"
            (touchend)="onShapeTouchEnd($event)">
        <img [ngClass]="tempWidgetClass?.class" style="object-fit: contain" class="tempWidget" [src]="tempWidgetClass?.src" #tempWidget/>

        <button type="button" [ngClass]="{'arrow': onArrow}" class="btn tick" (click)="shapeDone()"  (touchend)="onTickButtonTouchEnd($event)"><i class="mi mi-done" #tick></i></button>
        <button type="button" [ngClass]="{'arrow': onArrow}" class="btn resize"
            (mousedown)="onResizeButtonMouseDown($event)"
            (mouseup)="onResizeButtonMouseUp($event)" 
            (mouseleave)="onResizeButtonMouseLeave($event)"
            (touchstart)="onResizeButtonTouchStart($event)"
            (touchmove)="onResizeButtonTouchMove($event)"
            (touchend)="onResizeButtonTouchEnd($event)"></button>
    </div>
</div> 
