<div class="alert-card">
    <div class="card-header d-flex align-items-center justify-content-center">
        <p class="card-title mb-0 pb-0 fs-14 font-weight-600">{{selectedCell.type | translate}}</p>
        <img src="assets/img/close-modal.svg" class="close-modal" (click)="cancel()">
    </div>
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-center">
            <input type="text" class="form-control" *ngIf="selectedCell.type =='input'" [formControl]="cellFormControl">
            <!-- <input type="number" class="form-control" *ngIf="selectedCell.type =='number'" [formControl]="cellFormControl"> -->
            <div class="input-group me-1 ms-1" style="height:36px;min-width: 125px" *ngIf="selectedCell.type=='datepicker'">
                <input *ngIf="selectedCell.type =='datepicker'" [formControl]="cellFormControl" style="height: 40px;min-width: 255px;"
                #startDate="bsDatepicker" name="startDate"
                class="form-control custom-form-control border-right-none" id="form-control" bsDatepicker autocomplete="off"
                placeholder="{{selectedCell.templateOptions.placeholder}}"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }"
                onkeypress="return false;">
                <span class="input-group-text date-icon fs-12 font-weight-600" id="basic-addon2" (click)="clear()">{{ 'WEB-VERSION.CLEAR' | translate }}</span>
            </div>
            <!-- <timepicker formControlName="estimatedDuration" [showMeridian]="false" [disabled]="type=='published'"></timepicker> -->

            
            <div class="flex-column" style="position:relative;display: flex;" *ngIf="selectedCell.type=='number'" [formGroup]="cellControl">
                <div class="d-flex justify-content-center align-items-center mt-4">
                    <input type="number" onkeydown="return event.keyCode !== 69" formControlName="numberFirst"  class="form-control number" id="form-control"  placeholder="{{selectedCell.templateOptions.placeholder}}" 
                    autocomplete="off" description="xx" [ngStyle]="selectedCell.templateOptions['precision'] && {'min-width':'70px'}">
                    <span style="margin-left:6px;margin-right:6px;padding-top: 29px;" class="label-preview" *ngIf="selectedCell.templateOptions['precision']!== null && selectedCell.templateOptions['precision']">.</span>
                    <input type="number" onkeydown="return event.keyCode !== 69" formControlName="numberSecond"  class="form-control number" id="form-control" 
                    autocomplete="off" *ngIf="selectedCell.templateOptions['precision']!== null && selectedCell.templateOptions['precision']" style="min-width: 70px;">
                    <span style="margin-left:2px;width: auto;" class="label-preview" *ngIf="selectedCell.templateOptions['unit']!== null && selectedCell.templateOptions['unit']">{{selectedCell.templateOptions['unit']}}</span>
                </div>
                <p *ngIf="cellControl.controls.numberOutput?.errors?.min && cellControl.touched" class="validation-text text-start pb-0">{{ 'MODALS.TABLE-CELL.AT-LEAST' | translate:{min:selectedCell.templateOptions['min']} }}</p>
                <p *ngIf="cellControl.controls.numberOutput?.errors?.max && cellControl.touched" class="validation-text text-start pb-0">{{ 'MODALS.TABLE-CELL.AT-MOST' | translate:{max:selectedCell.templateOptions['max']} }}</p>
            </div>
            <div class="d-flex align-items-start" *ngIf="selectedCell.type=='number'" style="position: absolute;left:10px; bottom:55px">
                <label *ngIf="selectedCell.templateOptions.min || selectedCell.templateOptions.min == 0" class="label-preview-title fs-12 pb-1">Min : {{selectedCell.templateOptions.min}}</label>
                <label *ngIf="selectedCell.templateOptions.max || selectedCell.templateOptions.max == 0" class="label-preview-title fs-12 ps-2 pb-1">Max : {{selectedCell.templateOptions.max}}</label>
            </div>

            <div class="form-check text-start"   *ngIf="selectedCell.type =='multicheckbox'">
                <div [formGroup]="group" *ngFor="let group of cellControl.controls">
                    <input type="checkbox" class="form-check-input" [id]="'input-'+group.value.option"
                        [value]="group.value.option" formControlName="value"/>
                    <label class="form-check-label" [for]="'input-'+group.value.option"
                        *ngIf="group.value.option">
                        {{ group.value.option }}
                    </label>
                </div>
            </div>
            <div class="form-check text-start" *ngIf="selectedCell.type =='radio'">
                <div *ngFor="let option of selectedCell.templateOptions?.options">
                    <input type="radio" class="form-check-input" [id]="'input-'+option.value"
                        [value]="option.value" [formControl]="cellFormControl"/>
                    <label class="form-check-label" [for]="'input-'+option.value"
                        *ngIf="option.label">
                        {{ option.label }}
                    </label>
                </div>
            </div>

        </div>
    </div>
    <div class="d-flex justify-content-center alert-footer">
        <button class="v-btn btn btn-secondary me-2 mt-2 mb-2 fs-14 font-weight-500 cancel-btn" (click)="cancel()">{{ 'WEB-VERSION.CANCEL' | translate }}</button>
        <button class="v-btn btn btn-primary fs-14 text-white mt-2 mb-2 font-weight-500 save-cell-btn" (click)="saveCell()" [disabled]="!cellControl?.valid">{{ 'WEB-VERSION.SAVE' | translate }}</button>
    </div>
    
    
</div>