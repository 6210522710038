import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorMessageComponent } from 'src/app/models/field-templates/error-message.component';
import { SuccessMessageComponent } from 'src/app/models/field-templates/success-message-component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) { }

  success(message){
  this._snackBar.openFromComponent( SuccessMessageComponent,{
    data: message,
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
    panelClass: ["custom-style","success"],
    duration:5000
  })
  }
  
  error(message){
  this._snackBar.openFromComponent( ErrorMessageComponent,{
    data: message,
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
    panelClass: ["custom-style","error"],
    duration:5000
  })
  }
 
}
