<div class="d-flex guest-container">
    <div class="col-7">
        <!-- <div class="guest-wrapper  d-flex flex-column justify-content-center h-100">
            <div class="mb-3 d-flex">
                <input type="text" class="form-control me-2" [formControl]="approverEmail">
                <button class="v-btn btn-sm btn btn-primary" style="min-width: 80px;border-radius:10px;" (click)="addNewApprover()">Add</button>
            </div>
            <ng-container *ngFor="let approver of approvers">
                <div class="approver-card mb-1">
                    <label>{{approver}}</label>
                </div>
            </ng-container>
        </div> -->

        <div class="guest-wrapper-with-qr">
            <label class="fs-14 font-weight-600 mb-2">Approver Email</label>
            <div class="d-flex mb-5">
                <input type="text" class="form-control guest fs-14" style="width: 400px;height: 42px;" [formControl]="approverEmail" >
                <button class="v-btn btn btn-primary fs-12 font-weight-600 text-white add-button ms-2" (click)="addNewApprover()">Add</button>
            </div>
            
            <hr>

            <div class="d-flex flex-column approver-list">
                <label class="fs-14 font-weight-600 mb-2">Approvers</label>
                <ng-container *ngFor="let approver of approvers; let index=index">
                    <div class="d-flex align-items-center">
                        <div class="approver-card mb-2 me-3" *ngIf="selectedStepIndex !== index">
                            <label class="fs-14">{{approver}}</label>
                        </div>
                        <div *ngIf="selectedStepIndex == index" class="mb-2 me-3">
                            <input [inputSelectOnCreate]="true" type="text" class="title-input fs-14" [formControl]="approverEditEmail" (keyup.enter)="update(approver, index)" (blur)="update(approver, index)">
                        </div>
    
                        <div class="d-flex mb-2" *ngIf="selectedStepIndex !== index">
                            <div class="cursor-pointer" (click)="edit(index, approver)">
                                <img src="assets/img/edit_image.svg" alt="" width="20px" height="25px" class="me-3">
                            </div>
                            <div class="cursor-pointer" (click)="deleteApprover(index)">
                                <img src="assets/img/delete_image.svg" alt="" width="20px" height="25px">
                            </div>
                            
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="preview-part col-5">
        <div class="d-flex flex-column">
            <div class="col-6 d-flex align-items-center justify-content-center w-100">
            </div>
        </div>
        <div class="preview-part-box">
            
        </div>
    </div>
</div>