import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-video',
  template: `
  <div style="position: absolute;top:0;bottom:0;left:0;right:0;padding: 10px; padding-top:25px" [ngClass]="{'image-with-label': to.label}" *ngIf="to['videoUrl']">
      <video [src]="to['videoUrl'] | safeHtml" *ngIf="to['videoUrl']" controls class="form-video"></video>
  </div>
  `,
})
export class FormlyFieldCustomVideo extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}