<div class="login-wrapper d-flex justify-content-around">
    <div class="background-container"></div>
    <div class="left-side">
        <div class="graphic-content">
            <div class="background-logo"></div>
            <div class="actual-logo">
                <img class="logo-img" src="assets/img/logo_white.svg" alt="">
                <div class="tagline text-white mt-3 font-weight-600">Let's get you connected</div>    
            </div>
        </div>
    </div>
    <div class="right-side">
        <div class="login-content">
            <div class="card align-items-center pt-5">
                <img class="logo mb-4" style="height: 48px;" src="assets/img/logo.svg" alt="">

                <p class="fs-16 font-weight-600 mb-4">Login to VSight App</p>
    
                <form #loginForm="ngForm" id="loginForm" (ngSubmit)="onLogin(loginForm)" class="w-100">
    
                    <input type="text" name="account" #loginAccount="ngModel" [(ngModel)]="loginUser.account" class="form-control fs-14" placeholder="{{ 'LOGIN.ACCOUNT-PLACEHOLDER' | translate }}" minlength="2" required>
                    <p class="validation-text mb-0 mt-1 w-100 ps-2" [hidden]="!(loginAccount.errors && loginAccount.touched ? loginAccount.errors['required'] : false)">{{ 'LOGIN.ACCOUNT-REQUIRED' | translate }}</p>
                    <p class="validation-text mb-0 mt-1 w-100 ps-2" [hidden]="!(loginAccount.errors && loginAccount.touched ? loginAccount.errors['minlength'] : false)">{{ 'LOGIN.ACCOUNT-SHORT' | translate }}</p>
    
                    <input type="text" name="username" #loginUsername="ngModel" [(ngModel)]="loginUser.username" class="form-control fs-14 mt-3" placeholder="{{ 'LOGIN.USERNAME-PLACEHOLDER' | translate }}" minlength="2" required>
                    <p class="validation-text mb-0 mt-1 w-100 ps-2" [hidden]="!(loginUsername.errors && loginUsername.touched ? loginUsername.errors['required'] : false)">{{ 'LOGIN.USERNAME-REQUIRED' | translate }}</p>
                    <p class="validation-text mb-0 mt-1 w-100 ps-2" [hidden]="!(loginUsername.errors && loginUsername.touched ? loginUsername.errors['minlength'] : false)">{{ 'LOGIN.USERNAME-SHORT' | translate }}</p>
    
                    <input type="password" name="password" #loginPassword="ngModel" [(ngModel)]="loginUser.password" class="form-control fs-14 mt-3" placeholder="{{ 'LOGIN.PASSWORD-PLACEHOLDER' | translate }}" minlength="6" required>
                    <p class="validation-text mb-0 mt-1 w-100 ps-2" [hidden]="!(loginPassword.errors && loginPassword.touched ? loginPassword.errors['required'] : false)">{{ 'LOGIN.PASSWORD-REQUIRED' | translate }}</p>
                    <p class="validation-text mb-0 mt-1 w-100 ps-2" [hidden]="!(loginPassword.errors && loginPassword.touched ? loginPassword.errors['minlength'] : false)">{{ 'LOGIN.PASSWORD-SHORT' | translate }}</p>
    
                    <div class="form-group d-flex align-items-center mt-3">
                        <input type="checkbox" id="remember" name="remember"  [(ngModel)]="accountNameRemeber" class="form-check-input mt-0 me-2">
                        <label for="remember" class="form-check-label fs-14 font-weight-600 text-dark-grey">Remember account name</label>
                    </div>

                    <div class="form-group d-flex align-items-center mt-2">
                        <input type="checkbox" id="chinaserver" name="chinaserver" [(ngModel)]="useChinaServer" (ngModelChange)="chinaServerChange($event)" class="form-check-input mt-0 me-2">
                        <label for="chinaserver" class="form-check-label fs-14 font-weight-600 text-dark-grey">I'm logging in from China</label>
                    </div>
    
                    <div class="w-100"></div>
                    <button type="submit" class="v-btn btn btn-primary fs-15 font-weight-600 text-white open-button mt-3 mb-4" [disabled]="!loginForm.valid">{{ 'LOGIN.LOGIN-BUTTON' | translate }}</button>    
                </form>
    
                <div class="fs-12 font-weight-500 w-100 mb-4" style="max-width: 390px;">By clicking Open, you agree to our 
                    <span class="text-blue font-weight-600">Terms of Service</span> and 
                    <span class="text-blue font-weight-600">Privacy Policy</span>
                </div>
            </div>
            <div class="addition-part mt-3 d-flex justify-content-center w-100">
                <div class="language-dropdown" dropdown [dropup]="true" #dropdown="bs-dropdown">
                    <div id="language-toggle" class="language-link d-flex flex-row align-items-center cursor-pointer" dropdownToggle aria-controls="language-menu">
                        <span class="name fs-14 font-weight-500 lang ms-2 color-blue-text">{{ currentLang.code | uppercase }}</span>
                        <i id="dropdown-icon" class="mi mi-keyboard-arrow-down lang color-blue-text"></i>
                    </div>
                    <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                        <ng-container *ngFor="let lang of allLangs">
                            <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                <a class="dropdown-item language-link px-2 fs-14 font-weight-500" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
    
            </div>
        </div>
    </div>
</div>