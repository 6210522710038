import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { WorkflowService } from 'src/app/services/workflow.service';
import { LoaderService } from '@services/support/loader.service';
import { environment } from 'src/environments/environment';
import { DbService } from '@services/db.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@services/support/snackbar.service';


@Component({
  selector: 'app-workflow-detail',
  templateUrl: './workflow-detail.component.html',
  styleUrls: ['./workflow-detail.component.scss']
})
export class WorkflowDetailComponent implements OnInit, OnDestroy,OnChanges {

  @Input('type') type!: any
  @Input() workflowId!: any


  @Input('workflowDetail') workflowDetail!: any
  @Output() workflowDetailChange: EventEmitter<any> = new EventEmitter();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter();

  @Input() connectionEnabled: boolean

  workflowDetailForm = new UntypedFormGroup ({
    name: new UntypedFormControl({value:'',disabled: this.type=='published'}, Validators.required),
    description: new UntypedFormControl({value:'',disabled: this.type=='published'}),
    users: new UntypedFormControl([]),
    estimatedDuration: new UntypedFormControl(new Date()),
    labels : new UntypedFormControl(),
    showContentPage: new UntypedFormControl(),
    files: new UntypedFormControl(),
    detailExtraFields : new UntypedFormControl([]),
    expertsToCall : new UntypedFormControl(),
  })

  labels = [];
  loading = false;
  allLabels  = [];

  tagName = new UntypedFormControl()

  usersLoading: boolean = true
  tagArray = []
  valueChangeSub: Subscription;

  showDynamicFieldTypes = false

  dynamicFieldTypes = [{value:'input',title:'Input'}, {value:'datepicker',title:'Datepicker'}, {value:'multicheckbox',title:'Multicheckbox'}]
  notSelected = 'Not Selected'

  dynamicField = new UntypedFormControl('',Validators.required)
  dynamicFieldName = new UntypedFormControl('',Validators.required)

  form = new UntypedFormGroup({})
  model: any = {}

  options: FormlyFormOptions = {
    formState: {
      disabled: true
    }
  };

  fields: FormlyFieldConfig[] = [];

  isUpload
  isLoading

  subscriptions: Subscription[] = []
  files = []

  isConnectionEnabled = false

  expertList = []
  selectedExpertList = []
  expertLoading = true
  isRemoteCallAvailable
  isDisabled

  constructor(
    private dbService: DbService,
    private workflowService: WorkflowService,
    private modalService: BsModalService,
    public loaderService: LoaderService,
    private translateService: TranslateService,
    private authService: AuthService,
    private snackBarService: SnackbarService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['type']){
      if(this.type=='published'){
        this.workflowDetailForm.disable()
        this.isDisabled = true
      }else if(this.type=='draft'){
        this.workflowDetailForm.enable()
        this.isDisabled = false
      }
    }
    if(changes['connectionEnabled']) {
      if(changes['connectionEnabled'].currentValue) {
        this.isConnectionEnabled = true
        this.workflowDetailForm.controls['showContentPage'].patchValue(false)
        this.workflowDetailForm.controls['showContentPage'].disable()
      }
    }
    if(changes['workflowDetail']) {
      if(changes['workflowDetail'].currentValue && !changes['workflowDetail'].previousValue){
        this.selectedExpertList = []
        
        const today = new Date()
        if(changes['workflowDetail'].currentValue.estimatedDuration) {
          today.setHours(this.workflowDetail?.estimatedDuration.hours ? this.workflowDetail.estimatedDuration.hours : 0)
          today.setMinutes(this.workflowDetail?.estimatedDuration.minutes ? this.workflowDetail.estimatedDuration.minutes : 0)
        }
        this.files = this.workflowDetail?.files ? this.workflowDetail.files : []
        this.workflowDetailForm.patchValue({
          name : changes['workflowDetail'].currentValue.name,
          description : changes['workflowDetail'].currentValue.description,
          labels : changes['workflowDetail'].currentValue.labels,
          estimatedDuration: changes['workflowDetail'].currentValue.estimatedDuration ? today : 0 ,
          showContentPage: changes['workflowDetail'].currentValue.showContentPage,
          files: changes['workflowDetail'].currentValue.files,
          detailExtraFields: changes['workflowDetail'].currentValue.detailExtraFields,
          expertsToCall : changes['workflowDetail'].currentValue.expertsToCall,

        })
        this.unMap()
      }
    }
  }

  ngOnInit(): void {
    this.authService.getAccountData().then(account => {
      this.isRemoteCallAvailable = account.data.accountData.add_ons?.workflowremoteintegration
    })
    this.getExpertUsers()
    this.workflowService.getWorkflowAccountExtraDetails().then(detailFields => {
      this.fields = detailFields
    })
    this.unMap()

    this.workflowService.getWorkflowAccountLabels().then(workflowLabels => {
      this.allLabels = workflowLabels
    })
    const today = new Date()
    if(this.workflowDetail?.estimatedDuration) {
      today.setHours(this.workflowDetail?.estimatedDuration.hours ? this.workflowDetail.estimatedDuration.hours : 0)
      today.setMinutes(this.workflowDetail?.estimatedDuration.minutes ? this.workflowDetail.estimatedDuration.minutes : 0)
    }

    if(this.workflowDetail) {
      this.files = this.workflowDetail?.files ? this.workflowDetail.files : []
      this.selectedExpertList = this.workflowDetail?.expertsToCall ? this.workflowDetail.expertsToCall : []

      this.workflowDetailForm.patchValue({
        name : this.workflowDetail?.name,
        description : this.workflowDetail?.description,
        users: this.workflowDetail?.users,
        labels: this.workflowDetail?.labels ? this.workflowDetail.labels : [],
        estimatedDuration: this.workflowDetail?.estimatedDuration ? today : 0,
        showContentPage: this.workflowDetail?.showContentPage ? true : false,
        files: this.workflowDetail?.files ? this.workflowDetail.files : [],
        detailExtraFields: this.workflowDetail?.detailExtraFields ? this.workflowDetail.detailExtraFields : [],
        expertsToCall: this.workflowDetail?.expertsToCall ? this.workflowDetail.expertsToCall : [],
      })
    }
    
    
    let newData

    this.valueChangeSub = this.workflowDetailForm.valueChanges.subscribe(data => {
      
      newData = data
      let duration = {hours:0,minutes:0}
      duration.hours = data.estimatedDuration ? data.estimatedDuration.getHours() : 0
      duration.minutes = data.estimatedDuration ? data.estimatedDuration.getMinutes() : 0

      newData.estimatedDuration = duration

      if(this.form.dirty || this.workflowDetailForm.dirty) {
        this.workflowDetailChange.emit(newData)
      }
      this.formValid.emit(this.workflowDetailForm.valid)
    })

    this.form.valueChanges.subscribe(res => {
      let valueDynamic = []
      this.fields.forEach(field => {
        
        let fieldKey = Object.keys(this.model).find(res => res == field.key)
          let options = {
            templateOptions : {
              label: field.templateOptions.label,
              value: fieldKey ? this.model[fieldKey] : "",
            },
            type: field.type,
            key: field.key,
            detail: true,
          }
          valueDynamic.push(options)
      })
      this.workflowDetailForm.controls['detailExtraFields'].patchValue(valueDynamic)
    })
  }

  ngOnDestroy(): void {
    if (this.valueChangeSub) { this.valueChangeSub.unsubscribe() }
  }

  unMap() {
    if(this.workflowDetail?.detailExtraFields) {
      this.workflowDetail.detailExtraFields.forEach((res:any) => {
        let item = this.fields.find(field => field.key == res.key)
        if(!item) {
          this.fields.push({
            key: res.key,
            type: res.type,
            templateOptions: {
              type:res.type,
              placeholder: '',
              required: false,
              label:res.label,
              detail:true,
              disabled:false
            },
            })
  
        }
        this.model[res.key] = res.templateOptions.value
      })
      this.fields = [...this.fields]
    }
  }


  addTagPromise(name) {
    this.loading = true
    return this.workflowService.setWorkflowAccountLabels([...this.allLabels, name]).then(() => {
      this.allLabels.push(name);
      this.loading = false
      return name;
    })
  }

  dragDropUpload(event) {
    if(this.type !== 'published') {
      if (!environment.electron) {
        this.isUpload = true
        let selectedFile =  event
        this.previewUpload(selectedFile)  
      }
    }
  }

  deleteFile(index) {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.DELETE-FILE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-FILE')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    const sub = alertmodalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.files.splice(index,1)
        this.workflowDetailForm.controls['files'].patchValue(this.files)
      }
    })
  }

  chooseButtonUpload( event: any): void {
    this.isUpload = true
    let selectedFile =  event.target.files
    this.previewUpload(selectedFile)
  }

  chooseAndCopyWorkflowFile() {
    this.isLoading = true
    this.loaderService.show()
    this.dbService.chooseAndCopyWorkflowFile(this.workflowId, "pdf")
    .then(result => {
      this.files.push({ name: result[0], url: result[1] })
      this.workflowDetailForm.controls['files'].patchValue(this.files)
    })
    .finally(() => {
      this.isLoading = false
      this.loaderService.hide()
    })
  }

  chooseFolderAndSaveFile(file) {
    this.dbService.chooseFolderAndSaveFile(file.name, file.url)
  }

  private previewUpload(selectedFile: File): void {
    if (selectedFile) {
      this.isLoading = true;
      this.loaderService.show()
      this.subscriptions.push(this.uploadfile(selectedFile).subscribe({
        next: (resp) => {
          if(resp){
            this.isUpload = false
            let image
              image = []
              resp['data'].forEach(imageObject => {
                this.files.push({name:imageObject.fieldname,url:imageObject.url})
              })
              this.workflowDetailForm.controls['files'].patchValue(this.files)
          }
          this.isLoading = false;
          this.loaderService.hide()
        },
        error: (err) => {
          this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR'))
          this.isLoading = false;
          this.loaderService.hide()
        }
      })
      )
    }
  }

  uploadfile(file) {
    let formData = new FormData();
    
      if(file.length){
        for (let i = 0; i < file.length; i++) {
          formData.append(file[i].name, file[i])
        }
      }else{
        formData.append(file.name, file)
      }

      return this.workflowService.uploadWorkflowFiles(this.workflowId, formData)
    
  }

  getExpertUsers() {
    let Users = []
    let availability = true
    this.workflowService.getAllUsersForWorkflow().then(res => {
      const users = res.users.filter(user => user.auth && !user.auth.deleted)
      
      this.expertList = users.filter(user => user.role == 'expert')
      this.expertLoading = false
      this.workflowDetail?.expertsToCall.forEach(response => {
        if(!response.id) {
          availability = false
        }
        let findedExpert = this.expertList.find( expert => expert.id == response)
        if(findedExpert) {
          Users.push(findedExpert)
        }
      })
      if(!availability) {
        this.workflowDetailForm.controls['expertsToCall'].patchValue(Users)
      }

      this.loaderService.hide()
    })
  }

  addExpert(expert) {
    let expertObject = { 
      name : expert.name,
      email : expert.email,
      id : expert.id
    }
    this.selectedExpertList.push(expertObject)
    this.workflowDetailForm.controls['expertsToCall'].patchValue(this.selectedExpertList)

  }

  uploadedFilesChange(event) {
    if(event) {
      this.isUpload = false
      let image
        image = []
        event.forEach(imageObject => {
          this.files.push({name:imageObject.fieldname,url:imageObject.url})
        })
        this.workflowDetailForm.controls['files'].patchValue(this.files)
    }
  }

}
