<div class="d-flex flex-column h-100"  [formGroup]="numberGroup">
    <label class="label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="d-flex flex-column justify-content-center" style="height: 100%;">
        <div class="d-flex justify-content-center">
            <input type="number" onkeydown="return event.keyCode !== 69"  formControlName="numberFirst" class="form-control number custom-form-control" id="form-control"  placeholder="{{item.templateOptions.placeholder}}" 
            autocomplete="off" description="xx">
            <span style="margin-left:6px;margin-right:6px;padding-top: 29px;" class="label-preview" *ngIf="item.templateOptions['precision']!== null && item.templateOptions['precision']">.</span>
            <input type="number" onkeydown="return event.keyCode !== 69"  formControlName="numberSecond" maxlength="2" class="form-control number custom-form-control"
            autocomplete="off" *ngIf="item.templateOptions['precision']!== null && item.templateOptions['precision']" style="margin-left:2px;">
            <span style="margin-left:2px;padding-top: 13px;" class="label-preview label-form" *ngIf="item.templateOptions['unit']!== null && item.templateOptions['unit']">{{item.templateOptions['unit']}}</span>
        </div>
        <p *ngIf="numberGroup.controls.numberOutput?.errors?.required && numberGroup.touched" class="validation-text text-center pb-0">{{ 'MAIN.CONTENT.WORKFLOW.REQUIRED' | translate }}</p>
        <p *ngIf="numberGroup.controls.numberOutput?.errors?.min && numberGroup.touched" class="validation-text text-center pb-0">This field should be at least {{item.templateOptions.min}}</p>
        <p *ngIf="numberGroup.controls.numberOutput?.errors?.max && numberGroup.touched" class="validation-text text-center pb-0">This field should be at most {{item.templateOptions.max}}</p>
    </div>
   
    <div style="position: absolute;bottom:2px;left:0;right:0;display:flex">
          <label *ngIf="item.templateOptions.min || item.templateOptions.min == 0" class="label-preview-title pb-3 ps-4">Min : {{item.templateOptions.min}}</label>
          <label *ngIf="item.templateOptions.max || item.templateOptions.max == 0" class="label-preview-title ps-2 pb-3">Max : {{item.templateOptions.max}}</label>
    </div>
</div>