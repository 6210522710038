import { environment } from "src/environments/environment";

export function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.5)';
    }
    throw new Error('Bad Hex');
  }
  
  export const whiteLabelStylesInitializer = (): (() => void) => {
    return () => {
      const styleElement = document.createElement('style') as HTMLStyleElement;
      document.head.appendChild(styleElement);
  
      const styleSheet: CSSStyleSheet = styleElement.sheet;
      styleSheet.insertRule(`.v-btn{  &.btn-primary {
          background-color: ${environment.design.primaryColor} !important;
          border-color: ${environment.design.primaryColor} !important;
          color: white !important;
          &:focus {
      
            box-shadow: ${hexToRgbA(environment.design.primaryColor)} !important;
        }
        &:hover {
          background-color: ${environment.design.primaryColorLight} !important;
          border-color: ${environment.design.primaryColorLight} !important;
        }
        }}
      `);
      styleSheet.insertRule(`
      .btn-check:focus + .btn-primary, .btn-primary:focus {
        color: #fff;
        background-color: #0b5ed7;
        border-color: #0a58ca;
        box-shadow: 0 0 0 0.25rem ${hexToRgbA(environment.design.primaryColor)};
    }
    `);
      styleSheet.insertRule(`
      .form-check-input:checked[type=checkbox] {
        background-color: ${environment.design.primaryColor} !important;
        border-color: ${environment.design.primaryColor} !important;
    }
    `);
    styleSheet.insertRule(`
      .form-check-input:checked[type=radio] {
        background-color: ${environment.design.primaryColor} !important;
        border-color: ${environment.design.primaryColor} !important;
    }
    `);
    styleSheet.insertRule(`
    .form-check-input {
      border: 1px solid rgba(0, 0, 0, 0.25); !important;
  }
    `);
    styleSheet.insertRule(`
    .sidebar-container {
      background-color: ${environment.design.sidebarColor} !important;
        .active{
          .sidebar-link {
            background-color: ${environment.design.primaryColorLight} !important;
          }
        }
        .sidebar-item {
          &:hover {
            .sidebar-link {
              background-color: ${environment.design.primaryColorLight} !important;
            }
          }
        }
    }
    `);
    styleSheet.insertRule(`
    .tab-item{
      &.selected-tab {
        color: ${environment.design.sidebarColor} !important;
        border-bottom: solid 2px ${environment.design.sidebarColor} !important;
      }
      &:hover {
        color: ${environment.design.primaryColorLight} !important;
        border-bottom: solid 2px ${environment.design.primaryColorLight} !important; 
      }
      
    }
    
    `);
    styleSheet.insertRule(`
    .workflow-sidebar {
      background-color: ${environment.design.sidebarColor} !important;
        .tab {
          &:hover {
            background-color: ${environment.design.primaryColorLight} !important;
          }
          &.active {
            background-color: ${environment.design.primaryColorLight} !important;
          }
        }
    }
    `);
    styleSheet.insertRule(`
    .container
    {
      .switch {
        border-bottom: solid 1px ${environment.design.primaryColorLight} !important;
      }
    }
    `);
    styleSheet.insertRule(`
      .profile-wrapper {
        &:hover {
          background-color: ${environment.design.primaryColor} !important;
        }
      }
    `);
    styleSheet.insertRule(`
      .step-card.selected {
        border: solid 2px ${environment.design.sidebarColor} !important;
      }
    `);
    styleSheet.insertRule(`
    .card-body.preview-card-body{
      .selected {
        border: solid 2px ${environment.design.sidebarColor} !important;
      }
    }
    `);
    styleSheet.insertRule(`
      .change-layout-link {
        color: ${environment.design.sidebarColor} !important;
        &:hover {
          color: ${environment.design.primaryColorLight} !important;
        }
      }
    `);
    };
  };