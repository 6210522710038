import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { Subscription } from 'rxjs';
import { TableData } from 'src/app/components/main/content/workflow/workflow-steps/widget-properties/table-form-properties/table-form-properties.component';

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent implements OnChanges,OnDestroy,OnInit {
  @Input() stepData: any
  @Output() onCellSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRowDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() onColumnDeleted: EventEmitter<any> = new EventEmitter<any>();

  @Input() workflowTranslations?: any


  tableData: TableData;
  selectedCell: any;

  selectedLang
  subscriptions: Subscription[] = []

  constructor(private workflowLanguageService: WorkflowLanguageService) {
    this.selectedLang = this.workflowLanguageService.currentLang.code
    this.subscriptions.push(this.workflowLanguageService.onLangChange.subscribe(res => {
      this.selectedLang = res.lang.code
    }))
  }
  ngOnInit(): void {
    this.selectedCell = null

  }
  ngOnDestroy(): void {
    this.selectedCell = null
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stepData']?.currentValue) {
      this.tableData = this.stepData.layouts[0].items[0].templateOptions?.tableData || {
        columns: [{
          cells: []
        }],
        rows: []
      };
    }
  }

  selectCell(row: number, column: number, cell, isHeader?: boolean) {
    this.selectedCell = cell;
    this.onCellSelected.emit({ selectedCell: { row, column, data: this.selectedCell, isHeader }});
  }

  deleteRow(rowIndex) {
    this.onRowDeleted.emit({ rowIndex: rowIndex});
  }

  deleteColumn(columnIndex) {
    this.onColumnDeleted.emit({ columnIndex: columnIndex});
  }
}
