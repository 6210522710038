import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-number-widget',
  templateUrl: './number-widget.component.html',
  styleUrls: ['./number-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberWidgetComponent),
      multi: true,
    },
  ],
})
export class NumberWidgetComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() item //type tanımla

  subscriptions : Subscription[] = []

  numberControl = new UntypedFormControl()
  numberGroup

  value


  constructor() { }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.value = value
    if(value) {
      let number = {numberFirst:'',numberSecond:'', numberOutput:''}
      number.numberOutput = value
      number.numberFirst = value.toString().includes('.') ? value.split(".")[0] : value;
      number.numberSecond = value.toString().includes('.') ? value.split(".")[1] : 0
      this.numberGroup.patchValue(number)
    }
    
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.numberGroup.controls?.['numberOutput']?.valid ? null : this.numberGroup.controls?.['numberOutput']?.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    let validationArray = []

    if(this.item.templateOptions.required) {
      validationArray.push(Validators.required)
    }
    if(this.item.templateOptions.min){
      validationArray.push(Validators.min(this.item.templateOptions.min))
    }
    if(this.item.templateOptions.max){
      validationArray.push(Validators.max(this.item.templateOptions.max))
    }

    this.numberGroup = new UntypedFormGroup(
      { numberFirst: new UntypedFormControl(this.value),
        numberSecond: new UntypedFormControl('', this.item.templateOptions.precision ? Validators.maxLength(this.item.templateOptions.precision): null),
        numberOutput: new UntypedFormControl(this.value, validationArray) 
      })

    
    this.subscriptions.push(this.numberGroup.valueChanges.subscribe((value) => {
      const numberOutput = value.numberSecond ? (value.numberFirst + '.' + value.numberSecond) : value.numberFirst
      if (numberOutput !== value.numberOutput) {
        this.numberGroup.controls.numberOutput.patchValue(numberOutput)
        this.onChange(numberOutput)
      }
    }))
  }

}
