import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Step, StepData } from 'src/app/models/Step';
import { DetailPageService } from 'src/app/services/detail-page.service';
import { LoaderService } from 'src/app/services/support/loader.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { SnackbarService } from 'src/app/services/support/snackbar.service';
import { environment } from 'src/environments/environment';
import { catchError, debounceTime, forkJoin, fromEvent, lastValueFrom, map, Observable, of, Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from "@ngx-translate/core";
//import { NgSelectComponent } from '@ng-select/ng-select';
import { WorkflowsModalComponent } from 'src/app/components/modals/workflows-modal/workflows-modal.component';
import { TemplateModalComponent } from 'src/app/components/modals/template-modal/template-modal.component';
import { Widgets } from '@models/Widget';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { WorkflowNewLanguageModalComponent } from 'src/app/components/modals/workflow-new-language-modal/workflow-new-language-modal.component';
import languages from '../../../../../assets/languages.json'
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { FileShareService } from '@services/support/file-share.service';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit, OnDestroy, AfterViewChecked {

  stepPreviewContainer: ElementRef<HTMLDivElement>
  @ViewChild('stepPreviewContainer') set setStepPreviewContainer(content: any) {
    this.stepPreviewContainer = content;
    if (this.stepPreviewContainer) {
      this.onPreviewContainerAvailable();
    }
  }
  stepDelete: EventEmitter<void> = new EventEmitter();

  @Input('workflow') workflow!: any
  @Input('saveStatus') saveStatus!: any
  @Input() isChangeAvailable!: boolean
  easyAccessUpdated

  @Input('type') type!: string;
  @Output() typeChanged: EventEmitter<any> = new EventEmitter();

  @Input('editable') editable?: boolean
  @Input('status') status?: string
  //@ViewChild('selectedOption') selectedOption: NgSelectComponent

  @Input() addOns!: any;


  @ViewChild('sidebarElement', {static: true}) sidebarElement: ElementRef;
  @ViewChild('navbarElement', {static: true}) navbarElement: ElementRef;
  @Output() publish: EventEmitter<any> = new EventEmitter();

  previewEventData: any
  previewRowEventData: any
  previewColumnEventData: any

  deleteWidget: boolean
  widgets = Widgets
  previewWidget
  selectedStepOrder
  sectionStepsLength
  sectionStepOrder
  totalSectionCount

  /*resolutions = [
    { name: 'RealWear HMT-1', value: 1.77916666667 },
    { name: 'RealWear Navigator 500', value: 1.77916666667 },
    { name: 'Vuzix M400', value: 2.16666666667 },
    { name: 'Vuzix m4000', value: 1.77916666667 },
    { name: 'iPhone 13', value: 2.16666666667 },
    { name: 'Google Pixel 5', value: 2.16666666667 },
    { name: 'Google Pixel 3a XL', value: 2 },
    { name: 'iPhone 8', value: 1.77777777778 },
    { name: 'iPad Air 4', value: 1.4375 },
    { name: 'Samsung Galaxy Tab S7', value: 1.6 },
    { name: 'iPad Pro 11', value: 1.33333333333 },
  ];*/
  resolutions = [
    { name: 'RealWear HMT-1', width: 854, height: 480, ppi: 150, value: 1.77916666667 },
    { name: 'RealWear Navigator 500', width: 854, height: 480, ppi: 150, value: 1.77916666667 },
    { name: 'Samsung Galaxy M52', width: 2400, height: 1080, ppi: 393, value: 2.22222222222 },
    { name: 'iPhone 14', width: 2532, height: 1170, ppi: 460, value: 2.1641025641 },
    //{ name: 'iPad 6th gen', width: 2048, height: 1536, ppi: 264, value: 1.33333333333 },
    { name: 'iPad 9th gen', width: 2160, height: 1620, ppi: 264, value: 1.33333333333 },
    { name: 'iPad Air 5', width: 2360, height: 1640, ppi: 264, value: 1.43902439024 },
  ]
  resolution = this.resolutions[0];

  orientations = [
    { value: "Full Page", name: "fullscreen", count: 1, orientation_v: "row" },
    { value: "Vertical Page", name: "vertical-equal", count: 2, orientation_v: "row" },
    { value: "Horizontal Page", name: "horizontal-equal", count: 2, orientation_v: "column" }
  ]
  orientation = this.orientations[0]
  orientationValue = this.orientations[0].value

  tabs: boolean[] = [true, false, false, false, false];

  isWorkflowDetailValid = true
  workflowDetail: {
    name: string
    description: string
    users: string[],
    approvers: string[],
    labels: string[],
    estimatedDuration: string,
    showContentPage: boolean,
    files: any,
    detailExtraFields: string[],
    expertsToCall: any
  }

  isWorkflowStepsValid = true
  initialStep: string
  workflowSteps: Step[] = []

  selectedStep
  selectedStepForPreview
  selectedWidgetData

  isAllStepsValid = true
  isStepLayoutValid: any = {}

  selectedLayoutNo?: number;
  beforeUnloadSub: Subscription
  subscriptions: Subscription[] = []

  changeAvailable = false;
  selectTemplate = false;

  sidebarOpen: boolean;
  longImage: boolean;

  workflowStepsAreaPosition: { x: number, y: number }
  mouseUp: any;
  sidebar: any;
  navbar: any;
  outOfConnection: boolean = false
  changedTabIndex: number = null

  isWorkflowConnectionValid = true
  saveButtonClicked = false
  pageClosed = false
  ableToSaving = false

  connectionEnabled = false

  previewScalePercent = 1.0
  showPreviewOverlay = false

  previewOuterStyle: any = {}
  previewInnerStyle: any = {}
  previewHeaderFooterStyle: any = {}

  resizeSub: Subscription = null

  hideConnections = false

  isQrAvailable = false

  zoomOutValue
  zoomInValue
  fullMode

  currentLang;
  allLangs = [];
  selectedLang
  selectedLangName

  showLangs: boolean = false
  langSub: Subscription = null

  workflowTranslations

  addedTranslationAfterAutotranslate = false
  filterLang = new UntypedFormControl()
  filteredLangs = []

  subWorkflowsObject = {}
  stepList2 = []

  workflowStepFiles = {}
  showStepFiles = false
  selectedStepFiles = []
  isVideoFlow

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private detailPageService: DetailPageService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private dbService: DbService,
    private changeDetector: ChangeDetectorRef,
    private workflowLanguageService: WorkflowLanguageService,
    private http: HttpClient,
    private fileShareService: FileShareService
  ) {
    this.allLangs = this.workflowLanguageService.allLangs
    this.selectedLang = this.workflowLanguageService.currentLang.code
    this.selectedLangName = this.workflowLanguageService.currentLang.name
    this.subscriptions.push(this.workflowLanguageService.onLangChange.subscribe(res => {
      this.selectedLang = res.lang.code
      this.selectedLangName = res.lang.name

      if(this.selectedStepForPreview?.data?.layouts) {
        this.selectedStepForPreview = JSON.parse(JSON.stringify(this.selectedStep))

        this.selectedStepForPreview?.data?.layouts.forEach((res, index) => {
          this.selectedStepForPreview.data.name = this.workflowTranslations[this.selectedLang][this.selectedStep.data.name]
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.label = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.label]
          if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder == '') {
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.placeholder]
          }
          if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html == '') {
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.html]
          }
          if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options) {
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions['translationAvailable'] = this.workflowTranslations ? true : null
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options = this.selectedStep.data.layouts[index].items[0].templateOptions.options.map(option => { return { label: this.workflowTranslations[this.selectedLang][option.label], value: option.value } })
          }
        })
        this.selectedStepForPreview = JSON.parse(JSON.stringify(this.selectedStepForPreview))
      }
    }))
  }
  ngAfterViewChecked(): void {
    this.workflowDetail = this.workflowDetail
    this.changeDetector.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    // if(this.addOns) {
    //   this.hideConnections = !this.addOns.hideConnections
    //   this.isQrAvailable = this.addOns.isQrAvailable
    // }

    // Hide dashboard fixed header
    const dashboardHeader = this.document.getElementsByClassName('list-header-fixed')
    if (dashboardHeader?.length) {
      this.renderer2.addClass(dashboardHeader[0], 'd-none');
    }

    let unique = []
    for (let i = 0; i <= 30; i++) {
      unique.push()

    }
    if (this.workflow[this.type].defaultLanguage) {
      let workflowLangs = []
      Object.keys(this.workflow[this.type].translations).forEach(res => {
        let workflowLang = this.allLangs.find(lang => lang.code == res)
        if (workflowLang) {
          workflowLangs.push(workflowLang)
        }
      })
      let defaultLang = workflowLangs.find(res => res.code == this.workflow[this.type].defaultLanguage)
      let langObject = { code: defaultLang.code, name: defaultLang.name }
      this.changeCurrentLang(langObject)
    }
    this.sidebar = this.sidebarElement.nativeElement
    this.navbar = this.navbarElement.nativeElement

    this.resizeSub = fromEvent(window, 'resize').subscribe(res => {
      this.setResolutionSize()
    })

    this.isVideoFlow = this.workflow[this.type].isVideoFlow

    this.authService.getAccountData().then(account => {
      this.hideConnections = !account.data.accountData.add_ons?.workflowconnections
      this.isQrAvailable = account.data.accountData.features?.workflowqr
    })

    if (environment.production) {
      this.beforeUnloadSub = fromEvent(window, "beforeunload").subscribe(e => { e.returnValue = true; });
    }

    const sidebarSituation = localStorage.getItem('sidebarOpen');
    if (sidebarSituation && sidebarSituation === 'false') {
      this.sidebarOpen = false;
      this.longImage = false;
    } else {
      this.sidebarOpen = true;
      this.longImage = true;
    }
    this.workflowDetail = {
      name: this.workflow[this.type].translations[this.selectedLang][this.workflow[this.type].name],
      description: this.workflow[this.type].translations[this.selectedLang][this.workflow[this.type].description],
      users: this.workflow.users ? [...this.workflow.users] : [],
      approvers: this.workflow.approvers ? [...this.workflow.approvers] : [],
      labels: this.workflow[this.type].labels ? [...this.workflow[this.type].labels] : [],
      estimatedDuration: this.workflow[this.type].estimatedDuration ? this.workflow[this.type].estimatedDuration : { hours: 0, minutes: 0 },
      showContentPage: this.workflow[this.type].showContentPage ? true : false,
      files: this.workflow[this.type].files ? this.workflow[this.type].files : [],
      detailExtraFields: this.workflow[this.type].detailExtraFields ? this.workflow[this.type].detailExtraFields : [],
      expertsToCall: this.workflow[this.type].expertsToCall ? [...this.workflow[this.type].expertsToCall] : [],
    }

    this.initialStep = this.workflow[this.type].initialStep
    this.connectionEnabled = this.workflow[this.type].connectionEnabled ? this.workflow[this.type].connectionEnabled : false

    const stepIds = this.workflow[this.type].steps ? Object.keys(this.workflow[this.type].steps) : []
    this.workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(this.workflow[this.type].steps[sid]))
      stp.id = sid
      return stp
    }).sort((a, b) => a.data.order - b.data.order)
    if (this.workflow[this.type].stepsAreaPosition) {
      this.workflowStepsAreaPosition = JSON.parse(JSON.stringify(this.workflow[this.type].stepsAreaPosition))
    }

    this.workflowTranslations = this.workflow[this.type].translations

    const stepsIncludesHtmlWidget = this.workflowSteps.filter(step => step.data.layouts[0].items[0].type == 'html')
    if (stepsIncludesHtmlWidget.length && this.workflow[this.type].dataVersion >= 4) {
      const languages = Object.keys(this.workflowTranslations)
      for await (const lang of languages) {
        await lastValueFrom(this.getUrlRichTextWidget(lang))
      }
    }

    this.workflowSteps.forEach(step => {
      step.data.layouts.forEach(layout => {
        if (layout.items[0].type == 'form') {
          layout.items[0].templateOptions.formData.forEach(formDataItem => {
            formDataItem.fields.forEach(field => {
              if (this.workflowTranslations[this.workflow[this.type].defaultLanguage][field.templateOptions.label] == 'Auftrags-Nr. / Serien-Nr.') {
                field.templateOptions['useForFilter'] = true
              }
            })
          })
        }
      })
    })

    if (this.workflow[this.type]['subWorkflows']) {
      this.subWorkflowsObject = this.workflow[this.type]['subWorkflows']
    }
    if (this.subWorkflowsObject) {
      let subWorkflowsArray = []
      let orderedsubWorkflowsArray = []
      if (Object.keys(this.subWorkflowsObject).length > 0) {
        Object.keys(this.subWorkflowsObject).forEach(subWorkflow => {
          subWorkflowsArray.push(this.subWorkflowsObject[subWorkflow])
        })
        orderedsubWorkflowsArray = subWorkflowsArray.sort((a, b) => {
          if (a.stepData.order < b.stepData.order) {
            return -1;
          }
          if (a.stepData.order > b.stepData.order) {
            return 1;
          }
          return 0;
        });

        orderedsubWorkflowsArray.forEach(res => {
          this.mapStepsToWorkflowWidget(res)
        })
      }
    }

    this.workflowTranslations = this.workflow[this.type].translations

    this.subscriptions.push(this.filterLang.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.filterLangs(res.toLowerCase());
      } else {
        this.filteredLangs = this.allLangs
      }
    }))
    this.filteredLangs = this.allLangs

    if (this.isChangeAvailable) {
      this.changeAvailable = true
    }
    if(this.isVideoFlow) {
      this.tabs = [false, true, false, false, false];
    }
  }

  ngOnDestroy(): void {
    const dashboardHeader = this.document.getElementsByClassName('list-header-fixed')
    if (dashboardHeader?.length) {
      this.renderer2.removeClass(dashboardHeader[0], 'd-none');
    }

    if (this.beforeUnloadSub) { this.beforeUnloadSub.unsubscribe() }
    if (this.resizeSub) { this.resizeSub.unsubscribe() }
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  getFileContentFromUrl(url: string) {
    return this.http.get(url, { responseType: 'text' });
 }

  getUrlRichTextWidget(lang): Observable<boolean> {
    const requests = {};
    this.workflowSteps.forEach((step, index) => {
      step?.data?.layouts?.forEach((layout,index) => {
        if (layout.items[0].type == 'html') {
          const uniqueKey = layout.items[0].templateOptions.html
          requests[uniqueKey] = this.getFileContentFromUrl(this.workflowTranslations[lang][uniqueKey])
        }
      })
    });

    return forkJoin(requests).pipe(
      map((results) => {
        Object.keys(results).forEach((key) => {
          this.workflowTranslations[lang][key] = results[key]
        })
        return true;
      }),
      catchError((error) => {
        return of(false)
      })
    )
  }

  onTabClicked(index: number) {
    if (this.tabs[2]) {
      this.changedTabIndex = index
      this.outOfConnection = true
    } else {
      this.changeTab(index)
    }
  }

  changeTab(index: number) {
    // this.pageClosed = false
    // if(this.tabs[2] && index !== 2) {
    //   this.pageClosed = true
    // }
    this.tabs.forEach((tab, i) => {

      if (index == i) {
        this.tabs[i] = true;
      } else {
        this.tabs[i] = false;
      }
    });
  }

  stop(event) {
    event.stopPropagation()
  }

  filterLangs(lang) {
    let filteredData = []
    filteredData = this.allLangs.filter(langData => (langData.name.toLowerCase()).includes(lang.toLowerCase()))
    this.filteredLangs = filteredData;
  }

  goOutOfConnection(event: boolean) {
    if (event) {
      this.changeTab(this.changedTabIndex)
    }
    this.outOfConnection = false
  }

  setResolutionSize() {
    if (!this.stepPreviewContainer) {
      return
    }
    const containerWidth = this.stepPreviewContainer.nativeElement.offsetWidth
    const containerHeight = this.stepPreviewContainer.nativeElement.offsetHeight

    const maximumWidth = containerWidth - 120     // minus right - left padding
    const maximumHeight = containerHeight - 180  // minus header - footer height

    const deviceWidth = this.resolution.width / this.resolution.ppi * 120    // multiply with 120 to convert real size to pixels
    const deviceHeight = this.resolution.height / this.resolution.ppi * 120  // multiply with 120 to convert real size to pixels

    const desiredHeight = maximumWidth * (this.resolution.height / this.resolution.width)

    if (maximumHeight > desiredHeight) {
      this.previewScalePercent = maximumWidth / deviceWidth
      this.previewOuterStyle = {
        width: `${maximumWidth}px`,
        height: `${desiredHeight}px`
      }
      this.previewInnerStyle = {
        width: `${deviceWidth}px`,
        height: `${deviceHeight}px`,
        transform: `scale(${this.previewScalePercent.toFixed(2)}, ${this.previewScalePercent.toFixed(2)})`
      }
      this.previewHeaderFooterStyle = {
        width: `${maximumWidth}px`
      }
    } else {
      const requiredWidth = maximumHeight * (this.resolution.width / this.resolution.height)
      this.previewScalePercent = requiredWidth / deviceWidth

      this.previewScalePercent = requiredWidth / deviceWidth
      this.previewOuterStyle = {
        width: `${requiredWidth}px`,
        height: `${maximumHeight}px`
      }
      this.previewInnerStyle = {
        width: `${deviceWidth}px`,
        height: `${deviceHeight}px`,
        transform: `scale(${this.previewScalePercent.toFixed(2)}, ${this.previewScalePercent.toFixed(2)})`
      }
      this.previewHeaderFooterStyle = {
        width: `${requiredWidth}px`,
      }
    }
    this.changeDetector.detectChanges();
  }

  changeStepLayout(stepData: StepData): void {
    let templateModalRef = this.modalService.show(TemplateModalComponent, {
      initialState: {
        action: 'change',
        selectedTemplate: TemplateModalComponent.templateArray.findIndex(tmp => tmp.name === stepData.template.name) + 1
      },
      backdrop: 'static',
      class: 'modal-dialog-centered modal-lg modal-template',
      animated: false
    })
    this.subscriptions.push(templateModalRef.content.onClose.subscribe(res => {
      this.onOrientationChange(res, templateModalRef)
    }));
  }

  onPreviewContainerAvailable() {
    this.setResolutionSize();
  }

  onResolutionChange(resolution: any): void {
    this.setResolutionSize();
  }

  onOrientationChange(template: any, templateModalRef: BsModalRef<TemplateModalComponent>): void {
    const selected_orientation = this.orientations.find((o) => o.name === template.name);
    if (selected_orientation && selected_orientation.name !== this.orientation.name) {
      this.widgets = Widgets
      this.previewWidget = this.selectedStepForPreview?.data?.layouts[0]?.items[0]?.type
      if (this.widgets?.[this.previewWidget]?.requiresFullscreen) {
        const initialState = {
          textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.ERROR-MESSAGE.NOT-SUPPORT'),
          cancelButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.ERROR-MESSAGE.CONFIRM'),
          confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
          confirmButtonStyle: 'primary'
        };
        this.modalService.show(AlertModalComponent, {
          initialState,
          backdrop: 'static',
          class: 'modal-dialog-centered',
          animated: false
        });
      }
      else if (selected_orientation.count > this.orientation.count) {
        this.selectedStep.data.layouts.push({ items: [{ key: this.dbService.createPushId(), type: 'empty', templateOptions: { label: 'Title', required: false } }] })
        this.selectedStep.data.template = { count: 2, name: selected_orientation.name, orientation: selected_orientation.orientation_v, weights: [1, 1] }

      } else if (selected_orientation.count === this.orientation.count) {
        this.selectedStep.data.template.name = selected_orientation.name;
        this.selectedStep.data.template.orientation = selected_orientation.orientation_v;

      } else {
        if (!(this.selectedStepForPreview.data.layouts[0].items[0].type === 'empty') && !(this.selectedStepForPreview.data.layouts[1].items[0].type === 'empty')) {
          const initialState = {
            textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.ERROR-MESSAGE.MESSAGE'),
            cancelButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.SAVE-AS-DRAFT')
          };
          this.modalService.show(AlertModalComponent, {
            initialState,
            backdrop: 'static',
            class: 'modal-dialog-centered',
            animated: false
          });
          //this.selectedOption.writeValue(this.orientationValue);
          return;

        } else {
          if (this.selectedStepForPreview.data.layouts[0].items[0].type === 'empty') {
            this.selectedStep.data.layouts.splice(0, 1);
            this.selectedStep.data.template = { count: 1, name: selected_orientation.name, orientation: selected_orientation.orientation_v, weights: [1] }

          } else {
            this.selectedStep.data.layouts.splice(1, 1);
            this.selectedStep.data.template = { count: 1, name: selected_orientation.name, orientation: selected_orientation.orientation_v, weights: [1] }
          }
        }
      }
      this.orientation = selected_orientation;
      this.selectedStepChange(this.selectedStep);
      this.changeAvailable = true
    }
    templateModalRef.hide()
  }

  mapConnectionsAndConditionsOrder() {
    this.workflowSteps.forEach((step,index)=> {
      step.data.connections.forEach((connection,connectionIndex)=> {
        connection['order'] = connectionIndex
        if(connection.conditions){
          connection.conditions.forEach((condition,conditionIndex) => {
            condition['order'] = conditionIndex
          })
        }
      })
    })

    this.stepList2.forEach((step, index) => {
      step.data.connections.forEach((connection, connectionIndex) => {
        connection['order'] = connectionIndex
        if (connection.conditions) {
          connection.conditions.forEach((condition, conditionIndex) => {
            condition['order'] = conditionIndex
          })
        }
      })
    })
  }

  uploadFile(content: string, stepId: string) {
    const blob = new Blob([content], { type: 'text/html' });
    var file = new File([blob], "name.html");

    return new Promise((resolve, reject) => {
      this.fileShareService.uploadWorfklowFile(file, this.workflow.id, stepId, this.dbService.createPushId())
        .then(url => {
          resolve(url);
        })
        .catch(error => {
          console.error('Error uploading file:', error);
          reject(error);
        });
    });
  }

  saveWorkflow(type?) {
    if (this.workflow.draft.translations) {
      let languages = Object.keys(this.workflow.draft.translations)
      let translationObjectKeys = []
      languages.forEach(lang => {
        let singleLanguageKeys = Object.keys(this.workflow.draft.translations[lang])
        let newLangObject = {
          [lang]: singleLanguageKeys
        }
        translationObjectKeys.push(newLangObject)
      })

    }
    this.stepList2 = JSON.parse(JSON.stringify(this.workflowSteps))

    if (Object.keys(this.subWorkflowsObject).length > 0) {
      Object.keys(this.subWorkflowsObject).forEach(res => {
        this.mapSubWorkflows(this.subWorkflowsObject[res])
      })
    }

    this.mapConnectionsAndConditionsOrder()
    let exIds = []
    this.workflowDetail.expertsToCall.forEach(res => {
      if(res?.id) {
        exIds.push(res.id)
      }
    })

    if(this.workflow?.[this.type]) {
      Object.keys(this.workflow[this.type].translations).forEach(lang => {
        Object.keys(this.workflow[this.type].translations[lang]).forEach(key => {
          if(!this.workflow[this.type].translations[lang][key] && this.workflow[this.type].translations[lang][key] !== '') {
            this.workflow[this.type].translations[lang][key] = ''
          }
        })
      })
    }
    
    const workflow = JSON.parse(JSON.stringify(this.workflow))
    workflow.draft['subWorkflows'] = this.subWorkflowsObject
    workflow.draft.name = this.workflow[this.type].name
    workflow.draft.description = this.workflow[this.type].description
    workflow.draft.labels = this.workflowDetail.labels
    workflow.draft.estimatedDuration = this.workflowDetail.estimatedDuration
    workflow.draft.showContentPage = this.workflowDetail.showContentPage
    workflow.draft.files = this.workflowDetail.files ? this.workflowDetail.files : []
    workflow.draft.detailExtraFields = this.workflowDetail.detailExtraFields ? this.workflowDetail.detailExtraFields : []
    workflow.draft.expertsToCall = exIds
    workflow.draft.connectionEnabled = this.connectionEnabled
    workflow.draft.initialStep = this.initialStep
    workflow.draft.stepsAreaPosition = this.workflowStepsAreaPosition
    workflow.draft.videoFlowData = this.workflow.draft.videoFlowData
    workflow.draft.steps = this.stepList2.reduce((steps, stp) => {
      steps[stp.id] = { data: stp.data, coordinates: stp.coordinates }
      return steps
    }, {})
    workflow.draft.dataVersion = environment.dataVersion
    if (workflow.badges) {
      workflow.badges = this.workflow.badges
    }
    return this.workflowService.setWorkflowDraft(workflow).then(() => {
      this.changeAvailable = false
      const langs = Object.keys(this.workflowTranslations)
      for  (const lang of langs) {
         lastValueFrom(this.getUrlRichTextWidget(lang))
      }
    })
  }

  mapRichTextWidget(lang: string): Observable<boolean> {
    const uploadRequests = {};

    this.workflowSteps.forEach((step, index) => {
      step?.data?.layouts?.forEach(layout => {
        if (layout.items[0].type == 'html') {
          const uniqueKey = layout.items[0].templateOptions.html
            uploadRequests[uniqueKey] = this.uploadFile(this.workflowTranslations[lang][uniqueKey], step.id)
        }
      })
    });
    return forkJoin(uploadRequests).pipe(
      map((results) => {
        Object.keys(results).forEach((key) => {
          this.workflowTranslations[lang][key] = results[key]
        })
        return true;
      }),
      catchError((error) => {
        return of(false)
      })
    )
  }

  async saveAllWorkflow(type?) {
    this.saveButtonClicked = true
    if(!type) {
      this.loaderService.show()

    }
    const stepsIncludesHtmlWidget = this.workflowSteps.filter(step => step.data.layouts[0].items[0].type == 'html')
    if (stepsIncludesHtmlWidget.length) {
      const languages = Object.keys(this.workflowTranslations)
      let isUploadsSuccessful = true;
      for await (const lang of languages) {
        isUploadsSuccessful = await lastValueFrom(this.mapRichTextWidget(lang))
        if (!isUploadsSuccessful) {
          break;
        }
      }

      if (isUploadsSuccessful) {
        return this.saveWorkflow()
      }
    }
    return this.saveWorkflow(type); 
  }

  mapSubWorkflows(subWorkflowObject) {
    let copiedSteps
    let newWorkflowSteps
    let workflowWidgetIndex
    let mappedWorkflowSteps = JSON.parse(JSON.stringify(this.stepList2))

    let workflowWidget = mappedWorkflowSteps.find(step => step.data.layouts[0].items[0].type == 'workflow')
    let order = this.workflowSteps.findIndex(res => workflowWidget.id == res.id)
    if (workflowWidget) {
      workflowWidgetIndex = mappedWorkflowSteps.findIndex(step => step.data.layouts[0].items[0].type == 'workflow')
      copiedSteps = JSON.parse(JSON.stringify(subWorkflowObject.steps))

      // copiedSteps = subWorkflowObject.steps

      if (subWorkflowObject.workflow.connectionEnabled) {
        this.workflow.draft.connectionEnabled = true
        this.connectionEnabled = true
      }

      if (workflowWidget.data.parentId) {
        copiedSteps.forEach(res => {
          res.data.parentId = workflowWidget.data.parentId
        })
      }

      let stepDataObject = {
        name: workflowWidget.data.name,
        connections: workflowWidget.data.connections,
        order: order,
        stepId: workflowWidget.id,
        parentId: workflowWidget.data.parentId
      }

      if (workflowWidgetIndex > 0) {
        if (!this.connectionEnabled) {
          if (!mappedWorkflowSteps[workflowWidgetIndex - 1].data.connections.length) {
            mappedWorkflowSteps[workflowWidgetIndex - 1].data.connections.push({ next: copiedSteps[0].id })
            mappedWorkflowSteps[workflowWidgetIndex - 1].data['next'] = copiedSteps[0].id
          } else {
            mappedWorkflowSteps[workflowWidgetIndex - 1].data.connections[mappedWorkflowSteps[workflowWidgetIndex - 1].data.connections.length - 1]['next'] = copiedSteps[0].id
            mappedWorkflowSteps[workflowWidgetIndex - 1].data['next'] = copiedSteps[0].id
          }
        } else {
          mappedWorkflowSteps.forEach(stepToConnect => {
            if (stepToConnect.data.connections?.length) {
              stepToConnect.data.connections.forEach(connection => {
                if (connection['next'] == workflowWidget.id) {
                  connection['next'] = subWorkflowObject.initialStep
                }
              })
            }
          })
        }
        if (workflowWidget.data.connections.length > 0) {
          workflowWidget.data.connections.forEach(connection => {
            this.workflowSteps.find(step => connection['next'] == step.id)
          })
        }
        let stepDataObject = {
          name: workflowWidget.data.name,
          connections: workflowWidget.data.connections,
          order: order,
          stepId: workflowWidget.id,
          parentId: workflowWidget.data.parentId,
        }

        // mappedWorkflowSteps[workflowWidgetIndex-1].data['subWorkflow'] = {...subWorkflowObject, stepName: workflowWidget.data.name, stepData : stepDataObject }
        this.subWorkflowsObject[subWorkflowObject.workflowId]['stepData'] = stepDataObject
      } else {
        stepDataObject = {
          name: workflowWidget.data.name,
          connections: workflowWidget.data.connections,
          order: order,
          stepId: workflowWidget.id,
          parentId: workflowWidget.data.parentId,
        }
        this.subWorkflowsObject[subWorkflowObject.workflowId]['stepData'] = stepDataObject
        this.workflow.draft.initialStep = subWorkflowObject.initialStep
        this.initialStep = subWorkflowObject.initialStep
      }
      if (workflowWidget?.coordinates) {
        this.subWorkflowsObject[subWorkflowObject.workflowId]['coordinates'] = workflowWidget.coordinates
      }

      if (!this.workflow.draft.connectionEnabled) {
        let finalStepIndex = copiedSteps.findIndex(step => step.data.connections.length == 0)
        if (mappedWorkflowSteps[workflowWidgetIndex + 1] && finalStepIndex > 0) {
          copiedSteps[finalStepIndex].data.connections.push({ next: mappedWorkflowSteps[workflowWidgetIndex + 1].id })
        }
      } else {
        let finalStepIndex = copiedSteps.findIndex(step => step.data.connections.length == 0)
        copiedSteps.forEach((step, index) => {
          if (step.data.connections.length == 0) {
            copiedSteps[index].data.connections = workflowWidget.data.connections
          }
        })
      }
      newWorkflowSteps = [...mappedWorkflowSteps.slice(0, workflowWidgetIndex), ...copiedSteps, ...mappedWorkflowSteps.slice(workflowWidgetIndex + 1, mappedWorkflowSteps.length)]
      newWorkflowSteps.forEach((res, index) => {
        res.data.order = index
        if (res.data.connections?.length) {
          res.data.connections.forEach((connection, connectionIndex) => {
            connection['order'] = connectionIndex
          })
        }
      })

      if (this.workflow.draft.translations && Object.keys(this.workflow.draft.translations).length > 0) {
        Object.keys(this.workflow.draft.translations).forEach(translationKey => {
          this.workflow.draft.translations[translationKey] = Object.assign({}, this.workflow.draft.translations[translationKey], (subWorkflowObject.translations[translationKey] ? subWorkflowObject.translations[translationKey] : subWorkflowObject.translations[subWorkflowObject.defaultLanguage]));
        })
      }

      mappedWorkflowSteps = newWorkflowSteps
      this.stepList2 = mappedWorkflowSteps
    }
  }

  mapStepsToWorkflowWidget(subWorkflowConnection) {
    let newWorkflowSteps
    let initial = subWorkflowConnection?.['initialStep']
    let connections = subWorkflowConnection?.stepData?.connections
    let layouts = []

    layouts.push({ items: [{ key: this.dbService.createPushId(), type: 'workflow', templateOptions: { workflow: subWorkflowConnection, required: false } }] })

    let template = {
      count: 1,
      name: "fullscreen",
      orientation: "row",
      weights: [1]
    }

    let subWorkflowStep = {
      data: {
        name: subWorkflowConnection.stepData.name,
        layouts: layouts,
        template: template,
        order: subWorkflowConnection.stepData.order,
        connections: connections,
        parentId: subWorkflowConnection.stepData.parentId,
      },
      id: subWorkflowConnection.stepData.stepId
    }
    if (subWorkflowConnection.coordinates) {
      subWorkflowStep['coordinates'] = subWorkflowConnection.coordinates
    }

    newWorkflowSteps = [...this.workflowSteps.slice(0, subWorkflowConnection.stepData.order + 1), subWorkflowStep, ...this.workflowSteps.slice(subWorkflowConnection.stepData.order + 1, this.workflowSteps.length)]
    newWorkflowSteps = newWorkflowSteps.filter(step => step.data?.['parentWorkflow'] !== subWorkflowConnection.workflowId)
    newWorkflowSteps.forEach((res, index) => {
      res.data.order = index
    })
    newWorkflowSteps.forEach(step => {
      if (step.data.connections.length > 0) {
        step.data.connections.forEach(connection => {
          if (connection.next == initial) {
            connection.next = subWorkflowConnection.stepData.stepId
          }
        })
      }
    })

    this.workflowSteps = newWorkflowSteps
  }

  saveButtonClickedChange(event) {
    if (event) {
      this.saveButtonClicked = false
    }
  }

  pageClosedChange(event) {
    if (event) {
      this.pageClosed = false
    }
  }

  save(type?) {
    if (this.addedTranslationAfterAutotranslate) {
      const initialState = {
        textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.LANGUAGE.CHECK-LANGUAGE'),
        confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
        confirmButtonStyle: 'primary',
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      });
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if (res) {
          this.addedTranslationAfterAutotranslate = false
        }
      }))
    } else {
      this.saveAllWorkflow(type).then(res => {
        if(!type) {
          this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.WORKFLOW.SAVE-SUCCESSFUL'))
        }
      })
        .catch((err) => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.SAVE-ERROR')))
        .finally(() => {
          if(!type) {
            this.loaderService.hide()
          }
        })
    }
  }

  updateEasyAccess() {
    this.easyAccessUpdated = true
  }

  workflowTranscriptionIdChanged(event) {
    const isUpdate = true
    this.save(isUpdate)
  }
  workflowAIResultChanged(event) {
    const isUpdate = true
    this.save(isUpdate)
  }

  editWorkflow(): void {
    this.changeAvailable = false
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-PUBLISH-VIEW'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
      confirmButtonStyle: 'primary',
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.EDIT-WORKFLOW-TITLE')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        this.saveStatus = true
        this.onClose()
      }
    }))
  }

  publishWorkflow(): void {
    if (this.changeAvailable) {
      const initialState = {
        textMessage: this.translateService.instant('MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-UNSAVED-CHANGES'),
        confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.SAVE-PUBLISH'),
        confirmButtonStyle: 'primary',
        headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.PUBLISH-WORKFLOW')
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      })
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if (res) {
          this.saveAllWorkflow().then(resultSave => {
            this.publish.emit(this.workflow)
          })
        } else {
          alertmodalRef.hide()
        }
      }))
    } else {
      if(this.isVideoFlow) {
        this.saveAllWorkflow().then(resultSave => {
          this.publish.emit(this.workflow)
        })
      }else{
        this.loaderService.show()
        this.publish.emit(this.workflow)
      }
    }
  }

  stepListChange(event): void {
    this.changeAvailable = true
    let translations = Object.keys(this.workflow.draft.translations)
    if (translations.length > 1) {
      translations.forEach(translationKey => {
        if (translationKey !== this.selectedLang) {
          let selectedLanguageKeys = Object.keys(this.workflow.draft.translations[this.selectedLang])
          selectedLanguageKeys.forEach(key => {
            if (!this.workflow.draft.translations[translationKey][key]) {
              this.workflow.draft.translations[translationKey][key] = this.workflow.draft.translations[this.selectedLang][key]
            }
          })
          this.addedTranslationAfterAutotranslate = true
        }
      })
    }
  }

  workflowDetailChange(event): void {
    if (this.workflow.draft) {
      if (!this.workflow.draft?.translations[this.selectedLang]) {
        if (this.workflow.draft?.translations) {
          this.workflow.draft.translations[this.selectedLang] = JSON.parse(JSON.stringify(this.workflow.draft.translations[this.workflow.draft.defaultLanguage]))
        }
      }
      this.workflow.draft.translations[this.selectedLang][this.workflow.draft.name] = event.name
      this.workflow.draft.translations[this.selectedLang][this.workflow.draft.description] = event.description
      this.changeAvailable = true
    }

  }

  workflowConnectionChange() {
    this.changeAvailable = true;
  }

  templateChangeAvailable(event) {
    this.changeAvailable = true;
  }

  workflowConnectionStepsAreaPosition(event: { x: number, y: number }) {
    this.workflowStepsAreaPosition = event;
  }

  onClose() {
    if (this.changeAvailable) {
      const initialState = {
        textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.MESSAGE'),
        confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.CONFIRM'),
        headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.CLOSE-WORKFLOW')
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      })
      this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
        if (res) {
          this.detailPageService.removeComponent()
        } else {
          alertmodalRef.hide()
        }
      }))
    } else {
      this.detailPageService.removeComponent()
    }
  }

  connectionEnabledChanged(event) {
    this.workflowDetail.showContentPage = event ? false : this.workflowDetail.showContentPage
    this.workflowDetail = { ...this.workflowDetail }
    this.connectionEnabled = event
  }

  selectedStepChange(step: any) {
    if (step) {
      this.selectedStepFiles = step.data.files ? step.data.files : []
      let sectionCount = 0
      this.totalSectionCount = 0
      let sectionSteps = []
      this.workflowSteps.forEach((response, index) => {
        if (index <= step.data.order && response.data.isSection) {
          sectionCount += 1
        }
        if (response.data.isSection) {
          this.totalSectionCount += 1
        }
      })
      sectionSteps = this.workflowSteps.filter(resp => resp.data.parentId == step.data.parentId)
      let index = sectionSteps.findIndex(res => res.id == step.id)
      this.sectionStepsLength = sectionSteps.length
      this.sectionStepOrder = index
      this.selectedStepOrder = step.data.order + 1 - sectionCount
      this.selectedStep = step
      this.selectedStepForPreview = JSON.parse(JSON.stringify(step))
      if (this.selectedStepForPreview?.data?.layouts) {
        this.selectedStepForPreview?.data?.layouts.forEach((res, index) => {
          this.selectedStepForPreview.data.name = this.workflowTranslations[this.selectedLang][step.data.name]
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.label = this.workflowTranslations[this.selectedLang][step.data.layouts[index].items[0].templateOptions.label]
          if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder == '') {
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder = this.workflowTranslations[this.selectedLang][step.data.layouts[index].items[0].templateOptions.placeholder]
          }
          if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html == '') {
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html = this.workflowTranslations[this.selectedLang][step.data.layouts[index].items[0].templateOptions.html]
          }
          if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options) {
            this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options = this.selectedStep.data.layouts[index].items[0].templateOptions.options.map(option => { return { label: this.workflowTranslations[this.selectedLang][option.label], value: option.value } })
          }
        })
        this.selectedStepForPreview = JSON.parse(JSON.stringify(this.selectedStepForPreview))
      }

      this.selectedLayoutNo = null
      this.showStepFiles = true
      const nOrientation = this.orientations.find((o) => o.name === step.data.template.name)
      this.orientationValue = nOrientation?.value
      this.orientation = { value: this.orientationValue, name: step.data.template.name, count: step.data.template.count, orientation_v: step.data.template.orientation };
    } else {
      this.selectedStepForPreview = null
    }
  }

  viewStepFiles(event) {
    this.selectedLayoutNo = null
    this.showStepFiles = true
  }

  selectedLayoutNoChange(no: number) {
    this.selectedLayoutNo = no
    this.selectedWidgetData = this.selectedStep.data.layouts[no]?.items[0]
  }

  widgetDataChange(widgetData) {
    let newObj = {}

    if (widgetData.type == 'multicheckbox') {
      let options = []
      widgetData.templateOptions.options.forEach(res => {
        options.push({ label: this.workflowTranslations[this.selectedLang][res.label] })
      })
      newObj = {
        key: widgetData.key,
        type: widgetData.type,
        templateOptions: {
          label: widgetData.templateOptions.label,
          options: options,
          translationAvailable: this.workflowTranslations ? true : null
        }
      }
    }
    this.changeAvailable = true
    if (this.selectedStep.data.layouts[this.selectedLayoutNo]) {
      //widgetData.templateOptions.label
      if (widgetData.type == 'multicheckbox') {
        this.selectedStep.data.layouts[this.selectedLayoutNo].items[0] = newObj
      }
      this.selectedStep.data.layouts[this.selectedLayoutNo].items[0] = widgetData
    } else {
      this.selectedStep.data.layouts[this.selectedLayoutNo] = {
        items: [widgetData]
      }
    }
    let translations = Object.keys(this.workflow.draft.translations)
    if (translations.length > 1) {
      translations.forEach(translationKey => {
        if (translationKey !== this.selectedLang) {
          let selectedLanguageKeys = Object.keys(this.workflow.draft.translations[this.selectedLang])
          selectedLanguageKeys.forEach(key => {
            if (!this.workflow.draft.translations[translationKey][key]) {
              this.workflow.draft.translations[translationKey][key] = this.workflow.draft.translations[this.selectedLang][key]
            }
          })
        }
      })
    }

    this.selectedStepForPreview = JSON.parse(JSON.stringify(this.selectedStep))
    if (this.selectedStepForPreview?.data?.layouts) {
      this.selectedStepForPreview?.data?.layouts.forEach((res, index) => {
        this.selectedStepForPreview.data.name = this.workflowTranslations[this.selectedLang][this.selectedStep.data.name]
        this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.label = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.label]
        if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder == '') {
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.placeholder]
        }
        if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html == '') {
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.html]
        }
        if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options) {
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions['translationAvailable'] = this.workflowTranslations ? true : null
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options = this.selectedStep.data.layouts[index].items[0].templateOptions.options.map(option => { return { label: this.workflowTranslations[this.selectedLang][option.label], value: option.value } })
        }
      })
      this.selectedStepForPreview = JSON.parse(JSON.stringify(this.selectedStepForPreview))
    }
  }

  widgetFormValidChange(isValid: boolean) {
    if (this.isStepLayoutValid[this.selectedStep.id]) {
      this.isStepLayoutValid[this.selectedStep.id][this.selectedLayoutNo] = isValid
    } else {
      this.isStepLayoutValid[this.selectedStep.id] = {
        [this.selectedLayoutNo]: isValid
      }
    }
    this.isStepLayoutValid = { ...this.isStepLayoutValid }
    this.checkAllStepsValid()
  }

  templateChange(event) {
    this.selectedStep.data.template = event
  }

  onStepMove(newStepData) {
    const initialState = {
      action: 'move',
      stepData: newStepData,
      selectedLang: this.selectedLang,
      workflowTranslations: this.workflowTranslations,
      workflow: this.workflow
    }
    this.openWorkflowModal(initialState)
  }

  onStepListMove(newStepListData) {
    const initialState = {
      action: 'move',
      stepDataList: newStepListData,
      selectedLang: this.selectedLang,
      workflowTranslations: this.workflowTranslations,
      workflow: this.workflow
    }
    this.openWorkflowModal(initialState)
  }

  onStepClone(newStepData) {
    const initialState = {
      action: 'clone',
      stepData: newStepData,
      selectedLang: this.selectedLang,
      workflowTranslations: this.workflowTranslations,
      workflow: this.workflow
    }
    this.openWorkflowModal(initialState)
  }

  onStepListClone(newStepListData) {
    const initialState = {
      action: 'clone',
      stepDataList: newStepListData,
      selectedLang: this.selectedLang,
      workflowTranslations: this.workflowTranslations,
      workflow: this.workflow
    }
    this.openWorkflowModal(initialState)
  }

  openWorkflowModal(initialState) {
    let wfListModalRef = this.modalService.show(WorkflowsModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false
    })
    wfListModalRef.content.onWorkflowSelect.subscribe((selectedWorkflow) => {
      wfListModalRef.content.loaderService.show()
      if (!selectedWorkflow.isSingleStep) {
        this.cloneStepListToTargetWorkflow(selectedWorkflow.workflow).then((response) => {
          this.changeAvailable = false
          wfListModalRef.content.loaderService.hide()
          wfListModalRef.hide()
          this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.WORKFLOW.CLONED'))
          if (selectedWorkflow.action == 'move') {
            this.stepDelete.emit(initialState)
          }
        }).catch((error) => {
          wfListModalRef.content.loaderService.hide()
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.USER-LIST.ERROR'))
        })
      } else {
        this.cloneStepToTargetWorkflow(selectedWorkflow.workflow).then((response) => {
          this.changeAvailable = false
          wfListModalRef.content.loaderService.hide()
          wfListModalRef.hide()
          this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.WORKFLOW.CLONED'))
          if (selectedWorkflow.action == 'move') {
            this.stepDelete.emit(initialState)
          }
        }).catch((error) => {
          wfListModalRef.content.loaderService.hide()
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.USER-LIST.ERROR'))
        })
      }
    })
  }

  cloneStepToTargetWorkflow(targetWorkflow): Promise<any> {
    this.stepList2 = JSON.parse(JSON.stringify(this.workflowSteps))

    // if(Object.keys(this.subWorkflowsObject).length > 0) {
    //   Object.keys(this.subWorkflowsObject).forEach(res => {
    //     this.mapSubWorkflows(this.subWorkflowsObject[res], 'clone')
    //   })
    // }

    const workflow = JSON.parse(JSON.stringify(targetWorkflow))
    workflow.draft.steps = targetWorkflow.draft.steps.reduce((steps, stp) => {
      steps[stp.id] = { data: stp.data, coordinates: stp.coordinates }
      return steps
    }, {})
    return this.workflowService.setWorkflowDraft(workflow)
  }

  cloneStepListToTargetWorkflow(targetWorkflow) {
    const workflow = JSON.parse(JSON.stringify(targetWorkflow))
    workflow.draft.steps = targetWorkflow.draft.steps.reduce((steps, stp) => {
      steps[stp.id] = { data: stp.data, coordinates: stp.coordinates }
      return steps
    }, {})
    return this.workflowService.setWorkflowDraft(workflow)
  }

  onStepDelete(deletedStep: Step) {
    delete this.isStepLayoutValid[deletedStep.id]
    this.checkAllStepsValid()
  }

  checkAllStepsValid() {
    this.isAllStepsValid = Object.keys(this.isStepLayoutValid).reduce((stepsValid, stpId) => {
      const isAllLayoutsValid = Object.keys(this.isStepLayoutValid[stpId]).reduce((layoutsValid, layoutNo) => this.isStepLayoutValid[stpId][layoutNo] && layoutsValid, true)
      return isAllLayoutsValid && stepsValid
    }, true)
  }

  selectedStepFilesChange(event) {
    this.selectedStep.data['files']= event
  }

  sidebarToggle() {
    this.sidebarOpen = !this.sidebarOpen;
    localStorage.setItem('sidebarOpen', JSON.stringify(this.sidebarOpen))
    if (!this.sidebarOpen) {
      setTimeout(() => {
        this.longImage = false;
      }, 200);
    } else {
      this.longImage = true;
    }
    // Wait until the sidebar toggle (transition)
    setTimeout(() => this.setResolutionSize(), 500);
  }

  onMouseUp(event: any) {
    this.mouseUp = event;
    // this.workflowConnections.stepsAreaMouseUp(event);
    // this.workflowConnections.stepMouseUp();
  }

  emptyLayout(event) {
    this.deleteWidget = true
  }

  deleteWidgetChange(event) {
    this.deleteWidget = event
  }

  zoomOut() {
    this.zoomOutValue = true
  }

  zoomOutValueChange(event) {
    this.zoomOutValue = false
  }

  zoomIn() {
    this.zoomInValue = true
  }

  zoomInValueChange(event) {
    this.zoomInValue = false
  }

  fullSecreen() {
    this.fullMode = true
  }

  fullModeChange(event) {
    this.fullMode = false
  }

  fullSecreenOff() {
    this.fullMode = false
  }

  changeDefaultLanguage() {
    this.workflow.draft.defaultLanguage = this.selectedLang
  }

  deleteWorkflowLanguage(lang, event) {
    event.stopPropagation()
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.LANGUAGE.DELETE-LANGUAGE-OR-NOT'),
      cancelButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.ERROR-MESSAGE.CONFIRM'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.DELETE'),
      confirmButtonStyle: 'danger'
    };
    let modalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    modalRef.content.onClose.subscribe(res => {
      if (res) {
        if (this.selectedLang == lang.code) {
          let defaultLang = this.allLangs.find(lang => lang.code == this.workflow.draft.defaultLanguage)
          if (defaultLang) {
            this.changeCurrentLang(defaultLang)
          }
        }
        delete this.workflow.draft.translations[lang.code]
        this.workflowTranslations = this.workflow.draft.translations
      }
    })

  }

  patchPreviewData() {
    this.selectedLayoutNo = null
    if (this.selectedStepForPreview) {
      this.selectedStepForPreview?.data?.layouts.forEach((res, index) => {
        this.selectedStepForPreview.data.name = this.workflowTranslations[this.selectedLang][this.selectedStep.data.name]
        this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.label = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.label]
        if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder == '') {
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.placeholder = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.placeholder]
        }
        if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html || this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html == '') {
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.html = this.workflowTranslations[this.selectedLang][this.selectedStep.data.layouts[index].items[0].templateOptions.html]
        }
        if (this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options) {
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions['translationAvailable'] = this.workflowTranslations ? true : null
          this.selectedStepForPreview.data.layouts[index].items[0].templateOptions.options = this.selectedStep.data.layouts[index].items[0].templateOptions.options.map(option => { return { label: this.workflowTranslations[this.selectedLang][option.label], value: option.value } })
        }
      })
      this.selectedStepForPreview = JSON.parse(JSON.stringify(this.selectedStepForPreview))
    }
  }

  addNewLang(lang) {
    const initialState = {};
    let modalRef = this.modalService.show(WorkflowNewLanguageModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    modalRef.content.onClose.subscribe(res => {
      this.loaderService.show()
      if (!res) {
        this.workflow.draft.translations[lang.code] = JSON.parse(JSON.stringify(this.workflow.draft.translations[this.workflow.draft.defaultLanguage]))
        let emptyValues = {}
        Object.keys(this.workflow.draft.translations[lang.code]).forEach(key => {
          if (this.workflow.draft.translations[lang.code][key] == '') {
            emptyValues[key] = ''
            delete this.workflow.draft.translations[lang.code][key]
          }
        })

        let translationArray = []
        translationArray = Object.values(this.workflow.draft.translations[lang.code])
        let newHtmlObject = {}
        // translationArray.forEach((translationItem, index) => {
        //   if (translationItem && translationItem.match(/<[^>]*>/g)) {
        //     translationArray[index] = this.converter(translationItem, index, newHtmlObject)
        //   }
        // })

        let autoTranslateRequestData = {
          source: this.workflow.draft.defaultLanguage,
          destination: lang.code,
          translations: translationArray
        }

        this.workflowService.translateWorkflow(autoTranslateRequestData.source, autoTranslateRequestData.destination, autoTranslateRequestData.translations).then(res => {
          let responseArray = res.result

          // responseArray.forEach((responseItem, index) => {
          //   if (responseItem && responseItem.match(/\{\s*p\d+\}/g)) {
          //     responseArray[index] = this.converterPlaceholder(responseItem, index, newHtmlObject)
          //   }
          // })

          let selectedlanguageKeys = Object.keys(this.workflow.draft.translations[lang.code])
          let finalObject = {}
          selectedlanguageKeys.forEach((resp, index) => {
            finalObject[resp] = responseArray[index]
          })
          finalObject = { ...finalObject, ...emptyValues }
          this.workflow.draft.translations[lang.code] = finalObject
          this.workflowTranslations = this.workflow.draft.translations
          this.changeCurrentLang(lang)
          this.changeAvailable = true
        })
      } else {
        this.workflow.draft.translations[lang.code] = JSON.parse(JSON.stringify(this.workflow.draft.translations[this.workflow.draft.defaultLanguage]))
        this.workflowTranslations = this.workflow.draft.translations
        this.changeCurrentLang(lang)
        this.changeAvailable = true
      }
    })
  }

  converter(htmlString, index, htmlObject) {
    let counter = 1
    const replacement = {}
    const replaceString = htmlString.replace(/<[^>]*>/g, (match) => {
      const placeholder = `{p${counter++}}`
      replacement[placeholder] = match
      return placeholder
    })
    htmlObject[index] = replacement
    return replaceString
  }

  converterPlaceholder(htmlString, index, htmlObject) {
    const replaceString = htmlString.replace(/\{\s*p\d+\}/g, (match) => {
      let replacedItem = match.replace(/\s/g, "")
      return htmlObject[index][replacedItem]
    })
    return replaceString
  }

  changeCurrentLang(lang) {
    this.loaderService.show()
    this.workflowLanguageService.setCurrentLanguage(lang)
    this.currentLang = lang
    this.selectedLang = lang.code
    this.selectedLangName = lang.name
    this.workflow = { ...this.workflow }

    this.workflowTranslations = { ...this.workflowTranslations }
    this.workflowDetail = { ...this.workflowDetail }
    this.workflowDetail = {
      name: this.workflow[this.type].translations[this.selectedLang][this.workflow[this.type].name],
      description: this.workflow[this.type].translations[this.selectedLang][this.workflow[this.type].description],
      users: this.workflow.users ? [...this.workflow.users] : [],
      approvers: this.workflow.approvers ? [...this.workflow.approvers] : [],
      labels: this.workflow[this.type].labels ? [...this.workflow[this.type].labels] : [],
      estimatedDuration: this.workflow[this.type].estimatedDuration ? this.workflow[this.type].estimatedDuration : { hours: 0, minutes: 0 },
      showContentPage: this.workflow[this.type].showContentPage ? true : false,
      files: this.workflow[this.type].files ? this.workflow[this.type].files : [],
      detailExtraFields: this.workflow[this.type].detailExtraFields ? this.workflow[this.type].detailExtraFields : [],
      expertsToCall: this.workflow[this.type].expertsToCall ? [...this.workflow[this.type].expertsToCall] : [],
    }
    this.selectedLayoutNo = null
    this.patchPreviewData()
    this.loaderService.hide()
  }

  onPreviewEvent(event) {
    this.previewEventData = event
  }

  selectWorkflowToCopySteps(event) {
    this.subWorkflowsObject[event.workflowId] = event
  }

  deleteSubWorkflow(wfId) {
    delete this.subWorkflowsObject[wfId]
  }
}