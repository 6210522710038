<div class="file-container">
    <div class="card-deck d-flex flex-wrap justify-content-around" *ngIf="logFiles.length > 0">
      <div class="file-log col-4 border border-secondary mb-1 mt-2" *ngFor="let file of logFiles; index as i"
           placement="bottom" [popover]="popTemplate" [popoverContext]="{file:filesAndObjects[i]}" triggers="mouseenter:mouseleave" container="body">
        <div class="image-container d-flex justify-content-center mt-2">
          <img class="card-img-top" [src]="!filesAndObjects[i].src ? filesAndObjects[i].url : filesAndObjects[i].src">
        </div>
        <div class="file-info d-flex justify-content-center my-2">
          <div class="d-flex align-items-center pl-2">
            
            <div class="file-download bd-highlight">{{file.name}}</div>
          </div>
          <a class="download-icon d-flex align-items-center pr-1" (click)="onDownloadFile(file)">
            <img src="assets/img/download-step-file.svg" alt="" class="download-file">
          </a>
        </div>
        <div class="text-center mb-2" *ngIf="showCheckboxes">
            <input type="checkbox" class="form-check-input editor-access-checkbox"  [(ngModel)]="file.isChecked" (change)="onFileCheckboxChange(file)">
        </div>
      </div>
    </div>
  
    <div class="error-message" *ngIf="logFiles.length === 0">
      <p>{{'MAIN.SESSIONS.SESSION_FILES.NO_FILE_IS_ADDED' | translate}}</p>
    </div>
  </div>
  
  <ng-template #popTemplate let-file="file">
    <div class="file-name"><b>File Name:</b> {{ file.name }}</div>
    <div class="file-name" *ngIf="file.subfiles"><b>File Contents:</b></div>
    <li class="file-name" *ngFor="let f of file.subfiles">
      {{ f }}
    </li>
    <div class="file-size"><b>File Size:</b> {{ file.size | fileSize}}</div>
  </ng-template>