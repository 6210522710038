<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MAIN.LOBBY.NO_DEVICE_MODAL.TITLE' | translate }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            {{ 'MAIN.LOBBY.NO_DEVICE_MODAL.CALL_BODY' | translate }}
        </div>

        <div class="d-flex mb-5 top-gap-30 justify-content-center">
            <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary font-weight-600 me-1">
                {{ 'MAIN.CONTENT.DASHBOARD.CANCEL' | translate | titlecase }}
            </button>
            <button type="button" class="v-btn btn vsight-button btn-primary create-button">
                {{ 'MAIN.LOBBY.NO_DEVICE_MODAL.CALL' | translate }}
            </button>
        </div>
    </div>
</div>