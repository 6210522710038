import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-ewers-form',
  template: `
  <ng-container>
                <div class="fs-14 font-weight-500 cursor-pointer" *ngIf="to['formData']?.length>2">
                    <div style="position: relative; min-height: 200px; cursor: pointer; overflow: scroll;">
                                
                        <div class="d-flex flex-column col-12">
                            <div class="fs-15 mb-3 mt-2">Auftraggeber</div>

                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Firma</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Ansprechpartner</label></div>
                                <div class="col-6"><input type="text" class=" form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Straße</label></div>
                                <div class="col-6"><input type="text" class=" form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>PLZ / Ort:</label></div>
                                <div class="col-6"><input type="text" class=" form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Telefon</label></div>
                                <div class="col-6"><input type="text" class=" form-control h-30"></div>
                            </div>
                        </div>
                        <hr>

                        <div class="d-flex flex-column col-12">
                            <div class="fs-15 mb-3 mt-2">Einbauort</div>

                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Firma</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Ansprechpartner</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Straße</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>PLZ / Ort:</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Telefon</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                        </div>
                        <hr>

                        <div class="d-flex flex-column col-12">
                            <div class="fs-15 mb-3 mt-2">Station</div>

                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Typ</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Auftrags-Nr. / Serien-Nr.</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                        </div>
                        <hr>
                        
                        <div class="d-flex flex-column col-12">
                            <div class="fs-15 mb-3 mt-2">Termin</div>

                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Datum</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Uhrzeit</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                             <div class="d-flex align-items-center mb-2">
                                <div class="col-6"><label>Außentemperatur</label></div>
                                <div class="col-6"><input type="text" class="form-control h-30"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fs-14 font-weight-500 cursor-pointer" *ngIf="to['formData']?.length==2">
                <div style="position: relative; min-height: 200px; cursor: pointer; overflow: scroll;">
                                
                <div class="d-flex flex-column col-12">
                    <div class="fs-15 mb-3 mt-2">Auftraggeber</div>

                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Firma</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Ansprechpartner</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Straße</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>PLZ / Ort:</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Telefon</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Typ</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Datum</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Uhrzeit</label></div>
                        <div class="col-6"><input type="text" class=" form-control h-30"></div>
                    </div>
                </div>
                <hr>

                <div class="d-flex flex-column col-12">
                    <div class="fs-15 mb-3 mt-2">Einbauort</div>

                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Firma</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Ansprechpartner</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Straße</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>PLZ / Ort:</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Telefon</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Auftrags-Nr.</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-6"><label>Außentemperatur</label></div>
                        <div class="col-6"><input type="text" class="form-control h-30"></div>
                    </div>
                </div>
            </div>
                </div>
            </ng-container>
  `,
})
export class FormlyFieldCustomEwersForm extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}