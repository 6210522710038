import { Injectable } from '@angular/core';

import { map, filter, first } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription, firstValueFrom, Subject } from 'rxjs';

import { Session, SessionResponse } from '@models/Session';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@services/auth.service';
import { UtilityService } from './utility.service';
import { LogFile } from '@models/LogFile';
import { LogMessage } from '@models/LogMessage';
import { Archive } from '@models/Archive';
import { DbService } from '@services/db.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  sessionExportRequest: Subject<[string, string, boolean]> = new Subject<[string, string, boolean]>();

  lastSessions: Observable<Session[]>;
  private lastSessionsSource = new BehaviorSubject<Session[]>(null);
  private lastSessionsSub: Subscription = null;

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.lastSessions = this.lastSessionsSource.asObservable().pipe(filter(sessions => !!sessions));
    
    // this.authService.on("login", (account_id: string) => {
    //   this.startListenningLastSessions(account_id);
    // });
    // this.authService.on("logout", () => {
    //   this.stopListenningLastSessions();
    // });
  }

  startListenningLastSessions(account_id: string) {
    if (this.lastSessionsSub) { this.lastSessionsSub.unsubscribe() }

    this.lastSessionsSub = new Observable<Archive[]>(subscriber => {
      const sessions: Archive[] = [];
      // when this method used with combineLatest,
      // and if there is no archives,
      // blocks other observable(s) without following:
      subscriber.next(sessions);

      const sub1 = this.dbService.querySnap<Session>(`accounts/${account_id}/sessions_meta`, [{key: "orderByKey", value: null}, {key: "limitToLast", value: 3}], "child_added")
        .subscribe(snp => {
          const session = snp.data;
          session.id = snp.key;
          sessions.push(session);
          subscriber.next(sessions);
        });

      const sub2 = this.dbService.querySnap<Session>(`accounts/${account_id}/sessions_meta`, [{key: "orderByKey", value: null}, {key: "limitToLast", value: 3}], "child_changed")
        .subscribe(snp => {
          const i = sessions.findIndex(s => s.id === snp.key);
          const session = snp.data;
          session.id = snp.key;
          if (i>-1) {
            sessions[i] = session;
          } else {
            sessions.push(session);
          }
          subscriber.next(sessions);
        });

      const sub3 = this.dbService.querySnap<Session>(`accounts/${account_id}/sessions_meta`, [{key: "orderByKey", value: null}, {key: "limitToLast", value: 3}], "child_removed")
        .subscribe(snp => {
          const i = sessions.findIndex(s => s.id === snp.key);
          if (i>-1) {
            sessions.splice(i, 1);
            subscriber.next(sessions);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    }).subscribe(sessions => {
      this.lastSessionsSource.next(sessions.reverse());
    });
  }

  getSessionLogs(session: any): Observable<LogMessage[]> {
    const roomId = session.room ? session.room.id : session.room_id;

    return new Observable<LogMessage[]>(subscriber => {
      const list: LogMessage[] = [];
      subscriber.next(list);
      const sub1 = this.dbService.listenSnap<LogMessage>(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${session.id}/logs`, "child_added")
      .subscribe(snp => {
        const data = snp.data;
        data.id = snp.key;
        list.push(data);
        subscriber.next(list);
      });
      const sub2 = this.dbService.listenSnap<LogMessage>(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${session.id}/logs`, "child_changed")
      .subscribe(snp => {
        const i = list.findIndex(s => s.id === snp.key);
        const data = snp.data;
        data.id = snp.key;
        if (i>-1) {
          list[i] = data;
        } else {
          list.push(data);
        }
        subscriber.next(list);
      });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      }
    });
  }

  getSessionFiles(session: Session): Observable<LogFile[]> {
    return new Observable<LogFile[]>(subscriber => {
      const list: LogFile[] = [];
      subscriber.next(list);
      const sub1 = this.dbService.listenSnap<LogFile>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/files`, "child_added")
      .subscribe(snp => {
        const data = snp.data;
        data.id = snp.key;
        list.push(data);
        subscriber.next(list);
      });
      const sub2 = this.dbService.listenSnap<LogFile>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/files`, "child_changed")
      .subscribe(snp => {
        const i = list.findIndex(s => s.id === snp.key);
        const data = snp.data;
        data.id = snp.key;
        if (i>-1) {
          list[i] = data;
        } else {
          list.push(data);
        }
        subscriber.next(list);
      });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      }
    });
  }

  getSessionObjects(session: Session): Observable<LogFile[]> {
    return new Observable<LogFile[]>(subscriber => {
      const list: LogFile[] = [];
      subscriber.next(list);
      const sub1 = this.dbService.listenSnap<LogFile>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/objects`, "child_added")
      .subscribe(snp => {
        const data = snp.data;
        data.id = snp.key;
        list.push(data);
        subscriber.next(list);
      });
      const sub2 = this.dbService.listenSnap<LogFile>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/objects`, "child_changed")
      .subscribe(snp => {
        const i = list.findIndex(s => s.id === snp.key);
        const data = snp.data;
        data.id = snp.key;
        if (i>-1) {
          list[i] = data;
        } else {
          list.push(data);
        }
        subscriber.next(list);
      });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      }
    });
  }

  stopListenningLastSessions() {
    if (this.lastSessionsSub) { this.lastSessionsSub.unsubscribe() }
    this.lastSessionsSource.next(null);
  }

  getLastMonthSessionCount() {
    return this.dbService.query(`accounts/${this.authService.currentUser.account_id}/statistics/sessions_stats/session_count`, [{key: "orderByKey", value: null}, {key: "limitToLast", value: 30}])
    .pipe(
      map(sessionCountObj => {
        const labels = sessionCountObj ? Object.keys(sessionCountObj) : []
        return labels.map(label => { return { label: label, value: sessionCountObj[label] } }).reverse()
      })
    )
  }

  getRoomSessions(roomId: string): Observable<Session[]> {
    return new Observable<Session[]>(subscriber => {
      const sessions: Session[] = [];
      // when this method used with combineLatest,
      // and if there is no archives,
      // blocks other observable(s) without following:
      subscriber.next(sessions);

      const sub1 = this.dbService.querySnap<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
          {key: "orderByChild", value: "room_id"},
          {key: "equalTo", value: roomId},
          {key: "limitToLast", value: 3}
        ], "child_added")
        .subscribe(snp => {
          const session = snp.data;
          session.id = snp.key;
          sessions.push(session);
          subscriber.next(sessions);
        });

      const sub2 = this.dbService.querySnap<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
          {key: "orderByChild", value: "room_id"},
          {key: "equalTo", value: roomId},
          {key: "limitToLast", value: 3}
        ], "child_changed")
        .subscribe(snp => {
          const i = sessions.findIndex(a => a.id === snp.key);
          const session = snp.data;
          session.id = snp.key;
          if (i>-1) {
            sessions[i] = session;
          } else {
            sessions.push(session);
          }
          subscriber.next(sessions);
        });

      const sub3 = this.dbService.querySnap<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
          {key: "orderByChild", value: "room_id"},
          {key: "equalTo", value: roomId},
          {key: "limitToLast", value: 3}
        ], "child_removed")
        .subscribe(snp => {
          const i = sessions.findIndex(s => s.id === snp.key);
          if (i>-1) {
            sessions.splice(i, 1);
            subscriber.next(sessions);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    }).pipe(map(s => s.reverse()));
  }

  getSessionsAll() {
    return new Observable<Session[]>(subscriber => {
      const sessions: Session[] = [];
      // when this method used with combineLatest,
      // and if there is no archives,
      // blocks other observable(s) without following:
      subscriber.next(sessions);

      const sub1 = this.dbService.listenSnap<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, "child_added")
        .subscribe(snp => {
          const session = snp.data;
          session.id = snp.key;
          sessions.push(session);
          subscriber.next(sessions);
        });

      const sub2 = this.dbService.listenSnap<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, "child_changed")
        .subscribe(snp => {
          const i = sessions.findIndex(a => a.id === snp.key);
          const session = snp.data;
          session.id = snp.key;
          if (i>-1) {
            sessions[i] = session;
          } else {
            sessions.push(session);
          }
          subscriber.next(sessions);
        });

      const sub3 = this.dbService.listenSnap<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, "child_removed")
        .subscribe(snp => {
          const i = sessions.findIndex(s => s.id === snp.key);
          if (i>-1) {
            sessions.splice(i, 1);
            subscriber.next(sessions);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    }).pipe(map(s => s.reverse()));
  }

  getSessionsIn(startTime: number, endTime: number) {
    return new Observable<Session[]>(subscriber => {
      const sessions: Session[] = [];
      // when this method used with combineLatest,
      // and if there is no archives,
      // blocks other observable(s) without following:
      subscriber.next(sessions);

      const sub1 = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
          {key: "orderByChild", value: "create_time"},
          {key: "startAt", value: startTime},
          {key: "endAt", value: endTime}
        ], "child_added")
        .subscribe(snp => {
          const session = snp.data;
          session.id = snp.key;
          sessions.push(session);
          subscriber.next(sessions);
        });

      const sub2 = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
          {key: "orderByChild", value: "create_time"},
          {key: "startAt", value: startTime},
          {key: "endAt", value: endTime}
        ], "child_changed")
        .subscribe(snp => {
          const i = sessions.findIndex(a => a.id === snp.key);
          const session = snp.data;
          session.id = snp.key;
          if (i>-1) {
            sessions[i] = session;
          } else {
            sessions.push(session);
          }
          subscriber.next(sessions);
        });

      const sub3 = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
          {key: "orderByChild", value: "create_time"},
          {key: "startAt", value: startTime},
          {key: "endAt", value: endTime}
        ], "child_removed")
        .subscribe(snp => {
          const i = sessions.findIndex(s => s.id === snp.key);
          if (i>-1) {
            sessions.splice(i, 1);
            subscriber.next(sessions);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    }).pipe(map(s => s.reverse()));
  }

  getSession(sessionId: string): Observable<Session> {
    return this.dbService.listen<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta/${sessionId}`)
      .pipe(map(session => {
        session.id = sessionId;
        return session;
      }));
  }

  getSessionsAtYear(year: number, startDate: Date, endDate: Date): Promise<any[]> {
    const dateStart = new Date(year, 0, 0, 0, 0, 0, 0);
    const oneYearInMilliseconds = 31556952000;

    return firstValueFrom(this.dbService.query<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
        {key: "orderByChild", value: "create_time"},
        {key: "startAt", value: dateStart.getTime()},
        {key: "endAt", value: dateStart.getTime() + oneYearInMilliseconds}
      ]))
      .then(sessions => {
        const filteredSessions = sessions ? Object.values(sessions).filter((session: any) => {
          const sessionDate = new Date(session.start_time);
          return sessionDate >= startDate && sessionDate <= endDate;
        }) : [];
        return filteredSessions;
      });
  }

  getRooms() {
    return this.dbService.get<any>(`accounts/${this.authService.currentUser.account_id}/rooms`)
  }

  getSessionsAtDateRange(startDate: Date, endDate: Date): Promise<any> {
    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime() + 86399999; 

    return firstValueFrom(this.dbService.query<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [
      {key: "orderByChild", value: "create_time"},
      {key: "startAt", value: startTimestamp},
      {key: "endAt", value: endTimestamp}
    ]))
    .then(sessionsObj => {
      const sessionIds = sessionsObj ? Object.keys(sessionsObj) : []
      const sessions: any[] = [];

      for (const sid of sessionIds) {
        sessions.push({id: sid, ...sessionsObj[sid]});
      }
      return sessions;
    });
  }

  getSessionData(roomId: string, sessionId: string) {
    return this.dbService.get<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data`)
  }

  exportSession(room_id: string, session_id, recipients: string[]) {
    return this.http.post(environment.endPoints.exportsession, {
      token: this.authService.currentUser.token,
      room: room_id,
      session: session_id,
      from_admin: true,
      recipients: recipients,
      app_name: environment.design.appName
    }).toPromise()
  }

  setExportName(roomId: string, sessionId: string, text: string) {
    this.dbService.update(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data/export_name`, {name: text, author: this.authService.currentUser.name})
  }

  getSessionArchives(room_id: string, session_id: string): Observable<Archive[]> {
    return new Observable<Archive[]>(subscriber => {
      const archives: Archive[] = [];
      // when this method used with combineLatest,
      // and if there is no archives,
      // blocks other observable(s) without following:
      subscriber.next(archives);

      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${room_id}/${session_id}/archives`, "child_added")
        .subscribe(snp => {
          const archive = snp.data;
          archive.id = snp.key;
          archives.push(archive);
          subscriber.next(archives);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${room_id}/${session_id}/archives`, "child_changed")
        .subscribe(snp => {
          const i = archives.findIndex(a => a.id === snp.key);
          const archive = snp.data;
          archive.id = snp.key;
          if (i>-1) {
            archives[i] = archive;
          } else {
            archives.push(archive);
          }
          subscriber.next(archives);
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      }
    });
  }

  checkSessionArchives(roomId: string, sessionId: string) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "check", room: roomId, session: sessionId }).toPromise();
  }

  downloadArchive(roomId: string, sessionId: string, archive: Archive) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "get", room: roomId, session: sessionId, archive: archive.id }).toPromise()
    .then(response => response.url);
  }

  deleteArchive(roomId: string, sessionId: string, archive: Archive) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "delete", room: roomId, session: sessionId, archive: archive.id  }).toPromise();
  }

  getUserSessions(): Promise<SessionResponse> {
    const url = environment.endPoints.getUserSessions
    return this.http
      .post<SessionResponse>(url, { token: this.authService.currentUser.token })
      .toPromise();
  }

//   async getAccountTimezoneDateString() {
//     const [offset, timezone] = await Promise.all([
//       this.utilityService.getServerTimeOffset(),
//       this.accountService.accountData.pipe(first(), map(a => a.timezone)).toPromise()
//     ]);
//     // Todays timestamp + firebase correction offset + Date object offset (reset to UTC) + Account timezone offset
//     // Created object has local timezone metadata, but year-month-day is correct for account timezone
//     const serverTime = new Date(Date.now() + offset + UtilityService.timezoneOffset + UtilityService.timezones[timezone]);
//     return serverTime.getFullYear() + '-' + ("0"+(serverTime.getMonth()+1)).slice(-2) + '-' + ("0" + serverTime.getDate()).slice(-2);
//   }
/*
  getSessions(): Observable<any[]> {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`)
    .snapshotChanges(["child_added", "child_changed"])
    .pipe(
      map(sessionSnaps => {
        return sessionSnaps.map(sessionSnap => {
          const s = sessionSnap.payload.val();
          s.id = sessionSnap.key;
          return s;
        }).reverse();
      })
    );
  }

  getSessionsByPage(offset: number, endKey: string): Observable<any[]> {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, ref => {
      return endKey ? ref.orderByKey().limitToLast(offset+1).endAt(endKey) : ref.orderByKey().limitToLast(offset+1);
    })
    .snapshotChanges(["child_added", "child_removed", "child_changed"])
    .pipe(
      map(sessionSnaps => {
        return sessionSnaps.map(sessionSnap => {
          const s = sessionSnap.payload.val();
          s.id = sessionSnap.key;
          return s;
        }).reverse();
      })
    );
  }
*/
}
