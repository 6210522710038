import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RoomService } from "@services/remote/room.service";

@Pipe({name: 'roomName'})
export class RoomNamePipe implements PipeTransform {

    constructor(private roomService: RoomService) {}

    transform(value: string, args?: any): Observable<string> {
        return this.roomService.allRooms.pipe(map(rooms => {
            const room = rooms.find(r => r.id === value);
            return room ? room.room_data.name : "Unknown";
        }));
    }
}