<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MODALS.TEMPLATE.CREATE-STEP' | translate }}</div>
            <div class="cursor-pointer"  (click)="modalRef.hide()">
                <img src="assets/img/close_white.svg" alt="">
            </div>
        </div>
    
        <div class="card-body modal-card-body p-0" style="padding-bottom: 20px !important;">
            <div class="card-body-overlay">
                <label class="fs-14 text-dark-grey mb-3">Select the section where the new step will be added:</label>
                <div *ngFor="let section of sectionList" class="section fs-14 ms-2 me-2 mt-2 text-start ps-3 d-flex align-items-center" [class.selected]="section.selected" (click)="select(section)">
                    <div *ngIf="!workflowTranslations">{{section.data.name}}</div>
                    <div *ngIf="workflowTranslations">{{workflowTranslations[selectedLang][section.data.name]}}</div>
                </div>
                
            </div>
    
            <div class="d-flex top-gap-30 justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary" (click)="modalRef.hide()">
                    {{ 'MODALS.ALERT.CANCEL' | translate }}
                </button>
                <button type="button" class="v-btn vsight-button btn btn-primary"
                    (click)="selectParent()" [disabled]="!selectedSection">
                    {{ 'MODALS.TEMPLATE.CREATE-STEP' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>