<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">Make an Instant Call</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <div class="contact-body">
                <app-call-users (onSelectedUsersChanged)="onSelectedUsersChanged($event)" (onCallUser)="onCallUser($event)"></app-call-users>
            </div>

            <div class="d-flex top-gap justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary" (click)="close()">
                    {{ 'MODALS.ALERT.CANCEL' | translate }}
                </button>
                <button type="button" class="v-btn vsight-button btn btn-primary" (click)="onCallUsers()" [disabled]="selectedUsers.length < 2">
                    Call Multiple
                </button>
            </div>
        </div>
    </div>
</div>
