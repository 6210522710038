<div class="user-wrapper">
    <div class="user-detail-container">
        <div class="v-card">
            <div class="card list-header flex-row justify-content-between align-items-center pe-4 ps-2">
                <div *ngIf="detailMode === 'create'" class="header font-weight-700 fs-20 pt-1"><img class="back-button ps-1 pe-3 py-2" (click)="onBackButton()" src="assets/img/arrow-back.svg" alt="">{{ 'MAIN.CONTENT.USER-DETAIL.CREATE_TITLE' | translate }}</div>
                <div *ngIf="detailMode === 'edit'" class="header font-weight-700 fs-20 pt-1">
                    <img class="back-button ps-1 pe-3 py-2" (click)="onBackButton()" src="assets/img/back-blue.svg" alt="">
                    {{ 'MAIN.CONTENT.USER-DETAIL.EDIT_TITLE' | translate }}
                </div>
                <div>
                    <button *ngIf="detailMode === 'edit'" class="v-btn btn btn-primary fs-15 text-white delete-button" type="button" (click)="deleteUser(user.id)">
                        {{ 'MAIN.CONTENT.USER-DETAIL.DELETE_USER' | translate| titlecase }}
                    </button>
                    <button *ngIf="detailMode === 'edit'" class="v-btn btn btn-primary fs-15 text-white detail-button ms-3" type="button" (click)="onSaveChanges()" [disabled]="form['name'].errors || form['username'].errors?.['required'] || (form['username'].errors?.['uniqueUsername'] && (user?.auth.username !== form['username'].value)) || form['email'].errors" [class.disabledd]="formPristine">
                        {{ 'MAIN.CONTENT.USER-DETAIL.SAVE_CHANGES' | translate | titlecase }}
                    </button>
                 
                </div>
                <button *ngIf="detailMode === 'create'" class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" type="submit" [disabled]="!userDetailForm.valid" > {{ 'MAIN.CONTENT.USER-LIST.CREATE' | translate | titlecase }}</button>
            </div>
            <div class="card-body ps-5 d-flex detail-body">
                <div class="col-7 pe-5">
                    <form [formGroup]="userDetailForm" (ngSubmit)="onUserSubmitForm()" autocomplete="off">
                        <div class="d-flex flex-wrap">
                            <div *ngIf="detailMode === 'edit'" class="w-100"></div>
                            <div class="col-6 pe-3">
                                <label class="form-label fs-14 mb-2">{{ 'MAIN.CONTENT.USER-DETAIL.NAME' | translate }}<span class="fs-14">*</span></label>
                                <input type="text" class="form-control fs-14" maxlength="60" [ngClass]="{'is-invalid': (form['name'].errors?.['required'] && form['name'].touched) || (form['name'].errors?.['pattern'] && !form['name'].pristine)}" formControlName="name">
                                <small class="invalid-feedback mx-2" [hidden]="!form['name'].errors?.['required']">{{ 'MAIN.CONTENT.USER-DETAIL.NAME_REQUIRED' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['name'].errors?.['pattern']">{{ 'MAIN.CONTENT.USER-DETAIL.NAME_PATTERN' | translate }}</small>
                            </div>
                            <div class="col-6 ps-3">
                                <label class="form-label fs-14 mb-2">{{ 'MAIN.CONTENT.USER-DETAIL.USERNAME' | translate }}<span class="fs-14">*</span></label>
                                <input type="text" class="form-control fs-14" maxlength="30" [ngClass]="{'is-invalid': ((form['username'].errors?.['required'] || form['username'].errors?.['pattern']) && form['username'].touched) || (form['username'].errors?.['uniqueUsername'] && (user?.auth.username !== form['username'].value) && !form['username'].pristine)}" formControlName="username">
                                <small class="invalid-feedback mx-2" [hidden]="!form['username'].errors?.['required']">{{ 'MAIN.CONTENT.USER-DETAIL.USERNAME_REQUIRED' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['username'].errors?.['pattern'] || form['username'].errors?.['required']">{{ 'MAIN.CONTENT.USER-DETAIL.USERNAME_PATTERN' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['username'].errors?.['uniqueUsername'] || (form['username'].errors?.['uniqueUsername'] && user?.auth.username === form['username'].value)">{{ 'MAIN.CONTENT.USER-DETAIL.USERNAME_EXISTS' | translate }}</small>
                            </div>
                             
                            <div class="col-6 pe-3 mt-4">
                                <label class="form-label fs-14 mb-2">{{ 'MAIN.CONTENT.USER-DETAIL.EMAIL' | translate }}<span class="fs-14">*</span></label>
                                <input type="text" class="form-control fs-14"
                                [ngClass]="{'is-invalid': (form['email'].errors?.['required'] &&
                                 (form['email'].touched && detailMode == 'create' || (detailMode == 'edit'))) || 
                                 form['email'].errors?.['pattern'] && 
                                 (form['email'].touched && !form['email'].pristine)}" autocomplete="new-email" formControlName="email">
                                <small class="invalid-feedback mx-2" [hidden]="!form['email'].errors?.['required']">{{ 'MAIN.CONTENT.USER-DETAIL.EMAIL_REQUIRED' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['email'].errors?.['pattern']">{{ 'MAIN.CONTENT.USER-DETAIL.INVALID_EMAIL' | translate }}</small>
                            </div>

                            <div class="col-6 ps-3 mt-4">
                                <label class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.USER-DETAIL.ROLE' | translate }}<span class="fs-14">*</span></label>
                                <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0 ms-0 role-action-button" dropdown style="border: none; width: 100%;">
                                    <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 hover-style role-dropdown"  #toggleButton dropdownToggle data-bs-auto-close="inside">
                                    {{ selectedRole | titlecase }}
                                    </button>
                                    <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split role-split-button"  (click)="$event.stopPropagation(); toggleButton.click()"
                                            aria-controls="dropdown-split">
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu"  style="max-height: 240px; 
                                    overflow-y: scroll;" aria-labelledby="dropdownMenuClickableInside">
                                        <div *ngFor="let role of userRoles; let index = index" class="ps-2" (click)="selectRole(role)">
                                            <div class="custom-control custom-checkbox custom-checkbox-green" [ngClass]="{'mt-2':index == 0}">
                                                <label class="custom-control-label label-form fs-14" [for]="index">
                                                    {{ role | titlecase }}
                                                </label>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-6 pe-3 mt-4">
                                <label class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.TITLE' | translate }}</label>
                                <ng-select [items]="labels" [loading]="loading" formControlName="labels" [addTag]="addTagPromise.bind(this)" [multiple]="true" class="mt-2">

                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.CREATE' | translate }}</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>

                            <div class="col-12 mt-4">
                                <div class="form-group d-flex align-items-center mt-1">
                                    <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="editorAccessAvailability" name="editorAccessAvailability" formControlName="editorAccess" [disabled]="true">
                                    <label class="form-check-label fs-14 font-weight-500" for="editorAccessAvailability">{{ 'MAIN.CONTENT.USER-DETAIL.EDITOR-ACCESS' | translate }}
                                        <img src="assets/img/info_icon.svg" height="20" class="ms-1" [dynamicTooltip]="('MAIN.CONTENT.USER-DETAIL.EDITOR-ACCESS-TEXT' | translate)" placement="right" style="margin-bottom: 1px;">
                                    </label>
                                </div>
                            </div>
                            
                            <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>

                            <div class="form-row mt-4 col-12">
                                <div class="mb-3">
                                    <label class="fs-14 font-weight-600">Remote Assistance</label>
                                </div>
                                <div class="form-group col-12 mt-3">
                                  <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                                    <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="allowArchivingAvailability" name="allowArchivingAvailability" formControlName="allow_archiving">
                                    <label class="form-check-label fs-14 font-weight-500" for="allow_archiving">{{ 'MAIN.USERS.USER_DETAILS.ALLOW_ARCHIVING' | translate }}</label>
                                  </div>
                                  <!-- <label [ngClass]="{'d-none': accountArchiveAllowed}" class="text-danger" for="allow_archiving">{{ 'MAIN.USERS.USER_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label> -->
                                </div>
                    
                                <div class="form-group col-12 mt-3" *ngIf="accountCallingAllowed">
                                  <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountCallingAllowed">
                                    <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="allowCallingAvailability" name="allowCallingAvailability" formControlName="allow_calling">
                                    <label class="form-check-label fs-14 font-weight-500" for="allow_calling">{{ 'MAIN.USERS.USER_DETAILS.ALLOW_CALLING' | translate }}</label>
                                    <img src="assets/img/info_icon.svg" height="20" class="ms-1" [dynamicTooltip]="('MAIN.USERS.USER_DETAILS.CALL_INFO_MODAL.MESSAGE' | translate)" placement="right" style="margin-bottom: 1px;">
                                  </div>
                                  <!-- <label [ngClass]="{'d-none': accountCallingAllowed}" class="text-danger" for="allow_calling">{{ 'MAIN.USERS.USER_DETAILS.CALLING_UNAVAILABLE' | translate }}</label> -->
                                </div>
                                <div class="form-group pl-4 col-xl-6 mt-3">
                                    <select class="form-control" id="allowCallingAllUsersAvailability" name="allowCallingAllUsersAvailability" formControlName="allow_seeing_all_users" [disabled]="!accountCallingAllowed || !user.allow_calling">
                                      <option [ngValue]="false">{{ 'MAIN.USERS.USER_DETAILS.PERMISSION_TO_SEE_USERS_IN_SAME_ROOM' | translate }}</option>
                                      <option [ngValue]="true">{{ 'MAIN.USERS.USER_DETAILS.PERMISSION_TO_SEE_USERS_ALL' | translate }}</option>
                                    </select>
                                </div>
                    
                                <div class="form-group col-12 mt-3">
                                  <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountJoinLinkAllowed">
                                    <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="allowCJoinLinkAvailability" name="allowJoinLinkAvailability" formControlName="allowJoinLink" [disabled]="!accountJoinLinkAllowed">
                                    <label class="form-check-label fs-14 font-weight-500" for="allow_join_link">{{ 'MAIN.USERS.USER_DETAILS.ALLOW_JOIN_LINK' | translate }}</label>
                                  </div>
                                  <!-- <label [ngClass]="{'d-none': accountJoinLinkAllowed, 'text-danger': !accountJoinLinkAllowed}" for="allow_join_link">{{ 'MAIN.USERS.USER_DETAILS.JOIN_LINK_UNAVAILABLE' | translate }}</label> -->
                                </div>
                              </div>

                              <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>

                              <div class="form-row mt-4 col-12">
                                <div class="mb-3">
                                    <label class="fs-14 font-weight-600">Rooms</label>
                                </div>
                                <div class="form-group col-12 mt-3" *ngIf="('design' | env).showArchivePermission">
                                    <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                                      <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="allowArchivingAvailability" name="allowArchivingAvailability" formControlName="call_archive_perm_needed">
                                      <label class="form-check-label fs-14 font-weight-500" for="call_archive_perm_needed">{{ 'MAIN.USERS.USER_DETAILS.CALL_ARCHIVE_PERM_NEEDED' | translate }}</label>
                                    </div>
                                    <!-- <label [ngClass]="{'d-none': accountArchiveAllowed}" class="text-danger" for="call_archive_perm_needed">{{ 'MAIN.USERS.USER_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label> -->
                                </div>

                                <div class="form-group col-12 mt-3" *ngIf="accountFullHdAllowed">
                                    <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="accountAlwaysFullHd">
                                      <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="call_full_hdAvailability" name="call_full_hdAvailability" formControlName="call_full_hd">
                                      <label class="form-check-label fs-14 font-weight-500" for="call_full_hd">{{ 'MAIN.ROOMS.ROOM_DETAILS.FULL_HD' | translate }}
                                        <img src="assets/img/info_icon.svg" height="20" class="ms-1" [dynamicTooltip]="('MAIN.ROOMS.ROOM_DETAILS.FULL_HD_DISADVANTAGES' | translate)" placement="right" style="margin-bottom: 1px;">

                                      </label>
                                    </div>
                                    <!-- <label *ngIf="!accountAlwaysFullHd" class="text-danger" for="call_full_hd">{{ 'MAIN.ROOMS.ROOM_DETAILS.FULL_HD_DISADVANTAGES' | translate }}</label> -->
                                </div>

                                <div class="form-group mt-3" *ngIf="accountAutoJoinRoomAllowed">
                                    <label for="default_room" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.USERS.USER_DETAILS.DEFAULT_ROOM' | translate }}</label>
                                    <ng-select [items]="selectedUserRooms" name="default_room" bindLabel="name" bindValue="id" [clearable]= "true" id="defaultRoomAvailability" name="defaultRoomAvailability" formControlName="defaultRoom" [loading]="roomsLoading"
                                      placeholder="{{ 'MAIN.USERS.USER_DETAILS.DEFAULT_ROOM' | translate }}">
                                    </ng-select>
                                  </div>
                        
                                  <div class="form-group mt-3">
                                    <label for="rooms" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.USERS.USER_DETAILS.ROOMS' | translate }}</label>
                                    <ng-select [items]="rooms" name="rooms" bindLabel="name" bindValue="id" [multiple]="true" [clearSearchOnAdd] = "true" (change)="userRoomsChanged($event)"
                                      placeholder="{{ 'MAIN.USERS.USER_DETAILS.SELECT_ROOMS' | translate }}" id="roomsAvailability" name="roomsAvailability" formControlName="rooms" [closeOnSelect]="false" [loading]="roomsLoading">
                                    </ng-select>
                                  </div>
                              </div>
                            <!-- <div class="remote-assistance">
                                <div class="col-12 mt-4">
                                    <div class="form-group d-flex align-items-center mt-1">
                                        <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="editorAccessAvailability" name="editorAccessAvailability" formControlName="editorAccess" [disabled]="true">
                                        <label class="form-check-label fs-14 font-weight-500" for="editorAccessAvailability">{{ 'MAIN.CONTENT.USER-DETAIL.EDITOR-ACCESS' | translate }}
                                            <img src="assets/img/info_icon.svg" height="20" class="ms-1" [dynamicTooltip]="('MAIN.CONTENT.USER-DETAIL.EDITOR-ACCESS-TEXT' | translate)" placement="right" style="margin-bottom: 1px;">
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                            <div class="w-100"></div>
                            <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
                            <span *ngIf="detailMode === 'edit'" class="fs-14 font-weight-600 mt-4 mb-1">{{ 'MAIN.CONTENT.USER-DETAIL.CHANGE_PASSWORD' | translate }}</span>
                            <span *ngIf="detailMode === 'create'" class="fs-14 font-weight-600 mt-4 mb-1">{{ 'MAIN.CONTENT.USER-DETAIL.CREATE_PASSWORD' | translate }}</span>
                            <div class="w-100"></div>
                            <div class="col-6 pe-3 mt-4">
                                <label *ngIf="detailMode === 'create'" class="form-label fs-14 mb-2">{{ 'LOGIN.PASSWORD-PLACEHOLDER' | translate }}<span class="form-label fs-14">*</span></label>
                                <label *ngIf="detailMode === 'edit'" class="form-label fs-14 mb-2">{{ 'MAIN.CONTENT.USER-DETAIL.NEW_PASSWORD' | translate }}<span class="form-label fs-14">*</span></label>
                                <input type="password" class="form-control fs-14" autocomplete="new-password" simplePasswordValidator [ngClass]="{'is-invalid': (form['password'].errors?.['required'] || form['password'].errors?.['simplePass'] || form['password'].errors?.['passwordRequirements']) && form['password'].touched}" formControlName="password">
                                <small class="invalid-feedback mx-2" [hidden]="!form['password'].errors?.['simplePass']">Do not create weak passwords like '123456'</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['password'].errors?.['required']">{{ 'MAIN.CONTENT.USER-DETAIL.PASSWORD_REQUIRED' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['password'].errors?.['passwordRequirements']">{{ 'MAIN.CONTENT.USER-DETAIL.PASSWORD_REQUIREMENTS' | translate }}</small>
                            </div>
                            <div class="col-6 ps-3 mt-4">
                                <label *ngIf="detailMode === 'create'" class="form-label fs-14 mb-2">{{ 'MAIN.CONTENT.USER-DETAIL.CONFIRM_PASSWORD' | translate }}<span class="form-label fs-14">*</span></label>
                                <label *ngIf="detailMode === 'edit'" class="form-label fs-14 mb-2">{{ 'MAIN.CONTENT.USER-DETAIL.CONFIRM_NEW_PASSWORD' | translate }}<span class="form-label fs-14">*</span></label>
                                <input type="password" class="form-control fs-14" autocomplete="confirm-password" [ngClass]="{'is-invalid': (form['confirmPassword'].errors?.['required'] || form['confirmPassword'].errors?.['mustMatch']) && form['confirmPassword'].touched}" formControlName="confirmPassword">
                                <small class="invalid-feedback mx-2" [hidden]="!form['confirmPassword'].errors?.['required']">{{ 'MAIN.CONTENT.USER-DETAIL.CONFIRM_REQUIRED' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!form['confirmPassword'].errors?.['mustMatch']">{{ 'MAIN.CONTENT.USER-DETAIL.PASSWORD_MATCH' | translate }}</small>
                            </div>
                            <p class="form-label font-weight-600 fs-12 mt-4 mb-0">{{ 'MAIN.CONTENT.USER-DETAIL.PASSWORD_MUST' | translate }}</p>
                            <div class="w-100"></div>
                            <div class="fs-12 text-validation" [ngClass]="{'password-check': this.userDetailForm.get('password').value?.length >= requirements.minLength }">{{ 'MAIN.CONTENT.USER-DETAIL.MIN_LENGTH' | translate:{minPasswordLength:requirements.minLength} }}
                                <img style="display: none;" src="assets/img/password-check.svg" alt="">
                            </div>
                            <div  class="w-100"></div>
                            <div  class="fs-12 text-validation" [ngClass]="{'password-check': AZContains}">{{ 'MAIN.CONTENT.USER-DETAIL.UPPERCASE' | translate }}
                                <img style="display: none;" src="assets/img/password-check.svg" alt="">
                            </div>
                            <div class="w-100"></div>
                            <div class="fs-12 text-validation" [ngClass]="{'password-check': azContains}">{{ 'MAIN.CONTENT.USER-DETAIL.LOWERCASE' | translate }}
                                <img style="display: none;" src="assets/img/password-check.svg" alt="">
                            </div>
                            <div class="w-100"></div>
                            <div class="fs-12 text-validation" [ngClass]="{'password-check': numberContains}">{{ 'MAIN.CONTENT.USER-DETAIL.NUMBER' | translate }}
                                <img style="display: none;" src="assets/img/password-check.svg" alt="">
                            </div>
                            <!--                                 <div class="col-12 mt-4">
                                    <label class="fs-16">Workflows</label>
                                    <ng-select  formControlName="userWorkflows"  [multiple]="true" class="mt-2">
                                        <ng-template ng-tag-tmp let-search="searchTerm">
                                            <b>{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.CREATE' | translate }}</b>: {{search}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="mt-4" *ngIf="user">
                                    <div class="form-check">
                                        <div>
                                            <input
                                            type="checkbox"
                                            class="form-check-input"
                                            formControlName="workflowUser"
                                            />
                                            <label>Workflow User</label>
                                        </div>
                                        </div>
                                </div> -->
                        </div>
                    </form>
                </div>
                <div *ngIf="detailMode === 'edit'" class="col-5 qr-section">
                    <div class="qr-box" [ngClass]="{'expanded': isQrAvailable}">
                        <span class="d-block fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.USER-DETAIL.QR_CODE' | translate }}</span>
                        <span class="fs-14">{{ 'MAIN.CONTENT.USER-DETAIL.AUTHENTICATE' | translate }}</span>
                        <div class="text-center">
                            <div *ngIf="!isQrAvailable">
                                <button class="v-btn btn btn-primary fs-13 font-weight-600 generate-button mt-4" (click)="onGenerateQR()">
                                    {{ 'MAIN.CONTENT.USER-DETAIL.GENERATE_QR_CODE' | translate }}
                                </button>
                            </div>
                            <div *ngIf="isQrAvailable">
                                <div class="qr-code-wrapper">
                                    <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="qrData" [width]="200" [errorCorrectionLevel]="'M'" #qr></qrcode>
                                </div>
                                <a (click)="onRegenerateQR()" class="d-block regenerate-button">
                                    <img src="assets/img/regenerate-new.svg" alt="">
                                    <span class="ms-1 fs-14 font-weight-700">{{ 'MAIN.CONTENT.USER-DETAIL.REGENERATE_QR_CODE' | translate }}</span>
                                </a>
                                <a [href]="qrCodeDownloadLink" download="auth-qrcode" class="d-block regenerate-button">
                                    <img src="assets/img/download.svg" alt="">
                                    <span class="ms-1 fs-14 font-weight-700">{{ 'MAIN.CONTENT.USER-DETAIL.DOWNLOAD' | translate | titlecase }}</span>
                                </a>
                                <a class="d-block regenerate-button" (click)="onDeleteQR()">
                                    <img src="assets/img/delete-blue.svg" alt="">
                                    <span class="ms-1 fs-14 font-weight-700">{{ 'MAIN.CONTENT.USER-DETAIL.DELETE' | translate | titlecase }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>