import { Subject } from "rxjs";

export class TutorialController {
    readonly _controller = new Subject<{[tag: string]: true}>();
    private ended: boolean = false;
    private skipped: boolean = false;

    constructor() { }

    next(tags: {[tag: string]: true}) {
        this._controller.next(tags);
    }

    end() {
        this.ended = true;
        this._controller.complete();
    }

    skip() {
        this.skipped = true;
        this.ended = true;
        this._controller.complete();
    }

    isEnded() { return this.ended }

    isSkipped() { return this.skipped }
}