<div class="d-flex flex-column h-100">
    <div class="h-30">
        <label class="label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    </div>
    <div class="h-50 d-flex flex-column justify-content-center align-items-center" >
        <input #qrInput type="file" class="d-none" onclick="this.value = null" (change)="onSelects(qrInput.files,qrControl)" [multiple]="'multiple'" [accept]="'.jpg, .png, .gif, .jpeg'"/>
        <input  type="file" class="d-none" [accept]="'.jpg, .png, .gif, .jpeg'" onclick="this.value = null" (change)="onSelects(imageEditInput.files,qrControl)" #imageEditInput>
        <div class="" [ngClass]="{'image-with-label d-flex position-absolute-aligned p-1': qrControl?.value?.length>0}" *ngIf="!isScan">
            <div *ngFor="let item of qrCodeResult" style="position: relative">
                <ngx-scanner-qrcode #actionFile="scanner" [src]="qrControl?.value" [ngClass]="{'scanner-display': !isScan}" [config]="config" (event)="onEvent($event,qrControl,'upload')"></ngx-scanner-qrcode>
                <div (click)="deleteQR()">
                    <img src="assets/img/image-trash.svg">
                </div>
            </div>
        </div>

        <div class="" [ngClass]="{'image-with-label d-flex position-absolute-aligned-qr p-1': qrControl?.value?.length>0}">
            <div  style="position: relative">
                <ngx-scanner-qrcode  #action="scanner" [src]="qrControl?.value" [ngClass]="{'scanner-display': !isScan}"  [config]="config" (event)="onEvent($event,qrControl,'scan',action)"></ngx-scanner-qrcode>
                <div class="position-relative" *ngIf="qrControl?.value?.length>0">
                    <div class="d-flex flex-column">
                        <span *ngIf="showQrMatchError" class="fs-14 font-weight-600 text-start mb-1">{{ 'MAIN.USER-WORKFLOW.QR-NOT-MATCHED' | translate }}</span>
                        <p class="fs-14 qr-link-style font-weight-500">
                            {{qrControl?.value}}
                        </p>
                        <img src="assets/img/close-workflow.svg" alt="" class="close-qr-image cursor-pointer" [ngStyle]="{'top': showQrMatchError ? '38px' : '15px'}" (click)="deleteQR()">
                    </div>
                </div>
            </div>
        </div>
        <div class="btn btn-primary qr-button-field mb-2" style="position: absolute;
        top: 45%;" (click)="qrInput.click()" *ngIf="((!qrControl?.value || qrControl?.value?.length==0) && !scanStarted)">
            <span class="qr-text" *ngIf="item?.type=='qr'">{{ 'MAIN.CONTENT.WORKFLOW.PREVIEW.DETECT-QR-WITH-UPLOAD' | translate }}</span>
        </div>
        <!-- <div class="btn btn-primary qr-button-field" style="position: absolute;
        top: 62%;" (click)="handle(action, action?.isStart ? 'stop' : 'start')" *ngIf="(!qrControl?.value || qrControl?.value?.length==0) && !scanStarted">
            <span class="qr-text" *ngIf="item?.type=='qr'">{{ 'MAIN.CONTENT.WORKFLOW.PREVIEW.DETECT-QR-WITH-CAMERA' | translate }}</span>
        </div> -->
    </div>
</div>