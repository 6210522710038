import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { WorkflowService } from '@services/workflow.service';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeSelectedFiles, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment'
import * as bowser from 'bowser';
import { DomSanitizer } from '@angular/platform-browser';
import languagesJson from '../../../../assets/languages.json';
import { SnackbarService } from '@services/support/snackbar.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { LoaderService } from '@services/support/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-workflow',
  templateUrl: './user-workflow.component.html',
  styleUrls: ['./user-workflow.component.scss']
})
export class UserWorkflowComponent implements OnInit,OnChanges, OnDestroy,AfterViewInit {

  @ViewChild('action',  {static: true}) action: NgxScannerQrcodeComponent

  @Input() workflow?
  @Input() workflowId?
  @Output() workflowSubmitted  = new EventEmitter<boolean>()

  @Input() savedDraft?
  @Output() savedDraftChange = new EventEmitter<any>()

  @Input() results?
  @Input() reportId?
  @Input() report?

  @Input() guestData?
  @Output() conditionCheckChange  = new EventEmitter<boolean>()

  @Input() remoteUserId?
  @Input() token?

  @Input() isGuest?

  @Output() isWorkflowStart = new EventEmitter<any>()

  @Input() workflowMenuClicked?
  @Output() workflowMenuClickedChange = new EventEmitter<any>()

  @Output() langChanged  = new EventEmitter<any>()

  @Input() savedPrefilled?
  @Output() savedPrefilledChange = new EventEmitter<any>()

  @Input() contentListClicked?
  @Output() contentListClickedChange = new EventEmitter<any>()

  @Input() reportStatus?

  @Output() isStepFileAvailable = new EventEmitter<any>()
  @Input() stepFilesClicked?
  @Output() stepFilesClickedChange = new EventEmitter<any>()

  initialStep
  steps = []

  stepData;

  selectedStepIndex: number
  initialStepIndex : number
  formData: any = {}
  previewForm = null

  layoutCount = 1
  parentFlexDirection = "column"
  firstFlexGrow = 1
  secondFlexGrow = 1

  firstLayoutItems;
  secondLayoutItems;
  fileName


  form = new UntypedFormGroup({})
  model: any = {}

  stepDurations= {};
  startTime;
  endTime;
  duration;
  stepListCount;
  isSubmitted = false;
  isSaved= false;
  isLoading = false;
  isLoadingSubmit = false
  isStart = false

  subscriptions: Subscription[] = []

  isUpload
  totalPages
  pdfPage = 1

  contentPageAvailable
  showStepList
  lastSeenStep
  showLicenceLimitAlert = false
  showRequestAlert = false

  totalSectionCount
  sectionStepOrder
  sectionStepsCount

  guestToken

  browser
  isMobile
  isIos
  isSafari
  isLastStep = false
  previousIndex
  previousIndexArray = []
  nextIndexArray = []

  qrDetected =false
  isScan =false
  scanStarted = false

  selectedLanguage = 'en'

  currentLang;
  allLangs = []
  workflowLangs = []
  selectedLang = 'en'
  showLangs: boolean = false
  langSub: Subscription = null

  selectedLangName
  userDefaultLang

  workflowCopy

  widgetProps = ['empty', 'label', 'input', 'number', 'datepicker', 'multicheckbox', 'radio', 'image', 'video', 'pdf', 'qr', 'captureImage', 'captureVideo', 'ppe', 'html', 'gallery', 'table', 'form', 'id' ]
  selectedImage
  closeModal : EventEmitter<any> = new EventEmitter

  approversModalShow = false
  approvers = []
  validMailValidator = Validators.pattern(/^$|^[a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~]+([\.-]?[a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~]+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  approverEmail = new UntypedFormControl('', [Validators.required,this.validMailValidator])
  approverEditEmail = new UntypedFormControl('', Validators.required)
  isRequestSended = false
  sendedReportId
  showApproval
  sendMailAfterApproval

  guestLink = new UntypedFormControl()
  isPrefilledSave = false

  isQrAvailable
  qrData = ''

  continueTimes = []
  qrModalShow = false

  resultsArray = []
  showExpertsModal

  remoteIds = []

  userWorkflowForm = {}
  stepDataCopy

  remoteId: string
  remoteLink: string
  expertsToCall = []

  isLoadingExpertModal = false

  remoteIntegration = {}
  showMenu

  continueWithQrAvailable = environment.design.continueWithQr

  expertGroup = new UntypedFormGroup({})

  updatedPrefilled
  currentUser
  showCallPermissionWarning = false
  wfName
  wfDescription
  showFiles = false
  isDownloading = false

  constructor(private workflowService: WorkflowService,
    public datepipe: DatePipe,
    private authService: AuthService,
    private qrcode: NgxScannerQrcodeService,
    private sanitizer: DomSanitizer,
    private snackBarService: SnackbarService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private http: HttpClient
    ) { }

    ngAfterViewInit(): void {
      if(this.action) {
        this.action.start()
      }
    }

    ngOnChanges(changes: SimpleChanges): void {
      if(changes['savedDraft']?.currentValue) {
        this.submit(false)
      }
      if(changes['workflowMenuClicked']) {
        if(changes['workflowMenuClicked']?.currentValue && !(this.isSaved || this.isSubmitted)) {
          this.showMenu = true
          this.showStepList = false
        }else{
          this.showMenu = false
        }
      }
      if(changes['remoteCall']?.currentValue) {
        this.isLoadingExpertModal = true
        this.workflowService.workflowRemoteIntegrationGetId(environment.endPoints.remoteBase, this.workflow.id).then(res => {
          this.remoteLink = res.data.link
          this.remoteId = res.data.linkId
          this.expertsToCall = res.data.experts
          this.remoteIds.push(this.remoteId)
          this.showExpertsModal = true
          this.isLoadingExpertModal = false
        })
      if(!changes['workflowMenuClicked']?.currentValue) {
        this.showMenu = false
        this.workflowMenuClickedChange.emit(false)
      }
    }
    if(changes['stepFilesClicked']?.currentValue) {
      this.showStepFiles()
    }
  }

  ngOnInit(): void {
    this.authService.user.subscribe(res => {
      this.currentUser = res
    })
    document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1, user-scalable=0");
    this.languageAndBrowserSetting()

    //workflow settings
    this.lastSeenStep =  this.report?.lastSeenStep ? this.report.lastSeenStep : null
    this.contentPageAvailable = this.workflow.showContentPage
    this.initialStep = this.workflow.initialStep
    this.stepListCount = this.steps.length
    let initialStepIndex = this.steps.findIndex(step => step.id == this.initialStep)

    if(initialStepIndex || initialStepIndex == 0) {
      this.initialStepIndex = initialStepIndex
      this.selectedStepIndex = initialStepIndex
      this.stepData = this.steps[this.selectedStepIndex]
      this.previousIndex = initialStepIndex
      this.previousIndexArray.push(initialStepIndex)
    }else{
      this.stepData = this.steps[this.selectedStepIndex]
      this.previousIndex = 0
      this.previousIndexArray.push(0)
    }

    //if it is incomplete wf
    if(this.results) {
      this.unMapResults()
    }
    this.initializeFirstStep()

    if(this.guestData) {
      let token = this.guestData.token
      this.authService.loginToFirebase(token).then(response => {
        this.guestToken = response.token
      })
    }

    if(this.contentPageAvailable)  {
      this.steps.forEach(step => {
        let requiredItem = step.data.layouts.find(layout => layout.items[0].templateOptions.required)
        if(requiredItem) {
          step.data['required'] = true
        }
      })
    }

    this.subscriptions.push(this.expertGroup.valueChanges.subscribe(res => {
    }))

  }

  languageAndBrowserSetting() {
    this.allLangs = languagesJson.languages
    this.userDefaultLang = localStorage.getItem('userDefaultLanguage')
    
    this.browser = bowser.getParser(window.navigator.userAgent);
    if (this.browser.is("Safari")) {
      this.isSafari = true
    }
    if (this.browser.is("iOS")) {
      this.isIos = true
    }
    if(this.browser.is('mobile')) {
      this.isMobile = true
    }
    
    if(this.workflow.translations) {
      this.userWorkflowTranslationInit()
    }

    if(!this.workflow.translations) {
      const stepIds = this.workflow.steps ? Object.keys(this.workflow.steps) : []
      this.steps = stepIds.map(sid => {
        const stp = JSON.parse(JSON.stringify(this.workflow.steps[sid]))
        stp.id = sid
        return stp
      }).sort((a,b) => a.data.order - b.data.order)
    }
  }

  initializeFirstStep() {
    this.stepData = this.steps[this.selectedStepIndex]
    this.sectionStepOrderAndCountPatch()
    this.templateSettings(this.stepData)
    this.pathPreviewData()

    if(this.workflow.connectionEnabled && !this.report) {
      this.setConditionalStepValuesAndStartTime()
    }

    let date = new Date()
    
    this.stepDurations = {
      [this.stepData.id]: {
        durations: [{ startTime: new Date().getTime() }]
      }
    }
    
    this.startTime = this.report ? this.report.startTime :  date.getTime()

    if(this.report?.continueTimes && this.report?.status == 'prefilled' && this.isGuest) {
      this.continueTimes = this.report.continueTimes
      this.continueTimes.push({continueTime : new Date().getTime(), guest: { email:this.guestData?.email }})
    }
    
  }

  addNewApprover() {
    this.approvers.push(this.approverEmail.value)
    this.approverEmail.patchValue(null)
  }

  deleteApprover(index) {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.USER-WORKFLOW.DELETE-APPROVAL'),
      confirmButtonText: this.translateService.instant('MAIN.USER-WORKFLOW.DELETE'),
      confirmButtonStyle: "danger"
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe(res => {
      if(res == true) {
        this.approvers.splice(index, 1)
      }
    }))
  }

  

  unMapResults() {
    let defaultLang = this.workflowLangs.find(res => res.code == this.report.contentLanguage)
    this.selectedLangName = defaultLang ? defaultLang.name : null
    this.selectedLanguage = this.workflow.defaultLanguage
    if(defaultLang) {
      this.changeCurrentLang({ code:defaultLang.code, name: defaultLang.name })
    }
    this.createFormDataFromResult()
    let index = this.steps.findIndex(res => res.id == this.lastSeenStep)
    // if(index && !this.workflow.connectionEnabled) {
    //   this.selectStep(index)
    // }
    if(this.workflow.connectionEnabled) {
      this.previousIndexArray = [0]
      let element = this.nextIndexArray[0]
      this.nextIndexArray = []
      this.nextIndexArray.push(element)
    }
  }

  createFormDataFromResult() {
    let stepObject = {}

    this.results.forEach((result, resultIndex) => {
      Object.keys(result.data).find(formKey => {
        let value = this.steps.find(step =>  step.data.layouts.find(layout => layout.items[0].key == formKey))
       if(value) {
         if(result?.data) {
          if(!this.formData[formKey]) {
            this.formData[formKey] = new UntypedFormControl()
          }
          if(result.data[formKey].type == 'multicheckbox'){
            let multicheckboxItem = value.data.layouts.find(item=> item.items[0].key == formKey )
             const multicheckboxValues = []
             multicheckboxItem.items[0].templateOptions.options.forEach(item => {
               const value = result.data[formKey].value.find(optionLabel => item.value == optionLabel)
               if(value) {
                 multicheckboxValues.push({ value: true, option: item.label, key: item.value })
               }else{
                multicheckboxValues.push({ value: false, option: item.label, key: item.value })
               }
             })
              this.formData[formKey].patchValue(multicheckboxValues)
              stepObject[formKey] = multicheckboxValues

          }
           else if(result.data[formKey].type == 'radio') {
            let radioItem = value.data.layouts.find(item=> item.items[0].key == formKey )
            if(radioItem) {
              let radio = radioItem.items[0].templateOptions.options.find(radioVal => radioVal.value == result.data[formKey].value )
              if(radio) {
                this.formData[formKey].patchValue(radio.value)
                stepObject[formKey] = radio.value
              }
            }
           }
           else if(result.data[formKey].type == 'datepicker') {
             if(result.data[formKey].value) {
                const date = new Date(result.data[formKey].value);
                this.formData[formKey].patchValue(date)
                stepObject[formKey] = date
             }
           }
           else if(result.data[formKey].type == 'captureImage') {
            result.data[formKey].value
            let captureValue = result.data[formKey].value
            this.formData[formKey].patchValue(captureValue)
            stepObject[formKey] = captureValue
           }
           else if(result.data[formKey].type == 'ppe') {
            let ppeValue = result.data[formKey].value?.link ? {url:result.data[formKey].value.link} : null
            this.formData[formKey].patchValue(ppeValue)
            stepObject[formKey] = ppeValue

           }
           else if(result.data[formKey].type == 'qr') {
             if(result.data[formKey].value?.hasOwnProperty('qr')) {
               this.formData[formKey].patchValue(result.data[formKey].value.qr)
             }else{
               this.formData[formKey].patchValue(result.data[formKey].value)
             }
           }
           else if(result.data[formKey].type == 'number') {
            let number = {numberFirst:'',numberSecond:'', numberOutput:''}
            number.numberOutput = result.data[formKey].value
            number.numberFirst = result.data[formKey].value.toString().includes('.') ? result.data[formKey].value.split(".")[0] : result.data[formKey].value;
            number.numberSecond = result.data[formKey].value.toString().includes('.') ? result.data[formKey].value.split(".")[1] : 0
  
            this.formData[formKey].patchValue(number.numberOutput)
            stepObject[formKey] = number
           }
           else if(result.data[formKey].type == 'form') {
            let formWidgetItem = value.data.layouts.find(item=> item.items[0].key == formKey )
            this.formData[formKey].patchValue(formWidgetItem.items[0].templateOptions.formData)
             Object.keys(result.data[formKey].value).filter(objectKey => objectKey)
             if(this.formData[formKey].value) {
              this.formData[formKey].value.forEach((res,formIndex) => {
                res.fields.forEach((field, fieldIndex) => {
                  if(Object.keys(result.data[formKey].value).includes(field.key)) {
                    this.formData[formKey].value[formIndex].fields[fieldIndex].value = result.data[formKey].value[field.key]
                  }
                })
              })
             }
           }
           else{
            this.formData[formKey].patchValue(result.data[formKey].value)
            stepObject[formKey] = result.data[formKey].value
           }
          }
       }

     })
     if(this.workflow.connectionEnabled) {
      let stepId = result.stepId
      let durations = result.durations
      let stepData = stepObject
      let obj = {stepId,durations, ...stepData}
      this.nextIndexArray.push(obj)
     }
   })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  start():void {
    this.isStart = true
    this.previewForm.status = 'VALID'
    this.isWorkflowStart.emit(true)
  }

  setConditionalObject(stepData) {
      this.formModel[this.selectedStepIndex] = stepData
      let durations = this.nextIndexArray[this.nextIndexArray.length-1].durations
      let stepId = this.nextIndexArray[this.nextIndexArray.length-1].stepId
      this.nextIndexArray[this.nextIndexArray.length-1] = {durations,stepId,...stepData}
  }

  setConditionalStepEndTime() {
    const currentDateTime = new Date().getTime()
    if(this.nextIndexArray[this.nextIndexArray.length-1]?.durations){
      const lastDurationIndex = this.nextIndexArray[this.nextIndexArray.length-1].durations.length -1
      const stepDuration = this.nextIndexArray[this.nextIndexArray.length-1].durations[lastDurationIndex]
      stepDuration.endTime = currentDateTime
      stepDuration.duration = +(stepDuration.endTime - stepDuration.startTime)
    }
  }

  private setCurrentStepValues(status?,previous? ): void {
    if (this.previewForm) {
      const stepData = {};
      // Get only current step fields from form value
      Object.keys(this.previewForm.value).forEach((key) => {
        if (this.firstLayoutItems[0].key === key || (this.secondLayoutItems?.length > 0 && this.secondLayoutItems[0].key === key)) {
          stepData[key] = this.previewForm.value[key]
        }
      });
      this.formModel[this.selectedStepIndex] = stepData
      if(this.workflow.connectionEnabled) {
        this.setConditionalObject(stepData)
        this.setConditionalStepEndTime()
      }
      if(previous && this.workflow.connectionEnabled) {
        this.nextIndexArray.pop()
      }
      this.currentStepFormControls = Object.keys(this.formModel[this.selectedStepIndex]);

      if(this.contentPageAvailable) {
        this.checkContentListSteps()
      }
    }
  }

  checkContentListSteps() {
    this.steps.forEach(step => {
      
      step.data.layouts.filter(layout => {
        let isStepClicked = Object.keys(this.previewForm.controls).filter(key => key == layout.items[0].key && this.previewForm.controls[key].valid)
        if(isStepClicked.length) {
          step.data['visited'] = true
        }else{
          step.data['visited'] = false
        }
      })
    })
  }

  selectStep(index, status?) {
    
    this.showStepList = false
    if(this.steps[index].data.connections && this.steps[index].data.connections.length) {
      this.isLastStep= false
    }
    this.selectedStepIndex = index
    
    this.stepData = this.steps[index]
    this.selectedStepIndex = index
    this.setStartDuration()
    
    this.templateSettings(this.stepData)
    this.pathPreviewData()
    this.setFormValue()
  
  }

  checkConditions(step) {
    if(!step.data.connections.length || step.data.connections.length == 1){
      return step.data.connections[0].next
    }else{
      for(let i = 0; i<=step.data.connections.length-1;i ++) {
        if(i==step.data.connections.length-1){
          return step.data.connections[i].next
        }else{
          if(this.checkAllCondition(step.data.connections[i].conditions,step.data.connections[i].logicalOperator)){
            return step.data.connections[i].next
          }else{
            if(i==step.data.connections.length-1) {
              return step.data.connections[i].next
            }
          }
        }
      }
    }
  }

  checkAllCondition(conditions, logicalOperator) {
    let valid
    if(logicalOperator == 'AND') {
       valid = conditions.every(condition => {
        let insertedValue
        let layoutItemType
        let layoutItem
  
        if(this.previewForm?.value) {
          layoutItem = this.stepData.data.layouts.find(layout => layout.items[0].key == condition.type)
          layoutItemType = layoutItem.items[0].type
          insertedValue = this.previewForm?.value[condition.type]
        }
        return this.findConditionTypeIsValid(condition.condition, layoutItemType, insertedValue, condition.value, layoutItem)
        
      })
    }else{
      valid = conditions.some(condition => {
        let insertedValue
        let layoutItemType
        let layoutItem
  
        if(this.previewForm?.value) {
          layoutItem = this.stepData.data.layouts.find(layout => layout.items[0].key == condition.type)
          layoutItemType = layoutItem.items[0].type
          insertedValue = this.previewForm?.value[condition.type]
        }
        return this.findConditionTypeIsValid(condition.condition, layoutItemType, insertedValue, condition.value, layoutItem)
        
      })
    }
    return valid
  }

  findConditionTypeIsValid(type: string,layoutItemType: string, insertedValue: string, value: string, layoutItem?) {
    if((type == 'isEmpty' || type == 'isNotEmpty') && layoutItemType !== 'multicheckbox') {
      if(type == 'isEmpty'){
        return !insertedValue || insertedValue== '' ? true : false
      }
      else{
        return insertedValue && insertedValue !== '' ? true : false
      }
    }else{
      switch(value !== '') {
        case layoutItemType == 'input':
          if(this.inputConditions(type, insertedValue, value)) {
            return true
          }else{
            return false
          }
        case layoutItemType == 'number':
          if(this.numberConditions(type, insertedValue, value)) {
            return true
          }else{
            return false
          }
        case layoutItemType == 'radio':
          if(this.radioConditions(type, insertedValue, value, layoutItem)) {
            return true
          }else{
            return false
          }
        case layoutItemType == 'multicheckbox':
          if(this.multicheckboxConditions(type, insertedValue, value)) {
            return true
          }else{
            return false
          }
        case layoutItemType == 'datepicker':
          if(this.dateConditions(type, insertedValue, value)) {
            return true
          }else{
            return false
          }
        case layoutItemType == 'ppe':
          if(this.ppeConditions(type, insertedValue, value)) {
            return true
          }else{
            return false
          }
        case layoutItemType == 'qr':
          if(this.qrConditions(type, insertedValue, value, layoutItem)) {
            return true
          }else{
            return false
          }
        default: 
          return false
      }
    }
  }

  inputConditions(conditionType, insertedValue: string, value: string) {
    if(conditionType == 'is') {
      this.conditionCheckChange.emit(insertedValue.toLowerCase() == value.toLowerCase())
      return insertedValue.toLowerCase() == value.toLowerCase()
    }
    if(conditionType == 'isNot') {
      this.conditionCheckChange.emit(insertedValue.toLowerCase() !== value.toLowerCase())
      return insertedValue.toLowerCase() !== value.toLowerCase()
    }
    if(conditionType == 'contains') {
      this.conditionCheckChange.emit(insertedValue?.includes(value))
      return insertedValue?.includes(value)
    }
    if(conditionType == 'notContain') {
      this.conditionCheckChange.emit(!insertedValue.includes(value))
      return !insertedValue.includes(value)
    }
    if(conditionType == 'startsWith') {
      this.conditionCheckChange.emit(insertedValue.startsWith(value))
      return insertedValue.startsWith(value)
    }
    if(conditionType == 'endWith') {
      this.conditionCheckChange.emit(insertedValue.endsWith(value))
      return insertedValue.endsWith(value)
    }
    else{
      return false
    }
  }

  numberConditions(conditionType, insertedValue, value) {
    let insertedVal = insertedValue
    let valueAsNumber = value*1
    if(conditionType == 'equals') {
      return insertedVal == valueAsNumber
    }
    if(conditionType == 'notEquals') {
      return insertedVal !== valueAsNumber
    }
    if(conditionType == 'greaterThan') {
      return insertedVal > valueAsNumber
    }
    if(conditionType == 'lessThan') {
      return insertedVal < valueAsNumber
    }
    if(conditionType == 'greaterOrEqual') {
      return insertedVal >= valueAsNumber
    }
    if(conditionType == 'lessOrEqual') {
      return insertedVal <= valueAsNumber
    }
    else{
      return false
    }
  }

  radioConditions(conditionType, insertedValue, value, layoutItem) {
    let val = layoutItem.items[0].templateOptions.options.find(opt => opt.value == value)
    if(conditionType == 'is') {
      return val?.value == insertedValue
    }
    if(conditionType == 'isNot') {
      return  !val || (val.value !== insertedValue)
    }
    else{
      return false
    }
  }

  multicheckboxConditions(conditionType, insertedValue, value) {
    if(conditionType == 'contains') {
      let isContain = insertedValue?.find(res => res.key == value && res.value)
      return isContain ? true : false
    }
    if(conditionType == 'notContain') {
      let notContain = insertedValue?.find(res => res.key == value && !res.value)
      return notContain ? true : false
    }
    if(conditionType == 'isEmpty') {
      let isEmpty = insertedValue.every(res => !res.value)
      return isEmpty ? true : false
    }
    if(conditionType == 'isNotEmpty') {
      let isNotEmpty = insertedValue.some(res => res.value)
      return isNotEmpty ? true : false
    }
    else{
      return false
    }
  }

  dateConditions(conditionType, insertedValue, value) {
    let insertedVal
    let d = new Date(insertedValue);
    const day = d.getDate()
    const year = d.getFullYear()
    const month = d.getMonth()
    const utcTimestamp = Date.UTC(year, month, day, 0, 0, 0, 0)
    insertedVal = utcTimestamp

    if(conditionType == 'is') {
      return insertedVal == value
    }
    if(conditionType == 'isNot') {
      return insertedVal !== value
    }
    if(conditionType == 'isBefore') {
      return insertedVal < value
    }
    if(conditionType == 'isAfter') {
      return insertedVal > value
    }
    if(conditionType == 'isBetween') {
      let dateArray = JSON.parse(value)
      let firstVal = new Date(Number(dateArray[0])).getTime()
      let secondVal = new Date(Number(dateArray[1])).getTime()
      return firstVal <= insertedVal && secondVal >= insertedVal
    }
    if(conditionType == 'isNotBetween') {
      let dateArray = JSON.parse(value)
      let firstVal = new Date(Number(dateArray[0])).getTime()
      let secondVal = new Date(Number(dateArray[1])).getTime()
      return firstVal > insertedVal || secondVal < insertedVal
    }
    else{
      return false
    }
  }

  qrConditions(conditionType, insertedValue, value, layoutItem) {
    if(conditionType == 'isEqual') {
      return insertedValue == value
    }
    if(conditionType == 'isNotEqual') {
      return insertedValue !== value
    }
    else{
      return false
    }
  }

  ppeConditions(conditionType, insertedValue, value) {
    if(conditionType == 'isDetected') {
      return insertedValue ? insertedValue.detected : false
    }
    if(conditionType == 'isNotDetected') {
      return insertedValue ? !insertedValue.detected : true
    }
    else{
      return false
    }
  }

  next(): void {
    this.closeStepFiles()
    this.setEndDuration()
    this.setCurrentStepValues(true) 
    this.previousIndex = this.selectedStepIndex
    this.previousIndexArray.push(this.selectedStepIndex)

    if(this.workflow.connectionEnabled && this.stepData.data.connections.length) {
      let stepIndex = this.steps.findIndex(step => step.id == this.checkConditions(this.stepData))
      this.selectedStepIndex = stepIndex
      //this.checkConditions(this.stepData)
    }else if(this.workflow.connectionEnabled && !this.stepData.data.connections.length) {
      this.isLastStep = true
    }
    else{
      this.selectedStepIndex = this.selectedStepIndex +1
    }
    this.stepData = this.steps[this.selectedStepIndex]

    this.setStartDuration()
    this.templateSettings(this.stepData)
    this.pathPreviewData()
    if(this.workflow.connectionEnabled) {
      this.setConditionalStepValuesAndStartTime()
    }
    this.setFormValue()
    this.sectionStepOrderAndCountPatch()

  }
  setEndDuration(): void {
    const currentDateTime = new Date().getTime()
    if(this.stepDurations[this.stepData.id]?.durations){
      const lastDurationIndex = this.stepDurations[this.stepData.id].durations.length -1
      const stepDuration = this.stepDurations[this.stepData.id].durations[lastDurationIndex]
      stepDuration.endTime = currentDateTime
      stepDuration.duration = +(stepDuration.endTime - stepDuration.startTime)
    }
  }

  setStartDuration(): void {
    if (!this.stepDurations[this.stepData.id]) {
      this.stepDurations[this.stepData.id] = { durations: [] } 
    }
    this.stepDurations[this.stepData.id]['durations'].push({ startTime: new Date().getTime() })
  }

  setConditionalStepValuesAndStartTime() {
    const stepData = {};
    Object.keys(this.previewForm.value).forEach((key) => {
      if (this.firstLayoutItems[0].key === key || (this.secondLayoutItems?.length > 0 && this.secondLayoutItems[0].key === key)) {
        stepData[key] = this.previewForm.value[key]
      }
    });
    let stepId = this.steps[this.selectedStepIndex].id
    let durations = []
    durations.push({ startTime: new Date().getTime() })
    let obj = {stepId,durations}

    this.nextIndexArray.push(obj)
  }

  formModel: any = {};
  currentStepFormControls: string[] = [];

  previous(): void {
    this.closeStepFiles()
    this.setCurrentStepValues(false, true)
    // Clear current step errors from form to enable next button for previous step view
    this.currentStepFormControls.forEach((controlName) => {
      this.previewForm.controls[controlName].setErrors(null, { emitEvent: false })
      this.previewForm.controls[controlName].setValidators(null, { emitEvent: false })
      this.previewForm.controls[controlName].updateValueAndValidity()
      if(this.previewForm.controls[controlName].controls?.numberFirst) {
        this.previewForm.controls[controlName].controls?.numberFirst.setErrors(null, { emitEvent: false })
        this.previewForm.controls[controlName].controls?.numberFirst.setValidators(null, { emitEvent: false })
        this.previewForm.controls[controlName].controls?.numberFirst.updateValueAndValidity()

        this.previewForm.controls[controlName].controls?.numberOutput.setErrors(null, { emitEvent: false })
        this.previewForm.controls[controlName].controls?.numberOutput.setValidators(null, { emitEvent: false })
        this.previewForm.controls[controlName].controls?.numberOutput.updateValueAndValidity()

        this.previewForm.controls[controlName].controls?.numberSecond.setErrors(null, { emitEvent: false })
        this.previewForm.controls[controlName].controls?.numberSecond.setValidators(null, { emitEvent: false })
        this.previewForm.controls[controlName].controls?.numberSecond.updateValueAndValidity()
      }
    });
    this.setEndDuration()
    if(this.workflow.connectionEnabled) {
      this.selectedStepIndex = this.previousIndexArray[this.previousIndexArray.length-1]
      this.previousIndexArray.pop()
    }
    else {
      this.selectedStepIndex = this.selectedStepIndex -1
    }
    this.isLastStep = false
    this.stepData = this.steps[this.selectedStepIndex]
    this.setStartDuration()
    this.templateSettings(this.stepData)
    this.pathPreviewData()
    this.setFormValue()
    this.sectionStepOrderAndCountPatch()
  }

  sectionStepOrderAndCountPatch() {
    let sectionCount = 0
    this.totalSectionCount = 0
    let sectionSteps = []
    this.steps.forEach((response,index)=> {
      if(index<=this.stepData.data.order && response.data.isSection) {
        sectionCount += 1
      }
      if(response.data.isSection) {
        this.totalSectionCount += 1
      }
    })
    
    sectionSteps = this.steps.filter(resp => resp.data.parentId == this.stepData.data.parentId)
    let index = sectionSteps.findIndex(res => res.id == this.stepData.id)
    this.sectionStepsCount = sectionSteps.length
    this.sectionStepOrder = index
  }

  private setFormValue(): void {
    if (this.formModel[this.selectedStepIndex]) {
      const formValue = this.formModel[this.selectedStepIndex];
      this.currentStepFormControls = Object.keys(this.formModel[this.selectedStepIndex]);
      this.currentStepFormControls.forEach((key) => {
        if(this.previewForm.controls[key] instanceof UntypedFormArray) {
            const formArray: UntypedFormArray = this.previewForm.controls[key];
            formArray.controls.forEach((form: UntypedFormGroup, formGroupIndex) => {
              const formGroupValue = this.formModel[this.selectedStepIndex][key][formGroupIndex]
              form.setValue(formGroupValue);
            })
        } else {
          this.previewForm.controls[key].setValue(formValue[key]);
        }
      })
    }
  }

  tableOutput(key, value) {
    this.formData[key]= new UntypedFormControl(value)
  }
  formOutput(event) {

  }

  submit(status = true, prefilled?): void {
    const mappedData = []
    this.lastSeenStep = this.stepData.id
    this.showStepList = false
    this.setCurrentStepValues(true)
    this.setEndDuration()
    if(this.workflow.connectionEnabled) {

      this.nextIndexArray.forEach((res,index) => {
        const data = {}
        let submitStep = this.steps.find(step => step.id == res.stepId)
        submitStep.data.layouts.forEach((layout) => {
          const fieldKey = layout.items[0].key
          const fieldType = layout.items[0].type
          data[fieldKey] = { type: fieldType }
          switch(fieldType) {
            case 'multicheckbox': 
              data[fieldKey].value = this.nextIndexArray[index][fieldKey] ? this.nextIndexArray[index][fieldKey].filter((item) => item?.value).map((item) => item?.key) : []
              break;
            case 'ppe': 
              data[fieldKey].value = {detected:this.nextIndexArray[index][fieldKey]?.detected, link: this.nextIndexArray[index][fieldKey]?.url}
              break;
            case 'qr': 
              data[fieldKey].value = {detected:this.nextIndexArray[index][fieldKey] == layout.items[0].templateOptions.qrfield, qr: this.nextIndexArray[index][fieldKey]}
              break; 
            case 'datepicker': 
            data[fieldKey].value = this.nextIndexArray[index][fieldKey] && this.nextIndexArray[index][fieldKey] ? this.nextIndexArray[index][fieldKey]?.getTime(): null;
              break;
            case 'label': 
            data[fieldKey].value = layout.items[0].templateOptions.label
              break;
            case 'html': 
            data[fieldKey].value = layout.items[0].templateOptions.html
              break;
            case 'number': 
              data[fieldKey].value = this.nextIndexArray[index][fieldKey]
              break;
            case 'radio': 
              let radioData = layout.items[0].templateOptions.options.find(item => item.value == this.nextIndexArray[index][fieldKey])
              data[fieldKey].value = radioData?.value
              break;
            case 'table': 
              data[fieldKey].value = this.nextIndexArray[index][fieldKey]
              break;
            case 'form': 
              data[fieldKey].value = layout.items[0].templateOptions.formData
              break;
            default: 
              data[fieldKey].value = this.nextIndexArray[index][fieldKey]
              break;
          }
        })
        mappedData[index] = {
          data,
          durations: this.nextIndexArray[index]?.durations ? this.nextIndexArray[index].durations : [],
          stepId: this.nextIndexArray[index].stepId
        }
      })
    }else{
      // code refactor ile değişecek
      // this.steps.forEach((data,index) => {
      //   if(index <= this.steps.length-2){
      //     this.selectedStepIndex = index
      //     this.stepData = data
      //     if(index ==0){
      //       this.pathPreviewData()
      //       this.setFormValue()
      //       this.setCurrentStepValues() 
      //       if(this.stepDurations[this.stepData.id]) {
      //         this.stepDurations[this.stepData.id]['durations'].push({ startTime: new Date().getTime() })
      //       }
      //     }
      //       this.next()
      //   }else {
      //     this.setEndDuration()
      //     this.setCurrentStepValues()
      //   }
      // })

      Object.keys(this.formModel).forEach((stepIndex) => {
        const data = {}
        this.steps[stepIndex].data.layouts.forEach((layout) => {
          const fieldKey = layout.items[0].key
          const fieldType = layout.items[0].type
          data[fieldKey] = { type: fieldType }
          switch(fieldType) {
            case 'multicheckbox': 
            data[fieldKey].value = this.formModel[stepIndex][fieldKey] ? this.formModel[stepIndex][fieldKey].filter((item) => item?.value).map((item) => item?.key) : []
              break;
            case 'ppe': 
              data[fieldKey].value = {detected:this.formModel[stepIndex][fieldKey]?.detected, link: this.formModel[stepIndex][fieldKey]?.url}
              break;
            case 'qr': 
              data[fieldKey].value = {detected:this.formModel[stepIndex][fieldKey] == layout.items[0].templateOptions.qrfield, qr: this.formModel[stepIndex][fieldKey]}
              break;  
            case 'datepicker': 
            data[fieldKey].value = this.formModel[stepIndex][fieldKey] && this.formModel[stepIndex][fieldKey] ? this.formModel[stepIndex][fieldKey]?.getTime(): null;
              break;
            case 'label': 
            data[fieldKey].value = layout.items[0].templateOptions.label
              break;
            case 'html': 
            data[fieldKey].value = layout.items[0].templateOptions.html
              break;
            case 'number': 
              data[fieldKey].value = this.formModel[stepIndex][fieldKey]
              break;
            case 'radio': 
              let radioData = layout.items[0].templateOptions.options.find(item => item.value == this.formModel[stepIndex][fieldKey])
              data[fieldKey].value = radioData?.value
              break;
            case 'table': 
              data[fieldKey].value = this.formModel[stepIndex][fieldKey]
              break;
            case 'form': 
            let formObj = {}
            this.formModel[stepIndex][fieldKey].forEach(data => {
              data.fields.forEach(field => {
                if(field.value) {
                  if(field.type == 'datepicker') {
                    formObj[field.key] = field.value ? field.value?.getTime(): null
                  }else{
                    formObj[field.key] = field.value
                  }
                }
              })
            })
            data[fieldKey].value = formObj
              break;
            default: 
              data[fieldKey].value = this.formModel[stepIndex][fieldKey]
              break;
          }
        })
        mappedData[stepIndex] = {
          data,
          durations: this.stepDurations[this.steps[stepIndex].id]?.durations ? this.stepDurations[this.steps[stepIndex].id].durations : [],
          stepId: this.steps[stepIndex].id
        }
      })
    }
    this.isSubmitted = status
    this.isSaved = !status
    this.isLoadingSubmit = true
    this.endTime = (new Date()).getTime()
    this.duration = (this.endTime-this.startTime);
    this.save(mappedData, status, prefilled)
  }
  
  
  private save(mappedData, status, prefilled?): void{
    let baseUrl = environment.endPoints.workflowBase
    mappedData = mappedData.filter(res => res)
    this.savedDraftChange.emit(null)
    this.savedPrefilledChange.emit(null)
    let saveObject = {
      accountId : this.guestData ? this.guestData.account_id : (this.remoteUserId ? this.remoteUserId : this.authService.currentUser.account_id) ,
      updatedAt : '',
      startTime: this.startTime,
      endTime: this.endTime,
      duration : this.duration,
      results : mappedData,
      userId: '',
      version : this.workflow.version,
      workflowId : this.workflow.id,
      status : prefilled || this.updatedPrefilled ?  'prefilled' : (status ? 'submitted' : 'incomplete'),
      lastSeenStep: !status ? this.lastSeenStep : null,
      guest : {
        email : this.guestData?.email ? this.guestData.email : null
      },
      contentLanguage: this.selectedLanguage,
      continueTimes: this.continueTimes,
      remoteIntegration: this.remoteIntegration
    }
    if(this.reportId) {
      this.workflowService.updateWorkflowReport(saveObject,this.reportId, true, this.guestToken, baseUrl).then(res => {
        if(res) {
          this.showApproval = res.data.approval
          this.sendedReportId = res.data.reportId
          this.sendMailAfterApproval = res.data.sendWorkflowReportAfterApproval
          if(res.data?.license?.exceeded) {
            this.showLicenceLimitAlert = true  
          }
          this.workflowSubmitted.emit(true)
          this.isLoadingSubmit = false
          if(res.data?.continueWithQr) {
            if(res.data?.continueWithQr?.link) {
              this.isQrAvailable = true
              this.guestLink.patchValue(res.data.continueWithQr.link)
              this.qrData = res.data.continueWithQr.qr
            }
          }
        }
      })
      .catch(error => {
        if(error.error.message == 'report-submitted-before') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.REPORT-SUBMITTED-BEFORE'))
        }
      })
      .finally(()=> this.isLoadingSubmit = false)
    }else{
      let token
      if(this.guestData) {
        this.saveReport(saveObject, this.guestToken, baseUrl)
      }else{
        this.saveReport(saveObject, token, baseUrl)
      }
      this.workflowSubmitted.emit(true)
  }
}

  private saveReport(saveObject, token?, baseUrl?) {
    if(this.token) {
      token = this.token
    }
    return this.workflowService.saveWorkflowReport(saveObject, token, true, baseUrl).then(res => {
      if(res) {
        this.showApproval = res.data.approval
        this.sendMailAfterApproval = res.data.sendWorkflowReportAfterApproval
        this.sendedReportId = res.data.reportId
        if(res.data?.license?.exceeded) {
          this.showLicenceLimitAlert = true  
        }
        this.workflowSubmitted.emit(true)
        this.isLoadingSubmit = false
        if(res.data?.continueWithQr) {
          if(res.data?.continueWithQr?.link) {
            this.isQrAvailable = true
            this.guestLink.patchValue(res.data.continueWithQr.link)
            this.qrData = res.data.continueWithQr.qr
          }
        }
        
      }
    })
    .catch((error) => {
      this.isSubmitted = false
      if(error.error?.message == 'permission-denied') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-NOT-FOUND'))
      }
      if(error.error?.message == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.isLoadingSubmit = false
    })
  }

  changeRadioCheck(option,layoutItems) {
    if(this.formData[layoutItems.key].value == option.value){
      this.formData[layoutItems.key].reset()
    }
  }

  private templateSettings(data): void{
    this.layoutCount = data.data['template']?.count
    this.firstFlexGrow = data.data['template']?.weights[0]
    this.secondFlexGrow = data.data['template']?.weights[1]
    this.parentFlexDirection = data.data['template']?.orientation
  }

  private pathPreviewData(): void{
    if(!this.stepData.data.connections || this.stepData.data.connections.length==0) {
      this.isLastStep = true
    }
    if(this.stepData.data?.files && this.stepData.data?.files?.length) {
      this.isStepFileAvailable.emit(true)
    }else{
      this.isStepFileAvailable.emit(false)
    }
    let stepId = this.stepData.data.id
    this.firstLayoutItems = this.stepData.data?.layouts[0]?.items
    this.secondLayoutItems = this.stepData.data?.layouts[1]?.items

    if(this.firstLayoutItems[0]){
      this.patchFields(this.firstLayoutItems)
    }
    if(this.secondLayoutItems?.[0]){
      this.patchFields(this.secondLayoutItems)
    }
    this.previewForm = new UntypedFormGroup(this.formData)
  } 

  private patchFields(items): void{
    if(!this.formData[items[0].key]) {
      if(items[0].type == 'empty'){
        this.formData[items[0].key] = new UntypedFormControl()
      }
      if(items[0].type == 'label'){
        this.formData[items[0].key] = new UntypedFormControl()
      }
      if(items[0].type == 'html'){
        this.formData[items[0].key] = new UntypedFormControl()
      }
      if(items[0].type == 'input'){
        this.formData[items[0].key] = new UntypedFormControl('')
      }
      if(items[0].type == 'number'){
        this.formData[items[0].key] = new UntypedFormControl()
      }
      if(items[0].type == 'datepicker'){
        this.formData[items[0].key] = new UntypedFormControl('')
      }
      if(items[0].type == 'multicheckbox'){
        this.formData[items[0].key] = new UntypedFormControl()
      }
      if(items[0].type == 'radio'){
        this.formData[items[0].key] = new UntypedFormControl(false)
      }
      if (items[0].type == 'ppe') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.imgUrl)
      }
      if (items[0].type == 'captureImage') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.imgUrl)
      }
      if (items[0].type == 'captureVideo') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.videoUrl)
      }
      if (items[0].type == 'qr') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.qr)
      }
      if (items[0].type == 'image') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.imgUrl)
      }
      if (items[0].type == 'gallery') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.images)
      }
      if (items[0].type == 'video') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.videoUrl)
      }
      if (items[0].type == 'pdf') {
        this.isUpload = true
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.pdfUrl)
        this.formData.pdfPage = new UntypedFormControl()
        this.formData.pdfPage.patchValue(1)
        this.pdfPage = 1
        this.isUpload = false
      }
      if (items[0].type == 'table') {
        this.formData[items[0].key] = new UntypedFormControl({}, items[0].templateOptions.required ? Validators.required : null)
      }
      if (items[0].type == 'form') {
        this.formData[items[0].key] = new UntypedFormControl(items[0].templateOptions.formData, items[0].templateOptions.required ? Validators.required : null)
      }
      if (items[0].type == 'id') {
        this.formData[items[0].key] = new UntypedFormControl('')
      }
    }
  }

  closeAlert() {
    this.showRequestAlert = false
  }

  changeCurrentLang(lang) {
    this.selectedLanguage = lang.code
    this.selectedLangName = lang.name
    
    this.langChanged.emit(this.workflowCopy.translations[this.selectedLanguage][this.workflowCopy.name])

    this.wfDescription = this.workflowCopy.translations[this.selectedLanguage][this.workflowCopy.description]
    const stepIds = this.workflowCopy.steps ? Object.keys(this.workflowCopy.steps) : []

  let steps = stepIds.map(sid => {
    const stp = JSON.parse(JSON.stringify(this.workflow.steps[sid]))
    stp.id = sid
    return stp
  }).sort((a,b) => a.data.order - b.data.order)
  steps.forEach(step => {
    step.data.name = this.workflowCopy.translations[this.selectedLanguage][step.data.name]
    step.data.layouts.forEach(layout => {
      layout.items[0].templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.label]
      if(layout.items[0].templateOptions.placeholder) {
        layout.items[0].templateOptions.placeholder = this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.placeholder]
      }
      if(layout.items[0].templateOptions.html) {
        if(this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.html]) {
          layout.items[0].templateOptions.html = this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.html]
        }
      }
      if(layout.items[0].templateOptions.options) {
        layout.items[0].templateOptions.options = layout.items[0].templateOptions.options.map(option => {return {label:this.workflowCopy.translations[this.selectedLanguage][option.label], value: option.value}})
      }
      if(layout.items[0].templateOptions.tableData) {
        layout.items[0].templateOptions.tableData.columns.forEach(column => {
          column.cells.forEach(cell => {
            if(cell.templateOptions.label) {
              cell.templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][cell.templateOptions.label]
            }
          })
        })
        layout.items[0].templateOptions.tableData.rows.forEach(row => {
          row.cells.forEach(cell => {
            if(cell.templateOptions.label) {
              cell.templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][cell.templateOptions.label]
            }
            if(cell.templateOptions.placeholder) {
              cell.templateOptions.placeholder = this.workflowCopy.translations[this.selectedLanguage][cell.templateOptions.placeholder]
            }
            if(cell.templateOptions.options) {
              cell.templateOptions.options = cell.templateOptions.options.map(option => {return {label:this.workflowCopy.translations[this.selectedLanguage][option.label], value: option.value}})
            }
          })
        })
      }
      if(layout.items[0].templateOptions.formData) {
        layout.items[0].templateOptions.formData.forEach(data => {
          if(data.label) {
            data.label = this.workflowCopy.translations[this.selectedLanguage][data.label]
          }
          data.fields.forEach(field => {
            if(field.templateOptions.label) {
              field.templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][field.templateOptions.label]
            }
          })
        })
      }
    })
  })
  this.steps = JSON.parse(JSON.stringify(steps))
  this.stepData =  this.steps[this.selectedStepIndex]
  this.stepData = JSON.parse(JSON.stringify(this.steps[this.selectedStepIndex]))
  
    this.templateSettings(this.stepData)
    this.pathPreviewData()
  }

  openApproversModal() {
    if(this.guestData && this.sendMailAfterApproval) {
      let mail = new UntypedFormControl(this.guestData.email,this.validMailValidator)
      if(!mail.errors?.['pattern']) {
        this.approvers.push(this.guestData.email)
      }
    }
    this.approversModalShow = true
  }

  sendRequest() {
    this.isLoading = true
    this.loaderService.show()
    let baseUrl = environment.endPoints.workflowBase

    let token
    if(this.token) {
      token = this.token
    }else {
      token = this.guestToken
    }

    if(this.sendMailAfterApproval) {
      this.workflowService.sendWorkflowReportAfterApproval(baseUrl, this.sendedReportId, this.approvers, token).then(res => {
        if(res) {
          this.isRequestSended = true
          this.showRequestAlert = true
        }
      })
      .catch(error => {
        if(error.error == 'permission-denied') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.INVALID-TOKEN'))
        }
        if(error.error == 'missing-parameters') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.MISSING-PARAMETERS'))
        }
        if(error.error == 'invalid-parameters') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.USER-DETAIL.INVALID_EMAIL'))
        }
        if(error.error == 'report-not-found') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.REPORT-NOT-FOUND'))
        }
        if(error.error == 'internal-error') {
          this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
        }
      })
      .finally(() => this.loaderService.hide() )
    }else{
      this.workflowService.sendWorkflowReportToSign(baseUrl, this.sendedReportId, this.approvers, token).then(res => {
        if(res) {
          this.isRequestSended = true
          this.showRequestAlert = true
        }
      })
      .catch(error => {
        if(error.error == 'permission-denied') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.INVALID-TOKEN'))
        }
        if(error.error == 'missing-parameters') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.MISSING-PARAMETERS'))
        }
        if(error.error == 'invalid-parameters') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.USER-DETAIL.INVALID_EMAIL'))
        }
        if(error.error == 'report-not-found') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.REPORT-NOT-FOUND'))
        }
        if(error.error == 'internal-error') {
          this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
        }
      })
      .finally(() => this.loaderService.hide() )
    }
  }

  openContinueWithQrModal() {
    this.showMenu = false
    this.workflowMenuClickedChange.emit(false)
    this.qrModalShow = true
  }

  closeQrModal() {
    this.qrModalShow = false
  }

  generateQR() {
    this.qrModalShow = false
    this.isPrefilledSave = true
    this.submit(false, true)
  }

  copyGuestLinkToClipboard(input) {
    input.select()
    document.execCommand('copy');
  }

  userWorkflowTranslationInit() {
    this.workflowCopy = JSON.parse(JSON.stringify(this.workflow))

    Object.keys(this.workflow.translations).forEach(res => {
      let workflowLang = this.allLangs.find(lang => lang.code == res)
      if(workflowLang) {
        this.workflowLangs.push(workflowLang)
      }
    })
    if(this.userDefaultLang) {
      let isLang = this.workflowLangs.find(lang => lang.code == this.userDefaultLang)
      if(isLang) {
        this.selectedLanguage = isLang.code
      }else{
        this.selectedLanguage = this.workflow.defaultLanguage
      }
    }else{
      this.selectedLanguage = this.workflow.defaultLanguage
    }
        
    this.wfName = this.workflowCopy.translations[this.selectedLanguage][this.workflowCopy.name]
    this.langChanged.emit(this.workflowCopy.translations[this.selectedLanguage][this.workflowCopy.name])
    this.wfDescription = this.workflowCopy.translations[this.selectedLanguage][this.workflowCopy.description]
    const stepIds = this.workflowCopy.steps ? Object.keys(this.workflowCopy.steps) : []

    let steps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(this.workflow.steps[sid]))
      stp.id = sid
      return stp
    }).sort((a,b) => a.data.order - b.data.order)
    steps.forEach(step => {
      step.data.name = this.workflowCopy.translations[this.selectedLanguage][step.data.name]
      step.data.layouts.forEach(layout => {
        layout.items[0].templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.label]
        if(layout.items[0].templateOptions.placeholder) {
          layout.items[0].templateOptions.placeholder = this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.placeholder]
        }
        if(layout.items[0].templateOptions.html) {
          layout.items[0].templateOptions.html = this.workflowCopy.translations[this.selectedLanguage][layout.items[0].templateOptions.html]
        }
        if(layout.items[0].templateOptions.options) {
          layout.items[0].templateOptions.options = layout.items[0].templateOptions.options.map(option => {return {label:this.workflowCopy.translations[this.selectedLanguage][option.label], value: option.value}})
        }
        if(layout.items[0].templateOptions.tableData) {
          layout.items[0].templateOptions.tableData.columns.forEach(column => {
            column.cells.forEach(cell => {
              if(cell.templateOptions.label) {
                cell.templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][cell.templateOptions.label]
              }
            })
          })
          layout.items[0].templateOptions.tableData.rows.forEach(row => {
            row.cells.forEach(cell => {
              if(cell.templateOptions.label) {
                cell.templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][cell.templateOptions.label]
              }
              if(cell.templateOptions.placeholder) {
                cell.templateOptions.placeholder = this.workflowCopy.translations[this.selectedLanguage][cell.templateOptions.placeholder]
              }
              if(cell.templateOptions.options) {
                cell.templateOptions.options = cell.templateOptions.options.map(option => {return {label:this.workflowCopy.translations[this.selectedLanguage][option.label], value: option.value}})
              }
            })
          })
        }
        if(layout.items[0].templateOptions.formData) {
          layout.items[0].templateOptions.formData.forEach(data => {
            if(data.label) {
              data.label = this.workflowCopy.translations[this.selectedLanguage][data.label]
            }
            data.fields.forEach(field => {
              if(field.templateOptions.label) {
                field.templateOptions.label = this.workflowCopy.translations[this.selectedLanguage][field.templateOptions.label]
              }
            })
          })
        }
      })
    })
    this.steps = steps
    if(localStorage.getItem('userDefaultLanguage')) {
      let defaultLang = this.workflowLangs.find(res => res.code == localStorage.getItem('userDefaultLanguage'))
      if(defaultLang) {
        this.selectedLangName =  defaultLang.name 
      }else{
        let defaultLang = this.workflowLangs.find(res => res.code == this.workflow.defaultLanguage)
        this.selectedLangName = defaultLang ? defaultLang.name : null
      }
    }else{
      if(this.workflow.defaultLanguage){
        let defaultLang = this.workflowLangs.find(res => res.code == this.workflow.defaultLanguage)
        this.selectedLangName = defaultLang ? defaultLang.name : null
      }
    }
  }
  saveAsDraft() {
    this.qrModalShow = false
    this.submit(false)
  }
  
  qrDetect() {
    this.isScan = true
    if(this.action) {
      this.action.start()
    }
  }

  public handle(action: NgxScannerQrcodeComponent, fn: string): void {
    this.isScan = true
    this.scanStarted = true
    action[fn]().subscribe(console.log, alert);
    this.action = action
  }

  showRemoteExpertsModal() {
    this.showMenu = false
    this.workflowMenuClickedChange.emit(false)

    if(!this.currentUser?.allow_calling) {
      this.showCallPermissionWarning = true
    }else{
      const baseUrl = environment.endPoints.remoteBase
      this.isLoadingExpertModal = true
      this.workflowService.workflowRemoteIntegrationGetId(baseUrl, this.workflow.id).then(res => {
        this.remoteLink = res.data.link
        this.remoteId = res.data.linkId
        this.expertsToCall = res.data.experts
        this.expertsToCall.forEach(expert => {
          this.expertGroup.addControl(expert.id, new UntypedFormControl())
        })
        this.showExpertsModal = true
        this.isLoadingExpertModal = false
      })
    }
  }

  callExpert() {
    this.isLoadingExpertModal = true
    let expertListToCall = []
    this.remoteIntegration[this.remoteId] = {step: this.stepData.id}
    this.showExpertsModal = false
    expertListToCall = Object.keys(this.expertGroup.value).filter(expert => this.expertGroup.value[expert] == true )
    this.workflowService.workflowRemoteIntegrationUsers(this.remoteId, expertListToCall).then(res => {
      if(res) {
        window.open(this.remoteLink, "_blank");
      }
    })
    .catch(error => {
      if(error.error == 'permission-denied') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.INVALID-TOKEN'))
      }
      if(error.error == 'missing-parameters') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.MISSING-PARAMETERS'))
      }
      if(error.error == 'integration-not-found') {
        this.snackBarService.error(this.translateService.instant('MAIN.USER-WORKFLOW.ERRORS.INTEGRATION-NOT-FOUND'))
      }
      if(error.error == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.isLoadingExpertModal = false
      Object.keys(this.expertGroup.controls).forEach(res => {
        this.expertGroup.controls[res].patchValue(false)
      })
    })
  }

  cancelRemoteCall() {
    this.showExpertsModal = false
    Object.keys(this.expertGroup.controls).forEach(res => {
      this.expertGroup.controls[res].patchValue(false)
    })
  }

  closeWarningModal() {
    this.showCallPermissionWarning = false
  }

  showContentList() {
    this.setCurrentStepValues() 
    this.setEndDuration()
    this.showStepList = true
    this.showMenu = false
    this.workflowMenuClickedChange.emit(false)
  }

  updatePrefilledReport() {
    
    const initialState= {
      headerTitle: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.SAVE-PREFILLED-TITLE`),
      textMessage: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.SAVE-PREFILLED-TEXT`),
      confirmButtonText: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.CONTINUE`),
      confirmButtonStyle: 'primary',
      cancelButtonText:this.translateService.instant('MAIN.CONTENT.DASHBOARD.CANCEL'),
      instantClose:false
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        this.updatedPrefilled = true
        confirmModalRef.hide();
        this.isSubmitted = false
        this.showMenu = false
        this.submit(false)
      }
       this.savedPrefilled = false
    })
  }

  showStepFiles() {
    this.showFiles = true
  }

  closeStepFiles() {
    this.showFiles = false
    this.stepFilesClickedChange.emit(false)
  }

  downloadStepFile(file) {
    this.isDownloading = true

    if(file.url) {
      this.downloadFile(file.url).subscribe({
        next: (data: Blob) => {
          const blob = new Blob([data], { type: file.type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
    
          link.download = file.name;
          link.click();
          window.URL.revokeObjectURL(link.href);
          this.isDownloading = false
        },
        error: (e) => {
          this.isDownloading = false
        }
      })
    }
  }

  downloadFile(pdfUrl: string): Observable<Blob> {
    return this.http.get(pdfUrl, { responseType: 'blob' });
  }

}
