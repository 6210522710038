<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <!-- <div class="modal-header">
    </div> -->
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <div style="position: absolute;top: 8px;right:21px; cursor: pointer;" (click)="modalRef.hide()">
                <img src="assets/img/close.svg" alt="">

            </div>
            <app-step-preview 
                [stepData] = "stepData"
                [template] = "template"
                [stepListCount] = "stepListCount"
                [workflowName] = "workflowName"
                [workflowTranslations] = "workflowTranslations"
                [selectedStepOrder] = "selectedStepOrder"
                [viewOnly] = "true"
                style="width: 100%;">
            </app-step-preview>

        </div>
    </div>
</div>