import { Directive, OnInit, OnDestroy, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

declare var $: any;

@Directive({
    selector: '[dynamicTooltip]'
})
export class DynamicTooltipDirective implements OnInit, OnDestroy, OnChanges {
    @Input('dynamicTooltip') dynamicTooltip: string;
    @Input('tooltipDisabled') tooltipDisabled: boolean = false;
    @Input('tooltipDelay') tooltipDelay: number = 500;
    @Input('tooltipPosition') tooltipPosition: "left" | "top" | "right" | "bottom" = "top";

    private langSub: Subscription = null;
    private tooltipAdded: boolean = false;
    private jqueryObject: any;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        if (this.dynamicTooltip) {
            this.jqueryObject = $(this.el.nativeElement);
            this.addTooltip(this.dynamicTooltip);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['tooltipDisabled'] && !changes['tooltipDisabled'].firstChange && this.tooltipAdded) {
            this.tooltipDisabled = changes['tooltipDisabled'].currentValue;
            this.jqueryObject.tooltip(this.tooltipDisabled ? "disable" : "enable");
        }
        if (changes['tooltipDelay'] && !changes['tooltipDelay'].firstChange && this.tooltipAdded) {
            this.tooltipDelay = changes['tooltipDelay'].currentValue;
            this.addTooltip(this.dynamicTooltip);
        }
        if (changes['tooltipPosition'] && !changes['tooltipPosition'].firstChange && this.tooltipAdded) {
            this.tooltipPosition = changes['tooltipPosition'].currentValue;
            this.addTooltip(this.dynamicTooltip);
        }
        if (changes['dynamicTooltip'] && !changes['dynamicTooltip'].firstChange) {
            this.dynamicTooltip = changes['dynamicTooltip'].currentValue;
            this.addTooltip(this.dynamicTooltip);
        }
    }

    ngOnDestroy() {
        if (this.langSub) { this.langSub.unsubscribe() }
        if (this.dynamicTooltip && this.tooltipAdded) { this.jqueryObject.tooltip('dispose') }
    }

    private addTooltip(tooltip: string) {
        if (this.tooltipAdded) {
            this.jqueryObject.tooltip('dispose');
        }
        this.jqueryObject.tooltip({ title: tooltip, trigger: "hover", animation: false, placement: this.tooltipPosition, delay: {show: this.tooltipDelay, hide: 100} });
        if (this.tooltipDisabled) { this.jqueryObject.tooltip("disable") }
        this.tooltipAdded = true;
    }
}