<div class="d-flex workflow-connections-container" #connectionsContainer>
    <div class="workflow-connections-wrapper" [ngStyle]="{'zoom': scale ? scale : 'none'}">
        <div id="cursor" #cursor></div>
        <div class="drop-area d-flex" style="    background: #f0f0f0;" (cdkDragMoved)="stepsAreaMoved($event)" (mousedown)="stepsAreaMouseDown($event)" (click)="stepsAreaClicked($event)" #stepsArea cdkDrag [ngStyle]="{'cursor':grabbing ? 'grab' : 'grabbing'}">
            <!-- cdkDragBoundary=".workflow-connections-wrapper" -->
            <div id="step{{step.id}}" class="step-box d-flex align-items-center justify-content-center" 
            [ngClass]="{'selected':selectedStep?.id==step.id}" 
            
            *ngFor="let step of stepList" cdkDrag (mousedown)="stepMouseDown($event, step)" (cdkDragMoved)="stepMoved($event, step)">
                <img src="assets/img/start-connection.svg" alt="" class="start-arrow" *ngIf="step.id == initialStep">
                <img src="assets/img/start-connection-disable.svg" alt="" class="start-arrow-disabled" 
                 *ngIf="step.id !== initialStep && selectedStep?.id==step.id"
                 [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.INITIAL-STEP-CHANGE-TEXT' | translate )"
                 (click)="changeInitialStep(step)">

                <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="endConnection(step)" *ngIf="step.id !== initialStep" (mousedown)="ifConnectButtonClicked = true"></button>
                <div class="label-div">
                    <label class="fs-14 font-weight-600 step-name" title="{{workflowTranslations[selectedLang][step.data.name]}}">{{workflowTranslations[selectedLang][step.data.name]}}</label>
                </div>
                <div class="preview">
                    <img src="assets/img/preview-info.svg" class="normal-icon" (click)="openPreview(step)">
                    <img src="assets/img/selected-preview.svg" class="hover-icon" (click)="openPreview(step)">
                </div>
                <button type="button" class="btn btn-primary btn-square btn-sm" (click)="startConnection(step)" (mousedown)="ifConnectButtonClicked = true"></button>
            </div>
        </div>
    </div>
    <div class="condition-part" *ngIf="!fullMode || (fullMode && selectedStep && !formValid)">
        <div class="condition-header d-flex justify-content-between align-items-center">
            <label class="fs-18 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.CONDITIONS' | translate }}</label>
            <button class="v-btn btn btn-primary vsight-button create-button" (click)="createConditionFormData()" [disabled]="!selectedStep || !formValid">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.ADD-CONDITION' | translate }}</button>
        </div>
        <div class="condition-body">
            <div class="rectangle d-flex" *ngIf="!selectedConnection && !selectedStep">
                <div class="info-p">
                    <img src="assets/img/info.svg">
                </div>
                <div class="inform-text">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.INFORM-TEXT' | translate }}</div>
            </div>
            <div *ngIf="selectedConnection">
                <div class="d-flex">
                    <label class="fs-14 me-3 drop-options">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.FROM' | translate }}</label>
                    <div class="connection-info me-2">
                        <label class="fs-16 font-weight-400">{{selectedConnection.start.data.name}}</label>
                    </div>
                    <label class="fs-14 me-3 drop-options">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.TO' | translate }}</label>
                    <div class="connection-info me-2">
                        <label class="fs-16 font-weight-400">{{selectedConnection.end.data.name}}</label>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <button class="btn btn-secondary-outline vsight-button-outline-primary" (click)="removeConnection({startId: selectedConnection.start.id, endId: selectedConnection.end.id, connection: selectedConnection.connection, button: selectedConnection.button}, selectedConnection.index)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.DELETE-CONNECTION' | translate }}</button>
                </div>
            </div>
            <div *ngIf="!selectedConnection && selectedStep">
                <div *ngFor="let conditionsFormGroup of conditionForm.controls['connections'].controls; let conditionIndex=index;">
                    <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.CONDITION' | translate }} {{ conditionIndex + 1 }}</label>
                    <div class="mt-3">
                        <div *ngFor="let conditionRowForm of conditionsFormGroup.controls['conditions'].controls; let conditionRowIndex = index; first as isFirst">
                            <form [formGroup]="conditionRowForm">
                                <div class="d-flex mt-3">
                                    <div class="d-flex flex-column">
                                        <label class="fs-14 font-weight-400 me-3 drop-options" style="margin-left: 40px;" *ngIf="isFirst">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.WHEN' | translate }}</label>
                                        <ng-select placeholder="{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.SELECT' | translate }}" [disabled]="true" *ngIf="!isFirst && conditionRowIndex<=1" [formControl]="conditionsFormGroup.controls['logicalOperator']" [searchable]="false" [clearable]="false" class="input-condition me-2 fs-14 font-weight-400" style="width: 86px;">
                                            <ng-option [value]="operator" *ngFor="let operator of logicalOperators;first as isFirst">{{operator}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="conditionRowIndex>1" class="operator me-2">
                                            <label class="fs-14 font-weight-400">{{conditionsFormGroup.controls['logicalOperator'].value}}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <ng-select placeholder="{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.SELECT' | translate }}" formControlName="type" [searchable]="false" [clearable]="false" class="input-condition me-2 fs-14 font-weight-400">
                                            <div *ngFor="let item of itemMap | keyvalue">
                                                <ng-option [value]="item.key" *ngIf="typeOptions[item.value.type]">
                                                    {{typeOptions[item.value.type] | translate }} 
                                                    <span *ngIf="!workflowTranslations">({{item.value.label}})</span>
                                                    <span *ngIf="workflowTranslations">({{workflowTranslations[selectedLang][item.value.label]}})</span>
                                                </ng-option>
                                            </div>
                                            
                                        </ng-select>
                                        <p class="validation-text mt-1 mb-0" *ngIf="conditionRowForm.controls['type'].errors && conditionRowForm.controls['type']!=='empty'">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.REQUIRED-FIELD' | translate }}</p>
                                    </div>

                                    <div>
                                        <ng-select placeholder="{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.SELECT' | translate }}" formControlName="condition" [searchable]="false" [clearable]="false" class="input-condition me-2 fs-14 font-weight-400">
                                            <div *ngFor="let operator of generic[itemMap[conditionRowForm.value.type]?.type]">
                                                <ng-option [value]="operator.value" *ngIf="operator.value !=='empty'">
                                                    {{operator.title | translate}}
                                                </ng-option>
                                            </div>
                                        </ng-select>
                                        <p class="validation-text mt-1 mb-0" *ngIf="conditionRowForm.controls['condition'].errors">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.REQUIRED-FIELD' | translate }}</p>
                                    </div>

                                    <div>
                                        <input *ngIf="itemMap[conditionRowForm.controls.type.value]?.type == 'datepicker' && !(conditionRowForm.controls['condition']?.value=='isBetween' || conditionRowForm.controls['condition'].value=='isNotBetween')" #startDate="bsDatepicker" formControlName="value" name="startDate" class="form-control input-condition custom-form-control" id="form-control" bsDatepicker autocomplete="off" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }">
                                        <input *ngIf="itemMap[conditionRowForm.controls.type.value]?.type == 'datepicker' && conditionRowForm.controls['condition']?.value=='isBetween' || conditionRowForm.controls['condition'].value=='isNotBetween'"  formControlName="value"  class="form-control input-condition custom-form-control" id="form-control" bsDaterangepicker autocomplete="off" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" placement="left">

                                        <ng-select *ngIf="itemMap[conditionRowForm.value.type]?.options" placeholder="{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.SELECT' | translate }}" formControlName="value" [searchable]="false" [clearable]="false" class="input-condition me-2 fs-14 font-weight-400">
                                            <div *ngFor="let option of itemMap[conditionRowForm.value.type]?.options">
                                                <ng-option [value]="option" *ngIf="!workflowTranslations">
                                                    {{option}}
                                                </ng-option>
                                                <ng-option [value]="option" *ngIf="workflowTranslations">
                                                    {{workflowTranslations[selectedLang][option]}}
                                                </ng-option>
                                            </div>
                                            
                                        </ng-select>

                                        <input placeholder="{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.ENTER' | translate }}"
                                         *ngIf="(conditionRowForm?.controls?.type?.value && itemMap?.[conditionRowForm?.controls?.type.value]?.type !== 'datepicker' && itemMap[conditionRowForm.controls?.type.value]?.type !== 'multicheckbox' && itemMap[conditionRowForm.controls.type.value].type !== 'radio') || !conditionRowForm.controls.type.value"
                                         type="text" class="form-control input-condition fs-14 font-weight-400" formControlName="value" [disabled]="conditionRowForm.controls.condition.value == 'isEmpty'"
                                         [ngStyle]="{'pointer-events': conditionRowForm.controls.condition.value == 'isEmpty' || conditionRowForm.controls.condition.value == 'isNotEmpty' ? 'none' : ''}">
                                        <p class="validation-text mt-1 mb-0" *ngIf="conditionRowForm.controls['value'].errors && (conditionRowForm.controls['condition'] !== 'isEmpty' || conditionRowForm.controls['condition'].value !== 'isNotEmpty')">Required field</p>
                                    </div>
                                    <div class="btn-group dropstart drop-options more">
                                        <div data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/more-grey.svg" alt="" class="cursor-pointer" width="26px">
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li role="menuitem">
                                                <a class="dropdown-item fs-14" (click)="addLogicalCondition(conditionsFormGroup, conditionRowIndex)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.ADD-LOGICAL-CONDITION' | translate }}</a>
                                            </li>
                                            <li role="menuitem" *ngIf="!isFirst">
                                                <a class="dropdown-item fs-14" (click)="deleteLogicalCondition(conditionsFormGroup,conditionIndex, conditionRowIndex)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.DELETE-CONDITION' | translate }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="pt-2">
                                <label class="fs-14 font-weight-400" style="margin-left: 40px;margin-right: 17px;">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.GO-TO' | translate }}</label>
                            </div>
                            <div>
                                <ng-select [formControl]="conditionsFormGroup.controls['next']" [searchable]="false" [clearable]="false" class="input-condition me-2 fs-14 font-weight-400" style="width: 468px!important;">
                                    <div *ngFor="let step of stepList">
                                        <ng-option [value]="step.id" *ngIf="step.id !== selectedStep.id && step.id !==initialStep && !workflowTranslations">
                                            {{step.data.name}}
                                        </ng-option>
                                        <ng-option [value]="step.id" *ngIf="step.id !== selectedStep.id && step.id !==initialStep && workflowTranslations">
                                            {{workflowTranslations[selectedLang][step.data.name]}}
                                        </ng-option>
                                    </div>
                                </ng-select>
                                <p class="validation-text mt-1 mb-0" *ngIf="conditionsFormGroup.controls['next'].errors">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.REQUIRED-FIELD' | translate }}</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mt-4">
                            <button type="button" class="v-btn vsight-button me-1 btn vsight-button-outline-primary font-weight-600"
                             (click)="deleteConditionConection(conditionIndex)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.DELETE-CONDITION' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-3" *ngIf="selectedStep && !selectedConnection" [ngClass]="{'else-condition': this.conditionForm.controls.connections?.controls?.length>0}">
            <div class="pt-2">
                <label class="fs-14 font-weight-400" style="margin-left: 40px;margin-right: 12px;">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.ELSE-CONDITION-TEXT' | translate }}</label>
            </div>
            <div>
                <ng-select [formControl]="next" [searchable]="false" [clearable]="false" class="input-condition fs-14 font-weight-400 text-dark-grey" style="width: 375px!important;">
                    <div>
                        <ng-option [value]="notSelected">
                            {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.NOT-SELECTED' | translate }}
                        </ng-option>
                    </div>
                    <div *ngFor="let step of stepList">
                        <ng-option [value]="step.id" *ngIf="step && step.id !== selectedStep.id && step.id !==initialStep && !workflowTranslations">
                            {{step.data.name}}
                        </ng-option>
                        <ng-option [value]="step.id" *ngIf="step && step.id !== selectedStep.id && step.id !==initialStep && workflowTranslations">
                            {{workflowTranslations[selectedLang][step.data.name]}}
                        </ng-option>
                    </div>
                </ng-select>
                <p class="validation-text mt-1 mb-0" *ngIf="next.errors?.['required']">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.REQUIRED-FIELD' | translate }}</p>
            </div>
        </div>

    </div>
    
</div>

<div style="position: absolute;top: 0;right:0;bottom: 0;left: 65px;background: #080C14;
    opacity: 0.4;z-index:1000;" *ngIf="!connectionEnabled">
</div>
<div style="position: absolute;top: 0;right:0;bottom: 0;left: 65px;
opacity: 1;z-index:1000;" *ngIf="!connectionEnabled">
    <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
        <div class="info-box d-flex flex-column align-items-center">
            <img src="assets/img/info.svg" class="me-1">
            <label for="" class="fs-14" style="color: #667999;text-align: center;">
                {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.TURN-ON-CONNECTIONS-TEXT' | translate }}
            </label>
            <button class="v-btn btn btn-primary vsight-button create-button mt-3" (click)="changeStatus()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.TURN-ON-CONNECTIONS' | translate }}</button>
        </div>
        
    </div>
</div>