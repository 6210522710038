import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss']
})
export class TableModalComponent implements OnInit {

  @Input() close?:EventEmitter<any>
  onClose: EventEmitter<any> = new EventEmitter();
  @Input() tableData?
  @Input() workflowPublishedVersion?
  @Input() workflowTranslations?
  @Input() selectedLang?

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
