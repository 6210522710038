import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LoaderService } from '@services/support/loader.service';

import FilerobotImageEditor from 'filerobot-image-editor'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { base64ToFile } from 'ngx-image-cropper';
import { FilerobotImageEditorConfig, TABS, TOOLS } from 'react-filerobot-image-editor'
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-filerobot-iimage-editor',
  templateUrl: './filerobot-iimage-editor.component.html',
  styleUrls: ['./filerobot-iimage-editor.component.scss']
})
export class FilerobotIimageEditorComponent implements OnInit,OnDestroy {

  onClose: EventEmitter<any> = new EventEmitter();
  onSave: EventEmitter<any> = new EventEmitter();
  //closeClicked: EventEmitter<any> = new EventEmitter();
  closeClicked = new BehaviorSubject<boolean>(false)


  @ViewChild('imageEditorContainer', { static: false }) imageEditorContainer: ElementRef
  @Input() imageUrl
  @Input() imageName

  loading = true
  isImageReady = false;


  finalUrl
  file

  config:FilerobotImageEditorConfig

  editorRef: FilerobotImageEditor
  editedImage

  constructor(
    public modalRef: BsModalRef,
    public loaderService: LoaderService
  ) { }
  ngOnDestroy(): void {
    this.imageEditorContainer.nativeElement = null
    this.editorRef.terminate();
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
      this.loading =false
      this.loadImageEditor(this.imageEditorContainer.nativeElement)
      //this.loaderService.show()
    
  }

  loadImageEditor(container) {
      this.config = {
        source: this.imageUrl,
        onSave: (editedImageObject, designState) => {
          let files =  base64ToFile(editedImageObject.imageBase64)
          this.file = new File([files], this.imageName)
          this.onSave.next(true)
          this.onClose.next(true)
          this.editorRef.terminate();
  
        },
        onClose: (closingReason) => {
          this.imageUrl = null
          this.closeClicked.next(true)
          this.onClose.next(true)
          this.editorRef.terminate();
          
          //this.modalRef.hide();
        },
        annotationsCommon: {
          fill: "#00000000",
          stroke: "#EE4025",
          strokeWidth: 0
        },
        closeAfterSave: true,
        Text: {
          text: 'Text',
          fontFamily:'Open Sans',
          strokeWidth: 0,
          fill: "#EE4025"
        },
        Polygon: {
          sides: 5,
          strokeWidth: 8
        },
        Pen: {
          fill: "#EE4025",
          stroke: "#EE4025",
          strokeWidth: 8
        },
        Line: {
          strokeWidth: 8
        },
        Arrow: {
          strokeWidth: 10
        },
        Rect:{
          strokeWidth: 8
        },
        Ellipse: {
          strokeWidth: 8
        },
        Image: {
          gallery: [{
            originalUrl: 'assets/widgets/touch.png',
            previewUrl: 'assets/widgets/touch.png',
          },
          {
            originalUrl: 'assets/widgets/fire.png',
            previewUrl: 'assets/widgets/fire.png',
          },
          {
            originalUrl: 'assets/widgets/skull.png',
            previewUrl: 'assets/widgets/skull.png',
          },
          {
            originalUrl: 'assets/widgets/rightArrow.png',
            previewUrl: 'assets/widgets/rightArrow.png',
          },
          {
            originalUrl: 'assets/widgets/explosive.png',
            previewUrl: 'assets/widgets/explosive.png',
          },
          {
            originalUrl: 'assets/widgets/radiation.png',
            previewUrl: 'assets/widgets/radiation.png',
          },
          {
            originalUrl: 'assets/widgets/leftArrow.png',
            previewUrl: 'assets/widgets/leftArrow.png',
          },
          {
            originalUrl: 'assets/widgets/electric.png',
            previewUrl: 'assets/widgets/electric.png',
          },
          {
            originalUrl: 'assets/widgets/press.png',
            previewUrl: 'assets/widgets/press.png',
          },
          {
            originalUrl: 'assets/widgets/warning.png',
            previewUrl: 'assets/widgets/warning.png',
          },
          {
            originalUrl: 'assets/widgets/cold.png',
            previewUrl: 'assets/widgets/cold.png',
          }]
        },
        translations: {
          profile: 'Profile',
          coverPhoto: 'Cover photo',
          facebook: 'Facebook',
          socialMedia: 'Social Media',
          fbProfileSize: '180x180px',
          fbCoverPhotoSize: '820x312px'
        },
        Crop: {
          presetsItems: [
            {
              titleKey: 'classicTv',
              descriptionKey: '4:3',
              ratio: '4:3',
            },
            {
              titleKey: 'cinemascope',
              descriptionKey: '21:9',
              ratio: '21:9',
            },
          ],
          presetsFolders: [
            {
              titleKey: 'socialMedia',
              groups: [
                {
                  titleKey: 'facebook',
                  items: [
                    {
                      titleKey: 'profile',
                      width: 180,
                      height: 180,
                      descriptionKey: 'fbProfileSize',
                    },
                    {
                      titleKey: 'coverPhoto',
                      width: 820,
                      height: 312,
                      descriptionKey: 'fbCoverPhotoSize',
                    },
                  ],
                },
              ],
            },
          ],
        },
        Watermark: {
          gallery : ['assets/widgets/touch.png', 'assets/widgets/fire.png','assets/widgets/skull.png', 'assets/widgets/rightArrow.png', 'assets/widgets/explosive.png','assets/widgets/radiation.png', 'assets/widgets/leftArrow.png', 'assets/widgets/electric.png', 'assets/widgets/press.png','assets/widgets/warning.png', 'assets/widgets/cold.png'],
        },
        tabsIds: [TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK/*, TABS.FILTERS, TABS.FINETUNE, TABS.RESIZE, TABS.WATERMARK*/],
        defaultTabId: TABS.ANNOTATE,
        defaultToolId: TOOLS.TEXT,
        savingPixelRatio: 4,
        previewPixelRatio: window.devicePixelRatio
      }
      this.config.source = this.imageUrl
      this.editorRef = new FilerobotImageEditor(container, this.config)
      this.editorRef.render()
      setTimeout(()=> { 
        let el = document.getElementsByTagName('canvas')
        let docLabels = document.getElementsByClassName('gloAWZ')
        docLabels[4].innerHTML = 'Add Icons'

        this.isImageReady = el.length >= 2
        if(!this.isImageReady){
           this.onClose.next(false)
           this.modalRef.hide()
           this.editorRef.terminate()
        }
      },2000)
    
  }


  close(){
    this.onClose.next(true)
    this.closeClicked.next(true)
    this.editorRef.terminate();
    this.modalRef.hide()
  }

  save() {
    let element: HTMLElement = document.getElementsByClassName('FIE_topbar-save-button')[0] as HTMLElement;
     element.click();
  }

}
