<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MODALS.IMAGE-EDITOR.TITLE' | translate }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">
            </div>
        </div>
        <div class="modal-body">
            <div *ngIf="!isImageReady" class="loader-layer">
                <div class="loader">
                    <div class="spinner"></div>
                </div>
            </div>
            <div class="w-100" #imageEditorContainer></div>
        </div>
        <div class="modal-footer">
            <div>
                <button class="v-btn btn btn-primary vsight-button" (click)="save()" [disabled]="!isImageReady">{{ 'MODALS.IMAGE-EDITOR.SAVE' | translate }}</button>
            </div>
        </div>
    </div>
</div>