import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-label',
  template: `
  <label class="text-{{to['hPosition']}}" [formlyAttributes]="field">{{ to.label }}</label>
  `,
})
export class FormlyFieldCustomLabel extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}