import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent implements OnInit {

  @Input('action') action?: string = 'create'

  onClose: EventEmitter<any> = new EventEmitter();

  selectedTemplate?: number;

  static templateArray = [
    {
      count: 1,
      name: "fullscreen",
      orientation: "row",
      weights: [1]
    },
    {
      count: 2,
      name: "vertical-equal",
      orientation: "row",
      weights: [1, 1]
    },
    {
      count: 2,
      name: "horizontal-equal",
      orientation: "column",
      weights: [1, 1]
    }
  ]

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  selectTemplate(){
    this.onClose.next(TemplateModalComponent.templateArray[this.selectedTemplate-1]);
  }

}
