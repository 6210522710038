<!-- <ng-container>
    <div class="fs-14 font-weight-500 cursor-pointer">
        <div style="position: relative; min-height: 200px; cursor: pointer; overflow: scroll;">
            <form #widgetTableForm="ngForm">
                <div class="d-flex flex-column col-12">
                    <div class="fs-15 mb-3 mt-2 text-start">Auftraggeber</div>
    
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Firma</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Ansprechpartner</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Straße</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>PLZ / Ort:</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Telefon</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Typ</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Datum</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Uhrzeit</label></div>
                        <div class="col-8"><input type="text" class=" form-control h-30"></div>
                    </div>
                </div>
                <hr>
    
                <div class="d-flex flex-column col-12">
                    <div class="fs-15 mb-3 mt-2 text-start">Einbauort</div>
    
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Firma</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Ansprechpartner</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Straße</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>PLZ / Ort:</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Telefon</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Auftrags-Nr.</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="col-4 text-start"><label>Außentemperatur</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30"></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-container> -->

<div class="fs-14 font-weight-500 cursor-pointer">
    <div style="position: relative; min-height: 200px; cursor: pointer; overflow: scroll;">
        <form #widgetTableForm="ngForm">
            <ng-container *ngFor="let formObj of formData;let formIndex = index;">
                <div class="text-start mb-3 fs-15">
                    {{formObj.label}}
                </div>
                <div class="d-flex flex-column col-12">
    
                    <div class="d-flex align-items-center mb-2" *ngFor="let field of formObj.fields;let fieldIndex = index;">
                        <div class="col-4 text-start"><label>{{field.templateOptions.label}}</label></div>
                        <div class="col-8"><input type="text" class="form-control h-30" name="{{field.key}}"  [(ngModel)]="field.value"></div>
                    </div>
                </div>
                <hr *ngIf="formIndex !== formData.length-1">
            </ng-container>
        </form>
    </div>
</div>