import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-section-modal',
  templateUrl: './section-modal.component.html',
  styleUrls: ['./section-modal.component.scss']
})
export class SectionModalComponent implements OnInit {
  onClose: EventEmitter<any> = new EventEmitter();
  @Input() stepList
  @Input() workflowTranslations?
  @Input() selectedLang?

  sectionList = []
  selectedSection

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.selectedSection = null
    this.sectionList = this.stepList.filter(res => res.data.isSection)
  }

  select(section) {
    this.sectionList.forEach(res=> res.selected = false)
    section['selected'] = true
    this.selectedSection = section
  }

  selectParent() {
    this.sectionList.forEach(res=> res.selected = false)
    this.onClose.next(this.selectedSection)
    this.modalRef.hide()
  }

}
