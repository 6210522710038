import { Injectable } from '@angular/core';
import { BehaviorSubject, first, lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  sidebarState: Observable<boolean>;
  private sidebarStateSource = new BehaviorSubject<boolean>(false);

  constructor() { 
    this.sidebarState = this.sidebarStateSource.asObservable();
  }

  async toggleSidebar() {
    const state = await lastValueFrom(this.sidebarState.pipe(first()));
    this.sidebarStateSource.next(!state);
  }
}
