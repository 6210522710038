<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-500 text-white">Create Video Flow</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <div class="d-flex flex-column align-items-center">
                <p class="fs-14 font-weight-400">Upload your video to automatically generate a step-by-step workflow guide.</p>
                <div class="mt-2">
                    <input type="file" class="d-none" accept="video/*" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput>
                    <div class="upload-drop-zone" filedrop fileType="video" (filesDropped)="dragDropUpload($event)" accept="video/*" id="drop-zone">
                        <div class="upload-icon mt-3"><img src="assets/img/upload-icon.svg"></div>
                        <div class="fs-14 font-weight-600 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
                        <div class="fs-13 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}
                            <img src="assets/img/info_icon.svg" height="20" class="ms-1" [dynamicTooltip]="'Video duration cannot exceed 30 minutes.'" tooltipPosition="top" [tooltipDelay]="0" style="margin-bottom: 1px;">
                        </div>
                        <div class="mb-3 mt-3 w-100" *ngIf="uploadProgress && isFileUploading" style="padding-inline: 43px;">
                            <progressbar [value]="uploadProgress"></progressbar>
                        </div>
                        <div *ngIf="fileName && !isFileUploading" class="mt-3 mb-3">
                            <label class="fs-13" style="color: #24A148;">Video uploaded successfully!</label>
                        </div>
                        <div class="file-name mb-2" *ngIf="fileName && !isFileUploading">
                            <label class="fs-13 font-weight-500">{{fileName}}</label>
                        </div>
                        <ng-container *ngIf="!('electron' | env)">
                            <div class="upload-button mb-4">
                                <button class="vsight-button select-button v-btn btn btn-primary top-gap-12 font-weight-500 mb-2 text-white" (click)="imageInput.click()">{{ fileName ? 'Change Video' : 'Select Video' }}</button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <label *ngIf="showTimeLimitError" class="validation-text fs-13 mt-2">Video duration cannot exceed 30 minutes.</label>

                <div class="d-flex top-gap-30 justify-content-center">
                    <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary" (click)="close()">
                        {{ 'MODALS.ALERT.CANCEL' | translate }}
                    </button>
                    <button type="button" class="v-btn vsight-button btn btn-primary create-button" (click)="createWorkflowWithVideoFile()" [disabled]="!uploadedFile">
                        Create
                    </button>
                </div>
            </div>
        </div>
        
    </div>
</div>
