import { Component, ContentChild, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@models/User';
import { Workflow } from '@models/Workflow';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { DetailPageService } from '@services/detail-page.service';
import { AppService } from '@services/support/app.service';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { DefaultLanguageModalComponent } from 'src/app/components/modals/default-language-modal/default-language-modal.component';
import { JsonViewerComponent } from 'src/app/components/modals/json-viewer/json-viewer.component';
import { VideoUploadModalComponent } from 'src/app/components/modals/video-upload-modal-2/video-upload-modal-2.component';
import { WorkflowUsersModalComponent } from 'src/app/components/modals/workflow-users-modal/workflow-users-modal.component';
import { environment } from 'src/environments/environment';
import languagesJson from '../../../../../assets/languages.json';
import { WorkflowComponent } from '../workflow/workflow.component';

@Component({
  selector: 'app-video-flow-dashboard',
  templateUrl: './video-flow-dashboard-2.component.html',
  styleUrls: ['./video-flow-dashboard-2.component.scss']
})
export class VideoFlowDashboardComponent implements OnInit {

  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [];

  workflows: Workflow[] = []

  subscriptions: Subscription[] = []
  isLoading: boolean = true;
  allLabels  = []
  filteredLabels= []
  isAdmin
  dataLoaded = false;
  allUsers: User[] = []
  showWorkflow: boolean = false
  isEditorMode = false

  easyAccessUpdated = false

  labelGroup = new FormGroup({})
  filteredLabelWorkflows = []
  filteredNameWorkflows = []
  isLoadingFilter = false
  labelSearchControl = new FormControl()
  filteredLabelList = []
  wfName : string

  filter = new FormControl()

  @ContentChild('searchInput') searchInput: ElementRef;

  hideConnections
  isQrAvailable
  hasVideoFlow
  workflowSections
  isAIAvailable

  constructor(
    private dbService: DbService,
    public loaderService: LoaderService,
    private workflowService: WorkflowService,
    private detailPageService: DetailPageService,
    private modalService: BsModalService,
    private authService: AuthService,
    private workflowLanguageService: WorkflowLanguageService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private appService: AppService
  ) {
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin
    }))
   }

  ngOnInit(): void {
    this.subscriptions.push(this.appService.isEditorMode.subscribe(res => {
      this.dataLoaded = false
      if(this.isAdmin) {
        if (res !== this.isEditorMode) {
          this.isEditorMode = res
        }
      }
    }))

    this.authService.getAccountData().then(account => {
      this.isAIAvailable = account.data.accountData.features?.workflowai
      this.hideConnections = !account.data.accountData.add_ons?.workflowconnections
      this.isQrAvailable = account.data.accountData.features?.workflowqr
      this.hasVideoFlow = account.data.accountData.add_ons?.videoflow
      this.workflowSections = account.data.accountData.add_ons?.workflowsections
    })
    
    this.workflowService.getAllUsersForWorkflow().then(result => {
      this.allUsers = result.users
    })
    this.displayedColumns =[
      "draft['listName']",
      "status",
      "lastEdited",
      "languages",
      "actions"
    ]

    if(this.isAdmin) {
      this.getWorkflowList()
    }

    this.filter.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.filterWorkflows(res.toLowerCase())
      } else {
        this.filteredNameWorkflows = []
        this.filterLabels()
        this.dataSource = this.filteredLabelWorkflows.length ? new MatTableDataSource(this.filteredLabelWorkflows) :  new MatTableDataSource(this.workflows)
      }
    })

    if(this.isAdmin && this.isEditorMode) {
      this.workflowService.getWorkflowAccountLabels().then(workflowLabels => {
        this.allLabels = workflowLabels
        this.filteredLabelList = this.allLabels
        this.allLabels.forEach(label => {
          let labelControl = new FormControl (false)
          this.labelGroup.addControl(label, labelControl)
        })
      })
    }

    this.subscriptions.push(this.labelGroup.valueChanges.subscribe(filterLabelRes => {
      this.filteredLabelWorkflows = []
      this.filterLabels()
      
    }))

    this.subscriptions.push(this.labelSearchControl.valueChanges.subscribe(searchLabel => {
      if(searchLabel) {
        this.filteredLabelList = this.allLabels.filter(res => res.toLowerCase().includes(searchLabel.toLowerCase()))
      }else{
        this.filteredLabelList = this.allLabels
      }
    }))
  }

  getWorkflowList() {
    this.loaderService.show()
    return this.workflowService.getAllWorkflows().then(workflowList => {
      this.dataLoaded = true
      this.workflows = workflowList
      this.workflows = this.workflows.filter(res=> (res.draft && res.draft?.['isVideoFlow']) || (res.published && res.published?.['isVideoFlow']))
      this.workflows.forEach(res => {
        if(res) {
          if (res['show']) {
            res['show'] = false
          }
          if(res.createdAt){
            res.createdAt = new Date(res.createdAt)
          }
          if(res.draft?.updatedAt){
            res.draft.updatedAt = new Date(res.draft.updatedAt)
          }
          if(res.published?.updatedAt){
            res.published.updatedAt = new Date(res.published.updatedAt)
          }
        }
        
        if(res.draft?.translations) {
          res.draft['listName'] = res.draft.defaultLanguage ? res.draft.translations[res.draft.defaultLanguage][res.draft.name] : res.draft.translations['en'][res.draft.name]
        }else {
          if(res.draft) {
            res.draft['listName'] = res.draft.name

          }
        }
        if(res.published?.translations) {
          res.published['listName'] = res.published.defaultLanguage ? res.published.translations[res.published.defaultLanguage][res.published.name] : res.published.translations['en'][res.published.name]
        }else{
          if(res.published) {
            res.published['listName'] = res.published ? res.published.name : null
          }
        }
        
      })
      this.workflows.sort(function(a, b) {
        if(a?.draft?.['listName'] && b?.draft?.['listName']){
          if(a.draft['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
          else if(a.draft['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
        }
        if(a?.published?.['listName'] && b?.published?.['listName']){
          if(a.published['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
          else if(a.published['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
        }
        if(a?.published?.['listName'] && b?.draft?.['listName']){
          if(a.published['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
          else if(a.published['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
        }
        if(a?.draft?.['listName'] && b?.published?.['listName']){
          if(a.draft['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
          else if(a.draft['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
        }
        return 0;
      })
      // if (this.filter.value) {
      //   this.filterWorkflows(this.filter.value.toLowerCase())
      // } else {
        this.dataSource = new MatTableDataSource(this.workflows)
      // }
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case "draft['listName']":
            if (item.draft && item.draft['listName']) {
              return item.draft['listName'].toLowerCase()
            }else{
              return item.published['listName'].toLowerCase()
            }
            
          default:
              return item.published?.['listName'];
        }
      };
      this.dataSource.sort = this.sort;
      this.loaderService.hide()
    })
  }

  openVideoModal() {
    const initialState= {
      
    };
    let alertmodalRef = this.modalService.show(VideoUploadModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    this.subscriptions.push(alertmodalRef.content.createWorkflow.subscribe(response => {
      if(response.url) {
        this.createWorkflow(response)
      }else{
        // this.createWorkflow()
      }
    }))
  }

  createWorkflow(videoObject?) {
    const initialState= {
      isVideoFlow: true
    };
    let confirmModalRef = this.modalService.show(DefaultLanguageModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
        let descriptionranslationId = `$~#${this.dbService.createPushId()}$~#`
        
        let workflowObject = {
          draft: 
          { 
            connectionEnabled: false,
            name: nameTranslationId,
            description: descriptionranslationId, 
            steps: {}, 
            version: 1, 
            initialStep: '', 
            labels: [], 
            dataVersion: environment.dataVersion ,
            defaultLanguage: confirmModalRef.content.defaultLanguage,
            translations : {[confirmModalRef.content.defaultLanguage]: { [nameTranslationId]: confirmModalRef.content.workflowName, [descriptionranslationId]: ''}},
            isVideoFlow: true,
            videoFlowData: videoObject
          }, 
          users: [], 
          lastVersion: 1
        }
        
        let lang = languagesJson.languages.find(res => res.code == workflowObject.draft.defaultLanguage)
        this.workflowLanguageService.setCurrentLanguage({code: workflowObject.draft.defaultLanguage, name:lang.name})
        confirmModalRef.hide();
        this.loaderService.show()
        this.workflowService.createWorkflow(workflowObject)
        .then(workflow => this.open(workflow, 'draft', 'new', confirmModalRef.content.workflowName, confirmModalRef.content.defaultLanguage ))
        .catch(error => {
          if(error.error == 'permission-denied') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.WORKFLOW-NOT-FOUND'))
          }
          if(error.error == 'workflow-limit-reached') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.CREATION-LIMIT'))
          }
          if(error.error == 'internal-error') {
            this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
          }
        })
        .finally(() => this.loaderService.hide())
      }
    }))
  }

  open(workflow: Workflow, type: "published" | "draft", status?, workflowName?, defaultLanguage?): void {
    const addOns = {
      hideConnections: this.hideConnections,
      isQrAvailable: this.isQrAvailable,
      hasVideoFlow: this.hasVideoFlow,
      workflowSections: this.workflowSections
    }
    workflow['show'] = false
    
    if(type == 'draft' && workflow.draft?.defaultLanguage) {
      this.layoutPlaceholderControl(workflow,type)
    }
    const [instance, onClose] = this.detailPageService.loadComponent(WorkflowComponent, { workflow: workflow.id })
    instance.workflow = workflow
    instance.type = type
    instance.status = status
    instance.addOns = addOns

    instance.publish.subscribe(res=> {
      if(res) {
        this.workflowService.publishWorkflowDraft(workflow.id)
        .then(()=> this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-SUCCESSFUL')))
        .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-ERROR')))
        .finally(() => {
          this.loaderService.hide()
          this.getWorkflowList().then(()=> {
            let wf = this.workflows.find(publishedWorkflow => publishedWorkflow.id == workflow.id)
            this.openPublishedView(wf)
          })
        })
      }
    })

    
    onClose.then(() => {
      this.detailPageService.removeComponent()
        this.getWorkflowList()
    })

  }

  publishWorkflow(workflow: any) {
    workflow['show'] = false
    this.loaderService.show()
    this.workflowService.publishWorkflowDraft(workflow.id)
    .then(()=> this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-SUCCESSFUL')))
    .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-ERROR')))
    .finally(() => {
      this.loaderService.hide()
      this.getWorkflowList()
    })
  }

  openPublishedView(workflow:Workflow) {
    const addOns = {
      hideConnections: this.hideConnections,
      isQrAvailable: this.isQrAvailable,
      hasVideoFlow: this.hasVideoFlow,
      workflowSections: this.workflowSections
    }
    let saveStatus = false
    workflow['show'] = false
    const [instance, onClose] = this.detailPageService.loadComponent(WorkflowComponent, { workflow: workflow.id })
    instance.workflow = workflow
    instance.type = 'published'
    instance.saveStatus = saveStatus
    instance.easyAccessUpdated = this.easyAccessUpdated
    instance.addOns = addOns

    onClose.then(()=>{
      this.detailPageService.removeComponent()
      if(instance.easyAccessUpdated) {
        this.getWorkflowList()
      }
      if(instance.saveStatus){
        if(workflow.draft){
          this.open(workflow,'draft')
        }else{
          this.createDraft(workflow)
        }
      }
    })
  }

  createDraft(workflow: Workflow) {
    workflow['show'] = false
    this.loaderService.show()
    this.workflowService.createWorkflowDraft(workflow.id)
    .then(w => this.open(w, "draft"))
    .finally(() => this.loaderService.hide())
  }

  showWorkflowUsers(workflow) {
    let usersModalRef = this.modalService.show(WorkflowUsersModalComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false,
      initialState: {
        workflow: workflow
      }
    })
     usersModalRef.content.onClose.subscribe(res => {
      if(res) {
        workflow.users = res.map(u => u.id)
        usersModalRef.hide()
      }
     })
  }

  layoutPlaceholderControl(workflow,type) {
    const stepIds = workflow[type].steps ? Object.keys(workflow[type].steps) : []
    let workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(workflow[type].steps[sid]))
      stp.id = sid
      return stp
    })
    workflowSteps.forEach(step => {
      step.data?.layouts.forEach(layout => {
        if(layout.items[0].templateOptions) {
          if((layout.items[0].templateOptions.placeholder && layout.items[0].templateOptions.placeholder == '') || layout.items[0].templateOptions.placeholder == '') {
          let placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
          Object.keys(workflow.draft.translations).forEach(translationKey => {
            workflow.draft.translations[translationKey][placeholderTranslationId] = layout.items[0].templateOptions.placeholder
          })
          layout.items[0].templateOptions.placeholder = placeholderTranslationId
          }
        }
      })
    })

    workflow.draft.steps = workflowSteps.reduce((steps, stp) => {
      steps[stp.id] = { data: stp.data, coordinates: stp.coordinates }
      return steps
    }, {})
  }

  getUsersName(uid) {
    const user = this.allUsers.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }

  showJsonSource(event, workflow) {
    this.modalService.show(JsonViewerComponent, {
      initialState: {
        title: workflow.published ? workflow.published.name : workflow.draft.name,
        json: workflow,
        expanded: false
      },
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false
    });
  }

  filterWorkflows(filterData?, filterLabel?){
    let filteredData =[]
      if(this.filteredLabelWorkflows.length) {
        filteredData = this.filteredLabelWorkflows.filter(w => {
          if(w.published){
            return w.published?.['listName'].toLowerCase().includes(filterData.toLowerCase())
          }
          if(w.draft){
            return w.draft?.['listName'].toLowerCase().includes(filterData.toLowerCase())
          }
          else{
          return filteredData
          }
        })
      }else{
        filteredData = this.workflows.filter(w => {
          if(w.published){
            return w.published?.['listName'].toLowerCase().includes(filterData.toLowerCase())
          }
          if(w.draft){
            return w.draft?.['listName'].toLowerCase().includes(filterData.toLowerCase())
          }
          else{
          return filteredData
          }
        })
      }
    if(this.isAdmin &&this.isEditorMode) {
      filteredData = filteredData.filter(res => (res.draft && res.draft?.isVideoFlow) ||  (res.published && res.published?.isVideoFlow) )
    }
    
    this.filteredNameWorkflows = filteredData
    this.dataSource = new MatTableDataSource(filteredData);
  }

  clearAll() {
    this.isLoadingFilter = true
    this.filteredLabels = []
    Object.keys(this.labelGroup.controls).forEach(labelControl => {
      this.labelGroup.controls?.[labelControl].patchValue(false)
    })
    
    this.isLoadingFilter = false
  }

  selectLabels(label) {
    if( this.filteredLabels.includes(label) ) {
      let labelIndex = this.filteredLabels.findIndex(res => res == label)
      this.filteredLabels.splice(labelIndex, 1)
    }else{
      this.filteredLabels.push(label)
    }
  }

  deleteLabel(label) {
    let labelIndex = this.filteredLabels.findIndex(res => res == label)
    this.filteredLabels.splice(labelIndex, 1)
    this.labelGroup.controls[label].patchValue(false)
  }

  toggle(): void {
    if (this.searchInput?.nativeElement) {
      setTimeout(() => {
        (this.searchInput.nativeElement as HTMLInputElement).focus();
      });
    }
  }

  filterLabels(){
    if(this.filteredLabels.length) {
      if(this.isAdmin && this.isEditorMode) {
        if(this.filteredNameWorkflows.length) {
          this.filteredNameWorkflows.forEach(res => {
            if(res.published?.labels || res.draft?.labels) {
              this.filteredLabels.forEach(labelfilt => {
                if((res.published?.labels?.length || res.draft?.labels?.length) && (res.published?.labels.includes(labelfilt) || res.draft?.labels.includes(labelfilt)) && (this.filteredLabelWorkflows.length ? this.filteredLabelWorkflows.every(id => id.id !== res.id) : true)) {
                   this.filteredLabelWorkflows.push(res)
                }
              })
            }
          })
        }else{
          this.workflows.forEach(res => {
            if(res.published?.labels || res.draft?.labels) {
              this.filteredLabels.forEach(labelfilt => {
                if((res.published?.labels?.length || res.draft?.labels?.length) && (res.published?.labels.includes(labelfilt) || res.draft?.labels.includes(labelfilt)) && (this.filteredLabelWorkflows.length ? this.filteredLabelWorkflows.every(id => id.id !== res.id) : true)) {
                   this.filteredLabelWorkflows.push(res)
                }
              })
            }
          })
        }
        this.dataSource = new MatTableDataSource(this.filteredLabelWorkflows);
      }
    }else {
      this.dataSource = new MatTableDataSource(this.workflows)
    }
  }

  deleteWorkflow(workflow){
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-WORKFLOW')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
          this.loaderService.show()
          this.workflowService.deleteWorkflow(workflow.id).then(res => {
            this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.DELETE-SUCCESSFUL'))
            this.getWorkflowList()
         })
         .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.DELETE-ERROR')))
      }
    }))
  }
}
