import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-folder-info-modal',
  templateUrl: './folder-info-modal.component.html',
  styleUrls: ['./folder-info-modal.component.scss']
})
export class FolderInfoModalComponent implements OnInit {
  @Input() folder
  @Input() folders

  folderNames:string[] = []

  onClose: EventEmitter<any> = new EventEmitter();

  folderName: UntypedFormControl = new UntypedFormControl('', [Validators.required, this.unique()])
  uniqueError = false

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    if(this.folder) {
      this.folderName.patchValue(this.folder)
    }
    if(this.folders?.length) {
      this.folders.forEach(folder => {
        this.folderNames.push(folder.name)
      })
    }
  }

  close() {
    this.modalRef.hide()
  }

  save() {
    this.onClose.emit(this.folderName.value)
    this.modalRef.hide()
  }

  private unique(): ValidatorFn {
    return (FormControl: UntypedFormControl): ValidationErrors | null => {
      if(this.folderNames?.length && this.folderNames.includes(FormControl.value)) {
        return { uniqueError: true }
      } else {
        return null
      }
    }
  }

}
