<div class="session-dashboard" [ngStyle]="{'display': sessionDetailOpen ? 'none' : 'block'}">
    <div>
        <div class="filter-area mt-1 mb-4" style="padding-left: 20px;">
            <div class="row ps-3 align-items-center">
                <div class="col-3">
                    <input #filterDates="bsDaterangepicker" name="filterDates" [(ngModel)]="filterStartEnd" class="form-control datepicker-input" bsDaterangepicker
                        placeholder="{{ 'MAIN.SESSIONS.SESSION_LIST.START_END' | translate }}" [bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue' }" onkeypress="return false;">
                </div>
                <div class="col-3">
                    <ng-select [items]="rooms" name="rooms" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                        placeholder="{{ 'MAIN.SESSIONS.SESSION_LIST.ALL_ROOMS' | translate }}" [multiple]="true" [(ngModel)]="filterRooms">
                    </ng-select>
                </div>
                <div class="col-3">
                    <ng-select [items]="users" name="users" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                        placeholder="{{ 'MAIN.SESSIONS.SESSION_LIST.ALL_USERS' | translate }}" [multiple]="true" [(ngModel)]="filterUsers">
                    </ng-select>
                </div>
                <div class="col-3">
                    <button class="v-btn btn btn-primary vsight-button" (click)="applyFilters()">{{ 'MAIN.SESSIONS.SESSION_DETAILS.APPLY_FILTERS' | translate }}</button>
                </div>
            </div>
            <!-- <div class="row justify-content-end">
                <div class="col-12 text-right mt-2">
                    <button class="btn btn-info btn-sm" (click)="applyFilters()">{{ 'MAIN.SESSIONS.SESSION_DETAILS.APPLY_FILTERS' | translate }}</button>
                </div>
            </div> -->
        </div>
        <table mat-table [dataSource]="dataSource" matSort  matTableResponsive>
        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell data-column-name="#" *matCellDef="let i = index">
            
            </td>
        </ng-container>
        <ng-container matColumnDef="room">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by name"
                class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-3"
                style="padding-left: 20px;"
            >
            {{ 'MAIN.LOBBY.SESSIONS.ROOM_NAME' | translate }}
            </th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.ROOM_NAME' | translate" *matCellDef="let element"
            class="d-flex align-items-start fs-15 font-weight-700 justify-content-center flex-column col-3 cursor-pointer" style="padding-left: 20px;">{{ element.room_id | roomName | async }}</td>
        </ng-container>

        <ng-container matColumnDef="create_time">
            <th class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-2" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'MAIN.LOBBY.SESSIONS.DATE' | translate }} ({{ timezone }})
            </th>
            <td
            mat-cell
            data-column-name="Created On"
            *matCellDef="let element"
            class="col-2 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-light-black"
            >
            {{ element.create_time | localizedDateShort | async }}
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by duration"
                class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-1"
            >
            {{ 'MAIN.LOBBY.SESSIONS.DURATION' | translate }}
            </th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.DURATION' | translate" *matCellDef="let element"
            class="col-1 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-light-black">
                {{ element | sessionDuration }}
            </td>
            </ng-container>
        
        <ng-container matColumnDef="participants">
            <th mat-header-cell *matHeaderCellDef class="centered text-center table-fs-14 fs-14 text-dark-grey font-weight-400 col-2">{{ 'MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate }}</th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate" *matCellDef="let element" class="col-2 d-flex align-items-center justify-content-center flex-column fs-14 font-weight-400 text-dark-grey">
                <div *ngFor="let user of element.participants | slice: 0 : 3" class="participant">
                    <ng-container *ngIf="user">
                        <span *ngIf="user?.['name']">{{ user['name'] }}</span>
                    </ng-container>
                    
                    <span class="text-grey ms-1" *ngIf="user?.['isGuest']">({{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }})</span>
                </div>
                <span *ngIf="element.participants.length > 3" class="core-blue fs-14 font-weight-600 more" (click)="$event.stopPropagation(); showAllUsers(element.participants)">+ {{ element.participants.length - 3}} more</span>
            <!-- <div *ngFor="let guestUser of element.guest_users" class="participant">
                {{ guestUser.name }} <span class="text-grey ms-1">({{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }})</span>
            </div> -->
            </td>
        </ng-container>
        
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="centered text-center table-fs-14 fs-14 text-dark-grey font-weight-400 col-2">{{ 'MAIN.LOBBY.SESSIONS.STATUS' | translate }}</th>
            <td mat-cell [attr.data-column-name]="'MAIN.LOBBY.SESSIONS.STATUS' | translate" *matCellDef="let element"
            class="col-2 d-flex align-items-center justify-content-center flex-column fs-14 font-weight-400 text-dark-grey">
            <span [ngStyle]="{'color': !element.end_time ? 'green' : ''}">{{ (element.end_time ? 'MAIN.LOBBY.SESSIONS.ENDED' : 'MAIN.LOBBY.SESSIONS.ON_GOING') | translate }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="export">
            <th mat-header-cell *matHeaderCellDef class="centered table-fs-14 fs-14 text-dark-grey font-weight-400 col-2">
            <!-- {{ 'MAIN.LOBBY.SESSIONS.EXPORT' | translate }} -->
            </th>
            <td mat-cell *matCellDef="let element" class="col-2 fs-12 font-weight-400 d-flex justify-content-center align-items-center" style="padding-right: 40px;">
                <!-- <img *ngIf="!element.session_files_deleted" src="assets/export_session.svg" alt="" /> -->
                <button *ngIf="!element?.oldContinueWithQr" class="v-btn btn open-report-button cursor-pointer" (click)="onGetExport($event, element)">{{ "MAIN.LOBBY.SESSIONS.EXPORT" | translate }}</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <div>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSessionDetails(row)"></tr>
        </div>
        </table>
        <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>
        <div class="bg-white empty-list" *ngIf="dataSource && (!dataSource.filteredData || dataSource.filteredData.length === 0) && !isLoading">
        <div class="mt-4 fs-14 text-grey"> {{ "MAIN.LOBBY.SESSIONS.NO_SESSIONS" | translate }}</div>
    </div>
        <!-- <mat-paginator
        [pageSizeOptions]="[10, 5, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
        </mat-paginator> -->
    </div>
</div>
  
  <ng-template #loadingTemplate>
    <div style="background-color: white; padding: 50px;">
      <div class="d-flex justify-content-center">
        <div class="loader loader-sm la-dark">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>
  