import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom, fromEvent, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetailPageService {

  private container!: ViewContainerRef
  private queryParams: Params = null
  private popStateSub: Subscription = null
  private onRemoveDetail: Subject<void> = null

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  setDetailContainer(container: ViewContainerRef) {
    this.container = container
  }

  loadComponent<T>(component: Type<T>, params: Params = null): [T, Promise<void>] {
    this.container.clear()

    if (params) {
      this.queryParams = params
      this.router.navigate([], { relativeTo: this.route, queryParams: params, queryParamsHandling: 'merge', skipLocationChange: false });
    }
    this.popStateSub = fromEvent(window, 'popstate').subscribe(() => this.removeComponent())
    this.onRemoveDetail = new Subject<void>()
    return [this.container.createComponent(component).instance, firstValueFrom(this.onRemoveDetail.asObservable())]
  }

  removeComponent() {
    if (this.popStateSub) { this.popStateSub.unsubscribe() }
    if (this.queryParams) {
      Object.keys(this.queryParams).forEach(prop => { this.queryParams[prop] = null });
      this.router.navigate([], { relativeTo: this.route, queryParams: this.queryParams, queryParamsHandling: 'merge', skipLocationChange: false });
      this.queryParams = null
    }
    if (this.onRemoveDetail) {
      this.onRemoveDetail.next()
      this.onRemoveDetail.complete()
    }
    this.container.clear()
  }
}
