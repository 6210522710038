import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent implements OnInit {

  @Input('title') title: string
  @Input('bodyTitle') bodyTitle: string = null

  @Input('title') jsonTitle: string
  @Input('json') json: any

  @Input('title') json2Title: string
  @Input('json') json2: any

  @Input('showExpanded') showExpanded: boolean = true
  @Input('expanded') expanded: boolean = false
  @Input('raw') raw: boolean = false
  @Input('showRaw') showRaw: boolean = true

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
