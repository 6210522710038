import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, of, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import { WorkflowService } from './workflow.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  allUsernames: string[] = []

  passwordSub: Subscription = null;
  passwordRequirements = {
    minLength: 8,
    uppercase: false,
    lowercase: false,
    number: false,
    specialCharacter: false
  }

  constructor(
    private authService: AuthService,
    private workflowService: WorkflowService,
    private http: HttpClient
  ) {

    this.authService.getAccountData().then(ad => {
      const accountData = ad.data.accountData
      if (accountData) {
        const strongEnabled = accountData.password_policy ? accountData.password_policy.strong : false;
        if (strongEnabled) {
          const options = accountData.password_policy.options;

          this.passwordRequirements.minLength = options.length ? options.length : this.passwordRequirements.minLength;
          this.passwordRequirements.uppercase = options?.AZ;
          this.passwordRequirements.lowercase = options?.az;
          this.passwordRequirements.number = options?.number;
          this.passwordRequirements.specialCharacter = options?.special;
        }
      }
    })
   }

  getAllUsernames(): void {
    this.workflowService.getAllUsersForWorkflow()
    .then(users => {
      this.allUsernames = users.users.map(user => user.auth.username);
    })
  }
  
  checkUsernameUniqueness(value: string) {
    return of(this.allUsernames.some(username => username === value));
  }

  checkPasswordRequirements(value: string) {
    return of(
      (this.passwordRequirements.minLength > value.length) ||
      (this.passwordRequirements.uppercase && !RegExp('.*[A-Z].*').test(value)) ||
      (this.passwordRequirements.lowercase && !RegExp('.*[a-z].*').test(value)) ||
      (this.passwordRequirements.number && !RegExp('.*[0-9].*').test(value)) ||
      (this.passwordRequirements.specialCharacter && !RegExp('.*[-*/?#&()@%+\'!$^:,~_.½£₺é;{}ß>|æ].*').test(value))
    )
  }

  deleteUser(userId: string) {
    return this.http.post(environment.endPoints.deleteuser, { token: this.authService.currentUser.token, uid: userId }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.uid;
    })
  }

  createUser(newUser: User) {
    return this.http.post(environment.endPoints.createuser, { token: this.authService.currentUser.token,workflowUserIfHasLimit:true, user: newUser }).pipe(first()).toPromise()
    .then((result: any) => {
      return result;
    })
  }

  updateUser(changes: any) {
    return this.http.post(environment.endPoints.updateuser, { token: this.authService.currentUser.token, changes: changes }).pipe(first()).toPromise()
    .then((result) =>{
      return result

      // return (await new Promise<void>(resolve => {
      //   const sub = this.getAllUsers().pipe(
      //     timeout(5000),
      //     map(users => users ? users.find(user => user.id === changes.uid) : null)
      //   ).subscribe(user => {
      //     if (user) {
      //       // TODO refactor later for possible bugs
      //       if (Object.keys(changes.properties).every(p => p === 'username' ? changes.properties.username === user.auth.username : changes.properties[p] === user[p])) {
      //         if (sub) { sub.unsubscribe() }
      //         resolve();
      //       }
      //     }
      //   })
      // }))
    })
  }

  changeUserPassword(userId: string, username: string, password: string) {
    return this.http.post(environment.endPoints.changepsd,
      { token: this.authService.currentUser.token, uid: userId, username, password: password }).pipe(first()).toPromise()
    .then(result => {
      return;
    })
  }
  
  removeQR(username: string) {
    return this.http.post(environment.endPoints.generateremoveqr,
      { token: this.authService.currentUser.token, username: username, generate: false }).pipe(first()).toPromise()
    .then(result => {
      return;
    });
  }

  generateQr(username: string) {
    return this.http.post(environment.endPoints.generateremoveqr,
      { token: this.authService.currentUser.token, username: username, generate: true }).pipe(first()).toPromise()
    .then(result => {
      return;
    });
  }

  generateUserNameWithRole(user: any, licenseType: string) {
    // if (this.accountDataSub) { this.accountDataSub.unsubscribe() }

    // this.accountDataSub = this.accountService.accountData
    // .subscribe(accountData => {
    //   licenseType = accountData.license.type;
    // })

    
    const username = user.ad_user ? user.user_principal_name : user.auth.username;
    if (user.coadmin) {
      user.name2 = `${user.name} (${username} / Co-Admin)`;
      return user;
    }

    switch (user.role) {
      case "admin":
        user.name2 = `${user.name} (${username} / Admin)`;
        return user;
      case "coadmin":
        user.name2 = `${user.name} (${username} / Co-Admin)`;
        return user;
      case "expert":
        user.name2 = `${user.name} (${username} / Expert)`;
        return user;
      default:
        user.name2 = `${user.name} (${username} / ${licenseType === "concurrent_user_based" ? "User" : "Subscriber"})`;
        return user;
    }
  }
}