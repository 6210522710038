import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'success-message',
  template: `
  
  <div style="display:flex"><img src="assets/img/success.svg" class="me-2">{{data}}</div>
  `,
})
export class SuccessMessageComponent {
   constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string){

   }
}