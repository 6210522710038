import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'app-default-language-modal',
  templateUrl: './default-language-modal.component.html',
  styleUrls: ['./default-language-modal.component.scss']
})
export class DefaultLanguageModalComponent implements OnInit {

  onClose: EventEmitter<any> = new EventEmitter();

  @Input() headerTitle ?
  @Input() confirmButtonText ?
  @Input() workflowStatus ?
  @Input() isVideoFlow ?

  name = new UntypedFormControl('New Workflow', Validators.required)
  filterLang = new UntypedFormControl()

  allLangs = []
  defaultLanguage
  defaultLanguageName

  workflowName
  filteredLangs = []
  subscriptions : Subscription[] = []

  constructor(private workflowLangService: WorkflowLanguageService, public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.allLangs = this.workflowLangService.allLangs
    this.subscriptions.push(this.filterLang.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.filterLangs(res.toLowerCase());
      } else {
        this.filteredLangs = this.allLangs
      }
    }))
    this.filteredLangs = this.allLangs
    
    if(this.isVideoFlow) {
      this.selectDefaultLanguage({ code: 'en', name: 'English' })
    }
  }

  stop(event) {
    event.stopPropagation()
  }

  filterLangs(lang) {
    let filteredData = []
    filteredData = this.allLangs.filter(langData => (langData.name.toLowerCase()).includes(lang.toLowerCase()))
    this.filteredLangs = filteredData;
  }

  close() {
    this.modalRef.hide()
  }

  selectDefaultLanguage(lang) {
    this.defaultLanguage = lang.code
    this.defaultLanguageName = lang.name
  }

  saveDefaultLanguage() {
    this.workflowName = this.name.value
    this.onClose.emit(true)
  }

}
