import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { first, Subscription } from 'rxjs';
import { FileShareService } from 'src/app/services/support/file-share.service';
import { AlertModalComponent } from '../../../../../modals/alert-modal/alert-modal.component';
import { LoaderService } from 'src/app/services/support/loader.service';
import { ImageEditorComponent } from 'src/app/components/modals/image-editor/image-editor.component';
import { TranslateService } from "@ngx-translate/core";
import { WorkflowService } from '@services/workflow.service';
import { CollaborationService } from '@services/remote/collaboration.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { Widgets } from '@models/Widget';
import { FilerobotIimageEditorComponent } from 'src/app/components/modals/filerobot-iimage-editor/filerobot-iimage-editor.component';
import { environment } from 'src/environments/environment';
import { DbService } from '@services/db.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { base64ToFile } from 'ngx-image-cropper';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TableWidgets } from '@models/TableWidgets';
import { TableData } from './table-form-properties/table-form-properties.component';
import { WorkflowsModalComponent } from 'src/app/components/modals/workflows-modal/workflows-modal.component';
import { AuthService } from '@services/auth.service';
import { Formats, ToolOptions } from '@models/QuillConfig';

@Component({
  selector: 'app-widget-properties',
  templateUrl: './widget-properties.component.html',
  styleUrls: ['./widget-properties.component.scss']
})
export class WidgetPropertiesComponent implements OnInit, OnChanges, OnDestroy {

  @Input('widgetData') widgetData?: any
  @Input('layoutCount') layoutCount?: number
  @Input('workflowType') workflowType?: string
  @Input('selectedLayoutNo') selectedLayoutNo?: number
  @Input('workflowId') workflowId!: string
  @Input('stepId') stepId!: string
  @Input() deleteWidget: boolean
  @Input() step: any
  @Input() previewEventData: any
  @Input() previewRowEventData: any
  @Input() previewColumnEventData: any

  @Output() widgetDataChange = new EventEmitter<any>()
  @Output() widgetFormValidChange = new EventEmitter<boolean>()
  @Output() deleteWidgetChange = new EventEmitter<boolean>()
  @Output() invalidStep = new EventEmitter<string>()
  @Output() selectWorkflowToCopySteps = new EventEmitter<any>()
  @Output() deleteSubWorkflow = new EventEmitter<any>()
  @Output() viewStepFiles = new EventEmitter<any>()

  @Input() workflowTranslations
  @Output() workflowTranslationsChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() selected: any;

  @Output() connectionEnabledChanged: EventEmitter<boolean> = new EventEmitter();

  type: string
  key: string

  createMode
  widgets = Widgets

  widgetProps = {
    empty: {},
    label: { label: true, position: true },
    input: { label: true, placeholder: true, required: true },
    number: { label: true, placeholder: true, numberFields: true, required: true },
    datepicker: { label: true, placeholder: true, timepicker: true, required: true },
    multicheckbox: { label: true, options: true, required: true },
    radio: { label: true, options: true, required: true },
    image: { optionalLabel: true, imgUrl: true, imgName: true },
    gallery: { optionalLabel: true, images: true, galleryType: true },
    video: { optionalLabel: true, videoUrl: true, videoName: true },
    pdf: { optionalLabel: true, pdfUrl: true, pdfName: true, target: true },
    qr: { label: true, qrfield: true, qrValidation: true, required: true },
    captureImage: { label: true, required: true },
    captureVideo: { label: true, required: true },
    ppe: { optionalLabel: true, ppeFields: true, required: true },
    html: { html: true },
    table: { form: true, required: true, tableOptions: true },
    form: { formData: true },
    workflow: { workflow: true },
    id: { label: true, required: true, filterAvailable: true },
  }

  widgetForm: any = null
  tempUploadForm = new UntypedFormGroup({
    imgUrl: new UntypedFormControl(),
    imgName: new UntypedFormControl(),
  })
  widgetFormSub: Subscription = null
  subscriptions: Subscription[] = []

  lastColId
  collDataBeforeEdit
  selectedLang

  labelTranslationId
  placeholderTranslationId
  htmlTranslationId
  optionIds = []

  tableWidgetForm: any = null
  nestedWorkflowsAvailable = false
  quillConfig = {
    modules: {
      toolbar: ToolOptions
    },
    formats: Formats
  };

  constructor(
    private dbService: DbService,
    private fileShareService: FileShareService,
    private loaderService: LoaderService,
    private bsModalService: BsModalService,
    private translateService: TranslateService,
    public workflowService: WorkflowService,
    private collaborationService: CollaborationService,
    private snackBarService: SnackbarService,
    private workflowLanguageService: WorkflowLanguageService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {
    this.selectedLang = this.workflowLanguageService.currentLang.code
    this.workflowLanguageService.onLangChange.subscribe(res => {
      this.selectedLang = res.lang.code
    })
  }

  ngOnInit(): void {
    this.applyWidgetDataInput(this.widgetData)
    this.authService.getAccountData().then(account => {
      this.nestedWorkflowsAvailable = account.data.accountData.add_ons?.nestedworkflows
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widgetData']) {
      this.applyWidgetDataInput(changes['widgetData'].currentValue)
    }
    if (changes['deleteWidget'] && changes['deleteWidget'].currentValue) {
      let widget = { type: 'empty' }
      this.onWidgetSelect(widget)
    }
  }

  applyWidgetDataInput(widgetData) {
    if (widgetData && widgetData.type !== 'empty') {
      this.type = this.widgetData.type
      this.key = this.widgetData.key
      this.patchFormFields(this.widgetData)
    } else {
      this.widgetForm = null
      this.type = "empty"
      this.key = null
    }
  }

  ngOnDestroy(): void {
    if (this.widgetFormSub) {
      this.widgetFormSub.unsubscribe()
    }
  }

  disableScroll(event: MouseEvent) {
    (event.target as HTMLElement).blur();
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  onWidgetSelect(widget) {
    let show = false
    // If another widget selected
    if ((!this.type || this.type === 'empty')) {
      this.resetWidgetDataAndPatchFormFields(widget)

    } else if (this.type !== widget.type) {
      if (this.step.data.connections?.length > 0) {
        this.step.data.connections.forEach(connection => {
          if (connection.conditions?.length > 0) {
            connection.conditions.forEach(condition => {
              if (condition.type == this.step.data.layouts[this.selectedLayoutNo].items[0].key) {
                show = true
              }
            })
          }
        })
        if (show) {
          this.showConditionContainsWidgetModal()
        } else {
          this.showAlertModal(widget)
        }

      } else {
        this.showAlertModal(widget)
      }

    } else if (this.widgetData) {
      this.patchFormFields(this.widgetData)
    }

  }

  showAlertModal(widget?) {
    const initialState = {
      textMessage: this.translateService.instant(widget.type == 'empty' ? 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.DELETE-TEXT-MESSAGE' : 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TEXT-MESSAGE'),
      headerTitle: this.translateService.instant(widget.type == 'empty' ? 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TITLE-DELETE' : 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TITLE-CHANGE'),
    }
    let alertmodalRef = this.bsModalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
    const sub = alertmodalRef.content.onClose.subscribe((res) => {
      sub.unsubscribe()
      if (res) {
        this.resetWidgetDataAndPatchFormFields(widget)
        if (this.deleteWidget) {
          this.deleteWidgetChange.emit(false)
        }

      } else {
        if (this.deleteWidget) {
          this.deleteWidgetChange.emit(false)
        }
      }

    })
  }

  showConditionContainsWidgetModal() {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.CONNECTION-WARNING-TEXT-MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.CONNECTION-WARNING-HEADER'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
      confirmButtonStyle: 'primary',

    }
    let alertmodalRef = this.bsModalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
    const sub = alertmodalRef.content.onClose.subscribe((res) => {
      sub.unsubscribe()
      this.deleteWidgetChange.emit(false)
    })
  }

  resetWidgetDataAndPatchFormFields(widget) {
    this.type = widget.type
    this.key = this.dbService.createPushId()

    this.patchFormFields()
    this.onFormValueChanges(this.widgetForm.value)
    if (widget.type == 'empty') {
      this.widgetForm = null
    }
  }

  patchFormFields(data = null) {
    const formData: any = {}
    let optionTranslationId
    let optionKey


    optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
    optionKey = this.dbService.createPushId()

    let options: any[] = [{ value: optionKey, label: optionTranslationId }]
    let ppeOptions: any = {}
    if (this.type === 'ppe') {
      ppeOptions = { face: false, head: true, hand: false }
    }

    let labelTranslationId
    let placeholderTranslationId
    let htmlTranslationId

    if (!this.workflowTranslations[this.selectedLang]) {
      this.workflowTranslations[this.selectedLang] = {}
    }
    if (!data?.templateOptions) {
      labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
      placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
      htmlTranslationId = `$~#${this.dbService.createPushId()}$~#`

      this.workflowTranslations[this.selectedLang][labelTranslationId] = 'Title'
      this.workflowTranslations[this.selectedLang][placeholderTranslationId] = ''

      if (this.type == 'multicheckbox' || this.type == 'radio') {
        this.workflowTranslations[this.selectedLang][optionTranslationId] = 'Option'
      }
      if (this.type == 'html') {
        this.workflowTranslations[this.selectedLang][htmlTranslationId] = ''
      }
    }

    this.labelTranslationId = data?.templateOptions ? data?.templateOptions.label : labelTranslationId
    this.placeholderTranslationId = data?.templateOptions ? data?.templateOptions.placeholder : placeholderTranslationId
    this.htmlTranslationId = data?.templateOptions ? data?.templateOptions.html : htmlTranslationId



    const templateOptions = data?.templateOptions ? data.templateOptions : {
      label: labelTranslationId,
      placeholder: placeholderTranslationId,
      vPosition: 'center',
      hPosition: 'center',
      options: options,
      ppeOptions: ppeOptions,
      translationAvailable: this.workflowTranslations ? true : null,

      min: null,
      max: null,
      unit: '',
      precision: null,
      qrfield: '',
      qrValidation: false,
      timepicker: false,
      imgUrl: '',
      imgName: '',
      images: [],
      galleryType: 'grid',
      collId: '',
      videoUrl: '',
      videoName: '',
      pdfUrl: '',
      pdfName: '',
      required: false,
      html: htmlTranslationId
    }

    if (this.widgetProps[this.type].label) {
      formData.label = new UntypedFormControl(this.workflowTranslations[this.selectedLang][templateOptions.label], Validators.required)
    }
    if (this.widgetProps[this.type].html) {
      formData.html = new UntypedFormControl(this.workflowTranslations[this.selectedLang][templateOptions.html])

      // formData.html = new FormControl(templateOptions.html, Validators.required)
    }
    if (this.widgetProps[this.type].dataTable) {
      formData.columns = new UntypedFormControl(templateOptions.columns, Validators.required)
    }
    if (this.widgetProps[this.type].optionalLabel) {
      formData.optionalLabel = new UntypedFormControl(this.workflowTranslations[this.selectedLang][templateOptions.label])
    }
    if (this.widgetProps[this.type].placeholder) {
      formData.placeholder = new UntypedFormControl(this.workflowTranslations[this.selectedLang][templateOptions.placeholder])
    }
    if (this.widgetProps[this.type].position) {
      formData.vPosition = new UntypedFormControl(templateOptions.vPosition)
      formData.hPosition = new UntypedFormControl(templateOptions.hPosition)
    }
    if (this.widgetProps[this.type].options) {
      const optionList = []
      for (const option of templateOptions.options) {
        optionList.push(new UntypedFormGroup({
          deleteClicked: new UntypedFormControl(false),
          key: new UntypedFormControl(option.value),
          option: new UntypedFormControl(this.workflowTranslations[this.selectedLang][option.label], [Validators.required])
        }))
      }
      formData.options = new UntypedFormArray(optionList, [this.unique()])
    }
    if (this.widgetProps[this.type].numberFields) {
      formData.numberFields = new UntypedFormGroup({
        min: new UntypedFormControl(templateOptions.min),
        max: new UntypedFormControl(templateOptions.max),
        unit: new UntypedFormControl(templateOptions.unit),
        precision: new UntypedFormControl(templateOptions.precision)
      })
    }
    if (this.widgetProps[this.type].ppeFields) {
      let ppeField = Object.keys(templateOptions.ppeOptions).find(key => templateOptions.ppeOptions[key])
      formData.ppeFields = new UntypedFormControl(ppeField)
    }
    if (this.widgetProps[this.type].qrfield) {
      formData.qrfield = new UntypedFormControl(templateOptions.qrfield)
    }
    if (this.widgetProps[this.type].qrValidation) {
      formData.qrValidation = new UntypedFormControl(templateOptions.qrValidation)
      formData.qrValidation.valueChanges.subscribe(res => {
        if (formData.qrValidation.value) {
          formData.qrfield.setErrors({ required: true })
        } else {
          formData.qrfield.patchValue(null)
          formData.qrfield.setErrors(null)
        }
        this.onFormValueChanges(formData)
      })

    }
    if (this.widgetProps[this.type].imgUrl) {
      formData.imgUrl = new UntypedFormControl(templateOptions.imgUrl, Validators.required)
      formData.imgName = new UntypedFormControl(templateOptions.imgName)
      formData.colId = new UntypedFormControl(templateOptions.colId)
    }
    if (this.widgetProps[this.type].images) {
      formData.images = new UntypedFormControl(templateOptions.images)
    }
    if (this.widgetProps[this.type].galleryType) {
      formData.galleryType = new UntypedFormControl(templateOptions.galleryType)
    }
    if (this.widgetProps[this.type].videoUrl) {
      formData.videoUrl = new UntypedFormControl(templateOptions.videoUrl, Validators.required)
      formData.videoName = new UntypedFormControl(templateOptions.videoName)
    }
    if (this.widgetProps[this.type].pdfUrl) {
      formData.pdfUrl = new UntypedFormControl(templateOptions.pdfUrl, Validators.required)
      formData.pdfName = new UntypedFormControl(templateOptions.pdfName)
    }
    if (this.widgetProps[this.type].form) {
      formData.tableData = new UntypedFormControl(templateOptions.tableData, Validators.required)
    }
    if (this.widgetProps[this.type].timepicker) {
      formData.timepicker = new UntypedFormControl(templateOptions.timepicker, Validators.required)
    }
    if (this.widgetProps[this.type].required) {
      formData.required = new UntypedFormControl(templateOptions.required)
    }
    if (this.widgetProps[this.type].formData) {
      formData.formData = new UntypedFormControl(templateOptions.formData)
    }
    if (this.widgetProps[this.type].workflow) {
      formData.workflow = new UntypedFormControl(templateOptions.workflow, Validators.required)
    }

    this.widgetForm = new UntypedFormGroup(formData)
    this.widgetFormValidChange.emit(this.widgetForm.valid)


    if (this.widgetFormSub) { this.widgetFormSub.unsubscribe() }
    this.widgetFormSub = this.widgetForm.valueChanges.subscribe(formData => {
      this.onFormValueChanges(formData)
      this.widgetFormValidChange.emit(this.widgetForm.valid)
    })
  }

  private unique(): ValidatorFn {
    return (FormControl: UntypedFormControl): ValidationErrors | null => {
      let options = []
      FormControl.value?.forEach(res => {
        options.push(res.option?.toLowerCase())
      })
      let distinctOptions = new Set(options);
      if (distinctOptions.size !== options.length) {
        return { uniqueError: true }
      } else {
        return null
      }
    }
  }

  private imagesLength(): ValidatorFn {
    return (FormControl: UntypedFormControl): ValidationErrors | null => {
      if (!FormControl.value.length) {
        return { required: true }
      } else {
        return null
      }
    }
  }

  setWidgetDataWithFormData(formData: any) {
    const templateOptions: any = {}

    if (this.widgetProps[this.type].label) {
      this.workflowTranslations[this.selectedLang][this.labelTranslationId] = formData.label
      templateOptions.label = this.labelTranslationId
    }
    if (this.widgetProps[this.type].html) {
      let newHtml = `<!DOCTYPE html><head><meta charset="UTF-8"><meta name="viewport" content="width=1060, initial-scale=1.0"><style>* {font-family: Helvetica, Arial, sans-serif;-webkit-text-size-adjust: none;}p {color: black;font-family: Helvetica, Arial, sans-serif;} h1, h2, h3, h4, h5, h6 {}</style></head><body><div>${formData.html}</div></body></html>`
      this.workflowTranslations[this.selectedLang][this.htmlTranslationId] = newHtml
      templateOptions.html = this.htmlTranslationId
      // templateOptions.html = formData.html
    }
    if (this.widgetProps[this.type].optionalLabel) {
      this.workflowTranslations[this.selectedLang][this.labelTranslationId] = formData.optionalLabel
      templateOptions.label = this.labelTranslationId
    }
    if (this.widgetProps[this.type].placeholder) {
      this.workflowTranslations[this.selectedLang][this.placeholderTranslationId] = formData.placeholder
      templateOptions.placeholder = this.placeholderTranslationId
    }
    if (this.widgetProps[this.type].position) {
      templateOptions.vPosition = formData.vPosition
      templateOptions.hPosition = formData.hPosition
    }
    if (this.widgetProps[this.type].options) {
      let optionsArray = []
      formData.options.forEach(response => {
        let responseId = `$~#${response.key}$~#`
        this.workflowTranslations[this.selectedLang][responseId] = response.option
        optionsArray.push({ label: responseId, value: response.key })
      })
      templateOptions.options = optionsArray
      templateOptions['translationAvailable'] = this.workflowTranslations ? true : false
    }
    if (this.widgetProps[this.type].numberFields) {
      templateOptions.min = formData.numberFields.min
      templateOptions.max = formData.numberFields.max
      templateOptions.unit = formData.numberFields.unit
      templateOptions.precision = formData.numberFields.precision
    }
    if (this.widgetProps[this.type].ppeFields) {
      templateOptions['ppeOptions'] = {}
      templateOptions.ppeOptions['head'] = formData.ppeFields == 'head' ? true : false,
        templateOptions.ppeOptions['hand'] = formData.ppeFields == 'hand' ? true : false,
        templateOptions.ppeOptions['face'] = formData.ppeFields == 'face' ? true : false
    }
    if (this.widgetProps[this.type].qrfield) {
      templateOptions.qrfield = formData.qrfield
    }
    if (this.widgetProps[this.type].qrValidation) {
      templateOptions.qrValidation = formData.qrValidation
    }
    if (this.widgetProps[this.type].timepicker) {
      templateOptions.timepicker = formData.timepicker
    }
    if (this.widgetProps[this.type].imgUrl) {
      templateOptions.imgUrl = formData.imgUrl
      templateOptions.imgName = formData.imgName
      templateOptions.colId = formData.colId
    }
    if (this.widgetProps[this.type].images) {
      templateOptions.images = formData.images
      // { "imgUrl":"", "imgName":"", "imgThumbnailUrl":"" }
    }
    if (this.widgetProps[this.type].galleryType) {
      templateOptions.galleryType = formData.galleryType
      // { "imgUrl":"", "imgName":"", "imgThumbnailUrl":"" }
    }
    if (this.widgetProps[this.type].videoUrl) {
      templateOptions.videoUrl = formData.videoUrl
      templateOptions.videoName = formData.videoName
    }
    if (this.widgetProps[this.type].pdfUrl) {
      templateOptions.pdfUrl = formData.pdfUrl
      templateOptions.pdfName = formData.pdfName
    }
    if (this.widgetProps[this.type].dataTable) {
      templateOptions.columns = formData.columns
    }
    if (this.widgetProps[this.type].form) {
      templateOptions.tableData = formData.tableData
    }
    if (this.widgetProps[this.type].required) {
      templateOptions.required = formData.required
    }
    if (this.widgetProps[this.type].formData) {
      let formData = []
      let fields = []
      let fields2 = []
      let fields3 = []
      let fields4 = []


      let formLabels = ['Firma', 'Ansprechpartner', 'Straße', 'PLZ / Ort:', 'Telefon']
      let formLabels2 = ['Firma', 'Ansprechpartner', 'Straße', 'PLZ / Ort:', 'Telefon']
      let formLabels3 = ['Typ', 'Auftrags-Nr. / Serien-Nr.']
      let formLabels4 = ['Datum', 'Uhrzeit', 'Außentemperatur']


      formLabels.forEach(label => {
        let formDataObj = {
          key: this.dbService.createPushId(),
          type: 'input',
          templateOptions: {
            label: this.createTranslationKey(label)
          }
        }
        fields.push(formDataObj)
      })
      formData.push({ label: this.createTranslationKey('Auftraggeber'), fields: fields })

      formLabels2.forEach(label => {
        let formDataObj = {
          key: this.dbService.createPushId(),
          type: 'input',
          templateOptions: {
            label: this.createTranslationKey(label)
          }
        }
        fields2.push(formDataObj)
      })
      formData.push({ label: this.createTranslationKey('Einbauort'), fields: fields2 })

      formLabels3.forEach(label => {
        let formDataObj = {
          key: this.dbService.createPushId(),
          type: 'input',
          templateOptions: {
            label: this.createTranslationKey(label)
          }
        }
        if (label == 'Auftrags-Nr. / Serien-Nr.') {
          formDataObj.templateOptions['useForFilter'] = true
        }
        fields3.push(formDataObj)
      })
      formData.push({ label: this.createTranslationKey('Station'), fields: fields3 })

      formLabels4.forEach(label => {
        let formDataObj = {
          key: this.dbService.createPushId(),
          type: 'input',
          templateOptions: {
            label: this.createTranslationKey(label)
          }
        }
        fields4.push(formDataObj)
      })
      formData.push({ label: this.createTranslationKey('Termin'), fields: fields4 })

      templateOptions.formData = formData
    }
    if (this.widgetProps[this.type].workflow) {
      templateOptions.workflow = formData.workflow
    }
    if (this.widgetProps[this.type].filterAvailable) {
      templateOptions['useForFilter'] = true
    }

    this.widgetData.type = this.type
    this.widgetData.key = this.key
    this.widgetData.templateOptions = templateOptions
  }

  createTranslationKey(label) {
    let translationKey = `$~#${this.dbService.createPushId()}$~#`
    this.workflowTranslations[this.selectedLang][translationKey] = label
    return translationKey
  }

  onFormValueChanges(formData: any) {
    this.setWidgetDataWithFormData(formData)
    this.widgetDataChange.emit(this.widgetData)
  }

  back(): void {
    this.widgetForm = null
    this.createMode = false
  }

  dragDropUpload(type: "image" | "video" | "pdf", event: any, control: UntypedFormControl, nameControl: UntypedFormControl): void {
    if (!environment.electron) {
      let selectedFile = event[0]
      this.previewUpload(type, selectedFile, control, nameControl)
    }
  }

  dragDropUploadGallery(type, event, control) {
    let selectedFiles = event
    this.previewUploadGallery(type, selectedFiles, control)
  }

  chooseButtonUpload(type: "image" | "video" | "pdf", event: any, control: AbstractControl, nameControl: AbstractControl): void {
    let selectedFile = event.target.files[0]
    this.previewUpload(type, selectedFile, control, nameControl)
  }

  uploadGallery(type: "image" | "video" | "pdf", event: any, control: AbstractControl, nameControl: AbstractControl): void {
    let selectedFiles = event.target.files
    this.previewUploadGallery(type, selectedFiles, control)
  }

  chooseAndCopyWorkflowFile(type: 'image' | 'video' | 'pdf', control: AbstractControl, nameControl: AbstractControl) {
    this.loaderService.show()
    this.dbService.chooseAndCopyWorkflowFile(this.workflowId, type)
      .then(result => {
        if (result) {
          nameControl.patchValue(result[0])
          control.patchValue(result[1])
        }
      })
      .finally(() => this.loaderService.hide())
  }

  chooseFileAndGetPath(type: 'image' | 'video' | 'pdf', control: AbstractControl, nameControl: AbstractControl) {
    this.loaderService.show()
    this.dbService.chooseFileAndGetPath(type)
      .then(result => {
        if (result) {
          this.openFileRobot2(result[0], result[1], control, nameControl)
        }
      })
      .finally(() => this.loaderService.hide())
  }

  openFileRobot2(filename: string, url: string, control: AbstractControl, nameControl: AbstractControl) {
    let modal = this.bsModalService.show(FilerobotIimageEditorComponent, {
      initialState: {
        imageUrl: url,
        imageName: filename
      },
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      animated: false
    })

    modal.content.onSave.subscribe(res => {
      if (res) {
        this.loaderService.show()
        this.fileShareService.convertFileToBase64(modal.content.file)
          .then(base64File => this.dbService.saveWorkflowFile(base64File, filename, this.workflowId))
          .then(result => {
            if (result) {
              nameControl.patchValue(result[0])
              control.patchValue(result[1])
            }
          })
          .catch(err => console.log(err))
          .finally(() => this.loaderService.hide())
      }
    })

    let close
    modal.content.onClose.subscribe(res => {
      modal.content.closeClicked.subscribe(rest => {
        if (rest) {
          close = true
        }
      })
      if (res) {
        modal.hide()
      } else {
        if (!close) {
          this.openFileRobot2(filename, url, control, nameControl)
        } else {
          modal.hide()
        }
      }
    })
  }

  private previewUpload(type: "image" | "video" | "pdf", selectedFile: File, control: AbstractControl, nameControl: AbstractControl): void {
    if (type === "image") {
      if (!environment.defaultEditor) {
        this.showFileRobotEditor(selectedFile, control, nameControl)
      } else {
        this.showFileRobotEditor(selectedFile, control, nameControl)
      }
    } else {
      this.loaderService.show()
      this.fileShareService.uploadWorfklowFile(selectedFile, this.workflowId, this.stepId, this.dbService.createPushId())
        .then(url => {
          nameControl.patchValue(selectedFile.name)
          control.patchValue(url)
        })
        .finally(() => this.loaderService.hide())
    }
  }

  private previewUploadGallery(type: "image" | "video" | "pdf", selectedFiles: File[], control: AbstractControl): void {
    let images = []
    images = control.value
    if (images.length + selectedFiles.length > 50) {
      const initialState = {
        textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LIMIT-EXCEED'),
        confirmButtonStyle: 'danger',
        confirmButtonText: this.translateService.instant(`MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE-OK`)
      }
      let alertmodalRef = this.bsModalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
      const sub = alertmodalRef.content.onClose.subscribe((res) => {
        if (res) {
          alertmodalRef.hide()
          return
        }
      })
    } else {
      this.loaderService.show()
      this.createAndUploadGalleryObject(selectedFiles, images)
    }
    this.widgetForm.controls.images.patchValue(images)
  }

  resizeThumbnail(img, selectedFile) {
    let base64file

    const w = Math.round(img.naturalHeight > img.naturalWidth ? 64 * (img.naturalWidth / img.naturalHeight) : 64);
    const h = Math.round(img.naturalWidth > img.naturalHeight ? 64 * (img.naturalHeight / img.naturalWidth) : 64);

    const cnv: HTMLCanvasElement = document.createElement("canvas");
    const maxWidth = 300;
    const maxHeight = 300;
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }
    cnv.width = width;
    cnv.height = height;
    const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    base64file = cnv.toDataURL("image/png", 0.9);
    let files = base64ToFile(base64file)
    let file = new File([files], selectedFile.name)
    return file
  }

  createAndUploadGalleryObject(selectedFiles, images) {
    Object.keys(selectedFiles).forEach(key => {
      const img: HTMLImageElement = new Image();
      img.src = URL.createObjectURL(selectedFiles[key]);

      img.onload = () => {

        const file = this.resizeThumbnail(img, selectedFiles[key])

        this.fileShareService.uploadWorfklowFile(selectedFiles[key], this.workflowId, this.stepId, this.dbService.createPushId())
          .then(url => {
            return images.push({ imgUrl: url, imgName: selectedFiles[key].name, thumbnailUrl: '' })
          })
          .then(res => {
            this.fileShareService.uploadWorfklowFile(file, this.workflowId, this.stepId, this.dbService.createPushId())
              .then(url => {
                images[res - 1].thumbnailUrl = url
              })
          })
          .catch(err => console.log(err))
          .finally(() => this.loaderService.hide())
      }
    })
  }

  editImage() {
    if (!this.widgetForm.controls.colId?.value) {
      this.widgetForm.controls.colId.value = this.dbService.createPushId()
    }
    if (!environment.defaultEditor) {
      this.openFileRobot('edit')
    } else {
      this.openFileRobot('edit')
      // this.collaborationService.getCurrentCollaboration(this.widgetForm.controls.colId.value).pipe(first()).subscribe(res => {
      //   this.openImageEditorWithColl(res,'edit')
      //   this.collDataBeforeEdit = res
      // })
    }
  }

  deleteGalleryImage(control, image, index) {
    let images = []
    images = control.value
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.GALLERY.DELETE-IMAGE-TEXT-MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.GALLERY.DELETE-IMAGE'),
      confirmButtonStyle: 'danger',
    }
    let alertmodalRef = this.bsModalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
    const sub = alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        images.splice(index, 1)
        control.patchValue(images)
      }
    })
  }

  editGalleryImage(control, image, index) {
    this.galleryOpenFileRobot(control, image, index)
  }

  galleryOpenFileRobot(control?, image?, index?) {
    let modal = this.bsModalService.show(FilerobotIimageEditorComponent, {
      initialState: {
        imageUrl: image.imgUrl,
        imageName: image.imgName
      },
      backdrop: 'static',
      class: 'modal-lg image-modal-lg modal-dialog-centered',
      animated: false
    })
    let close

    modal.content.onSave.subscribe(res => {
      if (res) {
        this.loaderService.show()
        const img: HTMLImageElement = new Image();
        img.src = URL.createObjectURL(modal.content.file);

        img.onload = () => {
          const file = this.resizeThumbnail(img, modal.content.file)

          this.fileShareService.uploadWorfklowFile(modal.content.file, this.workflowId, this.stepId, this.dbService.createPushId())
            .then(url => {
              let images = []
              images = control.value
              images[index].imgUrl = url
              this.widgetForm.controls.images.patchValue(images)
              return images
            })
            .then(res => {
              this.fileShareService.uploadWorfklowFile(file, this.workflowId, this.stepId, this.dbService.createPushId())
                .then(urlForThumbnail => {
                  res[index].thumbnailUrl = urlForThumbnail
                  this.widgetForm.controls.images.patchValue(res)
                })
            })
            .catch(err => console.log(err))
            .finally(() => this.loaderService.hide())
        }
      }
    })
    modal.content.onClose.subscribe(res => {
      modal.content.closeClicked.subscribe(rest => {
        if (rest) {
          close = true
        }
      })
      if (res) {
        modal.hide()
      } else {
        if (!close) {
          this.galleryOpenFileRobot('', control, index)
        } else {
          modal.hide()
        }
      }
    })
  }

  showFileRobotEditor(file, control, nameControl) {
    this.loaderService.show()
    this.fileShareService.uploadWorfklowFile(file, this.workflowId, this.stepId, this.dbService.createPushId())
      .then(url => {
        nameControl.patchValue(file.name)
        control.patchValue(url)
        this.openFileRobot('', control, nameControl)
      })
      .finally(() => this.loaderService.hide())
  }

  openFileRobot(status?, control?, nameControl?) {
    let modal = this.bsModalService.show(FilerobotIimageEditorComponent, {
      initialState: {
        imageUrl: status ? this.widgetForm.controls.imgUrl.value : control.value,
        imageName: status ? this.widgetForm.controls.imgName.value : nameControl.value
      },
      backdrop: 'static',
      class: 'modal-lg image-modal-lg modal-dialog-centered',
      animated: false
    })

    let _control = status ? this.widgetForm.controls.imgUrl : control
    let _nameControl = status ? this.widgetForm.controls.imgName : nameControl
    let close



    modal.content.onSave.subscribe(res => {
      if (res) {
        this.loaderService.show()
        this.fileShareService.uploadWorfklowFile(modal.content.file, this.workflowId, this.stepId, this.dbService.createPushId())
          .then(url => {
            _nameControl.patchValue(modal.content.file.name)
            _control.patchValue(url)
            this.widgetForm.controls.imgUrl.patchValue(_control.value)
            this.widgetForm.controls.imgName.patchValue(nameControl?.value ? nameControl?.value : _nameControl.value)
          })
          .finally(() => this.loaderService.hide())
      }
    })
    modal.content.onClose.subscribe(res => {
      modal.content.closeClicked.subscribe(rest => {
        if (rest) {
          close = true
        }
      })
      if (res) {
        modal.hide()
      } else {
        if (!close) {
          if (status == 'edit') {
            this.openFileRobot('edit')

          } else {
            this.openFileRobot('', control, nameControl)
          }
        } else {
          modal.hide()
        }
      }
    })
  }

  openImageEditorWithColl(collData, status?, control?, nameControl?) {
    this.collaborationService.setCurrentCollaboration(collData, this.widgetForm.controls.colId.value)
    const modalRef = this.bsModalService.show(ImageEditorComponent, {
      initialState: {
        imageUrl: this.widgetForm.controls.imgUrl.value,
        name: this.widgetForm.value.imgName,
        collData: collData,
        currentCollaborationId: this.widgetForm.controls.colId.value,
        workflowId: this.workflowId,
        stepId: this.stepId,
        status: status
      },
      backdrop: 'static',
      class: 'modal-lg image-modal-lg modal-dialog-centered',
      animated: false
    })

    const _control = status == 'edit' ? this.widgetForm.controls.imgUrl : control
    const _nameControl = status == 'edit' ? this.widgetForm.controls.imgName : nameControl

    this.subscriptions.push(modalRef.content.imageCroppedCancel.subscribe(croppedFile => {
      if (croppedFile && status == 'edit') {
        this.loaderService.show()
        this.fileShareService.uploadWorfklowFile(croppedFile, this.workflowId, this.stepId, this.dbService.createPushId())
          .then(url => {
            _nameControl.patchValue(croppedFile.name)
            _control.patchValue(url)
            const currentCollaborationId = this.dbService.createPushId()
            this.widgetForm.controls.colId.value = currentCollaborationId
            const coll = this.collaborationService.createCollaboration(currentCollaborationId, _nameControl.value, _control.value)
            this.collaborationService.setCurrentCollaboration(coll, this.widgetForm.controls.colId.value)
            modalRef.content.replaceCollaboration(_control.value, _nameControl.value, coll, currentCollaborationId)
          })
          .finally(() => this.loaderService.hide())
      } else {
        this.widgetForm.controls.imgUrl.reset();
        this.widgetForm.controls.imgName.reset();
        this.lastColId = null;
        this.tempUploadForm.reset();
      }
    }))

    this.subscriptions.push(modalRef.content.imageCropped.subscribe(croppedFile => {
      if (croppedFile) {
        this.loaderService.show()
        this.fileShareService.uploadWorfklowFile(croppedFile, this.workflowId, this.stepId, this.dbService.createPushId())
          .then(url => {
            _nameControl.patchValue(croppedFile.name)
            _control.patchValue(url)
            const currentCollaborationId = this.dbService.createPushId()
            this.widgetForm.controls.colId.value = currentCollaborationId
            const coll = this.collaborationService.createCollaboration(currentCollaborationId, _nameControl.value, _control.value)
            this.collaborationService.setCurrentCollaboration(coll, this.widgetForm.controls.colId.value)
            modalRef.content.replaceCollaboration(_control.value, _nameControl.value, coll, currentCollaborationId)
          })
          .finally(() => this.loaderService.hide())
      }

    }))
    this.subscriptions.push(modalRef.content.onClose.subscribe(isSave => {
      if (isSave) {
        this.lastColId = this.widgetForm.controls.colId.value
        modalRef.content.createJpegFromCollaboration().then(editedImageFile => {
          this.loaderService.show()
          this.fileShareService.uploadWorfklowFile(editedImageFile, this.workflowId, this.stepId, this.dbService.createPushId())
            .then(url => {
              this.widgetForm.controls.imgName.patchValue(editedImageFile.name)
              this.widgetForm.controls.imgUrl.patchValue(url)
              modalRef.hide()
            })
            .catch(() => this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR')))
            .finally(() => this.loaderService.hide())
        })
          .catch((err) => {
            this.snackBarService.error(this.translateService.instant('ERRORS.ERROR'))
          })
      } else {
        if (this.widgetForm.controls.colId.value) {
          if (this.collDataBeforeEdit) {
            this.collaborationService.setCurrentCollaboration(this.collDataBeforeEdit, this.widgetForm.controls.colId.value)
          }
          const isChangeImageCancel = !!this.tempUploadForm.value.imgUrl;
          if (status !== 'edit' && !isChangeImageCancel) {
            this.widgetForm.controls.imgUrl.reset();
            this.widgetForm.controls.imgName.reset();
          }
          this.lastColId = null;
          this.tempUploadForm.reset();
        }
      }
    }))
  }

  showImageEditor(selectedFile: File, control: AbstractControl, nameControl: AbstractControl) {
    this.loaderService.show()
    this.fileShareService.uploadWorfklowFile(selectedFile, this.workflowId, this.stepId, this.dbService.createPushId())
      .then(url => {
        nameControl.patchValue(selectedFile.name)
        control.patchValue(url)
        const currentCollaborationId = this.dbService.createPushId()
        this.widgetForm.controls.colId.value = currentCollaborationId
        const coll = this.collaborationService.createCollaboration(currentCollaborationId, nameControl.value, control.value)
        this.openImageEditorWithColl(coll, '', control, nameControl)
      })
      .finally(() => this.loaderService.hide())
  }

  createOption(options: UntypedFormArray) {
    this.createMode = true
    if (options.length > 0) {
      (options.controls[0] as UntypedFormGroup).controls['deleteClicked'].setValue(false)
    }
    let length = options.value.length
    let optionLabel = 'Option ' + length
    options.push(new UntypedFormGroup({
      deleteClicked: new UntypedFormControl(false),
      key: new UntypedFormControl(this.dbService.createPushId()),
      option: new UntypedFormControl(optionLabel, [Validators.required])
    }))
  }

  deleteOption(options: UntypedFormArray, index: number): void {
    if (options.length > 1) {
      options.removeAt(index)
    } else {
      (options.controls[index] as UntypedFormGroup).controls['deleteClicked'].setValue(true)
    }
  }

  drop(event: CdkDragDrop<string[]>, images?): void {
    let imgs = images
    moveItemInArray(imgs, event.previousIndex, event.currentIndex);
    this.widgetForm.controls['images'].patchValue(imgs)
  }

  onUpdateTableWidget(tableData: TableData) {
    this.widgetForm.controls['tableData'].patchValue(tableData)
  }

  selectWorkflowToConnect(val?) {
    const initialState = {
      action: 'connectWorkflow',
      workflowTranslations: this.workflowTranslations,
      workflowId: this.workflowId,
      title: 'Select Workflow'
      // workflow: this.workflow
    }

    let wfListModalRef = this.modalService.show(WorkflowsModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false
    })

    wfListModalRef.content.onWorkflowSelectToConnect.subscribe(res => {
      this.widgetForm.controls.workflow.valueChanges.subscribe(wf => {
      })
      if (val) {
        this.deleteSubWorkflow.emit(val.workflowId)
      }
      this.selectWorkflowToCopySteps.emit(res)
      this.widgetForm.controls.workflow.patchValue(res)
      wfListModalRef.hide()
    })
    wfListModalRef.content.workflowConnectionStatusChanged.subscribe(res => {
      if (res) {
        this.connectionEnabledChanged.emit(true)
      }
    })
  }

  showStepFiles() {
    this.viewStepFiles.emit(true)
  }

  onWidgetFormSubmit(widgetForm) { }
}
