<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center justify-content-between" style="padding-inline: 22px;">
            <label class="modal-title fs-18">{{ 'MODALS.IMAGE-EDITOR.TITLE' | translate }}</label>
            <div (click)="cancel()" style="cursor: pointer;">
                <img src="assets/img/close_white.svg" alt="">
            </div>
        </div>

        <div class="modal-body d-flex flex-column align-items-center p-2">
            <div class="frame-box-container">
                <div class="frame-box border border-dark" *ngIf="frame" [addNativeElement]="frame">
                    <app-image-collaboration #imageCollaboration *ngIf="!onCrop" [onCrop]="onCrop" (crop)="crop($event)" [frame]="frame" [size]="'big'" [external]="false"></app-image-collaboration>
                    <app-image-cropper *ngIf="onCrop" (crop)="crop($event)" [frame]="frame" [name]="name" [(cropClickedFromEditor)]="cropClickedFromEditor" (cropClickedFromEditorChange)="cropClickedFromEditorChange($event)" (cropChanged)="cropChanged($event)" (beforeCropChanged)="beforeCropChanged($event)"></app-image-cropper>
                </div>
            </div>
        </div>

        <div class="card-footer modal-card-footer">
            <button type="button" class="v-btn btn btn-sm btn-outline-secondary" style="font-size: 0.875rem;" (click)="cancel()">
                {{ 'MODALS.IMAGE-EDITOR.CANCEL' | translate }}
            </button>
            <button type="button" class="v-btn btn btn-sm btn-primary" style="font-size: 0.875rem;" (click)="confirm()" *ngIf="!onCrop">
                {{ 'MODALS.IMAGE-EDITOR.SAVE' | translate }}
            </button>
            <button type="button" class="v-btn btn btn-sm btn-primary" style="font-size: 0.875rem;" (click)="cropClick()"  *ngIf="onCrop">
                {{ 'MODALS.IMAGE-EDITOR.CROP' | translate }}
            </button>
        </div>

    </div>
</div>