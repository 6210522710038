import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from '@angular/material/sort';
import { UserSession } from '@models/Session';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { SessionDetailsComponent } from '../sessions/session-details/session-details.component';
import { DetailPageService } from '@services/detail-page.service';
import { SessionDurationPipe } from 'src/app/pipes/session-duration.pipe';
import { AuthService } from '@services/auth.service';
import { SessionService } from '@services/remote/session.service';
import { LoaderService } from '@services/support/loader.service';
import { Session, SessionUser } from 'src/app/models/Session';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserListModalComponent } from 'src/app/components/modals/user-list-modal/user-list-modal.component';

type SessionParticipant = SessionUser & { isGuest?: boolean };
interface SessionUI extends Session {
  participants?: SessionParticipant[];
}


@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.scss']
})
export class UserSessionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  @ViewChild(MatPaginator, { static: false })
  set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
    setTimeout(() => {
        this.pageIndex = this.dataSource.paginator.pageIndex
        this.pageSize = this.dataSource.paginator.pageSize
    });
  }
  dataSource = new MatTableDataSource<SessionUI>();
  displayedColumns: string[] = [
    "index",
    "room.name",
    "create_time",
    "duration",
    "participants",
    "status"
  ];
  isUserExpert: boolean = false;
  sessions: SessionUI[];
  isLoading: boolean = true;
  timezone = "UTC"
  pageIndex: number;
  pageSize: number;
  subscriptions: Subscription[] = []

  webdavEnabled: boolean = false;
  sessionExportEnabled: boolean = false;
  sessionDetailOpen = false

  constructor(
    private authService: AuthService,
    private durationPipe: SessionDurationPipe,
    // private sessionExportService: SessionExportService,
    // private roomSessionService: RoomSessionService,
    private detailPageService: DetailPageService,
    private sessionService: SessionService,
    private loaderService: LoaderService,
    private modalService: BsModalService
  ) {}

  ngAfterViewInit(): void {}
  ngOnInit(): void {
    this.loaderService.show()
    // this.subscriptions.push(this.authService.timezone.pipe(
    //   distinctUntilChanged()
    // ).subscribe(res=>{
    //    this.timezone = res;
    // }))
    this.sessionService
      .getUserSessions()
      .then((res) => {
        this.isLoading = false;
        this.sessions = res.sessions.sort((a, b) => a.create_time > b.create_time ? -1 : 1) as SessionUI[];
        this.sessions.forEach(session => {
          let participants = []
          
          if(session.users) {
            participants = session.users
          }

          if(session['guest_users']) {
            
            participants = [...participants, ...session['guest_users'].map((item) => ({...item, isGuest: true}))]
          }
          session['participants'] = participants
        })
        this.dataSource = new MatTableDataSource(this.sessions);
        // this.dataSource.sortingDataAccessor = (item: UserSession, property) => {
        //   switch (property) {
        //     case "room.name":
        //       return item.room.name;
        //     case "duration":
        //       return this.durationPipe.transform(item);
        //     default:
        //       return item[property];
        //   }
        // };
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      })
      .catch((error) => {
        this.isLoading = false;
        // if (error.message === "permission-denied") {
        //   this.flashMessageService.showTranslated(
        //     "APP.EXTERNALS.AD_ERROR.PERMISSION_DENIED"
        //   );
        // }
        // if (error.message === "internal-error") {
        //   this.flashMessageService.showTranslated(
        //     "APP.EXTERNALS.AD_ERROR.INTERNAL_ERROR"
        //   );
        // }
      })
      .finally(() => this.loaderService.hide())

      this.authService.getAccountData().then(account => {
        this.sessionExportEnabled = account.data.accountData.add_ons.sessionexport;
        this.webdavEnabled = account.data.accountData.add_ons.webdav;
        this.subscriptions.push(this.authService.user.subscribe((userData) => {
          this.isUserExpert = userData && userData.role === "expert";
          if (this.isUserExpert && !this.displayedColumns.includes("export") && this.sessionExportEnabled && !this.webdavEnabled) {
            this.displayedColumns.push("export");
          }
        }));
      })
      // this.subscriptions.push(this.authService.addOns.subscribe(addOns => {
      //   this.sessionExportEnabled = addOns.sessionexport;
      //   this.webdavEnabled = addOns.webdav;
      //   this.subscriptions.push(this.authService.user.subscribe((userData) => {
      //     this.isUserExpert = userData && userData.role === "expert";
      //     if (this.isUserExpert && !this.displayedColumns.includes("export") && this.sessionExportEnabled && !this.webdavEnabled) {
      //       this.displayedColumns.push("export");
      //     }
      //   }));
      // }));
    
  }

  // onSessionExportModal(roomId: string, sessionId: string, event): void {
  //   event.stopPropagation();
  //   this.sessionExportService.sessionExportRequest.next([
  //     roomId,
  //     sessionId,
  //     true,
  //   ]);
  // }

  onSessionDetails(session: UserSession) {
    this.sessionDetailOpen = true
    const [instance, onClose] = this.detailPageService.loadComponent(SessionDetailsComponent);
    instance.session = session;
    instance.userSessions = true;
    onClose.then(() => {
      this.sessionDetailOpen = false;
    })
  }

  showAllUsers(users) {
    const initialState= {
      userList: users
    };
    let modalRef = this.modalService.show(UserListModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered v-modal-sm',
      animated: false
    });

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
