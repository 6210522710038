<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{headerTitle ? headerTitle : ('MAIN.CONTENT.DASHBOARD.CREATE' | translate) | titlecase }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <div class="form-group me-2" style="width:100%" *ngIf="workflowStatus !== 'update'">
                <label class="fs-14 text-light-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.NAME.TITLE' | translate }} <span class="label-info fs-14">*</span></label>
                <!-- <p class="fs-12 mb-1 mt-2">Fill in the Label field</p> -->
                <input [inputSelectOnCreate]="true" autofocus type="text" class="form-control mt-2 detail fs-14" [formControl]="name" inputSelectOnFocus>
                <p class="validation-text" *ngIf="name.errors?.['required']">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.NAME.NO-NAME' | translate }}</p>
            </div>

            <div class="d-flex flex-column mt-3" style="width:100% !important">
                <label class="fs-14 font-weight-600 mb-2">{{ 'MODALS.DEFAULT-LANGUAGE.DEFAULT-LANGUAGE' | translate }}</label>
                <app-dropdown title="{{ defaultLanguageName ? defaultLanguageName : 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SELECT-LANGUAGE' | translate }}" width="80%" [isVideoFlow]="isVideoFlow">
                    <div class="search-box">
                        <input type="text" #searchInput class="form-control filter fs-14" [formControl]="filterLang" (click)="stop($event)" placeholder="Search">
                        <div class="search-icon"> <img src="assets/img/search-copy.svg" alt=""></div>
                    </div>
                    <div *ngFor="let lang of filteredLangs" (click)="selectDefaultLanguage(lang)" class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer">
                        {{lang.name}}
                    </div>
                </app-dropdown> 
            </div>
            
            <div class="mt-3">
                    <img src="assets/img/info_icon.svg" alt="">
                    <span class="text-light-grey fs-12">{{ 'MODALS.DEFAULT-LANGUAGE.PRIMARY-LANGUAGE' | translate }}</span>
            </div>

            <div class="d-flex top-gap-30 justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary" (click)="close()">
                    {{ 'MAIN.CONTENT.DASHBOARD.CANCEL' | translate | titlecase }}
                </button>
                <button type="button" class="v-btn btn vsight-button btn-primary create-button" (click)="saveDefaultLanguage()" [disabled]="!name.valid || !defaultLanguage">
                    {{confirmButtonText ? confirmButtonText : 'MODALS.DEFAULT-LANGUAGE.CREATE' | translate | titlecase }}
                </button>
            </div>

        </div>
    </div>
</div>