import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DbService } from '@services/db.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { debounceTime, Subscription } from 'rxjs';
import { TableCell } from '../table-form-properties/table-form-properties.component';

@Component({
  selector: 'app-widget-properties-form',
  templateUrl: './widget-properties-form.component.html',
  styleUrls: ['./widget-properties-form.component.scss']
})
export class WidgetPropertiesFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: TableCell;
  @Input() workflowTranslations

  @Output() updated: EventEmitter<any> = new EventEmitter()
  @Output() workflowTranslationsChange: EventEmitter<string> = new EventEmitter<any>();

  allProperties = {
    label: { label: true, position: true },
    input: { placeholder: true },
    number: { placeholder: true, numberFields: true },
    datepicker: { placeholder: true, timepicker: true },
    multicheckbox: { options: true },
    radio: { options: true },
    empty: { empty: true }

  }

  properties;

  form = new UntypedFormGroup({
    label: new UntypedFormControl('', Validators.required),
    timepicker: new UntypedFormControl(),
    required: new UntypedFormControl(false),
    numberFields: new UntypedFormGroup({
      min: new UntypedFormControl(),
      max: new UntypedFormControl(),
      unit: new UntypedFormControl(),
      precision: new UntypedFormControl()
    }),
    position: new UntypedFormGroup({
      vPosition: new UntypedFormControl(),
      hPosition: new UntypedFormControl()
    }),
    placeholder: new UntypedFormControl(),
    options: new UntypedFormArray([], [this.unique()]),
    empty: new UntypedFormControl(true),
  })

  selectedLang: string
  translationIds: {
    label?: string,
    placeholder?: string,
    options?: string,
  } = {};

  subscriptions: Subscription[] = [];

  constructor(private dbService: DbService, private workflowLanguageService: WorkflowLanguageService) {
    this.selectedLang = this.workflowLanguageService.currentLang.code
    this.subscriptions.push(this.workflowLanguageService.onLangChange.subscribe(res => {
      this.selectedLang = res.lang.code
    }))
  }

  get templateOptions(): any {
    return this.data?.templateOptions;
  }

  get label(): UntypedFormControl {
    return this.form.get('label') as UntypedFormControl;
  }

  get options(): UntypedFormArray {
    return this.form.get('options') as UntypedFormArray;
  }

  get optionsFormGroups(): UntypedFormGroup[] {
    return this.options.controls as UntypedFormGroup[];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) =>  s?.unsubscribe())
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.previousValue?.key !== changes['data'].currentValue?.key) {
      this.properties = this.allProperties[this.data.type]
      this.initializeTranslationIds();
      this.setFormValues()
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.form.valueChanges.pipe(debounceTime(400)).subscribe(() => {
      this.emitFormChanges()
    }))
  }

  initializeTranslationIds() {
    this.translationIds.label = this.templateOptions.label || `$~#${this.dbService.createPushId()}$~#`
    this.translationIds.placeholder = this.templateOptions.placeholder || `$~#${this.dbService.createPushId()}$~#`
    this.translationIds.options = this.templateOptions.options || `$~#${this.dbService.createPushId()}$~#`
  }

  setFormValues() {
    Object.keys(this.properties).forEach((key) => {
      switch (key) {
        case 'label': {
          if (!this.templateOptions.label) {
            this.workflowTranslations[this.selectedLang][this.translationIds.label] = ''
          }
          this.form.get(key).setValue(this.workflowTranslations[this.selectedLang][this.translationIds.label], { emitEvent: false })
          break;
        }
        case 'placeholder': {
          if (!this.templateOptions.placeholder) {
            this.workflowTranslations[this.selectedLang][this.translationIds.placeholder] = ''
          }
          this.form.get(key).setValue(this.workflowTranslations[this.selectedLang][this.translationIds.placeholder], { emitEvent: false })
          break;
        }
        case 'timepicker': {
          this.form.get(key).setValue(this.templateOptions.timepicker || '', { emitEvent: false })
          break;
        }
        case 'required': {
          this.form.get(key).setValue(this.templateOptions.required || false, { emitEvent: false })
          break;
        }
        case 'options': {
          this.options.clear({ emitEvent: false });

          if (this.templateOptions.options?.length > 0) {
            this.templateOptions.options.forEach((option) => {
              const optionForm = new UntypedFormGroup({
                deleteClicked: new UntypedFormControl(false),
                key: new UntypedFormControl(option.value),
                option: new UntypedFormControl(this.workflowTranslations[this.selectedLang][option.label], [Validators.required])
              })
              this.options.push(optionForm, { emitEvent: false })
            })
          } else {
            this.workflowTranslations[this.selectedLang][this.translationIds.options] = 'Option'
            const option = new UntypedFormGroup({
              deleteClicked: new UntypedFormControl(false),
              key: new UntypedFormControl(this.dbService.createPushId()),
              option: new UntypedFormControl(this.workflowTranslations[this.selectedLang][this.translationIds.options], [Validators.required])
            })
            this.options.push(option, { emitEvent: false })
          }
          break;
        }
        case 'numberFields': {
          const value = {
            min: this.templateOptions.min || null,
            max: this.templateOptions.max || null,
            unit: this.templateOptions.unit || '',
            precision: this.templateOptions.precision || null
          }
          this.form.get(key).setValue(value, { emitEvent: false })
          break;
        }
        case 'position': {
          const value = {
            vPosition: this.templateOptions.vPosition || '',
            hPosition: this.templateOptions.hPosition || '',
          }
          this.form.get(key).setValue(value, { emitEvent: false })
          break;
        }
        case 'empty': {
          this.form.get(key).setValue(true, { emitEvent: false })
          break;
        }
      }
    })
    this.emitFormChanges()
  }

  emitFormChanges() {
    const formValue = this.form.value;
    const isValid = Object.keys(this.properties).every((key) => this.form.controls[key].valid)
    if (isValid) {
      // Collect only related form controls which is belong to this widget
      const widgetPropertyValues = {};

      Object.keys(this.properties).forEach((key) => {
        if (key == 'options') {
          let options = []
          formValue[key].forEach(item => {
            let translationId = `$~#${item.key}$~#`
            this.workflowTranslations[this.selectedLang][translationId] = item.option
            options.push({ label: translationId, value: item.key })
          })
          widgetPropertyValues[key] = options
        } else if (key == 'label' || key === 'placeholder') {
          const translationId = this.translationIds[key];
          widgetPropertyValues[key] = translationId
          this.workflowTranslations[this.selectedLang][translationId] = formValue[key]
          this.workflowTranslationsChange.emit(this.workflowTranslations)
        }else if (key == 'numberFields') {
          widgetPropertyValues['min'] = formValue[key]?.['min'] ? formValue[key]['min'] : null
          widgetPropertyValues['max'] = formValue[key]?.['max'] ? formValue[key]['max'] : null
          widgetPropertyValues['precision'] = formValue[key]?.['precision'] ? formValue[key]['precision'] : null
          widgetPropertyValues['unit'] = formValue[key]?.['unit'] ? formValue[key]['unit'] : ''

        }
        else if (key == 'timepicker') {
          widgetPropertyValues['timepicker'] = formValue[key] ? formValue[key] : null
        }
      });
      this.updated.emit(widgetPropertyValues)
    }
  }

  addOption(): void {
    const option = new UntypedFormGroup({
      deleteClicked: new UntypedFormControl(false),
      key: new UntypedFormControl(this.dbService.createPushId()),
      option: new UntypedFormControl('Option', [Validators.required])
    });
    this.options.push(option, { emitEvent: false })
    this.form.patchValue(this.form.value)

  }

  deleteOption(index: number): void {
    if (this.options.length > 1) {
      this.options.removeAt(index)
    } else {
      (this.options.controls[index] as UntypedFormGroup).controls['deleteClicked'].setValue(true)
    }
  }


  preventDefaults(event: MouseEvent) {
    (event.target as HTMLElement).blur();
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  private unique(): ValidatorFn {
    return (FormControl: UntypedFormControl): ValidationErrors | null => {
      let options = []
      FormControl.value?.forEach(res => {
        options.push(res.option?.toLowerCase())
      })
      let distinctOptions = new Set(options);
      if (distinctOptions.size !== options.length) {
        return { uniqueError: true }
      } else {
        return null
      }
    }
  }
}
