import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Frame } from 'src/app/models/Frame';
import { ImageEditorComponent } from 'src/app/components/modals/image-editor/image-editor.component';
import {base64ToFile} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit,OnChanges {
  @Input('frame') frame: Frame
  @Input() name;
  @Input() cropClickedFromEditor

  @Output() crop = new EventEmitter<boolean>()
  @Output() cropChanged = new EventEmitter<any>()
  file

  @Output() beforeCropChanged = new EventEmitter<any>()
  @Output() cropClickedFromEditorChange = new EventEmitter<any>()


  showBlackOverlay: boolean = true

  croppedImage: any = '';
  imageFile: File;
  firstFile: File

  constructor(private imageEditorComponent: ImageEditorComponent) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['cropClickedFromEditor']){
      this.cropClicked()
    }
  }

  ngOnInit(): void {
    this.imageEditorComponent.createJpegFromCollaboration().then(editedImageFile =>{
      this.imageFile = editedImageFile
      this.firstFile = editedImageFile
      this.beforeCropChanged.emit(this.firstFile)
    })
    
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = base64ToFile(event.base64)
    this.file = new File([this.croppedImage], this.name)
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
    this.showBlackOverlay = false
  }

  loadImageFailed() {
    // show message
  }

  cropClicked(){
    this.cropChanged.emit(this.file)
    this.cropClickedFromEditorChange.emit(false)
  }

}
