<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{headerTitle ? headerTitle : ('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.LICENCE-WARNING'| translate) }}</div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            
            <div style="top: 27px;left: 31px;">
                    <img src="assets/img/warning.svg" alt="">
                </div>

            <div *ngIf="!errorMessage" [innerHTML]="textMessage" class="fs-14 top-gap-8 ng-star-inserted ps-4 pe-4 text-center"></div>
        </div>
        <div class="d-flex mb-5 mt-2 justify-content-around" style="padding-inline: 45px;">
        
        </div>
    </div>
</div>