import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LoaderService } from '@services/support/loader.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ImageCollaborationComponent } from '../../main/content/remote/image-collaboration/image-collaboration.component';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit, OnChanges {
  @ViewChild(ImageCollaborationComponent, { static: false }) private imageCollaboration: ImageCollaborationComponent
  @Input('imageFile') imageFile?: File
  @Input('imageUrl') imageUrl
  @Input('name') name
  @Input('collData') collData
  @Input('currentCollaborationId') currentCollaborationId

  @Input() workflowId;
  @Input() stepId;
  @Input() status

  imageCropped: EventEmitter<any> = new EventEmitter();
  imageCroppedCancel: EventEmitter<any> = new EventEmitter();

  onClose: EventEmitter<any> = new EventEmitter();
  onCrop: boolean = false;
  cropClickedFromEditor = false

  frame: any = null
  subscriptions: Subscription[] = []
  cropAvailable = false
  beforeChange:File

  constructor(
    public modalRef: BsModalRef,
    public loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    if (this.imageUrl) {
      this.loadImageFile()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const imgInfo = changes['imageUrl']?.currentValue || changes['name']?.currentValue;
    if (imgInfo) {
      this.loadImageFile();
    } else {
      this.frame = null;
    }
  }

  loadImageFile():void {
    this.frame = { type: this.collData.collaboration_data.type, isCollaboration: true, collaborationData: this.collData.collaboration_data}
  }

  createJpegFromCollaboration() {
    return this.imageCollaboration.createJpegFromCollaboration()
  }

  cropChanged(event) {
    this.imageCropped.emit(event)
  }

  cropClick() {
    this.cropClickedFromEditor= true
  }
  
  cropClickedFromEditorChange(event) {
    this.cropClickedFromEditor= false

  }

  replaceCollaboration(imageUrl, name, collData, currentCollaborationId) {
    this.cropAvailable = true
    this.onCrop = false
    this.imageUrl = imageUrl
    this.name = name
    this.collData = collData
    this.currentCollaborationId = currentCollaborationId

    this.frame = null;

    setTimeout(() => {
      this.frame = { type: this.collData.collaboration_data.type, isCollaboration: true, collaborationData: this.collData.collaboration_data}
    }, 250);
  }

  cancel(): void {
    if(this.cropAvailable){
      this.imageCroppedCancel.emit(this.beforeChange)
      this.modalRef.hide();
      this.onClose.next(false);
    }else{
      this.onClose.next(false);
      this.modalRef.hide();
    }
  }

  beforeCropChanged(event){
    this.beforeChange = event
  }

  confirm(): void {
    this.onClose.next(true);
  }

  crop(value: boolean){
    this.onCrop = value;
  }
}
