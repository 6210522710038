import { FormlyFieldCustomCaptureImage } from "./field-templates/capture-image.component";
import { FormlyFieldCustomCaptureVideo } from "./field-templates/capture-video.component";
import { FormlyFieldCustomCheckbox } from "./field-templates/checkbox.component";
import { FormlyFieldCustomDate } from "./field-templates/date.component";
import { FormlyFieldCustomEmpty } from "./field-templates/empty.component";
import { FormlyFieldCustomEwersForm } from "./field-templates/ewers-form.component";
import { FormlyFieldCustomGallery } from "./field-templates/gallery.component";
import { FormlyFieldCustomID } from "./field-templates/id.component";
import { FormlyFieldCustomImage } from "./field-templates/image.component";
import { FormlyFieldCustomInput } from "./field-templates/input.component";
import { FormlyFieldCustomLabel } from "./field-templates/label.component";
import { FormlyFieldCustomNumber } from "./field-templates/number.component";
import { FormlyFieldCustomPdf } from "./field-templates/pdf.component";
import { FormlyFieldCustomPpe } from "./field-templates/ppe.component";
import { FormlyFieldCustomQR } from "./field-templates/qr.component";
import { FormlyFieldCustomRadio } from "./field-templates/radio.component";
import { FormlyFieldCustomRichText } from "./field-templates/rich-text.component";
import { FormlyFieldCustomVideo } from "./field-templates/video.component";
import { FormlyFieldCustomVoiceRecord } from "./field-templates/voice-record.component";
import { FormlyFieldCustomVoice } from "./field-templates/voice.component";
import { FormlyFieldCustomWorkflow } from "./field-templates/workflow.component";

export const CustomTypes = {
    types: [
        { name: 'image', component: FormlyFieldCustomImage, wrappers: ['form-field'] },
        {name: 'label', component: FormlyFieldCustomLabel },
        {name: 'datepicker', component: FormlyFieldCustomDate },
        {name: 'video', component: FormlyFieldCustomVideo},
        {name: 'qr', component: FormlyFieldCustomQR},
        {name: 'captureImage', component: FormlyFieldCustomCaptureImage},
        {name: 'captureVideo', component: FormlyFieldCustomCaptureVideo},
        {name: 'radio', component: FormlyFieldCustomRadio},
        {name: 'multicheckbox', component: FormlyFieldCustomCheckbox},
        {name: 'empty', component: FormlyFieldCustomEmpty},
        {name: 'number', component: FormlyFieldCustomNumber},
        {name: 'voicerecord', component: FormlyFieldCustomVoiceRecord},
        {name: 'voice', component: FormlyFieldCustomVoice},
        {name: 'ppe', component: FormlyFieldCustomPpe},
        {name: 'input', component: FormlyFieldCustomInput},
        {name: 'pdf', component: FormlyFieldCustomPdf},
        {name: 'html', component: FormlyFieldCustomRichText },
        {name: 'gallery', component: FormlyFieldCustomGallery },
        {name: 'form', component: FormlyFieldCustomEwersForm },
        {name: 'workflow', component: FormlyFieldCustomWorkflow },
        {name: 'id', component: FormlyFieldCustomID },
      ]
}
