

<div class="table-form-properties">
    
    <form *ngIf="isCreateFormVisible" [formGroup]="createForm">
        <div class="d-flex align-items-end">
            <div>
                <label class="me-2 fs-16 font-weight-600 mb-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.COLUMN' | translate }}</label>
                <input type="number" class="form-control me-2 mt-0 fs-14" formControlName="columns" inputSelectOnFocus>
            </div>
            <div>
                <label class="me-2 fs-16 font-weight-600 mb-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.ROW' | translate }}</label>
                <input type="number" class="form-control mt-0 fs-14" formControlName="rows" inputSelectOnFocus>
            </div>
            
            <button class="v-btn btn btn-primary vsight-button create-button ms-2" [disabled]="createForm.invalid" (click)="createTable()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.CREATE-TABLE' | translate }}</button>
        </div>
        <p class="validation-text" *ngIf="createForm.controls['columns']?.errors?.['min']">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.COLUMN-VALIDATION-TEXT' | translate }}</p>
        <p class="validation-text" *ngIf="createForm.controls['rows']?.errors?.['min']">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.ROW-VALIDATION-TEXT' | translate }}</p>

    </form>
    <ng-container *ngIf="isTableAvailable">
        <div class="btn-toolbar" role="toolbar">
            <a (click)="addRow()" class="d-flex align-items-center add-button col-5 fs-14 font-weight-600">
                <img src="assets/img/add-circle-blue.svg" class="normal-icon">
                <img src="assets/img/add-circle-blue-hover.svg" class="hover-icon">
                <span class="ms-1 me-3">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.ADD-ROW' | translate }}</span>
            </a>
            
            <a (click)="deleteRow()" class="d-flex align-items-center add-button fs-14 font-weight-600" [ngClass]="{'a-disabled': data?.rows.length == 1}">
                <img src="assets/img/remove-circle-blue.svg" alt="" class="normal-icon">
                <img src="assets/img/remove-circle-blue-hover.svg" alt="" class="hover-icon">
                <span class="ms-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.DELETE-ROW' | translate }}</span>
            </a>
        </div>
        <div class="btn-toolbar top-gap-12" role="toolbar">
            <a (click)="addColumn()" class="d-flex align-items-center add-button col-5 fs-14 font-weight-600">
                <img src="assets/img/add-circle-blue.svg" class="normal-icon">
                <img src="assets/img/add-circle-blue-hover.svg" class="hover-icon">
                <span class="ms-1 me-3">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.ADD-COLUMN' | translate }}</span>
            </a>
            
            <a (click)="deleteColumn()" class="d-flex align-items-center add-button fs-14 font-weight-600" [ngClass]="{'a-disabled': data.columns[0].cells.length == 1}">
                <img src="assets/img/remove-circle-blue.svg" alt="" class="normal-icon">
                <img src="assets/img/remove-circle-blue-hover.svg" alt="" class="hover-icon">
                <span class="ms-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.DELETE-COLUMN' | translate }}</span>
            </a>
        </div>

        <div *ngIf="data.columns[0].cells.length >= 7 || data.rows.length >= 5" class="inform-text"><img src="assets/img/info.svg" alt=""> {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.MAX-ADDED' | translate }}</div>

        <div class="table-form-properties-container">
            <div *ngIf="!selectedCell" class="info-container">
                <img src="assets/img/info_icon_blue.svg">
                <label class="label-span fs-12">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.SELECT-CELL-TEXT' | translate }}</label>
            </div>
            <p class="selected-cell mb-2" *ngIf="selectedCell">
                <label class="fs-16 me-2 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.SELECTED' | translate }}</label>
                <span class="fs-16">
                    {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.ROW' | translate }} {{selectedCell.row + 1}} {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.COLUMN' | translate }} {{selectedCell.column + 1}}
                </span>
            </p>
        
            <div class="cell-widget-selection" *ngIf="selectedCell">
    
                <app-dropdown title="{{ tableWidgets[selectedCell.data.type]?.name ||  ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.SELECT-CONTENT-TYPE-TEXT' | translate ) }}">
                    <a *ngFor="let widget of tableWidgets | objectToArray: 'type' " (click)="changeWidgetType(widget,selectedCell)" class="type-properties cursor-pointer fs-14">
                        {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.' + widget.name  | translate }}
                    </a>
                </app-dropdown> 
        
                <app-widget-properties-form 
                    *ngIf="selectedCell" 
                    [data]="selectedCell?.data"
                    (updated)="updateSelectedCellTemplateOptions($event)"
                    [workflowTranslations]="workflowTranslations" 
                    (workflowTranslationsChange)="workflowTranslationsChange.emit($event)">
                </app-widget-properties-form>
    
                <button *ngIf="selectedCell && selectedCell?.data.type !=='empty' && !selectedCell.isHeader" class="v-btn btn btn-primary mt-3 vsight-button create-button fs-14 mb-4"
                 (click)="changeWidgetType({type:'empty',name:'Empty'},selectedCell, true )">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.CLEAR' | translate }}</button>
            </div>

        </div>

    </ng-container>
</div>



<ng-template #createFormTemplate>
    <a (click)="isCreateFormVisible = true" class="add-button core-blue fs-12">
        <img src="assets/img/plus-circle.svg">
        <span class="ms-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.CREATE-TABLE' | translate }}</span>
    </a>
    
    <form *ngIf="isCreateFormVisible" class="d-flex align-items-center" [formGroup]="createForm">
        <span class="me-2 fs-12 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.COLUMN' | translate }}</span>
        <input type="number" class="form-control me-2 mt-0" formControlName="columns" inputSelectOnFocus>
        <span class="me-2 fs-12 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TABLE.ROW' | translate }}</span>
        <input type="number" class="form-control mt-0" formControlName="rows" inputSelectOnFocus>
        <!-- <button class="btn btn-primary fs-12 font-weight-600 text-white ms-2" [disabled]="createForm.invalid" (click)="createTable()">Create</button> -->
    </form>
</ng-template>