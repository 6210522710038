import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TutorialController } from '@models/TutorialController';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

    tutorial: Observable<{[tag: string]: true}>;
    private tutorialSource: BehaviorSubject<{[tag: string]: true}> = new BehaviorSubject<{[tag: string]: true}>(null);

    currentController: TutorialController = null;

    constructor(
        private dbService: DbService,
        private authService: AuthService
    ) {
        this.tutorial = this.tutorialSource.asObservable();
    }

    startTutorial(tags: {[tag: string]: true}): Promise<boolean> {
        this.currentController = new TutorialController();

        const promise = new Promise<boolean>((resolve) => {
            this.currentController._controller.asObservable()
            .subscribe(
                t => this.tutorialSource.next(t),
                err => this.tutorialSource.next(null),
                () => {
                    this.tutorialSource.next(null);
                    resolve(this.currentController.isSkipped());
                }
            );
            this.currentController.next(tags);
        });
        return promise;
    }

    isTutorialCompleted(name: string) {
        return this.authService.currentUser.tutorials && this.authService.currentUser.tutorials[name]
    }

    saveCompletedTutorial(name: string) {
        this.dbService.set(`accounts/${this.authService.currentUser.account_id}/users/${this.authService.currentUser.id}/tutorials/${name}`, true)
    }
}