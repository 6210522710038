import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { UserService } from "@services/user.service";
import { map, Observable } from "rxjs";

export class UniqueUsername {
  static createValidator(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return userService.checkUsernameUniqueness(control.value).pipe(
        map((result: boolean) => result ? { uniqueUsername: true } : null)
        );
    };
  }
}