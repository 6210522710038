
<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header">
            <div class="modal-title">{{ title }}</div>
            <div>
                <button (click)="expanded = !expanded" *ngIf="!raw && showExpanded" class="v-btn btn btn-sm me-2" [ngClass]="{ 'btn-secondary': expanded, 'btn-outline-secondary': !expanded }" style="box-shadow: none; border: none;">Expand</button>
                <button *ngIf="showRaw" (click)="raw = !raw;" class="v-btn btn btn-sm" [ngClass]="{ 'btn-secondary': raw, 'btn-outline-secondary': !raw }" style="box-shadow: none; border: none;">Raw</button>
            </div>
        </div>

        <div class="modal-body">
            <div *ngIf="bodyTitle" class="fs-16 mb-3">{{ bodyTitle }}</div>

            <div *ngIf="jsonTitle" class="fs-14 mb-1">{{ jsonTitle }}</div>
            <div class="viewer-wrapper mb-3" *ngIf="json">
                <pre *ngIf="raw">{{ json | json }}</pre>
                <ngx-json-viewer *ngIf="!raw" [json]="json" [expanded]="expanded"></ngx-json-viewer>
            </div>
            <div *ngIf="json2Title" class="fs-14 mb-1">{{ json2Title }}</div>
            <div class="viewer-wrapper" *ngIf="json2">
                <pre *ngIf="raw">{{ json2 | json }}</pre>
                <ngx-json-viewer *ngIf="!raw" [json]="json2" [expanded]="expanded"></ngx-json-viewer>
            </div>
        </div>

        <div class="card-footer modal-card-footer">
            <button class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary" (click)="modalRef.hide()">Close</button>
        </div>
    </div>
</div>