import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-id',
  template: `
    <ng-container>
      <div class="d-flex fs-15 font-weight-600 mb-2" style="height: 90%;" *ngIf="to['detail']">{{to.label}}</div>
      <div class="d-flex align-items-center justify-content-center">
        <input type="text"  class="form-control custom-form-control" id="form-control"
        autocomplete="off">
      </div>
    </ng-container>
  `,
})
export class FormlyFieldCustomID extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}