import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox-widget',
  templateUrl: './checkbox-widget.component.html',
  styleUrls: ['./checkbox-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxWidgetComponent),
      multi: true,
    },
  ],
})
export class CheckboxWidgetComponent implements ControlValueAccessor,OnChanges, OnInit, OnDestroy {

  @Input() item //type tanımla

  subscriptions : Subscription[] = []

  checkboxControl = new UntypedFormControl()
  checkboxGroup = null

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    const optionList = []
    for (const option of this.item.templateOptions.options) {
      optionList.push(new UntypedFormGroup({
        value: new UntypedFormControl(false, this.item.templateOptions.required ? Validators.required : null),
        option: new UntypedFormControl(option.label),
        key: new UntypedFormControl(option.value)
      }))
    }
    this.checkboxGroup = new UntypedFormArray(optionList, this.item.templateOptions.required ? this.multicheckboxValidator() : null)

    this.subscriptions.push(this.checkboxGroup.valueChanges.subscribe((res) => this.onChange(res)))
  }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.checkboxGroup.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.checkboxGroup.valid ? null : this.checkboxGroup.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    let valid
    
  }

  private multicheckboxValidator(): ValidatorFn {
    return (formArray:UntypedFormArray): ValidationErrors |  null => {
      const isValid = formArray.controls.some((formGroup: UntypedFormGroup) => formGroup.controls['value'].value)
      if (isValid) {
        return null
      } else {
        return { required: true }
      }
    }
  }

}
