<div class="report-wrapper" *ngIf="wfReportData" #pdfTable id="pdfTable">
    <div class="report-container" [ngClass]="{'report-container-desktop': !isMobile}">
        <ng-container *ngIf="!isWhitelabel" [ngTemplateOutlet]="reportHeaderVsight"></ng-container>
        <ng-container *ngIf="isWhitelabel" [ngTemplateOutlet]="reportHeaderEwers"></ng-container>
        <div class="d-flex justify-content-end align-items-center button-group-report" *ngIf="!('electron' | env) && !isMobile">

            <app-dropdown [imageExist]="true" [width]="dropdownWidth" title="{{selectedLangName ? selectedLangName : 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SELECT-LANGUAGE' | translate}}" style="width: 200px" class="me-2">
                <label class="fs-12 ps-3 text-grey font-weight-600" style="font-size:10px !important">{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.ADDED' | translate }}</label>
                <div *ngFor="let lang of workflowLangs" (click)="changeCurrentLang(lang)">
                    <div *ngIf="lang.code" class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer">
                        <div class="d-flex justify-content-between lang-line">
                            <div>
                                <img src="assets/img/check_icon.svg" alt="" *ngIf="selectedLang == lang.code" style="margin-left:-22px;">
                                {{lang.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </app-dropdown> 

            <div class="v-btn btn-primary vsight-button create-button btn me-2" (click)="download()" *ngIf="!isMobile">
                {{ 'MAIN.CONTENT.REPORT.EXPORT' | translate }}
            </div>
            <div class="v-btn btn-primary vsight-button create-button btn" style="margin-right: 50px;" (click)="downloadPDF()" *ngIf="!isMobile">
                {{ 'MAIN.CONTENT.REPORT.SAVE-PDF' | translate }}
            </div>
        </div>
        <div *ngIf="initialized" class="card mt-3" [ngStyle]="{'border' : isMobile ? 'none' : '', 'margin': isMobile ? '0px' : '50px'}">
            <div class="d-flex flex-wrap" [ngClass]="{'scr': !isMobile,'ms-5 me-5':isMobile,'m-100':!isMobile}">
                <div class="col-12 d-flex">
                    <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.NAME' | translate }}</div>
                    <div class="col-8 fs-14" *ngIf="!workflowPublishedVersion?.translations && workflowPublishedVersion?.name">: {{workflowPublishedVersion.name}}</div>
                    <div class="col-8 fs-14" *ngIf="workflowPublishedVersion?.translations">: {{workflowPublishedVersion?.translations[selectedLang][workflowPublishedVersion.name]}}</div>

                </div>
                <div class="col-12 d-flex">
                    <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.DESCRIPTION' | translate }}</div>
                    <div class="col-8 fs-14" *ngIf="workflowPublishedVersion?.description && !workflowPublishedVersion?.translations">: {{workflowPublishedVersion.description}}</div>
                    <div class="col-8 fs-14" *ngIf="workflowPublishedVersion?.translations && workflowPublishedVersion?.description">: {{workflowPublishedVersion?.translations[selectedLang][workflowPublishedVersion.description]}}</div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.STEPS-COMPLETED' | translate }}</div>
                    <div class="col-8 fs-14">: {{wfReportData.results.length}}</div>
                </div>

                <div class="col-12 d-flex" *ngIf="!wfReportData.continueTimes || !wfReportData.continueTimes?.length">
                    <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.START' | translate }}</div>
                    <div class="col-8 fs-14">: {{wfReportData.startTime | localizedDate | async}}</div>
                </div>

                <ng-container *ngIf="wfReportData.continueTimes && wfReportData.continueTimes?.length">
                    <div class="col-12 d-flex">
                        <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.PREFILLED-AT' | translate }}</div>
                        <div class="col-8 fs-14">: {{wfReportData.startTime | localizedDate | async}}</div>
                    </div>
                    <div class="col-12 d-flex">
                        <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.QR-SCANNED-AT' | translate }}</div>
                        <div class="col-8 fs-14">: {{wfReportData.continueTimes[0].continueTime | localizedDate | async}}</div>
                    </div>
                </ng-container>
                
                <div class="col-12 d-flex">
                    <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.END' | translate }}</div>
                    <div class="col-8 fs-14">: {{wfReportData.endTime | localizedDate | async}}</div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.DURATION' | translate }}</div>
                    <div class="col-8 fs-14">: {{reportDuration}}</div>
                </div>

                <ng-container *ngIf="wfReportData.continueTimes && wfReportData.continueTimes?.length">
                    <div class="col-12 d-flex">
                        <div class="col-4 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.SUBMITTED-BY' | translate }}</div>
                        <div class="col-8 fs-14" *ngIf="wfReportData.userId && !wfReportData.guest?.email">: {{reportUser ? reportUser : getUsersName(wfReportData.userId) }}</div>
                        <div class="col-8 fs-14" *ngIf="wfReportData.guest?.email">: {{wfReportData.guest.email }}</div>
                    </div>
                </ng-container>

            </div>

            <div *ngIf="!isMobile" class="d-flex deneme" [ngStyle]="{'margin': isMobile ? '0px' : '100px'}"  [ngClass]="{'mb-0':workflowPublishedVersion.detailExtraFields?.length>0}">
                <div class="col-6">
                    <div class="d-flex" *ngIf="('showSourceBtn' | env) && !isMobile">
                        <a class="source-icon" (click)="showJsonSource($event, wfReportData)"><i class="mi mi-code" style="font-size: 20px"></i></a>
                    </div>
                    <div class="d-flex">
                        <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.NAME' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.name && !workflowPublishedVersion?.translations">: {{workflowPublishedVersion.name}}
                        </div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.name && workflowPublishedVersion?.translations">: {{workflowPublishedVersion?.translations[selectedLang][workflowPublishedVersion.name]}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.DESCRIPTION' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.description && !workflowPublishedVersion?.translations">: {{workflowPublishedVersion.description}}
                        </div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.description && workflowPublishedVersion?.translations">: {{workflowPublishedVersion?.translations[selectedLang][workflowPublishedVersion.description]}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.STEPS-COMPLETED' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{wfReportData.results.length}}</div>
                    </div>
                    <div class="d-flex" *ngIf="isAdmin && isEditorMode && wfReportData.continueTimes && wfReportData.continueTimes?.length">
                        <div class="col-md-3 col-sm-12 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.PREFILLED-BY' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{reportUser ? reportUser : getUsersName(wfReportData.userId) }}</div>
                    </div>
                    <div class="d-flex" *ngIf="(isAdmin && isEditorMode) || showSubmittedBy || (wfReportData.continueTimes?.length && (wfReportData.userId || wfReportData.guest))">
                        <div class="col-md-3 col-sm-12 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.SUBMITTED-BY' | translate }}</div>
                        <div class="col-md-9 col-sm-12 col-xs-12 fs-15" *ngIf="wfReportData.userId && !wfReportData.guest?.email">: {{reportUser ? reportUser : getUsersName(wfReportData.userId) }}</div>
                        <div class="col-md-9 col-sm-12 col-xs-12 fs-15" *ngIf="wfReportData.guest?.email">: {{wfReportData.guest.email }}</div>
                    </div>

                </div>
                <div class="col-6">
                    <ng-container *ngIf="wfReportData.continueTimes && wfReportData.continueTimes?.length">
                        <div class="d-flex">
                            <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.PREFILLED-AT' | translate }}</div>
                            <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{wfReportData.startTime | localizedDate | async}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.QR-SCANNED-AT' | translate }}</div>
                            <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{wfReportData.continueTimes[0].continueTime | localizedDate | async}}</div>
                        </div>
                    </ng-container>
                    <div class="d-flex" *ngIf="!wfReportData.continueTimes || !wfReportData.continueTimes?.length">
                        <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.START' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{wfReportData.startTime | localizedDate | async}}</div>
                    </div>
                    <div class="d-flex">
                        <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.END' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{wfReportData.endTime | localizedDate | async}}</div>
                    </div>
                    <div class="d-flex">
                        <div class="col-md-3 col-sm-6 col-xs-12 fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.DURATION' | translate }}</div>
                        <div class="col-md-9 col-sm-6 col-xs-12 fs-15">: {{reportDuration}}</div>
                    </div>

                </div>

            </div>
            <div *ngIf="workflowPublishedVersion.files?.length>0">
                <hr>
                <div class="d-flex flex-wrap mt-0" style="margin-left:100px;">
                    <div class="col-md-1 col-sm-12 col-xs-12 fs-16 font-weight-500 mt-1">{{ 'MAIN.CONTENT.REPORT.FILES' | translate }}</div>
                    <div class="col-md-10 col-sm-12 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.name" style="margin-left:37px;">
                        <div class="d-flex flex-wrap"><span class="mt-1">:</span>
                            <div *ngFor="let file of workflowPublishedVersion.files; let index = index" style="padding: 7px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border-radius: 10px;" class="ms-1 mb-2 ng-star-inserted card fs-12 font-weight-500">
                            <div><label style="color: #4f5053" ><a [href]="file.url" class="file-attach">{{file.name}}</a></label></div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="workflowPublishedVersion.detailExtraFields?.length>0">
                <hr style="margin-top:3px;">
                <div class="d-flex flex-wrap mt-0" [ngClass]="{'m-100':!isMobile,'ms-5 me-5':isMobile, 'mb-0':(remoteCalls | keyvalue).length>0}">
                    <ng-container *ngIf="!isMobile">
                        <div class="col-6 col-xs-12"  *ngFor="let field of workflowPublishedVersion.detailExtraFields;let index=index">
                            <div class="d-flex">
                                <div class="col-3 col-sm-3 col-xs-12 fs-16 font-weight-500">{{ field.templateOptions?.label }}</div>
                                <div class="col-9 col-sm-9 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.name">: {{ field.templateOptions?.value }}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isMobile">
                        <div class="col-12 col-xs-12"  *ngFor="let field of workflowPublishedVersion.detailExtraFields;let index=index">
                            <div class="d-flex">
                                <div class="col-3 col-sm-4 col-xs-12 fs-16 font-weight-500">{{ field.templateOptions?.label }}</div>
                                <div class="col-9 col-sm-8 col-xs-12 fs-15" *ngIf="workflowPublishedVersion?.name">: {{ field.templateOptions?.value }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
            <hr *ngIf="isMobile && workflowPublishedVersion.detailExtraFields?.length>0">

            <hr *ngIf="(remoteCalls | keyvalue).length>0">
            <ng-container *ngIf="remoteCallsLoading && remoteCalls" [ngTemplateOutlet]="loadingTemplate"></ng-container>

            <div *ngIf="!remoteCallsLoading && remoteCalls && (remoteCalls | keyvalue).length > 0" class="mt-0" [ngStyle]="{'margin': isMobile ? '0px' : '100px'}">
                <p class="fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.TITLE' | translate }}</p>
                <div *ngFor="let remoteCall of remoteCalls | keyvalue ; let i = index" [ngClass]="{'remote-call-group' : i !== 0}">
                    <div class="d-flex col-12">
                        <div class="col-6">
                            <div class="d-flex" *ngIf="remoteCall.value['status'] == 'call-not-started'">
                                <div class="col-md-3 col-sm-6 col-xs-12">{{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.STATUS' | translate }}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14">: {{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.CALL-FAILED' | translate }}</div>
                            </div>
                            <div class="d-flex" *ngIf="remoteCall.value?.['stepName']">
                                <div class="col-md-3 col-sm-6 col-xs-12">{{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.STEP' | translate }}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14" *ngIf="remoteCall.value?.['stepName']">: {{workflowPublishedVersion.translations[selectedLang][remoteCall.value['stepName']]}}</div>
                            </div>
                            <div class="d-flex" *ngIf="remoteCall.value['session']?.['joinedUsers']">
                                <div class="col-md-3 col-sm-6 col-xs-12">{{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.USERS' | translate }}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14" *ngIf="remoteCall.value['session']?.['joinedUsers']">: 
                                    <span *ngFor="let joinedUser of remoteCall.value['session']['joinedUsers']; let userIndex = index;">
                                        {{ joinedUser?.name }} 
                                        <span *ngIf="joinedUser?.isGuest">({{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.GUEST' | translate }})</span>
                                        <span *ngIf="userIndex + 1 !== remoteCall.value['session']['joinedUsers']['length']">,</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <div class="col-md-3 col-sm-6 col-xs-12">{{ 'MAIN.CONTENT.REPORT.REMOTE-CALL.CREATED-AT' | translate }}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14" *ngIf="remoteCall.value['session']?.['create_time']">: {{remoteCall.value['session']['create_time'] | localizedDate | async}}</div>
                            </div>
                            <div class="d-flex">
                                <div class="col-md-3 col-sm-6 col-xs-12">{{ 'MAIN.CONTENT.REPORT.START' | translate }}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14" *ngIf="remoteCall.value['session']?.['start_time']">: {{remoteCall.value['session']['start_time'] | localizedDate | async}}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14" *ngIf="!remoteCall.value['session']?.['start_time']">: -</div>
                            </div>
                            <div class="d-flex" *ngIf="remoteCall.value['session']?.['end_time']">
                                <div class="col-md-3 col-sm-6 col-xs-12">{{ 'MAIN.CONTENT.REPORT.END' | translate }}</div>
                                <div class="col-md-9 col-sm-6 col-xs-12 fs-14" *ngIf="remoteCall.value['session']?.['end_time']">: {{remoteCall.value['session']['end_time'] | localizedDate | async}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex report-border" [ngClass]="{'report-border-desktop':!isMobile,'ms-5 me-5':isMobile}">
                <div class="col-4 fs-15 font-weight-600" *ngIf="!isWhitelabel">{{ 'MAIN.CONTENT.REPORT.STEP-LIST.NAME' | translate }}</div>
                <div class="col-6 fs-15 font-weight-600">{{ 'MAIN.CONTENT.REPORT.STEP-LIST.CONTENT' | translate }}</div>
                <div class="col-2 fs-15 font-weight-600" *ngIf="!isWhitelabel"> {{ 'MAIN.CONTENT.REPORT.STEP-LIST.DURATION' | translate }}</div>

            </div>
            <div class="d-flex align-items-center report" [ngClass]="{'den':result?.['isSection'],'report-desktop':!isMobile,'ms-5 me-5':isMobile, 'flex-wrap': result['parentWorkflow']}" *ngFor="let result of wfReportData.results">
                <!-- <ng-container>
                    <div *ngIf="result['parentWorkflow']" class="col-12 fs-16 font-weight-600 mt-2 pb-4" style="border-bottom: solid;">{{result['parentWorkflow']}}</div>
                </ng-container> -->
                <ng-container *ngIf="!isWhitelabel">
                    <div *ngIf="workflowPublishedVersion.translations " class="col-4" [ngClass]="{'fs-16 font-weight-600':result?.['isSection'], 'fs-15 font-weight-500':!result?.['isSection'] , 'ps-5':result?.['parentId']}">{{workflowPublishedVersion.translations[selectedLang][result['stepName']]}}</div>
                    <div *ngIf="!workflowPublishedVersion.translations " class="col-4" [ngClass]="{'fs-16 font-weight-600':result?.['isSection'], 'fs-15 font-weight-500':!result?.['isSection'] , 'ps-5':result?.['parentId']}">{{result['stepName']}}</div>    
                </ng-container>
                
                <div [ngClass]="{'col-6': !isWhitelabel, 'col-12': isWhitelabel}">
                    <div *ngFor="let item of result.reportArray" class="mt-3">
                        <p *ngIf="item.data && item.data['label']" class="mb-1 fs-14 font-weight-600">
                            <span *ngIf="workflowPublishedVersion.translations">{{workflowPublishedVersion.translations[selectedLang][item.data['label']]}}</span>
                            <span *ngIf="!workflowPublishedVersion.translations">{{ item.data['label']}}</span>
                        </p>

                        <ng-container *ngIf="!item?.data?.value && item.data?.type">
                            <p class="fs-14 font-weight-500" style="color: #A1A1A1;">{{ 'MAIN.CONTENT.REPORT.STEP-LIST.NO-CONTENT' | translate }}</p>
                        </ng-container>

                        <ng-container *ngIf="item?.data?.value">
                            <div *ngIf="item.data.type == 'html'" class="">
                                <!-- <div class="ql-editor html-widget" [innerHTML]="byPassHTML(item?.data?.value)"></div> -->
                                <div *ngIf="workflowPublishedVersion.translations[selectedLang][item?.data?.html]" class="ql-editor html-widget" [innerHTML]="byPassHTML(workflowPublishedVersion.translations[selectedLang][item?.data?.html])"></div>
                                <div *ngIf="!workflowPublishedVersion.translations[selectedLang][item?.data?.html]" class="ql-editor html-widget" [innerHTML]="byPassHTML(item?.data?.value)"></div>
                            </div>
    
                            <div class="fs-14 font-weight-500 text-black" *ngIf="!item.data?.mediaGroup && item.data.type !== 'pdf' && item.data.type !== 'label' && item.data.type !== 'html' && item.data.type !== 'gallery' && item.data.type !== 'datepicker' && item.data.type !== 'multicheckbox' && item.data.type !== 'table' && item.data.type !== 'form'">
                                <div *ngIf="item.data.type == 'radio'">
                                    <span class="fs-14 font-weight-600">Options : </span>
                                    <span *ngFor="let optionItem of item.data?.options;let i=index">
                                        <span *ngIf="!workflowPublishedVersion.translations">{{optionItem?.label}} </span>
                                        <span *ngIf="workflowPublishedVersion.translations">{{workflowPublishedVersion.translations[selectedLang][optionItem?.label]}} </span>
                                        <span *ngIf="i !== item.data?.options?.length-1">, </span></span>
                                </div>
                                <img src="assets/img/check1.svg" alt="" class="me-1">
                                <span *ngIf="(item.data.type =='number' || item.data.type =='input' || item.data.type =='id')">{{ item.data.value}}</span>

                                <span *ngIf="!workflowPublishedVersion.translations && item.data.type !=='number' && item.data.type !=='input'">{{ item.data.value}}</span>
                                <span *ngIf="workflowPublishedVersion.translations &&  item.data.type !=='number'  && item.data.type !=='input'">{{workflowPublishedVersion.translations[selectedLang][item.data.value]}} </span>
                                <span *ngIf="workflowPublishedVersion.translations &&  item.data.type !=='number'  && item.data.type !=='input' && isArray(item.data?.value)">
                                    <span *ngFor="let val of item.data?.values">{{workflowPublishedVersion.translations[selectedLang][val]}}</span>
                                </span>
                                <span *ngIf="item.data.unit">{{item.data.unit}}</span>
                            </div>
                            <div class="fs-14 font-weight-500 text-black" *ngIf="item.data.type == 'multicheckbox'">
                                <div>
                                    <span class="fs-14 font-weight-600">Options : </span>
                                    <span *ngFor="let optionItem of item.data?.options;let i=index">
                                        <span *ngIf="!workflowPublishedVersion.translations">{{optionItem?.label}} </span>
                                        <span *ngIf="workflowPublishedVersion.translations">{{workflowPublishedVersion.translations[selectedLang][optionItem?.label]}} </span>
                                        <span *ngIf="i !== item.data?.options?.length-1">, </span></span>
                                </div>
                                <img src="assets/img/check1.svg" class="me-1" alt="" *ngIf="item.data.value.length>0">
                                <span *ngIf="!workflowPublishedVersion.translations">
                                    <span *ngFor="let checkboxItem of item.data.values;let i=index">
                                    {{checkboxItem}}
                                    <span *ngIf="i !== item.data.values?.length-1">, </span>
                                    </span>
                                </span>
                                <span *ngIf="workflowPublishedVersion.translations">
                                    <span *ngFor="let checkboxItem of item.data.values;let i=index">
                                        {{workflowPublishedVersion.translations[selectedLang][checkboxItem]}}
                                        <span *ngIf="i !== item.data.values?.length-1">, </span>
                                    </span>
                                </span>
                                <p class="fs-14 font-weight-500" style="color: #A1A1A1;" *ngIf="item.data.value.length==0">{{ 'MAIN.CONTENT.REPORT.STEP-LIST.NO-CONTENT' | translate }}</p>
                            </div>

                            <p class="fs-14 font-weight-500 mb-0" *ngIf="!item.data?.mediaGroup && item.data.type !== 'pdf' && item.data.type == 'label'">
                                <span *ngIf="!workflowPublishedVersion.translations">{{ item.data.value}}</span>
                                <span *ngIf="workflowPublishedVersion.translations">{{workflowPublishedVersion.translations[selectedLang][item.data.value]}} </span>
                            </p>

                            <p class="fs-14 font-weight-500 mb-0" *ngIf="item.data.type == 'datepicker' && (item.data.value | typeof)=== 'string'">
                                <img src="assets/img/check1.svg" class="me-1" alt=""> {{ item.data.value}}
                            </p>

                            <p class="fs-14 font-weight-500 mb-0" *ngIf="item.data.type == 'datepicker' && (item.data.value | typeof)=== 'number'">
                                <img src="assets/img/check1.svg" class="me-1" alt=""> {{ item.data.value | localizedDate | async}}
                            </p>

                            <p class="fs-14 font-weight-500 text-black" *ngIf="item?.data.value  && item.data?.type =='ppe'">
                                <img [src]="item.data.value.detected ? 'assets/img/check1.svg' : 'assets/img/close.svg'" alt="" class="me-1"> 
                                {{ item.data.value.detected ? ('MAIN.CONTENT.REPORT.STEP-LIST.DETECTED' | translate) : ('MAIN.CONTENT.REPORT.STEP-LIST.NOT-DETECTED' | translate) }}
                            </p>
                            <p class="fs-14 font-weight-500 text-black" *ngIf="item.data?.type =='qr' && item.data.isQrMatch">
                                <img [src]=" item.data.value.detected ? 'assets/img/check1.svg' : 'assets/img/close.svg'" alt="" class="me-1"> 
                                {{ item.data.value.detected ? ('MAIN.CONTENT.REPORT.STEP-LIST.MATCHED' | translate) : ('MAIN.CONTENT.REPORT.STEP-LIST.NOT-MATCHED' | translate) }}
                            </p>
    
            
                            <div class="cursor-pointer p-relative min-200 overflow-scroll" [ngClass]="{'max-w-300': !isWhitelabel}" *ngIf="(item.data.mediaGroup && item.data.type!=='ppe' && item.data.type!=='qr' && !isArray(item.data.value))">
                                <div class="view" *ngIf="(item.data.type=='ppe' && item.data.value.link !==null) || item.data.mediaGroup && !isArray(item.data.value)">
                                    <img [src]="item.data.value | safeHtml" *ngIf="item.data.type=='image' || (item?.data?.type=='captureImage')" class="tt" (click)="openMedia(item.data.value,'image')">
                                    <video [src]="item.data.value | safeHtml" *ngIf="item?.data?.type=='video' || item?.data?.type=='captureVideo'" class="tt" controls (click)="openMedia(item.data.value,'video')"></video>
                                </div>
                            </div>
                            <div class="cursor-pointer p-relative min-200 overflow-scroll" [ngClass]="{'max-w-300': !isWhitelabel}" *ngIf="item.data.type=='ppe' && item.data.value?.link && item.data.value.link !==null">
                                <div class="view" *ngIf="(item.data.type=='ppe' && item.data.value.link && item.data.value.link !==null)">
                                    <img [src]="item.data.value.link | safeHtml" *ngIf="item?.data?.type=='ppe' && item.data.value.link !==null" class="tt" (click)="openMedia(item.data.value.link,'image')">
                                </div>
                            </div>

                            <div *ngIf="(item.data.type=='captureImage') && isArray(item.data.value)" >
                                <div class="html-widget d-flex flex-wrap">
                                    <div *ngFor="let imageItem of item.data.value"  class="me-2 mb-2 cursor-pointer">
                                        <div class="multiple-image-container">
                                            <div class="multiple-image-div">
                                                <img [src]="imageItem.url | safeHtml" class="tt" (click)="openMedia(imageItem.url,'image')" style="width: 100%;
                                                height: 100%;
                                                object-fit: contain;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="fs-14 font-weight-500 mb-0" *ngIf="item.data.type=='qr'">
                                <div *ngIf="item.data.enteredQRValue">
                                    <span class="fs-14 font-weight-600">{{'MAIN.CONTENT.REPORT.STEP-LIST.ENTERED-VALUE' | translate}} : </span>
                                    <span *ngIf="item.data.value?.hasOwnProperty('qr') && item.data.value.qr">{{ item.data.enteredQRValue}}</span>
                                </div>
                                
                                <span class="fs-14 font-weight-600">{{'MAIN.CONTENT.REPORT.STEP-LIST.SCANNED-VALUE' | translate}} : </span>
                                <span *ngIf="item.data.value?.hasOwnProperty('qr') && item.data.value.qr">{{ item.data.value.qr}}</span>
                                <span *ngIf="item.data.value && !item.data.value?.hasOwnProperty('qr')">{{ item.data.value}}</span>
                                <p class="fs-14 font-weight-500" style="color: #A1A1A1;" *ngIf="(item.data.value?.hasOwnProperty('qr') && !item.data.value.qr) || !item.data.value">{{ 'MAIN.CONTENT.REPORT.STEP-LIST.NO-CONTENT' | translate }}</p>
                            </div>
    
                            <div class="cursor-pointer p-relative min-200 overflow-scroll" [ngClass]="{'max-w-300': !isWhitelabel}" *ngIf="item.data?.type=='captureVideo' && isArray(item.data.value)">
                                <div class="view">
                                    <video [src]="video.url | safeHtml" *ngFor="let video of item.data.value" class="tt" controls (click)="openMedia(video.url,'video')"></video>
                                </div>
                            </div>

                            <div class="fs-14 font-weight-500 cursor-pointer" *ngIf="item.data.type == 'pdf'">
                                <div class="cursor-pointer p-relative min-200 overflow-scroll" [ngClass]="{'max-w-400': !isWhitelabel}">
                                    <div class="view">
                                        <div class="tt">
                                            <pdf-viewer 
                                                [src]="item.data.value"
                                                [render-text]="true"
                                                [original-size]="false"
                                                [autoresize]="true"
                                                [page]="currentPdfPage"
                                                (pageChange)= "pageChanged($event)"
                                                (after-load-complete)="afterLoadComplete($event)"
                                                style="height: 100%;"
                                            ></pdf-viewer>
                                        </div>
                                        <div class="position-absolute-aligned d-flex align-items-center justify-content-center form-image-overlay">
                                            <div class="zoom-outline" (click)="openMedia(item.data.value,'pdf')">
                                                <img src="assets/img/full.svg" alt="" class="zoom">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="item.data.type == 'gallery'" class="">
                                <div class="html-widget d-flex flex-wrap">
                                    <div *ngFor="let image of item.data.value" class="me-2 mb-2 cursor-pointer">
                                        <div class="multiple-image-container">
                                            <div class="multiple-image-div">
                                                <img [src]="image.imgUrl | safeHtml"  *ngIf="image.imgUrl" (click)="openMedia(image.imgUrl,'image')" style="width: 100%;
                                                height: 100%;
                                                object-fit: contain;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="item.data.type == 'form'" class="mt-3">
                                <div class="fs-14 font-weight-500 cursor-pointer mt-3">
                                    <div class="cursor-pointer p-relative min-200 overflow-scroll" [ngClass]="{'max-w-400': !isWhitelabel}">
                                        <div class="d-flex flex-wrap justify-content-between" [ngClass]="{'flex-column': !isWhitelabel || isMobile}">
                                
                                            <div class="grid-container" [ngClass]="{'col-12': isMobile || !isWhitelabel, 'col-6': !isMobile}" *ngFor="let form of workflowPublishedVersion?.steps?.[result.stepId]?.data.layouts[0].items[0].templateOptions.formData; let formIndex = index;">
                                                <p *ngIf="form.label" class="fs-14 font-weight-600">{{workflowPublishedVersion?.translations[selectedLang]?.[form.label]}}</p>
                                                <div class="d-flex mb-2 me-3 text-start" *ngFor="let field of form.fields; let fieldIndex = index;" style="align-items: end;">
                                                    <div [ngClass]="{'col-6': isMobile, 'col-4': !isMobile, 'col-8': isMobile && !isWhitelabel}"><label>{{workflowPublishedVersion?.translations[selectedLang]?.[field.templateOptions.label]}}</label></div>
                                                    <div [ngClass]="{'col-6': isMobile, 'col-8': !isMobile }"><input disabled type="text" class="table-form-control" placeholder="{{item.data.value[field.key]}}"></div>
                                                </div>
                                                <hr *ngIf="(formIndex == 0 || formIndex == 1 || formIndex == 2) && (isMobile || !isWhitelabel)">
                                            </div>
            
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.data.type == 'table'">
                                <div class="fs-14 font-weight-500 cursor-pointer">
                                    <div class="cursor-pointer p-relative min-200 overflow-scroll" [ngClass]="{'max-w-400': !isWhitelabel}">
                                        <div class="view">
                                            <div class="">
                                                <app-table-report [tableData]="item.data.value" [workflowPublishedVersion]="workflowPublishedVersion?.steps?.[result.stepId]?.data" [workflowTranslations]="workflowPublishedVersion?.translations" [selectedLang]="selectedLang"></app-table-report>
                                            </div>
                                            <div class="position-absolute-aligned d-flex align-items-center justify-content-center form-image-overlay">
                                                <div class="zoom-outline" (click)="openTable(item.data.value, workflowPublishedVersion?.steps?.[result.stepId]?.data)">
                                                    <img src="assets/img/full.svg" alt="" class="zoom">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                    </div>
                </div>
                <div class="col-2 fs-15 font-weight-500" *ngIf="!isWhitelabel"> {{result['duration']}}</div>
            </div>

            <div class="approval-header" [ngClass]="{'report-border-desktop':!isMobile,'ms-5 me-5':isMobile}" *ngIf="report.signature">
                <label class="fs-16 font-weight-500">{{ 'MAIN.CONTENT.REPORT.APPROVAL.TITLE' | translate }}</label>
            </div>

            <p class="fs-14 font-weight-400 mt-4 mb-4 report-desktop" [ngClass]="{'report-border-desktop':!isMobile,'ms-5 me-5':isMobile}" *ngIf="report.signature">
                {{ 'MAIN.CONTENT.REPORT.APPROVAL.DESCRIPTION-TEXT' | translate }}
            </p>

            <div class="signature-container" *ngIf="report.signature?.signers || signatureReportData?.type">
                <div class="card mb-3 approver-card" *ngFor="let signer of wfReportData.signature.signers" [ngClass]="{'report-border-desktop':!isMobile,'ms-0 me-5':isMobile}">
                    <div class="ms-4 fs-14">
                        <div class="d-flex flex-column align-items-start">
                            <div class="fs-14 font-weight-600" *ngIf="signer?.name">
                                {{signer.name}}
                            </div>
                            <div class="fs-14">
                                {{signer.email}}
                            </div>
                        </div>
                        
                    </div>

                    <div class="d-flex flex-wrap  align-items-center col-5">
                        <div class="col-md-7 col-12 col-sm-6 fs-14 font-weight-500">
                            <label *ngIf="signer.signedAt" class="fs-13">{{signer.signedAt | localizedDate | async}}</label>
                            
                        </div>
                        <div class="col-md-4 col-12 col-sm-5" [ngClass]="{'ms-4': signer.email == signatureReportData?.signer && !signer.signed && !isMobile, 'ms-1': signer.email == signatureReportData?.signer && !signer.signed && isMobile}">
                            <div *ngIf="signer.email == signatureReportData?.signer">
                                <button class="v-btn btn btn-primary fs-12 font-weight-600 text-white sign-button" (click)="openSignatureModal(signer)" *ngIf="!signer.signed">{{ 'MAIN.CONTENT.REPORT.APPROVAL.SIGN' | translate }}</button>
                                <div class="p-relative cursor-pointer" [ngStyle]="{'min-height': isMobile ? '80px' : '80px','min-width': isMobile ? '100px' : '120px'}" *ngIf="signer.signed">
                                    <div class="view">
                                        <img [src]="signer.signatureUrl" alt="" class="tt" *ngIf="signer.signed">
                                    </div>
                                </div>
                            </div>
    
                            <div class=""  *ngIf="signer.email !== signatureReportData?.signer">
                                <div class="d-flex flex-column align-items-center" *ngIf="!signer.signed">
                                    <label class="fs-14 font-weight-600">{{ 'MAIN.CONTENT.REPORT.APPROVAL.NOT-SIGNED' | translate }}</label>
                                </div>
                                <div class="p-relative cursor-pointer" [ngStyle]="{'min-height': isMobile ? '80px' : '80px','min-width': isMobile ? '110px' : '120px'}" *ngIf="signer.signed">
                                    <div class="view">
                                        <img [src]="signer.signatureUrl" alt="" class="tt" *ngIf="signer.signed">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                 </div>
            </div>
        </div>
    </div>

</div>

<ng-template #reportHeaderVsight>
    <div class="p-relative">
        <div style="position: absolute;right: 45px;top: 15px;" class="close" *ngIf="!isMobile && !signatureReportData">
            <img src="assets/img/close-circle.svg" alt="" (click)="onClose()" class="close-circle">
        </div>
    </div>
    <div style="margin-left: 46px;margin-top: 50px;">
        <img *ngIf="!logoList?.[accountName]" src="assets/img/logo.png" alt="" width="200px">
        <img *ngIf="logoList?.[accountName]" [src]="logoList?.[accountName].logo" alt="" width="200px">
    </div>
</ng-template>

<ng-template #reportHeaderEwers>
    <div class="p-relative">
        <div class="d-flex justify-content-end me-5 mt-3" *ngIf="!isMobile && !signatureReportData">
            <img src="assets/img/close-circle.svg" alt="" (click)="onClose()" class="cursor-pointer close-circle">
        </div>
    </div>
    <div class="d-flex justify-content-end me-5 logo-display mb-4">
        <img src="assets/img/ewers.png" alt="" width="200px">

    </div>

</ng-template>

<ng-template #loadingTemplate>
    <div class="spinner-border mb-4" role="status" style="margin-left:100px">
        <span class="visually-hidden">Loading...</span>
      </div>
</ng-template>
