import { Injectable } from '@angular/core';

import { DbService } from './db.service';
import { User } from '../models/User';
import { BehaviorSubject, filter, firstValueFrom, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private featuresSource: BehaviorSubject<{[key: string]: boolean}> = new BehaviorSubject(null);

  currentUser: User = null;

  user: Observable<User>;

  userForGuard: Observable<User>;
  guestForGuard: Observable<any>;

  constructor(
    private dbService: DbService,
    private http: HttpClient
  ) {
    this.user = this.dbService.currentUserSource.asObservable().pipe(
      tap(user => { this.currentUser = user }),
      filter(users => !!users)
    );
    this.userForGuard = this.dbService.userForGuardSource.asObservable();
    this.guestForGuard = this.dbService.guestForGuardSource.asObservable();
  }

  getAccountData() {
    return this.dbService.getAccountData()
  }

  loginToFirebase(token: string) {
    return this.dbService.loginToFirebase(token)
  }

  switchToRemote() {
    const url = "https://us-central1-vsight-remote-angular-dev.cloudfunctions.net/switchToRemote"
    return firstValueFrom(this.http.post<any>(url, { token: this.currentUser.token}))
  }

  getToken() {
    return this.dbService.getToken()
  }

  getAuth(): Observable<boolean> {
    return this.dbService.getAuth()
  }

  login(account_name: string, username: string, password: string): Promise<any> {
    return this.dbService.login(account_name, username, password)
  }

  logout() {
    return this.dbService.logout()
  }

  verifyLoginCode(account_name: string, username: string, password: string, code: string) {
    return this.dbService.verifyLoginCode(account_name, username, password, code)
  }

  on( event: "login" | "logout" | "externalLogin" | "multipleLogin", callback) {
    // this.listeners[event].push(callback);
  }

  off(event: "login" | "logout", callback?) {
    // if (callback == undefined) {
    //   this.listeners[event] = [];
    // } else {
    //   const index = this.listeners[event].indexOf(callback);
    //   this.listeners[event].splice(index, 1);
    // }
  }
// api ile değil de sürekli dinlenmesi gerekn yerler için?
  isFeatureAvailable(feature: string): boolean {
    return this.featuresSource.value ? this.featuresSource.value[feature] : false;
  }

  getAccountLabels(): Observable<string[]> {
    return this.dbService.getAccountLabels()
  }

  setAccountLabels(labels: string[]): Promise<void> {
    return this.dbService.setAccountLabels(labels)
  }

  twoFactorSendCode(action: string) {
    return this.dbService.twoFactorSendCode(action)
  }

  twoFactorProceed(action: string, code: string) {
    return this.dbService.twoFactorProceed(action, code)
  }

  authenticateSSO(id: string) {
    return this.dbService.twoFactorSendCode(id)
  }

  getJoinLinkData(linkId: string) {
    const url = environment.endPoints.joinwithlink;
    return this.http.post<any>(url, { action: 'info', link_id: linkId }).toPromise()
    .then(result => result.data);
  }

  joinWithLink(linkId: string, name: string): Promise<any> {
    const url = environment.endPoints.joinwithlink;
    return this.http.post<any>(url, { action: 'credentials', link_id: linkId, name: name }).toPromise()
      .catch(error => {
        if (error.error) {
          if (error.error === 'session-not-found') {
            throw new Error('session-not-found');
          } else if (error.error === 'session-expired') {
            throw new Error('session-expired');
          } else if (error.error === 'concurrent-limit-reached') {
            throw new Error('concurrent-limit-reached');
          } else if (error.error === 'expert-concurrent-limit-reached') {
            throw new Error('expert-concurrent-limit-reached');
          } else if (error.error === 'invalid-parameters') {
            throw new Error('invalid-parameters');
          } else if (error.error === 'internal-error') {
            throw new Error('internal-error');
          }
        }
        throw new Error('internal-error');
      });
  }

}
