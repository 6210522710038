import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-rich-text',
  template: `
  <div class="html-widget-iframe-container" #containerRef style="position:relative;">
  </div>
  <div class="overlay" style="top:0;left:0;width:100%;height:100%;position:absolute;" [ngStyle]="{'display': isOverlayAvailable ? 'none' : ''}" (click)="htmlClick()"></div>
  `,
})
export class FormlyFieldCustomRichText extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild('containerRef', { static: true }) containerRef: ElementRef<HTMLElement>;
  isOverlayAvailable: boolean = false;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
    const iframe: HTMLIFrameElement = this.renderer.createElement('iframe');
    iframe.width = '100%'
    iframe.height = '400px'
    iframe.src = `data:text/html;charset=utf-8,${this.htmlContent}`
    this.renderer.appendChild(this.containerRef.nativeElement, iframe)
    
  }

  overlayClick() {
    this.isOverlayAvailable = true;
    this.htmlClick(); // Overlay'e tıklandığında deneme fonksiyonunu çağır
  }

  htmlClick() {
    this.isOverlayAvailable = true;
  }

  get type() {
    return this.to.type || 'text';
  }

  get htmlContent(): string {
    return `
    <!DOCTYPE html>
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=1060, initial-scale=1.0">
        <style>
          * {
            font-family: Helvetica, Arial, sans-serif;
          }
          p {
            color: black;
            font-family: Helvetica, Arial, sans-serif;
          }
          h1, h2, h3, h4, h5, h6 {
          }
        </style>
      </head>
      <body id="content">
        <div>
        ${this.to['html']}
        </div>
      </body>
    </html>`;
  }
}