import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "@services/user.service";
import { WorkflowService } from "@services/workflow.service";

@Pipe({ name: 'userNames' })
export class UserNamesPipe implements PipeTransform {
    users = []

    constructor(private userService: UserService, private workflowservice: WorkflowService) {

    }

    transform(value: string[], args?: any) {
        return this.workflowservice.getAllUsersForWorkflow().then(resp => {
            this.users = resp.users
            return value.map(uid => {
                const u = this.users.find(user => user.id === uid);
                return u ? u.name : "Unknown";
            }).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
        })
    }

    // transform(value: string[], args?: any) {
    //     return this.userService.allUsers.pipe(map(users => {
    //         return value
    //         .map(uid => {
    //             const u = users.find(user => user.id === uid);
    //             return u ? u.name : "Unknown";
    //         })
    //         .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
    //     }));
    // }
}