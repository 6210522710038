<div class="d-flex flex-column h-100">
    <label class="label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="d-flex mt-2" style="height: 100%; overflow-y: scroll;">
     <div class="form-check w-100 text-start">
        <div class="row">
          <ng-container *ngFor="let optionGroup of checkboxGroup.controls; let i=index;" [formGroup]="optionGroup">
            <div [ngClass]="{'col-md-6': checkboxGroup.length > 2, 'col-md-12': checkboxGroup.length <= 2}">
              <div class="custom-control custom-checkbox custom-checkbox-green">
                <input
                  type="checkbox"
                  class="form-check-input ms-0"
                  [id]="'input-'+item.key+optionGroup.value.option"
                  formControlName="value"
                />
                <label class="custom-control-label label-form" [for]="'input-'+item.key+optionGroup.value.option">
                  {{ optionGroup.value.option }}
                </label>
              </div>
            </div>
          </ng-container>
        </div>
     </div>
    </div>
    <p class="validation-text pb-0 text-start" *ngIf="checkboxGroup?.errors?.required && checkboxGroup.touched">{{ 'MAIN.CONTENT.WORKFLOW.REQUIRED' | translate }}</p>
</div>