import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
// import { DetailPageService } from '@services/support/detail-page.service';
import { Session } from '@models/Session';
import { interval, of, Subscription } from 'rxjs';
import { User } from '@models/User';
import { UserService } from '@services/user.service';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@services/remote/session.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { AuthService } from '@services/auth.service';
import { WorkflowService } from '@services/workflow.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DetailPageService } from '@services/detail-page.service';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit, OnDestroy {

  @ViewChild("pickTicketTemplate", { static: true }) private pickTicketTemplate: TemplateRef<any>;

  session: any;
  userSessions: boolean;

  sessionSub: Subscription = null;

  participants: string[] = [];

  ticketAvailable: boolean = false;

  users: User[] = [];
  userSub: Subscription = null;

  selectedTicket = null;

  attachedTicketId: string = null;
  attachedTicket: any = null;

  licenseType: string = "expert_user_based";
  
  exportRecipients: any[] = [];
  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ""};
  emailPholder = "";
  emailSecPholder = "";
  
  sessionExportEnabled: boolean = false;
  webdavEnabled: boolean = false;
  accountDataSub: Subscription = null;

  showCheckboxes = false;
  downloadSelectedFiles = false;
  showDownloadButton: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private sessionService: SessionService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private workflowService: WorkflowService,
    private route: ActivatedRoute,
    private detailPageService: DetailPageService
  ) { }

  ngOnInit() {
    // this.route.queryParams.subscribe(params => {
    //   const id = params['id'];
    //   this.sessionSub = this.sessionService.getSession(id).subscribe(res => {
    //     this.session = res
    //   })
    // })
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.PLACEHOLDER').pipe(first()).toPromise().then(tr => {
      this.emailPholder = tr;
    });
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER').pipe(first()).toPromise().then(tr => {
      this.emailSecPholder = tr;
    });
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.INVALID_EMAIL').pipe(first()).toPromise().then(tr => {
      this.invalidEmailError.pattern = tr;
    });

    this.authService.getAccountData().then(account => {
      this.sessionExportEnabled = account.data.accountData.add_ons.sessionexport;
      this.webdavEnabled = account.data.accountData.add_ons.webdav;
      this.licenseType = account.data.accountData.license.type;
    })

    this.workflowService.getAllUsersForWorkflow().then(res => {
      if(res?.users) {
        this.users = res.users.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      }
    })
  }

  onGetExport() {
    this.sessionService.sessionExportRequest.next([this.session.room_id, this.session.id, !!this.session.end_time]);
  }

  toggleSelection() {
    this.showCheckboxes = !this.showCheckboxes;
    this.showDownloadButton = !this.showDownloadButton;
  }

  onDownloadStarted() {
    if (this.downloadSelectedFiles) {
      this.downloadSelectedFiles = false;
    } else {
      setTimeout(() => {
        this.downloadSelectedFiles = true;
        setTimeout(() => {
          this.downloadSelectedFiles = false;
        }, 0);
      }, 0);
    }
  }

  // onSessionExportModal($event, roomId:string, sessionId: string, modalId?: number) {
  //   $event.stopPropagation();
  //   this.ticketService.sessionExportRequest.next([roomId,sessionId, true, modalId]);
  // }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
  }

  // onBack() {
  //   this.detailPageService.removeComponent2();
  // }

  getUsersName(id: string) {
    const user = this.users.find(u => u.id === id);
    return user ? user.name : "Unknown";
  }

  onTicketClicked(id) {
    this.selectedTicket = id;
  }

  generateUserNameWithRole(user: any, licenseType: string) {
    // if (this.accountDataSub) { this.accountDataSub.unsubscribe() }

    // this.accountDataSub = this.accountService.accountData
    // .subscribe(accountData => {
    //   licenseType = accountData.license.type;
    // })

    
    const username = user.ad_user ? user.user_principal_name : user.auth.username;
    if (user.coadmin) {
      user.name2 = `${user.name} (${username} / Co-Admin)`;
      return user;
    }

    switch (user.role) {
      case "admin":
        user.name2 = `${user.name} (${username} / Admin)`;
        return user;
      case "coadmin":
        user.name2 = `${user.name} (${username} / Co-Admin)`;
        return user;
      case "expert":
        user.name2 = `${user.name} (${username} / Expert)`;
        return user;
      default:
        user.name2 = `${user.name} (${username} / ${licenseType === "concurrent_user_based" ? "User" : "Subscriber"})`;
        return user;
    }
  }

  onBackButton(){
    this.detailPageService.removeComponent();
  }

  // onAttachToTicket() {
  //   const dataModel = { tickets: [] };
  //   const ticketSub = this.ticketService.getRoomTicketList(this.session.room_id)
  //   .subscribe(tickets => { dataModel.tickets = tickets; });

  //   const modalId = this.modalService.show({
  //     template: this.pickTicketTemplate,
  //     context: {
  //       dataModel: dataModel,
  //       callbacks: {
  //         close: () => {
  //           this.selectedTicket = null;
  //           ticketSub.unsubscribe();
  //           this.modalService.hide(modalId);
  //         },
  //         attach: () => {
  //           this.loaderService.show();
  //           this.ticketService.attachTicket(this.selectedTicket, this.session.room_id, this.session.id)
  //           .then(() => {
  //             ticketSub.unsubscribe();
  //             this.modalService.hide(modalId);
  //           })
  //           .catch(error => this.flashMessageService.show('There is an error occured.'))
  //           .finally(() => this.loaderService.hide());
  //         }
  //       }
  //     }
  //   });
  // }
}
