<app-user-workflow style="display:block; height:100%; width: 100%;margin-top: 20px;min-width: 200px;min-height:200px;"
*ngIf="workflow && token"
    [workflow]= "workflow"
    [remoteUserId]= "remoteUserId"
    [token]= "token"
></app-user-workflow>

<ng-container  *ngIf="!workflow || !token || !remoteUserId" [ngTemplateOutlet]="loadingTemplate"></ng-container>

<ng-template #loadingTemplate>
    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
    </div>
</ng-template>