import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-voice',
  template: `
  <label *ngIf="to.label">{{ to.label }}<span *ngIf="to.required">*</span></label>
  `,
})
export class FormlyFieldCustomVoice extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}