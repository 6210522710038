import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TableWidgets } from '@models/TableWidgets';
import { TranslateService } from '@ngx-translate/core';
import { DbService } from '@services/db.service';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';

export interface TableProperties {
  key: string
  type: 'table'
  templateOptions: {
    label: string
    tableData: TableData
  }
}

export interface TableData {
  columns: TableRow[],
  rows: TableRow[]
}

export interface TableRow {
  cells: TableCell[]
}

export interface TableCell {
  key: string;
  type: TableWidgetTypes;
  order: number;
  templateOptions: any;
}

export type TableWidgetTypes = 'label' | 'input' | 'number' | 'multicheckbox' | 'datepicker' | 'empty' | 'radio'
export type TableWidgetProperties = {
   label?: string; 
   placeholder?: string; 
   numberFields?: boolean; 
   timepicker?: boolean; 
   position?: boolean; 
   options?: boolean  }


@Component({
  selector: 'app-table-form-properties',
  templateUrl: './table-form-properties.component.html',
  styleUrls: ['./table-form-properties.component.scss']
})
export class TableFormPropertiesComponent implements OnInit, OnChanges {
  @Input() data: TableData
  @Input() selectedCell: {
    row: number;
    column: number;
    data: any;
    isHeader?: boolean;
  };
  @Input() workflowTranslations

  @Input() deletedRow
  @Input() deletedColumn

  @Output() updated: EventEmitter<TableData> = new EventEmitter()
  @Output() workflowTranslationsChange: EventEmitter<string> = new EventEmitter<any>();

  createForm = new UntypedFormGroup({
    columns: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
    rows: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
  })

  isTableAvailable = false
  isCreateFormVisible = false

  tableWidgets = TableWidgets
  selectedWidget
  selectedWidgetPreviousValue


  selectedLang
  subscriptions: Subscription[] = [];

  constructor(
    private dbService: DbService,
    private translateService: TranslateService,
    private bsModalService: BsModalService,
    private workflowLanguageService: WorkflowLanguageService
    ) {
      this.selectedLang = this.workflowLanguageService.currentLang.code
      this.subscriptions.push(this.workflowLanguageService.onLangChange.subscribe(res => {
      this.selectedLang = res.lang.code
    }))
     }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.selectedCell = null;
      this.isTableAvailable = this.data?.rows?.length > 0
      this.selectedWidget = null
    }
    if(changes['selectedCell']?.currentValue) {
      this.selectedWidgetPreviousValue = changes['selectedCell']?.previousValue?.data['type']
      this.selectedWidget = null
    }
    if(changes['deletedRow']?.currentValue && !changes['deletedRow']?.firstChange) {
      this.showAlertModal('row', changes['deletedRow'].currentValue['rowIndex'])
    }
    if(changes['deletedColumn']?.currentValue) {
      this.showAlertModal('column', changes['deletedColumn'].currentValue['columnIndex'])
    }
  }

  get columns(): TableCell[] {
    return this.data.columns[0].cells
  }

  get columnCount(): number {
    return this.data?.columns.length > 0 ? this.data.columns[0].cells.length : 0
  }

  get rows(): TableRow[] {
    return this.data?.rows
  }

  ngOnInit(): void {
    this.isTableAvailable = this.rows?.length > 0
    this.isCreateFormVisible = !this.isTableAvailable
  }

  getCell(rowIndex, columnIndex): TableCell {
    return this.rows[rowIndex].cells[columnIndex]
  }

  addRow(): void {
    const row = this.createRow(this.columnCount)
    this.rows.push(row)
    this.updated.emit(this.data)
  }

  addColumn(): void {
    let columnLabelKey = `$~#${this.dbService.createPushId()}$~#`
    this.workflowTranslations[this.selectedLang][columnLabelKey] = `Column ${this.columnCount + 1}`
    const templateOptions = {label: columnLabelKey }
    const data = this.generateTableCellData('label', this.columnCount + 1, templateOptions)
    this.columns.push(data)
    
    this.rows.forEach((row) => row.cells.push(this.generateTableCellData('empty', this.columnCount, {})))
    this.updated.emit(this.data)
  }

  deleteRow() {
    this.rows.pop()
    this.updated.emit(this.data)
  }

  deleteTableRow(index) {
    this.rows.splice(index,1)
    this.updated.emit(this.data)
  }

  deleteColumn() {
    this.columns.pop()
    this.rows.forEach((row) => row.cells.pop())
    this.updated.emit(this.data)
  }

  deleteTableColumn(index) {
    this.columns.splice(index,1)
    this.rows.forEach((row) => row.cells.splice(index,1))
    this.updated.emit(this.data)
  }

  createTable(): void {
    this.data = {
      columns: [],
      rows: []
    }
  
    const rowCount = +this.createForm.value.rows
    const columnCount = +this.createForm.value.columns


    const cells: TableCell[] = [];
    for (let c = 0; c < columnCount; c++) {
      let columnLabelKey = `$~#${this.dbService.createPushId()}$~#`
      this.workflowTranslations[this.selectedLang][columnLabelKey] = `Column ${c+1}`
      const templateOptions = {label: columnLabelKey }
      const data = this.generateTableCellData('label', c, templateOptions)
      cells.push(data)
    }
    this.data.columns = [{ cells }]

    for(let r = 0; r < rowCount; r++) {
      const row = this.createRow(columnCount);
      this.rows.push(row)
    }

    this.isTableAvailable = true
    this.isCreateFormVisible = false

    this.updated.emit(this.data)
  }

  createRow(columnCount): TableRow {
    const cells = []
    // Fill row columns
    for (let c = 0; c < columnCount; c++) {
      const templateOptions = {}
      const data = this.generateTableCellData('empty', c, templateOptions)
      cells.push(data)
    }
    return { cells };
  }

  generateTableCellData(type: TableWidgetTypes, order: number, templateOptions?: any): TableCell {
    return {
      type,
      order,
      templateOptions,
      key: this.dbService.createPushId(),
    }
  }

  updateTableColumnData(columnIndex, data) {
    this.columns[columnIndex] = data;
  }

  updateTableDataAt(rowIndex, columnIndex, data) {
    this.rows[rowIndex].cells[columnIndex] = data
  }

  updateSelectedCellTemplateOptions(value: any): any {
    this.selectedCell.data = {
      ...this.selectedCell.data,
      templateOptions: value
    }
    if(this.selectedCell.isHeader) {
      this.updateTableColumnData(this.selectedCell.column, this.selectedCell.data);
    } else {
      this.updateTableDataAt(this.selectedCell.row, this.selectedCell.column, this.selectedCell.data);
    }
    this.updated.emit(this.data)
  }

  changeWidgetType(widget: { type: TableWidgetTypes; name: string }, selected, isEmpty?): void {
    if(isEmpty && selected.data.type !== 'empty') {
      const initialState = {
        textMessage: isEmpty ? this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.DELETE-TEXT-MESSAGE') : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TEXT-MESSAGE'),
        headerTitle: isEmpty ? this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TITLE-DELETE') : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TITLE-CHANGE'),
        confirmButtonStyle: isEmpty ? null : 'primary',
        confirmButtonText: isEmpty ? null : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.REPLACE'),
      }
      let alertmodalRef = this.bsModalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
      const sub = alertmodalRef.content.onClose.subscribe((res) => {
        sub.unsubscribe()
        if (res) {
          this.selectedCell.data = this.generateTableCellData(widget.type, this.selectedCell.data.order, {})
          this.updateSelectedCellTemplateOptions({})
        }
      })
    }else{
      this.selectedCell.data = this.generateTableCellData(widget.type, this.selectedCell.data.order, {})
      this.updateSelectedCellTemplateOptions({})
    }
    this.selectedWidget = widget.type
    
    // Reset selected cell data template options when widget type change
    // this.selectedCell.data = this.generateTableCellData(widget.type, this.selectedCell.data.order, {})
    // this.updateSelectedCellTemplateOptions({})

  }

  showAlertModal(type, index) {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.DELETE-ROW-TEXT-MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TITLE-DELETE-ROW'),
    }
    let alertmodalRef = this.bsModalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
    const sub = alertmodalRef.content.onClose.subscribe((res) => {
      sub.unsubscribe()
      if (res) {
        if(type === 'row') {
          this.deleteTableRow(index)
        }
        if(type === 'column') {
          this.deleteTableColumn(index)
        }
      }
    })
  }
}

