
<div class="modal-dialog-alert" role="document">
        <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MODALS.TEMPLATE.CREATE-STEP' | translate | titlecase }}</div>
            <div class="cursor-pointer"  (click)="modalRef.hide()">
                <img src="assets/img/close_white.svg" alt="">
            </div>
        </div>
        <div class="card-body modal-card-body p-0">
            <div class="card-body-overlay mt-5">
                <div class="fs-24 font-weight-600 color-blue-dark">{{ 'MODALS.TEMPLATE.OPTIONS.TITLE' | translate }}</div>
                <div class="overlay-content">
                    <div class="step-template-box m-3 btn border flex-column" [ngClass]="{'border-light': selectedTemplate !== 1, 'border-primary': selectedTemplate === 1}" tabindex="0" (focus)="selectedTemplate = 1">
                        <img src="assets/img/fullscreen.svg">
                        <label class="fs-16 font-weight-500 top-gap-28">{{ 'MODALS.TEMPLATE.OPTIONS.FULL' | translate }}</label>
                    </div>
                    <div class="step-template-box m-3 btn border flex-column" [ngClass]="{'border-light': selectedTemplate !== 2, 'border-primary': selectedTemplate === 2}" tabindex="0" (focus)="selectedTemplate = 2">
                        <div style="position: relative;">
                            <img src="assets/img/fullscreen.svg">
                            <div style="position: absolute;top: 12px;transform: rotate(-90deg);">
                                <img src="assets/img/vertical.svg">
                            </div>
                        </div>
                        <label class="fs-16 font-weight-500 top-gap-28">{{ 'MODALS.TEMPLATE.OPTIONS.VERTICAL' | translate }}</label>
                    </div>
                    <div class="step-template-box m-3 btn border flex-column" [ngClass]="{'border-light': selectedTemplate !== 3, 'border-primary': selectedTemplate === 3}" tabindex="0" (focus)="selectedTemplate = 3">
                        <div style="position: relative;">
                            <img src="assets/img/fullscreen.svg">
                            <div style="position: absolute; top: 10px;right: -4px;">
                                <img src="assets/img/horizontal.svg">
                            </div>
                        </div>
                        <label class="fs-16 font-weight-500 top-gap-28">{{ 'MODALS.TEMPLATE.OPTIONS.HORIZONTAL' | translate }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mb-5 top-gap-30 justify-content-center">
            <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary font-weight-600" (click)="modalRef.hide()">
                {{ 'MAIN.CONTENT.DASHBOARD.CANCEL' | translate | titlecase }}
            </button>
            <button type="button" class="v-btn btn vsight-button btn-primary font-weight-600 text-white" [disabled]="!selectedTemplate" (click)="selectTemplate()">
                {{ (action === 'create' ? 'MODALS.TEMPLATE.CREATE' : 'MODALS.TEMPLATE.CHANGE') | translate }}
            </button>
        </div>
    </div>
</div>
