<div class="dashboard-wrapper">
    <div class="v-card">
        <div class="card report-header list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="d-flex">
                <div class="header fs-20 font-weight-700  pt-1 me-2">Remote Assistance</div>
            </div>
            <div class="p-relative">
                <button class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" (click)="newSession()">
                    New Remote Session
                    <img src="assets/img/button-down.svg" alt="" class="me-2" class="ms-2">
                </button>
                <div class="menu" *ngIf="showMenu">
                    <div class="fs-14 option cursor-pointer" (click)="scheduleMeeting()">Schedule Meeting</div>
                    <div class="fs-14 option cursor-pointer mt-2" (click)="instantCall()">Start an Instant Call</div>
                </div>
            </div>
        </div>

        <div class="card tab-area d-flex flex-row first-area">
            <div class="tab-item left fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 0 }" (click)="onTabClicked(0)">
                <span class="tab-title">
                    Overview
                </span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 1 }" (click)="onTabClicked(1)">
                <span class="tab-title">
                    Meetings
                </span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 2 }" (click)="onTabClicked(2)">
                <span class="tab-title">
                    Work Groups
                </span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 3 }" (click)="onTabClicked(3)">
                <span class="tab-title">
                    History
                </span>
            </div>
        </div>

        <div class="card list-area first-area">

            <div class="row mt-5 ps-3 pe-4" *ngIf="tabIndex == 0">
                <div class="col-8">
                    <div class="join-session-area">
                        <div class="fs-18 font-weight-700">Join a Remote Session</div>
                        <label class="fs-14 mt-4">Enter a shared link to join a scheduled meeting.</label>
                        <div class="d-flex align-items-center mt-3">
                            <input type="text" class="form-control w-50" placeholder="Enter Link" [formControl]= "linkOrFieldId">
                            <button class="v-btn btn btn-primary vsight-button-sm ms-3" (click)="onLinkOrFieldId()" [disabled]="linkOrFieldId.errors?.['required']">Join</button>
                        </div>
                    </div>

                    
                </div>
                <div>
                    <div class="mt-5">
                        <div class="d-flex align-items-center">
                            <label class="fs-18 font-weight-700">Upcoming Scheduled Meetings</label>
                            <a class="core-blue fs-14 font-weight-600 link ms-4" (click)="viewAllMeetings()">View all Meetings</a>
                        </div>

                        <div>
                            <app-meetings  [isOverview]="true" (onMeetingCreate)="scheduleMeeting()"></app-meetings>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-4 call-area">
                    <app-call-users></app-call-users>
                </div> -->
            </div>
            
            <div class="row mt-2 ps-3 pe-4" *ngIf="tabIndex == 1">
                <div class="col-12">
                    <app-meetings></app-meetings>
                </div>
            </div>

            <div class="row mt-2" *ngIf="tabIndex == 2">
                <div class="col-12">
                    <app-user-rooms></app-user-rooms>
                </div>
            </div>

            <div class="row mt-2" *ngIf="tabIndex == 3">
                <div class="">
                    <app-user-sessions></app-user-sessions>
                </div>
            </div>
            
        </div>


    </div>
</div>

<ng-template #scheduleMeetingTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="schedule-meeting-modal-dialog modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content" style="width: 600px;">
            <div class="modal-header">
                <div class="modal-title font-weight-600">{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.TITLE' | translate }}</div>

                <div class="cursor-pointer"  (click)="callbacks.close()">
                    <img src="assets/img/close_white.svg" alt="">
                </div>
            </div>
            <div class="form-inside modal-body px-5" style="overflow-y: auto;max-height:350px ;">
                <form #scheduleForm="ngForm" class="ticket-form" (ngSubmit)="callbacks.schedule(scheduleForm);">
                    <div class="d-flex mb-2">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="assets/img/title.svg" alt="" class="me-2">
                        </div>
                        <div class="form-group mb-0 w-100">
                            <input #title="ngModel" class="form-control" style="font-size: 14px;" type="text" name="title" placeholder="{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.MEETING_TITLE' | translate }}" 
                                [ngClass]="{'is-invalid':title.errors && title.touched}" [(ngModel)]="dataModel.title" maxlength="60" required>
                            <small class="invalid-feedback mx-2" [hidden]="!title.errors?.['required']">{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.MEETING_TITLE_REQUIRED' | translate }}</small>
                        </div>
                    </div>
                    <div class="d-flex mb-2">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="assets/img/people.svg" alt="" class="me-2">
                        </div>
                        <div class="form-group mb-0 w-100" style="font-size: 16px;">
                            <ng-select [items]="dataModel.users" [clearable]="false" [searchable]="true" name="users" bindLabel="name" bindValue="id"
                                placeholder="{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.ENTER_USERS' | translate }}" [multiple]="true" [clearSearchOnAdd] = "true" [closeOnSelect]="false" (change)="callbacks.userListChanged($event)"></ng-select>    
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="assets/img/time.svg" alt="" class="me-2">
                        </div>
                        <div class="d-flex w-100">
                            <div class="mb-1 d-flex">
                                <div class="form-group datepicker-form-group align-items-center mb-0">
                                    <input #startDate="bsDatepicker" name="startDate" class="form-control fs-14 text-dark-grey date-input me-2" id="form-control" bsDatepicker placeholder="{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.START_DATE' | translate }}" style="font-size: 13px;"
                                        [(ngModel)]="dataModel.start.date" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" (ngModelChange)="callbacks.startDateChanged($event)">
                                </div>
                                <div class="form-group mb-0">
                                    <ng-select class="fs-14 text-dark-grey" [items]="dataModel.startSlots" [clearable]="false" [searchable]="false" name="startTime" bindLabel="label" bindValue="time"
                                        placeholder="" [(ngModel)]="dataModel.start.time" (change)="callbacks.startTimeChanged($event)"></ng-select>
                                </div>
                            </div>
                            <img src="assets/img/arrow.svg" alt="" class="ms-2 me-2">
                            <div class="mb-1 col-6 d-flex">
                                <div class="form-group datepicker-form-group  align-items-center mb-0">
                                    <input #endDate="bsDatepicker" [minDate]="dataModel.start.date" name="endDate" id="form-control" class="form-control fs-14 text-dark-grey date-input me-2" bsDatepicker placeholder="{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.END_DATE' | translate }}" style="font-size: 13px;"
                                        [(ngModel)]="dataModel.end.date" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" (ngModelChange)="callbacks.endDateChanged($event)">
                                </div>
                                <div class="form-group mb-0">
                                    <ng-select class="fs-14 text-dark-grey" [items]="dataModel.endSlots" [clearable]="false" [searchable]="false" name="endTime" bindLabel="label" bindValue="time"
                                        placeholder="" [(ngModel)]="dataModel.end.time" (change)="callbacks.endTimeChanged($event)" style="width: 125px;"></ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="assets/img/duration-icon.svg" alt="" class="me-2">
                        </div>
                        <div class="d-flex align-items-center fs-14 text-dark-grey">{{ dataModel.duration }}</div>
                    </div>
                    <div class="d-flex">
                        <div class="col-12 d-flex align-items-center fs-14 font-weight-600 text-dark-grey label-text">{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SEND_INVITATIONS' | translate }}</div>
                    </div>
                    <div class="d-flex mb-2">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="assets/img/email.svg" alt="" class="me-2">
                        </div>
                        <tag-input class="col-11" #tagInput name="emailRecipients" style="font-size: 14px;" [(ngModel)]="dataModel.emailRecipients" placeholder="{{ 'MAIN.EMAIL_RECIPIENTS.PLACEHOLDER' | translate }}"
                            secondaryPlaceholder="{{ 'MAIN.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER' | translate }}" theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError"
                            [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
                    </div>
                    <div class="d-flex mb-1">
                        <div class="col-12 d-flex align-items-center fs-14 font-weight-600 text-dark-grey label-text">{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.INVITATION_EMAIL_LANG' | translate }}</div>
                    </div>
                    <div class="d-flex mb-3">
                        <div class="d-flex justify-content-center align-items-center">
                             <img src="assets/img/language-2.svg" alt="" class="me-2">
                        </div>
                        <div class="d-flex">
                            <div class="language-dropdown" dropdown [dropup]="true" #dropdown="bs-dropdown">
                                <button id="language-toggle" type="button" class="language-link lang-link-button d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                                    <!--<i class="mi mi-language ml-1" style="font-size: 20px;"></i>-->
                                    <span class="ms-2">{{ dataModel.emailLang | uppercase }}</span>
                                    <i class="mi mi-keyboard-arrow-down ms-2"></i>
                                </button>
                                <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0 lang-menu" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                                    <ng-container *ngFor="let lang of dataModel.emailLangList">
                                        <li class="language-item" role="menuitem" *ngIf="dataModel.emailLang !== lang">
                                            <a class="dropdown-item language-link fs-14 text-dark-grey px-2" (click)="callbacks.onLanguageSelected(lang)">{{ lang | uppercase }}</a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="col-12 d-flex align-items-center fs-14 font-weight-600 text-dark-grey label-text">{{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SHARE_AFTER' | translate }}</div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-center d-block text-right py-3 px-3">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-outline-secondary" (click)="callbacks.close()">
                    {{ 'MODALS.ALERT.CANCEL' | translate }}
                </button>
                <button type="button" class="v-btn vsight-button btn btn-primary"
                    [disabled]="!scheduleForm.valid || meetingCreationInProgress" *ngIf="dataModel.emailRecipients.length>0" (click)="callbacks.schedule(scheduleForm)">
                    {{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SCHEDULE_SEND' | translate }}
                </button>
                <button type="button" class="v-btn vsight-button btn btn-primary"
                    [disabled]="!scheduleForm.valid || meetingCreationInProgress" *ngIf="dataModel.emailRecipients.length===0" (click)="callbacks.schedule(scheduleForm)">
                    {{ 'MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SCHEDULE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>