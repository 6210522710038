import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, UntypedFormArray, Validators } from '@angular/forms';
import { Room } from '@models/Room';
import { User } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { DetailPageService } from '@services/detail-page.service';
import { ModalService } from '@services/remote/modal.service';
import { RoomService } from '@services/remote/room.service';
import { UtilityService } from '@services/remote/utility.service';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { UserService } from '@services/user.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { first, firstValueFrom, map, startWith, Subscription } from 'rxjs';
import { AddWebhookModalComponent } from 'src/app/components/modals/add-webhook-modal/add-webhook-modal.component';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';
import { UserDetailComponent } from '../user-detail/user-detail.component';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  @ViewChild("alwaysArchiveTemplate", { static: true }) private alwaysArchiveTemplate: TemplateRef<any>;
  @ViewChild("alwaysFullHdTemplate", { static: true }) private alwaysFullHdTemplate: TemplateRef<any>;
  @ViewChild("peerToPeerTemplate", { static: true }) private peerToPeerTemplate: TemplateRef<any>;
  @ViewChild("addEmailTemplate", { static: true }) private addEmailTemplate: TemplateRef<any>;
  @ViewChild("twoFactorAdminTemplate", { static: true }) private twoFactorAdminTemplate: TemplateRef<any>;
  @ViewChild("enableSystemEmailTemplate", { static: true }) private enableSystemEmailTemplate: TemplateRef<any>;
  @ViewChild("verifySystemEmailTemplate", { static: true }) private verifySystemEmailTemplate: TemplateRef<any>;
  @ViewChild("disableSystemEmailTemplate", { static: true }) private disableSystemEmailTemplate: TemplateRef<any>;
  @ViewChild("configureSamlTemplate", { static: true }) private configureSamlTemplate: TemplateRef<any>;
  @ViewChild("configureScimTemplate", { static: true }) private configureScimTemplate: TemplateRef<any>;
  @ViewChild("configureOIDCTemplate", { static: true }) private configureOIDCTemplate: TemplateRef<any>;
  @ViewChild("removeSamlTemplate", { static: true }) private removeSamlTemplate: TemplateRef<any>;
  @ViewChild("removeScimTemplate", { static: true }) private removeScimTemplate: TemplateRef<any>;
  @ViewChild("removeOIDCTemplate", { static: true }) private removeOIDCTemplate: TemplateRef<any>;
  @ViewChild("newWebhookTemplate", { static: true }) private newWebhookTemplate: TemplateRef<any>;
  @ViewChild("removeWebhookTemplate", { static: true }) private removeWebhookTemplate: TemplateRef<any>;

  webhooks = []

  timezones: string[] = ["UTC-12", "UTC-11", "UTC-10", "UTC-9", "UTC-8", "UTC-7", "UTC-6", "UTC-5", "UTC-4", "UTC-3", "UTC-2", "UTC-1",
    "UTC", "UTC+1", "UTC+2", "UTC+3", "UTC+4", "UTC+5", "UTC+6", "UTC+7", "UTC+8", "UTC+9", "UTC+10", "UTC+11", "UTC+12", "UTC+13", "UTC+14"];
  orjTzone: string = "UTC";
  tzone: string = "UTC";

  passwordLengths: number[] = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 24, 28, 32, 64];
  passwordLength: number = 6;
  AZSelected: boolean = false;
  AZClicked: boolean = false;
  azSelected: boolean = false;
  azClicked: boolean = false;
  numberSelected: boolean = false;
  numberClicked: boolean = false;
  specialCharacterSelected: boolean = false;
  specialCharacterClicked: boolean = false;
  oldPasswordLength: number = 6;
  strongEnabled: boolean = false;
  changeDetected: boolean = false;

  alwaysArchive: boolean = false;
  alwaysFullHd: boolean = false;
  allowTwoFactor: boolean = false;
  twoFactorAdmin: boolean = false;
  twoFactorOthers: boolean = false;
  customEmailAvailable: boolean = false;
  customEmailEnabled: boolean = false;
  adIntegrationAvailable: boolean = false;
  oidcAvailable: boolean = false;

  customEmailCredentials = { name: "", host: "", port: 465, user: "", pass: ""/*, test: ""*/ };

  emailRecipients: any[] = [];
  recipientsChanged: boolean = false;
  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError
  emailPholder
  emailSecPholder

  accountData: any = null;
  accountDataSub: Subscription = null;

  rooms: Room[] = null;
  roomsSub: Subscription = null;

  users: User[] = [];
  usersSub: Subscription = null;

  azureAdConfig;
  oauthOIDCConfig;

  samlConfigured: boolean = false;
  scimConfigured: boolean = false;
  oidcConfigured: boolean = false;

  formPristine: boolean = true;
  formValueSub: Subscription = null;
  contactsForm;
  contactsFormGroupArray: FormGroup[] = [];
  orjContactsFormGroupArray: FormGroup[] = [];
  contactData: any;
  isContactEdit: boolean = false;

  licenseInfo: any = {
    license: null
  };
  license

  webhooksAvailable: boolean = false;
  tabIndex = 0

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private dbService: DbService,
    private http: HttpClient,
    private snackBarService: SnackbarService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private detailPageService: DetailPageService,
    private roomService: RoomService,
    private userService: UserService,
    private workflowService : WorkflowService,
    private datePipe: DatePipe,
    private multilanguageService: MultilanguageService
  ) { }

  ngOnInit(): void {
    const url = environment.endPoints.changeWebhook
    const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "getWebhooks" })
    firstValueFrom(pending)
      .then(result => {
        this.webhooks = result.data.webhooks
      })
      .catch(error => {
        console.log(error)
      })

      if (environment.azureAdConfig) {
        const name = environment.azureAdConfig['name'] ? environment.azureAdConfig['name'] : this.authService.currentUser.auth.account_name;
  
        this.azureAdConfig = {
          accountNameAsName: environment.azureAdConfig['name'] ? false : true,
          name: name,
          samlEntityId: this.renderTemplate(environment.azureAdConfig.samlEntityId, { name: name }),
          samlReplyUrl: this.renderTemplate(environment.azureAdConfig.samlReplyUrl, { name: name }),
          samlReplyUrl2: this.renderTemplate(environment.azureAdConfig.samlReplyUrl2, { name: name }),
          samlReplyUrl3: this.renderTemplate(environment.azureAdConfig.samlReplyUrl3, { name: name }),
          scimEndpoint: this.renderTemplate(environment.azureAdConfig.scimEndpoint, { name: name }),
          msLoginUrl: this.renderTemplate(environment.azureAdConfig.msLoginUrl, { name: name })
        }
      }
      if (environment.oauthOIDCConfig) {
        const name = this.authService.currentUser.auth.account_name;
        this.oauthOIDCConfig = {
          name: name,
          initiateLoginUrl: this.renderTemplate(environment.oauthOIDCConfig.initiateLoginUrl, { name: name }),
          redirectUrl: this.renderTemplate(environment.oauthOIDCConfig.redirectUrl, { name: name })
        }
      }
  
      this.roomsSub = this.roomService.rooms.subscribe(rooms => {
        this.rooms = rooms.sort((a, b) => a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase()));
      });

      this.workflowService.getAllUsersForWorkflow().then(res => {
        this.users = res.users;
      })
      
      this.authService.getAccountData().then(account => {
        this.multilanguageService.onLangChange.pipe(
          // onLangChange will not triggered until lang change,
          // we need to trigger first time to fill license related data
          startWith(this.multilanguageService.currentLang),
          map(() => account.data.accountData)
        )
      .subscribe(accountData => {
        this.mapLicence(account.data.accountData);
      });

        if (account.data.accountData.timezone !== this.orjTzone) {
          this.tzone = account.data.accountData.timezone;
        }
        this.orjTzone = account.data.accountData.timezone;
        this.twoFactorAdmin = account.data.accountData.two_factor_auth && account.data.accountData.two_factor_auth.admin;
        this.twoFactorOthers = account.data.accountData.two_factor_auth && account.data.accountData.two_factor_auth.others;
  
        this.allowTwoFactor = account.data.accountData.features.twofactorauth;
        this.alwaysArchive = account.data.accountData.always_archive;
        this.alwaysFullHd = account.data.accountData.always_full_hd;
  
        this.webhooksAvailable = account.data.accountData.add_ons.remotewebhooks;
        // this.webhooksAvailable = account.data.accountData.add_ons?.workflowwebhooks

        if (account.data.accountData.webhooks) {
          const eventNames = Object.keys(account.data.accountData.webhooks)
          this.webhooks = []
          for (const eventName of eventNames) {
            const webhookIds = Object.keys(account.data.accountData.webhooks[eventName])
            const eventWebhooks = webhookIds.map(wid => {
              const webhook = account.data.accountData.webhooks[eventName][wid]
              webhook.id = wid
              return webhook
            })
            eventWebhooks.forEach(wh => this.webhooks.push(wh))
          }
        } else {
          this.webhooks = []
        }
  
        this.customEmailAvailable = account.data.accountData.add_ons.customserviceemail;
        this.customEmailEnabled = account.data.accountData.email_config && !account.data.accountData.email_config.auth_shared;
        this.adIntegrationAvailable = environment.design.showAzureAdIntegration && account.data.accountData.add_ons.azureadintegration;
        this.oidcAvailable = account.data.accountData.add_ons.oidcsso;
  
        this.samlConfigured = account.data.accountData.ad_config && account.data.accountData.ad_config.saml_configured;
        this.scimConfigured = account.data.accountData.ad_config && account.data.accountData.ad_config.scim_configured;
        this.oidcConfigured = account.data.accountData.ad_config && account.data.accountData.ad_config.oidc_configured;
  
        if (account.data.accountData.password_policy) {
          this.passwordLength = account.data.accountData.password_policy.options.length ? account.data.accountData.password_policy.options.length : 6;
          this.strongEnabled = account.data.accountData.password_policy.strong ? account.data.accountData.password_policy.strong : false;
          this.oldPasswordLength = this.passwordLength;
          this.AZSelected = account.data.accountData.password_policy.options.AZ ? account.data.accountData.password_policy.options.AZ : false;
          this.azSelected = account.data.accountData.password_policy.options.az ? account.data.accountData.password_policy.options.az : false;
          this.numberSelected = account.data.accountData.password_policy.options.number ? account.data.accountData.password_policy.options.number : false;
          this.specialCharacterSelected = account.data.accountData.password_policy.options.special ? account.data.accountData.password_policy.options.special : false;
        }
  
        this.emailRecipients = (account.data.accountData.email_recipients ? account.data.accountData.email_recipients : []).map(r => ({value: r, display: r}));
  
        this.contactData = account.data.accountData.contacts;
  
        this.accountData = account.data.accountData;
      })
  
      this.translateService.get('MAIN.SETTINGS.EMAIL_RECIPIENTS.PLACEHOLDER').pipe(first()).toPromise().then(tr => {
        this.emailPholder = tr;
      });
      this.translateService.get('MAIN.SETTINGS.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER').pipe(first()).toPromise().then(tr => {
        this.emailSecPholder = tr;
      });
      this.translateService.get('MAIN.SETTINGS.EMAIL_RECIPIENTS.INVALID_EMAIL').pipe(first()).toPromise().then(tr => {
        // this.invalidEmailError.pattern = tr;
      });
      this.createForm();
  
      this.formValueSub = this.contactsForm.valueChanges.subscribe(value => {
        let pristine = true;
        if (this.orjContactsFormGroupArray) {
          if (this.orjContactsFormGroupArray.length === value.formGroupList.length) {
            for (let i = 0; i < this.orjContactsFormGroupArray.length; i++) {
              if (this.orjContactsFormGroupArray[i].controls['id'].value !== value.formGroupList[i].id)
                pristine = false;
              if (this.orjContactsFormGroupArray[i].controls['department'].value !== value.formGroupList[i].department)
                pristine = false;
              if (this.orjContactsFormGroupArray[i].controls['name'].value !== value.formGroupList[i].name)
                pristine = false;
              if (this.orjContactsFormGroupArray[i].controls['email'].value !== value.formGroupList[i].email)
                pristine = false;
              if (this.orjContactsFormGroupArray[i].controls['phone'].value !== value.formGroupList[i].phone)
                pristine = false;
            }
          } else {
            pristine = false;
          }
        }
        this.formPristine = pristine;
      })
  }

  onTabClicked(index) {
    this.tabIndex = index
  }

  onAddWebhook() {
    const initialState = {}
    let addWebhookModalRef = this.bsModalService.show(AddWebhookModalComponent,  { initialState, backdrop:'static', class:'modal-lg modal-dialog-centered', animated:false })
    addWebhookModalRef.content.onClose.subscribe((res) => {
      addWebhookModalRef.hide()
    })
    addWebhookModalRef.content.onNewWebhook.subscribe(webhook => {
      this.loaderService.show()
      const url = environment.endPoints.changeWebhook
      const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "addWebhook", webhook: webhook })
      firstValueFrom(pending)
        .then(result => {
          addWebhookModalRef.hide()
          webhook.id = result.data.webhookId
          this.webhooks.push(webhook)
        })
        .catch(error => {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.ERROR'))
        })
        .finally(() => this.loaderService.hide())
    })
  }

  onRemoveWebhook(webhook) {
    const initialState = {
      textMessage : this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.TITLE'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.REMOVE'),
      confirmButtonStyle: 'danger',
      oneButtonAvailable: false
    }
    let alertmodalRef = this.bsModalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.loaderService.show()
        const url = environment.endPoints.changeWebhook
        const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "removeWebhook", webhook: webhook })
        firstValueFrom(pending)
          .then(result => {
            alertmodalRef.hide()
            this.webhooks = this.webhooks.filter(wh => wh.id !== webhook.id)
          })
          .catch(error => {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.ERROR'))
          })
          .finally(() => this.loaderService.hide())
      } else {
        alertmodalRef.hide();
      }
    })
  }

  //remote

  enablePasswordSetting() {
    this.strongEnabled = !this.strongEnabled;
    this.changeDetected = true;
  }

  AZClick() {
    this.AZSelected = !this.AZSelected;
    this.AZClicked = !this.AZClicked;
    this.changeDetected = true;
  }

  azClick() {
    this.azSelected = !this.azSelected;
    this.azClicked = !this.azClicked;
    this.changeDetected = true;
  }

  numberClick() {
    this.numberSelected = !this.numberSelected;
    this.numberClicked = !this.numberClicked;
    this.changeDetected = true;
  }

  specialCharactersClick() {
    this.specialCharacterSelected = !this.specialCharacterSelected;
    this.specialCharacterClicked = !this.specialCharacterClicked;
    this.changeDetected = true;
  }

  minimumCharacterClick() {
    this.changeDetected = true;
  }

  async onPasswordControlGenerate() {
    this.loaderService.show();
    this.accountService.savePasswordSettings(this.AZSelected, this.azSelected, this.numberSelected, this.specialCharacterSelected, this.passwordLength, this.strongEnabled)
      .then(() => {
        this.changeDetected = false;
        this.snackBarService.success(this.translateService.instant("MAIN.SETTINGS.PASSWORD_POLICY.SUCCESS"))
      })
      .catch(err => {
        this.snackBarService.error(this.translateService.instant("MAIN.SETTINGS.PASSWORD_POLICY.ERROR"))
      })
      .finally(() => this.loaderService.hide())
  }

  renderTemplate(templateStr: string, args: any): string {
    templateStr = templateStr.replace(/`/g, '\\`');
    const keys = Object.keys(args);
    const fn = new Function(...keys, 'return `' + templateStr + '`');
    return fn(...keys.map(key => args[key]));
  }

  addSamlConfiguration() {
    const dataModel = { ssoUrl: "", cert: "" };
    const modalId = this.modalService.show({
      template: this.configureSamlTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          add: (samlForm: NgForm) => {
            if (!samlForm.valid) {
              return;
            }
            this.loaderService.show();
            samlForm.control.disable();

            this.accountService.addSamlConfiguration(this.azureAdConfig.name, samlForm.value.ssoUrl, samlForm.value.cert)
            .then(() => this.modalService.hide(modalId))
            .catch(error => {
              this.snackBarService.error(this.translateService.instant("There is an error occured."));
              samlForm.control.enable();
            })
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  removeSamlConfiguration() {
    const modalId = this.modalService.show({
      template: this.removeSamlTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show();
            this.accountService.removeSamlConfiguration(this.azureAdConfig.name)
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.snackBarService.error(this.translateService.instant("There is an error occured.")))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  addScimConfiguration() {
    const dataModel = { scimToken: "", tokenGenerated: false };
    const modalId = this.modalService.show({
      template: this.configureScimTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId),
          generateToken: () => {
            this.loaderService.show();
            this.accountService.addScimConfiguration(this.azureAdConfig.name)
            .then(config => {
              dataModel.scimToken = config.token;
            })
            .catch(error => this.snackBarService.error(this.translateService.instant("There is an error occured.")))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  removeScimConfiguration() {
    const modalId = this.modalService.show({
      template: this.removeScimTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show();
            this.accountService.removeScimConfiguration(this.azureAdConfig.name)
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.snackBarService.error(this.translateService.instant("There is an error occured.")))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  addOIDCConfiguration() {
    const dataModel = {
      discoveryEndpoint: "",
      clientId: "",
      clientSecret: "",
      responseType: "code",
      logoutUrl: ""
    };
    const modalId = this.modalService.show({
      template: this.configureOIDCTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          add: (oidcForm: NgForm) => {
            if (!oidcForm.valid) {
              return;
            }
            this.loaderService.show();
            oidcForm.control.disable();

            this.accountService.addOidcConfiguration(this.oauthOIDCConfig.name, oidcForm.value.discoveryEndpoint, oidcForm.value.clientId, oidcForm.value.clientSecret, [oidcForm.value.responseType], oidcForm.value.logoutUrl, [this.oauthOIDCConfig.redirectUrl])
            .then(() => this.modalService.hide(modalId))
            .catch(error => {
              this.snackBarService.error(this.translateService.instant("There is an error occured."));
              oidcForm.control.enable();
            })
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  removeOIDCConfiguration() {
    const modalId = this.modalService.show({
      template: this.removeOIDCTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show();
            this.accountService.removeOidcConfiguration(this.azureAdConfig.name)
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.snackBarService.error(this.translateService.instant("There is an error occured.")))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  copied(event) {
    this.snackBarService.success("Copied");
  }
  createForm() {
    if (this.contactData){
      for (const contact of Object.keys(this.contactData)){
        this.contactsFormGroupArray.push(new FormGroup({
          id: new FormControl(contact),
          department: new FormControl(this.contactData[contact].department),
          name: new FormControl(this.contactData[contact].name),
          email: new FormControl(this.contactData[contact].email),
          phone: new FormControl(this.contactData[contact].phone)
        }));
        this.orjContactsFormGroupArray.push(new FormGroup({
          id: new FormControl(contact),
          department: new FormControl(this.contactData[contact].department),
          name: new FormControl(this.contactData[contact].name),
          email: new FormControl(this.contactData[contact].email),
          phone: new FormControl(this.contactData[contact].phone)
        }));
      }
    }
    this.contactsForm = new FormGroup({
      formGroupList: new UntypedFormArray(this.contactsFormGroupArray)
    });
  }

  addFormGroup() {
    const formGroupList = this.contactsForm.controls.formGroupList as UntypedFormArray;
    formGroupList.push(new FormGroup({
      id: new FormControl(this.accountService.createContactID()),
      department: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, this.validMailValidator]),
      phone: new FormControl("")
    }));
  }

  removeFormGroup(index: number) {
    const formGroupList = this.contactsForm.controls.formGroupList as UntypedFormArray;
    formGroupList.removeAt(index);
  }

  revertChanges() {
    const formGroupList = this.contactsForm.controls.formGroupList as UntypedFormArray;
    formGroupList.clear();
    for (const formGroup of this.orjContactsFormGroupArray) {
      formGroupList.push(new FormGroup({
        id: new FormControl(formGroup.controls['id'].value),
        department: new FormControl(formGroup.controls['department'].value),
        name: new FormControl(formGroup.controls['name'].value),
        email: new FormControl(formGroup.controls['email'].value),
        phone: new FormControl(formGroup.controls['phone'].value)
      }))
    }
  }

  contactsFormSubmit(form: FormGroup) {
    const contactList = {
      contacts: []
    };
    this.loaderService.show();
    for (const formGroup of form.controls['formGroupList'].value) {
      contactList.contacts.push({
        id: formGroup.id,
        department: formGroup.department,
        name: formGroup.name,
        email: formGroup.email,
        phone: formGroup.phone
      });
    }
    this.accountService.changeContactSetting(contactList)
    .then(() => {
      // this.flashMessageService.showTranslated("MAIN.SETTINGS.CONTACT_INFO.SUCCESS", { cssClass: 'alert-success' })
      this.orjContactsFormGroupArray = [];
      if (contactList) {
        for (const contact of (contactList.contacts)){
          this.orjContactsFormGroupArray.push(new FormGroup({
            id: new FormControl(contact.id),
            department: new FormControl(contact.department),
            name: new FormControl(contact.name),
            email: new FormControl(contact.email),
            phone: new FormControl(contact.phone)
          }));
        }
        this.formPristine = true;
      }
      this.isContactEdit = false;
    })
    .catch(error => {
      // this.flashMessageService.showTranslated("MAIN.SETTINGS.CONTACT_INFO.FAILED")
      if (error.error) {
        if (error.error === 'permission-denied')
          throw new Error('permission-denied');
        else if (error.error === 'invalid-parameters')
          throw new Error('invalid-parameters');
        else 
          throw new Error('internal-error');
      }
    })
    .finally(() => this.loaderService.hide());
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.usersSub) { this.usersSub.unsubscribe() }
    if (this.formValueSub) { this.formValueSub.unsubscribe() }
  }

  disableContactEdit() {
    this.isContactEdit = false;
  }

  enableContactEdit() {
    this.isContactEdit = true;
  }

  onSaveAlwaysArchive() {
    const p2pCount = this.rooms ? this.rooms.filter(r => r.room_data.peer_to_peer).length : 0;
    const modalId = this.modalService.show({
      template: this.alwaysArchiveTemplate,
      context: {
        dataModel: { alwaysArchive: this.alwaysArchive, p2pCount: p2pCount },
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          change: () => {
            this.loaderService.show();
            this.accountService.changeAlwaysRecordSetting(this.alwaysArchive)
            .then(() => this.snackBarService.success("Automatic archive setting changed."))
            .catch(error => this.snackBarService.error('Cannot change automatic archive setting.'))
            .finally(() => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  onSaveAlwaysFullHd() {
    const modalId = this.modalService.show({
      template: this.alwaysFullHdTemplate,
      context: {
        dataModel: { alwaysFullHd: this.alwaysFullHd },
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          change: () => {
            this.loaderService.show();
            this.accountService.changeAlwaysFullHdSetting(this.alwaysFullHd)
            .then(() => this.snackBarService.success("FullHD setting changed."))
            .catch(error => this.snackBarService.error('Cannot change FullHD setting.'))
            .finally(() => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  emailListChanged() {
    this.recipientsChanged = true;
  }

  onSaveEmailList() {
    this.loaderService.show();
    this.accountService.changeEmailRecipientsSetting(this.emailRecipients.map(r => r.value))
    .then(() => { this.recipientsChanged = false; })
    .catch(error => this.snackBarService.error(this.translateService.instant("MAIN.SETTINGS.EMAIL_RECIPIENTS.CANNOT_CHANGE")))
    .finally(() => this.loaderService.hide());
  }

  onChangeTimezone() {
    this.loaderService.show();
    this.accountService.changeTimezoneSetting(this.tzone)
    .catch(error => this.snackBarService.error(this.translateService.instant("MAIN.SETTINGS.TIMEZONE.CANNOT_CHANGE")))
    .finally(() => this.loaderService.hide());
  }

  onPeerToPeerInfo() {
    const modalId = this.modalService.show({
      template: this.peerToPeerTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  onChangeTwoFactor(action: string) {
    if (action === 'enableadmin') {
      const noEmailUsers = this.users.filter(u => (u.role === "admin" || u.role === "coadmin" || u.coadmin) && (u.email === '' || !u.email))
      const dataModel = {
        forAdmin: true,
        users: noEmailUsers
      };
      if (noEmailUsers.length > 0) {
        const modalId = this.modalService.show({
          template: this.addEmailTemplate,
          context: {
            dataModel: dataModel,
            callbacks: {
              close: () => { this.modalService.hide(modalId) },
              profile: (userId: string) => {
                this.openUserDetail(userId);
                this.modalService.hide(modalId);
              }
            }
          }
        });
      } else {
        this.changeTwoFactor(action);
      }
    } else if (action === 'enableothers') {
      const noEmailUsers = this.users.filter(u => (!u.email || u.email === ""));
      if (noEmailUsers.length > 0) {
        const modalId = this.modalService.show({
          template: this.addEmailTemplate,
          context: {
            dataModel: {
              forAdmin: false,
              users: noEmailUsers
            },
            callbacks: {
              close: () => { this.modalService.hide(modalId) },
              profile: (userId: string) => {
                this.openUserDetail(userId);
                this.modalService.hide(modalId);
              }
            }
          }
        });
      } else {
        this.changeTwoFactor(action);
      }
    } else {
      this.changeTwoFactor(action);
    }
  }

  changeTwoFactor(action: string) {
    const twoFactorModel = {
      action: action,
      codeSended: false,
      code: null
    };
    const modalId = this.modalService.show({
      template: this.twoFactorAdminTemplate,
      context: {
        dataModel: twoFactorModel,
        callbacks: {
          close: () => { this.modalService.hide(modalId) },
          send: () => {
            this.loaderService.show();
            this.authService.twoFactorSendCode(action)
            .then(() => {
              twoFactorModel.codeSended = true;
            })
            .catch(error => this.showTwoFactorError(error))
            .finally(() => this.loaderService.hide());
          },
          proceed: () => {
            this.loaderService.show();
            this.authService.twoFactorProceed(action, twoFactorModel.code)
            .then(() => {
              this.modalService.hide(modalId);
              if (action === 'enableadmin' || action === 'enableothers') {
                this.snackBarService.success("Two factor authentication enabled");
              } else {
                this.snackBarService.success("Two factor authentication disabled.");
              }
            })
            .catch(error => this.showTwoFactorError(error))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showTwoFactorError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.error === 'email-required') {
        this.snackBarService.error("Please fill required email address in user profile.");
      } else if (error.error === 'invalid-verification-code') {
        this.snackBarService.error(this.translateService.instant("Invalid two-step verification code."));
      } else {
        this.snackBarService.error(this.translateService.instant("There is an error occured."));
      }
    } else {
      this.snackBarService.error(this.translateService.instant("There is an error occured."));
    }
  }

  openUserDetail(userId: string = null) {
    // const [instance, onClose] = this.detailPageService.loadComponent(UserDetailComponent)

    // const detail = this.detailPageService.loadComponent(UserDetailComponent).instance;
    // instance.action = UserDetailComponent.ACTION_DETAILS;
    // instance.u = userId ? userId : this.authService.currentUser.id;
  }

  onEnableCustomSystemEmail() {
    const modalId = this.modalService.show({
      template: this.enableSystemEmailTemplate,
      context: {
        dataModel: this.customEmailCredentials,
        callbacks: {
          cancel: () => {
            this.customEmailCredentials = { name: "", host: "", port: 465, user: "", pass: ""/*, test: ""*/ };
            this.modalService.hide(modalId);
          },
          enable: (customEmailForm: NgForm) => {
            if (!customEmailForm.valid) {
              return;
            }
            this.loaderService.show();
            customEmailForm.control.disable();

            this.accountService.setServiceEmail({
              name: customEmailForm.value.senderName,
              host: customEmailForm.value.senderHost,
              port: customEmailForm.value.senderPort,
              email: customEmailForm.value.senderEmail,
              user: customEmailForm.value.senderUser,
              pass: customEmailForm.value.pass/*,
              test: customEmailForm.value.testEmail*/
            })
            .then(() => {
              this.modalService.hide(modalId);
              this.verifyCustomSystemEmail();
            })
            .catch(error => {
              if (error instanceof HttpErrorResponse) {
                if (error.error === 'cannot-send-email') {
                  this.snackBarService.error("Service email credentials cannot verified.");
                } else {
                  this.snackBarService.error(this.translateService.instant("There is an error occured."));
                }
              } else {
                this.snackBarService.error(this.translateService.instant("There is an error occured."));
              }
              customEmailForm.control.enable();
            })
            .finally(() => {
              this.customEmailCredentials = { name: "", host: "", port: 465, user: "", pass: ""/*, test: ""*/ };
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  verifyCustomSystemEmail() {
    const verifyModel = { code: null };
    const modalId = this.modalService.show({
      template: this.verifySystemEmailTemplate,
      context: {
        dataModel: verifyModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          verify: (verifyEmailForm: NgForm) => {
            this.loaderService.show();
            verifyEmailForm.control.disable();

            this.accountService.verifyServiceEmail(verifyEmailForm.value.verificationCode)
            .then(() => {
              this.modalService.hide(modalId);
              this.snackBarService.success('Custom service email enabled.')
            })
            .catch(error => {
              verifyEmailForm.control.enable();
              this.snackBarService.error('Cannot verify custom service email.');
            })
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  onDisableCustomSystemEmail() {
    const modalId = this.modalService.show({
      template: this.disableSystemEmailTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          disable: () => {
            this.loaderService.show();
            this.accountService.disableServiceEmail()
            .then(() => {
              this.modalService.hide(modalId);
              this.snackBarService.success('Custom service email disabled successfully.');
            })
            .catch(error => this.snackBarService.error('Cannot disable custom service email.'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  mapLicence(accountData) {
    this.licenseInfo.license = {
      start: this.datePipe.transform(new Date(accountData.license.remote_start + UtilityService.timezoneOffset), 'mediumDate'),
      end: this.datePipe.transform(new Date(accountData.license.remote_end + UtilityService.timezoneOffset), 'mediumDate'),
      limit: accountData.license.limit === 0 ? this.translateService.instant('MAIN.LICENSE_MODAL.UNLIMITED') : accountData.license.limit,
      limit2: accountData.license.limit2 === 0 ? this.translateService.instant('MAIN.LICENSE_MODAL.UNLIMITED') : accountData.license.limit2,
      expert_concurrent_limit: accountData.license.expert_concurrent_limit === 0 ? this.translateService.instant('MAIN.LICENSE_MODAL.UNLIMITED') : accountData.license.expert_concurrent_limit,
      allow_archiving: accountData.features.archiving ? this.translateService.instant('MAIN.LICENSE_MODAL.ARCHIVE_AVAILABLE') : this.translateService.instant('MAIN.LICENSE_MODAL.ARCHIVE_UNAVAILABLE'),
      trainingLicenses: accountData.training_licenses ? Object.keys(accountData.training_licenses).map(l => accountData.training_licenses[l]) : null
    }
    this.license = Object.entries(this.licenseInfo.license)
  }
}
