import { Component, Input, OnInit, Output, EventEmitter, Renderer2, ViewChild, ElementRef, SimpleChanges, HostListener } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PreviewModalComponent } from 'src/app/components/modals/preview-modal/preview-modal.component';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Step } from '@models/Step';
import { interval, take } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import 'leader-line'
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { GENERIC, TYPEOPTIONS } from '../utils';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowLanguageService } from '@services/workflow-language.service';

declare let LeaderLine: any;

interface ConditionTypeItem {
  label: string;
  key: string;
  type: string;
  options?: any[];
  optionKeys?: any[]
}

@Component({
  selector: 'app-workflow-connections',
  templateUrl: './workflow-connections.component.html',
  styleUrls: ['./workflow-connections.component.scss'],
})
export class WorkflowConnectionsComponent implements OnInit {
  @Input() stepList?;
  @Output() stepListChange: EventEmitter<Step[]> = new EventEmitter<Step[]>();

  @Input() stepAreaPosition?
  @Input('mouseUp') mouseUp?
  @Input('sidebarOpen') sidebarOpen?: boolean
  @Input('sidebar') sidebar
  @Input('navbar') navbar
  @Input() workflowName
  
  @Input() initialStep: string
  @Output() initialStepChange: EventEmitter<string> = new EventEmitter<string>();

  @Input('outOfConnection') outOfConnection
  @Output() changesSaved: EventEmitter<boolean> = new EventEmitter()

  @Output() availableChange: EventEmitter<boolean> = new EventEmitter();
  @Output() stepsAreaPositionChange: EventEmitter<{x: number, y: number}> = new EventEmitter();

  @Output() allFormIsValid: EventEmitter<boolean> = new EventEmitter();

  @Input() saveButtonClicked
  @Output() saveButtonClickedChange: EventEmitter<boolean> = new EventEmitter();

  @Input() pageClosed
  @Output() pageClosedChange: EventEmitter<boolean> = new EventEmitter();

  @Input() connectionEnabled?
  @Output() connectionEnabledChanged: EventEmitter<boolean> = new EventEmitter();

  @Input() zoomInValue
  @Output() zoomInValueChange: EventEmitter<boolean> = new EventEmitter();

  @Input() zoomOutValue
  @Output() zoomOutValueChange: EventEmitter<boolean> = new EventEmitter();

  @Input() fullMode
  @Output() fullModeChange: EventEmitter<boolean> = new EventEmitter();

  @Input() workflowTranslations

  @ViewChild('stepsArea', {static: false}) stepsArea: ElementRef;
  @ViewChild('cursor') cursor: ElementRef;
  @ViewChild('connectionsContainer') connectionsContainer: ElementRef;

  conditionForm:any = new UntypedFormGroup({ connections: new UntypedFormArray([]) });

  notSelected: string = 'not-selected'
  next = new UntypedFormControl(this.notSelected)

  formData
  type
  selectedStep
  grabbing

  itemMap: Record<string, ConditionTypeItem> = {};
  valueTypes = {}

  typeOptions = TYPEOPTIONS
  generic = GENERIC
  logicalOperators = ['AND', 'OR'];

  stepPositionBefore: {x: number, y: number};
  stepsAreaFirstPosition: {x: number, y: number} = {x: -100000, y: -100000}
  sidebarWidth: number
  navbarHeight: number
  stepPaddingWhitUnsetCoordinates = 24

  ifStepsAreaMoved: boolean = false;
  ifStepMoved: boolean = false;
  ifStepMouseDown: boolean = false;
  ifConnectButtonClicked: boolean = false;
  ifStepSelected: boolean = false;
  ifSetInChanges: boolean = false
  
  stepAreaMouseDown: {x: number, y: number}
  mouseDownStep: Step;

  connectionStart: Step = null
  currentConnection: any = null
  currentConnectionHTMLElement: HTMLElement = null
  allConnections: any[] = []
  selectedConnection: any = null

  formValid
  options = []

  typeKeyObject= {}

  scale
  full

  selectedLang

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    let scaleValue = this.scale ? this.scale : 1
    this.renderer.setStyle(this.cursor.nativeElement, 'left', `${(event.clientX / scaleValue )}px`);
    this.renderer.setStyle(this.cursor.nativeElement, 'top', `${event.clientY*(1 + 1-scaleValue) - this.navbarHeight }px`);
    if(this.connectionStart){
      if(this.currentConnection){
        this.currentConnection.position();
      }
    }
  }

  constructor(
    private modalService: BsModalService,
    private renderer: Renderer2,
    public multiLanguageService: MultilanguageService,
    private translateService: TranslateService,
    private workflowLanguageService: WorkflowLanguageService
  ) {
      this.selectedLang = this.workflowLanguageService.currentLang.code
      this.workflowLanguageService.onLangChange.subscribe(res => {
        this.selectedLang = res.lang.code
      })
    }

  ngOnInit(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden')
    this.init()
    this.next.valueChanges.pipe(startWith(null), pairwise()).subscribe(([prev, res])=> {
      if(!this.ifStepSelected){
        if(!this.checkConnection(this.selectedStep.id, res)){
          if(prev !== this.notSelected){
            this.deleteConnection(prev)
          }
          if(res !== this.notSelected){
            const nextStep = this.stepList.find(step => {
              return step.id === res
            })
            this.createConnection(this.selectedStep.id);
            this.connectConnection(nextStep, this.selectedStep);
          }
        } else{
          this.showConnectionError()
          this.ifStepSelected = true
          this.next.patchValue(prev)
          this.ifStepSelected = false
        }
      }
      if(this.conditionForm.controls['connections'].controls.length>0) {
        if(this.next.value && this.next.value !== this.notSelected) {
          this.allFormIsValid.emit(true)
          this.formValid=true
        }else{
          this.next.setErrors({required:true})
          this.allFormIsValid.emit(false)
          this.formValid=false
        }
      }
      
    })
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['saveButtonClicked']) {

      this.selectedConnection = null
      let connections = this.conditionForm.controls['connections'].controls
      let dataCheck = connections.find(res => !res.valid)

      if(dataCheck){
        this.showAlertModal()
        
      }else{
        if(this.selectedStep){
          this.saveConditionData()
          this.selectedStep = null
        }
        this.itemMap = {}
        this.valueTypes = {}
      }
      this.saveButtonClickedChange.emit(true)

    }
    if(changes['pageClosed']) {

      this.selectedConnection = null
      let connections = this.conditionForm.controls['connections'].controls
      let dataCheck = connections.find(res => !res.valid)

      if(!dataCheck){
        if(this.selectedStep){
          this.saveConditionData()
          this.selectedStep = null
          this.pageClosedChange.emit(true)
        }
      }
    }
    if(changes['mouseUp']){
      this.stepsAreaMouseUp(changes['mouseUp'].currentValue);
      this.stepMouseUp();
    }
    if(changes['sidebarOpen']){
      const timer = interval(0.000000001).pipe(take(300));
      timer.subscribe((x) => {
        this.moveConnections()
      })

      setTimeout(() => {
        this.sidebarWidth = this.sidebar.getBoundingClientRect().width;
      }, 300)    
    }
    if(changes['outOfConnection']){
      if(changes['outOfConnection'].currentValue){
        const saved = this.onTabClicked()
        setTimeout(() => {
          this.changesSaved.emit(saved)
        }, 1);
      }
    }
    if(changes['zoomOutValue']){
      if(changes['zoomOutValue']?.currentValue) {
        this.zoomOut()
      }
    }
    if(changes['zoomInValue']){
      if(changes['zoomInValue']?.currentValue) {
        this.zoomIn()
      }
    }
    if(changes['fullMode']){
      if(changes['fullMode']?.currentValue) {
        this.fullModeOn()
      }
    }
  }

  ngOnDestroy(){
    this.allConnections.forEach(connection => {
      connection.button.remove();
      connection.connection.remove();
    });
    this.allConnections = [];
  }

  private init():void {
    let firstStep
    let firstBox
    this.sidebarWidth = this.sidebar.getBoundingClientRect().width;
    this.navbarHeight = this.navbar.getBoundingClientRect().height;
    setTimeout(() => {
      if(this.stepAreaPosition){
        this.renderer.setStyle(this.stepsArea.nativeElement, 'top', `${this.stepAreaPosition.y-this.navbarHeight}px`);
        this.renderer.setStyle(this.stepsArea.nativeElement, 'left', `${this.stepAreaPosition.x}px`);
      }
      if(this.stepList[0]){
        firstStep = document.getElementById(`step${this.stepList[0].id}`);
        firstBox = firstStep.getBoundingClientRect();
      }
      
      this.stepList.forEach(step => {
        if(!step.coordinates || !this.connectionEnabled){
          const selectedStep = document.getElementById(`step${step.id}`);
          const box = selectedStep.getBoundingClientRect();
          
          if(this.connectionEnabled){
          step.coordinates = {x: (firstBox?.left-25)-this.stepsArea.nativeElement.getBoundingClientRect().left+this.stepPaddingWhitUnsetCoordinates, y: box.top-this.stepsArea.nativeElement.getBoundingClientRect().top+this.navbarHeight};
          }else{
            step.coordinates = {x: box.left-this.stepsArea.nativeElement.getBoundingClientRect().left+this.stepPaddingWhitUnsetCoordinates*step.data.order, y: box.top + 200-this.stepsArea.nativeElement.getBoundingClientRect().top+this.navbarHeight+this.stepPaddingWhitUnsetCoordinates};
          }
        }
        this.setStepPosition(step);
      });
      this.stepList.forEach(step => {
        this.connectStep(step)
      })
    }, 100);
  }

  private patchData():void {
    if (this.selectedStep.data.connections?.length) {
      this.selectedStep.data.connections.forEach((conditionSection) => {
        if(conditionSection.hasOwnProperty('conditions')) {
          this.createConditionFormData(conditionSection);
        } else {
          //This is else of all conditions
          if(!conditionSection.next){
            this.next.patchValue(this.notSelected);
          }else{
            this.next.patchValue(conditionSection.next)
          }
        }
      });
    }
  }

  private selectStep(step?):void {
    
    if(this.selectedStep && this.selectedStep !== step || !this.selectedStep){
      this.ifStepSelected = true;
      this.selectedConnection = null
      let connections = this.conditionForm.controls['connections'].controls
      let dataCheck = connections.find(res => !res.valid) || (connections?.[0]?.controls.conditions.value.length>0 && (!this.next.value || this.next.value ==this.notSelected))

      if(dataCheck){
        this.allFormIsValid.emit(false)
        this.formValid=false
        this.showAlertModal()
      }else{
        if(this.selectedStep){
          this.saveConditionData()
        }
        this.itemMap = {}
        this.selectedStep = step;
        let options = []
        let optionKeys = []
        this.selectedStep.data.layouts.forEach((item,index) => {
          options = []
          optionKeys = []
          const mappedItem = {label:item.items[0].templateOptions.label,key:item.items[0].key, type:item.items[0].type,order:index}
          if(item.items[0].type == 'multicheckbox' || item.items[0].type == 'radio') {
            item.items[0].templateOptions.options.forEach((res:any) => {
              options.push(res.label)
              optionKeys.push(res)
            })
            this.typeKeyObject[item.items[0].key] = item.items[0].type
            mappedItem['options'] = options
            mappedItem['optionKeys'] = optionKeys
          }
          this.itemMap[mappedItem.key] = mappedItem
        })
    
        this.conditionForm = new UntypedFormGroup({
          connections: new UntypedFormArray([]),
        });
        this.next.patchValue(this.notSelected)
        this.patchData()
        this.allFormIsValid.emit(true)
        this.formValid = true
      }
      this.ifStepSelected = false
    }      
  }

  changeStatus():void {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.CONTENT-PAGE-WARNING-TEXT'),
      importantTextMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.CONTENT-PAGE-IMPORTANT-WARNING-TEXT'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.WARNING'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.CONFIRM'),
      confirmButtonStyle: "primary"
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    alertmodalRef.content.onClose.subscribe(isConfirm => {
      if(isConfirm) {
        this.connectionEnabledChanged.emit(true)
      }
    })
  }

  private showAlertModal():void {
    let connections = this.conditionForm.controls['connections'].controls
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.INVALID-DATA'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.WARNING')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    alertmodalRef.content.onClose.subscribe(isDelete => {
      if(isDelete) {
        const conditionFormToDeleteIndexes = []
        connections.forEach((conditionForm,index) => {
          if(!conditionForm.valid){
            conditionFormToDeleteIndexes.push(index)
          }
        })
        conditionFormToDeleteIndexes.reverse().forEach(i => {
          this.deleteConditionConection(i)
        })
      }
    })
  }

  createConditionFormData(data?) {
    const conditionSection = new UntypedFormGroup({
      conditions: new UntypedFormArray([]),
      logicalOperator: new UntypedFormControl(data ? data?.logicalOperator : 'AND'),
      next: new UntypedFormControl(data?.next, Validators.required),
    });

    conditionSection.valueChanges.subscribe(res => {
      if(conditionSection.valid && this.next.value && this.next.value !==this.notSelected) {
        this.allFormIsValid.emit(true)
        this.formValid = true
      }else{
        this.allFormIsValid.emit(false)
        this.formValid = false
      }
      if(!this.next.value || this.next.value ==this.notSelected) {
        this.next.setErrors({required:true})
      }

    })
    
    const firstData = conditionSection.controls['next'].value
    conditionSection.controls['next'].valueChanges.pipe(startWith(null) ,pairwise()).subscribe(([prev, res])=> {
      if(!this.ifSetInChanges){
        if(!this.checkConnection(this.selectedStep.id, res)){
          if(prev){
            this.deleteConnection(prev)
          } else if(firstData){
            this.deleteConnection(firstData)
          }
          const nextStep = this.stepList.find(step => {
            return step.id === res
          })
          this.createConnection(this.selectedStep.id);
          this.connectConnection(nextStep, this.selectedStep);
        } else{
          this.showConnectionError()
          this.ifSetInChanges = true
          const valueSet = prev ? prev : firstData ? firstData : null
          conditionSection.controls['next'].setValue(valueSet)
          this.ifSetInChanges = false
        }
      }
    })

    if (data?.conditions?.length > 0) {
      data.conditions.forEach((item) => {
        const condition = this.createConditionFormGroup(item);
        (conditionSection.get('conditions') as UntypedFormArray).push(condition);
      })
    }
    //Start with an empty condition form 
    else {
      const condition = this.createConditionFormGroup();
      (conditionSection.get('conditions') as UntypedFormArray).push(condition);
    }
    (this.conditionForm.get('connections') as UntypedFormArray).push(conditionSection);
  }

  createConditionFormGroup(data?): UntypedFormGroup {
    let value;
    const item = this.itemMap[data?.type]
    if(data?.value && item?.type == 'datepicker'){
      if(data?.condition == 'isBetween' || data?.condition == 'isNotBetween'){
        let dateArray = JSON.parse(data.value)
        value = []
        dateArray.forEach(dateValue => {
          const timestamp = new Date(Number(dateValue)).getTime();
          value.push(new Date(timestamp))
        })
          
      }else{
        if(data?.value){
          const timestamp = new Date(data?.value).getTime();
          value = new Date(timestamp)
        }
      }
    }else{
      if(item?.type =='multicheckbox') {
        let  multicheckboxValueY = item.optionKeys.find(res => res.value == data?.value) ? item.optionKeys.find(res => res.value == data?.value) : item.optionKeys.find(res => this.workflowTranslations[this.selectedLang][res.label] == data?.value)
        if(!multicheckboxValueY) {
          value = ''
        }else{
          value = multicheckboxValueY.label
        }
      }
      else if(item?.type =='radio') {
        let  multicheckboxValueY = item.optionKeys.find(res => res.value == data?.value) ? item.optionKeys.find(res => res.value == data?.value) : item.optionKeys.find(res => this.workflowTranslations[this.selectedLang][res.label] == data?.value)
        if(!multicheckboxValueY) {
          value = ''
        }else{
          value = multicheckboxValueY.label
        }
      }else{
        value = data?.value
      }
    }
    const condition = new UntypedFormGroup({
      type: new UntypedFormControl(item?.key, Validators.required),
      condition: new UntypedFormControl(data?.condition, Validators.required),
      value:  new UntypedFormControl(value),
    });
    return condition
  }

  addLogicalCondition(conditionSectionForm: UntypedFormGroup, index, data?) {
    const condition: UntypedFormGroup = this.createConditionFormGroup()
    condition.controls['type'].valueChanges.subscribe((res) => {
      this.generic[res.type] = [...this.generic[res.type]];
    });
    (conditionSectionForm.get('conditions') as UntypedFormArray).insert(
      index + 1,
      condition
    )
  }

  deleteLogicalCondition(conditionRow,connectionIndex,index) {
    this.conditionForm.controls.connections.controls[connectionIndex].controls.conditions.controls.splice(index,1)
    this.conditionForm.controls.connections.controls[connectionIndex].controls.conditions.value.splice(index,1)
    this.conditionForm.controls.connections.controls[connectionIndex].controls.conditions.updateValueAndValidity()
    this.conditionForm.controls.connections.updateValueAndValidity()
  }

  deleteCondition(conditionIndex) {
    this.conditionForm.controls.connections.controls.splice(conditionIndex, 1)
    this.conditionForm.controls.connections.value.splice(conditionIndex, 1)
    this.conditionForm.controls.connections.updateValueAndValidity()
    this.conditionForm.controls.connections.controls.forEach(connection => {
      if(!connection){
        this.conditionForm.controls.connections.updateValueAndValidity()
      }
    })
      if(this.conditionForm.controls.connections.valid){
        this.allFormIsValid.emit(true)
        this.formValid = true
      }
  }

  private saveConditionData(step?):void {
    const allConditionSections = [...this.conditionForm.value.connections]
    allConditionSections.forEach((conditionSection,connectionIndex)=> {
      conditionSection.order=connectionIndex
      if(conditionSection && conditionSection.conditions?.length){
        conditionSection.conditions.forEach((condition,conditionIndex) => {
          if(this.itemMap[condition.type].type == 'datepicker' && condition.value){
            if(condition.condition == 'isBetween' || condition.condition == 'isNotBetween'){
              let dateValueArray = []
              condition.value.forEach((dateValue,index) => {
                let d = new Date(dateValue);
                const day = d.getDate()
                const year = d.getFullYear()
                const month = d.getMonth()
                const utcTimestamp = Date.UTC(year, month, day, 0, 0, 0, 0)
                dateValueArray.push(utcTimestamp)
              })
              condition.value = JSON.stringify(dateValueArray)
            }else{
              let d = new Date(condition.value);
              const day = d.getDate()
              const year = d.getFullYear()
              const month = d.getMonth()
              const utcTimestamp = Date.UTC(year, month, day, 0, 0, 0, 0)
              condition.value = utcTimestamp
            }
          }
          if(this.itemMap[condition.type].type == 'multicheckbox' && condition.value){
            let item = this.itemMap[condition.type].optionKeys.find(res => res.label == condition.value)
            condition.value = item ? item.value : ''
          }
          if(this.itemMap[condition.type].type == 'radio' && condition.value){
            let item = this.itemMap[condition.type].optionKeys.find(res => res.label == condition.value)
            condition.value = item ? item.value : ''
          }
          condition.type = this.itemMap[condition.type].key
          condition.order = conditionIndex
        })
      }
    });
    //this is else of all conditions
    if(this.next.value !== this.notSelected){
      const elseOption = { next: this.next.value }
      allConditionSections.push(elseOption)
    }
    this.selectedStep.data['connections'] = allConditionSections
    this.stepListChange.emit(this.stepList)
  }

  openPreview(step):void {
    let stepData
    if(this.workflowTranslations) {
      stepData = JSON.parse(JSON.stringify(step))
      stepData.data.name = this.workflowTranslations[this.selectedLang][step.data.name]

      stepData?.data?.layouts.forEach((res,index)=> {
          stepData.data.layouts[index].items[0].templateOptions.label = this.workflowTranslations[this.selectedLang][step.data.layouts[index].items[0].templateOptions.label]
          if(stepData.data.layouts[index].items[0].templateOptions.placeholder || stepData.data.layouts[index].items[0].templateOptions.placeholder == '') {
            stepData.data.layouts[index].items[0].templateOptions.placeholder = this.workflowTranslations[this.selectedLang][step.data.layouts[index].items[0].templateOptions.placeholder]
          }
          if(stepData.data.layouts[index].items[0].templateOptions.options) {
            stepData.data.layouts[index].items[0].templateOptions['translationAvailable'] = this.workflowTranslations ? true : null
            stepData.data.layouts[index].items[0].templateOptions.options = step.data.layouts[index].items[0].templateOptions.options.map(option => {return {label:this.workflowTranslations[this.selectedLang][option.label], value: option.value}})
          }
          if(stepData.data.layouts[index].items[0].templateOptions.html || stepData.data.layouts[index].items[0].templateOptions.html == '') {
            stepData.data.layouts[index].items[0].templateOptions.html = this.workflowTranslations[this.selectedLang][step.data.layouts[index].items[0].templateOptions.html]
          }
        })
    }else{
      stepData = step
    }
    const initialState = {
      stepData: stepData.data,
      template: step.data.template,
      stepListCount: this.stepList.length,
      workflowName: this.workflowName,
      workflowTranslations: this.workflowTranslations,
      selectedStepOrder: step.data.order + 1
    }
    this.modalService.show(PreviewModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false,
    })
  }

  stepsAreaMoved(event: CdkDragMove<any>):void {
    this.ifStepsAreaMoved = true;
    this.moveConnections()
  }

  stepsAreaMouseDown(event: any):void {
    this.stepAreaMouseDown = {x: event.x, y: event.y}
    this.grabbing = false
  }

  private stepsAreaMouseUp(event: any):void {
    if(this.ifStepsAreaMoved){
      this.stepsAreaPositionChange.emit({x: this.stepsArea.nativeElement.getBoundingClientRect().left-this.sidebarWidth, y: this.stepsArea.nativeElement.getBoundingClientRect().top})
      this.stepList.forEach(step => {
        step.coordinates.x += (event.clientX - this.stepAreaMouseDown.x);
        step.coordinates.y += (event.clientY - this.stepAreaMouseDown.y);
      });
      this.availableChange.emit(true)
    }
    this.ifStepsAreaMoved = false;
    this.grabbing = true
  }

  stepsAreaClicked(event: any):void {
    if(event.target === this.stepsArea.nativeElement && this.connectionStart && this.currentConnection){
        this.currentConnection.remove()
        this.connectionStart = null
        this.currentConnection = null
        this.currentConnectionHTMLElement = null
    }
  }

  stepMoved(event: CdkDragMove<Step>, movedStep: Step):void {
    this.ifStepMoved = true;
    movedStep.coordinates = {x: event.pointerPosition.x-this.stepPositionBefore.x-this.sidebarWidth, y: event.pointerPosition.y-this.stepPositionBefore.y}
    this.moveConnections()
  }

  stepMouseDown(event: any, step: Step):void {
    const box: DOMRect = document.getElementById(`step${step.id}`)!.getBoundingClientRect();
    this.stepPositionBefore = {x: event.clientX-box.left, y: event.clientY-box.top}
    this.ifStepMouseDown = true;
    this.mouseDownStep = step;
  }

  private stepMouseUp():void {
    if(this.ifStepMoved){
      this.availableChange.emit();
    } else if(!this.ifConnectButtonClicked && this.ifStepMouseDown){
      this.selectStep(this.mouseDownStep)
    }
    this.ifStepMoved = false;
    this.ifStepMouseDown = false;
    this.mouseDownStep = null;
    this.ifConnectButtonClicked = false;
  }

  private setStepPosition(step: Step):void {
    const selectedStep = document.getElementById(`step${step.id}`);
    const box = selectedStep.getBoundingClientRect();
    this.renderer.setStyle(selectedStep, 'top', `${step.coordinates.y-this.stepsArea.nativeElement.getBoundingClientRect().top}px`);
    this.renderer.setStyle(selectedStep, 'left', `${step.coordinates.x-this.stepsArea.nativeElement.getBoundingClientRect().left-step.data.order*box.width+this.sidebarWidth}px`);
  }

  changeInitialStep (step: Step) {
    let connectedStepToInitialStep = []
    this.stepList.forEach(stepData => {
      if(stepData.data?.connections){
        let connectedStep = stepData.data?.connections.find(resp => resp.next == step.id)
        if(connectedStep) {
          connectedStepToInitialStep.push(connectedStep)
        }
      }
    })
    if(connectedStepToInitialStep.length) {
      this.showConnectedInitialStepWarning()
    }else{
      this.initialStepChange.emit(step.id)
    }
  }

  private showConnectedInitialStepWarning():void {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.INITIAL-STEP-CHANGE-WARNING-TEXT'),
      confirmButtonText : this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
      confirmButtonStyle: 'primary'
    };
    this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
  }

  startConnection(step: Step){
    if(!this.connectionStart){
      if(step !== this.selectedStep){
        let connections = this.conditionForm.controls['connections'].controls
        let dataCheck = connections.find(res => {
          return !res.valid
        })
        if(dataCheck){
          this.showAlertModal()
          return
        }
      }
      this.connectionStart = step;
      this.createConnection(step.id)
    }
  }

  endConnection(step: Step){
    if(this.connectionStart && this.currentConnection){
      if(this.connectionStart !== step){
        if(this.checkConnection(this.connectionStart.id, step.id)){
          this.showConnectionError()

          this.currentConnection.remove()
          this.connectionStart = null
          this.currentConnection = null
          this.currentConnectionHTMLElement = null
          return
        }
        this.connectConnection(step, this.connectionStart);
        this.selectStep(this.connectionStart)
        if(this.next.value === this.notSelected){
          this.ifStepSelected = true
          this.next.patchValue(step.id)
          this.ifStepSelected = false
        } else{
          this.createConditionFormData({next: step.id})
        }
        this.connectionStart = null;
      }
    } else {
      let i;
      const brokenConnection = this.allConnections.slice().reverse().find((connection, index) => {
        if(connection.endId === step.id){
          i = this.allConnections.length - 1 - index;
          return connection;
        }
      })

      const startConnection = this.stepList.find(startStep => {
        return startStep.id === brokenConnection.startId;
      })
      this.selectStep(startConnection)
      
      const conditionIndex = this.findConnection(step.id)
      if(conditionIndex === -1){
        this.ifStepSelected = true
        this.next.patchValue(this.notSelected)
        this.ifStepSelected = false
      } else{
        this.deleteCondition(conditionIndex)
      }

      this.connectionStart = startConnection
      brokenConnection.button.remove();
      this.allConnections.splice(i, 1)

      if(this.connectionStart.data.connections){
        const connectionEnd = this.connectionStart.data.connections.find((startingConnection, index) => {
          if(startingConnection.next === brokenConnection.endId){
            i = index
            return startingConnection
          }
          return null
        })

        if(connectionEnd){
          this.connectionStart.data.connections.splice(i, 1)
        }
      }

      this.currentConnection = brokenConnection.connection
      this.currentConnectionHTMLElement = document.getElementById(`line${brokenConnection.startId}-${brokenConnection.endId}`)
      this.currentConnection.end = this.cursor.nativeElement
    }
    this.availableChange.emit(true);
  }

  private createConnection(id: string,scale?):void {
    const line = new LeaderLine(
      document.getElementById(`step${id}`),
      this.cursor.nativeElement,
      {color: '#003DA6', path: 'fluid', size: 1.5, /* endPlug: 'arrow2', */ endPlugSize: 1.5}
    );
    line.setOptions({startSocket: 'right', endSocket: 'left'});

    const lineElements = document.querySelectorAll('.leader-line') as NodeListOf<HTMLElement>;
    const lineElement = lineElements[lineElements.length-1]
    this.renderer.setStyle(lineElement, 'z-index', 500);
    this.renderer.setStyle(lineElement, 'zoom', this.scale);
    
    this.currentConnection = line;
    this.currentConnectionHTMLElement = lineElement
  }

  private connectConnection(step: Step, startStep: Step):void {
    this.currentConnection.end = document.getElementById(`step${step.id}`);

    this.renderer.setAttribute(this.currentConnectionHTMLElement, 'id', `line${startStep.id}-${step.id}`)

    const connectionButton = this.createConnectionButton()
    this.positionConnectionButton(connectionButton, startStep.id, step.id)
    connectionButton.addEventListener('click', this.selectConnection.bind(this, {start: startStep, end: step, connection: this.currentConnection, button: connectionButton, index: this.allConnections.length}))
    
    this.allConnections.push({startId: startStep.id, endId: step.id, connection: this.currentConnection, button: connectionButton})
    this.currentConnection = null;
    this.currentConnectionHTMLElement = null
  }

  private moveConnections():void {
    this.allConnections.forEach(movedConnection => {
      movedConnection.connection.position()
      this.positionConnectionButton(movedConnection.button, movedConnection.startId, movedConnection.endId)
    })
  }

  removeConnection(removedConnection: any, index: number){
    removedConnection.button.remove()
    this.allConnections.splice(index, 1)

    const startStep = this.stepList.find(step => {
      return step.id === removedConnection.startId;
    })

    let i;
    if(startStep.data.connections){
      const connectionEnd = startStep.data.connections.find((connection, x) => {
        if(connection.next === removedConnection.endId){
          i = x
          return connection
        }
      })

      if(connectionEnd){
        startStep.data.connections.splice(i, 1)
      }
    }
    removedConnection.connection.remove()
    this.availableChange.emit(true);
    this.selectedConnection = null;
  }

  private createConnectionButton(){
    const connectionButton = this.renderer.createElement('button')
    //this.renderer.setAttribute(connectionButton, 'class', 'btn btn-connect btn-circle btn-sm');
    this.renderer.appendChild(document.body, connectionButton);
    return connectionButton;
  }

  private positionConnectionButton(connectionButton: any, startStepId: string, endStepId: string):void {
    const connectionPosition = document.getElementById(`line${startStepId}-${endStepId}`).getBoundingClientRect()

    //set in btn-connect class, in scss file
    const connectionButtonHeight = 7
    const connectionButtonWidth = 7
    this.renderer.setStyle(connectionButton, 'top', `${(connectionPosition.top+connectionPosition.bottom-connectionButtonHeight)/2}px`/* +window.scrollY */)
    this.renderer.setStyle(connectionButton, 'left', `${(connectionPosition.right+connectionPosition.left-connectionButtonWidth)/2}px`/* +window.scrollX */)
  }

  private connectStep(step: Step){
    if(step.data.connections){
      step.data.connections.forEach(connection => {
        if(connection.next){
          const connectedStep: Step = this.stepList.find(foundStep => {
            return foundStep.id === connection.next
          })
          this.createConnection(step.id);
          this.connectConnection(connectedStep, step);
        }
        
      })
    }
  }

  private selectConnection(connection: any):void {
    this.selectedConnection = connection
    this.selectedStep = null
  }

  findConnection(stepId: string): number{
    const index = this.conditionForm.controls['connections'].controls.findIndex(condition => {
      return condition.controls.next.value === stepId
    })
    return index
  }

  deleteConditionConection(index: number):void {
    this.deleteConnection(this.conditionForm.controls.connections.value[index].next)
    this.deleteCondition(index)
    if(this.conditionForm.controls.connections.value.length<=1) {
      this.next.setErrors({required:false})
    }
    
  }

  private deleteConnection(endId: string){
    let index
    const connectionToRemove = this.allConnections.find((connection, i) => {
      if(connection.endId === endId && connection.startId === this.selectedStep.id){
        index = i
        return connection
      }
    })

    if(connectionToRemove){
      this.removeConnection(connectionToRemove, index)
    }
  }

  private checkConnection(startId: string, endId: string): boolean {
    const foundConnection = this.allConnections.find(connection => {
      return connection.startId === startId && connection.endId === endId;
    })
    return foundConnection ? true : false
  }

  private showConnectionError():void {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.ALREADY-CONNECTED'),
      cancelButtonText : this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.CONFIRM'),
    };
    this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
  }

  private onTabClicked(): boolean {
    if(this.selectedStep){
      let connections = this.conditionForm.controls['connections'].controls
      let dataCheck = connections.find(res => !res.valid) || (connections?.[0]?.controls.conditions.value.length>0 && (!this.next.value || this.next.value ==this.notSelected))

      if(dataCheck){
        this.showAlertModal()
        return false
      }
      this.saveConditionData()
    }
    return true
  }

  center() {
    let firstStep
    let firstBox
    this.sidebarWidth = this.sidebar.getBoundingClientRect().width;
    this.navbarHeight = this.navbar.getBoundingClientRect().height;
    this.allConnections[0].startId
    this.allConnections[0].endId

    const connectionPosition = document.getElementById(`line${this.allConnections[0].startId}-${this.allConnections[0].endId}`).getBoundingClientRect()
    if(this.stepAreaPosition){
        this.renderer.setStyle(this.stepsArea.nativeElement, 'top', `${-100000 - connectionPosition.top}px`);
        this.renderer.setStyle(this.stepsArea.nativeElement, 'left', `${connectionPosition.left}px`);
      }
      
    this.stepList.forEach(step => {
      this.connectStep(step)
    })
  }

  zoomOut() {
    if(this.selectedStep){
      let connections = this.conditionForm.controls['connections'].controls
      let dataCheck = connections.find(res => !res.valid)

      if(dataCheck){
        this.showAlertModal()
      }
      this.saveConditionData()
      this.selectStep(this.selectedStep)
    }
    this.scale = !this.scale ? 1 : this.scale

    if(this.scale > 0.8) {
      this.scale -= 0.1
      this.allConnections.forEach(connection => {
        connection.button.remove();
         connection.connection.remove();
      });
      this.allConnections = []
      this.stepList.forEach(step => {
        
        this.connectStep(step)
      })
    }
    const timer = interval(0.000000001).pipe(take(200));
    timer.subscribe((x) => {
      this.moveConnections()
    })
    setTimeout(() => {
      this.sidebarWidth = this.sidebar.getBoundingClientRect().width;
    }, 300) 
    
    this.zoomOutValueChange.emit(false)
     
  }

  zoomIn() {
    if(this.selectedStep){
      let connections = this.conditionForm.controls['connections'].controls
      let dataCheck = connections.find(res => !res.valid)

      if(dataCheck){
        this.showAlertModal()
      }
      this.saveConditionData()
      this.selectStep(this.selectedStep)
    }

    if(this.scale < 1 && this.scale) {
      
      this.scale += 0.1
      this.allConnections.forEach(connection => {
        connection.button.remove();
         connection.connection.remove();
      });
      this.allConnections = []
      this.stepList.forEach(step => {
        this.connectStep(step)
      })
    }else{
      
    }
    const timer = interval(0.000000001).pipe(take(200));
    timer.subscribe((x) => {
      this.moveConnections()
    })

    setTimeout(() => {
      this.sidebarWidth = this.sidebar.getBoundingClientRect().width;
    }, 300) 
    this.zoomInValueChange.emit(false)
  }

  fullModeOn() {
    this.full = true
  }
}
