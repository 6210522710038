<div class="guest-wrapper-background"></div>

<div class="badge-card" style="padding-inline: 10px;" [ngClass]="{'d-none': !showDownloadApp}">
    <div class="card-body px-5 px-md-5 py-1 h-100">
        <div class="d-flex flex-column h-100" style="justify-content: space-between;">
            <div class="d-flex flex-column align-items-center justify-content-center mt-3">
                <input type="text" class="form-control guest fs-14" style="opacity:0 ;" [formControl]="publicLink" #guestLink>
                <img style="height: 48px;" src="assets/img/workflow-logo.svg" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center">
                <p class="fs-14 text-center">{{ 'MAIN.CONTENT.EASY-ACCESS.OPEN-APP' | translate }}</p>
                <div class="d-flex align-items-center">
                    <div class="custom-control custom-checkbox custom-checkbox-green">
                        <input type="checkbox" class="form-check-input copy-permission-checkbox" [formControl]="copyPermission">
                    </div>
                    <div>
                        <label class="fs-12" for="">{{ 'MAIN.CONTENT.EASY-ACCESS.COPY-LINK-TEXT' | translate }}</label>
                    </div>
                </div>    
                <a class="btn btn-primary mt-3 d-flex align-items-center justify-content-center download-button" [href]="downloadAppButtonUrl" (click)="clickStoreLink(guestLink)">
                    {{ 'MAIN.CONTENT.EASY-ACCESS.DOWNLOAD-APP' | translate }}
                </a>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.EASY-ACCESS.OR' | translate }}</div>
                <div class="mt-3">
                    <a class="continue-website-link mt-3" href="#" (click)="showDownloadApp = false" target="_blank" rel="noopener">{{ 'MAIN.CONTENT.EASY-ACCESS.CONTINUE-WITH-BROWSER' | translate }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center pb-4">
                <img style="height: 36px;" src="assets/img/logo-horizontal.svg" alt="">
            </div>
        </div>
    </div>
</div>
<div class="guest-wrapper">
    <div class="card align-items-center">
        <div class="mt-5">
            <img style="height: 48px;" src="assets/img/logo.svg" alt="">
        </div>
        <p class="fs-14 font-weight-600 mt-4">{{ 'MAIN.CONTENT.EASY-ACCESS.EASY-ACCESS-TITLE' | translate:('design' | env) }}</p>
        <p class="fs-14 font-weight 500 mb-1 text-center">{{ 'MAIN.CONTENT.EASY-ACCESS.OPEN-WORKFLOW-NAME' | translate }}<span class="fs-14 font-weight-600 ms-1">{{workflowName}}</span></p>
        <p><img src="assets/img/company-icon.svg" alt=""><span class="fs-14 font-weight-500 ms-1">{{companyName}}</span></p>
        <input type="text" class="form-control fs-14" placeholder="{{ 'MAIN.CONTENT.EASY-ACCESS.ENTER-EMAIL' | translate }}" [formControl]= "username" autocomplete="off">
        <p class="validation-text mb-0 mt-1 w-100 ps-2" *ngIf="username.errors?.['required'] && username?.touched">{{ 'MAIN.CONTENT.EASY-ACCESS.REQUIRED' | translate }}</p>
        <div class="w-100">
        </div>
        <input type="password" class="form-control fs-14 mt-2" placeholder="{{ 'MAIN.CONTENT.EASY-ACCESS.ENTER-WORKFLOW-PASSWORD' | translate }}" [formControl]= "password" *ngIf="isPasswordRequired" autocomplete="off">

        <button class="v-btn btn btn-primary fs-12 font-weight-600 text-white open-button mt-3 mb-4" (click)= "openWorkflow()" [disabled]="username.errors?.['required']">{{ 'MAIN.CONTENT.EASY-ACCESS.OPEN' | translate }}</button>
    </div>
    <div class="addition-part mt-3 d-flex justify-content-center">
        <div class="language-dropdown" dropdown #dropdown="bs-dropdown">
            <div id="language-toggle" class="language-link d-flex flex-row align-items-center cursor-pointer" dropdownToggle aria-controls="language-menu">
                <span class="name fs-14 font-weight-500 lang ms-2 color-blue-text">{{ currentLang.code | uppercase }}</span>
                <i id="dropdown-icon" class="mi mi-keyboard-arrow-down lang color-blue-text"></i>
            </div>
            <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                <ng-container *ngFor="let lang of allLangs">
                    <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                        <a class="dropdown-item language-link px-2 fs-14 font-weight-500" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <!-- <div class="fs-12 font-weight-500 ms-3 me-5 text-center">By clicking Open, you agree to our 
            <span class="text-blue font-weight-600">Terms of Service</span> and 
            <span class="text-blue font-weight-600">Privacy Policy</span>
        </div> -->

    </div>
</div>