import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowService } from '@services/workflow.service';
import { first, Subscription } from 'rxjs';
import * as bowser from 'bowser';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-guest-login-with-link',
  templateUrl: './guest-login-with-link.component.html',
  styleUrls: ['./guest-login-with-link.component.scss']
})
export class GuestLoginWithLinkComponent implements OnInit, OnDestroy {

  username = new UntypedFormControl('', Validators.required)
  password = new UntypedFormControl()

  publicLink = new UntypedFormControl('', Validators.required)
  copyPermission = new UntypedFormControl('', Validators.required)

  isPasswordRequired: boolean = false
  workflowName: string
  companyName: string

  linkId
  currentLang
  allLangs
  subscriptions: Subscription[] = []

  browser: bowser.Parser.Parser
  showDownloadApp = false

  downloadAppButtonUrl = ""

  constructor(private route: ActivatedRoute,
    private workflowService: WorkflowService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private router: Router,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService
  ) {
    

    /*this.route.queryParams.subscribe(params => {
      if (params['store']) {
        if (this.browser.is('iOS') || (this.browser.is("macOS") && isTouchDevice)) {
          window.location.href = environment.design.appStoreLink
        }
        if (this.browser.is('android')) {
          window.location.href = environment.design.googlePlayLink + '&referrer=utm-source%3Dvsight%26utm_medium%3Dguest%26utm_content%3Dwid-' + this.linkId
        }
      } else {
        const urlTree = this.router.createUrlTree([], { queryParams: { store: true }, queryParamsHandling: "merge" })
        this.downloadAppButtonUrl = window.location.origin + urlTree.toString()
      }
    })*/
  }

  ngOnInit(): void {
    let fullUrl
    this.route.url.subscribe(url => {
      fullUrl = url.join('/');
    })
    this.browser = bowser.getParser(window.navigator.userAgent)
    const isTouchDevice = 'ontouchstart' in window;
    if ((this.browser.is('mobile') || this.browser.is('tablet') || (this.browser.is("macOS") && isTouchDevice )) && (fullUrl !== 'videoflow')) {
      this.showDownloadApp = true
    }
    if (this.browser.is('iOS') || (this.browser.is("macOS") && isTouchDevice)) {
      this.downloadAppButtonUrl = environment.design.appStoreLink
    } else {
      this.downloadAppButtonUrl = environment.design.googlePlayLink + this.linkId
    }
    
    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.subscriptions.push(this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    })) 
    this.loaderService.show()
    this.initialize()
  }

  initialize() {
    this.route.queryParams.pipe(first()).toPromise().then(param => {
      this.linkId = param['id']
      //Check the link to be set
      const isLinkAvailable = environment.design.guestLoginLinkAvailable
      const base = `${environment.endPoints.workflowBase}/share?${this.linkId}`
      isLinkAvailable ? this.publicLink.patchValue(base) : this.publicLink.patchValue(window.location.href)

      return this.workflowService.workflowQrGetData(param['id'])
    })
    .then(qrData => {
      this.isPasswordRequired = qrData.data.passwordRequired
      this.workflowName = qrData.data.workflowName
      this.companyName = qrData.data.companyName

      if (qrData.data.user) {
        this.username.patchValue(qrData.data.user.name)
        this.username.disable()
      }

      if (qrData.data.type === "openWorkflowWithoutPublicAccess") {
        this.username.patchValue("Guest User")
        this.username.disable()

        setTimeout(() => { this.openWorkflow() }, 500)
      }
    })
    .catch(error => {
      if(error.error.message == 'missing-parameters') {
        this.snackBarService.error(this.translateService.instant('LOGIN.LINK-ID-MISSING'))
      }
      if(error.error.message == 'workflow-not-found') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-NOT-FOUND'))
      }
      if(error.error.message == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => this.loaderService.hide())
  }

  openWorkflow() {
    this.loaderService.show()
    let email = this.username.value
    let password = this.password.value
    this.workflowService.workflowQrGetCredentials(this.linkId, email, password).then(res => {
      if(res) {
        this.authService.loginToFirebase(res.data.token).then(res=>res)

          this.router.navigate(['guest-workflow'], {state: { publicWorkflow:res.data.workflow, data: res.data}})
      }
    })
    .catch(error => {
      if(error.error.message == 'missing-parameters') {
        this.snackBarService.error(this.translateService.instant('LOGIN.ID-OR-NAME-MISSING'))
      }
      if(error.error.message == 'wrong-password') {
        this.snackBarService.error(this.translateService.instant('LOGIN.GUEST-PASSWORD-WRONG'))
      }
      if(error.error.message == 'workflow-not-found') {
        this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-NOT-FOUND'))
      }
      if(error.error.message == 'internal-error') {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => this.loaderService.hide())
  }

  onLanguageSelected(lang: any) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  clickStoreLink(link) {
    if (this.copyPermission.value) {
      link.select()
      document.execCommand('copy');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

}
