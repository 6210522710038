import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { UserService } from "@services/user.service";
import { map, Observable } from "rxjs";

export class PasswordRequirements {
  static createValidator(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return userService.checkPasswordRequirements(control.value).pipe(
        map((result: boolean) => result ? { passwordRequirements: true } : null)
      )
    }
  }
}