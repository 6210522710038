import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pdf-widget',
  templateUrl: './pdf-widget.component.html',
  styleUrls: ['./pdf-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PdfWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PdfWidgetComponent),
      multi: true,
    },
  ],
})
export class PdfWidgetComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() item //type tanımla

  subscriptions : Subscription[] = []

  pdfControl = new UntypedFormControl()
  pdfPage = 1
  totalPages
  isUpload = true


  constructor() { }
  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.pdfControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.pdfControl.valid ? null : this.pdfControl.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
  }

  pageChanged(event) {
    this.pdfPage = event
  }
  pdfNext() {
    this.pdfPage +=  1
  }
  pdfPrevious() {
    if(this.pdfPage !==1) {
      this.pdfPage -=  1
    }
  }

  pageSearch() {
    //this.pdfPage = this.formData.pdfPage.value
  }

  afterLoadComplete(pdf): void {
    this.isUpload = false
    this.totalPages = pdf.numPages;
  }

}
