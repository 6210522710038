import { Component, OnInit, Input } from '@angular/core';
import { User } from '@models/User';
import { Subscription } from 'rxjs';
import { LogFile } from '@models/LogFile';
import { Session } from '@models/Session';
import { FileShareService } from '@services/support/file-share.service';
import { first } from 'rxjs/operators';
import { SessionService } from '@services/remote/session.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-session-file',
  templateUrl: './session-file.component.html',
  styleUrls: ['./session-file.component.scss']
})

export class SessionFileComponent implements OnInit {

    @Input('session') session: Session;
    @Input('session_id') session_id: string;

    @Input() showCheckboxes?: boolean;
    @Input() downloadSelectedFiles?: boolean;

    currentUser: User = null;

    logFiles: LogFile[] = [];
    filesAndObjects: any[] = [];
    chatexport: any;

    private logFilesSub: Subscription = null;
    private logObjectsSub: Subscription = null;

    selectedFiles: any[] = [];


    constructor(
        private sessionService: SessionService,
        private fileShareService: FileShareService,
        private snackBarService: SnackbarService,
        private translateService: TranslateService,
        private loaderService: LoaderService
    ) { }

    onDownloadFile(file: any) {
      this.snackBarService.success(this.translateService.instant('MAIN.SESSIONS.SESSION_FILES.DOWNLOAD_STARTED_SOON'));
        if (file.extension === 'obj') {
            //@ts-ignore
            const files = file.files;
            for (let f of files) {
                this.fileShareService.downloadFile(f.url, f.name)
                    .catch(error => {this.snackBarService.error(this.translateService.instant('MAIN.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED')) });
            }
        } else if (file.exportPdf) {
        this.fileShareService.getSessionExportPDF(file.room_id, file.session_id)
        .catch(error => { this.snackBarService.error(this.translateService.instant('MAIN.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED')) });
        }
        else {
        this.fileShareService.downloadFile(file.url, file.name)
          .catch(error => {this.snackBarService.error(this.translateService.instant('MAIN.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED')) });
        }
    }

    ngOnInit() {
        this.logFilesSub = this.sessionService.getSessionFiles(this.session).pipe(first()).subscribe(files => {
            files.forEach(file => {
                if (file.file_type === 'image' || file.file_type === 'screenshot') {
                    this.filesAndObjects.push({name: file.name, size: file.size, src: file.url});
                } else if (file.file_type === 'pdf') {
                    this.filesAndObjects.push({name: file.name, size: file.size, src: 'assets/img/pdf.png'});
                } else if (file.file_type === 'other') {
                    this.filesAndObjects.push({name: file.name, size: file.size, src: 'assets/img/other.png'});
                }
                this.logFiles.push(file);
            });
        });

        this.logObjectsSub = this.sessionService.getSessionObjects(this.session).pipe(first()).subscribe(objects => {
            objects.forEach(object => {
                let sum = 0;
                let subfiles = [];
                //@ts-ignore
                object.files.forEach(file => { 
                    sum = sum + file.size;
                    subfiles.push(file.name);
                });
                this.filesAndObjects.push({name: object.name, size: sum, src: 'assets/img/obj.png', subfiles: subfiles});
                this.logFiles.push(object);
            });
        });
        this.chatexport = {
            name: "export.pdf",
            size: 0,
            src: "assets/img/pdf.png",
            exportPdf: true,
            room_id: this.session.room_id,
            session_id: this.session_id
          };
          
          this.filesAndObjects.push(this.chatexport);
          this.logFiles.push(this.chatexport);
    }

    onFileCheckboxChange(file: any) {
        if (file === this.chatexport) {
          if (file.isChecked) {
            this.selectedFiles.push(file);
          } else {
            const index = this.selectedFiles.findIndex(selectedFile => selectedFile === file);
            if (index !== -1) {
              this.selectedFiles.splice(index, 1);
            }
          }
        } else {
          if (file.isChecked) {
            this.selectedFiles.push(file);
          } else {
            const index = this.selectedFiles.findIndex(selectedFile => selectedFile.id === file.id);
            if (index !== -1) {
              this.selectedFiles.splice(index, 1);
            }
          }
        }
      }
      
        resetCheckboxSelections() {
          for (const file of this.selectedFiles) {
            file.isChecked = false;
          }
          this.selectedFiles = []
        }
      
        async onDownloadSelectedFiles() {
        this.loaderService.show();
      
        if (this.selectedFiles.length > 0) {
          await this.fileShareService.downloadSelectedFilesAsZip(this.selectedFiles, 'selectedFiles.zip')
          .then(() => {  this.snackBarService.success(this.translateService.instant('MAIN.LOBBY.SESSIONS.SESSION_FILES.SUCCESS_MESSAGE'))  })
          .catch(error => { this.snackBarService.success(this.translateService.instant('MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED')) })
          .finally(() => this.loaderService.hide());
        }
      }

    ngOnDestroy() {
        if (this.logFilesSub) { this.logFilesSub.unsubscribe() }
        if (this.logObjectsSub) { this.logObjectsSub.unsubscribe() }
    }
}