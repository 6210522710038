<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MODALS.WORKFLOW-NEW-LANGUAGE.ADD-LANGUAGE' | translate | titlecase }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <div  class="fs-14 ps-4 pe-4 text-center">
                {{ 'MODALS.WORKFLOW-NEW-LANGUAGE.NEW-LANGUAGE' | translate }}
            </div>
        </div>
        <div class="d-flex mb-5 top-gap-30 justify-content-center">
            <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary font-weight-600 me-1"(click)="close()">
                {{ 'MAIN.CONTENT.DASHBOARD.CANCEL' | translate | titlecase }}
            </button>
            <button type="button" class="v-btn btn vsight-button left btn-primary create-button me-1" (click)="addNewLanguage(0)">
                {{ 'MODALS.WORKFLOW-NEW-LANGUAGE.AUTO-TRANSLATE' | translate | titlecase }}
            </button>
            <button type="button" class="v-btn btn vsight-button btn-primary create-button" (click)="addNewLanguage(1)">
                {{ 'MODALS.WORKFLOW-NEW-LANGUAGE.COPY-CONTENT' | translate }}
            </button>
        </div>
    </div>
</div>