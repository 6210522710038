<div class="collaboration-container" #collaborationTutorial="tutorial" tutorial="collaboration" [ngClass]="{'small': size === 'small' && !isFullscreen }" (mouseup)="onDocumentMouseUp($event)" (mousedown)="onDocumentMouseDown($event)" (mousemove)="onDocumentMouseMove($event)"
    (mouseleave)="onDocumentMouseLeave($event)" (mouseenter)="onDocumentMouseEnter($event)" (touchstart)="onDocumentTouchStart($event)" (touchmove)="onDocumentTouchMove($event)" (touchend)="onDocumentTouchEnd($event)">

    <app-image-viewer [controlInput]="controlInput" [collaborationData]="frame.collaborationData" (img)="onImage($event)"></app-image-viewer>
    <app-image-annotation-canvas [ngClass]="{'d-none': !annotating}" [controlInput]="controlInput" (canvas)="onCanvas($event)"></app-image-annotation-canvas>

    <div class="controls" [ngClass]="{'show': !hide, 'd-none': size === 'small'}">

        <nav class="navbar" #navbar [ngClass]="{'d-none': external }" (mouseleave)="onDocumentMouseLeaveControl($event)" (mouseenter)="onDocumentMouseEnterControl($event)" #noCloseCollTutorial="tutorial" tutorial="noCloseColl">
            <div class="annotate-controls" *ngIf="annotating && controlsOpen">
                <button type="button" class="btn undo" (click)="undo()" data-placement="bottom" [dynamicTooltip]="'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.UNDO' | translate" [tooltipDisabled]="external"><i class="mi mi-undo"></i></button>
                <button type="button" class="btn redo" (click)="redo()" data-placement="bottom" [dynamicTooltip]="'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.REDO' | translate" [tooltipDisabled]="external"><i class="mi mi-redo"></i></button>
            </div>
            <div class="container-fluid">
                <!-- REMOTE_CHANGED
                    <button *ngIf="!isFullscreen" class="close-collaboration btn" (click)="endCollaboration()" title="Close"><i class="mi mi-close"></i></button>
                -->
            </div>
        </nav>

        <div class="left-col" *ngIf="controlsOpen">
            <div class="scale" #scale (mouseleave)="onDocumentMouseLeaveControl($event)" (mouseenter)="onDocumentMouseEnterControl($event)">
                <button type="button" class="btn btn-refresh" (click)="refresh()" data-placement="right" [dynamicTooltip]="'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.REVERT' | translate" [tooltipDisabled]="external"><i class="mi mi-replay"></i></button>
                <button type="button" class="btn" (click)="zoomIn()" title="{{ 'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.ZOOM-IN' | translate }}"><i class="mi mi-zoom-in"></i></button>
                <button type="button" class="btn" (click)="zoomOut()" title="{{ 'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.ZOOM-OUT' | translate }}"><i class="mi mi-zoom-out"></i></button>
            </div>
        </div>

        <div class="right-col" *ngIf="controlsOpen">
            <div class="direction" #direction (mouseleave)="onDocumentMouseLeaveControl($event)" (mouseenter)="onDocumentMouseEnterControl($event)">
                <div class="up-direction">
                    <button type="button" class="btn" (click)="moveUp()" title="{{ 'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.UP' | translate }}"><i class="mi mi-expand-less"></i></button>
                </div>
                <div class="middle-direction">
                    <button type="button" class="btn left-dir" title="{{ 'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.LEFT' | translate }}" (click)="moveLeft()"><i class="mi mi-navigate-before"></i></button>
                    <button type="button" class="btn right-dir" title="{{ 'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.RIGHT' | translate }}" (click)="moveRight()"><i class="mi mi-navigate-next"></i></button>
                </div>
                <div class="bottom-direction">
                    <button type="button" class="btn" title="{{ 'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.DOWN' | translate }}" (click)="moveDown()"><i class="mi mi-expand-more"></i></button>
                </div>
            </div>
        </div>

    </div>


    <div *ngIf="!external" class="annotationTools" [ngClass]="{'show': annotating, 'd-none': size === 'small'}">

        <div class="colors" [ngClass]="{'showColor': showColorsDiv && annotating}">

            <button type="button" [ngStyle]="color=='white'?{'opacity': 1.0} : {'opacity': '0.9'}" [ngClass]="{'d-none': !annotating}" class="btn colorWhite" (click)="changeColor('white')"></button>

            <button type="button" [ngStyle]="color=='yellow'?{'opacity': 1.0} : {'opacity': '0.9'}" [ngClass]="{'d-none': !annotating}" class="btn colorYellow" (click)="changeColor('yellow')"></button>

            <button type="button" [ngStyle]="color=='green'?{'opacity': 1.0} : {'opacity': '0.9'}" [ngClass]="{'d-none': !annotating}" class="btn colorGreen" (click)="changeColor('green')"></button>

            <button type="button" [ngStyle]="color=='red'?{'opacity': 1.0} : {'opacity': '0.9'}" [ngClass]="{'d-none': !annotating}" class="btn colorRed" (click)="changeColor('red')"></button>

        </div>

        <div class="weights" [ngClass]="{'showWeight': showWeightsDiv && annotating}">

            <button type="button" [ngStyle]="weight=='thin'?{'opacity': 1.0} : {'opacity': '0.4'}" [ngClass]="{'d-none': !annotating}" class="btn weightThin" (click)="changeWeight('thin')">
                <div class="divThin" [style.background-color]="weight_color"></div>
            </button>

            <button type="button" [ngStyle]="weight=='middle'?{'opacity': 1.0} : {'opacity': '0.4'}" [ngClass]="{'d-none': !annotating}" class="btn weightMiddle" (click)="changeWeight('middle')">
                <div class="divMiddle" [style.background-color]="weight_color"></div>
            </button>

            <button type="button" [ngStyle]="weight=='thick'?{'opacity': 1.0} : {'opacity': '0.4'}" [ngClass]="{'d-none': !annotating}" class="btn weightThick" (click)="changeWeight('thick')">                <div></div>
                <div class="divThick" [style.background-color]="weight_color" ></div>
            </button>

            <button type="button" [ngStyle]="weight=='verythick'?{'opacity': 1.0} : {'opacity': '0.4'}" [ngClass]="{'d-none': !annotating}" class="btn weightVeryThick" (click)="changeWeight('verythick')">
                <div class="divVeryThick" [style.background-color]="weight_color"></div>
            </button>

        </div>

        <div class="shapes" [ngClass]="{'showShapes': showShapesDiv && annotating}">

            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn rectangle" (click)="changeShape('rectangle')"><i class="mi mi-crop-square"></i></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn circle" (click)="changeShape('ellipse')"><i class="mi mi-panorama-fish-eye"></i></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn arrow" (click)="changeShape('arrow')"><i class="mi mi-arrow-forward"></i></button>

        </div>

        <div class="main_widgets" [ngClass]="{'showWidgets': showWidgetsDiv && annotating}">
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn leftArrow" (click)="changeWidget('leftArrow')"><img class="spinLeft" src="assets/widgets/leftArrow.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn rightArrow" (click)="changeWidget('rightArrow')"><img class="spinRight" src="assets/widgets/rightArrow.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn warning" (click)="changeWidget('warning')"><img src="assets/widgets/warning.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn touch" (click)="changeWidget('touch')"><img src="assets/widgets/touch.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn cold" (click)="changeWidget('cold')"><img src="assets/widgets/cold.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn electric" (click)="changeWidget('electric')"><img src="assets/widgets/electric.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn explosive" (click)="changeWidget('explosive')"><img src="assets/widgets/explosive.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn fire" (click)="changeWidget('fire')"><img src="assets/widgets/fire.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn press" (click)="changeWidget('press')"><img src="assets/widgets/press.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn skull" (click)="changeWidget('skull')"><img src="assets/widgets/skull.png"/></button>
            <button type="button" [ngClass]="{'d-none': !annotating}" class="btn radiation" (click)="changeWidget('radiation')"><img src="assets/widgets/radiation.png"/></button>
        </div>

        <button type="button" [ngStyle]="onPen?{'background-color': borderColor} : {'background-color': 'rgba(0, 0, 0, 0.6)'}" class="btn pen" (click)="onPenClick()"><i [ngStyle]="color=='white' && onPen ?{'color': 'black'} : {'color': 'white'}" class="mi mi-edit"></i></button>
        <button type="button" [ngStyle]="onMouse?{'background-color': borderColor} : {'background-color': 'rgba(0, 0, 0, 0.6)'}" class="btn mouse" (click)="onMouseClick()"><i [ngStyle]="color=='white' && onMouse ?{'color': 'black'} : {'color': 'white'}" class="mi mi-open-with"></i></button>
        <button type="button" class="btn lineWeight" (click)="onLineWeightClick()"><i class="mi mi-line-weight"></i></button>
        <button type="button" class="btn lineColor" (click)="onColorClick()"><i [style.color]="borderColor" class="mi mi-color-lens"></i></button>
        <button type="button" [ngStyle]="onText?{'background-color': borderColor} : {'background-color': 'rgba(0, 0, 0, 0.6)'}" class="btn text" (click)="onTextClick()"><i [ngStyle]="color=='white' && onText ?{'color': 'black'} : {'color': 'white'}" class="mi mi-title"></i></button>
        <button type="button" [ngStyle]="onShape?{'background-color': borderColor} : {'background-color': 'rgba(0, 0, 0, 0.6)'}" class="btn shape" (click)="onShapeClick()"><i [ngStyle]="color=='white' && onShape ?{'color': 'black'} : {'color': 'white'}" class="mi mi-widgets"></i></button>
        <button type="button" [ngStyle]="onWidget?{'background-color': borderColor} : {'background-color': 'rgba(0, 0, 0, 0.6)'}" class="btn widget" (click)="onWidgetClick()"><i [ngStyle]="color=='white' && onWidget ?{'color': 'black'} : {'color': 'white'}" class="mi mi-warning"></i></button>
        <button type="button" [ngStyle]="onCrop?{'background-color': borderColor} : {'background-color': 'rgba(0, 0, 0, 0.6)'}" class="btn crop" (click)="onCropClicked()"><i [ngStyle]="color=='white' && onCrop ?{'color': 'black'} : {'color': 'white'}" class="mi mi-crop"></i></button>

    </div>

    <button *ngIf="controlsOpen && !external" class="bottom-button annotate btn" [ngClass]="{'annotating': annotating}" data-placement="top" #annotateTutorial="tutorial" tutorial="annotate" (change)="$event ? annotatePopover.show() : annotatePopover.hide()"
        #annotatePopover="bs-popover" popover="Open annotation" triggers="" placement="top" container="body" containerClass="tutorial-popover" (click)="annotate(); annotateTutorial.next({collaboration: true, noCloseColl: true, draw: true})" [dynamicTooltip]="'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.ANNOTATE' | translate"><i class="mi mi-gesture"></i></button>

    <div class="ar-dots">
        <img [src]="'assets/arplus/'+arDot.shape+'-'+arDot.color+'.svg'" class="ar-dot" *ngFor="let arDot of arDots;" [ngStyle]="{left: arDot.left, top: arDot.top}">
    </div>

    <div class="ar-names" [class.small]="size === 'small' && !isFullscreen">
        <div class="ar-name" *ngFor="let arDot of arDots;" [style.color]="'#'+arDot.colorCode">{{ arDot.name }}</div>
    </div>

    <div *ngIf=" !imageCollaborationService.isLoaded " class="loader-wrapper d-flex justify-content-center align-items-center" style="height: 100%;">
        <div class="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="loading-users rounded" *ngIf="notSucceededUsers.length > 0" [ngClass]="{'small': size === 'small' && !isFullscreen, 'controls-open': controlsOpen}">
        <div class="loading-user" style="margin-top: 2px; margin-bottom: 2px; text-shadow: 0px 0px 3px #000000;" *ngFor="let notSucceededUser of notSucceededUsers;">
            {{ notSucceededUser.name }} {{ notSucceededUser.state }}
        </div>
    </div>

    <div style="position: absolute; bottom: 0; left: 50%;" #drawTutorial="tutorial" tutorial="draw" (change)="$event ? drawPopover.show() : drawPopover.hide()" #drawPopover="bs-popover" [popover]="drawTmp" triggers="" placement="top" container="body" containerClass="tutorial-popover"></div>
</div>

<ng-template #endCollTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.END_COLL_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.END_COLL_MESSAGE' | translate }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                    {{ 'APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.END_COLL_NO' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                    {{ 'APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.END_COLL_YES' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #drawTmp>
    <div>{{ 'APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.SKETCH_COLLABORATION_PANE' | translate }}</div>
    <a (click)="drawTutorial.next({showTutorial: true})">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.NEXT' | translate }}</a>
</ng-template>