import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'htmlToPlaintext'
})
export class HtmlToPlaintextPipe implements PipeTransform {
    transform(value: any): string {
        var strippedHtml = value.replace(/<[^>]+>/g, '  ');
        return strippedHtml || '';
    }
}