import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  userWorkflowisOpen: Observable<boolean>;
  public userWorkflowisOpenSource = new BehaviorSubject<boolean>(false);

  reportisOpen: Observable<boolean>;
  public reportisOpenSource = new BehaviorSubject<boolean>(false);

  isEditorMode: Observable<boolean>;
  public isEditorModeSource = new BehaviorSubject<boolean>((sessionStorage.getItem('isEditorMode')) ? JSON.parse(sessionStorage.getItem('isEditorMode')) : true);

  constructor() { 
    this.userWorkflowisOpen = this.userWorkflowisOpenSource.asObservable()
    this.reportisOpen = this.reportisOpenSource.asObservable()
    this.isEditorMode = this.isEditorModeSource.asObservable()
  }
}
