<div class="modal-dialog-alert" role="document" style="min-width: 750px;">
    <div class="modal-content v-card card modal-card" style="position:relative">
        <div class="card-header modal-card-header fs-18">
            <div class="fs-16 font-weight-600" *ngIf="title">{{ title }}
            </div>
            <div *ngIf="!title" class="fs-16 font-weight-600">{{action == 'clone' ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.CLONE-STEP' | translate) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.MOVE-STEP' | translate) }}
                    <img src="assets/img/info.svg" class="ml-2" [dynamicTooltip]="action == 'clone' ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.CLONE-TEXT-MESSAGE' | translate) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MOVE-TEXT-MESSAGE' | translate)"  placement="right">
            </div>
            <div class="close-btn" (click)="modalRef.hide()">
                <img src="assets/img/close.svg" alt="">
            </div>
        </div>
            <div class="modal-content">
                      <div class="card-body pe-4 ps-3 filter-area">
                        <div class="d-flex justify-content-start" style="padding-top: 2px;">
                            <div class="search-box">
                                <input type="text" [formControl]="filter" placeholder="Search" class="filter-input fs-14">
                                <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                            </div>
                         
                        </div>
                      </div>
                    <div class="card list-area">
                        <div class="d-flex flex-column">
                            <ng-container *ngFor="let workflow of filteredWorkflows">

                            <div class="d-flex justify-content-between align-items-center" style="padding: 18px 40px 18px 40px;border-bottom: solid 1px #eff1f5;" *ngIf="(workflowId && workflow.published?.['listName']) || !workflowId">
                                    <div>
                                        <div class="fs-14 font-weight-500">{{ workflow.published?.['listName'] }}</div>
                                        <div class="fs-14 font-weight-500" *ngIf="!workflow.published?.name">{{ workflow.draft?.['listName'] }}</div>
                                    </div>
                                    <div>
                                        <button *ngIf="!workflowId"  class="v-btn btn btn-secondary-outline fs-16 font-weight-500" (click)="selectWorkflow(workflow)">{{'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.SELECT' | translate}}</button>
                                        <button *ngIf="workflowId"  class="v-btn btn btn-secondary-outline fs-16 font-weight-500" (click)="selectWorkflowToConnect(workflow)">{{'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.SELECT' | translate}}</button>
                                    </div>
                            </div>
                        </ng-container>

                        </div>
                    </div>
            </div>
    </div>
</div>
