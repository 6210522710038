import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgxSignatureOptions, NgxSignaturePadComponent } from '@eve-sama/ngx-signature-pad';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { base64ToFile } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signature-pad-modal',
  templateUrl: './signature-pad-modal.component.html',
  styleUrls: ['./signature-pad-modal.component.scss']
})
export class SignaturePadModalComponent implements OnInit {
  onClose: EventEmitter<any> = new EventEmitter();
  signed: EventEmitter<any> = new EventEmitter();

  private image = new Image();

  @Input() linkId
  @Input() signer

  matchedSigner

  @ViewChild('signature') signature: NgxSignaturePadComponent;
  @ViewChild('canvas', { static: false }) public canvas: ElementRef;

  file
  subscriptions: Subscription[] = []

  tabs: boolean[] = [true, false, false];

  signerName: UntypedFormControl = new UntypedFormControl('', Validators.required)
  signatureText: UntypedFormControl = new UntypedFormControl('')

  signatureImage
  signatureFile

  context

  constructor(
    public modalRef: BsModalRef,
    private workflowService: WorkflowService,
    private loaderService: LoaderService) {}

  get isEmpty(): boolean {
    if (this.signature) {
      return this.signature.isEmpty();
    }
    return true;
  }

  public options: NgxSignatureOptions = {
    backgroundColor: '#FFF',
    // width: '100%',
    height: 140,
    css: {
      'border-radius': '10px',
      'touch-action': 'none',
      'border-bottom-left-radius': '0px',
      'border-bottom-right-radius': '0',
      'background-color': '#fff',
      'width': '100%'
    }
  };

  ngOnInit(): void {
    this.image.src = 'https://file.qingflow.com/uploads/file/e28cf1bd-f701-4fbb-8aff-942a80013df9.png';
  }

  public clear(): void {
    this.signature.clear();
  }

  clearSignatureText():void {
    this.signatureText.patchValue(null)
  }

  public drawImage(): void {
    this.signature.getContext().drawImage(this.image, 230, 35, 100, 50, 230, 110, 100, 50);
    this.signature.setDirty();
  }

  public revert(): void {
    this.signature.revert();
  }

  public save(type?: 'image/jpeg' | 'image/svg+xml'): void {
    let base64: string;
    switch (type) {
      case 'image/jpeg':
        base64 = this.signature.toDataURL('image/jpeg');
        break;
      case 'image/svg+xml':
        base64 = this.signature.toDataURL('image/svg+xml');
        break;
      default:
        base64 = this.signature.toDataURL();
    }
    const a = document.createElement('a');
    const event = new MouseEvent('click');
    a.download = 'demo'; // set file name
    a.href = base64; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  }

  public setDirty(): void {
    this.signature.setDirty();
  }

  public setEmpty(): void {
    this.signature.setEmpty();
  }

  sign() {
    this.loaderService.show()
    let formData = new FormData();
    if(this.tabs[2]) {
      formData.append(this.signerName.value,this.signatureFile)
    }
    else if(this.tabs[1]) {
      // this.canvas.nativeElement.width = 800
      // this.canvas.nativeElement.height = 700

      let ctx = this.canvas.nativeElement.getContext('2d');
      ctx.fillStyle = '#FFF';
      ctx.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      ctx.fillStyle = '#000';
      ctx.font = 'italic 36px SignPainter';

      let centerX = this.canvas.nativeElement.width / 2;
      let centerY = this.canvas.nativeElement.height / 2;

      ctx.fillText(this.signatureText.value, centerX, centerY);

      let files =  base64ToFile(this.canvas.nativeElement.toDataURL('image/jpeg'))
      let file = new File([files], 'signature')

      formData.append(this.signerName.value,file)

    }else{
      this.options.backgroundColor ='#fff'
      this.signature['smallPad'].backgroundColor = '#fff'
      let files =  base64ToFile(this.signature.toDataURL('image/jpeg'))
      let file = new File([files], 'signature')
      formData.append(this.signerName.value,file)
    }
    this.workflowService.signWorkflowReport(formData, this.linkId).subscribe(res => {
      if(res) {
        let matchSigner = res['data'].signature.signers.find(signerInfo => signerInfo.email == this.signer.email)
        if(matchSigner) {
          this.matchedSigner = matchSigner
          this.signed.emit(true)
        }
      }else{
        this.loaderService.hide()
      }
    })
  }


  close() {
    this.modalRef.hide()
  }

  changeTab(index: number){
    this.tabs.forEach((tab, i) => {
      if (index == i) {
        this.tabs[i] = true;
      } else {
        this.tabs[i] = false;
      }
    });
  }

  uploadSignature(event) {
    this.signatureFile = event.target.files[0]
    this.getBase64(event.target.files[0]).then(res => {
      this.signatureImage = res
    })

  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  deleteSignature() {
    this.signatureImage = null
    this.signatureFile = null
  }
  
  
}
