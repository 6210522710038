import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WorkOrder } from '@models/WorkOrder';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import * as bowser from 'bowser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, pairwise, Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';

@Component({
  selector: 'app-work-order-detail',
  templateUrl: './work-order-detail.component.html',
  styleUrls: ['./work-order-detail.component.scss']
})
export class WorkOrderDetailComponent implements OnInit, OnChanges {

  @Input() selectedWorkOrder : WorkOrder
  @Input() mode: string
  @Input() isWebClient?: boolean
  @Output() selectedWorkOrderChange: EventEmitter<any> = new EventEmitter()
  @Output() deletedWorkOrder: EventEmitter<any> = new EventEmitter()
  @Output() workOrderUpdated: EventEmitter<any> = new EventEmitter()
  @Output() workOrderCreated: EventEmitter<any> = new EventEmitter()

  priorityOptions = ['high', 'medium', 'low']
  selectedPriority = 'high'

  statusOptions = ['open', 'in-progress', 'on-hold', 'done']
  selectedStatus = 'open'

  recurrenceOptions = ['none', 'daily', 'weekly', 'monthly']
  selectedRecurrence = 'none'
  viewMode = true

  browser
  isMobile = false
  userList = []
  filteredUserList = []

  expertList = []

  dueTime = new FormControl()
  userFilter= new FormControl()
  selectedUser
  currentUser

  selectedExpert

  workflowFilter = new FormControl()

  showDueTime = false

  allLabels  = [];
  loading = false;

  allWorkflows = []
  filteredWorkflowList = []
  selectedWorkflows = []

  isUpload = false

  selectedFiles = []

  subscriptions : Subscription[] = []
  workOrderId

  activities = []
  isDownloading = false

  changes = {}

  tabIndex = 0

  workOrderForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    status: new FormControl('open'),
    priority: new FormControl('low'),
    dueDate: new FormControl(''),
    recurrence: new FormControl('none'),
    description: new FormControl(''),
    assignedWorkflows: new FormControl([]),
    assignedTo: new FormControl(''),
    assignedExpert: new FormControl(''),
    files: new FormControl([]),
    labels: new FormControl([]),
  })

  constructor(
    private workflowService: WorkflowService,
    public loaderService: LoaderService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private dbService: DbService,
    private http: HttpClient) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.['selectedWorkOrder']?.currentValue) {
      this.selectedWorkOrder = changes?.['selectedWorkOrder'].currentValue
      this.mode = 'update'

      if(this.mode !== 'create') {
        this.subscriptions.push(this.workOrderForm.valueChanges.pipe(pairwise()).subscribe(([prev, formRes]) => {
          if(formRes.name !==this.selectedWorkOrder.name) { this.changes['name'] = formRes.name }
          if(formRes.description !==this.selectedWorkOrder.description) { this.changes['description'] = formRes.description }
          if(formRes.status !==this.selectedWorkOrder.status) { this.changes['status'] = formRes.status }
          if(formRes.priority !==this.selectedWorkOrder.priority) { this.changes['priority'] = formRes.priority }
          if(formRes.assignedTo !==this.selectedWorkOrder.assignedTo) { this.changes['assignedTo'] = formRes.assignedTo }
          if(formRes.assignedExpert !==this.selectedWorkOrder.assignedExpert) { this.changes['assignedExpert'] = formRes.assignedExpert }
          // if(formRes.dueDate !==this.selectedWorkOrder.dueDate) { this.changes['dueDate'] = formRes.dueDate }
          if(formRes.recurrence !==this.selectedWorkOrder.recurrence) { this.changes['recurrence'] = formRes.recurrence }
          if(formRes.assignedWorkflows !==this.selectedWorkOrder.assignedWorkflows) { this.changes['assignedWorkflows'] = formRes.assignedWorkflows }
          // if(formRes.files !==this.selectedWorkOrder.files) { this.changes['files'] = formRes.files }
          if(formRes.labels !==this.selectedWorkOrder.labels) { this.changes['labels'] = formRes.labels }
        }))
      }
    }
  }

  ngOnInit(): void {
    this.authService.user.subscribe(res => {
      this.currentUser = res
    })
    this.browser = bowser.getParser(window.navigator.userAgent);

    if(this.browser.is('mobile')) {
      this.isMobile = true
    }
    if(this.mode === 'create') {
      this.workOrderId = this.dbService.createPushId()
      this.viewMode = false
    }else{
      this.workOrderId = this.selectedWorkOrder.id
    }

    if(this.isWebClient) {
      this.workflowService.getUsers().then(res => {
        if(res) {
          let mappedUsers = res.users.map(user => ({
            id: user.id,
            name: user.name,
          }))
          this.userList = mappedUsers
          this.filteredUserList = this.userList
        }
      })

    }else{
      this.workflowService.getAllUsersForWorkflow().then(resp => {
        if(resp) {
          let expertMappedUsers = resp.users.filter(user => user?.role && user.role === 'expert').map(user => ({
            id: user.id,
            name: user.name,
            username: user.auth.username
          }))
          this.expertList = expertMappedUsers
  
          let mappedUsers = resp.users.map(user => ({
            id: user.id,
            name: user.name,
            username: user.auth.username
          }))
          this.userList = mappedUsers
          this.filteredUserList = this.userList
        }
  
        if(this.assignedTo?.value) {
          this.selectedUser = this.getUsersName(this.assignedTo.value)
        }
  
        if(this.assignedExpert?.value) {
          this.selectedExpert = this.getUsersName(this.assignedExpert.value)
        }
      })
    }

    if(this.selectedWorkOrder) {
      // this.patchFormValues(this.selectedWorkOrder)
      let date
      if(this.selectedWorkOrder.dueDate) {
        date = this.mapDueDate(this.selectedWorkOrder.dueDate)
      }

      this.workOrderForm.patchValue({
        name: this.selectedWorkOrder.name,
        status: this.selectedWorkOrder.status,
        priority: this.selectedWorkOrder.priority,
        dueDate: date ? date : this.selectedWorkOrder.dueDate,
        recurrence: this.selectedWorkOrder.recurrence,
        description: this.selectedWorkOrder.description,
        assignedWorkflows: this.selectedWorkOrder.assignedWorkflows,
        assignedTo: this.selectedWorkOrder.assignedTo,
        assignedExpert: this.selectedWorkOrder.assignedExpert,
        files: this.selectedWorkOrder.files,
        labels: this.selectedWorkOrder.labels
      });

      this.selectedFiles = this.selectedWorkOrder.files ? this.selectedWorkOrder.files : []
    }

    this.workflowService.getWorkOrderLabels().then(workflowLabels => {
      this.allLabels = workflowLabels
    })

    this.getAllWorkflows()

    if(this.mode !== 'create') {
      this.subscriptions.push(this.workOrderForm.valueChanges.subscribe(formRes => {
        if(formRes.name !==this.selectedWorkOrder.name) { this.changes['name'] = formRes.name }
        if(formRes.description !==this.selectedWorkOrder.description) { this.changes['description'] = formRes.description }
        if(formRes.status !==this.selectedWorkOrder.status) { this.changes['status'] = formRes.status }
        if(formRes.priority !==this.selectedWorkOrder.priority) { this.changes['priority'] = formRes.priority }
        if(formRes.assignedTo !==this.selectedWorkOrder.assignedTo) { this.changes['assignedTo'] = formRes.assignedTo }
        if(formRes.assignedExpert !==this.selectedWorkOrder.assignedExpert) { this.changes['assignedExpert'] = formRes.assignedExpert }
        // if(formRes.dueDate !==this.selectedWorkOrder.dueDate) { this.changes['dueDate'] = formRes.dueDate }
        if(formRes.recurrence !==this.selectedWorkOrder.recurrence) { this.changes['recurrence'] = formRes.recurrence }
        if(formRes.assignedWorkflows !==this.selectedWorkOrder.assignedWorkflows) { this.changes['assignedWorkflows'] = formRes.assignedWorkflows }
        // if(formRes.files !==this.selectedWorkOrder.files) { this.changes['files'] = formRes.files }
        if(formRes.labels !==this.selectedWorkOrder.labels) { this.changes['labels'] = formRes.labels }
      }))
    }


    this.userFilter.valueChanges.subscribe(res => {
      if(res) {
        this.filteredUserList = this.userList.filter(user => user.name && user.name.toLowerCase().includes(res.toLowerCase()))
      }else{
        this.filteredUserList = this.userList
      }
    })

    this.workflowFilter.valueChanges.subscribe(res => {
      if(res) {
        this.filteredWorkflowList = this. allWorkflows.filter(wf => wf.published?.listName && wf.published.listName.toLowerCase().includes(res.toLowerCase()))
      }else{
        this.filteredWorkflowList = this. allWorkflows
      }
    })

  }

  get status() {
    return this.workOrderForm.controls['status']
  }

  get priority() {
    return this.workOrderForm.controls['priority']
  }

  get recurrence() {
    return this.workOrderForm.controls['recurrence']
  }

  get assignedTo() {
    return this.workOrderForm.controls['assignedTo']
  }

  get assignedExpert() {
    return this.workOrderForm.controls['assignedExpert']
  }

  get assignedWorkflows() {
    return this.workOrderForm.controls['assignedWorkflows']
  }

  get dueDate() {
    return this.workOrderForm.controls['dueDate']
  }

  get files() {
    return this.workOrderForm.controls['files']
  }

  changePriority(priority) {
    this.priority.patchValue(priority)
  }

  changeStatus(status) {
    this.status.patchValue(status)
  }

  changeRecurrence(recurrence) {
    this.recurrence.patchValue(recurrence)
  }

  changeDate(date) {
    const timestamp = new Date(Number(date)).getTime();

    let d = new Date(date);
    const day = d.getDate()
    const year = d.getFullYear()
    const month = d.getMonth()
    const utcTimestamp = Date.UTC(year, month, day, 0, 0, 0, 0)
    const ozge = utcTimestamp
    return timestamp
  }

  editWorkOrder() {
    this.viewMode = false
  }

  saveChanges() {
    this.viewMode = true
    const date = this.changeDate(this.dueDate.value)
    this.dueDate.patchValue(date)
    this.selectedWorkOrder = {id: this.selectedWorkOrder.id, ...this.workOrderForm.value}
    const changes = this.changes
    this.workOrderUpdated.emit(changes)
    this.selectedWorkOrderChange.emit(this.selectedWorkOrder)
  }

  back() {
    this.selectedWorkOrderChange.emit(null)
  }

  deleteFileFromWorkOrder(index, control) {
    const initialState= {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.DELETE-FILE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-FILE'),
      instantClose:false
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.deleteItem(index, control)
        confirmModalRef.hide()
      }
    }))
  }

  deleteWorkflowFromWorkOrder(index, control) {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORK-ORDERS.WARNING.UNASSIGN-WORKFLOW-TEXT'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORK-ORDERS.WARNING.UNASSIGN-WORKFLOW-TITLE'),
      instantClose:false
    }
    let workflowModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(workflowModalRef.content.onClose.subscribe((res) => {
      this.deleteItem(index, control)
      workflowModalRef.hide();
    }))
  }

  deleteItem(index, control) {
    this.loaderService.show()
    let items = this.workOrderForm.controls[control].value
    items.splice(index, 1)
    this.workOrderForm.controls[control].patchValue(items)
    this.loaderService.hide()
  }

  createWorkOrder() {
    this.loaderService.show()
    const date = this.changeDate(this.dueDate.value)
    this.dueDate.patchValue(date)
    const data = this.workOrderForm.value
    this.workflowService.createWorkOrder({id: this.workOrderId, ...data}).then(res => {
      if(res) {
        this.mode = null
        this.viewMode = true
        const workOrderData = {id: res.workorder.id, ...data}
        this.workOrderCreated.emit(workOrderData)
      }
    })
    .catch(err => {})
    .finally(() => this.loaderService.hide())
    
  }

  deleteWorkOrder() {
    this.deletedWorkOrder.emit(true)
  }

  selectUser(user) {
    this.assignedTo.patchValue(user.id)
    this.selectedUser = user.name
  }

  getUsersName(uid) {
    const user = this.userList.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }

  addDueTime() {
    this.showDueTime = true
  }

  clearDueTime() {
    this.showDueTime = false
  }

  mapDueTime(dateTimeString) {
    let date = new Date(dateTimeString)

    let hours = date.getHours()
    let minutes = date.getMinutes()

    let minutesString = minutes < 10 ? '0' + minutes : minutes.toString()
    let timeString: string = hours + ':' + minutesString
  }

  mapDueDate(dueDate) {
    const dateStr = dueDate;
    const date = new Date(dateStr);
    // const timestamp = Math.floor(date.getTime() / 1000);
    this.dueDate.patchValue(date)
    return date
  }

  addTagPromise(name) {
    this.loading = true
    return this.workflowService.setWorkOrderLabels([...this.allLabels, name]).then(() => {
      this.allLabels.push(name);
      this.loading = false
      return name;
    })
  }

  getAllWorkflows() {
    this.workflowService.getAllWorkflowsWithId().then(res => {
      const workflowList = res.workflowList
      const publishedWorkflowList = workflowList.filter(wf => wf['published'])
      this.allWorkflows = publishedWorkflowList
      this.filteredWorkflowList = publishedWorkflowList
    })
  }

  selectWorkflow(workflow) {
    if(this.selectedWorkflows.includes(workflow.id)) {
      let workflowIndex = this.selectedWorkflows.findIndex(res => res == workflow.id)
      this.selectedWorkflows.splice(workflowIndex, 1)
    }else{
      this.selectedWorkflows.push(workflow.id)
    }
    this.assignedWorkflows.patchValue(this.selectedWorkflows)
  }

  selectExpert(expert) {
    this.assignedExpert.patchValue(expert.id)
    this.selectedExpert = expert.name
  }

  getWorkflowNameById(wfId) {
    const wf = this.allWorkflows.find(wf => wf.id == wfId)
    if(wf?.published?.listName) {
      return wf.published.listName
    }
  }

  uploadedFilesChange(event) {
    if(event) {
      this.isUpload = false
      let image
        image = []
        event.forEach(imageObject => {
          //name,mimetype, url
          this.selectedFiles.push({name:imageObject.fieldname,url:imageObject.url, mimeType: imageObject.mimetype})
        })
        // this.cdr.detectChanges();

        this.files.patchValue(this.selectedFiles)
        this.changes['files'] = this.files.value
    }
  }

  downloadWorkOrderFile(file) {
    this.isDownloading = true
    if(file.url) {
      this.downloadFile(file.url).subscribe({
        next: (data: Blob) => {
          const blob = new Blob([data], { type: file.type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          link.download = file.name;
          link.click();
          window.URL.revokeObjectURL(link.href);
          this.isDownloading = false
        },
        error: (e) => {
          this.isDownloading = false
        }
      })
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  onTabClicked(index) {
    this.tabIndex = index
  }

}
