import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { LoaderService } from '@services/support/loader.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuestAuthGuard implements CanActivate {

  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // this.loaderService.show()
      // Return observable of <auhtenticated or not>
      return this.authService.guestForGuard.pipe(
        map(response => {
          this.loaderService.hide()
          if (!response) {
            return false
          } else {
            return true
          }
        })
      )
  }
  
}
