import { Component, ContentChild, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DbService } from '@services/db.service';
import { AppService } from '@services/support/app.service';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';
import languagesJson from '../../../../../assets/languages.json';
import * as bowser from 'bowser';
import { UserDefaultContentLanguageModalComponent } from 'src/app/components/modals/user-default-content-language-modal/user-default-content-language-modal.component';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';


@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {
  @Input() workflowList
  @Input() workflowId
  @Input() publicWorkflow?;

  @ContentChild('searchInput') searchInput: ElementRef;

  folders = []
  allLabels = []

  showWorkflow: boolean = false
  boxHeight = 410;
  boxWidth = 700;
  workflow : any;

  isSubmitted = false;
  isAdmin: boolean = false
  hasEditorAccess: boolean = false
  isEwers = environment.design.continueWithQr

  savedDraft = false
  savedPrefilled
  workflowMenuClicked

  results
  reportId
  report

  isStart
  subscriptions: Subscription[] = []
  wfName
  isMobile
  browser
  
  allFolders

  stepFilesClicked = false
  isStepFileAvailable = false

  order
  publishDateOrder
  filteredWorkflowList = []

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private dbService: DbService,
    public loaderService: LoaderService,
    private workflowService: WorkflowService,
    private modalService: BsModalService,
    private appService: AppService,
    private router: Router,
  ) {
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin
      this.hasEditorAccess = user.editorAccess
    }))
  }

  ngOnInit(): void {
    this.browser = bowser.getParser(window.navigator.userAgent);
      if(this.browser.is('mobile')) {
        this.isMobile = true
        this.boxHeight = null;
        this.boxWidth = null;
      }
      if(this.workflowId) {
        this.selectSingleWorkflow(this.publicWorkflow)
      }
    if(this.workflowList.folders) {
      this.folders = this.workflowList.folders
      this.workflowList.folders.forEach(folder => {
        let folderWorkflows = this.workflowList.workflowList.filter(res => folder.workflows.includes(res.id))
        folder['workflowList'] = folderWorkflows
        folder['openStatus'] = false
      })
      this.filteredWorkflowList = JSON.parse(JSON.stringify(this.workflowList))
      this.allFolders = JSON.parse(JSON.stringify(this.folders))
      this.workflowList.workflowList.forEach(wf => {
        if(wf.labels && wf.labels.length) {
          wf.labels.forEach(label => {
            this.allLabels.push(label)
          })
        }
      })
    }
  }

  onAllWorkflows() {
    this.appService.isEditorModeSource.next(true)
    this.router.navigate(['/']);
  }

  onSubmittedWorkflows() {
    this.router.navigate(['/user-reports']);
  }

  changeFolderStatus(folder) {
    folder['openStatus'] = !folder['openStatus']
  }

  exportPublishedWorkflows() {
    this.dbService.exportPublishedWorkflows()
    .then(errorCode => {
      if (errorCode) {
        if (errorCode === 'choose-canceled') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.EXPORT-CANCELED'))
        } else if (errorCode === 'export-folder-exists') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.EXPORT-FOLDER'))
        } else if (errorCode === 'unknown-error') {
          this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
        }
      } else {
        this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.EXPORT-SUCCESSFUL'))
      }
    })
  }

  selectSingleWorkflow(workflow) {
    let workflowLangs = []
    let userDefaultLang = localStorage.getItem('userDefaultLanguage')
    if(workflow.translations) {
      Object.keys(workflow.translations).forEach(res => {
        let workflowLang = languagesJson.languages.find(lang => lang.code == res)
        if(workflowLang) {
          workflowLangs.push(workflowLang)
        }
      })
      let isLang = workflowLangs.find(lang => lang.code == userDefaultLang)
      if(isLang) {
        this.wfName = workflow.translations[isLang.code][workflow.name]
      }else{
        this.wfName = workflow.translations[workflow.defaultLanguage][workflow.name]
      }
    }else{
      this.wfName = workflow.name
    }

    if(this.isMobile) {
      this.appService.userWorkflowisOpenSource.next(true)
    }
    this.loaderService.show()
    let draft
    this.workflowService.getUserWorkflowReports().then(reports => {
    this.loaderService.hide()
    draft = reports.find((report) => (report.workflowId == workflow.id && report.version == workflow.version && report.status == 'incomplete'))
    if(draft) {
      const initialState= {
        textMessage: this.translateService.instant('MAIN.CONTENT.DASHBOARD.WORKFLOW-STARTED'),
        confirmButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.CONTINUE-UPPERCASE'),
        cancelButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.START-OVER'),
        cancelButtonStyle:'primary',
        confirmButtonStyle:'primary',
        instantClose:false
      };
      let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
      this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
        if (res == true) {
          this.results = draft.results
          this.reportId = draft.id
          this.report = draft
          this.workflow = workflow
          this.showWorkflow = true
          confirmModalRef.hide();
        }else{
          this.loaderService.show()
          this.workflowService.deleteWorkflowReport(draft.id).then(res=> {
            if(res) {
              this.loaderService.hide()
              this.reportId = null
              this.results = null
              this.workflow = workflow
              this.showWorkflow = true
              confirmModalRef.hide();
            }
          })
          .catch(err => this.loaderService.hide())
        }
    }))

    }else{
      this.workflow = workflow
      this.showWorkflow = true
    }
    })
    
  }

  close() {
    this.workflowMenuClicked = false
    if(this.isSubmitted){
      this.showWorkflow = false
      this.workflow = {}
      this.isSubmitted = false
      this.results = null
      this.reportId = null
      this.isStart = false
    }else{
      if(this.isStart) {
        const initialState= {
          textMessage: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE-CLOSE-WORKFLOW`),
          confirmButtonText: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.CLOSE`),
          instantClose:false,
          cancelButtonText:this.translateService.instant('MAIN.CONTENT.DASHBOARD.SAVE-AS-DRAFT')
        };
        let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
        this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
          if (res == true) {
            if(this.isMobile) {
              this.appService.userWorkflowisOpenSource.next(false)
            }
            confirmModalRef.hide();
            this.showWorkflow = false
            this.workflow = {}
            this.isSubmitted = false
            this.isStart = false
          }else{
            this.savedDraft = true
            this.isStart = false
          }
          this.savedPrefilled = false
        }))
      }else{
        this.showWorkflow = false
        this.workflow = {}
      }
    }
    this.isStart = false
  }

  workflowSubmitted(event) {
    this.isSubmitted = event
  }

  workflowMenuClickedChange(event) {
    this.workflowMenuClicked = event
  }

  langChanged(lang) {
    this.wfName = lang
  }

  isWorkflowStart(event) {
    this.isStart = event
  }

  savedDraftChage(event) {
    this.savedDraft = event
  }

  switchWorkflowMenu() {
    this.workflowMenuClicked = !this.workflowMenuClicked
  }

  stepFileAvailability(event){
    this.isStepFileAvailable = event
  }

  showStepFiles() {
    this.stepFilesClicked = true
  }

  selectContentLanguage() {
    const initialState= {};
    let confirmModalRef = this.modalService.show(UserDefaultContentLanguageModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        confirmModalRef.hide();
        localStorage.setItem('userDefaultLanguage', confirmModalRef.content.defaultLanguage)
      }
    }))
  }

  sortByName() {
    this.publishDateOrder = ''
    this.order = !this.order ? 'asc' : (this.order == 'asc' ? this.order = 'desc' : this.order = 'asc')
    this.filteredWorkflowList['folders'].forEach(folder => {
      folder.workflowList.sort((a, b) => {
        if (this.order === 'asc') {
          return a.listName.localeCompare(b.listName);
        } else {
          return b.listName.localeCompare(a.listName);
        }
      });
    });
  }

  sortByPublishDate() {
    this.order = ''
    this.publishDateOrder = !this.publishDateOrder ? 'asc' : (this.publishDateOrder == 'asc' ? this.publishDateOrder = 'desc' : this.publishDateOrder = 'asc')
    this.filteredWorkflowList['folders'].forEach(folder => {
      folder.workflowList.sort((a, b) => {
        if (this.publishDateOrder === 'asc') {
          return a.publishedAt - b.publishedAt
        } else {
          return b.publishedAt - a.publishedAt
        }
      });
    });
  }

  onFilterChange(filterEvent) {
    this.filteredWorkflowList['folders'] = this.getFilteredWorkflows(filterEvent)
  }

  getFilteredWorkflows(filterEvent) {
    return this.workflowList.folders.map((folder,index) => {
      return {
        ...folder,
        workflowList: folder.workflowList.filter(workflow => {
          const nameMatch = !filterEvent.name || workflow.listName.toLowerCase().includes(filterEvent.name.toLowerCase());
          const labelsMatch = !filterEvent.labels.length || workflow.labels.some(label => filterEvent.labels.includes(label));
          return nameMatch && labelsMatch;
        }),
        openStatus: this.filteredWorkflowList['folders'][index]?.openStatus
      };
    }).filter(folder => folder.workflowList.length > 0);
  }

}
