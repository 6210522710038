import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { WorkflowService } from '@services/workflow.service';

@Component({
  selector: 'app-table-report',
  templateUrl: './table-report.component.html',
  styleUrls: ['./table-report.component.scss']
})
export class TableReportComponent implements OnInit {

  @Input() tableData?
  @Input() workflowPublishedVersion?
  @Input() workflowTranslations?
  @Input() selectedLang?
  
  workflowTableData

  constructor(
    public workflowService: WorkflowService,
    public multiLanguageService: MultilanguageService) { }

  ngOnInit(): void {
    if(this.workflowPublishedVersion) {
      this.workflowTableData = this.workflowPublishedVersion.layouts[0].items[0].templateOptions.tableData
    }
    let cell;

    for (const col of this.workflowTableData.rows) {
      for(const cellItem of Object.keys(this.tableData)) {
        cell = col.cells.find(colItem => colItem.key == cellItem)
        if(cell?.type == 'datepicker') {
          this.tableData[cell.key] = this.mapDateData(this.tableData[cell.key])
        }
        if(cell?.type == 'multicheckbox') {
          this.tableData[cell.key] = this.mapCheckboxData(cell.key,this.tableData[cellItem])
        }
      }
      // if (!!cell) {
      //   break;
      // }
    }
  }

  mapDateData(valueItem) {
    const datePipe: DatePipe = new DatePipe(
      this.multiLanguageService.currentLang.code
    )
    let dateValue = datePipe.transform(valueItem,'long')
    return`${dateValue}`
  }

  mapCheckboxData(checkboxKey, selectedOptionKeys) {
    let checkboxItem;
    for (const col of this.workflowTableData.rows) {
      checkboxItem = col.cells.find(colItem => colItem.key == checkboxKey)
      if (!!checkboxItem) {
        break;
      }
    }
    checkboxItem.templateOptions
    let keyItem = checkboxItem.templateOptions.options.filter(res => selectedOptionKeys.length && (res == selectedOptionKeys[0]))
    // let valueItem = stepDataItem.templateOptions.options.filter(res => reportDataKey.data?.value?.length && (res.label == reportDataKey.data.value[0]))
    let array = []
    if(keyItem) {
      selectedOptionKeys.forEach(response => {
        let optionItem = checkboxItem.templateOptions.options.find(item => item.value == response)
        if(optionItem) {
          array.push(optionItem.value)
        }
      })
    }
    return array
  }

  mapRadioData(radioKey, selectedOptionKey) {
    let radioItem;

    for (const col of this.tableData.rows) {
      radioItem = col.cells.find(colItem => colItem.key == radioKey)
      if (!!radioItem) {
        break;
      }
    }
    let keyItem = radioItem.templateOptions.options.filter(res => selectedOptionKey&& (res.value == selectedOptionKey))

    if(keyItem) {
                  
      let x = radioItem.templateOptions.options.find(item => item.value == selectedOptionKey)
      if(x) {
        radioKey.data['value'] = x.label
      }
    }
  }

}
