import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() id?: string
  @Input() accept?: string
  @Input() isDisabled?: boolean

  @Output() uploadedFiles: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription[] = []

  files = []
  acceptTypes: string

  constructor(
    private workflowService: WorkflowService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.acceptTypes = !this.accept ? 'video/*,image/*,application/pdf,audio/*' : this.accept
  }

  chooseButtonUpload( event: any): void {
    let selectedFile =  event.target.files
    this.previewUpload(selectedFile)
  }

  dragDropUpload(event) {
    if (!environment.electron) {
      let selectedFile =  event
      this.previewUpload(selectedFile)  
    }
  }

  private previewUpload(selectedFile: File): void {
    if (selectedFile) {
      this.loaderService.show()
      this.subscriptions.push(this.uploadfile(selectedFile).subscribe({
        next: (resp) => {
          if(resp){
            let image
            image = []
            this.uploadedFiles.emit(resp['data'])
            // resp['data'].forEach(imageObject => {
            //   this.files.push({name:imageObject.fieldname,url:imageObject.url})
            // })
            this.loaderService.hide()
          }
        },
        error: (err) => {
          this.loaderService.hide()
        }
      })
      )
    }
  }

  uploadfile(file) {
    let formData = new FormData();
    
      if(file.length){
        for (let i = 0; i < file.length; i++) {
          formData.append(file[i].name, file[i])
        }
      }else{
        formData.append(file.name, file)
      }

      return this.workflowService.uploadWorkflowFiles(this.id, formData)
    
  }

}
