<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MAIN.CONTENT.EASY-ACCESS.QR-INFORMATION' | translate }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            
            <div class="guest-wrapper-with-qr w-100">
                <label class="fs-14 font-weight-600 mb-2 w-100 text-center">{{ 'MAIN.CONTENT.EASY-ACCESS.LINK' | translate }}</label>
                <div class="d-flex mb-1 w-100 align-items-center">
                    <input type="text" class="form-control guest fs-14" style="height: 42px;" [formControl]="guestLink" #guestLinkp style="pointer-events:none">
                    <button class="v-btn btn btn-primary vsight-button font-weight-600 text-white ms-2" (click)="copyGuestLinkToClipboard(guestLinkp)">{{ 'MAIN.CONTENT.EASY-ACCESS.COPY' | translate }}</button>
                </div>

                <div class="d-flex flex-column align-items-center">
                    <label class="fs-14 font-weight-600 mt-2">{{ 'MAIN.CONTENT.EASY-ACCESS.QR-CODE' | translate }}</label>
            
                    <div>
                        <img class="img-fluid" [src]="qrData" alt="QR" style="cursor: pointer;" style="width: 150px;">
                    </div>
                
                    <a [href]="qrData"><button class="v-btn btn btn-primary vsight-button font-weight-600 text-white">{{ 'MAIN.CONTENT.EASY-ACCESS.DOWNLOAD' | translate }}</button></a>
                </div>
                
            </div>

        </div>
    </div>
</div>