import { Injectable } from '@angular/core';
import { saveAs } from "file-saver";

import { HttpClient } from '@angular/common/http';
import { DbService } from '@services/db.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '@services/auth.service';
import JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class FileShareService {

  constructor(
    private dbService: DbService,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  async downloadFile(url: string, filename: string) {
    return this.http.get(url, {observe: 'response', responseType: 'blob'}).toPromise().then(response => {
      saveAs(response.body, filename);
      return;
    });
  }

  uploadWorfklowFile(file: File, workflowId: string, stepId: string, fileId: string): Promise<string> {
    return this.dbService.uploadWorfklowFile(file, workflowId, stepId, fileId)
  }

  convertBase64toFile(content: string, contentType: string, filename: string): File {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
 
    // Method which converts byteArrays to blob
    const blob = new Blob(byteArrays, { type: contentType });

    // File constructor for create file
    let f;
    try {
      f = new File([blob], filename, { type: contentType, lastModified: (new Date()).getTime() });
    } catch(error) {
      const lastModifiedDate = new Date();
      Object.defineProperties(blob, {
        name: {
          value: filename
        },
        lastModifiedDate: {
          value: lastModifiedDate
        },
        lastModified: {
          value: lastModifiedDate.getTime()
        },
        toString: {
          value() {
            return '[object File]'
          }
        }
      });
      f = blob;
    }
    return f;
  }

  convertCanvasToJPEGFile(canvas: HTMLCanvasElement, filename: string, compressionRatio: number): Promise<File> {
    return new Promise<File>(resolve => {
      if (canvas.toBlob) {
        canvas.toBlob(blob => {
          resolve(new File([blob], filename, { type: "image/jpeg", lastModified: (new Date()).getTime() }));
        }, "image/jpeg", compressionRatio);
      } else {
        resolve(this.convertBase64toFile(canvas.toDataURL("image/jpeg", compressionRatio).slice('data:image/jpeg;base64,'.length), "image/jpeg", filename));
      }
    });
  }

  saveBase64File(content: string, contentType: string, filename: string) {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    saveAs(new Blob(byteArrays, { type: contentType }), filename);
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Str = reader.result.toString();
        const base64StrWithoutHeader = base64Str.slice(base64Str.indexOf(',') + 1);
        resolve(base64StrWithoutHeader);
      }
      reader.onerror = error => reject(error);
    })
  }

  saveFile(file: File) {
    saveAs(file);
  }

  async getSessionExportPDF(room_id: string, session_id: string) {
    const url = environment.endPoints.getExportPDF;
    const body = {
      room_id: room_id,
      session_id: session_id,
      token: this.authService.currentUser.token
    }

    if (typeof (window as any).showSaveFilePicker === 'function')  {
      const defaultFileName = 'export.pdf';

      const options = {
        types: [
          {
            description: 'PDF Files',
            accept: {
              'application/pdf': ['.pdf'],
            },
          },
        ],
        suggestedName: defaultFileName,
      };

      const handle = await (window as any).showSaveFilePicker(options);
      const fileName = handle.name || defaultFileName;

      const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });

      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    } else {
      const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
      saveAs(blob, 'export.pdf');
    }
  }

  async downloadSelectedFilesAsZip(files: any[], zipFileName: string): Promise<void> {
    const zip = new JSZip();

    for (const file of files) {
      if (file.exportPdf) {
        const url =  environment.endPoints.getExportPDF;
        const body = {
          room_id: file.room_id,
          session_id: file.session_id,
          token: this.authService.currentUser.token
        };
        const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
        const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        zip.file(file.name, blob);
      } else if (file.url) {
        const response = await this.http.get(file.url, { observe: 'response', responseType: 'blob' }).toPromise();
        const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        zip.file(file.name, blob);
      }
    }

    const content = await zip.generateAsync({ type: 'blob' });

    // if (typeof window.showSaveFilePicker === 'function') {
    //   const handle = await window.showSaveFilePicker({
    //     suggestedName: zipFileName,
    //     types: [
    //       {
    //         description: 'ZIP Files',
    //         accept: {
    //           'application/zip': ['.zip']
    //         }
    //       }
    //     ]
    //   });

    //   const writable = await handle.createWritable();
    //   await writable.write(content);
    //   await writable.close();
    // } else {
    //   saveAs(content, zipFileName);
    // }
  }

}
