<div class="v-card card modal-card border-0" style="border-radius:0 0 10px 10px ;" [ngStyle]="token ? {'height' : 'calc(100% - 20px)'} : {}">
    <div class="modal-background" *ngIf="qrModalShow || showExpertsModal || showCallPermissionWarning || showFiles"></div>
    <div class="card preview-card" *ngIf="stepData.data">

        <div *ngIf="showRequestAlert && showApproval">
            <div class="request-alert-card">
                <div class="d-flex justify-content-center align-items-center card-header">
                    <label class="card-title fs-15 font-weight-600 text-white text-center mb-0">{{ 'MAIN.USER-WORKFLOW.APPROVAL-REQUEST' | translate }}</label>
                </div>
                <div class="card-body fs-14 font-weight-500 pt-2 mt-4 mb-2">{{ 'MAIN.USER-WORKFLOW.REQUEST-SENDED-TEXT' | translate }}</div>
                <div class="alert-footer d-flex align-items-center justify-content-center" (click)="closeAlert()">{{ 'MAIN.USER-WORKFLOW.CLOSE' | translate }}</div>
            </div>
        </div>

        <ng-container *ngIf="qrModalShow" [ngTemplateOutlet]="prefilledWarningTemplate"></ng-container>
        <div *ngIf="showExpertsModal">
            <div class="request-alert-card" style="left: 155px;
            right: 155px;">
                <div class="d-flex justify-content-center align-items-center card-header">
                    <label class="card-title fs-15 font-weight-600 text-white text-start ms-3 mb-0">{{ 'MAIN.USER-WORKFLOW.CALL-EXPERT' | translate | uppercase }}</label>
                </div>
                <div class="card-body fs-14 font-weight-500 pt-2 mt-4 mb-2">
                    <p class="fs-14 text-start">{{ 'MAIN.USER-WORKFLOW.SELECT-EXPERT-TEXT' | translate }}</p>
                    <div class="card expert-list-card" [formGroup]="expertGroup">
                        <div *ngFor="let expert of expertsToCall" class="text-start mb-1 mt-2 d-flex align-items-end">
                        
                            <div class="custom-control custom-checkbox custom-checkbox-green w-100">
                                <label class="custom-control-label label-form" [for]="expert.name">
                                    <div class="user-icon" style="position: relative;display: inline-block;">
                                        <img src="assets/img/user-icon.svg" alt="" class="me-2">
                                        <span *ngIf="expert.web_status" class="fs-20 expert-web-status {{expert.web_status}}">.</span>

                                    </div>
                                    
                                    {{ expert.name }}
                                </label>
                                <input
                                  type="checkbox"
                                  class="form-check-input ms-0 me-3"
                                  [id]="expert.name"
                                  [formControlName]="expert.id"
                                />
                            </div>
    
                        </div>
                    </div>

                    
                </div>
                
                <div class="alert-footer d-flex align-items-center justify-content-center" (click)="closeAlert()">
                    <button class="v-btn btn btn-primary fs-14 font-weight-500 me-2 modal-footer-button cancel-button"(click)="cancelRemoteCall()">
                        {{ 'MAIN.USER-WORKFLOW.CANCEL' | translate }}
                    </button>
                    <button class="v-btn btn btn-primary fs-14 font-weight-500 ms-2 modal-footer-button" (click)="callExpert()">
                        {{ 'MAIN.USER-WORKFLOW.CALL' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="stepData.data?.files?.length && showFiles">
            <div class="request-alert-card" style="left: 155px;
            right: 155px;">
                <div class="d-flex justify-content-center align-items-center card-header">
                    <label class="card-title fs-15 font-weight-600 text-white text-start mb-0">{{ 'MAIN.USER-WORKFLOW.STEP-FILES.TITLE' | translate }}</label>
                </div>
                <div class="card-body fs-14 font-weight-500 pt-2 mt-4 d-flex flex-column align-items-start step-files-body">
                    <div class="card step-list-card text-start">
                        <div *ngFor="let file of stepData.data?.files" class="step-files-line mb-1 mt-2 d-flex justify-content-between">
                            <div>{{file.name}}</div>
                            <img src="assets/img/download-step-file.svg" alt="" 
                            class="cursor-pointer" [ngClass]="{'is-downloading': isDownloading}"
                            (click)="downloadStepFile(file)">
                        </div>
                    </div>
                </div>
                
                <div class="alert-footer d-flex align-items-center justify-content-center">
                    <button class="v-btn btn btn-primary fs-14 font-weight-500 modal-footer-button cancel-button"(click)="closeStepFiles()">
                        {{ 'MAIN.USER-WORKFLOW.CLOSE' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="showCallPermissionWarning">
            <div class="request-alert-card" style="left: 155px;
            right: 155px;">
                <div class="d-flex justify-content-center align-items-center card-header">
                    <label class="card-title fs-15 font-weight-600 text-white text-start ms-3 mb-0">{{ 'MAIN.USER-WORKFLOW.CALL-EXPERT' | translate | uppercase }}</label>
                </div>
                <div class="card-body fs-14 font-weight-500 pt-2 mt-4">
                    <p class="fs-14 mb-1">{{ 'MAIN.USER-WORKFLOW.CALL-PERMISSION-WARNING' | translate }}</p>
                </div>
                
                <div class="alert-footer d-flex align-items-center justify-content-center" (click)="closeAlert()">
                    <button class="v-btn btn btn-primary fs-14 font-weight-500 me-2 modal-footer-button cancel-button"(click)="closeWarningModal()">
                        {{ 'MAIN.USER-WORKFLOW.CLOSE' | translate }}
                    </button>
                </div>
            </div>
        </div>
        
        <div class="card-body preview-card-body" [ngStyle]="{'flex-direction': parentFlexDirection}" *ngIf="!(isSubmitted || isSaved) && isStart">
            <div *ngIf="layoutCount > 0" class="first-layout" tabindex="0"   [ngStyle]="{ 'flex-grow': firstFlexGrow }"
                [class.show-overflow]="selectedImage" [class.scroll-overflow]="!selectedImage && firstLayoutItems?.[0]?.type == 'gallery'">
                <ng-container *ngIf="stepData.data.layouts[0]?.items" [ngTemplateOutlet]="layoutColumnTemplate" [ngTemplateOutletContext]="{ layout: firstLayoutItems, step: stepData }"></ng-container>
                <ng-container *ngIf="!stepData.data.layouts.length || !stepData.data.layouts[0]" [ngTemplateOutlet]="layoutEmptyTemplate"></ng-container>
            </div>
    
            <div *ngIf="layoutCount > 1" class="second-layout" tabindex="0"   [ngStyle]="{ 'flex-grow': secondFlexGrow }">
                <ng-container *ngIf="stepData.data.layouts[1]?.items" [ngTemplateOutlet]="layoutColumnTemplate" [ngTemplateOutletContext]="{ layout: secondLayoutItems}"></ng-container>
                <ng-container *ngIf="!stepData.data.layouts.length || !stepData.data.layouts[1]" [ngTemplateOutlet]="layoutEmptyTemplate"></ng-container>
            </div>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center align-items-center fs-14 font-weight-500 start-screen" *ngIf="isSubmitted || isSaved">
            <ng-container *ngIf="isQrAvailable && !updatedPrefilled" [ngTemplateOutlet]="prefilledTemplate"></ng-container>
            <ng-container *ngIf="qrModalShow" [ngTemplateOutlet]="prefilledWarningTemplate"></ng-container>
            <ng-container *ngIf="isLoadingSubmit" [ngTemplateOutlet]="loadingTemplate"></ng-container>
            <ng-container *ngIf="(!isLoadingSubmit || isRequestSended) &&  (updatedPrefilled || !isQrAvailable)">
                <div class="h-100 w-100" [ngClass]="{'d-flex flex-column justify-content-center' : !approversModalShow || isRequestSended}">
                    <div *ngIf="approversModalShow && !isRequestSended">
                        <ng-container *ngIf="approversModalShow" [ngTemplateOutlet]="approvalLayout"></ng-container>
                    </div>
                    <div class="text-center">
                        <img src="assets/img/check_circle.svg" alt="">
                    </div>
                    <label class="mt-3 text-center">{{isSubmitted ? ('WEB-VERSION.SUCCESS-SUBMIT-MESSAGE' | translate ) : ('WEB-VERSION.SUCCESS-SAVE-MESSAGE' | translate) }}
                        <span *ngIf="showLicenceLimitAlert && isSubmitted">{{ 'MAIN.USER-WORKFLOW.LICENCE-LIMIT' | translate }}</span>
                    </label>
                    <div class="d-flex justify-content-center mt-4" *ngIf="!isRequestSended && showApproval && isSubmitted">
                        <button class="v-btn btn btn-outline-primary fs-14 font-weight-600 request-button" (click)="openApproversModal()">{{ sendMailAfterApproval ? ('MAIN.USER-WORKFLOW.REQUEST-REPORT' | translate) : ('MAIN.USER-WORKFLOW.REQUEST-APPROVAL' | translate) }}</button>
                    </div>

                    <div class="d-flex justify-content-center mt-4" *ngIf="isRequestSended">
                        <div class="fs-14 font-weight-600 request-button-pending" >{{ 'MAIN.USER-WORKFLOW.REQUEST-PENDING' | translate }}</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="isLoadingExpertModal" class="d-flex flex-column justify-content-center align-items-center remote-loading">
            <ng-container *ngIf="isLoadingExpertModal" [ngTemplateOutlet]="loadingTemplateRemote"></ng-container>
        </div>
        <div class="d-flex flex-column h-100 mt-3 align-items-start fs-14 font-weight-500 start-screen" *ngIf="!isStart">
            <div class="d-flex justify-content-start align-items-center" *ngIf="workflowLangs.length">
                <img src="assets/img/language.svg" alt="">
                <span>{{ 'MAIN.USER-WORKFLOW.CONTENT-LANGUAGE' | translate }}</span>
                <div class="d-flex justify-content-end" *ngIf="!('electron' | env)">
                    <div class="btn-group dropdown-wrapper-list me-2 ms-2" dropdown style="width: 200px">
                        <button type="button" class="btn btn-primary action-dropdown p-0" style="width: 75%;">
                            <img src="assets/img/check_icon.svg" alt="" *ngIf="selectedLangName">
                            {{selectedLangName ? selectedLangName : ('MAIN.USER-WORKFLOW.SELECT-LANGUAGE' | translate) }}</button>
                        <button id="button-split" type="button" dropdownToggle class="btn btn-danger dropdown-toggle dropdown-toggle-split"
                                aria-controls="dropdown-split">
                        </button>
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
                            <div *ngFor="let lang of workflowLangs" (click)="changeCurrentLang(lang)" >
                                <div *ngIf="lang.code" class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer">
                                    <div class="d-flex justify-content-between lang-line">
                                        <div>
                                            <img src="assets/img/check_icon.svg" alt="" *ngIf="selectedLanguage == lang.code" style="margin-left:-22px;">
                                            {{lang.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            
           <div class="mt-4 w-100">
                <p *ngIf="wfDescription" class="text-start">{{wfDescription}}</p>
                <div class="d-flex mt-4">
                    <label><img src="assets/img/task.svg" alt="">{{ 'MAIN.USER-WORKFLOW.TASK-TO-COMPLETE' | translate }} {{steps.length}}</label>
                    <label class="ms-4"><img src="assets/img/duration.svg" alt=""> {{ 'MAIN.USER-WORKFLOW.ESTIMATED-DURATION' | translate }} {{workflow.estimatedDuration.hours}} h {{workflow.estimatedDuration.minutes}} min</label>
                </div>
                <div *ngIf="workflow.labels?.length" class="text-start mt-4">
                    <img src="assets/img/tags.svg" alt="">
                    <label *ngFor="let label of workflow.labels" class="ms-1 label">{{label}}</label>
                </div>
                <div *ngIf="workflow.files?.length" class="text-start mt-4">
                    <img src="assets/widgets/attach_file.svg" alt="">
                    <label  *ngFor="let file of workflow.files;let index=index" class="ms-1 file">
                        <div><label style="color: #4f5053" ><a [href]="file.url" class="file-attach">{{file.name}}</a></label></div>
                    </label>
                </div>
                <hr *ngIf="workflow.detailExtraFields?.length">
                <div *ngIf="workflow.detailExtraFields?.length" class="text-start mt-4">
                    <div  *ngFor="let field of workflow.detailExtraFields;let index=index">
                        <div class="d-flex">
                            <div>{{ field.templateOptions?.label }}</div>
                            <div class="ms-2"  *ngIf="wfName">: {{ field.templateOptions?.value }}
                            </div>
                        </div>
                    </div>
                </div>
           </div>
          
        </div>

        <div class="content-page-container w-50" *ngIf="isStart && contentPageAvailable && showStepList" [ngStyle]="{'top': guestData && isMobile ? '0px' : '-20px'}">
            <div class="d-flex h-100 justify-content-center fs-16 content-page-screen mb-3"  style="overflow-y:scroll">
                <div class="w-100">
                 <div *ngFor="let step of steps;let index=index" class="d-flex align-items-center justify-content-between step-list fs-14 font-weight-500 cursor-pointer" (click)="selectStep(index,true)">
                    <div>
                        <span class="step-index me-2">{{index + 1}}</span>
                        {{step.data.name}}
                    </div>
                    <div>
                        <img *ngIf="step.data.required && !step.data.visited" src="assets/img/content_error.svg" alt="">
                        <img *ngIf="step.data.visited" src="assets/img/check_content.svg" alt="">

                    </div>
                 </div>
                </div>
             </div>
        </div>

        <div class="menu-container w-30" *ngIf="isStart && showMenu">
            <div class="h-100 justify-content-center fs-16 content-page-screen mb-3"  style="overflow-y:scroll">
                <div class="w-100 d-flex flex-column justify-content-end align-items-end me-3 pe-4">
                    <div class="mt-3 mb-2 fs-14 font-weight-500 cursor-pointer" (click)="showContentList()" *ngIf="contentPageAvailable">
                        {{ 'MAIN.USER-WORKFLOW.TABLE-OF-CONTENTS' | translate }}
                    </div>
                    <div class="mt-3 mb-2 fs-14 font-weight-500 cursor-pointer" (click)="showRemoteExpertsModal()" *ngIf="workflow.expertsToCall?.length > 0">
                        {{ 'MAIN.USER-WORKFLOW.CALL-EXPERT' | translate }}
                    </div>
                    <div class="mt-3 fs-14 font-weight-500 cursor-pointer" (click)="openContinueWithQrModal()" *ngIf="continueWithQrAvailable && !isGuest && reportStatus !== 'prefilled'">
                        {{ 'MAIN.USER-WORKFLOW.HAND-OVER' | translate }}
                    </div>
                    <div class="mt-3 fs-14 font-weight-500 cursor-pointer" (click)="updatePrefilledReport()" *ngIf="continueWithQrAvailable && !isGuest && reportStatus == 'prefilled'">
                        {{ 'MAIN.USER-WORKFLOW.SAVE-CHANGES' | translate }}
                    </div>
                </div>
             </div>
        </div>
        
        <div class="card-footer preview-card-footer" *ngIf="(isStart && !approversModalShow )|| isRequestSended">
            <button class="button-placeholder v-btn btn-primary rounded d-flex" (click)="previous()" [disabled]="(stepData.id == initialStep) && !isLoading || isSubmitted || isSaved">{{ 'WEB-VERSION.PREVIOUS' | translate }}</button>
            <div class="fs-14 font-weight-500 text-grey d-flex preview-main-title" *ngIf="!stepData.data.isSection && !stepData.data.parentId">  
                <label class="step-overflow" title="{{stepData.data.name}}">
                {{stepData.data.order+1}} / {{stepListCount}} - {{stepData.data.name}}</label>
            </div>
            <div class="fs-14 d-flex preview-main-title" *ngIf="stepData.data.parentId">
                <label class="step-overflow" title="{{stepData.data.name}}">
                    {{sectionStepOrder + 1}} / {{sectionStepsCount}} - {{stepData.data.name}}
                </label>
            </div>
            <div class="fs-14 d-flex preview-main-title" *ngIf="stepData.data.isSection"><label>{{stepData.data.name}}</label></div>
            <button class="button-placeholder v-btn btn-primary rounded d-flex" (click)="next()" *ngIf="!isLastStep" [disabled]="((!previewForm.valid) || isLoading ) && !contentPageAvailable || isSaved">{{ 'WEB-VERSION.NEXT' | translate }}</button>
            <button class="button-placeholder v-btn btn-primary rounded d-flex" (click)="submit()" *ngIf="isLastStep" [disabled]="isSubmitted || (!previewForm.valid) || isSaved">{{ 'WEB-VERSION.SUBMIT' | translate }}</button>
        </div>
        <div class="card-footer preview-card-footer start-footer justify-content-center cursor-pointer" (click)="start()" *ngIf="!isStart">
            <div class="fs-14 font-weight-600 text-center">
                <label style="color: #314B76;">{{ 'WEB-VERSION.START' | translate }}</label>
            </div>
        </div>
        <div class="card-footer preview-card-footer start-footer justify-content-center cursor-pointer" *ngIf="approversModalShow && !isRequestSended" (click) ="sendRequest()">
            <div class="fs-14 font-weight-600 text-center">
                <label style="color: #314B76;cursor:pointer;">{{ sendMailAfterApproval ? ('MAIN.USER-WORKFLOW.SEND-REPORT-TITLE' | translate) : ('MAIN.USER-WORKFLOW.SEND-REQUEST-TITLE' | translate) }}</label>
            </div>
        </div>
    </div>
    
    <ng-template #layoutColumnTemplate let-layout="layout" let-step="step">
        <div class="inner-layout d-flex align-items-{{layout[0]?.templateOptions?.vPosition}} justify-content-{{layout[0]?.templateOptions?.hPosition}}">
            <div class="d-flex flex-column w-100 h-100" [ngStyle]="{'width':layout[0].type!=='label' ? '100%' : '' , 'height':layout[0].type!=='label' ? '100%' : ''}">
                <div class="layout-style d-flex h-100">
                        <form [formGroup]="previewForm" class="w-100" [ngStyle]="{'overflow' : (layout[0]?.type == 'table' || layout[0]?.type == 'form') ? 'scroll' : 'none'}">
                            <div class="" *ngIf="layout[0]?.type == 'empty'">
                                
                            </div>
    
                            <div class="" *ngIf="layout[0]?.type == 'label'">
                                <div class="d-flex align-items-center justify-content-center h-100 text-muted fs-14 inner-layout">
                                    <label class="label-form">{{layout[0].templateOptions?.label}}</label>
                                </div>
                            </div>
    
                            <!-- <div class="" *ngIf="layout[0]?.type == 'html'">
                                <div class="ql-editor text-start" [innerHTML]="byPassHTML(layout[0].templateOptions?.html)"></div>
                            </div>  -->

                            <app-html-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'html'" [dataVersion]="workflow?.dataVersion"></app-html-widget>
    
                            <app-input-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'input' || layout[0]?.type == 'id'"></app-input-widget>
    
                            <app-datepicker-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'datepicker'"></app-datepicker-widget>
    
                            <app-number-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'number'"></app-number-widget>
    
                            <app-checkbox-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'multicheckbox'"></app-checkbox-widget>
    
                            <app-radio-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'radio'"></app-radio-widget>
    
                            <app-gallery-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'gallery'"></app-gallery-widget>
    
                            <ng-container *ngIf="layout[0]?.type == 'captureImage' || layout[0]?.type == 'captureVideo' || layout[0]?.type == 'ppe'">
                                <app-media-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" [token]="token" [guestToken]="guestToken" [workflowId]="workflow.id"></app-media-widget>
                            </ng-container>
    
                            <ng-container *ngIf="layout[0]?.type == 'image' || layout[0]?.type == 'video' || layout[0]?.type == 'pdf'">
                                <app-media-instructions-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]"></app-media-instructions-widget>
                            </ng-container>
    
                            <app-qr-widget  [item]="layout[0]" [formControl]="previewForm.controls?.[layout[0].key]" *ngIf="layout[0]?.type == 'qr'"></app-qr-widget>
    
                            <div *ngIf="layout[0]?.type == 'table'">
                                <app-table-widget-view [tableData]="layout[0].templateOptions.tableData" [formControl]="previewForm.controls[layout[0].key]"></app-table-widget-view>
                            </div>
    
                            <div class="" *ngIf="layout[0]?.type == 'form'">
                                <app-form-widget-view [formData]="previewForm.controls[layout[0].key]?.value" (formOutputData)="formOutput($event)"></app-form-widget-view>
                            </div>
    
                            <ng-container *ngIf="!stepData.data.layouts.length || !stepData.data.layouts[1]" [ngTemplateOutlet]="emptyLayout" [ngTemplateOutletContext]="{ type: layout[0]?.type }"></ng-container>
                        </form>

                    

                </div>
            </div>
        </div>
    </ng-template>
    
    <ng-template #layoutEmptyTemplate>
       <div class="d-flex align-items-center justify-content-center h-100 text-muted fs-14 inner-layout">
           Not Selected
       </div>
    </ng-template>

    <ng-template #loadingTemplate>
        <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
      </ng-template>

      <ng-template #loadingTemplateRemote>
        <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
      </ng-template>

      <ng-template #emptyLayout let-type="type">
        <div class="d-flex align-items-center justify-content-center h-100 text-muted fs-14 inner-layout"
        *ngIf="!(widgetProps.includes(type))">
            This widget is not currently supported
        </div>
     </ng-template>

     <ng-template #approvalLayout>
        <div class="approver-modal">
            <div class="input-group mt-2 ms-1" [ngClass]="{'mb-3':!approverEmail.errors?.['pattern'], 'mb-1': approverEmail.errors?.['pattern']}">
                <input type="text" class="form-control email-input fs-14" [formControl]="approverEmail" placeholder="Enter email" aria-label="Enter email" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fs-14 font-weight-600 add-button" (click)="addNewApprover()" type="button" [disabled]="approverEmail.errors?.['required'] || approverEmail.errors?.['pattern']">{{ 'MAIN.USER-WORKFLOW.ADD' | translate }}</button>
                </div>
              </div>
              <p *ngIf="approverEmail.errors?.['pattern']" class="validation-text text-start  mt-0 ms-2 mb-2">{{ 'MAIN.USER-WORKFLOW.INVALID-EMAIL' | translate }}</p>

            <div class="text-start fs-12 font-weight-600 ms-2 mb-2" style="color: #909090;">{{ sendMailAfterApproval ? ('MAIN.USER-WORKFLOW.SEND-REPORT' | translate) : ('MAIN.USER-WORKFLOW.SEND-REQUEST' | translate) }}</div>

            <div class="d-flex flex-column approver-list ms-1">
                <ng-container *ngFor="let approver of approvers; let index=index">
                    <div class="d-flex align-items-center justify-content-between ng-star-inserted pb-1">
                        <div class="approver-card mb-2 me-3">
                            <label class="fs-14 font-weight-400">{{approver}}</label>
                        </div>
                        <div class="fs-14 font-weight-600 cursor-pointer" style="color:#324b76;" (click)="deleteApprover(index)">{{ 'MAIN.USER-WORKFLOW.REMOVE' | translate }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
     </ng-template>

     <ng-template #prefilledTemplate>
        <div *ngIf="isQrAvailable" class="guest-wrapper-with-qr w-100">
            <label class="fs-14 font-weight-600 mb-2">{{ 'MAIN.CONTENT.EASY-ACCESS.LINK' | translate }}</label>
            <div class="d-flex mb-1 w-100 align-items-center">
                <input type="text" class="form-control guest fs-14" style="height: 42px;" [formControl]="guestLink" #guestLinkp>
                <button class="v-btn btn btn-primary fs-12 font-weight-600 text-white copy-button ms-2" (click)="copyGuestLinkToClipboard(guestLinkp)">{{ 'MAIN.CONTENT.EASY-ACCESS.COPY' | translate }}</button>
            </div>
            
            <label class="fs-14 font-weight-600 mt-2">{{ 'MAIN.CONTENT.EASY-ACCESS.QR-CODE' | translate }}</label>

            <div>
                <img class="img-fluid" [src]="qrData" alt="QR" style="cursor: pointer;" style="width: 150px;">
            </div>

            <a [href]="qrData"><button class="v-btn btn btn-primary fs-12 font-weight-600 text-white detail-button">{{ 'MAIN.CONTENT.EASY-ACCESS.DOWNLOAD' | translate }}</button></a>
        </div>
     </ng-template>

     <ng-template #prefilledWarningTemplate>
        <div class="w-100">
            <div class="request-alert-card">
                <div class="d-flex justify-content-center align-items-center card-header">
                    <label class="card-title fs-15 font-weight-600 text-white text-start ms-3 mb-0">{{ 'MAIN.USER-WORKFLOW.CONTINUE-WITH-QR-TITLE' | translate}}</label>
                </div>
                <div class="card-body fs-13 font-weight-500 pt-2 mt-2 mb-0 text-start">
                    <p>{{ 'MAIN.USER-WORKFLOW.CONTINUE-WITH-QR-WARNING-TEXT' | translate}}</p>
                    <div class="d-flex justify-content-center align-items-center mt-4">
                        <button class="v-btn btn btn-outline-secondary fs-14 w-130 close-qr-button" (click)="closeQrModal()">{{ 'MODALS.ALERT.CANCEL' | translate}}</button>
                        <button class="v-btn btn btn-outline-primary ms-4 me-4 fs-14 save-draft-button" (click)="saveAsDraft()">{{ 'MAIN.CONTENT.DASHBOARD.SAVE-AS-DRAFT' | translate}}</button>
                        <button class="v-btn btn btn-primary fs-14 w-130 border-radius-10" (click)="generateQR()">{{ 'WEB-VERSION.SUBMIT' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
     </ng-template>

     <ng-template #prefilledWarningTemplate>
        <div class="w-100">
            <div class="request-alert-card">
                <div class="d-flex justify-content-start align-items-center card-header">
                    <label class="card-title fs-15 font-weight-600 text-white text-start ms-3 mb-0">{{ 'MAIN.USER-WORKFLOW.CONTINUE-WITH-QR-TITLE' | translate}}</label>
                </div>
                <div class="card-body fs-13 font-weight-500 pt-2 mt-2 mb-0 text-start">
                    <p>{{ 'MAIN.USER-WORKFLOW.CONTINUE-WITH-QR-WARNING-TEXT' | translate}}</p>
                    <div class="d-flex justify-content-center align-items-center mt-4">
                        <button class="v-btn btn btn-outline-secondary fs-14 w-130 close-qr-button" (click)="closeQrModal()">{{ 'MODALS.ALERT.CANCEL' | translate}}</button>
                        <button class="v-btn btn btn-outline-primary ms-4 me-4 fs-14 save-draft-button" (click)="saveAsDraft()">{{ 'MAIN.CONTENT.DASHBOARD.SAVE-AS-DRAFT' | translate}}</button>
                        <button class="v-btn btn btn-primary fs-14 w-130 border-radius-10" (click)="generateQR()">{{ 'WEB-VERSION.SUBMIT' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
     </ng-template>
</div>
