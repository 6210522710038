<div class="dashboard-wrapper" *ngIf="dataLoaded" >
    <div class="v-card">
        <!-- <ng-container *ngIf="!workflows.length && dataLoaded && isAdmin">
        <div class="d-flex align-items-center me-5">
            <div class="col-md-5 col-sm-12" style="margin-right: 3rem !important;
  margin-left: 2rem;">
                <p class="fs-32">{{ 'MAIN.CONTENT.DASHBOARD.NO-WORKFLOW.TITLE' | translate }}</p>
                <div class="fs-16-light">{{ 'MAIN.CONTENT.DASHBOARD.NO-WORKFLOW.TEXT' | translate }}</div>
                <div>
                    <button class="v-btn btn btn-primary m-2 fs-12 font-weight-600 text-white" style="margin-left: 0px!important;" (click)="openVideoModal()">{{ 'MAIN.CONTENT.DASHBOARD.NO-WORKFLOW.CREATE' | translate }}
                    </button>
                </div>
            </div>
        </div>
        </ng-container> -->

        <div class="workflow-background" *ngIf="showWorkflow"></div>
        
        <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="d-flex">
                <div class="header fs-16 font-weight-600 pt-1 me-2">Video Flows</div>
                <div *ngIf="('electron' | env)">
                    <!-- <button (click)="exportPublishedWorkflows()" class="v-btn btn btn-primary fs-12 font-weight-600 text-white" style="border-radius:7px; height: 30px;padding-top:5px">{{ 'MAIN.CONTENT.DASHBOARD.EXPORT-WORKFLOWS' | translate }}</button> -->
                </div>
            </div>
            
            <div *ngIf="isAdmin">
                <button class="v-btn btn btn-primary vsight-button create-button text-white" (click)="openVideoModal()">Create Video Flow</button>
            </div>

          </div>
          <!-- <app-dashboard-filter></app-dashboard-filter> -->
          
        <div class="card list-area" style="padding-top: 95px;">
            <div class="pe-4 ps-3 filter-area">
                <div class="d-flex justify-content-between" style="padding-top: 2px;">
                    <div class="d-flex align-items-center">
                        <span class="ms-1 me-2 fs-14 font-weight-600">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.FILTER-BY' | translate }}</span>
                        <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 240px; border: none;">
                            <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
                                {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-LABEL' | translate }}
                            </button>
                            <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                                    aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                            </button>
                            <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                            overflow-y: scroll;" [formGroup]="labelGroup" aria-labelledby="dropdownMenuClickableInside">
                                <div class="search-box p-2">
                                    <input  type="text" class="form-control fs-14" [formControl]="labelSearchControl" style="height: 38px;"  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                                    <div class="search-icon me-2"> <img src="assets/img/search-copy.svg" alt=""></div>
                                </div>
                                <div *ngFor="let label of filteredLabelList; let index = index" class="ps-2">
                                    <div class="custom-control custom-checkbox custom-checkbox-green">
                                        <input
                                            type="checkbox"
                                            class="form-check-input ms-0"
                                            [id]="index"
                                            (click) = selectLabels(label)
                                            [formControlName]="label"
                                        />
                                        <label class="custom-control-label label-form fs-14" [for]="index">
                                            {{ label }}
                                        </label>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
    
                  <div class="search-box">
                    <input type="text" [formControl]="filter" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14">
                    <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                  </div>
                 
                </div>
    
                <div class="mt-3 d-flex flex-wrap" *ngIf="filteredLabels?.length">
                    <span class="fs-14 me-2">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECTED-LABELS' | translate }} : </span>
                    <span class="fs-12 label-span me-2 mb-2" *ngFor="let label of filteredLabels">{{label}} <img (click)="deleteLabel(label)" class="label-close cursor-pointer" src="assets/img/close.svg" alt=""></span>
                    <span class="fs-13 font-weight-600 cursor-pointer" [ngClass]="{'disabled': isLoadingFilter}" (click)="clearAll()">Clear all</span>
                </div>
              </div>
            <div class="" style="display: flex;flex-direction: column;text-align: center;justify-content: center;">
                <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell data-column-name="#" *matCellDef="let i = index">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="draft['listName']">
                        <th class="table-fs-14 fs-14 text-dark-grey font-weight-400 col-4" style="padding-left: 20px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.NAME' | translate }}</th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION'" *matCellDef="let element" class="fs-15 font-weight-700 d-flex col-4" style="text-align: start; padding-left: 20px;align-items: center;">
                           
                            <div>
                            <div (click)="showJsonSource($event, element)" [ngStyle]="{'pointer-events': !('showSourceBtn' | env) ? 'none' : '' }">{{ element.published?.['listName'] }}</div>
                                <div *ngIf="!element.published?.['listName'] && isAdmin">{{ element.draft?.['listName'] }}</div>
                                <div *ngIf="element.createdAt" class="description-info font-weight-400" title="{{element.createdAt | localizedDate | async}}">{{ element.createdAt | localizedDateShort | async}}</div>
                                <div *ngIf="element.createdBy" class="description-info font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.CREATED-BY' | translate }} : {{ getUsersName(element.createdBy)}}</div>
                                <div style="margin-top: -3px;">
                                    <span class="badge badge-secondary" style="color: #0ae6ff;
                                    background-color: #000F29;" *ngIf="element.badges">CreatedByAI</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="centered table-fs-14 fs-14 text-dark-grey font-weight-400 col-2">{{ isAdmin ? ('MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.TITLE' | translate) : 'Version' }} </th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="d-flex align-items-start justify-content-center flex-column col-2">
                            <div *ngIf="isAdmin">
                                <span *ngIf="element.published" class="fs-12 me-2">v{{element.published.version}}</span>
                                <span *ngIf="element.published" class="badge bg-success fs-12 font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.PUBLISHED' | translate }}</span>
                            </div>
                            <div class="mt-1" *ngIf="isAdmin">
                                <span *ngIf="element.draft" class="fs-12 me-2">v{{element.draft.version}}</span>
                                <span *ngIf="element.draft" class="badge bg-warning fs-12 font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.DRAFT' | translate }}</span>
                            </div>
                            <div *ngIf="!isAdmin" class="fs-12 me-2">v{{element.version}}</div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastEdited">
                        <th mat-header-cell *matHeaderCellDef class="col-2 centered table-fs-14 fs-14 text-dark-grey font-weight-400">{{ isAdmin ? ('MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.LAST-EDITED' | translate) : ('MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.PUBLISHED-AT' | translate)  }}</th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 d-flex align-items-start justify-content-center flex-column fs-14">
                            <div *ngIf="element.published?.updatedAt && isAdmin" title="{{element.published.updatedAt | localizedDate | async}}">
                                {{ element.published.updatedAt | localizedDateShort | async}}
                            </div>
                            <div *ngIf="element.draft?.updatedAt && isAdmin" class="mt-1" title="{{element.draft.updatedAt | localizedDate | async}}">
                                {{ element.draft.updatedAt | localizedDateShort | async}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="languages">
                        <th mat-header-cell *matHeaderCellDef class="col-3 centered table-fs-14 fs-14 text-dark-grey font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.LANGUAGES' | translate }}</th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 d-flex align-items-start justify-content-center flex-column fs-14">
                            <div *ngIf="element.published?.translations && isAdmin && isEditorMode">
                                <span *ngFor="let code of element.published.translations | keyvalue;let index = index">
                                    <span *ngIf="code?.key && index<4">{{$any(code.key) | uppercase}}<span *ngIf="index !== (element.published.translations | keyvalue).length-1 && index<3">,</span>
                                </span>
                                </span>
                                <span *ngIf="(element.published.translations | keyvalue).length>=4">...</span>
                            </div>
                            <div *ngIf="element.draft?.translations && isAdmin && isEditorMode" class="mt-1">
                                <span *ngFor="let code of element.draft.translations | keyvalue;let index = index">
                                    <span *ngIf="code?.key && index<4">{{$any(code.key) | uppercase}}<span *ngIf="index !== (element.draft.translations | keyvalue).length-1 && index<3">,</span></span>
                                </span>
                                <span *ngIf="(element.draft.translations | keyvalue).length>=4" [dynamicTooltip]="'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.REVERT' | translate">...</span>
                            </div>
                            <div *ngIf="element.translations && (!isAdmin || (isAdmin && !isEditorMode))">
                                <span *ngFor="let code of element.translations | keyvalue;let index = index">
                                    <span *ngIf="code?.key && index<4">{{$any(code.key) | uppercase}}<span *ngIf="index !== (element.translations | keyvalue).length-1 && index<3">,</span>
                                </span>
                                </span>
                                <span *ngIf="(element.translations | keyvalue).length>=4">...</span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="col-1 table-fs-14 text-center"></th>
                        <td mat-cell *matCellDef="let element" class="col-2 d-flex justify-content-end align-items-center" style="padding-right: 30px;">

                              <app-dropdown title="{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.TITLE' | translate }}" style="width: 120px;">
                                <ng-container *ngIf="!('electron' | env)">
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="showWorkflowUsers(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.USERS' | translate }}</a>
                                    </li>
                                    <hr>
                                </ng-container>
                                <li role="menuitem">
                                    <a class="dropdown-item" (click)="element.draft ? open(element,'draft') : createDraft(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.EDIT' | translate }}</a>
                                </li>
                                <li role="menuitem" *ngIf="element.draft">
                                    <a class="dropdown-item" (click)="publishWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.PUBLISH' | translate }}</a>
                                </li>
                                <li role="menuitem" *ngIf="element.published">
                                    <a class="dropdown-item" (click)="openPublishedView(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.PUBLISH-VIEW' | translate }}</a>
                                </li>
                                <li role="menu-item">
                                    <a class="dropdown-item text-red" (click)="deleteWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.DELETE' | translate }}</a>
                                </li>
                            </app-dropdown> 

                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <div>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </div>
                </table>
                <div *ngIf="!workflows.length && dataLoaded && isAdmin" class="mt-5 mb-5 fs-14 text-grey">No video flows found</div>

            </div>
        </div>
    </div>
</div>

<ng-template #empty>
    <div class="d-flex">
        <div class="col-6"></div>
        <div class="col-6">
            <img src="assets/img/empty.svg" alt="">
        </div>
    </div>
</ng-template>
