<div class="image-container"  #container
(mouseup)="onDocumentMouseUp($event)" (mousedown)="onDocumentMouseDown($event)"
    (mousemove)="onDocumentMouseMove($event)" (mouseleave)="onDocumentMouseLeave($event)"
    (mouseenter)="onDocumentMouseEnter($event)"
    (mousewheel)="onDocumentMouseWheel($event)">
    <img draggable="false" onselectstart="return false;" ondragstart="return false;" class="image-viewer" #image 
    src="{{ collaborationData.url }}"
    (load)="onImageLoad()"
    (progress)="onProgress($event)"
    crossOrigin="Anonymous">
</div>