import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { StepData, Template } from 'src/app/models/Step';

@Component({
  selector: 'app-step-preview',
  templateUrl: './step-preview.component.html',
  styleUrls: ['./step-preview.component.scss']
})
export class StepPreviewComponent implements OnInit,OnChanges {
  @Input('stepData') stepData?: StepData
  @Input('stepListCount') stepListCount?
  @Input('workflowName') workflowName?
  @Input() type?
  @Input() workflowTranslations?
  @Output() workflowTranslationsChange: EventEmitter<string> = new EventEmitter<any>();


  @Input() selectedLayoutNo?: number
  @Input() template?: Template
  @Input() selectedStepOrder?: number
  @Input() totalSectionCount?: number
  @Input() sectionStepsCount?: number
  @Input() sectionStepOrder?: number
  @Input() viewOnly?: boolean

  @Output() selectedLayoutNoChange: EventEmitter<number> = new EventEmitter<number>()
  @Output() emptyLayout: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() templateChange: EventEmitter<any> = new EventEmitter<any>()

  @Output() templateChangeAvailable: EventEmitter<any> = new EventEmitter<any>()
  @Output() onPreviewEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output() onPreviewRowDelete: EventEmitter<any> = new EventEmitter<any>()
  @Output() onPreviewColumnDelete: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('vertical') vElement: ElementRef;
  @ViewChild('horizontal') hElement: ElementRef;
  @ViewChild('div1') div1: ElementRef;
  @ViewChild('div2') div2: ElementRef;
  @ViewChild('step') step: ElementRef;


  width: number = 10;

  layoutCount = 1
  parentFlexDirection = "column"
  firstFlexGrow = 1
  secondFlexGrow = 1
  templateName = ''

  form = new UntypedFormGroup({})
  model: any = {}

  options: FormlyFormOptions = {
    formState: {
      disabled: true
    }
  };

  isMouseDown

  width1 = 47;
  width2 = 50;
  public startX = 0;
  public startWidth1 = 0;

  direction = 'horizontal';

  height1 = 47;
  height2 = 50;
  public startY = 0;
  public startHeight1 = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['template']?.currentValue && Object.keys(changes?.['template']?.currentValue).length>0) {
      this.layoutCount = changes['template']?.currentValue.count
      this.firstFlexGrow = changes['template']?.currentValue.weights[0]
      this.secondFlexGrow = changes['template']?.currentValue.weights[1]
      this.parentFlexDirection = changes['template']?.currentValue.orientation
      this.templateName = changes['template']?.currentValue.name
      
      if(changes['template']?.currentValue.orientation == 'row' && changes['template']?.currentValue.weights.length>1) {
        this.patchRatio('vertical')
        this.direction = 'vertical'
        this.height1 = 100
        this.height2 = 100
      }
      if(changes['template']?.currentValue.orientation == 'column' && changes['template']?.currentValue.weights.length>1) {
        this.patchRatio('horizontal')
        this.direction = 'horizontal'
        this.width1 = 100
        this.width2 = 100
      }
      if(changes['template']?.currentValue.count == 1) {
        this.width1 = 100
        this.width2 = null
        this.height1 =100
        this.height2 =100

      }
    }
  }

  ngOnInit(): void {
    if(this.template && Object.keys(this.template).length>0){
      this.layoutCount = this.template.weights.length
      this.firstFlexGrow = this.template.weights[0]
      this.secondFlexGrow = this.template.weights[1]
      this.parentFlexDirection = this.template.orientation
      this.templateName = this.template.name
    }
    if(this.parentFlexDirection == 'row' && this.template.weights.length>1) {
      this.direction = 'vertical'
      this.height1 = 100
      this.height2 = 100
    }
    if(this.parentFlexDirection == 'column') {
      this.direction = 'horizontal'
      this.width1 = 100
      this.width2 = 100
    }
  }

  onLayoutSelect(no: number): void {
    if (this.selectedLayoutNo != no) {
      this.selectedLayoutNo = no
      this.selectedLayoutNoChange.emit(no) 
    }
  }

  deleteLayout() {
    this.emptyLayout.emit(true)
  }

  patchRatio(direction) {
    if(this.firstFlexGrow == 1) {
      direction == 'vertical' ? this.width1 = 50 : this.height1 = 50
    }
    if(this.secondFlexGrow == 1) {
      direction == 'vertical' ? this.width2 = 50 : this.height2 = 50
    }
    if(this.firstFlexGrow <=10 && this.secondFlexGrow <=10 && (this.firstFlexGrow !==1)) {
      direction == 'vertical' ? this.width1 = this.firstFlexGrow*10 : this.height1 = this.firstFlexGrow*10
      direction == 'vertical' ? this.width2 =this.secondFlexGrow*10 : this.height2 =this.secondFlexGrow*10
    }
  }

  findDecimal(value) {
    if(value) {
      const stringValue = (value).toString();
      const decimalIndex = stringValue.indexOf('.');
      return  parseInt(stringValue.charAt(decimalIndex + 1))
    }else{
      return null
    }
    
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: any) {
    if(this.templateName !== 'fullscreen') {
      if (this.parentFlexDirection == 'row') {
        let weight1 =  this.findDecimal(this.width1) <=5 ? Math.floor(this.width1)/10 : Math.ceil(this.width1)/10
        let weight2 =  this.findDecimal(this.width2) <=5 ? Math.floor(this.width2)/10 : Math.ceil(this.width2)/10

        weight1 = this.findDecimal(weight1) <=5 ? Math.floor(weight1) : Math.ceil(weight1)
        weight2 = this.findDecimal(weight2) <=5 ? Math.floor(weight2) : Math.ceil(weight2)

        if((weight1 + weight2) < 10) {
          weight1 = weight1 + 1
        }

        let templateObj = {
          count : this.template.count,
          name : this.template.name,
          orientation : this.template.orientation,
          weights : [weight1,weight2]
        }
        this.templateChange.emit(templateObj)
    } else {

      let weight1 =  this.findDecimal(this.height1) <=5 ? Math.floor(this.height1)/10 : Math.ceil(this.height1)/10
      let weight2 =  this.findDecimal(this.height2) <=5 ? Math.floor(this.height2)/10 : Math.ceil(this.height2)/10

      weight1 = this.findDecimal(weight1) <=5 ? Math.floor(weight1) : Math.ceil(weight1)
      weight2 = this.findDecimal(weight2) <=5 ? Math.floor(weight2) : Math.ceil(weight2)

      if((weight1 + weight2) < 10) {
        weight1 = weight1 + 1
      }

      let templateObj = {
        count : this.template.count,
        name : this.template.name,
        orientation : this.template.orientation,
        weights : [weight1,weight2]
      }
      this.templateChange.emit(templateObj)
    }
    
    this.startX = 0;
    this.startY = 0;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    if (this.startX) {
      this.templateChangeAvailable.emit(true)
      const delta = event.clientX - this.startX;
      let newWidth1 = this.startWidth1 + delta / this.step.nativeElement.clientWidth * 100;
      if (newWidth1 < 30 || newWidth1 > 70) {
        return;
      }
      this.width1 = newWidth1;
      this.width2 = 100 - newWidth1;
    }
    if (this.startY) {
      this.templateChangeAvailable.emit(true)
      const delta = event.clientY - this.startY;
      let newHeight1 = this.startHeight1 + delta / this.step.nativeElement.clientHeight * 100;
      if (newHeight1 < 30 || newHeight1 > 70) {
        return;
      }
      this.height1 = newHeight1;
      this.height2 = 100 - newHeight1;
    }
  }

  onMouseDown(event: any) {
    if (this.parentFlexDirection == 'row') {
      this.startX = event.clientX;
      this.startWidth1 = this.width1;
    } else {
      this.startY = event.clientY;
      this.startHeight1 = this.height1;
    }
  }

  emitPreviewUpdates(data) {
    this.onPreviewEvent.emit(data)
  }

  onRowDeleted(rowIndexData) {
    this.onPreviewRowDelete.emit(rowIndexData)
  }

  onColumnDeleted(columnIndexData) {
    this.onPreviewColumnDelete.emit(columnIndexData)
  }
}