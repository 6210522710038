import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { SnackbarService } from '@services/support/snackbar.service';
import * as bowser from 'bowser';
import { first, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetailPageService } from '@services/detail-page.service';
import { RoomWrapperComponent } from '../../main/content/room-wrapper/room-wrapper.component';

@Component({
  selector: 'app-join-with-link',
  templateUrl: './join-with-link.component.html',
  styleUrls: ['./join-with-link.component.scss']
})
export class JoinWithLinkComponent implements OnInit, OnDestroy {

  @ViewChild("detailContainer", { read: ViewContainerRef, static: true }) detailContainer!: ViewContainerRef

  username = new UntypedFormControl('', Validators.required)

  publicLink = new UntypedFormControl('', Validators.required)
  copyPermission = new UntypedFormControl('', Validators.required)

  linkData
  linkDataError = null

  linkId
  currentLang
  allLangs
  subscriptions: Subscription[] = []

  browser: bowser.Parser.Parser
  showDownloadApp = false

  downloadAppButtonUrl = ""
  downloadSmartGlassesAppButtonUrl = null

  constructor(
    private route: ActivatedRoute,
    private detailPageService: DetailPageService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService
  ) {}

  
  ngOnInit(): void {
    this.detailPageService.setDetailContainer(this.detailContainer);

    let fullUrl
    this.route.url.subscribe(url => {
      fullUrl = url.join('/');
    })
    this.browser = bowser.getParser(window.navigator.userAgent)
    const isTouchDevice = 'ontouchstart' in window;
    if ((this.browser.is('mobile') || this.browser.is('tablet') || (this.browser.is("macOS") && isTouchDevice )) && (fullUrl !== 'videoflow')) {
      this.showDownloadApp = true
    }
    if (this.browser.is('iOS') || (this.browser.is("macOS") && isTouchDevice)) {
      this.downloadAppButtonUrl = environment.design.remoteAppStoreLink
    } else {
      this.downloadAppButtonUrl = environment.design.remoteGooglePlayLink
      this.downloadSmartGlassesAppButtonUrl = environment.design.remoteApkLink
    }

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.subscriptions.push(this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    }))
    this.loaderService.show()
    this.initialize()
  }

  initialize() {
    this.route.queryParams.pipe(first()).toPromise().then(param => {
      this.linkId = param['id']

      //Check the link to be set
      const isLinkAvailable = environment.design.guestLoginLinkAvailable
      const base = `${environment.endPoints.workflowBase}/share?${this.linkId}`
      isLinkAvailable ? this.publicLink.patchValue(base) : this.publicLink.patchValue(window.location.href)

      return this.authService.getJoinLinkData(param['id'])
    })
    .then(linkData => {
      this.linkData = linkData
    })
    .catch(error => {
      if (error.error === 'session-not-found') {
        this.linkDataError = "Session not found"
      } else if (error.error === 'session-expired') {
        this.linkDataError = "Session expired"
      } else {
        this.linkDataError = "An error occured"
      }
    })
    .finally(() => this.loaderService.hide())
  }

  getMeetingDuration(start: number, end: number) {
    const i3 = Math.floor((end - start) / 60000);
    const h3 = Math.floor(i3/60);
    const m3 = i3 % 60;

    return `${h3}h ${m3}m`;
  }

  joinRoom() {
    let guestName = this.username.value
    this.openRoomInterface(this.linkId, guestName)
  }

  openRoomInterface(linkId: string, guestName: string) {
    const [instance, onClose] = this.detailPageService.loadComponent(RoomWrapperComponent, {})
    instance.roomId = null
    instance.users = null
    instance.linkId = linkId
    instance.guestName = guestName
    onClose.then(() => {
      this.username.reset()
      this.username.markAsPristine()
    })
  }

  onLanguageSelected(lang: any) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  clickStoreLink(link) {
    if (this.copyPermission.value) {
      link.select()
      document.execCommand('copy');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

}
