<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">All Users</div>
            <div class="cursor-pointer"  (click)="modalRef.hide()">
                <img src="assets/img/close_white.svg" alt="">
            </div>
        </div>
    
        <div class="card-body modal-card-body p-0" style="padding-bottom: 20px !important;">
            <div class="card-body-overlay">
                <div *ngFor="let user of userList" class="fs-14 text-dark-grey font-weight-700 ms-2 me-2 mt-2 text-start d-flex align-items-center">
                    <div>{{user.name}} <span class="text-grey ms-1" *ngIf="user['isGuest']">({{ 'MAIN.ROOMS.ROOM_LIST.GUEST' | translate }})</span></div>
                </div>
                
            </div>
    
            <div class="d-flex top-gap-30 justify-content-center">
                <button type="button" class="v-btn vsight-button btn vsight-button-outline-secondary btn-outline-secondary" (click)="modalRef.hide()">
                    {{ 'MAIN.CONTENT.DASHBOARD.CLOSE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>