import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-radio',
  template: `


<div class="form-check w-100 text-start" style= "pointer-events:none">
        <div class="row">
          <ng-container *ngFor="let option of radioOptions; let i=index">
            <div [ngClass]="{'col-md-6': radioOptions.length > 2, 'col-md-12': radioOptions.length <= 2}">
              <div class="custom-control ps-0 custom-radio" style="align-items: start;">
              <input
              type="radio"
              class="form-check-input"
              [id]="'input-'+option.value"
              [formlyAttributes]="field"
              [value]="option.value"
            />
            <label class="form-check-label ps-1" [for]="'input-'+option.value" style="overflow: auto; margin-top:2px">
            {{ option.label }}
            </label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
  `,
})
export class FormlyFieldCustomRadio extends FieldType {
  get type() {
    return this.to.type || 'text';
  }

  get radioOptions(): any[] {
    return this.to.options as any[];
  }
}