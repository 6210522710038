import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step-files',
  templateUrl: './step-files.component.html',
  styleUrls: ['./step-files.component.scss']
})
export class StepFilesComponent implements OnInit, OnChanges {

  @Input() selectedStepFiles?
  @Input() workflowId?
  @Output() selectedStepFilesChange: EventEmitter<any> = new EventEmitter();

  isUpload
  isLoading
  subscriptions: Subscription[] = []
  isDownloading = false

  constructor(private workflowService: WorkflowService,
    public loaderService: LoaderService,
    public modalRef: BsModalRef,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private http: HttpClient,
    private snackBarService: SnackbarService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.selectedStepFiles) {
      this.selectedStepFiles = []
    }
  }

  ngOnInit(): void {
    
  }

  chooseButtonUpload (event: any): void {
    this.isUpload = true
    let selectedFile =  event.target.files
    this.previewUpload(selectedFile)
  }

  dragDropUpload (event: any): void {
    if (!environment.electron) {
      let selectedFile = event[0]
      this.previewUpload(selectedFile)
    }
  }

  private previewUpload (selectedFile: File): void {
    if (selectedFile) {
      this.isLoading = true;
      this.loaderService.show()

      if(!this.selectedStepFiles) {
        this.selectedStepFiles = []
      }

      this.subscriptions.push(this.uploadfile(selectedFile).subscribe({
        next: (resp) => {
          if(resp && resp['data']) {
            resp['data'].forEach(item => {
              const stepFileData = {
                url : item.url,
                name : item.name,
                type : item.mimetype,
                id : item.id
              }
              this.selectedStepFiles.push(stepFileData)
              this.selectedStepFilesChange.emit(this.selectedStepFiles)
            })
          }
          this.loaderService.hide()
          this.isUpload = false
          this.isLoading = false;
        },
        error: (e) => {
          this.snackBarService.error(this.translateService.instant('ERRORS.UPLOAD-ERROR'))
          this.loaderService.hide()
        }
      }
      ))
    }
  }

  uploadfile(file) {
    let formData = new FormData();

    if(file.length){
      for (let i = 0; i < file.length; i++) {
        formData.append(file[i].name, file[i])
      }
    }else{
      formData.append(file.name, file)
    }
    return this.workflowService.uploadWorkflowFiles(this.workflowId, formData)
  }

  close(): void {
    this.modalRef.hide();
  }

  deleteStepFile(index) {
    const initialState = {
      textMessage: this.translateService.instant('MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.DELETE-FILE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-FILE')
    }
    let alertmodalRef = this.modalService.show(AlertModalComponent, { initialState, backdrop: 'static', class: 'modal-dialog-centered', animated: false });
    this.subscriptions.push(
      alertmodalRef.content.onClose.subscribe((res) => {
        if (res) {
          this.selectedStepFiles.splice(index,1)
          this.selectedStepFilesChange.emit(this.selectedStepFiles)
        }
      })
    ) 
  }

  downloadStepFile(file) {
    this.isDownloading = true
    if(file.url) {
      this.downloadFile(file.url).subscribe({
        next: (data: Blob) => {
          const blob = new Blob([data], { type: file.type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          link.download = file.name;
          link.click();
          window.URL.revokeObjectURL(link.href);
          this.isDownloading = false
        },
        error: (e) => {
          this.isDownloading = false
        }
      })
    }
  }
  uploadedFilesChange(event) {
    if(event) {
      event.forEach(item => {
        const stepFileData = {
          url : item.url,
          name : item.name,
          type : item.mimetype,
          id : item.id
        }
        this.selectedStepFiles.push(stepFileData)
        this.selectedStepFilesChange.emit(this.selectedStepFiles)
      })
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

}
