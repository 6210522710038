import { Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WorkflowService } from '@services/workflow.service';

@Component({
  selector: 'app-work-order-filter',
  templateUrl: './work-order-filter.component.html',
  styleUrls: ['./work-order-filter.component.scss']
})
export class WorkOrderFilterComponent implements OnInit, OnChanges {
  @ContentChild('searchInput') searchInput: ElementRef;

  @Input() allLabels?
  @Input() filterReset?
  @Input() selectedTabIndex?
  @Output() filterChanged : EventEmitter<any> = new EventEmitter()


  filterForm: UntypedFormGroup = new UntypedFormGroup({
    nameFilter: new UntypedFormControl(),
    formFieldFilter: new UntypedFormControl(),
    statusFilter: new UntypedFormControl()
  })

  statusGroup = new UntypedFormGroup({
    open: new UntypedFormControl(),
    'in-progress': new UntypedFormControl(),
    'on-hold': new UntypedFormControl(),
    done: new UntypedFormControl()
  })

  priorityGroup = new UntypedFormGroup({
    high: new UntypedFormControl(),
    medium: new UntypedFormControl(),
    low: new UntypedFormControl(),
  })

  name = new UntypedFormControl()
  dueDate = new UntypedFormControl()
  
  labelGroup = new UntypedFormGroup({})
  labelSearchControl = new UntypedFormControl()

  userList = []
  priorityOptions = ['high', 'medium', 'low']
  statusOptions = ['open', 'in-progress', 'on-hold']

  filteredLabels = []
  filteredLabelList = []

  selectedStatusList = []
  selectedPriorityList = []
  selectedAssigneeList = []

  constructor(
    private workflowService: WorkflowService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.['filterReset']) {
      this.selectedStatusList  = []
      this.selectedPriorityList  = []
      this.selectedAssigneeList  = []

      const filterList= {
        status: this.selectedStatusList,
        priority: this.selectedPriorityList,
        assignee: this.selectedAssigneeList
      }
      this.filterChanged.emit(filterList)
    }
  }

  ngOnInit(): void {
    this.workflowService.getUsers().then(resp => {
      if(resp) {
        let mappedUsers = resp.users.map(user => ({
          id: user.id,
          name: user.name,
        }))
        this.userList = mappedUsers
      }
    })

    this.filteredLabelList = this.allLabels
    if(this.allLabels) {
      this.allLabels.forEach(label => {
        let labelControl = new UntypedFormControl (false)
        this.labelGroup.addControl(label, labelControl)
      })
    }

    this.priorityGroup.valueChanges.subscribe(res => {
      if(res) {
        this.selectedPriorityList = Object.keys(res).filter(priority => res[priority])
        this.filterChange()
      }
    })

    this.statusGroup.valueChanges.subscribe(res => {
      if(res) {
        this.selectedStatusList = Object.keys(res).filter(status => res[status])
        this.filterChange()
      }
    })

    this.name.valueChanges.subscribe(name => {
      this.filterChange()
    })
  }

  selectStatus(status) {
  }

  selectPriority(priority) {
  }

  selectAssignee(assignee) {
    if( this.selectedAssigneeList.includes(assignee.id) ) {
      let assigneeIndex = this.selectedAssigneeList.findIndex(res => res == assignee.id)
      this.selectedAssigneeList.splice(assigneeIndex, 1)
    }else{
      this.selectedAssigneeList.push(assignee.id)
    }
    this.filterChange()
  }

  selectLabels(label) {
    if( this.filteredLabels.includes(label) ) {
      let labelIndex = this.filteredLabels.findIndex(res => res == label)
      this.filteredLabels.splice(labelIndex, 1)
    }else{
      this.filteredLabels.push(label)
    }

  }

  filterChange() {
    const filterList= {
      status: this.selectedStatusList,
      priority: this.selectedPriorityList,
      assignee: this.selectedAssigneeList,
      name: this.name ? this.name.value : null
    }
    this.filterChanged.emit(filterList)

  }

  toggle(): void {
    if (this.searchInput?.nativeElement) {
      setTimeout(() => {
        (this.searchInput.nativeElement as HTMLInputElement).focus();
      });
    }
  }

}
