import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Room } from '@models/Room';
import { User } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { DetailPageService } from '@services/detail-page.service';
import { ModalService } from '@services/remote/modal.service';
import { RoomService } from '@services/remote/room.service';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { UserService } from '@services/user.service';
import { debounceTime, distinctUntilChanged, first, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoomDetailComponent } from './room-detail/room-detail.component';
import { WorkflowService } from '@services/workflow.service';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnChanges, OnInit, OnDestroy {

   @ViewChild('stepListStepOptions', {static: false}) stepListStepOptions: ElementRef;


  @ViewChild("toggleTemplate", { static: true }) private toggleTemplate: TemplateRef<any>;
  @Input() detailed: boolean = true;
  @Input() roomCreate: boolean = true;


  rooms: Room[] = [];
  roomsSub: Subscription = null;

  users: User[] = [];
  userNames: any[] = [];
  userSub: Subscription = null;

  searchTextChangedSource: Subject<string> = new Subject<string>();
  searchTextChangedSub: Subscription = null;

  allRooms: any[] = null;

  textfieldInput: any = false;

  selectedUserFilter: string[] = []
  roomName: string = "";

  isLoading = true
  roomDetailOpen = false
  accountData

  constructor(
    // private accountService: AccountService,
    private roomService: RoomService,
    // private modalService: BsModalService,
    // private detailPageService: DetailPageService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private workflowService: WorkflowService,
    private snackBarService: SnackbarService,
    private router: Router,
    private detailPageService: DetailPageService,
    private authService: AuthService,
    private modalService: ModalService
  ) {
    // Code piece runs when user touches the textfield search.
    this.searchTextChangedSub = this.searchTextChangedSource.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(text => {
      this.textfieldInput = text;
      var selectedUsers = [...this.selectedUserFilter];
      this.rooms = this.allRooms
        .filter(room => {
          let roomNameSelected = text ? room.room_data.name.toLowerCase().match(text.toLowerCase()) : true;
          return roomNameSelected;
        })
        .filter(room => {
          var users = Object.keys(room.room_data.users).reverse();
          if (users.length === 0 && selectedUsers.length === 0) { return true }
          else {
            for (var i=0; i<users.length; i++){
              var value = room.room_data.users[users[i]];
              let userSelected = (selectedUsers && selectedUsers.length > 0) ? selectedUsers.includes(value.name) : true;
              if (userSelected === true) { 
                return userSelected;
              }
            }
            return false;
          }
        })
        .sort((a, b) => {
          if (!a.room_data.disabled && b.room_data.disabled === true) {
            return -1;
          } else if (a.room_data.disabled === true && !b.room_data.disabled) {
            return 1;
          } else {
            return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
          }
        })
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.['roomCreate']) {
      if(changes['roomCreate'].currentValue) {
        this.onNewRoom()
      }
    }
  }

  ngOnInit() {
    this.loaderService.show()
    this.authService.getAccountData().then(account => {
      this.accountData = account.data.accountData;
    })
    this.roomsSub = this.roomService.rooms.subscribe({
      next: (rooms) => {
          let disableFilteredRoomList = rooms
          .filter(room => {
            let roomNameSelected = this.textfieldInput ? room.room_data.name.toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
            return roomNameSelected;
          })
          .filter(room => {
            var users = Object.keys(room.room_data.users).reverse();
            if (users.length === 0 && this.selectedUserFilter.length === 0) { return true }
            else {
              for (var i=0; i<users.length; i++){
                var value = room.room_data.users[users[i]];
                let userSelected = (this.selectedUserFilter && this.selectedUserFilter.length > 0) ? this.selectedUserFilter.includes(value.name) : true;
                if (userSelected === true) { 
                  return userSelected;
                }
              }
              return false;
            }
          })
          .sort((a, b) => {
          if (!a.room_data.disabled && b.room_data.disabled === true) {
            return -1;
          } else if (a.room_data.disabled === true && !b.room_data.disabled) {
            return 1;
          } else {
            return a.room_data?.name?.toLocaleLowerCase().localeCompare(b.room_data?.name?.toLocaleLowerCase());
          }
        });
        this.rooms = [...disableFilteredRoomList]

        this.allRooms = rooms.sort((a, b) => {
          const nameA = a.room_data?.name?.toLocaleLowerCase() || '';
          const nameB = b.room_data?.name?.toLocaleLowerCase() || '';
          return nameA.localeCompare(nameB);
        });
        // this.allRooms = rooms.sort((a, b) => a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase()));
        this.loaderService.hide()
        this.isLoading = false
      },
      error: () => {
        this.loaderService.hide()
        this.isLoading = false
      }
      }
    )

    this.workflowService.getAllUsersForWorkflow().then(res => {
      this.users = res.users.filter(user => user.auth && !user.auth.deleted).sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.userNames = this.users.map(user => user.name);
    })

    // this.userSub = this.userService.users.subscribe(u => {
    //   this.users = u.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    //   this.userNames = this.users.map(user => user.name);
    // });
  }

  ngOnDestroy() {
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.searchTextChangedSub) { this.searchTextChangedSub.unsubscribe() }
  }

  toggleItems(event: Event, roomData) {
    event.stopPropagation();
    roomData['isExpanded'] = !!!roomData['isExpanded'];
  }

  getRoomUsers(roomUsers: any) {
    return (roomUsers ? Object.keys(roomUsers).map(userId => roomUsers[userId]) : [])
    .sort((a, b) => {
      if (a.in_room && !b.in_room) {
        return -1;
      } else if (!a.in_room && b.in_room) {
        return 1;
      } else {
        return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
      }
    });
  }

  async onNewRoom() {
    console.log(this.accountData)
    if (this.accountData.license.room_limit && !isNaN(this.accountData.license.room_limit) && this.accountData.license.room_limit > 0 && this.rooms.length >= this.accountData.license.room_limit) {
      this.snackBarService.error(this.translateService.instant('MAIN.ROOMS.ROOM_LIST.ROOM_LIMIT_REACHED'))
      return;
    }
    const [instance, onClose] = this.detailPageService.loadComponent(RoomDetailComponent)
    instance.action = RoomDetailComponent.ACTION_NEW;
  }

  onRoomDetails(e: MouseEvent, room: Room) {
    e.stopPropagation();
    // this.router.navigate(['/room-detail'], { 
    //   queryParams: { id: room?.id}
    // })

    this.roomDetailOpen = true;
    const [instance, onClose] = this.detailPageService.loadComponent(RoomDetailComponent)
    instance.action = RoomDetailComponent.ACTION_DETAILS;
    instance.roomId = room.id;
    onClose.then(() => {
      this.roomDetailOpen = false;
    })

    // const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
    // detail.action = RoomDetailsComponent.ACTION_DETAILS;
    // detail.roomId = room.id;
  }

  onRoomEdit(e: MouseEvent, room: Room) {
    e.stopPropagation();
    if (!room.room_data.disabled) {
      const [instance, onClose] = this.detailPageService.loadComponent(RoomDetailComponent);
      instance.action = RoomDetailComponent.ACTION_EDIT;
      instance.roomId = room.id;
      onClose.then(() => {
        this.roomDetailOpen = false;
      })
    }
  }

  onFilterChanged() {
    var selectedUsers = [...this.selectedUserFilter];

    this.rooms = this.allRooms
      .filter(room => {
        let roomNameSelected = this.textfieldInput ? room.room_data.name.toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
        return roomNameSelected;
      })
      .filter(room => {
        var users = Object.keys(room.room_data.users).reverse();
        if (users.length === 0 && selectedUsers.length === 0) { return true }
        else {
          for(var i = 0; i < users.length; i++){
            var value = room.room_data.users[users[i]];
            let userSelected = (selectedUsers && selectedUsers.length > 0) ? selectedUsers.includes(value.name) : true;
            if (userSelected === true) {
              return userSelected;
            }
          }
          return false;
        }
      })
      .sort((a, b) => {
        if (!a.room_data.disabled && b.room_data.disabled === true) {
          return -1;
        } else if (a.room_data.disabled === true && !b.room_data.disabled) {
          return 1;
        } else {
          return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
        }
      })
  }

  replaceSpecialCharactersWithSpace(text: string): string {
    return text.replace(/([.?*+^$[\]\\(){}|-])/g, ' ');
  }

  onSearchTextChange(text: string): void {
    this.searchTextChangedSource.next(this.replaceSpecialCharactersWithSpace(text));
  }
  
  onRoomToggle(e: MouseEvent, room: Room) {
    e.stopPropagation();

    const modalId = this.modalService.show({
      template: this.toggleTemplate,
      context: {
        dataModel: room.room_data,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.roomService.toggleRoomStatus(room.id)
            .then(disabled => {
              if (disabled) {
                this.translateService.instant('MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLED', { cssClass: 'alert-success' });
              } else {
                this.translateService.instant('MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLED', { cssClass: 'alert-success' });
              }
            })
            .catch(error => this.translateService.instant('MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ERROR'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }
}
