import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from '@services/db.service';
import { ReportService } from '@services/report.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, Subscription } from 'rxjs';
import { JsonViewerComponent } from 'src/app/components/modals/json-viewer/json-viewer.component';
import { User } from 'src/app/models/User';
import { WorkflowReport } from 'src/app/models/WorkflowReport';
import { DetailPageService } from 'src/app/services/detail-page.service';
import { LoaderService } from 'src/app/services/support/loader.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { environment } from 'src/environments/environment';
import { ReportComponent } from '../report/report.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {
  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [
    "name",
    "version",
    "user",
    "status",
    "select"
  ];

  reportList: WorkflowReport[] = []
  isLoading = false

  allUsers: User[] = []
  usersLoading: boolean = true
  subscriptions: Subscription[] = []
  reportUser = ''
  reportSelected
  loadedReport;
  workflowPublishedVersion
  newArray = []
  isDownloading

  isLoadingMore = false;
  limit = 10;

  isAdmin: boolean = false
  hasEditorAccess: boolean = false

  private originalReportList = [];

  showCountPanel = false
  licenseCount
  licenseLimit
  isEwers = false

  filterForm: UntypedFormGroup = new UntypedFormGroup({
    nameFilter: new UntypedFormControl(),
    labelFilter: new UntypedFormControl()
  })

  tabIndex = 0

  constructor(
    private authService: AuthService,
    private workflowService: WorkflowService,
    private detailPageService: DetailPageService,
    private route: ActivatedRoute,          
    private loaderService:LoaderService,
    private snackBarService: SnackbarService,
    private reportService: ReportService,
    private modalService: BsModalService,
    private dbService: DbService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin
      this.hasEditorAccess = user.editorAccess
    }))
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['tab'] === "remote") {
        this.tabIndex = 1
      }
    });

    this.loaderService.show()
    this.subscriptions.push(this.route.queryParams.subscribe(res => {
      if (res['report'] && !res['step'] && !this.loadedReport) {
        this.getSingleReport(res['report']).then((report) => this.openReport(report));
      }
      if (res['step'] && !res['index'] && !this.loadedReport) {
        this.getSingleReport(res['report']).then((report) => this.openReport(report, res['step'], res['key']));
      }
      if (res['index'] && !this.loadedReport) {
        this.getSingleReport(res['report']).then((report) => this.openReport(report, res['step'], res['key'], res['index']));
      }

    }))

    this.isEwers = (environment.design.shortName == 'ewers')

    if (!environment.electron) {
      this.workflowService.getAllUsersForWorkflow().then(result => {
        this.allUsers = result.users
      })
    }
    this.reportListAssign()
    // this.getReportList();
    this.subscriptions.push(this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      this.filterReports(res);
    }))
  }

  onAllReports() {
    this.tabIndex = 0
    this.router.navigate(['/reports/workflow']);
  }

  onAllSessions() {
    this.tabIndex = 1
    this.router.navigate(['/reports/remote']);
  }

  showJsonSource(event, report) {
    event.stopPropagation()

    this.modalService.show(JsonViewerComponent, {
      initialState: {
        title: report.workflowName,
        json: report,
        expanded: false
      },
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  getUsersName(uid) {
    const user = this.allUsers.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }

  getSingleReport(reportId) {
    //this.loaderService.show()
    return this.workflowService.getAllWorkflowReports(true).then(reports => reports.reportList.find((report) => reportId == report.id))
      .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.ERROR')))
      .finally(() => this.loaderService.hide())
  }

  getReportList() {
    this.isLoading = true
    this.initializeReports().then(() => {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case "name":
            return item.workflowName;
          default:
            if (item.createdAt) {
              return item.createdAt;
            }
        }
      };
      this.dataSource.sort = this.sort;
      this.isLoading = false
      this.loaderService.hide()
    })
  }

  async reportListAssign() {
    this.isLoading = true
    this.workflowService.getAllWorkflowReports(true).then(res => {
      if(res.license?.exceeded) {
        this.licenseCount = res.license.count
        this.licenseLimit = res.license.limit
        this.showCountPanel = true
      }
      this.reportList = res.reportList
      this.dataSource = new MatTableDataSource(this.reportList)
      this.mapReportList()
    })
  }

  async mapReportList() {
    this.reportList.sort(function(a, b) { 
      if (a.createdAt < b.createdAt) {return 1}
      else if (a.createdAt > b.createdAt){return -1}
      return 0;
    })
    this.isLoading = false
    this.dataSource = new MatTableDataSource(this.reportList)
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "name":
          return item.workflowName;
        default:
          if (item.createdAt) {
            return item.createdAt;
          }
      }
    };
    this.dataSource.sort = this.sort;

    this.loaderService.hide()
  }

  async initializeReports(): Promise<void> {
    this.originalReportList = await this.workflowService.getAllWorkflowReports(true);
    this.originalReportList = this.originalReportList.sort(function (a, b) {
      const firstDate = new Date(a.createdAt);
      const secondDate = new Date(b.createdAt);

      if (firstDate < secondDate) { return 1 }
      else if (firstDate > secondDate) { return -1 }
      return 0;
    });
    this.reportList = this.originalReportList
    this.dataSource = new MatTableDataSource(this.reportList);
  }

  async export() {
    this.isDownloading = true
    let workflowIds = []
    let distinctIds: any
    let index = 0;
    for (const wfReportData of this.dataSource.filteredData) {
      let cloneReportData = JSON.parse(JSON.stringify(wfReportData))
      index++;
      workflowIds.push(cloneReportData.workflowId)
      distinctIds = new Set([...workflowIds])

    }
    this.mapListForCsv(distinctIds)
  }

  async mapListForCsv(distinctIds?, id?) {
    let workflows = []
    let workflowObject = {}
    let exportArray = []

    for await (const results of distinctIds) {
      await this.workflowService.getAllPublishedWorkflowVersions(results).then(workflow => {
        workflowObject[results] = workflow
        workflows.push(workflow)
      })
    }

    for (const wfReportData of this.dataSource.filteredData) {
      const wf = workflowObject[wfReportData.workflowId].find(res => res.version == wfReportData.version)

      let cloneReportData = JSON.parse(JSON.stringify(wfReportData))

      cloneReportData.workflowDescription = wf.translations ? wf.translations[wf.defaultLanguage][wf.description] : cloneReportData.workflowDescription

      if (cloneReportData.results && cloneReportData.results.length) {
        cloneReportData.results.forEach((reportData) => {
          if (!Object.keys(reportData.data).length) {
            reportData.data = { isValid: true, labels: [] }
          }
          this.loaderService.hide()
          let duration = 0;
          reportData.durations?.forEach(durationData => {
            duration += durationData?.duration
          })

          if (wf) {
            const stepData = wf?.steps?.[reportData.stepId]?.data;
            if (stepData) {
              let cloneStepData = JSON.parse(JSON.stringify(stepData))

              if (wf.translations) {
                cloneStepData.name = wf.defaultLanguage ? wf.translations[wf.defaultLanguage][cloneStepData.name] : wf.translations['en'][cloneStepData.name]
                cloneStepData.layouts.forEach(layout => {
                  layout.items[0].templateOptions.label = wf.defaultLanguage ? wf.translations[wf.defaultLanguage][layout.items[0].templateOptions.label] : wf.translations['en'][layout.items[0].templateOptions.label]
                })
              }
              let firstLayout = cloneStepData.layouts[0]?.items[0].key
              let secondLayout = cloneStepData.layouts[1]?.items[0].key
              let reportArray = []
              reportArray.push({
                key: firstLayout,
                data: reportData.data[firstLayout],
                index: 0
              })
              if (secondLayout) {
                reportArray.push({
                  key: secondLayout,
                  data: reportData.data[secondLayout],
                  index: 1
                })
              }
              reportData['reportArray'] = reportArray
              reportData['stepName'] = cloneStepData.name
              reportData['duration'] = duration && duration > 0 ? '~' + this.convertMsToMinutesAndSecons(duration) : 'Less than 1s';

              reportData = this.reportService.mappingReport(reportData, cloneStepData)
              if(wf.translations) {
                reportData.reportArray.forEach(report => {
                  if(report.data.type == 'radio') {
                    let radioVal 
                    if(Array.isArray(report.data.value)) {
                        radioVal = report.data.options.find(option => option.value == report.data.value[0])
                    }else{
                      radioVal = report.data.options.find(option => option.label == report.data.value)
                    }
                    if(radioVal) {
                      report.data.value = wf.translations[wf.defaultLanguage][radioVal.label]
                    }
                  }
                  if(report.data.type == 'multicheckbox') {
                    let multiVal = []
                    report.data.values.forEach(res => {
                      let val = report.data.options.filter(option => option.label == res)
                      if(val.length) {
                        multiVal.push(wf.translations[wf.defaultLanguage][res])
                      }
                    })
                    report.data.value = multiVal
                  }
                })
              }
              
            }

          }
        });
      }
      exportArray = exportArray.concat(this.reportService.mapReportDataForCsvExport(cloneReportData, wf, this.getUsersName(wfReportData.userId), wfReportData.id))
    }
    this.reportService.download(exportArray)
    this.isDownloading = false
  }

  convertMsToMinutesAndSecons(ms) {
    let minutes = (ms / 1000) / 60;
    let seconds = (ms / 1000) % 60;
    if (minutes >= 1) {
      return (Math.floor(minutes) + " m " + Math.ceil(seconds) + " s")
    } else {
      return Math.ceil(seconds) + "s"
    }
  }

  filterReports(filteredForm) {
    let filteredData = this.reportList.filter((report) => (!filteredForm.nameFilter || (report.workflowName ? report.workflowName.toLocaleLowerCase().includes(filteredForm.nameFilter.toLocaleLowerCase())  : null)) && (!filteredForm.labelFilter || (report['filterValue'] ? report['filterValue'].toLocaleLowerCase().includes(filteredForm.labelFilter.toLocaleLowerCase())  : null)))
    this.dataSource = new MatTableDataSource(filteredData);
  }


  async openReport(report, step?, key?, index?) {
    this.loaderService.show()
    this.reportSelected = true
    // if(step){
    //   this.loaderService.show()
    // }
    this.workflowService.getPublishedWorkflowVersion(report.workflowId, report.version)
      .then(workflowPublished => {
        this.loadedReport = report.id;
        const [instance, onClose] = this.detailPageService.loadComponent(ReportComponent, { report: report.id, step: step, key: key })
        instance.report = report
        instance.reportUser = this.getUsersName(report.userId)
        instance.workflowPublishedVersion = workflowPublished
        instance.step = step
        instance.key = key
        instance.index = index
        // TODO should we fetch report list again?
        onClose.then(() => {
          this.reportSelected = false
          this.loadedReport = null;
        })
      })
      .finally(() => this.loaderService.hide())
  }

  importReports() {
    this.dbService.importReports()
      .then(errorCode => {
        if (errorCode) {
          if (errorCode === 'choose-canceled') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.IMPORT-CANCELED'))
          } else if (errorCode === 'reports-content-not-found') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT.REPORT-NOT-FOUND'))
          } else if (errorCode === 'unknown-error') {
            this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
          } else if (errorCode === 'wrong-report-format') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.REPORT-FORMAT-WRONG'))
          }
        } else {
          this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.IMPORT-SUCCESSFUL'))
          this.getReportList();
        }
      })
  }

  changeToPrefilled(report) {
    const initialState= {
      textMessage: this.translateService.instant('MAIN.CONTENT.DASHBOARD.SUBMITTED-REPORT-TO-PREFILLED-TEXT'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.CONTINUE'),
      confirmButtonStyle: 'primary',
      headerTitle: this.translateService.instant('MAIN.CONTENT.DASHBOARD.SUBMITTED-REPORT-TO-PREFILLED-TITLE'),
      instantClose:false
    };
    let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    confirmModalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.loaderService.show()
        this.workflowService.workflowReportToPrefilled(report.id).then(response => {
          report.status = 'prefilled'
          this.reportListAssign();
        })
        .catch(err => console.log(err))
        .finally(() => {})
      }
      confirmModalRef.hide();
    })
  }

  onTabClicked(index) {
    this.tabIndex = index
  }

}
