<ng-container *ngIf="reportList.length == 0 && !isLoading">
    <div class="d-flex align-items-center me-5 ms-5">
        <div class="col-md-4 col-sm-12" style="margin-right: 3rem !important;
    margin-left: 2rem;">
            <p class="fs-32">{{ 'MAIN.CONTENT.REPORT-LIST.NO-REPORT.TITLE' | translate }}</p>
            <div class="fs-16-light">{{ 'MAIN.CONTENT.REPORT-LIST.NO-REPORT.TEXT' | translate }}</div>
        </div>
        <div class="col-md-5 col-sm-12">
            <img src="assets/img/empty.svg" alt="">
        </div>
    </div>
</ng-container>

<div class="workflow-background" *ngIf="showWorkflow"></div>

<div style="position: absolute;z-index:1000" *ngIf="showWorkflow">
    <div class="workflow-box" cdkDrag  handleResize  [(height)]="boxHeight" [(width)]="boxWidth" 
    [style.width.px]="boxWidth" [style.height.px]="boxHeight" [maxWidth]="700" [maxHeight]="700" [minWidth]="400" [minHeight]="300">
        <div cdkDragHandle style="cursor: move;border-bottom: solid 1px #eeeeee;
        padding-bottom: 5px;">
            <!-- <div class="move-handle">
                <svg width="20px" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
            </div> -->
            <div class="d-flex align-items-center">
                <div class="col-2 d-flex h-100 text-start ps-3">
                    <img src="assets/img/amblem.svg" alt="" style="height: 26px;">
                </div>
                <div class="col-8 text-center">
                    <label class="cursor-move text-grey" *ngIf="wfName">{{wfName}}</label>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-end">
                    <div *ngIf="isStepFileAvailable && isStart" class="me-2">
                        <img src="assets/img/step-files-icon.svg" alt="" class="cursor-pointer" (click)="showStepFiles()">
                    </div>
                    <div class="me-2" [ngStyle]="{'pointer-events': isStart ? '' : 'none'}" *ngIf="workflow.showContentPage || isEwers || (workflow.expertsToCall?.length > 0)">
                        <img src="assets/img/list-content.svg" class="cursor-pointer" alt="" height="13px" (click)="switchWorkflowMenu()">
                    </div>
                    <div class="close text-grey" (click)="close()">
                        <img src="assets/img/close-workflow.svg" height="14px">
                    </div>
                </div>
            </div>
            
        </div>
        <app-user-workflow style="display:block; height:100%; width: 100%;margin-top: 20px;min-width: 200px;min-height:200px;"
            [workflow]="workflow"
            (workflowSubmitted)= "workflowSubmitted($event)"
            [(savedDraft)]= "savedDraft" 
            (savedDraftChage)= "savedDraftChage($event)" 
            [(contentListClicked)]="contentListClicked"
            (contentListClickedChange)="contentListClickedChange($event)"
            [results]= "results" 
            [report]= "report" 
            [reportId]= "reportId"
            (isWorkflowStart)="isWorkflowStart($event)"
            [(workflowMenuClicked)]="workflowMenuClicked"
            (workflowMenuClickedChange)= "workflowMenuClickedChange($event)"
            [(savedPrefilled)] = "savedPrefilled"
            (savedPrefilledChange)= "savedPrefilledChange($event)"
            (isWorkflowStart)="isWorkflowStart($event)"
            [reportStatus]= "reportStatus"
            (langChanged)="langChanged($event)"
            [(stepFilesClicked)]="stepFilesClicked"
            (isStepFileAvailable)="stepFileAvailability($event)">
        </app-user-workflow>
        <div class="handler"></div>
    </div>
</div>
<div class="dashboard-wrapper" *ngIf="reportList.length > 0 && !isLoading">
    <div class="v-card">
        <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="d-flex">
                <div class="header fs-20 font-weight-600  pt-1 me-2">{{ 'MAIN.CONTENT.REPORT-LIST.TITLE' | translate }}</div>
            </div>
        </div>

        <div class="card tab-area d-flex flex-row first-area">
            <div class="tab-item right fs-16 font-weight-600 ms-4" (click)="onUserWorkflows()">
                <span class="tab-title">My workflows</span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" *ngIf="isAdmin || hasEditorAccess" (click)="onAllWorkflows()">
                <span class="tab-title">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.MANAGE-WORKFLOWS' | translate }}</span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4 selected-tab">
                <span class="tab-title">Submitted workflows</span>
            </div>
        </div>

        <div class="card list-area">
            <div class="d-flex align-items-center justify-content-between filter-area mb-3 pe-4">
                <div>
                    <div class="d-flex align-items-center" [formGroup]="filterForm">
                        <div class="d-flex">
                            <div style="position: relative;">
                                <input type="text" placeholder="{{ 'MAIN.CONTENT.REPORT-LIST.PLACEHOLDER' | translate }}" formControlName="nameFilter" class="filter-input fs-14">
                                <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="position: relative;">
                                <input type="text" placeholder="{{ isEwers ? 'Suche mit Auftrags-Nr. / Serien-Nr.' : 'MAIN.CONTENT.REPORT-LIST.FILTER-ID' | translate }}" formControlName="formFieldFilter" class="filter-input fs-14">
                                <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 282px;border: none;">
                        <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 text-dark-grey fs-13"  #toggleButton dropdownToggle data-bs-auto-close="inside" style="background-color: white !important;
                        height: 37px;
                        color: #707070;
                        width: 80%;">
                        {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-STATUS' | translate }}
                        </button>
                        <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                                aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false" style="background-color: white;
                                border-color: #eff1f5;
                                border-left-color: white;
                                height: 37px;
                                width: 15px;">
                        </button>
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                        overflow-y: scroll;" [formGroup]="statusGroup" aria-labelledby="dropdownMenuClickableInside">
                            
                            <div *ngFor="let status of statusList; let index = index" class="ps-2">
                                <div class="custom-control custom-checkbox custom-checkbox-green" [ngClass]="{'mt-2':index == 0}">
                                    <input
                                        type="checkbox"
                                        class="form-check-input ms-0"
                                        [id]="index"
                                        (click) = selectStatus(status)
                                        [formControlName]="status"
                                    />
                                    <label class="custom-control-label label-form fs-14" [for]="index">
                                        {{ status | titlecase }}
                                    </label>
                                </div>
                            </div>
                            
                        </ul>
                    </div>
                    </div>
    
                </div>
                
            </div>
    
            <div class="mt-4 status-filter-area" *ngIf="filteredStatusList?.length">
                <div class="d-flex flex-wrap">
                    <span class="fs-14 me-2 font-weight-600 text-dark-grey">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECTED-STATUSES' | translate }} : </span>
                    <span class="fs-12 label-span me-2 mb-2" *ngFor="let status of filteredStatusList">{{status}} <img (click)="deleteStatus(status)" class="label-close cursor-pointer" src="assets/img/close.svg" alt=""></span>
                    <span class="fs-14 font-weight-600 cursor-pointer text-blue" [ngClass]="{'disabled': isLoadingFilter}" (click)="clearAll()">Clear all</span>
                </div>
            </div>
            <div class="" style="display: flex;
              text-align: center;
              justify-content: center;">
                <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell data-column-name="#" *matCellDef="let i = index">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name" class="col-4 table-fs-14 text-center user-select-none fs-14 text-dark-grey font-weight-400" style="padding-left: 20px;">

                            {{ 'MAIN.CONTENT.REPORT-LIST.LIST.WORKFLOW' | translate }}
                        </th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION'" *matCellDef="let element" class="col-4 fs-15 font-weight-700 d-flex flex-column justify-content-center" style="text-align: start;
                        padding-left: 20px;">
                            <div>{{element.workflowName}}</div>
                            <div *ngIf="element?.['filterValue']" class="mb-1">
                                <span class="badge badge-secondary pb-1" style="background-color: #eff1f5;
                                color: #667999;">
                                {{isEwers ? 'Auftrags-Nr. / Serien-Nr.' : ('MAIN.CONTENT.REPORT-LIST.ID-TAG' | translate) }}
                                : {{element['filterValue']}}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="version">
                        <th mat-header-cell *matHeaderCellDef class="col-1  table-fs-14 text-center fs-14 text-dark-grey font-weight-400">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.VERSION' | translate }}</th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-1 fs-14 font-weight-400 d-flex align-items-center justify-content-center">
                            v{{element.version}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastSavedDate">
                        <th mat-header-cell *matHeaderCellDef class="col-2  table-fs-14 text-start fs-14 text-dark-grey font-weight-400">{{ 'MAIN.CONTENT.REPORT-LIST.LAST-SAVED' | translate }}</th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 fs-14 font-weight-400 d-flex align-items-center text-start" style="padding-right:30px">
                            <span *ngIf="element.updatedAt" title="{{element.updatedAt | localizedDate | async}}">
                                {{ element.updatedAt | localizedDateShort | async}}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="submittedDate">
                        <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 text-start fs-14 text-dark-grey font-weight-400">
                            {{ 'MAIN.CONTENT.REPORT-LIST.LIST.SUBMITTED-TITLE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-2 fs-14 font-weight-400  d-flex align-items-center text-start" style="padding-right:30px">
                            <span *ngIf="element.submittedAt" title="{{element.submittedAt | localizedDate | async}}">
                                {{ element.submittedAt | localizedDateShort | async}}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="col-1 table-fs-14 text-start fs-14 text-dark-grey font-weight-400">
                            {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.TITLE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-1 fs-14 font-weight-400 d-flex align-items-center text-start">
                            <span *ngIf="element.status =='incomplete'" class="badge bg-warning fs-12 font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.INCOMPLETE' | translate }}</span>
                            <span *ngIf="element.status =='submitted'" class="badge bg-success fs-12 font-weight-400">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.SUBMITTED' | translate }}</span>
                            <span *ngIf="element.status =='prefilled'" class="badge bg-secondary fs-12 font-weight-400">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.PREFILLED' | translate }}</span>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="statusIcon">
                        <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 fs-14 font-weight-400 text-center">

                        </th>
                        <td mat-cell *matCellDef="let element" class="col-2 fs-14 font-weight-400  d-flex align-items-center justify-content-end cursor-pointer">

                            <div class="btn-group dropdown-wrapper-list" dropdown *ngIf="element.status =='incomplete'">
                                
                                <button type="button" class="btn btn-primary action-dropdown  p-0"  #toggleButton dropdownToggle data-bs-auto-close="inside" style="width: 70%;">
                                    <!-- <img src="assets/img/visibility.svg" alt="" style="width: 22px;"> -->
                                    {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.TITLE' | translate }}
                                </button>
                                <button id="button-split" type="button"  class="btn btn-danger dropdown-toggle dropdown-toggle-split option-toggle" style="border-color: white !important;"  (click)="$event.stopPropagation(); toggleButton.click()"
                                        aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                                </button>
                                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="openWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.PREFILLED-OPTIONS.EDIT' | translate }}</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="deleteReport(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.PREFILLED-OPTIONS.DELETE' | translate }}</a>
                                    </li>
                                </ul>
                            </div>

                            <button *ngIf="element.status =='submitted' && !(element?.oldContinueWithQr && isEwers)" class="v-btn btn open-report-button cursor-pointer fs-14" (click)="openReport(element)">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.VIEW' | translate }}</button>

                            <div dropdown class="btn-group dropdown-wrapper-list" *ngIf="element.status =='submitted' && element?.oldContinueWithQr && isEwers">
                                <button id="workflow-actions-dropdown-toggle-button" dropdownToggle #workflowActionsToggleBtn 
                                type="button" class="btn btn-primary action-dropdown action-dropdown-show-options p-0" 
                                style="width: 70%;">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.TITLE' | translate }}</button>
                                <button id="workflow-actions-dropdown-split-button" type="button"  
                                class="btn btn-danger dropdown-toggle dropdown-toggle-split "
                                (click)="$event.stopPropagation(); workflowActionsToggleBtn.click()"
                                aria-controls="workflow-actions-dropdown">
                                </button>
                                <ul id="workflow-actions-dropdown" *dropdownMenu class="dropdown-menu" aria-labelledby="workflow-actions-dropdown-toggle-button">
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="openReport(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.SUBMITTED-OPTIONS.SHOW-REPORT' | translate }}</a>
                                    </li>
                                    <li role="menuitem" *ngIf="element?.oldContinueWithQr">
                                        <a class="dropdown-item" (click)="changeToPrefilled(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.SUBMITTED-OPTIONS.CHANGE-PREFILLED' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <div dropdown class="btn-group dropdown-wrapper-list" *ngIf="element.status =='prefilled'">
                                <button id="workflow-actions-dropdown-toggle-button" dropdownToggle #workflowActionsToggleBtn 
                                type="button" class="btn btn-primary action-dropdown action-dropdown-show-options p-0" 
                                style="width: 70%;">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.TITLE' | translate }}</button>
                                <button id="workflow-actions-dropdown-split-button" type="button"  
                                class="btn btn-danger dropdown-toggle dropdown-toggle-split"
                                (click)="$event.stopPropagation(); workflowActionsToggleBtn.click()"
                                aria-controls="workflow-actions-dropdown">
                                </button>
                                <ul id="workflow-actions-dropdown" *dropdownMenu class="dropdown-menu" aria-labelledby="workflow-actions-dropdown-toggle-button">
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="duplicateReport(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.PREFILLED-OPTIONS.DUPLICATE' | translate }}</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="openWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.PREFILLED-OPTIONS.EDIT' | translate }}</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="deleteReport(element)" style="color: #B94E4F !important">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.PREFILLED-OPTIONS.DELETE' | translate }}</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="showQr(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.PREFILLED-OPTIONS.SHOW-QR' | translate }}</a>
                                    </li>
                                </ul>
                            </div>

                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <div >
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </div>
                </table>
            </div>
        </div>


    </div>
</div>

<ng-template #loading>
    <div class="loader">
        <div class="spinner"></div>
    </div>

</ng-template>