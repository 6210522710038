import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
          <div class="d-flex fs-14 text-dark-grey mb-2" style="height: 90%;" *ngIf="to['detail']">{{to.label}}</div>
          <div class="d-flex align-items-center justify-content-center">
          <input type="text"  [formControl]="formControl" class="form-control custom-form-control" id="form-control"  placeholder="{{to.placeholder}}" 
          autocomplete="off">
          </div>
  `,
})
export class FormlyFieldCustomInput extends FieldType<any> {
  get type() {
    return this.to.type || 'text';
  }
}