import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-gallery',
  template: `
  
  <div style="position: absolute;top:0;bottom:0;left:0;right:0;padding: 10px;padding-top:25px" [ngClass]="{'image-with-label-gallery': to.label, 'image-without-label-gallery': !to.label}" >
    <div class="d-flex flex-column justify-content-space-between h-100">
      <div class="d-flex h-100 mb-3" style="justify-content:space-around;width:92%">
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;margin-left:0;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
      </div>

      <div class="d-flex h-100 mb-3" style="justify-content:space-around;width:92%">
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;margin-left:0;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
      </div>

      <div class="d-flex h-100 mb-3" style="justify-content:space-around;width:92%">
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;margin-left:0;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
        <div class="col-3" style="background-color:#f0f0f0; margin-inline:10px;"></div>
      </div>
    </div>
  </div>
  `,
})
export class FormlyFieldCustomGallery extends FieldType<FieldTypeConfig> {
 
  get type() {
    return this.to.type || 'text';
  }
}