import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-capture-video',
  template: `
  <div class="d-flex flex-column align-items-center">
    <div class="btn btn-primary qr-button">
      <span class="qr-text">{{'MAIN.CONTENT.WORKFLOW.PREVIEW.CAPTURE-VIDEO' | translate}}</span>
    </div>
    <div class="btn btn-primary qr-button mt-4">
      <span class="qr-text">{{'MAIN.CONTENT.WORKFLOW.PREVIEW.PICK-VIDEO' | translate}}</span>
    </div>
  </div>
  `,
})
export class FormlyFieldCustomCaptureVideo extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}