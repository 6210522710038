<div *ngIf="!selectedImage" class="d-flex flex-column h-100">
    <label class="h-50 label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="h-50 d-flex justify-content-center">
        <div *ngIf="item.templateOptions.images" [ngClass]="{'image-with-label': item.templateOptions.label}" class="d-flex position-absolute-aligned flex-wrap p-1">
            <div *ngFor="let image of item.templateOptions.images" class="me-2 mb-2">
                <div class="multiple-image-container">
                    <div class="multiple-image-div">
                        <img [src]="image.thumbnailUrl | safeHtml"  *ngIf="image.imgUrl" (click)="openImageFullscreen(image.imgUrl)" style="width: 100%;
                        height: 100%;
                        object-fit: contain;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedImage" class="d-flex flex-column h-100">
    <div style="position: absolute;top: -5px;right:12px;cursor: pointer;z-index:500;" (click)="exitFullscreen()">
        <img src="assets/img/close.svg" alt="">
     </div>
    <div class="d-flex position-absolute-aligned p-1">
        <div class="position-relative form-image-wrapper me-1 w-100">
            <img [src]="selectedImage | safeHtml" class="form-image">
        </div>
    </div>
</div>