import { Injectable } from '@angular/core';

// Router imports
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

// Authservice for control authentication
import { AuthService } from '../services/auth.service';

// rxjs imports
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from '../services/support/loader.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    this.loaderService.show()
    // Return observable of <auhtenticated or not>
    return this.authService.userForGuard.pipe(
      map(user => {
        this.loaderService.hide()
        if (!user) {
          if(state.url !== '/'){
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }})
          }else{
            this.router.navigate(['/login'])
          }
          
          return false
        } else {
          return true
        }
      })
    );
  }
}