<div class="comment-wrapper">
    <div class="" style="height: calc(100% - 147px);overflow-y: scroll;background-color: white;">
        <div class=" fs-14 w-100" *ngFor="let comment of commentTexts; let i = index">
            <div class="mb-2" *ngIf="comment.createdBy == currentUserId">
                <div class="d-flex">
                    <div class="col-4"></div>
                    <div class="col-8">
                        <div class="fs-12 text-grey mb-1 ms-2">{{getUsersName(comment.createdBy)}}</div>
                        <div class="comment" [innerHTML]="byPassHTML(comment.text)">{{comment.text}}</div>
                        <!-- <div class="comment">{{comment.text}}</div> -->
                        <div class="text-end fs-12 text-grey me-3">{{comment.createdAt | date: 'short' }}</div>
                    </div>
                </div>
                
            </div>

            <div class="mb-2" *ngIf="comment.createdBy !== currentUserId">
                <div class="d-flex">
                    <div class="col-8">
                        <div class="fs-12 text-grey mb-1 ms-2">{{getUsersName(comment.createdBy)}}</div>
                        <!-- <div class="comment-left">{{comment.text}}</div> -->
                        <div class="comment-left" [innerHTML]="byPassHTML(comment.text)">{{comment.text}}</div>
                        <div class="text-end fs-12 text-grey me-3">{{comment.createdAt | date: 'short' }}</div>
                    </div>
                    <div class="col-4"></div>
                </div>
                
            </div>
            <!-- <div class="d-flex mb-2">
                <div class="col-8 ">
                    <div class="comment-2">{{comment.text}}</div>
                    <p class="text-end fs-12 text-grey">{{comment.date}}</p>
                </div>
                <div class="col-4"></div>
            </div> -->
        </div>

        


    </div>
    <div class="comment-container" style="position: absolute; bottom: 0; z-index: 500; background-color: white;width: 100%;">
        <div class="input-wrapper">
            <div class="d-flex align-items-center">
                
                <div class="col-9 p-relative">
                    <quill-editor class="d-block" (onContentChanged)="getContent($event)" [(ngModel)]="commentText" placeholder="Enter your comment. Start by typing @ for mentions" [modules]="modules" [style]="{'min-height': '90px', 'min-width': '100%'}"></quill-editor>
                </div>
                <div class="col-3 d-flex align-items-center justify-content-end">
                    <div style="margin-right: 25px;">
                        <input type="file" class="d-none" accept="*" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput multiple>
                        <img src="assets/img/attach-icon-blue.svg" alt="" class="attachment" (click)="imageInput.click()">
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="v-btn btn btn-primary vsight-button" (click)="sendComment()" [disabled]="isCommentSending">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>