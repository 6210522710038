<div class="room-lis">
    <div class="">

        <div class="room-body top-gap-30">
            <div class="bg-white empty-list" *ngIf="rooms.length === 0">
                <div class="mt-4 fs-14 text-grey"> {{ 'MAIN.ROOMS.ROOM_LIST.NO_ROOM_MESSAGE' | translate }}</div>
            </div>
            <div class="" *ngIf="rooms.length !== 0">
                <div class="room-card" *ngFor="let room of rooms; index as i;">
                    <div class="row">
                        <div class="col-7">
                            <div class="fs-18 font-weight-700">{{ room.room_data.name }}
                            </div>
                            <div class="fs-14 text-dark-grey">{{ room.room_data.description }}</div>
                            
                        </div>

                        <div class="col-3">

                            <div class="d-flex flex-column h-100 justify-content-center py-md-3 py-2">
                                <div class="fs-14 text-dark-grey" *ngIf="room.room_data.users.length === 0">{{ 'MAIN.LOBBY.LOBBY_SHARED.NO_OTHER_USERS' | translate }}</div>
                                <ng-container *ngFor="let user of room.room_data.users; let ui = index">
                                    <div *ngIf="showRoomUsers[room.id] || ui < 3" class="room-user pt-1 fs-14" [ngClass]="{'in-room-user': user.in_room, 'not-in-room-user': !user.in_room}">{{ user.name }}</div>
                                </ng-container>
                                <ng-container *ngIf="room.room_data.users.length > 3">
                                    <a *ngIf="!showRoomUsers[room.id]" (click)="showRoomUsers[room.id] = true" class="text-muted pt-1" style="font-size: 12px; font-weight: 600; cursor: pointer;">{{ 'MAIN.LOBBY.LOBBY_SHARED.SHOW_MORE' | translate:{count:room.room_data.users.length-3} }}</a>
                                    <a *ngIf="showRoomUsers[room.id]" (click)="showRoomUsers[room.id] = false" class="text-muted pt-1" style="font-size: 12px; font-weight: 600; cursor: pointer;">{{ 'MAIN.LOBBY.LOBBY_SHARED.SHOW_LESS' | translate }}</a>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-2 d-flex align-items-center justify-content-end">
                           <button class="v-btn btn btn-primary vsight-button" (click)="onJoin(room.id)">Join</button>

                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="d-flex justify-content-between">
                <div class="d-inline own-rooms-header text-muted py-2">{{ 'MAIN.LOBBY.LOBBY_SHARED.ROOMS_TITLE' | translate }}</div>
            </div>

            <div class="d-flex justify-content-center" *ngIf="roomsLoading">
                <div class="loader loader-sm la-dark"><div></div><div></div><div></div><div></div><div></div></div>
            </div>

            <div class="no-room-message text-muted mt-2" *ngIf="!roomsLoading && rooms.length === 0">{{ 'MAIN.LOBBY.LOBBY_SHARED.NO_ROOM' | translate }}</div>

            <div class="room rounded-0 card-1 mt-2 py-0 px-2" *ngFor="let room of rooms; let i = index">
                <div class="room-color" [ngStyle]="{'background-color': (i % 2) === 0 ? ('design' | env).primaryColor : ('design' | env).accentColor}"></div>

                <div class="row px-2">
                    <div class="col-md-6 col-7">
                        <div class="d-flex flex-column h-100 justify-content-center py-md-3 py-2">
                            <div class="room-header d-flex align-items-center flex-row">
                                <div class="room-icon border border-light">
                                    <img src="{{ room.room_data.icon }}" alt="Room Icon">
                                </div>
                                <div class="room-name ml-2">{{ room.room_data.name }}</div>
                            </div>
                            <div class="room-description mt-3">{{ room.room_data.description }}</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-5 divided">
                        <div class="vertical-divider"></div>
                        <div class="d-flex flex-column h-100 justify-content-center py-md-3 py-2">
                            <div class="room-no-user text-muted" *ngIf="room.room_data.users.length === 0">{{ 'MAIN.LOBBY.LOBBY_SHARED.NO_OTHER_USERS' | translate }}</div>
                            <ng-container *ngFor="let user of room.room_data.users; let ui = index">
                                <div *ngIf="showRoomUsers[room.id] || ui < 3" class="room-user pt-1" [ngClass]="{'in': user.in_room, 'out': !user.in_room}">{{ user.name }}</div>
                            </ng-container>
                            <ng-container *ngIf="room.room_data.users.length > 3">
                                <a *ngIf="!showRoomUsers[room.id]" (click)="showRoomUsers[room.id] = true" class="text-muted pt-1" style="font-size: 12px; font-weight: 600; cursor: pointer;">{{ 'MAIN.LOBBY.LOBBY_SHARED.SHOW_MORE' | translate:{count:room.room_data.users.length-3} }}</a>
                                <a *ngIf="showRoomUsers[room.id]" (click)="showRoomUsers[room.id] = false" class="text-muted pt-1" style="font-size: 12px; font-weight: 600; cursor: pointer;">{{ 'MAIN.LOBBY.LOBBY_SHARED.SHOW_LESS' | translate }}</a>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-2 col-12 divided">
                        <div class="vertical-divider d-md-block d-none"></div>
                        <div class="horizontal-divider d-md-none d-block"></div>
                        <div class="d-flex h-100 flex-column justify-content-center align-items-center py-md-3 py-2">
                            <button (click)="onJoin(room)" class="btn rounded-0 btn-outline-dark btn-sm btn-block px-2">{{ 'MAIN.LOBBY.LOBBY_SHARED.JOIN' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<ng-template #loader>
</ng-template>