<div class="call-container">
    <!-- <div class="call-header d-flex align-items-center justify-content-between">
        <div class="call-title fs-20 font-weight-600">Call</div>
        <div>
            <button class="v-btn btn btn-primary vsight-button" (click)="onToggleMultiSelect()">Call Multiple</button>
        </div>
    </div> -->
    <div class="user-filter mt-3 mb-4">
        <p class="fs-14 text-dark-grey mb-4">Make an instant call with a user or select multiple users to make a group call.</p>
        <div>
            <input class="field form-control py-2" placeholder="Search name" #name="ngModel" [(ngModel)]="nameUserName" 
                [ngClass]="{'is-invalid':name.errors && name.touched}" type="text" name="nameUserName" minlength="1" (ngModelChange)="onSearchTextChange($event)">
        </div>
    </div>

    <div class="call-body mb-3">
        <div class="row d-flex align-items-center contact" *ngFor="let user of users" [ngClass]="{'selected' : selectedUsers[user.id]}">
            <div class="form-group d-flex align-items-center mt-1 col-6 p-relative mb-3 mt-3 ps-0">
                <input type="checkbox" class="form-check-input user-checkbox mt-0" id="{{user.id}}" name="{{user.id}}" [formControl]="selectedUsersToCall" (click)="onContactClicked(user.id)">
                <label class="form-check-label fs-14 font-weight-600" for="{{user.id}}">
                    <span class="fs-20 user-status {{user.status.state}}" *ngIf="user?.status?.state">.</span>
                    <span class="fs-20 user-status offline" *ngIf="user && !user.status?.state">.</span>
                    {{user.name}}
                </label>
            </div>

            
            <div class="col-6 text-end mb-3 mt-3 pe-0">
                <button *ngIf="currentUserId !== user.id" class="v-btn btn btn-primary vsight-button call-button" (click)="$event.stopPropagation();callSingleUser(user.id)">Call</button>
                <!-- <div *ngIf="selectMultiple && currentUserId !== user.id"
                 class="custom-control custom-checkbox d-flex align-items-center justify-content-end" (click)="$event.stopPropagation();">
                    <input type="checkbox" class='form-check-input ms-0' [attr.id]="user.id" [attr.name]="user.id" [(ngModel)]="selectedUsers[user.id]">
                    <label class="custom-control-label" [attr.for]="user.id"></label>
                </div> -->
            </div>
        </div>
    </div>

</div>