import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit,OnDestroy {
  @Input() url?: string;
  @Input() type?: string;
  @Input() close?:EventEmitter<any>

  onClose: EventEmitter<any> = new EventEmitter();

  pdfPage = new UntypedFormControl()
  currentPdfPage = 1
  totalPages

  constructor(public modalRef: BsModalRef) {
   }
  ngOnDestroy(): void {
    this.modalRef.hide()
  }

  ngOnInit(): void {
    if(this.type=='pdf') {
      this.pdfPage.patchValue(1)
    }
    this.close.subscribe(res=> {
      if(res) {
        this.modalRef.hide()
      }
    })
  }

  pageChanged(event) {
    this.pdfPage.patchValue(event)
    this.currentPdfPage = event
  }
  pdfNext() {
    this.pdfPage.patchValue(this.currentPdfPage+1)
    this.currentPdfPage +=  1
  }
  pdfPrevious() {
    if(this.currentPdfPage !==1) {
      this.pdfPage.patchValue(this.currentPdfPage-1)
      this.currentPdfPage -=  1
    }
  }

  pageSearch() {
    this.currentPdfPage = this.pdfPage.value
  }

  afterLoadComplete(pdf): void {
    this.totalPages = pdf.numPages;
  }

}
