import { Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  
  @Input() title: string;
  @Input() imageExist?: boolean;
  @Input() width?
  @Input() type?: string
  @Input() imgSrc?: string;
  @Input() isVideoFlow?: boolean;


  @ContentChild('searchInput') searchInput: ElementRef;

  toggle(): void {
    if (this.searchInput?.nativeElement) {
      setTimeout(() => {
        (this.searchInput.nativeElement as HTMLInputElement).focus();
      });
    }
  }
}
