<div class="d-flex flex-column h-100" *ngIf="item.type == 'ppe'">
    <div *ngIf="showPpeAlert">
        <div class="alert-card">
            <div class="card-title fs-15 font-weight-600 mt-4">{{ 'MAIN.USER-WORKFLOW.MISSING-EQUIPMENT' | translate }}</div>
            <div class="card-body fs-14 font-weight-500 pt-2">{{ 'MAIN.USER-WORKFLOW.PROTECTED-EQUIPMENT' | translate }}</div>
            <div class="alert-footer d-flex align-items-center justify-content-center" (click)="closeAlert()">{{ 'MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK' | translate }}</div>
        </div>
    </div>
    <div class="h-50 ">
        <label class="label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    </div>
    <div class="h-50 d-flex justify-content-center">
        <input type="file" class="d-none" accept="image/png,image/jpeg" onclick="this.value = null" (change)="chooseButtonUpload('ppe', $event)" #ppeInput>
        <div *ngIf="mediaControl?.value" [ngClass]="{'image-with-label': item.templateOptions.label}" class="d-flex position-absolute-aligned p-1">
            <div class="position-relative form-image-wrapper me-1 w-100">
                <img [src]="mediaControl?.value.url | safeHtml" class="form-image">
                <div class="form-image-overlay position-absolute-aligned d-flex align-items-center justify-content-center">
                    <div class="me-4" (click)="ppeInput.click()">
                        <img src="assets/img/edit.svg">
                    </div>
                    <div (click)="deleteImage( 'ppe', item?.key)">
                        <img src="assets/img/image-trash.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="btn btn-primary qr-button" (click)="ppeInput.click()" *ngIf="!mediaControl?.value">
            <span class="qr-text" >{{ 'MAIN.CONTENT.WORKFLOW.PREVIEW.DETECT-PPE-FROM-FINDER' | translate }}</span>
        </div>
    </div>
</div>

<div class="d-flex flex-column h-100" *ngIf="item?.type == 'captureImage' && !selectedImage">
    <label class="h-50 label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="h-50 d-flex justify-content-center">
        <input type="file" class="d-none" accept="{{'image/png,image/jpeg'}}" onclick="this.value = null" (change)="chooseButtonUpload('image', $event)" #imageInput [multiple]="item?.type == 'captureImage'">
        <input type="file" class="d-none" accept="{{'image/png,image/jpeg'}}" onclick="this.value = null" #imageEditInput>
        <div *ngIf="mediaControl.value?.length > 0" class="image-with-label d-flex position-absolute-aligned p-1 flex-wrap overflow-scroll">
            <div *ngFor="let file of mediaControl.value; let i = index" class="position-relative  me-2 mb-2">
                <div class="multiple-image-container"> 
                    <div class="multiple-image-div">
                        <img [src]="file.url | safeHtml" style="width: 100%; height: 100%; object-fit: contain;">
                        <div class="position-absolute-aligned d-flex align-items-center justify-content-center form-image-overlay">
                            <div class="me-2" (click)="openImageFullscreen(file.url)">
                                <img style="width: 80%;" src="assets/img/zoom_in.svg">
                            </div>
                            <div class="me-2" (click)="changeImage(imageEditInput, i)"> 
                                <img style="width: 70%;" src="assets/img/edit.svg">
                            </div>
                            <div (click)="deleteImage('image', i)">
                                <img style="width: 70%;"src="assets/img/image-trash.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-relative  justify-content-center">
                <button class="add-image-button" (click)="imageInput.click()">
                    <img src="assets/img/plus.svg" style=" width: 100%; height: 50%; object-fit: contain;">
                </button>
            </div>
        </div>
        <div class="btn btn-primary qr-button" (click)="imageInput.click()" *ngIf="!mediaControl.value || mediaControl.value?.length == 0">
            <span class="qr-text">{{ 'MAIN.CONTENT.WORKFLOW.PREVIEW.PICK_IMAGE' | translate }}</span>
        </div>
    </div>
</div>


<div class="d-flex flex-column h-100" *ngIf="item?.type == 'captureVideo'">
    <label class="h-50 label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="h-50 d-flex justify-content-center">
        <input type="file" class="d-none" accept="video/*" onclick="this.value = null" (change)="chooseButtonUpload('video', $event)" #imageInput>
        <input type="file" class="d-none" accept="video/*" onclick="this.value = null" (change)="editUpload('video', $event)"  #imageEditInput>
        <div *ngIf="mediaControl.value?.length > 0" class="image-with-label d-flex position-absolute-aligned p-1">
            <div class="position-relative form-image-wrapper me-1 w-100">
                <video controls playsinline autoplay muted loop class="form-video"  *ngIf="(isMobile || isSafari) && item?.type == 'captureVideo'">
                    <source [src]="mediaControl.value | safeHtml" type="video/mp4" />
                </video>
                <video controls playsinline autoplay muted loop class="form-video" [src]="mediaControl.value | safeHtml" type="video/*"  *ngIf="!isMobile && !isSafari && item?.type == 'captureVideo'">
                </video>
                <div class="position-absolute-aligned d-flex align-items-center justify-content-center form-video-overlay" (click)="changeVideo(imageEditInput)">
                    <div class="me-4">
                        <img src="assets/img/edit.svg">
                    </div>
                    <div (click)="deleteImage( 'video', item?.key)">
                        <img src="assets/img/image-trash.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="btn btn-primary qr-button" (click)="imageInput.click()" *ngIf="!mediaControl.value || mediaControl.value?.length == 0">
            <span class="qr-text">{{ 'MAIN.CONTENT.WORKFLOW.PREVIEW.PICK-VIDEO' | translate }}</span>
            <span class="qr-text" *ngIf="item?.type=='qr'">{{ 'MAIN.CONTENT.WORKFLOW.PREVIEW.DETECT-QR' | translate }}</span>
        </div>
    </div>
</div>

<div *ngIf="selectedImage" class="d-flex flex-column h-100">
    <div style="position: absolute;top: -5px;right:12px;cursor: pointer;z-index:500;" (click)="exitFullscreen()">
        <img src="assets/img/close.svg" alt="">
     </div>
    <div class="d-flex position-absolute-aligned p-1">
        <div class="position-relative form-image-wrapper me-1 w-100">
            <img [src]="selectedImage | safeHtml" class="form-image">
        </div>
    </div>
</div>

<div class="d-flex align-items-center justify-content-center loading-upload" *ngIf="isUpload">
    <ng-container *ngIf="isUpload" [ngTemplateOutlet]="loadingTemplateUpload"></ng-container>
</div>

<div class="d-flex align-items-center justify-content-center ppe-alert" *ngIf="showPpeAlert">
    <ng-container *ngIf="showPpeAlert" [ngTemplateOutlet]="ppeAlert"></ng-container>
</div>

<ng-template #loadingTemplateUpload>
    <div class="spinner-border mt-5" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
</ng-template>

<ng-template #ppeAlert>
    <div class="m-5" role="status">
        <span class="visually-hidden"></span>
      </div>
</ng-template>