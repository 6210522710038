import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Archive } from '@models/Archive';
import { Subscription } from 'rxjs';
import { LoaderService } from '@services/support/loader.service';
import { SessionService } from '@services/remote/session.service';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@services/support/snackbar.service';

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.scss']
})
export class ArchivesComponent {
  @ViewChild("deleteArchiveTemplate", { static: true }) private deleteArchiveTemplate: TemplateRef<any>;
  @ViewChild("downloadArchiveTemplate", { static: true }) private downloadArchiveTemplate: TemplateRef<any>;
  @ViewChild("watchArchiveTemplate", { static: true }) private watchArchiveTemplate: TemplateRef<any>;

  @Input() room_id: string;
  @Input() session_id: string;

  downloadLinks: { [key: string]: { loading: boolean, url?: string } } = {};

  archives: Archive[] = null;
  archivesSub: Subscription = null;

  selectedArchive: Archive = null;

  firstLoaded: boolean = false;
  showArchives: boolean = false;

  pendingExists: boolean = false;
  refreshing: boolean = false;

  dwUrl = "";

  accountDataSub: Subscription = null;
  timezone: string = "UTC";

  webdavEnabled: boolean = false;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit() {
    this.authService.getAccountData().then(account => {
      this.webdavEnabled = account.data.accountData.add_ons.webdav;
    })
    this.archivesSub = this.sessionService.getSessionArchives(this.room_id, this.session_id)
    .subscribe(archiveList => {
      this.archives = archiveList.sort((a, b) => a.create_time > b.create_time ? -1 : 1);

      if (this.archives.length > 0 && this.archives.filter(a => a.storage_status === 'pending').length > 0) {
        this.pendingExists = true;
      } else {
        this.pendingExists = false;
      }

      if (!this.firstLoaded) {
        this.firstLoaded = true;

        if (this.pendingExists) {
          this.sessionService.checkSessionArchives(this.room_id, this.session_id).finally(() => {
            this.showArchives = true;
          });
        } else {
          this.showArchives = true;
        }
      }
    });
  }

  refreshArchives() {
    this.refreshing = true;
    this.sessionService.checkSessionArchives(this.room_id, this.session_id).finally(() => {
      this.refreshing = false;
    });
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.archivesSub) { this.archivesSub.unsubscribe() }
  }

  getArchiveStatusKey(archive: Archive): string {
    switch (archive.storage_status) {
      case "uploaded":
        return "MAIN.ARCHIVES.ARCHIVE_LIST.AVAILABLE";
      case "disconnected":
        return "MAIN.ARCHIVES.ARCHIVE_LIST.DISCONNECTED";
      case "expired":
      case "deleted":
        return "MAIN.ARCHIVES.ARCHIVE_LIST.DELETED";
      case "pending":
        return "MAIN.ARCHIVES.ARCHIVE_LIST.WAITING";
      case "failed":
        return "MAIN.ARCHIVES.ARCHIVE_LIST.FAILED";
      default:
        return "MAIN.ARCHIVES.ARCHIVE_LIST.FAILED";
    }
  }

  getBadgeClass(archive: Archive) {
    switch (archive.storage_status) {
      case "uploaded":
        return {"badge-success": true};    
      case "pending":
        return {"badge-warning": true};
      case "disconnected":
        return {"badge-secondary": true};
      case "expired":
      case "deleted":
      case "failed":
      default:
        return {"badge-danger": true};
    }
  }

  async onDownloadArchive(archive: Archive) {
    if (!(this.downloadLinks[archive.id] && this.downloadLinks[archive.id].url)) {
      this.downloadLinks[archive.id] = { loading: true };
      await this.sessionService.downloadArchive(this.room_id, this.session_id, archive)
      .then(url => this.downloadLinks[archive.id].url = url )
      .catch(error => console.log(error))
      .finally(() => { this.downloadLinks[archive.id].loading = false })
    }
  }

  async onWatchArchive(archive: Archive) {    
    // this.selectedArchive = archive;
    // const modalId = this.modalService.show({
    //   template: this.watchArchiveTemplate,
    //   context: {
    //     dataModel: "",
    //     callbacks: {
    //       close: () => {
    //         this.modalService.hide(modalId);
    //         this.dwUrl = ""
    //       }
    //     }
    //   }
    // });
    
    // if (!(this.downloadLinks[archive.id] && this.downloadLinks[archive.id].url)) {
    //   this.downloadLinks[archive.id] = { loading: true };
    //   await this.sessionService.downloadArchive(this.room_id, this.session_id, archive)
    //   .then(url => this.downloadLinks[archive.id].url = url )
    //   .catch(error => console.log(error))
    //   .finally(() => { this.downloadLinks[archive.id].loading = false })
    // }
    // this.dwUrl = this.downloadLinks[archive.id].url
  }

  onDeleteArchive(archive: Archive) {
    // const modalId = this.modalService.show({
    //   template: this.deleteArchiveTemplate,
    //   context: {
    //     dataModel: { shared: archive.storage_shared },
    //     callbacks: {
    //       cancel: () => {
    //         this.modalService.hide(modalId);
    //       },
    //       delete: () => {
    //         this.loaderService.show();
    //         this.sessionService.deleteArchive(this.room_id, this.session_id, archive)
    //         .then(() => this.snackBarService.success("Record deleted."))
    //         .catch(error => this.snackBarService.error('Cannot delete record.'))
    //         .finally(() => {
    //           this.modalService.hide(modalId);
    //           this.loaderService.hide();
    //         });
    //       }
    //     }
    //   }
    // });
  }

}
