import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DetailPageService } from '@services/detail-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-room-wrapper',
  templateUrl: './room-wrapper.component.html',
  styleUrls: ['./room-wrapper.component.scss']
})
export class RoomWrapperComponent implements OnInit, OnDestroy {

  @Input() roomId: string
  @Input() users: string[]
  @Input() linkId: string
  @Input() guestName: string

  iframeSrc: string = null
  baseUrl: string = environment.endPoints.remoteBase
  loading = true

  constructor(
    private authService: AuthService,
    private detailPageService: DetailPageService
  ) { }

  ngOnInit() {
    if (this.roomId) {
      this.authService.switchToRemote()
      .then(r => {
        const token = encodeURIComponent(btoa(r.token))
        const origin = encodeURIComponent(window.location.origin)

        this.iframeSrc = `${this.baseUrl}/iframe?room=${this.roomId}&token=${token}&origin=${origin}`
      })
    } else if (this.users) {
      this.authService.switchToRemote()
      .then(r => {
        const token = encodeURIComponent(btoa(r.token))
        const origin = encodeURIComponent(window.location.origin)

        const usersStr = encodeURIComponent(JSON.stringify(this.users))

        this.iframeSrc = `${this.baseUrl}/iframe?users=${usersStr}&token=${token}&origin=${origin}`
      })
    } else if (this.linkId && this.guestName) {
      const name = encodeURIComponent(this.guestName)
      this.iframeSrc = `${this.baseUrl}/iframe?linkId=${this.linkId}&name=${name}&origin=${origin}`
    }
  }

  onFrameLoad(frame: HTMLIFrameElement) {
    //frame.contentWindow.postMessage({ anything: "tosend" }, "*")
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent<any>) {
    if (event.origin === this.baseUrl) {
      if (event.data.closed) {
        this.detailPageService.removeComponent()
      } else if (event.data.opened) {
        this.loading = false
      }
    } 
  }

  ngOnDestroy() {}
}
