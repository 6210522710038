import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-licence-warning-modal',
  templateUrl: './licence-warning-modal.component.html',
  styleUrls: ['./licence-warning-modal.component.scss']
})
export class LicenceWarningModalComponent implements OnInit {

  instantClose = true
  textMessage?: string
  confirmButtonText?: string
  confirmButtonStyle?: string
  errorMessage?: string
  cancelButtonText?: string
  headerTitle: string


  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.renderer.setStyle(document.body, 'overflow', 'hidden')
  }

}
