import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { FolderInfoModalComponent } from 'src/app/components/modals/folder-info-modal/folder-info-modal.component';

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit, OnDestroy {
  @ViewChild('stepListStepOptions', {static: false}) stepListStepOptions: ElementRef;
  @ViewChild('stepsWrapper') stepsWrapper: ElementRef;

  @Input() folders
  @Output() selectedFolderChange: EventEmitter<string> = new EventEmitter<any>();
  @Output() folderDeleted: EventEmitter<string> = new EventEmitter<any>();
  @Output() notFolderedSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscriptions: Subscription[] = []
  
  selectedFolder
  notFolderedSelected

  constructor(
    public loaderService: LoaderService,
    private modalService: BsModalService,
    private workflowService: WorkflowService,
    private translateService: TranslateService,
    private renderer: Renderer2
  ){}

  ngOnInit(): void {
    if(this.folders?.length) {
      this.selectFolder(this.folders[0])
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }


  createNewFolder() {
    const initialState= {
      folders : this.folders
    };
    let folderModalRef = this.modalService.show(FolderInfoModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(folderModalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.folders.push({name: res, workflows: []})
        this.setWorkflowFolders()
      }
    }))
  }

  editFolder(folder) {
    const initialState= {
      folder: folder.name,
      folders : this.folders
    };
    let folderModalRef = this.modalService.show(FolderInfoModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(folderModalRef.content.onClose.subscribe((res) => {
      if(res) {
        const editedFolderIndex = this.folders.findIndex(item => item.name == folder.name)
        if(editedFolderIndex || editedFolderIndex == 0 ) {
          this.folders[editedFolderIndex].name = res
          this.setWorkflowFolders()
        }
      }
    }))
  }

  setWorkflowFolders() {
    const workflowFolders = this.folders
    this.workflowService.setWorkflowFolders(workflowFolders).then(res=> {
      if(this.selectedFolder) {
        this.selectFolder(this.selectedFolder)
      }
    })
    .catch()
    .finally(()=> this.loaderService.hide())
  }

  selectFolder(folder) {
    this.selectedFolder = folder
    this.notFolderedSelected = false
    this.selectedFolderChange.emit(folder)
  }

  deleteFolder(folder) {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER-WARNING-TEXT'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER'),
      regularTextMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER-INFO-TEXT')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });

    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if(res) {
        const deletedFolderIndex = this.folders.findIndex(item => item.name == folder.name)
        if(deletedFolderIndex || deletedFolderIndex == 0 ) {
          this.folderDeleted.emit(deletedFolderIndex)
          // let deletedFolderWorkflows = this.workflows.filter(res => this.folders[deletedFolderIndex].workflows.includes(res.id))
          // this.notFolderedWorkflows = this.notFolderedWorkflows.concat(deletedFolderWorkflows)
          this.folders.splice(deletedFolderIndex, 1)
          this.setWorkflowFolders()
        }
      }
    }))

  }

  showNotFolderedWorkflows() {
    this.selectedFolder = null
    this.notFolderedSelectedChange.emit(true)

  }

  onScrollStepList(): void {
    const stepList: HTMLDivElement = this.stepsWrapper.nativeElement;
    if(this.stepListStepOptions){
      const dropdownMenu = this.stepListStepOptions.nativeElement;

      if(dropdownMenu.getBoundingClientRect().top < stepList.getBoundingClientRect().top){
        document.getElementById(`stepListStepOptionsButton${this.selectedFolder.name}`).click();
      } else{
        this.renderer.setStyle(dropdownMenu, 'top', `${dropdownMenu.scrollTop}px`);
      }
      
    }
  }

}
