import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { JsonViewerComponent } from '../json-viewer/json-viewer.component';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-webhook-modal',
  templateUrl: './add-webhook-modal.component.html',
  styleUrls: ['./add-webhook-modal.component.scss']
})
export class AddWebhookModalComponent implements OnInit {

  authModels = [{
    type: "none",
    name: "None"
  }, {
    type: "token-on-body",
    name: "Token based (on request body)"
  }, {
    type: "token-on-header",
    name: "Token based (on header)"
  }]

  onClose: EventEmitter<any> = new EventEmitter()
  onNewWebhook: EventEmitter<any> = new EventEmitter()

  webhook = {
    event: environment.design.supportedEvents[0],
    baseUrl: window.location.origin,
    url: "",
    authType: "none",
    authKey: "",
    authValue: ""
  }

  showWarningVal = false
  showWarningTimer = null

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public modalRef: BsModalRef,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }

  addNewWebhook() {
    this.onNewWebhook.emit(this.webhook)
  }

  async showExampleNotification(webhook) {
    const url = environment.endPoints.changeWebhook
    const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "getExampleResponse", webhook: webhook })
    const result = await firstValueFrom(pending)

    const initialState = {
      title: "Example Notification",
      bodyTitle: "POST "+webhook.url,
      jsonTitle: "Headers",
      json: result.data.headers,
      json2Title: "Body",
      json2: result.data.body,
      expanded: true,
      raw: true,
      showRaw: false
    }
    this.modalService.show(JsonViewerComponent,  { initialState, backdrop:'static', class:'modal-lg modal-dialog-centered', animated:false })
  }

  showWarning() {
    this.showWarningVal = true
    if (this.showWarningTimer) {
      clearTimeout(this.showWarningTimer)
      this.showWarningTimer = null
    }
    this.showWarningTimer = setTimeout(() => {
      this.showWarningVal = false
    }, 1000);
  }
}
