import { environment } from "src/environments/environment";

export const Widgets = {
    label: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.LABEL', description: 'Instruction', icon: 'assets/widgets/label.svg', showWidget: true },
    html: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.HTML', description: 'Instruction', icon: 'assets/widgets/rich-text.svg', showWidget: true },
    input: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.TEXT-INPUT', description: 'Feedback', icon: 'assets/widgets/text-input.svg', showWidget: true },
    number: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.NUMBER-INPUT', description: 'Feedback', icon: 'assets/widgets/number-input.svg', showWidget: true },
    datepicker: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.DATE-PICKER', description: 'Feedback', icon: 'assets/widgets/date-picker.svg', showWidget: true },
    multicheckbox: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.CHECKBOX', description: 'Feedback', icon: 'assets/widgets/checkbox.svg', showWidget: true },
    radio: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.RADIO-BUTTON', description: 'Feedback', icon: 'assets/widgets/radio-button.svg', showWidget: true },
    image: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.IMAGE', description: 'Instruction', icon: 'assets/widgets/image.svg', showWidget: true },
    gallery: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.GALLERY', description: 'Instruction', icon: 'assets/widgets/gallery.svg', requiresFullscreen: true, showWidget: true },
    video: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.VIDEO', description: 'Instruction', icon: 'assets/widgets/video.svg', showWidget: true },
    pdf: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.PDF', description: 'Instruction', icon: 'assets/widgets/pdf.svg', requiresFullscreen: true, showWidget: true },
    qr: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.DETECT-QR', description: 'Feedback', icon: 'assets/widgets/detect-qr.svg', requiresFullscreen: true, showWidget: true },
    captureImage: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.CAPTURE-IMAGE', description: 'Feedback', icon: 'assets/widgets/capture-image.svg', requiresFullscreen: true, showWidget: true },
    captureVideo: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.CAPTURE-VIDEO', description: 'Feedback', icon: 'assets/widgets/capture-video.svg', requiresFullscreen: true, showWidget: true },
    ppe: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.PPE', description: 'Visual AI', icon: 'assets/widgets/ppe.svg', requiresFullscreen: true, descriptionInfo: 'Personal Protective Equipment', showWidget: true },//(Personal Protective Equipment)
    table: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.TABLE', description: 'Feedback', icon: 'assets/widgets/table.svg', requiresFullscreen: true, showWidget: true},
    form: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.FORM', description: 'Feedback', icon: 'assets/widgets/form.svg', requiresFullscreen: true, showWidget: environment.design.showFormWidget},
    id: { name: environment.design.idWidgetTranslation ? 'Fertigungsnr.' : 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.ID', description: 'Feedback', icon: 'assets/widgets/id.svg', requiresFullscreen: true, showWidget: true},
    workflow: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.WORKFLOW', description: 'Instruction', icon: 'assets/widgets/workflow.svg', requiresFullscreen: true, showWidget: true},
    voice: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.VOICE', description: 'Instruction', icon: 'assets/widgets/voice.svg', comingSoon: true, showWidget: true },
    voicerecord: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.VOICE-RECORD', description: 'Feedback', icon: 'assets/widgets/voice-record.svg', comingSoon: true, showWidget: true },
    arInstruction: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.AR-INSTRUCTION', description: 'Augmented Reality', icon: 'assets/widgets/ar-instruction.svg', comingSoon: true, showWidget: true },
    vsightRemote: { name: 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-TITLES.VSIGHT-REMOTE', description: 'Visual Support', icon: 'assets/widgets/vsight-remote.svg', comingSoon: true, showWidget: environment.design.showVSightRemoteWidget },
  }

  