import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from './services/support/loader.service';
import { Subscription, startWith, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LicenceWarningModalComponent } from './components/modals/licence-warning-modal/licence-warning-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private langSub: Subscription = null;

  showLoader: boolean = false

  constructor(
    private titleService: Title,
    private loaderService: LoaderService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private authService: AuthService,
    private translateService: TranslateService,
    private multilanguageService: MultilanguageService
  ) {

    this.langSub = this.multilanguageService.onLangChange.pipe(startWith(null)).subscribe(() => {
      this.setAppName()
    });

  }

  setAppName() {
    if (environment.design.addWorkflowToName) {
      this.titleService.setTitle(this.multilanguageService.getInstantTranslation('LOGIN.APP-NAME', environment.design));
    } else {
      this.titleService.setTitle(environment.design.appName);
    }
  }

  ngOnInit() {
    if(environment.electron) {
      this.licenceTimeoutCheck()
      setInterval(this.licenceTimeoutCheck,60000)
    }
    this.loaderService.showLoader.subscribe(show => {
      this.showLoader = show
      this.cd.detectChanges()
    })
  }

  licenceTimeoutCheck() {
    const licenceEndDate = new Date('2023-03-01').getTime()                
    const todayDate = new Date().getTime()
    if(todayDate > licenceEndDate){
      this.authService.logout()
      const initialState = { 
        textMessage : this.translateService.instant('MAIN.CONTENT.LICENCE-EXPIRED'),
        headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.LICENCE-WARNING')
      }
      this.modalService.show(LicenceWarningModalComponent, {initialState, backdrop:'static', class: 'modal-dialog-centered', animated:false});
    }
  }
}
