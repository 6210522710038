<div class="d-flex flex-column h-100">
    <label class="label-preview-title">{{item.templateOptions.label}} <span *ngIf="item.templateOptions.required">*</span></label>
    <div class="h-100 d-flex flex-column justify-content-center">
        <div style="position: relative;">
            <input #startDate="bsDatepicker"
             [formControl]="dateControl" 
             name="startDate" 
             class="form-control custom-form-control" 
             id="form-control" 
             bsDatepicker
            autocomplete="off"
            [bsConfig]="{ containerClass: 'theme-blue', customTodayClass: 'datepicker-today', adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY'}">
            <span class="date-cancel" *ngIf="dateControl?.value" (click)="cancelDate()"><img src="assets/img/close.svg" width="9px"></span>
        </div>
        <p class="validation-text pb-0 text-start" *ngIf="dateControl.invalid && dateControl.errors['required'] && dateControl.touched">{{ 'MAIN.CONTENT.WORKFLOW.REQUIRED' | translate }}</p>
    </div>
    <!-- <div class="estimated-duration-wrapper">
        <timepicker formControlName="time" [showMeridian]="false" ></timepicker>
    </div> -->
</div>