import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-no-device-modal',
  templateUrl: './no-device-modal.component.html',
  styleUrls: ['./no-device-modal.component.scss']
})
export class NoDeviceModalComponent {

  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
  }

}
