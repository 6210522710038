import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
    selector: '[inputSelectOnCreate]'
})
export class InputSelectOnCreateDirective implements AfterViewInit {

    @Input('inputSelectOnCreate') active: boolean;

    constructor(private el: ElementRef) { }

    ngAfterViewInit(): void {
        if (this.active) {
            (this.el.nativeElement as HTMLInputElement).select()
        }
    }

}