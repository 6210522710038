import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HtmlToPlaintextPipe } from '../pipes/html-to-plain-text.pipe';
import { MultilanguageService } from './support/multilanguage.service';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

    constructor(public multiLanguageService: MultilanguageService, private htmlPipe: HtmlToPlaintextPipe, private sanitizer: DomSanitizer) { }

  mappingReport(reportData,stepData, workflowPublishedVersion?) {
    let stepDataItem
    reportData.reportArray.forEach((reportDataKey,repoIndex) => {
         stepDataItem = stepData.layouts[repoIndex].items[0]
         if(!reportDataKey.data || reportDataKey.data?.type=='') {
          reportDataKey.data = {type:''}
          reportDataKey.data['type'] = stepDataItem ? stepDataItem.type : ''
         }
      
        if (stepDataItem) {
          const reportFieldType = reportDataKey.data['type'];
          switch(reportFieldType) {
            case 'input':
            case 'textarea':
            case 'datepicker':
            case 'id':
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportData['next'] = stepData.next;
              break;
            case 'html':
              reportDataKey.data['html'] = this.htmlPipe.transform(stepDataItem.templateOptions.html) ;
              reportData['next'] = stepData.next;
              break;
            case 'number':  
            reportDataKey.data['label'] = stepDataItem.templateOptions.label;
            reportDataKey.data['unit'] = stepDataItem.templateOptions.unit;
            reportData['next'] = stepData.next;
              break;
            case 'gallery':
              reportDataKey.data['value'] = stepDataItem.templateOptions.images;
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              break;
            case 'image':
              reportDataKey.data['mediaGroup'] = true;
              reportDataKey.data['value'] = stepDataItem.templateOptions.imgUrl;
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportData['next'] = stepData.next;
              break;
            case 'video':
              const isVideoFlow = workflowPublishedVersion.isVideoFlow
              const videoUrlVal = isVideoFlow ? this.videoFlowVideoTimestampMap(stepDataItem.templateOptions) : stepDataItem.templateOptions.videoUrl
              reportDataKey.data['mediaGroup'] = true;
              reportDataKey.data['value'] = videoUrlVal;
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportData['next'] = stepData.next;
              break;
            case 'label':
              reportDataKey.data['value'] = stepDataItem.templateOptions.label;
              reportData['next'] = stepData.next;
              break;
            case 'multicheckbox':
            case 'radio':
              reportDataKey.data['options'] = stepDataItem.templateOptions.options
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportDataKey.data['options'] = stepDataItem.templateOptions.options;
              reportDataKey.data['required'] = stepDataItem.templateOptions.required;
              let keyItem = stepDataItem.templateOptions.options.filter(res => reportDataKey.data?.value?.length && (res.value == reportDataKey.data.value[0]))
              let valueItem = stepDataItem.templateOptions.options.filter(res => reportDataKey.data?.value?.length && (res.label == reportDataKey.data.value[0]))
              let array = []
              if(Array.isArray(reportDataKey.data.value)) {
                if(keyItem) {
                  reportDataKey.data.value.forEach(response => {
                    let x = stepDataItem.templateOptions.options.find(item => item.value == response)
                    if(x) {
                      array.push(x.label)
                    }
                  })
                }
                if(valueItem) {
                  reportDataKey.data.value.forEach(response => {
                    let x = stepDataItem.templateOptions.options.find(item => item.label == response)
                    if(x) {
                      array.push(x.label)
                    }
                  })
                }
               reportDataKey.data['values'] = array;
              }else{
                if(keyItem) {
                  
                    let x = stepDataItem.templateOptions.options.find(item => item.value == reportDataKey.data.value)
                    if(x) {
                      reportDataKey.data['value'] = x.label
                    }
                }
                if(valueItem) {
                  
                    let x = stepDataItem.templateOptions.options.find(item => item.label == reportDataKey.data.value)
                    if(x) {
                      reportDataKey.data['value'] = x.label
                    }
                }
              }
              reportData['next'] = stepData.next;
              break;
            case 'pdf':
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportData['next'] = stepData.next;
              break;
            case 'ppe':
            case 'captureVideo':
            case 'captureImage':
              reportDataKey.data['mediaGroup'] = true;
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportData['next'] = stepData.next;
              break;
            case 'qr':
              reportDataKey.data['mediaGroup'] = true;
              reportDataKey.data['label'] = stepDataItem.templateOptions.label;
              reportDataKey.data['isQrMatch'] = stepDataItem.templateOptions.qrValidation;
              reportDataKey.data['enteredQRValue'] = stepDataItem.templateOptions.qrfield;
              reportData['next'] = stepData.next;
              break;
            case 'table':
              reportDataKey.data['tableData'] = stepDataItem.templateOptions.tableData ;
              reportData['next'] = stepData.next;
              break;
            case 'form':
              reportDataKey.data['formData'] = stepDataItem.templateOptions.formData ;
              reportData['next'] = stepData.next;
              break;
            default:
              if(reportData.data[reportDataKey.key]){
                reportData.data[reportDataKey.key]['required'] = stepDataItem.templateOptions.required;
              }
              reportData['next'] = stepData.next;

          }
        }
        else{
          reportData['next'] = stepData.next;
        }
    });
      return reportData
  }
  
  mapTimeStamp(time) {
    const timeString = time;
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds
   }

   videoFlowVideoTimestampMap(templateOptions) {
    let url
    if(templateOptions.startTime) {
      const totalSecondsStart = this.mapTimeStamp(templateOptions.startTime)
      const totalSecondsEnd = this.mapTimeStamp(templateOptions.endTime) + 1
      url = templateOptions.videoUrl + `#t=${totalSecondsStart},${totalSecondsEnd}`
    }
    return url
   }

  convertMsForExport(ms){
    let minutes = (ms / 1000) / 60;
    let seconds = (ms / 1000) % 60;
    if (minutes >= 1) {
      return  ('0:' + (minutes > 9 ? Math.floor(minutes) : "0" + Math.floor(minutes))  + ":" + (seconds > 9 ? Math.ceil(seconds) : "0" + Math.ceil(seconds) ))
    } else {
      return  ('0:' + "00" + ":" + (seconds > 9 ? Math.ceil(seconds) : "0" + Math.ceil(seconds) ))
    }
  }

  isArray(val): boolean { return Array.isArray(val); }

  private transformName(type){
    let transformedType = type
    switch (type) {
      case 'captureImage':
        transformedType = 'Capture Image'
        break;
        case 'captureVideo':
          transformedType = 'Capture Video'
          break;
        case 'empty':
          transformedType = 'Empty'
          break;
        case 'label':
          transformedType = 'Label'
          break;
        case 'multicheckbox':
          transformedType = 'Multicheckbox'
          break;
        case 'image':
          transformedType = 'Image'
          break;
        case 'video':
          transformedType = 'Video'
          break;
        case 'ppe':
          transformedType = 'PPE'
          break;
        case 'qr':
          transformedType = 'QR'
          break;
        case 'radio':
          transformedType = 'Radio'
          break;
        case 'input':
          transformedType = 'Input'
          break;
        case 'number':
          transformedType = 'Number'
          break;
        case 'textarea':
          transformedType = 'Textarea'
          break;
        case 'datepicker':
          transformedType = 'Datepicker'
          break;
        case 'pdf':
          transformedType = 'PDF'
          break;
        case 'html':
          transformedType = 'Rich Text'
          break;
        case 'gallery':
          transformedType = 'Gallery'
          break;
        case 'form':
          transformedType = 'Form'
          break;
        case 'table':
          transformedType = 'Table'
          break;
        case 'id':
          transformedType = 'ID'
          break;
        default:
          break;
    }
    return transformedType
}

  private convertToCSV(objArray){
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','
            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

  private exportCSVFile(headers, items, fileTitle):void {
    if (headers) {
        items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement("a");

    if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
  download(rows: any[]){
    let headers = {
      uniqueSubmissionID: "Unique submission ID",
      workflowName: "Name",
      version: "Version",
      description: "Description",
      labels: "Labels",
      estimatedDuration: "Estimated Duration",
      stepsCount: "Total Step Count",
      submittedBy: "Submitted By",
      startedAt: "Started At",
      endedAt: "Ended At",
      workflowDuration: "Total Duration",
      stepIndex: "Step",
      stepName: 'Step Name',
      duration: "Step Duration (~)",
      type: "Type",
      label: "Title",
      value: "Value",
    },
    fileTitle = 'Export';
      this.exportCSVFile(headers, rows, fileTitle);
  }

  byPassHTML(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
  
  mapReportDataForCsvExport(wfReportData, workflowPublishedVersion,reportUser, report): any[] {
    let wfInfo= true

    // format the data
    const rows = []
    wfReportData.results.forEach((item,indexBox) => {
        let newVal
        let newItem
        let labelVal
        let typeVal

        let valArray = []
        let itemArray = []
        let labelArray = []
        let typeArray = []
        let durationArray = []
        let wfDuration = this.convertMsForExport(wfReportData.duration)

        if(item?.reportArray?.length>0) {
          item.reportArray.forEach(valueItem => {
            newVal = (valueItem?.data['label'] ? valueItem.data['label'] : ' ')  + (valueItem?.data['value'] ? valueItem.data['value'] : '')
            if(valueItem?.data['type'] =='label'){
              newItem = valueItem.data['value']
            }
            if(valueItem?.data['value'] && valueItem?.data['type']){
              if(valueItem?.data['type'] =='ppe'){
                if(valueItem?.data['value']?.link) {
                  let key = Object.keys(item.data).find(itemKey => (itemKey == valueItem.key ))
                  const obj = {
                    report: report,
                    step: indexBox.toString(),
                    key: key,
                  };
                  newItem = `${environment.endPoints.workflowBase}/report-file?` + new URLSearchParams(obj).toString();
                }else{
                  newItem = ' ';
                }
              }
              else if(valueItem?.data['type'] =='qr'){
                let newValue
                if(valueItem.data['value']['qr'] || valueItem.data['value'].hasOwnProperty('qr')) {
                  newValue = valueItem.data['value']['qr']
                }else{
                  newValue = valueItem.data['value']
                }
                newItem = newValue ? newValue : ' '
              }
              else if(valueItem?.data['type']=='image' || valueItem?.data['type']=='video' || valueItem?.data['type']=='pdf'){
                let key = Object.keys(item.data).find(itemKey => (itemKey == valueItem.key ))
                const obj = {
                  report: report,
                  step: indexBox.toString(),
                  key: key,
                };
                newItem = `${environment.endPoints.workflowBase}/report-file?` + new URLSearchParams(obj).toString();
              }
              else if(valueItem?.data['type']=='gallery'){
                let imageGroup=''
                valueItem.data['value'].forEach((multiItem, index)=> {
                  let key = Object.keys(item.data).find(itemKey => (itemKey !== 'isValid' && itemKey !== 'labels'))
                  const obj = {
                    report: report,
                    step: indexBox.toString(),
                    key: key,
                    index:index
                  };
                  let newImage = `${environment.endPoints.workflowBase}/report-file?` + new URLSearchParams(obj).toString();
  
                  imageGroup +=  (index !== 0 ? ',' : '') + newImage
                })
                newItem = `"${imageGroup}"`
              }
              else if((valueItem?.data['type']=='captureImage' || valueItem?.data['type']=='captureVideo') &&   (typeof valueItem?.data['value']  == 'string')){
                let key = Object.keys(item.data).find(itemKey => (itemKey == valueItem.key))
                const obj = {
                  report: report,
                  step: indexBox.toString(),
                  key: key,
                };
                newItem = `${environment.endPoints.workflowBase}/report-file?` + new URLSearchParams(obj).toString();
              }
              else if((valueItem?.data['type']=='captureImage' || valueItem?.data['type']=='captureVideo') &&  (typeof valueItem?.data['value']  == 'object')){
                let imageGroup=''
                valueItem.data['value'].forEach((multiItem, index)=> {
                  let key = Object.keys(item.data).find(itemKey => (itemKey !== 'isValid' && itemKey !== 'labels'))
                  const obj = {
                    report: report,
                    step: indexBox.toString(),
                    key: key,
                    index:index
                  };
                  let newImage = `${environment.endPoints.workflowBase}/report-file?` + new URLSearchParams(obj).toString();
  
                  imageGroup +=  (index !== 0 ? ',' : '') + newImage
                })
                newItem = `"${imageGroup}"`
              }
              else if(valueItem?.data['type'] =='multicheckbox'){
                let multiGroup=''
                valueItem.data['value'].forEach((multiItem, index)=> {
                  multiGroup +=  (index !== 0 ? ',' : '') + multiItem
                })
                newItem = `"${multiGroup}"`
              }
              else if(valueItem?.data['type'] =='html') {
                const regex = /[$~#]/;
                newItem = regex.test(valueItem.data['html']) ? this.htmlPipe.transform(valueItem.data['value']) : valueItem.data['html']
              }
              else if(valueItem?.data['type'] =='datepicker'){
                const datePipe: DatePipe = new DatePipe(
                  this.multiLanguageService.currentLang.code
                )
                if(typeof valueItem?.data['value']  == 'number' ){
                  let dateValue = datePipe.transform(valueItem?.data?.['value'],'long')
                  newItem = `"${dateValue}"`
                }else{
                  newItem = `"${valueItem?.data['value']}"`
                }
              }
              else if(valueItem?.data['type'] =='table' || valueItem?.data['type'] =='form'){
                newItem = `""`
              }
              else{
                newItem = `"${valueItem?.data['value']}"`
              }
            }else if(valueItem.length){
              valueItem.forEach(unsetLabel => {
                newVal = (unsetLabel ? unsetLabel : ' ') 
              })
            }
            else if(!valueItem?.data['value']){
              newItem = ''
            }
            let duration=0
            item.durations?.forEach(durationData => {
              if(durationData?.duration) {
                duration += durationData.duration
              }
              
            })
            if(valueItem?.data['type'] =='label'){
              labelVal =  `"${(valueItem?.data['value'])}"`
            }else{
              labelVal = (valueItem?.data['label'] ? `"${valueItem.data['label']}"` : ' ')
            }
            typeVal = (valueItem?.data['type'] ? valueItem.data['type'] : ' ')
            let durationO = this.convertMsForExport(duration)
  
            labelArray.push(labelVal)
            typeArray.push(typeVal)
            itemArray.push(newItem)
            valArray.push(newVal)
            durationArray.push(durationO)
          }) 
        }

       

        for (let i = 0; i < valArray.length; i++) {
            let itemLabel = ' ';
            let itemValue:any = ' ';
            let label:any = ' ';
            let value:any = ' ';

            if(i == 0) {
                itemLabel = item.stepName;
                itemValue = item.data;
                label = valArray[i].label;
                value = valArray[i].value;

            }
            const datePipe: DatePipe = new DatePipe(
              this.multiLanguageService.currentLang.code
            )
            let startTime = datePipe.transform(wfReportData.startTime,'long')
            let endTime = datePipe.transform(wfReportData.endTime,'long')
            let workflowEstimatedDuration = workflowPublishedVersion.estimatedDuration?.hours +':' + workflowPublishedVersion.estimatedDuration?.minutes

            let labelGroup=''
            let labels=''
            if(workflowPublishedVersion.labels) {
              workflowPublishedVersion.labels.forEach((label,index) => {
                if(label){
                 labelGroup +=  (index !== 0 ? ',' : '') + label
                 labels = `"${labelGroup}"`
                }
              })
            }
            
            rows.push({
              uniqueSubmissionID: wfInfo ? wfReportData.id : ' ',
              name: wfInfo ? `"${wfReportData.workflowName}"` : ' ',
              version: wfInfo ? wfReportData.version : ' ',
              description: wfInfo && wfReportData.workflowDescription ? `"${wfReportData.workflowDescription}"` : ' ',
              labels: wfInfo && workflowPublishedVersion.labels && workflowPublishedVersion.labels.length!==0  ? labels : ' ',
              estimatedDuration: wfInfo && workflowPublishedVersion.estimatedDuration && workflowPublishedVersion.estimatedDuration.minutes !==0 ? workflowEstimatedDuration  : ' ',
              stepsCount: wfInfo ? wfReportData.results.length : ' ',
              submittedBy: wfInfo && reportUser ? reportUser : ' ',
              startedAt: wfInfo ?  `"${startTime}"`:  ' ',
              endeddAt: wfInfo ? `"${endTime}"` :  ' ',
              workflowDuration: wfInfo ? wfDuration : ' ',
              stepIndex: indexBox+1,
              stepName: `"${itemLabel}"`,
              duration: durationArray[i],
              type: this.transformName(typeArray[i]),
              itemLabel:labelArray[i],
              itemVAL:itemArray[i],
            });
            wfInfo = false

        }

    });
    return rows;
  }

  export(headers,itemsFormatted,fileTitle) {
    this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }
}