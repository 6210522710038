import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NgForm, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableData } from 'src/app/components/main/content/workflow/workflow-steps/widget-properties/table-form-properties/table-form-properties.component';

@Component({
  selector: 'app-table-widget-view',
  templateUrl: './table-widget-view.component.html',
  styleUrls: ['./table-widget-view.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TableWidgetViewComponent
    }
  ]
})
export class TableWidgetViewComponent implements ControlValueAccessor, OnDestroy {
  @Input() tableData: TableData;
  
  previewForm: UntypedFormGroup
  
  selectedCell
  
  private subscriptions: Subscription[] = []

  getMultiCheckboxOptions(key): UntypedFormGroup[] {
    return (this.previewForm.get(key) as UntypedFormArray).controls as UntypedFormGroup[];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe())
  }

  onChange = (data) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value) {
    this.initializePreviewForm(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // Map form value for user-workflow submit operation
  emitFormOutputData(): any {
    let output = this.previewForm.value
    this.tableData.rows.forEach((row) => {
      row.cells.forEach(cell => {
        if (cell.type == 'datepicker') {
          const formControlValue: Date = output[cell.key] || null
          output[cell.key] = formControlValue?.getTime()
        }
        else if (cell.type == 'multicheckbox') {

          output[cell.key] = output[cell.key].filter((item) => item.value).map((item) => item.key)
        }
        else if (cell.type == 'number') {
          output[cell.key] = output[cell.key].numberOutput
        }  
      })
    })
    this.onChange(output)
  }

  initializePreviewForm(initialFormValue = {}) {
    let formData = {}

    this.tableData.rows.forEach(row => {
      row.cells.forEach(cell => {
        const value = initialFormValue[cell.key]
        if (cell.type == 'empty') {
          formData[cell.key] = new UntypedFormControl(value)
        }
    
        if(cell.type == 'label') {
          formData[cell.key] = new UntypedFormControl(value)
        }
    
        if(cell.type == 'input') {
          formData[cell.key] = new UntypedFormControl(value, cell.templateOptions.required ? Validators.required : null)
        }
    
        if(cell.type == 'number') {
          let validationArray = []
          if(cell.templateOptions.required){
            validationArray.push(Validators.required)
          }
          if(cell.templateOptions.min){
            validationArray.push(Validators.min(cell.templateOptions.min))
          }
          if(cell.templateOptions.max){
            validationArray.push(Validators.max(cell.templateOptions.max))
          }
          
          formData[cell.key] = new UntypedFormGroup({ 
            numberFirst: new UntypedFormControl(value?.toString().includes('.') ? value.split(".")[0] : value), 
            numberSecond: new UntypedFormControl(value?.toString().includes('.') ? value.split(".")[1] : null, cell.templateOptions.precision ? Validators.maxLength(cell.templateOptions.precision): null), 
            numberOutput: new UntypedFormControl(value || null, validationArray) 
          })

          this.subscriptions.push(formData[cell.key].valueChanges.subscribe((value) => {
            const numberOutput = value.numberSecond ? (value.numberFirst + '.' + value.numberSecond) : value.numberFirst
            if (numberOutput !== value.numberOutput) {
              formData[cell.key].controls.numberOutput.patchValue(numberOutput)
            }
          }))

        }
    
        if(cell.type == 'datepicker') {
          formData[cell.key] = new UntypedFormControl(value ? new Date(value) : null, cell.templateOptions.required ? Validators.required : null)
        }
    
        if(cell.type == 'multicheckbox') {
            const optionList = []
            for (const option of cell.templateOptions.options) {
              const optionValue = (value || []).find((item) => item === option.value)
              optionList.push(new UntypedFormGroup({
                value: new UntypedFormControl(!!optionValue, cell.templateOptions.required ? Validators.required : null),
                option: new UntypedFormControl(option.label),
                key: new UntypedFormControl(option.value)
              }))
            }
          // TODO bos array yerine cell yazmisim duzeltilecek
          formData[cell.key] = new UntypedFormArray(optionList, [])
        }
        
        if(cell.type == 'radio'){
          formData[cell.key] = new UntypedFormControl(value, cell.templateOptions.required ? Validators.required : null)
        }
      })
    })
    this.previewForm = new UntypedFormGroup(formData)
    this.emitFormOutputData()
  }

  selectCell(cell) {
    this.selectedCell = cell;
  }

  onCellUpdated(event) {
    this.previewForm.controls[this.selectedCell.key].patchValue(event)
    this.emitFormOutputData()
    this.selectedCell = null
  }

  closeModal(event) {
    if(event) {
      this.selectedCell= null
    }
  }

}
