<nav class="navbar navbar-light navbar-default navbar-static-top navbar-expand px-0 bg-white">
    <div class="container-fluid">
        <a class="icon-wrapper" (click)="toggleSidebar()">
            <img class="icon" src="assets/img/sidebar-toggle.svg">
        </a>
        <div>
            <div class="language-dropdown" dropdown #dropdown="bs-dropdown">
                <a id="language-toggle" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                    <i id="lang-image" class="mi mi-language mr-2"></i>
                    <span class="name fs-14 font-weight-500">{{ currentLang.code | uppercase }}</span>
                    <i id="dropdown-icon" class="mi mi-keyboard-arrow-down ml-2"></i>
                </a>
                <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                    <ng-container *ngFor="let lang of allLangs">
                        <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                            <a class="dropdown-item language-link px-2 fs-14 font-weight-500" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div class="profile-wrapper">
                <a class="avatar-wrapper">
                    <img class="avatar" src="assets/img/avatar.svg">
                </a>
                <div class="dropdown-wrapper" dropdown>
                    <a class="name-wrapper dropdown-toggle" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                        <span class="name fs-14 font-weight-500 core-blue">{{ user.name }}</span>
                        <img class="down-icon" src="assets/img/down-icon.svg">
                    </a>
                    <div class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item fs-14 disabled" role="menuitem">v{{ version }}</a>
                        <a class="dropdown-item fs-14" role="menuitem" (click)="onLogout()">{{ 'MAIN.NAVBAR.LOGOUT.TEXT' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>