<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card" *ngIf="!isLoading">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600 text-white">{{ 'MAIN.CONTENT.DASHBOARD.GENERATE' | translate }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            <div style="width: 75%;">
                <label class="text-white fs-14">{{'MODALS.GENERATE-WORKFLOW.PROMPT-TEXT' | translate}}</label>
                <textarea type="text" class="form-control mt-3 text-area-style" [formControl]="promptText" rows="4" cols="7"
                placeholder="{{ 'MODALS.OPEN-AI.DESCRIBE' | translate }}"></textarea>
            </div>
        </div>
        <div class="d-flex mb-5 top-gap-30 justify-content-around ms-1" style="padding-inline: 12px;">
            <button  type="button" class="v-btn btn vsight-button confirm cursor-pointer" style="font-size: 0.875rem;" (click)="confirm(1)" [disabled]="!promptText.valid">
                <label class="fs-14 font-weight-600 text-blue cursor-pointer">{{ 'MAIN.CONTENT.DASHBOARD.GENERATE' | translate }}</label>
                <img src="assets/img/ai_icon_green.svg" alt="" class="ms-2" height="20px">
            </button>
        </div>
    </div>
</div>