<div class="card preview-card">
    <div class="card-header preview-card-header">
        <div class="text-center w-100">
            <label class="fs-14 preview-main-title font-weight-400">{{workflowName}}</label>
        </div>
       
    </div>
    <div class="card-body preview-card-body" [ngClass]="direction" [ngStyle]="{'flex-direction': parentFlexDirection}" #step>
        <div *ngIf="layoutCount > 0" class="first-layout" tabindex="0" (focus)="onLayoutSelect(0)" [class.selected]="selectedLayoutNo === 0" [ngStyle]="{ 'flex-grow': firstFlexGrow }" [style.width.%]="width1" [style.height.%]="height1" #div1 [class.table-view]="stepData.layouts[0]?.items[0]?.type=='table' || stepData.layouts[0]?.items[0]?.type=='form'">
            <ng-container *ngIf="stepData.layouts[0]?.items" [ngTemplateOutlet]="layoutColumnTemplate" [ngTemplateOutletContext]="{ layout: stepData.layouts[0]?.items }"></ng-container>
            <ng-container *ngIf="!stepData.layouts.length || !stepData.layouts[0]" [ngTemplateOutlet]="layoutEmptyTemplate"></ng-container>
        </div>
        
        <div class="divider-vertical divider" *ngIf="parentFlexDirection == 'row' && layoutCount>1" (mousedown)=onMouseDown($event)  [ngStyle]="{'pointer-events': type=='published' || viewOnly ? 'none' : ''}">
            <!-- <img src="assets/img/step-divider-icon.svg" alt="" class="divider-icon-step" (mousedown)=onMouseDown($event)> -->
        </div>
        <div class="divider-horizontal divider" *ngIf="parentFlexDirection == 'column'" (mousedown)=onMouseDown($event) [ngStyle]="{'pointer-events': type=='published' || viewOnly ? 'none' : ''}">
            <!-- <img src="assets/img/step-divider-icon.svg" alt="" class="divider-icon-step-vertical"> -->

        </div>

        <div *ngIf="layoutCount > 1" class="second-layout" tabindex="0" (focus)="onLayoutSelect(1)" [class.selected]="selectedLayoutNo === 1" [ngStyle]="{ 'flex-grow': secondFlexGrow }" [style.width.%]="width2" [style.height.%]="height2" #div2>
            <ng-container *ngIf="stepData.layouts[1]?.items" [ngTemplateOutlet]="layoutColumnTemplate" [ngTemplateOutletContext]="{ layout: stepData.layouts[1]?.items }"></ng-container>
            <ng-container *ngIf="!stepData.layouts.length || !stepData.layouts[1]" [ngTemplateOutlet]="layoutEmptyTemplate"></ng-container>
        </div>
    </div>
    <div class="card-footer preview-card-footer">
        <div class="button-placeholder rounded"></div>
        <div class="fs-14 d-flex preview-main-title font-weight-400" *ngIf="!stepData.isSection && !stepData.parentId">
            <label>{{selectedStepOrder}}</label>
            <label>/</label>
            <label class="cut-text" title="{{stepData.name}}">{{stepListCount}} - {{stepData.name}}</label>
        </div>
        <div class="fs-14 d-flex preview-main-title" *ngIf="stepData.parentId">
            <label>{{sectionStepOrder + 1}}</label>
            <label>/</label>
            <label class="cut-text" title="{{stepData.name}}">{{sectionStepsCount}} - {{stepData.name}}</label>
        </div>
        <div class="fs-14 d-flex preview-main-title" *ngIf="stepData.isSection"><label>{{stepData.name}}</label></div>
        <div class="button-placeholder rounded"></div>
    </div>
</div>

<ng-template #layoutColumnTemplate let-layout="layout">
    <div class="inner-layout d-flex align-items-{{layout[0]?.templateOptions?.vPosition}} justify-content-{{layout[0]?.templateOptions?.hPosition}}" [ngStyle]="{'position': layout[0]?.type === 'table' ? 'relative' : ''}">
        <div class="d-flex flex-column" style="width: 100%;height: 100%;" [ngStyle]="{'width':layout[0].type!=='label' ? '100%' : '' , 'height':layout[0].type!=='label' ? '100%' : ''}">
            <div *ngIf="!(layout[0]?.type === 'label' || layout[0]?.type === 'empty' || layout[0]?.type === 'html')">
                <label class="label-preview-title">{{layout[0].templateOptions.label}} <span *ngIf="layout[0].templateOptions.required">*</span></label>
            </div>
            <div class="layout-style d-flex  justify-content-center" [ngStyle]="{'align-items' : layout[0]?.type == 'html' || layout[0]?.type == 'radio' || layout[0]?.type == 'multicheckbox' ? 'start' : 'center', 'overflow-y' : layout[0]?.type == 'radio' || layout[0]?.type == 'multicheckbox' || layout[0]?.type == 'html' ? 'scroll' : '' }" style="height: 90%;" *ngIf="layout[0]?.type !== 'table'">
                <form [formGroup]="form" style="width: 100%;" [ngStyle]="{'height': layout[0]?.type === 'form' ? '100%' : ''}">
                    <formly-form [model]="model" [fields]="layout" [options]="options" [form]="form"></formly-form>
                </form>
            </div>
            <div *ngIf="layout[0]?.type === 'table'" class="w-100 h-100">
                <app-table-form 
                [stepData] = "stepData" 
                (onCellSelected)="emitPreviewUpdates($event)" 
                [workflowTranslations]="workflowTranslations"
                (onRowDeleted)="onRowDeleted($event)"
                (onColumnDeleted)="onColumnDeleted($event)"  
                class="w-100 h-100"></app-table-form>
            </div>
        </div>
    </div>
    <div style="position: absolute;top:0;left:0;bottom: 0;right: 0;" (focus)="onLayoutSelect(0)" *ngIf="layout[0]?.type !== 'table' && layout[0]?.type !== 'radio' && layout[0]?.type !== 'multicheckbox' && layout[0]?.type !== 'html'"></div>
    <div style="position: absolute;top:0;left:0;bottom: 0;right: 0;" (focus)="onLayoutSelect(0)" *ngIf="layout[0]?.type == 'table' && selectedLayoutNo !== 0"></div>

    <div class="delete" (click)="deleteLayout()" *ngIf="layout[0]?.type !== 'empty'" [ngClass]="{'delete-table-view': layout[0]?.type == 'table' || layout[0]?.type == 'form'}">
        <img src="assets/img/delete-widget.svg" alt="" class="normal-icon">
        <img src="assets/img/delete-widget-hover.svg" alt="" class="hover-icon">
    </div>

</ng-template>

<ng-template #layoutEmptyTemplate>
    <div class="d-flex align-items-center justify-content-center h-100 text-muted fs-14 inner-layout">
        Not Selected
    </div>
</ng-template>