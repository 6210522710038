import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MultilanguageService } from '@services/support/multilanguage.service';
import {  map, Observable } from "rxjs";

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(public multiLanguageService: MultilanguageService) {}

  transform(value: any, pattern: string = 'long'): Observable<string> {
    return this.multiLanguageService.onLangChange.pipe(
      map(() => {
        const datePipe: DatePipe = new DatePipe(
          this.multiLanguageService.currentLang.code
        )
        if(value) {
          return datePipe.transform(value, pattern);
        }else{
          return null
        }

      })
    )
  }
}
