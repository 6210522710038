import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DetailPageService } from '@services/detail-page.service';
import { LoaderService } from '@services/support/loader.service';
import { NavigationService } from '@services/support/navigation.service';
import { UserService } from '@services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MustMatch } from 'src/app/validators/must-match.validator';
import { PasswordRequirements } from 'src/app/validators/password-requirements.validator';
import { UniqueUsername } from 'src/app/validators/unique-username.validator';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { QRCodeComponent } from 'angularx-qrcode';

import { User } from '../../../../models/User';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@services/support/snackbar.service';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/support/app.service';
import { RoomService } from '@services/remote/room.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  @ViewChild('qr', { static: false }) qrContainer: QRCodeComponent;

  @Input() user?
  @Input() detailMode
  @Input() workflowUserCount
  @Input() workflowUserLimit

  passwordValueSub: Subscription = null;
  
  requirements = {
    minLength: 6,
    uppercase: false,
    lowercase: false,
    number: false,
    specialCharacter: false
  }
  AZContains: boolean = false;
  azContains: boolean = false;
  numberContains: boolean = false;
  specialCharacterContains: boolean = false;

  userDetailForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9ıİğĞüÜşŞöÖçÇ][a-zA-Z0-9ıİğĞüÜşŞöÖçÇ\\-._ ]{0,59}$")]),
    username: new UntypedFormControl('', {
      validators: [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]{1,30}$")],
      asyncValidators: [UniqueUsername.createValidator(this.userService)],
      updateOn: 'change'
    }),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$")]),
    workflowUser: new UntypedFormControl(),
    userWorkflows: new UntypedFormControl(),
    password: new UntypedFormControl('', {
      validators: [Validators.required],
      asyncValidators: [PasswordRequirements.createValidator(this.userService)],
      updateOn: 'change'
    }),
    confirmPassword: new UntypedFormControl('', Validators.required),
    editorAccess: new UntypedFormControl(false),
    allow_archiving: new UntypedFormControl(),
    call_archive_perm_needed: new UntypedFormControl(),
    allow_seeing_all_users: new UntypedFormControl(),
    call_full_hd: new UntypedFormControl(),
    allow_join_link: new UntypedFormControl(),
    rooms: new UntypedFormControl(),
    allow_calling: new UntypedFormControl(),
    defaultRoom: new UntypedFormControl(),
    label: new UntypedFormControl()
  }, {
    validators: MustMatch('password', 'confirmPassword')
  })

  userData: any = {
    name: '',
    username: '',
    email: '',
    editorAccess: false,
    allow_archiving: false,
    allow_seeing_all_users: false,
    allow_calling: false,
    call_archive_perm_needed: false,
    call_full_hd: false,
    rooms: []
  };

  changes = {
    uid: '',
    name: '',
    properties: {},
    deleted_rooms: [],
    inserted_rooms: []
  }

  userDetailFormSub: Subscription = null;
  formPristine: boolean = true;
  passwordChangeInvalid: boolean = false;

  sidebarSub: Subscription;
  sidebarClosed: boolean = true;

  qrSub: Subscription = null;
  isQrAvailable: boolean = false;
  qrData: string = "";
  qrCodeDownloadLink: SafeUrl = "";

  userRoles = ['coadmin', 'user']
  selectedRole = 'user'

  currentUser: User

  accountDataSub: Subscription = null;
  accountArchiveAllowed: boolean = false;
  accountJoinLinkAllowed: boolean = false;
  accountCallingAllowed: boolean = false;
  accountCoAdminAllowed: boolean = false;
  accountAutoJoinRoomAllowed: boolean = false;
  integrationAllowed: boolean = false;
  integrationCode: string = null;
  integrationLoading: boolean = false;
  accountFullHdAllowed: boolean = false;
  accountAlwaysFullHd: boolean = false;

  licenseType: string = "concurrent_user_based";

  rooms = [];
  roomsLoading: boolean = true;
  selectedUserRooms: { id: string, name: string }[] = []

  subscriptions: Subscription[] = []

  labels: string[] = [];
  accountLabelsLoading = true;
  accountLabelsSub: Subscription = null;
  loading = false

  constructor(
    private userService: UserService,
    private detailPageService: DetailPageService,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private appService: AppService,
    private roomService: RoomService
    ) { }

  get form() {
    return this.userDetailForm.controls;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.roomService.rooms.subscribe(rooms => {
      if(rooms) {
        this.rooms = rooms
        .map(room => { return { id: room.id, name: room.room_data.name } })
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.roomsLoading = false;
      if(this.user && this.user.rooms) {
        const userRooms = []
        Object.keys(this.user.rooms).forEach(resp => {
          userRooms.push({id:resp,...this.user.rooms[resp]})
        })
        this.selectedUserRooms = userRooms.map(rid => this.rooms.find(r => r.id === rid.id))
      }
      }
    })) 

    this.currentUser = this.authService.currentUser;
    this.authService.getAccountData().then(resp => {
      if(resp) {
        this.licenseType = resp.data.accountData.license.type;
        this.accountArchiveAllowed = resp.data.accountData.features.archiving ? true : false;
        this.accountJoinLinkAllowed = resp.data.accountData.features.joinwithlink ? true : false;
        this.accountCallingAllowed = resp.data.accountData.features.calling ? true : false;
        this.accountCoAdminAllowed = resp.data.accountData.features.coadmin ? true : false;
        this.accountAutoJoinRoomAllowed = resp.data.accountData.add_ons.autojoinroom ? true : false;
        this.integrationAllowed = resp.data.accountData.add_ons.sso ? true : false;
        this.accountFullHdAllowed = resp.data.accountData.add_ons.fullhd ? true : false;
        this.accountAlwaysFullHd = resp.data.accountData.always_full_hd ? true : false;

        if(this.licenseType === 'expert_user_based') {
          this.userRoles = ['coadmin', 'user', 'expert']
        }
      }
    })

    if (environment.design.showLabels) {
      this.accountLabelsSub = this.authService.getAccountLabels()
      .subscribe(labels => {
        this.labels = labels ? labels : [];
        this.accountLabelsLoading = false;
      });
    }

    this.userService.getAllUsernames()
    if (this.user){
      this.userData = {
        name: this.user.name,
        username: this.user.auth.username,
        email: this.user.email,
        editorAccess: this.user.editorAccess ? true : false,
        allow_join_link: this.user.allow_join_link ? true : false,
        allow_archiving: this.user.allow_archiving ? true : false,
        allow_seeing_all_users: this.user.allow_seeing_all_users ? true : false,
        allow_calling: this.user.allow_calling ? true : false,
        call_archive_perm_needed: this.user.call_archive_perm_needed ? true : false,
        call_full_hd: this.user.call_full_hd ? true : false,
        rooms: Object.keys(this.user.rooms ? this.user.rooms : {}).filter(r => !this.user.rooms[r].personal_room && !this.user.rooms[r].meeting_room)
      }
      if (environment.design.showLabels) {
        this.userData.label = this.user.label ? this.user.label : "";
      }

      this.userDetailForm.patchValue(this.userData);
      if (this.user?.role) {
        this.selectedRole = this.user.coadmin ? 'coadmin' : this.user.role
      }

      if (this.user.role === 'admin' || this.user.role === 'coadmin' || this.user.coadmin) {
        this.userDetailForm.controls['editorAccess'].patchValue(true)
        this.userDetailForm.controls['editorAccess'].disable()
        this.userDetailForm.controls['label'].disable()
      } else if (this.user.role === 'expert') {
        this.userDetailForm.controls['editorAccess'].enable()
        this.userDetailForm.controls['label'].enable()
      } else {
        this.userDetailForm.controls['editorAccess'].enable()
        this.userDetailForm.controls['label'].disable()
      }
    }

    if (this.detailMode === 'edit') {
      this.userDetailFormSub = this.userDetailForm.valueChanges.subscribe(f => {

        this.changes = {
          uid: this.user.id,
          name: f.name !== this.userData.name ? f.name : this.userData.name,
          properties: {},
          deleted_rooms: [],
          inserted_rooms: []
        };
        if (f.name !== this.userData.name) { this.changes.properties['name'] = f.name}
        if (f.username !== this.userData.username) { this.changes.properties['username'] = f.username}
        if (f.email !== this.userData.email) { this.changes.properties['email'] = f.email}
        if ((this.selectedRole === 'expert' || this.selectedRole === 'user') && f.editorAccess !== this.userData.editorAccess) { this.changes.properties['editorAccess'] = f.editorAccess}
        if (f.allow_join_link !== this.userData.allow_join_link) { this.changes.properties['allow_join_link'] = f.allow_join_link}
        if (f.allow_archiving !== this.userData.allow_archiving) { this.changes.properties['allow_archiving'] = f.allow_archiving}
        if (f.allow_calling !== this.userData.allow_calling) { this.changes.properties['allow_calling'] = f.allow_calling}
        if (f.allow_seeing_all_users !== this.userData.allow_seeing_all_users) { this.changes.properties['allow_seeing_all_users'] = f.allow_seeing_all_users}
        if (f.call_archive_perm_needed !== this.userData.call_archive_perm_needed) { this.changes.properties['call_archive_perm_needed'] = f.call_archive_perm_needed}
        if (f.call_full_hd !== this.userData.call_full_hd) { this.changes.properties['call_full_hd'] = f.call_full_hd}
        if ((this.user?.coadmin ? 'coadmin' : this.user?.role) !== this.selectedRole) { this.changes.properties['role'] = this.selectedRole }
        if (environment.design.showLabels && (f.label ? f.label : "") !== this.userData.label) {
          this.changes.properties['label'] = f.label ? f.label : ""
        }

        const rooms = Object.keys(this.user.rooms ? this.user.rooms : {}).filter(r => !this.user.rooms[r].personal_room && !this.user.rooms[r].meeting_room)
        const deleted_rooms = rooms.filter(x => !f.rooms.includes(x));
        const inserted_rooms = f.rooms.filter(x => !rooms.includes(x));
        if (deleted_rooms.length > 0) {
          this.changes.deleted_rooms = deleted_rooms;
        } else {
          this.changes.deleted_rooms = [];
        }
        if (inserted_rooms.length > 0) {
          this.changes.inserted_rooms = inserted_rooms;
        } else {
          this.changes.inserted_rooms = [];
        }

        this.formPristine = (
          f.name === this.userData.name &&
          f.username === this.userData.username &&
          f.email === this.userData.email &&
          this.changes.inserted_rooms.length === 0 &&
          this.changes.deleted_rooms.length === 0 &&
          f.allow_join_link === this.userData.allow_join_link &&
          f.allow_archiving === this.userData.allow_archiving &&
          f.allow_calling === this.userData.allow_calling &&
          f.allow_seeing_all_users === this.userData.allow_seeing_all_users &&
          f.call_archive_perm_needed === this.userData.call_archive_perm_needed &&
          f.call_full_hd === this.userData.call_full_hd &&
          ((this.selectedRole === 'expert' || this.selectedRole === 'user') ? f.editorAccess === this.userData.editorAccess : true) &&
          (environment.design.showLabels ? f.label === this.userData.label : true)
        ) ? true : false;
      })
   
      if(this.user.auth.qr) {
        this.isQrAvailable = true;
        this.qrData = this.user.auth.account_name + "!!" + this.user.auth.username + '!!' + this.user.auth.qr + '!!v2';
      }else{
        this.isQrAvailable = false;
        this.qrData = null;
      }
    }

    this.passwordValueSub = this.userDetailForm.get('password').valueChanges.subscribe(value => {
      this.testPasswordRequirements(value);
    });
    this.sidebarSub = this.navigationService.sidebarState.subscribe(state => {
      this.sidebarClosed = state;
    });
    this.requirements = this.userService.passwordRequirements;
  }

  onLabelChange(label: string) {
    if (!label) {
      this.userData.label = ""
    }
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  testPasswordRequirements(password: string){
    this.AZContains = RegExp('.*[A-Z].*').test(password);
    this.azContains = RegExp('.*[a-z].*').test(password);
    this.numberContains = RegExp('.*[0-9].*').test(password);
    this.specialCharacterContains = RegExp('.*[-*/?#&()@%+\'!$^:,~_.½£₺é;{}ß>|æ].*').test(password);
  }

  onBackButton(){
    this.detailPageService.removeComponent();
  }

  onUserSubmitForm(){
    const newUser = {
      name: this.userDetailForm.get('name').value,
      role: this.selectedRole,
      allow_archiving: false,
      allow_join_link: false,
      allow_calling: false,
      allow_seeing_all_users: false,
      call_archive_perm_needed: false,
      username: this.userDetailForm.get('username').value,
      email: this.userDetailForm.get('email').value,
      rooms: [],
      password: this.userDetailForm.get('password').value,
      editorAccess: this.userDetailForm.get('editorAccess').value
    }
    this.createUser(newUser);
  }

  createUser(newUser: User) {
    if(this.workflowUserCount == this.workflowUserLimit && this.workflowUserLimit !== 0) {
      const initialState= {
        textMessage : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.LIMIT-WARNING'),
        confirmButtonText : this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
        confirmButtonStyle : 'primary'
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      });
      alertmodalRef.content.onClose.subscribe(isConfirm => {
        if(isConfirm) {
          this.saveUser(newUser,false)
        }
      })
    }else{
      this.saveUser(newUser,true)
    }
  }

  saveUser(newUser,limitError) {
    this.userDetailForm.disable();
      this.loaderService.show();
      return this.userService.createUser(newUser)
      .then(result => {
        if(!result.workflowUser && limitError) {
          this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.USER-DETAIL.CREATED-SUCCESSFULLY'))
        }
        this.detailPageService.removeComponent();
      })
      .catch(error => {
        this.snackBarService.error(this.translateService.instant('ERRORS.ERROR'))
        this.userDetailForm.enable();
      })
      .finally(() => {
        this.loaderService.hide();
      })
  }

  async onSaveChanges() {
    this.loaderService.show();
    if (!(this.userDetailForm.controls['password'].errors && this.userDetailForm.controls['confirmPassword'].errors)) {
      try {
        await this.onPasswordChange()
      } catch (error) {
        console.log(error)
      }
    }

    return this.userService.updateUser(this.changes)
    .then(() => {
      if(this.user.auth?.username == this.currentUser.auth?.username && this.changes.properties?.['editorAccess'] === false) {
        this.appService.isEditorModeSource.next(false)
      }
      this.user.name = this.changes.properties['name'] ? this.changes.properties['name'] : this.user.name;
      this.user.username = this.changes.properties['username'] ? this.changes.properties['username'] : this.user.username;
      this.user.email = this.changes.properties['email'] ? this.changes.properties['email'] : this.user.email;

      /*this.detailPageService.removeComponent()
      const [instance, onClose] = this.detailPageService.loadComponent(UserDetailComponent);
      instance.user = this.user;
      instance.detailMode = 'edit';*/
    })
    .catch(error => {
      if(error.error) {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onPasswordChange() {
    console.log("onPasswordChange")

    return this.userService.changeUserPassword(this.user.id, this.user.auth.username, this.userDetailForm.get('password').value)
    .then(() => {
      this.userDetailForm.get('password').patchValue("");
      this.userDetailForm.get('confirmPassword').patchValue("");
      this.snackBarService.success("Password changed.")

      // const [instance, OnClose] = this.detailPageService.loadComponent(UserDetailComponent);
      // instance.user = this.user;
      // instance.detailMode = 'edit';
    })
    .catch(error => {
      this.snackBarService.error("Cannot change password.")
    })
  }

  deleteUser(userId: string) {
    const initialState = {
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-USER'),
      textMessage : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE-USER-OR-NOT'),
      confirmButtonText : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.loaderService.show();
        this.userService.deleteUser(userId).finally(() => {
          this.loaderService.hide();
          this.detailPageService.removeComponent();
        });
      } else {
        alertmodalRef.hide();
      }
    })
  }

  onGenerateQR() {
    this.loaderService.show();
    this.userService.generateQr(this.user.auth.username)
    .then(() => {
      this.isQrAvailable = true;
    })
    .catch(error => {
      if(error.error) {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onRegenerateQR() {
    this.loaderService.show();
    this.userService.generateQr(this.user.auth.username)
    .then(() => {

    })
    .catch(error => {
      if(error.error) {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onDeleteQR() {
    const initialState = {
      headerTitle: ' ',
      textMessage : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE-QR-OR-NOT'),
      confirmButtonText : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE'),
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.isQrAvailable = false;
        this.userService.removeQR(this.user.auth.username)
        .catch(error => {
          if(error.error) {
            this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
          }
        })
      } else {
        alertmodalRef.hide();
      }
    })
  }

  selectRole(role) {
    console.log(role)
    
    this.selectedRole = role
    if (role === 'coadmin') {
      this.userDetailForm.controls['editorAccess'].patchValue(true)
      this.userDetailForm.controls['editorAccess'].disable()
      this.userDetailForm.controls['label'].disable()
      this.userDetailForm.controls['label'].setValue('')
    } else if (role === 'expert') {
      this.userDetailForm.controls['editorAccess'].enable()
      this.userDetailForm.controls['label'].enable()
    } else {
      this.userDetailForm.controls['editorAccess'].enable()
      this.userDetailForm.controls['label'].disable()
      this.userDetailForm.controls['label'].setValue('')
    }
  }

  userRoomsChanged(rooms: any[]) {
    this.selectedUserRooms = rooms
    if (!this.selectedUserRooms.find(r => r.id === this.user.default_room)) {
      this.user.default_room = null
    }
  }

  addTagPromise(name) {
    this.loading = true
    return this.authService.setAccountLabels([...this.labels, name]).then(() => {
      this.labels.push(name);
      this.loading = false
      return name;
    })
  }

  ngOnDestroy() {
    if (this.passwordValueSub) { this.passwordValueSub.unsubscribe() }
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
    if (this.qrSub) { this.qrSub.unsubscribe() }
    if (this.userDetailFormSub) { this.userDetailFormSub.unsubscribe() }
  }


}
