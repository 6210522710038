import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-remote-workflow',
  templateUrl: './remote-workflow.component.html',
  styleUrls: ['./remote-workflow.component.scss']
})
export class RemoteWorkflowComponent implements OnInit {

  workflow

  contentListClicked
  isSubmitted

  remoteUserId
  token

  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private workflowService: WorkflowService
    ) { }

  ngOnInit(): void {
    this.initialize()
  }

  initialize() {
    this.route.queryParams.pipe(first()).toPromise().then(async param => {
      try {
        const idToken = atob(decodeURIComponent(param['token']));
        this.token = idToken
        
        const result = await this.workflowService.switchToWorkflow(idToken)
         this.remoteUserId = result.account_id
         this.workflowService.getMyWorkflows(false, false, idToken).then(res => {
          let wf = res.find(workflow => workflow.id ==  param['id'])
          this.workflow = wf
         })
        
        } catch(err) {
         console.log(err)
        }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => this.loaderService.hide())
  }

  workflowSubmitted(event) {
    this.isSubmitted = event
  }

  contentListClickedChange(event) {
    this.contentListClicked = event
  }

}
