import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qr-widget',
  templateUrl: './qr-widget.component.html',
  styleUrls: ['./qr-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QrWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QrWidgetComponent),
      multi: true,
    },
  ],
})
export class QrWidgetComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

  @Input() item //type tanımla
  @ViewChild('action',  {static: true}) action: NgxScannerQrcodeComponent

  subscriptions : Subscription[] = []

  qrControl = new UntypedFormControl()
  qrDetected =false
  isScan =false
  scanStarted = false
  
  showQrMatchError = false

  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public qrCodeResultr: ScannerQRCodeResult[] = [];
  public config: ScannerQRCodeConfig = {
    // fps: 100,
    // isAuto: true,
    isBeep: false,
    // decode: 'macintosh',
    // deviceActive: 1,
    constraints: { 
      audio: false,
      video: {
        width: window.innerWidth
      }
    } 
  };
  
  constructor(private qrcode: NgxScannerQrcodeService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.item) {
      this.qrControl.setValidators([])
      if(this.item.templateOptions.required) {
        this.qrControl.addValidators(Validators.required)

        if(this.item.templateOptions.qrValidation) {
          this.qrControl.addValidators(this.qrMatchValidatorFn(this.item.templateOptions.qrfield))
        }
      }

      // Form control will be checked on next change detection
      setTimeout(() => {
        this.qrControl.updateValueAndValidity()
      });
    }
  }

  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: any) {
    this.qrControl.patchValue(value)
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.qrControl.valid ? null : this.qrControl.errors
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.qrControl.valueChanges.subscribe(value => {
      this.showQrMatchError = this.item.templateOptions.qrfield && this.item.templateOptions.qrfield !== value
       this.onChange(value)
    }))
  }

  onSelects(files: any,control) {
    this.qrcode.loadFilesToScan(files,this.config).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      this.qrCodeResult = res;
      control.patchValue(res[0].url)
    });
  }

  deleteQR() {
    this.qrControl.patchValue(null)
    this.qrCodeResult = []
  }

  onEvent(event, control,type,act?) {
    if(event.length && !control.value) {
      control.patchValue(event[0].value)
      this.handle(this.action, 'stop')
      this.isScan = false
      this.scanStarted = false
    }else if(event.length && control.value) {
      control.patchValue(event[0].value)
      this.scanStarted = false
    }
  }

  qrDetect() {
    this.isScan = true
    if(this.action) {
      this.action.start()
    }
  }

  public handle(action: NgxScannerQrcodeComponent, fn: string): void {
    this.isScan = true
    this.scanStarted = true
    action[fn]().subscribe(console.log, alert);
    this.action = action
  }

  private qrMatchValidatorFn(qrField: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (qrField) {
        return control.value === qrField ? null : { invalidQr: true }
      }
      return null
    }
  }
}
