import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { combineLatest, debounceTime, distinctUntilChanged, map, Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DetailPageService } from 'src/app/services/detail-page.service';
import { NavigationService } from 'src/app/services/support/navigation.service';
import { Router } from '@angular/router';
import { AppService } from '@services/support/app.service';
import { SessionService } from '@services/remote/session.service';
import { UtilityService } from '@services/remote/utility.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/remote/modal.service';
import { environment } from 'src/environments/environment';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild("detailContainer", { read: ViewContainerRef, static: true }) detailContainer!: ViewContainerRef
  @ViewChild("sessionExportTemplate", { static: true }) private sessionExportTemplate: TemplateRef<any>;

  isAuthenticated = false
  authSub: Subscription

  sidebarSub: Subscription
  sidebarClosed: boolean = true;
  isAdmin: boolean = true

  userWfIsOpen = false
  reportIsOpen = false
  isEditorMode = false

  webhooksAvailable: boolean = false
  hasVideoFlow: boolean = false
  addOns
  browser
  isMobile : boolean = false

  openProfileRequest = new Subject<void>();

  exportNameChangedSource: Subject<string> = new Subject<string>();
  exportNameAuthor: string;
  isExportNameLocked: boolean = false;
  sessionExportSub: Subscription = null

  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ""};
  invalidEmailMessageSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private detailPageService: DetailPageService,
    private navigationService: NavigationService,
    private router: Router,
    private appService: AppService,
    private sessionService: SessionService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.authService.getAuth().subscribe(auth => {
      if (!auth) {
        this.router.navigate(['/login']);
      }
    });

    this.authService.getAccountData().then(account => {
      this.webhooksAvailable = account.data.accountData.add_ons?.workflowwebhooks
      this.hasVideoFlow = account.data.accountData.add_ons?.videoflow
      this.addOns = {
        webhooksAvailable : this.webhooksAvailable,
        hasVideoFlow : this.hasVideoFlow
      }
    })
    
    this.authSub = this.authService.user.subscribe(user => {
      this.isAuthenticated = !!user;
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin// || user.editorAccess
    })
    this.detailPageService.setDetailContainer(this.detailContainer);
    this.navigationService.sidebarState.subscribe(state => {
      this.sidebarClosed = state;
    });

    this.appService.userWorkflowisOpen.subscribe(res => {
        this.userWfIsOpen = res
    })
    this.appService.reportisOpen.subscribe(res => {
      this.reportIsOpen = res
    })
    this.appService.isEditorMode.subscribe(res => {
      this.isEditorMode = res
      sessionStorage.setItem('isEditorMode', res.toString())
    })

    this.sessionExportSub = this.sessionService.sessionExportRequest.subscribe(([roomId, sessionId, session_ended]) => { 
      this.onSessionExportModal(roomId, sessionId, session_ended);
    })

    this.invalidEmailMessageSub = this.translateService.get('MODALS.EMAIL_RECIPIENTS.INVALID_EMAIL').subscribe(tr => {
      this.invalidEmailError.pattern = tr;
    })
  }

  ngOnDestroy(): void {
    if (this.authSub) { this.authSub.unsubscribe() }
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
    if (this.sessionExportSub) { this.sessionExportSub.unsubscribe() }
    if (this.invalidEmailMessageSub) { this.invalidEmailMessageSub.unsubscribe(); }
  }

  getExportTimePart(timestamp: number, timezone: string) {
    const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[timezone]);
    return "_" + d.getFullYear() + "-" +
      ("0"+(d.getMonth()+1)).slice(-2) + "-" +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + "-" +
      ("0" + d.getMinutes()).slice(-2) + "-" +
      ("0" + d.getSeconds()).slice(-2) + "_" +
      timezone;
  }

  onExportNameChange(text: string): void {
    this.exportNameChangedSource.next(text);
  }

  onSessionExportModal(roomId: string, sessionId: string, sessionEnded: boolean) {
    let dataModel: { emailRecipients: any, exportName: any }, sub: Subscription;
    dataModel = {
      emailRecipients: [],
      exportName: "SessionExport"
      };
    sub = this.authService.user.pipe(
      map(u => u && u.email ? u.email : null),
      distinctUntilChanged()
    ).subscribe(email => {
      const defaultIndex = dataModel.emailRecipients.findIndex(e => e.default);
      if (email) {
        if (defaultIndex > -1) {
          dataModel.emailRecipients.splice(defaultIndex, 1, { value: email, display: email, default: true });
        } else {
          dataModel.emailRecipients.push({ value: email, display: email, default: true });
        }
      } else {
        if (defaultIndex > -1) {
          dataModel.emailRecipients.splice(defaultIndex, 1);
        }
      }
    });

    let sub2 = combineLatest([this.authService.getAccountData().then(ad => ad.data.accountData.timezone), this.sessionService.getSessionData(roomId, sessionId)])
    .subscribe(([timezone, sessionData]) => {
      if (sessionData.export_name?.name || sessionData.export_name?.name === "") {
        dataModel.exportName = sessionData.export_name?.name;
      } else {
        const dateString = this.getExportTimePart(sessionData.create_time, timezone);
        dataModel.exportName = environment.design.appName.replace(/ /g, "_").concat(dateString);
      }
      this.exportNameAuthor = sessionData.export_name?.author;
      this.isExportNameLocked = sessionData.export_name?.locked;
    });

    let exportNameChangedSub = this.exportNameChangedSource.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(text => {
        if (text) {
          this.sessionService.setExportName(roomId, sessionId, text);
        }

    });

    const modalId = this.modalService.show({
      template: this.sessionExportTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
            if (sub) { sub.unsubscribe() }
            if (sub2) { sub2.unsubscribe() }
            if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
          },
          openProfile: () => {
            this.openProfileRequest.next();
            this.modalService.hide(modalId);
            if (sub) { sub.unsubscribe() }
            if (sub2) { sub2.unsubscribe() }
            if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
          },
          getexport: () => {
            this.loaderService.show();
            this.sessionService.exportSession(roomId, sessionId, dataModel.emailRecipients.map(email => email.value))
              .then(() => {
                this.modalService.hide(modalId);
                if (sub) { sub.unsubscribe() }
                if (sub2) { sub2.unsubscribe() }
                if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
                if (sessionEnded === false) {
                  this.snackBarService.success(this.translateService.instant('MODALS.GET_EXPORT_MODAL.EXPORT_SUCCEEDED'))
                } else {
                  this.snackBarService.success(this.translateService.instant('MODALS.GET_EXPORT_MODAL.ENDED_SESSION_EXPORT_SUCCEEDED'))
                }
              })
              .catch(error => this.snackBarService.success(this.translateService.instant('MODALS.GET_EXPORT_MODAL.EXPORT_FAILED')))
              .finally(() => this.loaderService.hide());
          }
        }
      }
    })

  }

}
