
<div class="d-flex workflow-detail-container">
    <div class="workflow-detail-wrapper col-7">
        <div class="fs-16 font-weight-600 mb-4">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.TITLE' | translate }}</div>
        <form [formGroup]="workflowDetailForm">
            <div class="form-group">
                <label class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.NAME.TITLE' | translate }} <span class="fs-14 text-dark-grey">*</span></label>
                <!-- <p class="fs-12 mb-1 mt-2">Fill in the Label field</p> -->
                <input type="text" class="form-control mt-2" formControlName="name" [disabled]="type=='published'">
                <p *ngIf="workflowDetailForm.controls['name'].errors?.['required']" class="validation-text">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.NAME.NO-NAME' | translate }}</p>
            </div>
            <div class="form-group mt-4">
                <label class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.DESCRIPTION' | translate }}</label>
                <!-- <p class="fs-12 mb-1 mt-2">Fill in the Label field</p> -->
                <input type="text" class="form-control mt-2" formControlName="description" [disabled]="type=='published'">
            </div>
            <div class="form-group mt-4" style="margin-top: -3px;">
                <label class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.TITLE' | translate }}</label>
                <ng-select [items]="allLabels" [loading]="loading" formControlName="labels" [addTag]="addTagPromise.bind(this)" [multiple]="true" class="mt-2" [disabled]="type=='published'">

                    <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.CREATE' | translate }}</b>: {{search}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="estimated-duration-wrapper mt-4">
                <label class="fs-14 text-dark-grey mb-2">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.DURATION' | translate }}</label>
                <timepicker formControlName="estimatedDuration" [showMeridian]="false" [disabled]="type=='published'"></timepicker>
            </div>

            <div class="form-group d-flex align-items-center form-part">
                <input type="checkbox" class="form-check-input mt-0 me-2" id="showContentPage" name="showContentPage" formControlName="showContentPage" [dynamicTooltip]="(isConnectionEnabled) ? 'You cannot activate this because Connection is active' : null">
                <label class="form-check-label fs-14 font-weight-600 text-dark-grey" for="showContentPage" [dynamicTooltip]="(isConnectionEnabled) ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.CONNECTION-WARNING' | translate ) : null">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.SHOW-CONTENT-PAGE' | translate }}</label>
            </div>

            <hr *ngIf="isRemoteCallAvailable">

            <div class="form-group form-part top-gap-32" *ngIf="isRemoteCallAvailable">
                <label class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.REMOTE-CALL-EXPERTS' | translate }}</label>
                <ng-select [items]="expertList" [loading]="expertLoading" bindLabel="name" formControlName="expertsToCall" [addTag]="addExpert.bind(this)" [multiple]="true" class="mt-2" [disabled]="type=='published'">
    
                    <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.CREATE' | translate }}</b>: {{search}}
                    </ng-template>
                </ng-select>
            </div>

            <!-- <div class="d-flex mt-4" style="padding-left: 60px">
                <div class="">
                    <label class="image-label fs-14 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}
                    </label>
                    <img src="assets/img/info_icon.svg" class="ms-2" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE-INFO' | translate )"  placement="right">

                    <div class="d-flex flex-wrap mt-3">
                        <div *ngFor="let file of workflowDetailForm.controls['files'].value; let index = index" style="padding: 7px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-radius: 10px;" class="me-1 mb-2 ng-star-inserted card fs-12 font-weight-500">
                        <label *ngIf="!('electron' | env)" style="color: #4f5053" ><a [href]="file.url" class="file-attach">{{file.name}}</a></label>
                        <label *ngIf="('electron' | env)" style="color: #4f5053" ><a (click)="chooseFolderAndSaveFile(file)" class="file-attach">{{file.name}}</a></label>
                        <img src="assets/img/close.svg" alt="" height="8px" class="cursor-pointer" style="margin-left: 16px;" (click)="deleteFile(index)" [ngStyle]="{'pointer-events' : type=='published' ? 'none' : ''}">
                        </div>

                    </div>
                    <input type="file" class="d-none" accept=".pdf" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput multiple>
                    <div class="upload-drop-zone" filedrop fileType="pdf" (filesDropped)="dragDropUpload($event)" accept="*" id="drop-zone">
                        <div class="upload-icon mt-3"><img src="assets/img/upload-icon.svg"></div>
                        <div class="fs-14 font-weight-500 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
                        <div class="fs-12 text-muted">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
                        <div class="upload-button mb-4 mt-1">
                            <button *ngIf="!('electron' | env)" class="vsight-button select-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2 text-white" (click)="imageInput.click()" [disabled]="type=='published'">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}</button>
                            <button *ngIf="('electron' | env)" class="vsight-button select-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2 text-white" (click)="chooseAndCopyWorkflowFile()" [disabled]="type=='published'">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </form>

        <hr *ngIf="fields.length>0">

        <div *ngIf="fields.length>0" [ngStyle]="{'pointer-events' : type=='published' ? 'none' : ''}" class="top-gap-32 mb-5">
            <form [formGroup]="form" style="width: 100%;" *ngIf="fields">
                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
            </form>
        </div>
    </div>
    <div class="col-5 file-wrapper">
        <div class="detail-file-container">
            <div class="w-100">
                <label class="image-label fs-16 font-weight-600 mb-4">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}
                </label>
                <img src="assets/img/info_icon.svg" class="ms-2 image-info" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE-INFO' | translate )"  placement="right">

                <app-file-upload [id]="workflowId" [accept]="'.pdf'" [isDisabled]="isDisabled" (uploadedFiles)="uploadedFilesChange($event)"></app-file-upload>


                <!-- <input type="file" class="d-none" accept=".pdf" onclick="this.value = null" (change)="chooseButtonUpload($event)" #imageInput multiple>
                <div class="upload-drop-zone" filedrop fileType="pdf" (filesDropped)="dragDropUpload($event)" accept="*" id="drop-zone">
                    <div class="upload-icon mt-3"><img src="assets/img/upload-icon.svg"></div>
                    <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
                    <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
                    <div class="upload-button mb-4 mt-1">
                        <button *ngIf="!('electron' | env)" class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="imageInput.click()" [disabled]="type=='published'">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}</button>
                        <button *ngIf="('electron' | env)" class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseAndCopyWorkflowFile()" [disabled]="type=='published'">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.UPLOAD-FILE' | translate }}</button>
                    </div>
                </div> -->

                <div class="d-flex flex-wrap files-area mt-3">
                    <div class="file-badge d-flex align-items-center mt-2 fs-14 me-2 mb-1" *ngFor="let file of workflowDetailForm.controls['files'].value; let index = index">
                        <a *ngIf="!('electron' | env)" [href]="file.url" class="file-attach"><img src="assets/img/download-step-file.svg" alt="" class="download-file"></a>
                        <a *ngIf="('electron' | env)" (click)="chooseFolderAndSaveFile(file)" class="file-attach"><img src="assets/img/download-step-file.svg" alt="" class="download-file"></a>
                        <label class="fs-14 font-weight-400">{{file.name}}</label>
                        <img src="assets/img/close.svg" alt="" class="close-file" (click)="deleteFile(index)" [ngStyle]="{'pointer-events': isDisabled ? 'none' : ''}">
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>