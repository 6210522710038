<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{folder ? ('MAIN.CONTENT.DASHBOARD.FOLDER.EDIT-FOLDER' | translate) : ('MAIN.CONTENT.DASHBOARD.FOLDER.CREATE-FOLDER' | translate) | titlecase}}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">
            
            <div class="folder-wrapper w-100">
                <label class="fs-14 font-weight-500 text-light-grey mb-2 w-100">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.FOLDER-NAME' | translate }}<span class="ms-1 fs-14 text-light-grey">*</span></label>
                <div>
                    <input type="text" class="form-control fs-14" placeholder="" [formControl]="folderName">
                    <p class="validation-text pb-0 mb-0" *ngIf="folderName.errors?.['required'] && folderName.touched && !folderName.pristine">{{ 'MODALS.SIGNATURE.REQUIRED-NAME' | translate }}</p>
                    <p class="validation-text pb-0 mb-0" *ngIf="folderName.errors?.['uniqueError'] && (!folder || (folder && folder !== folderName.value))">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.FOLDER-NAME-UNIQUE-ERROR' | translate }}</p>
                </div>
                <div class="d-flex top-gap-30 justify-content-center">
                    <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="close()">
                 {{ 'MODALS.ALERT.CANCEL' | translate | titlecase }}
                </button>
                <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="save()" [disabled]="folderName.errors?.['required'] || folderName.errors?.['uniqueError']">
                    {{folder ? ('WEB-VERSION.SAVE' | translate ) : ('MAIN.CONTENT.DASHBOARD.FOLDER.CREATE' | translate) | titlecase}}
                </button>
                </div>
            </div>

        </div>
    </div>
</div>