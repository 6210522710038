import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-capture-image',
  template: `
  <div class="d-flex flex-column align-items-center">
    <div class="btn btn-primary qr-button">
        <span class="qr-text">{{'MAIN.CONTENT.WORKFLOW.PREVIEW.CAPTURE-IMAGE' | translate}}</span>
    </div>
    <div class="btn btn-primary qr-button mt-4">
        <span class="qr-text">{{'MAIN.CONTENT.WORKFLOW.PREVIEW.PICK_IMAGE' | translate}}</span>
    </div>
  </div>
  `,
})
export class FormlyFieldCustomCaptureImage extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
}