<div class="modal-content">
  <div class="modal-header d-flex align-items-center">
    <div class="modal-title font-weight-600">{{ 'MODALS.SIGNATURE.ADD-SIGNATURE' | translate }}</div>
    <div class="cursor-pointer"  (click)="close()">
        <img src="assets/img/close_white.svg" alt="">
    </div>
  </div>

  <div>
    <div class="display-container">
      <div>
        <div class="sidebar">
          <div class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[0]}" (click)="changeTab(0)">
            <img src="assets/img/draw_icon.svg" class="sidebar-icon" alt="">
          </div>
          <div class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[1]}" (click)="changeTab(1)">
            <img src="assets/img/keyboard_icon.svg" class="sidebar-icon" alt="">
          </div>
          <div class="tab fs-15 font-weight-600" [ngClass]="{'active': tabs[2]}" (click)="changeTab(2)">
            <img src="assets/img/upload-signature.svg" class="sidebar-icon" alt="">
          </div>
        </div>
      </div>

      <div class="w-100">

        <div *ngIf="tabs[0]">
          <div style="padding:30px;height: 380px;">
            <p class="fs-14 font-weight-600">{{ 'MODALS.SIGNATURE.DRAW-SIGNATURE' | translate }}</p>
      
            <div class="display">
              <ngx-signature-pad #signature [options]="options"></ngx-signature-pad>
            </div>
            <div class="d-flex align-items-center justify-content-center  signature-footer cursor-pointer">
              <a (click)="clear()" class="fs-12 text-dark-grey me-3 font-weight-600 cursor-pointer">{{ 'MODALS.SIGNATURE.CLEAR' | translate }}</a>
            </div>
    
            <div style="margin-top: 40px;">
              <input type="text" class="form-control fs-14" placeholder="Full name" [formControl]="signerName">
              <p class="validation-text pb-0 mb-0" *ngIf="signerName.errors?.['required'] && signerName.touched">{{ 'MODALS.SIGNATURE.REQUIRED-NAME' | translate }}</p>
            </div>
          </div>
        </div>
  
        <div *ngIf="tabs[1]" style="width: 100%;padding-inline:30px;">
          <div style="padding:30px;height: 380px;" class="d-flex flex-column justify-content-between">
            <div>
              <p class="fs-14 font-weight-600">{{ 'MODALS.SIGNATURE.TYPE-SIGNATURE' | translate }}</p>
              <div style="margin-top: 10px;" class="signature-text-container">
                <input type="text" class="form-control custom-signature-text" placeholder="Enter text" [formControl]="signatureText">
              </div>
              <div class="d-flex align-items-center justify-content-center mt-3 cursor-pointer">
                <a (click)="clearSignatureText()" class="fs-12 text-dark-grey me-3 font-weight-600 cursor-pointer">{{ 'MODALS.SIGNATURE.CLEAR' | translate }}</a>
              </div>
            </div>
            
            <canvas id="canvas" #canvas style="display: none;"></canvas>
  
            <div style="margin-top: 40px;">
              <input type="text" class="form-control fs-14" placeholder="Full name" [formControl]="signerName">
              <p class="validation-text pb-0 mb-0" *ngIf="signerName.errors?.['required'] && signerName.touched">{{ 'MODALS.SIGNATURE.REQUIRED-NAME' | translate }}</p>
            </div>
          </div>
        </div>
  
        <div *ngIf="tabs[2]" style="width: 100%;padding-inline:30px;">
          <div style="padding:30px;height: 380px;width: 100%;" class="d-flex flex-column justify-content-between">
            <div>
              <p class="fs-14 font-weight-600">{{ 'MODALS.SIGNATURE.USE-PHOTO' | translate }}</p>
              <button class="v-btn btn btn-primary fs-12 font-weight-600 text-white upload-btn" (click)="imageInput.click()" *ngIf="!signatureImage">Upload</button>
            </div>
  
            <div class="d-flex flex-column justify-content-center" >
                <input type="file" class="d-none" accept="image/png,image/jpeg" onclick="this.value = null" (change)="uploadSignature($event)" #imageInput>
                <div *ngIf="signatureImage" class="d-flex position-absolute-aligned p-1" style="max-height: 180px;">
                      <div class="position-relative form-image-wrapper me-1" style="width: 300px;">
                          <img [src]="signatureImage" class="form-image">
                      </div>
                </div>
                <div class="d-flex align-items-center justify-content-center cursor-pointer" *ngIf="signatureImage">
                  <a (click)="deleteSignature()" class="fs-12 text-dark-grey me-3 font-weight-600 cursor-pointer">{{ 'MODALS.SIGNATURE.CLEAR' | translate }}</a>
                </div>
            </div>
  
            <div style="margin-top: 40px;">
              <input type="text" class="form-control fs-14" placeholder="Full name" [formControl]="signerName">
              <p class="validation-text pb-0 mb-0" *ngIf="signerName.errors?.['required'] && signerName.touched">{{ 'MODALS.SIGNATURE.REQUIRED-NAME' | translate }}</p>
            </div>
          </div>
  
        </div>

      </div>
      
    </div>
  </div>

  <div class="modal-footer">
    <div class="sign-buttons d-flex justify-content-end">
      <!-- <div>
          <button class="btn btn-outline-primary me-1 fs-14 font-weight-500" (click)="revert()">Revert</button>
      </div> -->
      <div>
          <button class="v-btn btn btn-primary vsight-button create-button" 
          (click)="sign()"
          [disabled]="signerName.errors?.['required'] || (tabs[2] && !signatureImage) || (tabs[0] && isEmpty) || (tabs[1] && !signatureText.value)">
          {{ 'MODALS.SIGNATURE.INSERT' | translate }}
        </button>
      </div>
  </div>
  </div>

    <!-- <div class="direction">
      <table>
        <thead>
          <tr>
            <th>action</th>
            <th>describe</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><button type="button" (click)="clear()">clear</button></td>
            <td>clear signature-pad</td>
          </tr>
          <tr>
            <td>isEmpty</td>
            <td>Get state of signature-pad, return true if user modify signature-pad, otherwise return false. <span
                class="strong">Current State is : {{ isEmpty }}</span></td>
          </tr>
          <tr>
            <td><button type="button" (click)="setDirty()">setDirty</button></td>
            <td>Set 'isEmpty' as false</td>
          </tr>
          <tr>
            <td><button type="button" (click)="setEmpty()">setEmpty</button></td>
            <td>Set 'isEmpty' as true</td>
          </tr>
          <tr>
            <td><button type="button" (click)="drawImage()">drawImage</button></td>
            <td>You can paste imaged/canvas/video to the signature-pad</td>
          </tr>
          <tr>
            <td><button type="button" (click)="save('image/jpeg')">saveAsJpeg</button></td>
            <td>You can get base64 of signature-pad with the format of jpeg</td>
          </tr>
          <tr>
            <td><button type="button" (click)="save()">saveAsPng</button></td>
            <td>You can get base64 of signature-pad with the format of png</td>
          </tr>
          <tr>
            <td><button type="button" (click)="save('image/svg+xml')">saveAsSvg</button></td>
            <td>You can get base64 of signature-pad with the format of svg</td>
          </tr>
          <tr>
            <td><button type="button" (click)="revert()">revert</button></td>
            <td>Undo the last action</td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>