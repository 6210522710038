<div class="modal-dialog-alert" role="document">
    <div class="modal-content v-card card modal-card">
        <div class="modal-header d-flex align-items-center">
            <div class="modal-title font-weight-600">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.CONTENT-LANGUAGE' | translate | titlecase }}</div>
            <div class="cursor-pointer"  (click)="close()">
                <img src="assets/img/close_white.svg" alt="">

            </div>
        </div>
        <div class="modal-body d-flex flex-column align-items-center" style="position: relative;">

            <div class="d-flex flex-column" style="width:100% !important">
                <label class="fs-14 font-weight-500 mb-3">{{ 'MODALS.USER-DEFAULT-LANGUAGE.PREFFERED-LANGUAGE' | translate }}</label>
                <div class="d-flex justify-content-center">
                    <div class="btn-group dropdown-wrapper-list w-100" dropdown>
                        <button type="button" class="btn btn-primary action-dropdown p-0" style="width: 85%;height: 36px;" data-bs-auto-close="inside">{{defaultLanguageName ? defaultLanguageName : 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SELECT-LANGUAGE' | translate}}</button>
                        <button id="button-split" type="button" dropdownToggle class="btn btn-danger dropdown-toggle dropdown-toggle-split"
                                aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false" style="height: 36px;">
                        </button>
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
                            <div class="search-box">
                                <input type="text" class="form-control filter fs-14" [formControl]="filterLang" (click)="stop($event)" placeholder="Search">
                                <div class="search-icon"> <img src="assets/img/search-copy.svg" alt=""></div>
                            </div>
                            <div *ngFor="let lang of filteredLangs" (click)="selectDefaultLanguage(lang)" class="lang fs-14  ps-4 pt-2 pb-2 cursor-pointer">
                                {{lang.name}}
                            </div>
                            
                        </ul>
                      </div>
                </div>
            </div>
            <div class="d-flex top-gap-30 justify-content-center">
                <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-sm btn-secondary me-2" (click)="close()">
                    {{ 'MAIN.CONTENT.DASHBOARD.CANCEL' | translate | titlecase }}
                </button>
                <button type="button" class="v-btn btn vsight-button btn-sm btn-primary ms-2" (click)="saveDefaultLanguage()" [disabled]="!name.valid || !defaultLanguage">
                    {{ 'MAIN.CONTENT.USER-LIST.DEACTIVATE.SAVE' | translate | titlecase }}
                </button>
            </div>

        </div>
    </div>
</div>