<div class="card-body pe-4 ps-3 filter-area">
    <div class="d-flex justify-content-between" style="padding-top: 2px;">
        <div class="d-flex align-items-center">
            <span class="me-2 fs-14 font-weight-600 ms-1">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.FILTER-BY' | translate }}</span>
            <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 240px; border: none;">
                <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
                    {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-LABEL' | translate }}
                </button>
                <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                        aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                </button>
                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                overflow-y: scroll;" [formGroup]="labelGroup" aria-labelledby="dropdownMenuClickableInside">
                    <div class="search-box p-2">
                        <input  type="text" class="form-control fs-14" [formControl]="labelSearchControl" style="height: 38px;"  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                        <div class="search-icon me-2"> <img src="assets/img/search-copy.svg" alt=""></div>
                    </div>
                    <div *ngFor="let label of filteredLabelList; let index = index" class="ps-2">
                        <div class="custom-control custom-checkbox custom-checkbox-green">
                            <input
                                type="checkbox"
                                class="form-check-input ms-0"
                                [id]="index"
                                (click) = selectLabels(label)
                                [formControlName]="label"
                            />
                            <label class="custom-control-label label-form fs-14" [for]="index">
                                {{ label }}
                            </label>
                        </div>
                    </div>
                </ul>
            </div>
        </div>

      <div class="search-box">
        <input type="text" [formControl]="filter" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14">
        <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
      </div>
    </div>

    <div class="mt-3 d-flex flex-wrap" *ngIf="filteredLabels?.length">
        <span class="fs-14 me-2 font-weight-600 ms-1">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECTED-LABELS' | translate }} : </span>
        <span class="fs-12 font-weight-500 label-span me-2 mb-2" *ngFor="let label of filteredLabels">{{label}} <img (click)="deleteLabel(label)" class="label-close cursor-pointer" src="assets/img/close.svg" alt=""></span>
        <span class="fs-14 font-weight-600 cursor-pointer text-blue" (click)="clearAll()">Clear all</span>
    </div>
  </div>