import { DBSource } from "./DBSource";

import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import { User } from "./User";

import { Observable, Subject, BehaviorSubject, of, from } from "rxjs";
import { share, map, switchMap, filter, first, tap, timeout } from "rxjs/operators";
import { UploadModel } from "./UploadModel";
import { UploadFile } from "./UploadFile";
import { environment } from "src/environments/environment";
import { AuthUser } from "./AuthUser";

export class FirebaseSource implements DBSource {

    readonly type: "firebase" | "socket" = "firebase";

    isConnected: Observable<boolean>;
    private isConnectedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    isAuthenticated: Observable<boolean>;
    authState: Observable<AuthUser>;
    currentUser: Observable<User>;
    private isAuthenticatedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    private app: firebase.app.App;
    private auth: firebase.auth.Auth;
    private database: firebase.database.Database;
    private storage: firebase.storage.Storage;

    manageUserStatus: boolean = true;
    private userStatusRef: firebase.database.Reference = null;
    private onDisconnect: firebase.database.OnDisconnect = null;

    private online: any = {
        state: 'online',
        last_update: firebase.database.ServerValue.TIMESTAMP
    }
    private offline: any = {
        state: 'offline',
        last_update: firebase.database.ServerValue.TIMESTAMP
    }

    private http: HttpClient;
    private isFirefox: boolean;

    constructor(http: HttpClient, isFirefox: boolean) {
        this.http = http;
        this.isFirefox = isFirefox;

        this.app = firebase.initializeApp(environment.firebase, environment.firebase.projectId);
        this.auth = this.app.auth();
        this.database = this.app.database(environment.firebase.databaseURL);
        this.storage = this.app.storage();

        this.isConnected = this.isConnectedSource.asObservable().pipe(filter(c => c !== null));
        this.database.ref(".info/connected").on("value", snap => {
            // Handle initial false
            if (this.isConnectedSource.getValue() === null) {
              if (snap.val()) { this.isConnectedSource.next(true) }
            } else {
              this.isConnectedSource.next(snap.val());
            }
        });

        this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

        this.auth.onAuthStateChanged(user => this.isAuthenticatedSource.next(user ? true : false));
        this.isAuthenticated = this.isAuthenticatedSource.asObservable().pipe(filter(a => a !== null));

        const authSource = new BehaviorSubject<firebase.User>(null);
        this.auth.onIdTokenChanged(user => authSource.next(user));

        this.authState = authSource.pipe(
            switchMap(user => user ? from(user.getIdTokenResult()) : of(null)),
            map(idTokenResult => {
                if (idTokenResult) {
                    const user: AuthUser = {
                        uid: idTokenResult.claims['uid'],
                        account_id: idTokenResult.claims['account_id'],
                        role: idTokenResult.claims['role'],
                        token: idTokenResult.token
                    };
                    if (this.manageUserStatus) {
                        this.database.ref(`accounts/${user.account_id}/account_data/add_ons/webstatus`).once("value")
                        .then(snap => {
                            if (snap.val()) {
                                this.online.web_status = 'available';
                                this.online.web_status_lock = false;
                                this.offline.web_status = null;
                            }
                            if (this.onDisconnect) { this.onDisconnect.cancel(); }
                            this.userStatusRef = this.database.ref(`accounts/${user.account_id}/users/${user.uid}/status`)
                            this.onDisconnect = this.userStatusRef.onDisconnect();
                            this.userStatusRef.update(this.online);
                            this.onDisconnect.update(this.offline);
                        });
                    }
                    return user;
                } else {
                    if (this.manageUserStatus && this.onDisconnect && this.userStatusRef) {
                        this.onDisconnect.cancel();
                        this.onDisconnect = null;
                        this.userStatusRef = null;
                    }
                    return null;
                }
            })
        )
        this.currentUser = this.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.listen<User>(`accounts/${user.account_id}/users/${user.uid}`)
                    .pipe(map(u => {
                        u.id = user.uid;
                        u.token = user.token;
                        return u;
                    }));
                } else {
                    return of(null);
                }
            })
        )
    }

    authecticated(): boolean {
        return this.isAuthenticatedSource.value ? true : false;
    }

    connected(): boolean {
        return this.isConnectedSource.value ? true : false;
    }

    getEndPoint(name: string): string {
        return environment.endPoints[name];
    }

    login(token: string, manageUserStatus: boolean, reconnect: boolean): Promise<User> {
        this.manageUserStatus = manageUserStatus;
        return this.auth.signInWithCustomToken(token)
        .then(userCredential => {
            sessionStorage.setItem("sourceType", "firebase");
            return this.currentUser.pipe(filter(u => !!u), first()).toPromise()
        });
    }

    logout(): Promise<void> {
        if (this.manageUserStatus && this.userStatusRef) {
            return this.userStatusRef.update(this.offline)
            .catch(error => null) // Ignore status update error
            .then(() => this.auth.signOut());
        }
        return this.auth.signOut();
    }

    destroy(): Promise<void> {
        if (this.authecticated()) {
            return this.logout().then(() => this.app.delete());
        }
        return this.app.delete();
    }

    listen<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
        return new Observable<T>(subscriber => {
            const ref = this.database.ref(path);
            const callback = (snap: firebase.database.DataSnapshot, key: string) => subscriber.next(snap.val());
            ref.on(event, callback, err => subscriber.error(err));
            return () => { ref.off(event, callback) }
        }).pipe(share());
    }

    listenSnap<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{ data: T; key: string; }> {
        return new Observable<{data: T, key: string}>(subscriber => {
            const ref = this.database.ref(path);
            const callback = (snap: firebase.database.DataSnapshot, key: string) => subscriber.next({ data: snap.val(), key: snap.key});
            ref.on(event, callback, err => subscriber.error(err));
            return () => { ref.off(event, callback) }
        }).pipe(share());
    }

    query<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[],
            event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
        return new Observable<T>(subscriber => {
            const ref = queries.reduce((r, q) => q.value ? r[q.key](q.value) : r[q.key](), <any>this.database.ref(path));
            const callback = (snap: firebase.database.DataSnapshot, key: string) => subscriber.next(snap.val());
            ref.on(event, callback, err => subscriber.error(err));
            return () => { ref.off(event, callback) }
        }).pipe(share());
    }

    querySnap<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[],
            event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{ data: T; key: string; }> {
        return new Observable<{data: T, key: string}>(subscriber => {
            const ref = queries.reduce((r, q) => q.value ? r[q.key](q.value) : r[q.key](), <any>this.database.ref(path));
            const callback = (snap: firebase.database.DataSnapshot, key: string) => subscriber.next({ data: snap.val(), key: snap.key});
            ref.on(event, callback, err => subscriber.error(err));
            return () => { ref.off(event, callback) }
        }).pipe(share());
    }

    get<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Promise<T> {
        return this.database.ref(path).once(event).then(snap => snap.val());
    }

    set(path: string, data: any): Promise<void> {
        return this.database.ref(path).set(data);
    }

    push(path: string, data: any): Promise<string> {
        return this.database.ref(path).push(data).then(ref => ref.key);
    }

    update(path: string, data: any): Promise<void> {
        return this.database.ref(path).update(data);
    }

    remove(path: string): Promise<void> {
        return this.database.ref(path).remove();
    }

    transaction(path: string, name: string, data: any): Promise<{ committed: boolean; data: any; }> {
        const fn: (database: firebase.database.Database, path: string, data: any) => Promise<any> = FirebaseTransactions[name];
        if (fn) {
            return fn(this.database, path, data).then(tResult => ({ committed: tResult.committed, data: tResult.snapshot.val() }));
        }
        throw new Error('transaction-not-found');
    }

    createPushId() {
        return this.database.ref('asd').push().key;
    }

    timestamp(): any {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    upload(storagePath: string, uploadModel: UploadModel): [Observable<any>, boolean] {
        const storageRef = this.storage.ref(storagePath);
        const uploadedSource = new Subject<UploadFile>();
        const urlPromises: Promise<any>[] = [];
    
        uploadModel.uploading = true;
    
        let finished = 0;
        uploadModel.uploadFiles.forEach(upload => {
            upload.key = this.createPushId();
            upload.uploadTask = storageRef.child(upload.key + '.' + upload.extension).put(upload.file);
    
            uploadModel.totalBytes += upload.uploadTask.snapshot.totalBytes;
            
            upload.uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot: firebase.storage.UploadTaskSnapshot) => {
                    let trBytes = 0;
                    let ttBytes = 0;
                    uploadModel.uploadFiles.forEach(upfile => {
                        if (!(upfile.uploadTask.snapshot.state === 'error' || upfile.uploadTask.snapshot.state === 'canceled')) {
                            ttBytes += upfile.uploadTask.snapshot.totalBytes;
                            trBytes += upfile.uploadTask.snapshot.bytesTransferred;
                        }
                    });
                    uploadModel.totalBytes = ttBytes;
                    uploadModel.transferredBytes = trBytes;
        
                    upload.progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    finished++;
                    if (finished === uploadModel.uploadFiles.length) {
                        uploadModel.uploading = false;
                        uploadModel.completed = true;
                    }
                },
                () => {
                    urlPromises.push(
                        upload.uploadTask.snapshot.ref.getDownloadURL()
                        .then(url => {
                            upload.url = url;
                            uploadedSource.next(upload);
                        })
                        .catch(error => {
                        })
                    );
                    finished++;
                    if (finished === uploadModel.uploadFiles.length) {
                        uploadModel.uploading = false;
                        uploadModel.completed = true;
            
                        Promise.all(urlPromises)
                        .then(() => {
                            uploadedSource.complete();
                        })
                        .catch(error => {
                        });
                    }
                }
            );
        });

        return [uploadedSource.asObservable(), true];
    }

    uploadFiles(accountId: string, currentRoomId: string, currentSessionId: string, uploadModel: UploadModel, token: string): [Observable<any>, boolean] {
        const sessionPath = `accounts/${accountId}/sessions/${currentRoomId}/${currentSessionId}`;
        return this.upload(sessionPath, uploadModel)
    }

    uploadRoomFiles(accountId: string, currentRoomId: string, uploadModel: UploadModel, token: string) {
        const sessionPath = `accounts/${accountId}/rooms_files/${currentRoomId}`;
        return this.upload(sessionPath, uploadModel);
    }

    uploadTicketFiles(accountId: string, ticketId: string, uploadModel: UploadModel, token: string) {
        const sessionPath = `accounts/${accountId}/ticket_system/tickets_files/${ticketId}`;
        return this.upload(sessionPath, uploadModel);
    }

    uploadSnapshot(currentSessionPath: string, upFile: UploadFile): Promise<UploadFile> {
        const storageRef = this.storage.ref(currentSessionPath);
    
        upFile.key = this.createPushId();
        upFile.uploadTask = storageRef.child(upFile.key + '.' + upFile.extension).put(upFile.file);

        return new Promise<UploadFile>((resolve, reject) => {
            upFile.uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot: firebase.storage.UploadTaskSnapshot) => {
                    upFile.progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                error => reject(error),
                () => {
                    upFile.uploadTask.snapshot.ref.getDownloadURL()
                    .then(url => {
                        upFile.url = url;
                        resolve(upFile);
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            );
        });
    }
}

class FirebaseTransactions {
    static openCollaboration = (database: firebase.database.Database, datapath: string, newCollaboration: any) => {
        return database.ref(datapath).transaction(
            sessionData => {
                if (sessionData) {
                    const devIds = sessionData.devices ? Object.keys(sessionData.devices) : [];
                    if (devIds.every(id => !(sessionData.devices[id].device_status && sessionData.devices[id].device_status.arkit && (sessionData.devices[id].device_status.arkit.status === 'open' || sessionData.devices[id].device_status.arkit.status === 'opened')))) {
                        if (sessionData.collaboration.data) {
                            // Abort, collaboration open
                            return;
                        }
                        if (sessionData.focus) {
                            if (sessionData.focus.type === "video") {
                                // Abort, focus open
                                return;
                            } else if (sessionData.focus.type === "collaboration") {
                                // Remove previous collaboration focus
                                sessionData.focus = null;
                            }
                        }
                        sessionData.collaboration.data = {
                            id: newCollaboration.collaboration_data.id,
                            key: newCollaboration.collaboration_data.key,
                            type: newCollaboration.collaboration_data.type,
                            name: newCollaboration.collaboration_data.name
                        }
                        if (newCollaboration.collaboration_data.type !== 'object') {
                            sessionData.collaboration.data.url = newCollaboration.collaboration_data.url;
                        }
                        sessionData.collaboration.last_update = firebase.database.ServerValue.TIMESTAMP;
                    } else {
                        // Abort, ArPlus open
                        return;
                    }
                }
                return sessionData;
            }, null, false
        );
    }
    static closeCollaboration = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath).transaction(
            sessionData => {
                if (sessionData) {
                    if (sessionData.collaboration.data) {
                        sessionData.collaboration.data = null;
                        sessionData.collaboration.last_update = firebase.database.ServerValue.TIMESTAMP;

                        if (sessionData.focus && sessionData.focus.type === "collaboration") {
                            sessionData.focus = null;
                        }
                    } else {
                        return;
                    }
                }
                return sessionData;
            }, null, false
        );
    }
    static scaleUp = (database: firebase.database.Database, datapath: string) => {
        const scaleList = [1,3,6,12,25,50,75,100,125,150,175,200,250,300,400,500];
        return database.ref(datapath).transaction(selectData => {
            if (selectData && selectData.object && selectData.object.scale && selectData.object.scale !== 500) {
                let i = 0;
                while (i < scaleList.length && selectData.object.scale >= scaleList[i]) {
                    i++;
                }
                selectData.object.scale = scaleList[i];
                selectData.first = null;
            }
            return selectData;
        });
    }
    static scaleDown = (database: firebase.database.Database, datapath: string) => {
        const scaleList = [1,3,6,12,25,50,75,100,125,150,175,200,250,300,400,500];
        return database.ref(datapath).transaction(selectData => {
            if (selectData && selectData.object && selectData.object.scale && selectData.object.scale !== 1) {
                let i = scaleList.length-1;
                while (i >= 0 && selectData.object.scale <= scaleList[i]){
                    i--;
                }
                selectData.object.scale = scaleList[i];
                selectData.first = null;
            }
            return selectData;
        })
    }
    static zoomIn = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath).transaction(zoom => {
            if (zoom) {
                if (!zoom.available) { return }
                zoom.status = zoom.status >= 5 ? 5 : zoom.status + 1;
            }
            return zoom;
        });
    }
    static zoomOut = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath).transaction(zoom => {
            if (zoom) {
                if (!zoom.available) { return }
                zoom.status = zoom.status <= 0 ? 0 : zoom.status - 1;
            }
            return zoom;
        });
    }
    static toggleFlash = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath).transaction(flash => {
            if (flash) {
                if (!flash.available) { return }
                flash.status = flash.status === 'on' ? 'off' : 'on';
            }
            return flash;
        });
    }
    static openArPlus = (database: firebase.database.Database, datapath: string, destination: any) => {
        return database.ref(datapath).transaction(
            sessionData => {
                if (sessionData) {
                    if (sessionData.focus) {
                        if (sessionData.focus.type !== "video" || sessionData.focus.id !== destination) {
                            // Abort, focus open
                            return;
                        }
                    }
                    if (sessionData.collaboration.data) {
                        // Abort, open collaboration exists
                        return;
                    }
                    if (sessionData.devices[destination].device_status.arkit.status === "closed") {
                        sessionData.devices[destination].device_status.arkit.status = "open"
                    } else {
                        // Abort, arkit status not "closed"
                        return;
                    }
                }
                return sessionData;
            }, null, false
        )
    }
    static closeArPlus = (database: firebase.database.Database, datapath: string) => {
        const deviceStatusPath = datapath.split('/arkit')[0];
        return database.ref(deviceStatusPath).transaction(
            deviceStatus => {
                if (deviceStatus?.arkit?.status === "opened") {
                    deviceStatus.arkit.status = "close";
                    if (deviceStatus.arkit_lock?.locked) {
                        deviceStatus.arkit_lock.locked = false;
                    }
                } else {
                    // Abort arkit not open
                    return;
                }
                return deviceStatus;
            }, null, false
        );
    }
    static closeAllArPluses = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath).transaction(
            devices => {
                if (devices) {
                    const devIds = devices ? Object.keys(devices) : [];
                    for (const id of devIds) {
                        if (devices[id].device_status?.arkit?.status === 'opened') {
                            devices[id].device_status.arkit.status = 'close';
                            if (devices[id].device_status.arkit_lock?.locked) {
                                devices[id].device_status.arkit_lock.locked = false;
                            }
                        }
                    }
                }
                return devices;
            }
        );
    }
    static deleteArPlus = (database: firebase.database.Database, datapath: string, objId: string) => {
        return database.ref(datapath)
        .transaction(arrays => {
            if (arrays) {
                if (!(arrays.visible && arrays.visible[objId])) {
                    return;
                }
                if (!arrays.undo_delete) {
                    arrays.undo_delete = {};
                }
                // add this object to undo list
                arrays.undo_delete[objId] = arrays.visible[objId];
                arrays.undo_delete[objId].delete_time = firebase.database.ServerValue.TIMESTAMP;
                // remove from visible list
                arrays.visible[objId] = null;
                // clear deleted list (the list named deleted is actually redo list)
                arrays.deleted = null;
            }
            return arrays;
        });
    }
    static undoArPlus = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath)
        .transaction(arrays => {
            if (arrays) {
                const visibleIds = (arrays.visible ? Object.keys(arrays.visible) : []).sort((a, b) => {
                    return (arrays.visible[a].add_time < arrays.visible[b].add_time) ? 1 : -1;
                });
                const undoDeleteIds = (arrays.undo_delete ? Object.keys(arrays.undo_delete) : []).sort((a, b) => {
                    return (arrays.undo_delete[a].delete_time > arrays.undo_delete[b].delete_time) ? 1 : -1;
                });

                let lastVisibleId = null;
                for (const id of visibleIds) {
                    if (arrays.info[id].done) {
                        if (arrays.info[id].hit) {
                            if (!lastVisibleId) { lastVisibleId = id }
                        } else {
                            arrays.visible[id] = null;
                        }
                    }
                }
                const lastUndoDeleteId = undoDeleteIds.length > 0 ? undoDeleteIds[undoDeleteIds.length-1] : null;
                let source;
                if (lastVisibleId) {
                    if (lastUndoDeleteId) {
                        // choose last one
                        if (arrays.visible[lastVisibleId].add_time > arrays.undo_delete[lastUndoDeleteId].delete_time) {
                            source = 'visible';
                        } else {
                            source = 'undo';
                        }
                    } else {
                        source = 'visible';
                    }
                } else if (lastUndoDeleteId) {
                    source = 'undo';
                } else {
                    source = null;
                }

                if (source === 'visible') {
                    // visible => deleted
                    if (!arrays.deleted) {
                        arrays.deleted = {};
                    }
                    arrays.deleted[lastVisibleId] = arrays.visible[lastVisibleId];
                    arrays.deleted[lastVisibleId].add_time = firebase.database.ServerValue.TIMESTAMP;
                    arrays.visible[lastVisibleId] = null;
                } else if (source === 'undo') {
                    // undo => redo
                    if (!arrays.redo_delete) {
                        arrays.redo_delete = {};
                    }
                    arrays.redo_delete[lastUndoDeleteId] = arrays.undo_delete[lastUndoDeleteId];
                    arrays.redo_delete[lastUndoDeleteId].delete_time = firebase.database.ServerValue.TIMESTAMP;

                    // undo => visible
                    if (!arrays.visible) {
                        arrays.visible = {};
                    }
                    // Shallow copy with Object.assign, do not change nested object properties in feature
                    arrays.visible[lastUndoDeleteId] = Object.assign({}, arrays.undo_delete[lastUndoDeleteId]);
                    arrays.visible[lastUndoDeleteId].delete_time = null;
                    arrays.undo_delete[lastUndoDeleteId] = null;
                } else {
                    // Abort, no object exists that hit planes or there is no undo object
                    return;
                }
            }
            return arrays;
        });
    }
    static redoArPlus = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath)
        .transaction(arrays => {
            if (arrays) {
                const lastVisibleId = (arrays.visible ? Object.keys(arrays.visible) : [])
                .reduce((l, a) => l && arrays.visible[l].add_time > arrays.visible[a].add_time ? l : a, null);

                const shouldDelete = (arrays.redo_delete ? Object.keys(arrays.redo_delete) : [])
                .filter(a => !(lastVisibleId && arrays.visible[lastVisibleId].add_time < arrays.redo_delete[a].delete_time))

                let redoDeleteDeleted = false;
                for (const a of shouldDelete) {
                    redoDeleteDeleted = true;
                    delete arrays.redo_delete[a];
                }

                const lastDeletedId = (arrays.deleted ? Object.keys(arrays.deleted) : [])
                .reduce((l, a) => l && arrays.deleted[l].add_time > arrays.deleted[a].add_time ? l : a, null);
                const lastRedoDeleteId = (arrays.redo_delete ? Object.keys(arrays.redo_delete) : [])
                .reduce((l, a) => l && arrays.redo_delete[l].delete_time > arrays.redo_delete[a].delete_time ? l : a, null);

                let source;
                if (lastDeletedId) {
                    if (lastRedoDeleteId) {
                        // choose last one
                        if (arrays.deleted[lastDeletedId].add_time > arrays.redo_delete[lastRedoDeleteId].delete_time) {
                            source = 'deleted';
                        } else {
                            source = 'redo';
                        }
                    } else {
                        source = 'deleted';
                    }
                } else if (lastRedoDeleteId) {
                    source = 'redo';
                } else {
                    source = null;
                }

                if (source === 'deleted') {
                    // deleted => visible
                    if (!arrays.visible) {
                        arrays.visible = {};
                    }
                    arrays.visible[lastDeletedId] = arrays.deleted[lastDeletedId];
                    arrays.visible[lastDeletedId].add_time = firebase.database.ServerValue.TIMESTAMP;
                    arrays.deleted[lastDeletedId] = null;
                } else if (source === 'redo') {
                    // redo,visible => undo
                    if (!arrays.undo_delete) {
                        arrays.undo_delete = {};
                    }
                    // add this object to undo list
                    arrays.undo_delete[lastRedoDeleteId] = arrays.redo_delete[lastRedoDeleteId];
                    arrays.undo_delete[lastRedoDeleteId].delete_time = firebase.database.ServerValue.TIMESTAMP;
                    // remove from redo and visible list
                    arrays.visible[lastRedoDeleteId] = null;
                    arrays.redo_delete[lastRedoDeleteId] = null;
                    // clear deleted list (the list named deleted is actually redo list)
                    //arrays.deleted = null;
                } else if (!redoDeleteDeleted) {
                    // Abort, no object exists that hit planes or there is no undo object
                    return;
                }
            }
            return arrays;
        });
    }
    static raiseHand = (database: firebase.database.Database, datapath: string, user_id: string) => {
        return database.ref(datapath).transaction(roomData => {
            if (roomData) {
                if (!roomData.session.active) {
                    return; // Hand raising not accepted when session is inactive, abort transaction
                }
                if (!roomData.users[user_id].in_room) {
                    return; // User not in room, abort transaction
                }
                if (roomData.users[user_id].status !== "waiting") {
                    return; // User not waiting, abort transaction
                }
                roomData.users[user_id].status = "hand-raised";
            }
            return roomData;
        }, null, false);
    }
    static lowerHand = (database: firebase.database.Database, datapath: string, user_id: string) => {
        return database.ref(datapath).transaction(roomData => {
            if (roomData) {
                if (!roomData.session.active) {
                    return; // Hand raising not accepted when session is inactive, abort transaction
                }
                if (!roomData.users[user_id].in_room) {
                    return; // User not in room, abort transaction
                }
                if (roomData.users[user_id].status !== "hand-raised") {
                    return; // User not waiting, abort transaction
                }
                roomData.users[user_id].status = "waiting";
            }
        }, null, false);
    }
    static makePublish = (database: firebase.database.Database, datapath: string, user_id: string) => {
        return database.ref(datapath).transaction(roomData => {
            if (roomData) {
                if (!(roomData.users[user_id].status === "waiting" || roomData.users[user_id].status === "hand-raised")) {
                    return; // User must be waiting or hand raised, abort transaction
                }
                roomData.users[user_id].status = "try-to-publish";
            }
        }, null, false);
    }
    static acceptPublish = (database: firebase.database.Database, datapath: string, user_id: string) => {
        return database.ref(datapath).transaction(roomData => {
            if (roomData) {
                if (!roomData.session.active) {
                    return; // Hand raising not accepted when session is inactive, abort transaction
                }
                if (!roomData.users[user_id].in_room) {
                    return; // User not in room, abort transaction
                }
                if (roomData.users[user_id].status !== "try-to-publish") {
                    return; // User not authorized to publish, abort transaction
                }
                roomData.users[user_id].status = "publishing";
            }
        }, null, false);
    }
    static denyPublish = (database: firebase.database.Database, datapath: string, user_id: string) => {
        return database.ref(datapath).transaction(roomData => {
            if (roomData) {
                if (!roomData.session.active) {
                    return; // Hand raising not accepted when session is inactive, abort transaction
                }
                if (!roomData.users[user_id].in_room) {
                    return; // User not in room, abort transaction
                }
                if (roomData.users[user_id].status !== "try-to-publish") {
                    return; // User not authorized to publish, abort transaction
                }
                roomData.users[user_id].status = "publish-denied";
            }
        }, null, false);
    }
    static endPublish = (database: firebase.database.Database, datapath: string, user_id: string) => {
        return database.ref(datapath).transaction(roomData => {
            if (roomData) {
                if (!roomData.users[user_id].in_room) {
                    return; // User not in room, abort transaction
                }
                if (!(roomData.users[user_id].status === "publishing" || roomData.users[user_id].status === "publish-denied")) {
                    return; // User not publishing or denied publish, abort transaction
                }
                roomData.users[user_id].status = "waiting";
            }
        }, null, false);
    }
    static enableFocus = (database: firebase.database.Database, datapath: string, data: any) => {
        return database.ref(datapath).transaction(
            sessionData => {
                if (sessionData) {
                    const devIds = sessionData.devices ? Object.keys(sessionData.devices) : [];
                    if (devIds.every(id => id === data.id || !(sessionData.devices[id].device_status && sessionData.devices[id].device_status.arkit && (sessionData.devices[id].device_status.arkit.status === 'open' || sessionData.devices[id].device_status.arkit.status === 'opened')))) {
                        sessionData.focus = data;
                    } else {
                        // Abort, ArPlus open
                        return;
                    }
                }
                return sessionData;
            }, null, false
        );
    }
    static disableFocus = (database: firebase.database.Database, datapath: string) => {
        return database.ref(datapath).transaction(
            sessionData => {
                if (sessionData) {
                    sessionData.focus = null;
                }
                return sessionData;
            }, null, false
        );
    }
    static updateImageScroll = (database: firebase.database.Database, datapath: string, values: any) => {
        return database.ref(datapath).transaction(controls => {
            if (controls) {
                if (controls.x !== values.x || controls.y !== values.y) {
                    return; // Abort transaction
                } else {
                    controls.x = values.newX;
                    controls.y = values.newY;
                }
            }
            return controls;
        }, null, false);
    }
}