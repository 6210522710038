import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'convertUtc'
})
export class ConvertUtcPipe implements PipeTransform {

  transform(dateTime: string): string {
    return dateTime.replace('GMT', 'UTC');
  }
}