import { Component, EventEmitter } from '@angular/core';
import { DetailPageService } from '@services/detail-page.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RoomWrapperComponent } from '../../main/content/room-wrapper/room-wrapper.component';

@Component({
  selector: 'app-contact-list-modal',
  templateUrl: './contact-list-modal.component.html',
  styleUrls: ['./contact-list-modal.component.scss']
})
export class ContactListModalComponent {

  selectedUsers = []

  onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    private detailPageService: DetailPageService
  ) {}

  close(): void {
    this.modalRef.hide();
  }

  onSelectedUsersChanged(users: string[]) {
    this.selectedUsers = users
  }

  onCallUser(user: string) {
    this.openRoomInterface([user])
  }

  onCallUsers() {
    this.openRoomInterface(this.selectedUsers)
  }

  openRoomInterface(users: string[]) {
    const [instance, onClose] = this.detailPageService.loadComponent(RoomWrapperComponent, {})
    instance.users = users
    instance.roomId = null
    instance.linkId = null
    instance.guestName = null
    onClose.then(() => {
      console.log(closed)
    })
  }


}
