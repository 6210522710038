<div class="dashboard-wrapper">
    <div class="v-card">
      <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
        <div class="d-flex">
            <div class="header fs-20 font-weight-700  pt-1 me-2">{{ 'MAIN.SIDEBAR.SETTINGS' | translate }}</div>
        </div>

    </div>

        <div class="card tab-area d-flex flex-row first-area">
          <div class="tab-item left fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 0 }" (click)="onTabClicked(0)">
              <span class="tab-title">
                  General
              </span>
          </div>
          <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 1 }" (click)="onTabClicked(1)">
              <span class="tab-title">
                  Integrations
              </span>
          </div>
          <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 2 }" (click)="onTabClicked(2)">
            <span class="tab-title">
                License
            </span>
        </div>
      </div>

        <div class="card list-area pt-4">

            <div class="settings-container">

              <div *ngIf="tabIndex == 0">
                <form #webhooksForm="ngForm" autocomplete="off">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.TITLE' | translate }}</span>
                  </div>
                  <div class="mt-4">
                    <div><button class="v-btn btn btn-primary vsight-button" (click)="onAddWebhook()">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.NEW_WEBHOOK' | translate }}</button></div>
                  </div>
                  <div class="form-row mt-4">
                      <!-- <div *ngIf="webhooks.length === 0" class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.NO_WEBHOOK' | translate }}</div> -->
                      <div class="form-group d-flex align-items-center w-100 pl-0" *ngIf="webhooks.length > 0">
                          <div class="fs-14 font-weight-600 text-dark-grey me-2" style="width: 240px;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
                          <div class="fs-14 font-weight-600 text-dark-grey" style="flex-grow: 1;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.URL' | translate }}</div>
                      </div>
                      <div *ngFor="let webhook of webhooks" class="form-group d-flex align-items-center mt-2 w-100 pl-0">
                          <input class="form-control fs-14 me-2" style="width: 240px; min-width: 240px;" [value]="webhook.event" [disabled]="true" type="text">
                          <input class="form-control fs-14" style="flex-grow: 1;" [value]="webhook.url" [disabled]="true" type="text">
                          <button class="v-btn btn btn-danger h-100 ms-2 px-3 vsight-button vsight-danger remove-button" (click)="onRemoveWebhook(webhook)">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE' | translate }}</button>
                      </div>
                  </div>
              </form>
  
                <div class="mt-5">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.ARCHIVE.TITLE' | translate }}</span>
                  </div>
                  <div class="">
                    <ng-container *ngIf="rooms && accountData">
                      <ng-container *ngIf="accountData.features.archiving">
                        <div class="">
                          <div class="row mb-3 mt-5">
                            <div class="col-9 col-xl-10 fs-14 font-weight-600">{{ 'MAIN.SETTINGS.ARCHIVE.ROOMS' | translate }}</div>
                            <div class="col-3 col-xl-2 fs-14 font-weight-600">{{ 'MAIN.SETTINGS.ARCHIVE.RECORD_STATUS' | translate }}</div>
                          </div>
                        </div>
                        <div class=" mb-4" style="overflow-x: scroll; max-height: 200px;">
                          <div class="row d-flex align-items-center" *ngFor="let room of rooms; index as i;">
                            <div class="col-9 col-xl-10 d-inline fs-14 text-dark-grey">{{ room.room_data.name }}</div>
                            <div class="col-3 col-xl-2">
                              <div class="custom-control centered-checkbox custom-checkbox" [class.disabled]="true">
                                <input type="checkbox" class="custom-control-input" id="{{ 'auto-archive'+i }}" [disabled]="true" [(ngModel)]="room.room_data.auto_archive">
                                <label class="custom-control-label fs-14 text-dark-grey" [attr.for]="'auto-archive'+i">
                                  <span>{{ (room.room_data.auto_archive ? 'MAIN.SETTINGS.ARCHIVE.AUTO' : (room.room_data.peer_to_peer ? 'MAIN.SETTINGS.ARCHIVE.P2P' : 'MAIN.SETTINGS.ARCHIVE.MANUAL')) | translate }}</span>
                                  <a *ngIf="room.room_data.peer_to_peer" class="peer-to-peer-info text-info ml-2" (click)="onPeerToPeerInfo()">(?)</a>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <div class=" mb-4">
                        <div class="form-group d-flex align-items-center mt-1">
                          <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="video-switch" name="switch" [disabled]="!accountData.features.archiving" [(ngModel)]="alwaysArchive">
                          <label class="form-check-label fs-14 font-weight-400 text-dark-grey" for="editorAccessAvailability">{{ 'MAIN.SETTINGS.ARCHIVE.AUTO_RECORD' | translate }}
                          </label>
                        </div>
  
                        <div class="fs-12 text-dark-grey mt-1" *ngIf="accountData.features.archiving && !accountData.always_archive">
                          {{ 'MAIN.SETTINGS.ARCHIVE.CHANGE_INDIVIDUALLY' | translate }}
                        </div>
                        <div class="fs-14 text-dark-grey" *ngIf="!accountData.features.archiving">
                          {{ 'MAIN.SETTINGS.ARCHIVE.NO_ARCHIVE' | translate }}
                        </div>
                      </div>
              
                      <button class="v-btn btn btn-primary vsight-button" [disabled]="this.accountData.always_archive === this.alwaysArchive" (click)="onSaveAlwaysArchive()">{{ 'MAIN.SETTINGS.ARCHIVE.SAVE' | translate | titlecase }}</button>
                    </ng-container>
                  </div>
                </div>
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4" *ngIf="accountData?.add_ons?.fullhd">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.ALWAYS_FULL_HD.TITLE' | translate }}</span>
                  </div>
  
                  <div class="form-group d-flex align-items-center mt-4">
                    <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="full-hd-switch" name="full-hd-switch" [disabled]="!accountData.add_ons.fullhd" [(ngModel)]="alwaysFullHd">
                    <label class="form-check-label fs-14 font-weight-400 text-dark-grey" for="editorAccessAvailability">{{ 'MAIN.SETTINGS.ALWAYS_FULL_HD.FULL_HD_MESSAGE' | translate }}
                    </label>
                  </div>
  
                  <div class="fs-12 text-dark-grey mt-1" *ngIf="accountData.features.archiving && !accountData.always_archive">
                    {{ 'MAIN.SETTINGS.ALWAYS_FULL_HD.INDIVIDUAL_MESSAGE' | translate }}
                  </div>
  
                  <button class="v-btn btn btn-primary vsight-button mt-4" [disabled]="accountData.always_full_hd === alwaysFullHd" (click)="onSaveAlwaysFullHd()">{{ 'MAIN.SETTINGS.ALWAYS_FULL_HD.SAVE' | translate | titlecase }}</button>
                </div>
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.EMAIL_RECIPIENTS.TITLE' | translate }}</span>
                  </div>
                  <div class="mt-4">
                    <div class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.EMAIL_RECIPIENTS.MESSAGE' | translate:('design' | env) }}</div>
                    <tag-input #tagInput [(ngModel)]="emailRecipients" (onAdd)="emailListChanged()" (onRemove)="emailListChanged()" [placeholder]="emailPholder" [secondaryPlaceholder]="emailSecPholder"
                      theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError" [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
              
                    <div class="fs-12 font-weight-600 text-dark-grey mt-2">{{ 'MAIN.SETTINGS.EMAIL_RECIPIENTS.WARNING' | translate }}</div>
              
                    <button class="v-btn btn btn-primary vsight-button mt-4" [ngStyle]="{'cursor': recipientsChanged ? 'pointer' : 'default'}" [fakeDisabled]="!recipientsChanged" (enabledClick)="onSaveEmailList()">{{ 'MAIN.SETTINGS.EMAIL_RECIPIENTS.SAVE' | translate | titlecase }}</button>
                  </div>
                </div>
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.TIMEZONE.TITLE' | translate }}</span>
                  </div>
                  <div class="mt-4">
                    <!--<div class="recipients-message mb-2">{{ 'MAIN.SETTINGS.TIMEZONE.MESSAGE' | translate }}</div>-->
                    <select #timezone [(ngModel)]="tzone" class="form-control" name="timezone">
                      <option *ngFor="let zone of timezones" [ngValue]="zone">{{ zone }}</option>
                    </select>
                    <button class="v-btn btn btn-primary vsight-button mt-4" [disabled]="orjTzone === tzone" (click)="onChangeTimezone()">{{ 'MAIN.SETTINGS.TIMEZONE.SAVE' | translate | titlecase }}</button>
                  </div>
                </div>
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="password-policy mt-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.TITLE' | translate }}</span>
                  </div>
  
                  <div class="form-group d-flex align-items-center mt-4">
                    <input type="checkbox" class="form-check-input editor-access-checkbox mt-0 me-2" id="password-switch" name="switch" [checked]="strongEnabled" (change)="enablePasswordSetting()">
                    <label class="form-check-label fs-14 font-weight-400 text-dark-grey" for="editorAccessAvailability">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.SPECIFY_RULE' | translate }}
                    </label>
                  </div>
  
                  <div class="">
                    <div class="row mt-4" *ngIf="strongEnabled">
                      <div class="col-sm">
                        <div class="text-left mb-2">
                          <div class='title fs-14 text-dark-grey'>{{ 'MAIN.SETTINGS.PASSWORD_POLICY.MIN_LENGTH' | translate }}</div>
                        </div>
                        <div class="mb-3 mt-3">
                          <ng-select 
                            class="select-length" 
                            [items]="passwordLengths" 
                            [disabled]="!strongEnabled" 
                            [clearable]="false" 
                            [searchable]="false" 
                            name="passwordlength" 
                            placeholder="" 
                            [(ngModel)]="passwordLength"
                            (change)="minimumCharacterClick()"
                          ></ng-select>
                        </div>
                      </div>
                      <div class="col-sm ms-5">
                        <div class="text-left">
                          <div class='title fs-14 text-dark-grey'>{{ 'MAIN.SETTINGS.PASSWORD_POLICY.FOLLOWING_CRITERIAS' | translate }}</div>
                        </div>
                        <div class="form-group mt-3">
                          <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                            <input 
                              class="custom-control-input form-check-input editor-access-checkbox mt-0" 
                              [checked]="strongEnabled && AZSelected" 
                              type="checkbox" 
                              value="" 
                              (change)="AZClick()" 
                              id="uppercase_enabled" 
                              name="uppercase_enabled"
                              [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}"
                              [disabled]="!strongEnabled"
                            >
                            <label class="custom-control-label fs-14 text-dark-grey" for="uppercase_enabled">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.UPPERCASE' | translate }}&nbsp;
                            <label class="custom-control-identifier fs-14 text-dark-grey">(A-Z)</label></label>
                          </div>
                        </div>
                        <div class="form-group mt-1">
                          <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                            <input
                              class="custom-control-input form-check-input editor-access-checkbox mt-0" 
                              [checked]="strongEnabled && azSelected" 
                              type="checkbox" 
                              value="" 
                              (change)="azClick()" 
                              id="lowercase_enabled"
                              name="lowercase_enabled" 
                              [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}" 
                              [disabled]="!strongEnabled"
                            >
                            <label class="custom-control-label fs-14 text-dark-grey" for="lowercase_enabled">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.LOWERCASE' | translate }}&nbsp;
                            <label class="custom-control-identifier fs-14 text-dark-grey">(a-z)</label></label>
                          </div>
                        </div>
                        <div class="form-group mt-1">
                          <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                            <input
                              class="custom-control-input form-check-input editor-access-checkbox mt-0" 
                              [checked]="strongEnabled && numberSelected" 
                              type="checkbox" 
                              value="" 
                              (change)="numberClick()" 
                              id="number_enabled"
                              name="number_enabled"
                              [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}" 
                              [disabled]="!strongEnabled"
                            >
                            <label class="custom-control-label fs-14 text-dark-grey" for="number_enabled">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.NUMBER' | translate }}&nbsp;
                            <label class="custom-control-identifier fs-14 text-dark-grey">(0-9)</label></label>
                          </div>
                        </div>
                        <div class="form-group mt-1">
                          <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                            <input
                              class="custom-control-input form-check-input editor-access-checkbox mt-0" 
                              [checked]="strongEnabled && specialCharacterSelected" 
                              type="checkbox" 
                              value="" 
                              (change)="specialCharactersClick()" 
                              id="special_enabled"
                              name="special_enabled" 
                              [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}" 
                              [disabled]="!strongEnabled"
                            >
                            <label class="custom-control-label fs-14 text-dark-grey" for="special_enabled">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.SPECIAL' | translate }}&nbsp;
                            <label class="custom-control-identifier fs-14 text-dark-grey">(-*/?#&())</label></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mt-4">
                      <button class="v-btn btn btn-primary vsight-button" (click)="onPasswordControlGenerate()" [disabled]="!changeDetected">{{ 'MAIN.SETTINGS.PASSWORD_POLICY.SAVE' | translate | titlecase }}</button>
                    </div>
                  </div>
                </div>
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.CONTACT_INFO.TITLE' | translate }}</div>
                    <div>
                      <button *ngIf="!isContactEdit"  class="v-btn btn btn-primary vsight-button" style="font-size: 14px;" (click)="enableContactEdit()">{{ 'MAIN.SETTINGS.CONTACT_INFO.EDIT' | translate }}</button>
                      <button *ngIf="isContactEdit" [disabled]="contactsForm.controls.formGroupList.length > 2" class="v-btn btn btn-primary vsight-button me-2" (click)="addFormGroup()">{{ 'MAIN.SETTINGS.CONTACT_INFO.NEW' | translate }}</button>
                      <button *ngIf="isContactEdit && formPristine" class="v-btn btn btn-primary vsight-button vsight-secondary-btn" (click)="disableContactEdit()">{{ 'MAIN.SETTINGS.CONTACT_INFO.CANCEL' | translate }}</button>
                      <button *ngIf="isContactEdit && !formPristine" class="v-btn btn btn-primary vsight-button vsight-secondary-btn" (click)="revertChanges()">{{ 'MAIN.SETTINGS.CONTACT_INFO.REVERT' | translate }}</button>
                    </div>
                    
                  </div>
                  <div class="">
                    <form [formGroup]="contactsForm" autocomplete="off">
                      <div *ngIf="isContactEdit">
                        <div class="mb-4" [formGroup]="formGroupList" *ngFor="let formGroupList of contactsForm.controls.formGroupList.controls; index as groupIndex;">
                          <div class="d-flex justify-content-between align-items-center col-12 mb-0 p-0">
                            <span class="mt-2 mb-2 fs-14 font-weight-600">{{ 'MAIN.SETTINGS.CONTACT_INFO.CONTACT' | translate }} {{ groupIndex+1 }}</span>
                          </div>
                          <div class="d-flex flex-wrap">
                            <div class="form-group col-12 col-lg-6 mb-0 p-0 pe-2">
                              <input class="form-control contact-input mt-1" type="text" formControlName="department" placeholder="{{ 'MAIN.SETTINGS.CONTACT_INFO.DEPARTMENT' | translate }}*"
                                [ngClass]="{'is-invalid':formGroupList.controls.department?.errors && formGroupList.controls.department.touched}">
                              <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.department.errors?.['required']">{{ 'MAIN.SETTINGS.CONTACT_INFO.DEP_REQ' | translate }}</small>  
                            </div>
                            <div class="form-group col-12 col-lg-6 mb-0 p-0">
                              <input class="form-control contact-input mt-1 ml-auto" type="text" formControlName="name" placeholder="{{ 'MAIN.SETTINGS.CONTACT_INFO.NAME' | translate }}*"
                                [ngClass]="{'is-invalid':formGroupList.controls.name?.errors && formGroupList.controls.name.touched}">
                              <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.name.errors?.['required']">{{ 'MAIN.SETTINGS.CONTACT_INFO.NAME_REQ' | translate }}</small>  
                            </div>
                            <div class="form-group col-12 col-lg-6 mb-0 p-0 pe-2 mt-2">
                              <input class="form-control contact-input mt-1" type="text" formControlName="email" placeholder="{{ 'MAIN.SETTINGS.CONTACT_INFO.EMAIL' | translate }}*"
                                [ngClass]="{'is-invalid':formGroupList.controls.email?.errors && formGroupList.controls.email.touched}">
                              <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.email.errors?.['required']">{{ 'MAIN.SETTINGS.CONTACT_INFO.EMAIL_REQ' | translate }}</small>  
                              <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.email.errors?.['pattern']">{{ 'MAIN.SETTINGS.CONTACT_INFO.INVALID_EMAIL' | translate }}</small>  
                            </div>
                            <div class="form-group col-12 col-lg-6 mb-0 p-0 mt-2">
                              <input class="form-control contact-input mt-1 ml-auto" type="text" formControlName="phone" placeholder="{{ 'MAIN.SETTINGS.CONTACT_INFO.PHONE' | translate }}"
                                [ngClass]="{'is-invalid':formGroupList.controls.phone.errors && formGroupList.controls.phone.touched}">
                            </div>
                          </div>
                          <button type="button" class="v-btn btn btn-primary vsight-button vsight-secondary-btn mt-3" (click)="removeFormGroup(groupIndex);">{{ 'MAIN.SETTINGS.CONTACT_INFO.DELETE' | translate }}</button>
                        </div>
                      </div>
                      <div *ngIf="!isContactEdit">
                        <div class="d-flex flex-row mb-4" *ngFor="let formGroupList of contactsForm.controls.formGroupList.controls; index as groupIndex;">
                          <div class="row w-100">
                            <div class="d-flex justify-content-between align-items-center col-12 mb-0">
                              <span>{{ 'MAIN.SETTINGS.CONTACT_INFO.CONTACT' | translate }} {{ groupIndex+1 }}</span>
                              <!-- class="ml-2" -->
                            </div>
                            <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-0 mt-2">
                              <span style="font-weight: 700;">{{ 'MAIN.SETTINGS.CONTACT_INFO.DEPARTMENT' | translate }}: </span>
                            </div>
                            <div class="col-6 col-md-8 col-lg-3 col-xl-4 mb-0 mt-2">
                              <span>{{formGroupList.controls.department.value}}</span>
                            </div>
                            <div class="col-6 col-md-4 col-lg-2 mb-0 mt-2">
                              <span style="font-weight: 700;">{{ 'MAIN.SETTINGS.CONTACT_INFO.NAME' | translate }}: </span>
                            </div>
                            <div class="col-6 col-md-8 col-lg-4 mb-0 mt-2">
                              <span>{{formGroupList.controls.name.value}}</span>
                            </div>
                            <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-0 mt-2">
                              <span style="font-weight: 700;">{{ 'MAIN.SETTINGS.CONTACT_INFO.EMAIL' | translate }}: </span>
                            </div>
                            <div class="col-6 col-md-8 col-lg-3 col-xl-4 mb-0 mt-2">
                              <span>{{formGroupList.controls.email.value}}</span>
                            </div>
                            <div class="col-6 col-md-4 col-lg-2 mb-0 mt-2">
                              <span style="font-weight: 700;">{{ 'MAIN.SETTINGS.CONTACT_INFO.PHONE' | translate }}: </span>
                            </div>
                            <div class="col-6 col-md-8 col-lg-4 mb-0 mt-2">
                              <span>{{formGroupList.controls.phone.value || "-"}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button *ngIf="isContactEdit" [disabled]="!contactsForm.valid || formPristine" class="v-btn btn btn-primary vsight-button mt-2" (click)="contactsFormSubmit(contactsForm)">{{ 'MAIN.SETTINGS.CONTACT_INFO.SAVE' | translate }}</button>
                    </form>
                  </div>
                </div>
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.TWO_FACTOR_AUTH.TITLE' | translate }}</span>
                  </div>
                  <div class="">
                      <div class="fs-14 text-dark-grey mt-4 mb-4">{{ 'MAIN.SETTINGS.TWO_FACTOR_AUTH.ADMIN_2FA' | translate }}</div>
                      <button *ngIf="!twoFactorAdmin" class="v-btn btn btn-primary vsight-button ml-3" (click)="onChangeTwoFactor('enableadmin')" [disabled]="!allowTwoFactor">{{ 'MAIN.SETTINGS.TWO_FACTOR_AUTH.ENABLE' | translate | titlecase }}</button>
                      <button *ngIf="twoFactorAdmin" class="btn btn-danger btn-sm ml-3" (click)="onChangeTwoFactor('disableall')" [disabled]="!allowTwoFactor">{{ 'MAIN.SETTINGS.TWO_FACTOR_AUTH.DISABLE' | translate | titlecase }}</button>
              <!--
                    <hr>
                    <div class="">
                      <div class="row d-flex align-items-center">
                        <div class="col-9">
                          <div class="fs-14 font-weight-600 text-dark-grey">Remote Two Factor Authentication</div>
                          <div class="mt-2">You can enable two factor authentication for all users.</div>
                          <div *ngIf="!twoFactorAdmin" class="mt-2 text-danger fs-13">You must enable admin two factor before all users.</div>
                        </div>
                        <div class="col-3">
                          <button *ngIf="!twoFactorOthers" class="btn btn-info btn-sm" [disabled]="!(twoFactorAdmin && allowTwoFactor)" (click)="onChangeTwoFactor('enableothers')">Enable</button>
                          <button *ngIf="twoFactorOthers" class="btn btn-danger btn-sm" (click)="onChangeTwoFactor('disableexceptadmin')" [disabled]="!allowTwoFactor">Disable Except Admin</button>
                        </div>
                      </div>
                    </div>
              -->
                    <div *ngIf="!allowTwoFactor" class="disabled-overlay" [dynamicTooltip]="'MAIN.SETTINGS.TWO_FACTOR_AUTH.TWO_FACTOR_UNAVAILABLE'" [tooltipDisabled]="allowTwoFactor" [tooltipDelay]="200"></div>
                  </div>
                </div><!-- -->
  
                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4" *ngIf="customEmailAvailable">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.WHITELABEL_SETTINGS.TITLE' | translate }}</span>
                  </div>
                  <div class="mt-4">
                    <div>
                      <div class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.WHITELABEL_SETTINGS.SYSTEM_EMAIL_TITLE' | translate }}</div>
                      <div class="mt-2 fs-12 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.WHITELABEL_SETTINGS.SYSTEM_EMAIL_MESSAGE' | translate }}</div>
                    </div>
                    <div class="mt-4">
                      <button *ngIf="!customEmailEnabled" class="v-btn btn btn-primary vsight-button ml-3" (click)="onEnableCustomSystemEmail()">{{ 'MAIN.SETTINGS.WHITELABEL_SETTINGS.ENABLE' | translate | titlecase }}</button>
                      <button *ngIf="customEmailEnabled" class="btn btn-danger btn-sm ml-3" (click)="onDisableCustomSystemEmail()">{{ 'MAIN.SETTINGS.WHITELABEL_SETTINGS.DISABLE' | translate | titlecase }}</button>
                    </div>
                  </div>
                </div>

                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
    
                <!-- webhook form remote -->
              
                <!-- <div class="mt-4" *ngIf="webhooksAvailable">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.WEBHOOKS.TITLE' | translate }}</span>
                    <button class="btn btn-info" style="font-size: 14px;" (click)="onAddNewWebhook()">{{ 'MAIN.SETTINGS.WEBHOOKS.NEW_WEBHOOK' | translate }}</button>
                  </div>
                  <div class="">
                    <form #webhooksForm="ngForm" autocomplete="off">
                      <div class="form-row px-4">
                        <div *ngIf="webhooks.length === 0">{{ 'MAIN.SETTINGS.WEBHOOKS.NO_WEBHOOK' | translate }}</div>
                        <div class="form-group d-flex align-items-center w-100 pl-0" *ngIf="webhooks.length > 0">
                          <div class="fs-14 font-weight-600 text-dark-grey mr-2" style="width: 150px;">{{ 'MAIN.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
                          <div class="fs-14 font-weight-600 text-dark-grey" style="flex-grow: 1;">{{ 'MAIN.SETTINGS.WEBHOOKS.URL' | translate }}</div>
                        </div>
                        <div *ngFor="let webhook of webhooks" class="form-group d-flex align-items-center mt-2 w-100 pl-0">
                          <input class="form-control mr-2" style="width: 150px;" [value]="webhook.event" [disabled]="true" type="text">
                          <input class="form-control" style="flex-grow: 1;" [value]="webhook.url" [disabled]="true" type="text">
                          <button class="btn btn-danger btn-sm h-100 ml-2" (click)="onRemoveWebhook(webhook)">{{ 'MAIN.SETTINGS.WEBHOOKS.REMOVE' | translate }}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> -->
              
                <div class="mt-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.SYS_REQ.TITLE' | translate }}</span>
                  </div>
                  <div class="mt-4">
                    <div class="requirement-container">
                      <div class="requirement-content">
                        <div class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.SYS_REQ.ANDROID_TITLE' | translate }}</div>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.ANDROID_VERSION' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.ANDROID_RAM' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.ANDROID_CORE_SPEED' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.ANDROID_ARCORE' | translate }}<a href="https://developers.google.com/ar/devices">{{ 'MAIN.SETTINGS.SYS_REQ.ANDROID_ARCORE_DEVICE' | translate }}</a></p>
                        
                        <div class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.SYS_REQ.IOS_TITLE' | translate }}</div>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.IOS_VERSION' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.IOS_IPHONE' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.IOS_IPAD' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.IOS_ARKIT' | translate }}<a href="https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/DeviceCompatibilityMatrix/DeviceCompatibilityMatrix.html">{{ 'MAIN.SETTINGS.SYS_REQ.IOS_ARKIT_DEVICE' | translate }}</a></p>
                        
                        <div class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.SYS_REQ.NETWORK_TITLE' | translate }}</div>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.NETWORK_BODY' | translate }}</p>
                    
                        <div class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.SYS_REQ.BROWSERS_TITLE' | translate }}</div>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.CHROME' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.FIREFOX' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.SAFARI' | translate }}</p>
                        <p class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.SYS_REQ.EDGE' | translate }}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="tabIndex == 1">
                <div>
                  <app-storage></app-storage>
                </div>

                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>

                <div class="mt-4" *ngIf="adIntegrationAvailable">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.AZURE_AD.AZURE_AD_INTEGRATION' | translate }}</span>
                  </div>
                  <div class="">
                    <div class="d-flex justify-content-between align-items-center py-3">
                      <div>
                        <div class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.AZURE_AD.SINGLE_SIGN_ON' | translate }}</div>
                        <div class="mt-2 fs-12 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.AZURE_AD.CONFIGURE_SAML' | translate }}</div>
                      </div>
                      <button *ngIf="!samlConfigured" class="v-btn btn btn-primary vsight-button" (click)="addSamlConfiguration()">{{ 'MAIN.SETTINGS.AZURE_AD.ADD_SAML_CONFIGURATION' | translate }}</button>
                      <button *ngIf="samlConfigured" class="v-btn vsight-button btn btn-danger vsight-danger" (click)="removeSamlConfiguration()">{{ 'MAIN.SETTINGS.AZURE_AD.REMOVE_SAML_CONFIGURATION' | translate }}</button>
                    </div>
                    <div *ngIf="samlConfigured" class="row mt-3">
                      <div class="col-12">
                        <div class="fs-14 font-weight-600 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.INFORMATION' | translate }}</div>
                      </div>
                      <div class="col-12 col-xl-8">
                        <form #samlDataFormFixed="ngForm" autocomplete="off">
                          <div class="form-row">
                            <div class="form-group col-9">
                              <label for="entityId" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}</label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="entityId" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}" [value]="azureAdConfig.samlEntityId" [disabled]="true">
                                <button #copyEntityIdFixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlEntityId" [select-text]="copyEntityIdFixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                            </div> -->
                          </div>
                          <div class="form-row mt-2">
                            <div class="form-group col-9">
                              <label for="replyUrl" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}</label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="replyUrl" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}" [value]="azureAdConfig.samlReplyUrl" [disabled]="true">
                                <button #copyReplyUrlFixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlReplyUrl" [select-text]="copyReplyUrlFixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                            </div> -->
                          </div>
                          <div class="form-row mt-2">
                            <div class="form-group col-9">
                              <label for="replyUrl2" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}</label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="replyUrl2" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}" [value]="azureAdConfig.samlReplyUrl2" [disabled]="true">
                                <button #copyReplyUrl2Fixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlReplyUrl2" [select-text]="copyReplyUrl2Fixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                            </div> -->
                          </div>
                          <div class="form-row mt-2">
                            <div class="form-group col-9">
                              <label for="replyUrl3" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}</label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="replyUrl3" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}" [value]="azureAdConfig.samlReplyUrl3" [disabled]="true">
                                <button #copyReplyUrl3Fixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlReplyUrl3" [select-text]="copyReplyUrl3Fixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                            </div> -->
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center py-3">
                      <div>
                        <div class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.AZURE_AD.USER_PROVISIONING' | translate }}</div>
                        <div class="mt-2 fs-12 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.AZURE_AD.SCIM_USER_PROVISIONING' | translate }}</div>
                      </div>
                      <button *ngIf="!scimConfigured" class="v-btn btn btn-primary vsight-button" (click)="addScimConfiguration()">{{ 'MAIN.SETTINGS.AZURE_AD.ADD_SCIM_CONFIGURATION' | translate }}</button>
                      <button *ngIf="scimConfigured" class="v-btn vsight-button btn btn-danger vsight-danger" (click)="removeScimConfiguration()">{{ 'MAIN.SETTINGS.AZURE_AD.REMOVE_SCIM_CONFIGURATION' | translate }}</button>
                    </div>
                    <div *ngIf="scimConfigured" class="row mt-3">
                      <div class="col-12">
                        <div class="fs-14 font-weight-600 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.INFO' | translate }}</div>
                      </div>
                      <div class="col-12 col-xl-8">
                        <form #scimDataFormFixed="ngForm" autocomplete="off">
                          <div class="form-row mt-2">
                            <div class="form-group col-9">
                              <label for="scimEndpoint" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}<span class="required-field">*</span></label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="scimEndpoint" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}" [value]="azureAdConfig.scimEndpoint" [disabled]="true">
                                <button #copyScimEndpointFixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.scimEndpoint" [select-text]="copyScimEndpointFixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                            </div> -->
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div  style="border-bottom: solid 1px #ececec; padding-top: 30px;" class="w-100"></div>
              
                <div class="mt-4 mb-4" *ngIf="oidcAvailable">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.OAUTH_OIDC.OAUTH_OIDC_TITLE' | translate }}</span>
                  </div>
                  <div class="">
                    <div class="d-flex justify-content-between align-items-center py-3">
                      <div>
                        <div class="fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.OAUTH_OIDC' | translate }}</div>
                        <div class="mt-2 fs-12 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.CONFIGURE_OAUTH_OIDC' | translate }}</div>
                      </div>
                      <button *ngIf="!oidcConfigured" class="v-btn btn btn-primary vsight-button" (click)="addOIDCConfiguration()">{{ 'MAIN.SETTINGS.OAUTH_OIDC.ADD_OAUTH_OIDC_CONFIGURATION' | translate }}</button>
                      <button *ngIf="oidcConfigured" class="v-btn vsight-button btn btn-danger vsight-danger" (click)="removeOIDCConfiguration()">{{ 'MAIN.SETTINGS.OAUTH_OIDC.REMOVE_OAUTH_OIDC_CONFIGURATION' | translate }}</button>
                    </div>
                    <div *ngIf="oidcConfigured" class="row mt-3">
                      <div class="col-12">
                        <div class="fs-14 font-weight-600 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.OAUTH_OIDC.INFORMATION' | translate }}</div>
                      </div>
                      <div class="col-12 col-xl-8">
                        <form #oidcDataFormFixed="ngForm" autocomplete="off">
                          <div class="form-row mt-2">
                            <div class="form-group col-9">
                              <label for="initiateLoginUrl" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}</label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="initiateLoginUrl" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}" [value]="oauthOIDCConfig.initiateLoginUrl" [disabled]="true">
                                <button #initiateLoginUrlFixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="oauthOIDCConfig.initiateLoginUrl" [select-text]="initiateLoginUrlFixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</label>
                            </div> -->
                          </div>
                          <div class="form-row mt-2">
                            <div class="form-group col-9">
                              <label for="redirectUrl" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}</label>
                              <div class="d-flex">
                                <input class="form-control col-9" type="text" name="redirectUrl" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}" [value]="oauthOIDCConfig.redirectUrl" [disabled]="true">
                                <button #redirectUrlFixed class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="oauthOIDCConfig.redirectUrl" [select-text]="redirectUrlFixed" (copied)="copied($event)">{{ 'MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-3">
                              <label style="display: block; opacity: 0;">{{ 'MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</label>
                            </div> -->
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="tabIndex == 2">
                  <div class="">
                      <div class="text-center mt-2" *ngIf="!license">{{ 'MAIN.LICENSE_MODAL.LOADING' | translate }}</div>
                      <div class="t-container t-no-hover" *ngIf="license">
                        <div class="row mb-3" *ngFor="let licenceItem of license">
                          <div class="col-6 fs-14 font-weight-600">{{ 'MAIN.LICENSE_MODAL.'+ licenceItem[0] | uppercase | translate }}</div>
                          <div *ngIf="licenceItem[1].constructor.name !== 'Array'" class="col-3 fs-14 text-dark-grey">{{ licenceItem[1]  }}</div>
                          <div *ngIf="licenceItem[1].constructor.name == 'Array'" class="col-3 fs-14 text-dark-grey">
                            <div *ngFor="let elementTraining of licenceItem[1]">
                                <div class="mb-2">{{'MAIN.LICENSE_MODAL.LICENCE_HOURS' | translate:{hours:elementTraining.time} }}</div>
                                <div><span>{{'MAIN.LICENSE_MODAL.CONSUMED' | translate}}:</span> {{elementTraining.time_usage | durationFormat: 'ms':'hhmmss'}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            
            
            <!--
              <div class="voice-commands mt-4">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="fs-14 font-weight-600">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.TITLE' | translate }}</span>
                  <button class="btn btn-outline-success" (click)="onNewVoiceCommand()">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.NEW_COMMAND' | translate }}</button>
                </div>
                <div class="card-body p-0">
                  <div class="text-center py-3 bg-white" *ngIf="!voiceCommands">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.LOADING' | translate }}</div>
                  <div class="text-center py-3 bg-white" *ngIf="voiceCommands && voiceCommands.length === 0">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.NO_COMMAND' | translate }}</div>
                  <div *ngIf="voiceCommands && voiceCommands.length > 0" cdkDropList #voiceCommandList="cdkDropList" [cdkDropListData]="voiceCommands" (cdkDropListDropped)="onCommandDrop($event)" class="t-container ">
                    <div class="t-head t-draggable row sm-hide">
                      <div class="t-cell col-6 col-lg-4">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.COMMAND' | translate }}</div>
                      <div class="t-cell col-6 col-lg-4">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.TRANSLATION' | translate }}</div>
                      <div class="t-cell col-6 col-lg-2">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.LANGUAGE' | translate }}</div>
                      <div class="t-cell col-6 col-lg-2"></div>
                    </div>
                    <div class="t-row t-draggable row bg-white" *ngFor="let command of voiceCommands; index as i;" cdkDrag>
                      <div class="drag-handle" cdkDragHandle><i class="mi mi-drag-handle"></i></div>
                      <div class="t-cell sm-show col-6 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.COMMAND' | translate }}</div>
                      <div class="t-cell col-6 col-lg-4">{{ command.original.text }}</div>
                      <div class="t-cell sm-show col-6 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.TRANSLATION' | translate }}</div>
                      <div class="t-cell col-6 col-lg-4">{{ command.translation.text }}</div>
                      <div class="t-cell sm-show col-6 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.LANGUAGE' | translate }}</div>
                      <div class="t-cell col-6 col-lg-2">{{ command.translation.language }}</div>
                      <div class="t-cell sm-show col-6"></div>
                      <div class="t-cell col-6 col-lg-2">BUTTONS</div>
                    </div>
                  </div>
                  <div *ngIf="!allowVoiceCommands" class="disabled-overlay" [dynamicTooltip]="'MAIN.SETTINGS.VOICE_COMMANDS.UNAVAILABLE'" [tooltipDisabled]="allowVoiceCommands" [tooltipDelay]="200"></div>
                </div>
              </div>
            -->
            </div>
        </div>
          <!--
          <ng-template #addCommandTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="card">
                  <div class="py-3">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.ADD_MODAL.TITLE' | translate }}</div>
                  <div class="card-body">
                    <div class="modal-body">
                      
                    </div>
                    <div class="m-footer">
                      <a class="text-info" (click)="callbacks.close()">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.ADD_MODAL.CLOSE' | translate }}</a>
                      <a class="text-success" (click)="callbacks.add()">{{ 'MAIN.SETTINGS.VOICE_COMMANDS.ADD_MODAL.ADD' | translate }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          -->
          
          <ng-template #configureSamlTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.AZURE_AD.ADD_SAML' | translate }}</div>
                  <div class="">
                    <div class="modal-body pt-4 px-5">
          
                      <div class="fs-14 font-weight-600 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.ADD_SAML' | translate }}</div>
                      <form #samlDataForm="ngForm" autocomplete="off">
                        <div class="form-row">
                          <div class="form-group mt-2">
                            <label for="entityId" class="fs-14 text-dark-grey mt-2 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}</label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="entityId" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}" [value]="azureAdConfig.samlEntityId" [disabled]="true">
                              <button #copyEntityId class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlEntityId" [select-text]="copyEntityId" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group ">
                            <label for="replyUrl" class="fs-14 text-dark-grey mt-2 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}</label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="replyUrl" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}" [value]="azureAdConfig.samlReplyUrl" [disabled]="true">
                              <button #copyReplyUrl class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlReplyUrl" [select-text]="copyReplyUrl" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group ">
                            <label for="replyUrl2" class="fs-14 text-dark-grey mt-2 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}</label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="replyUrl2" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}" [value]="azureAdConfig.samlReplyUrl2" [disabled]="true">
                              <button #copyReplyUrl2 class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlReplyUrl2" [select-text]="copyReplyUrl2" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group ">
                            <label for="replyUrl3" class="fs-14 text-dark-grey mt-2 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}</label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="replyUrl3" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}" [value]="azureAdConfig.samlReplyUrl3" [disabled]="true">
                              <button #copyReplyUrl3 class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.samlReplyUrl3" [select-text]="copyReplyUrl3" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                            </div>
                          </div>
                        </div>
                      </form>
          
                      <div class="fs-14 font-weight-600 text-dark-grey mt-3 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.INFORMATION_VSIGHT' | translate }}</div>
                      <form #samlForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.enable(samlForm)">
                        <div class="form-group">
                          <label for="ssoUrl" class="fs-14 text-dark-grey mt-2 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.SSO' | translate }}<span class="required-field">*</span></label>
                          <input #ssoUrl="ngModel" class="form-control" type="text" name="ssoUrl" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.SSO' | translate }}"
                              [ngClass]="{'is-invalid':ssoUrl.errors && ssoUrl.touched}" [(ngModel)]="dataModel.ssoUrl" required>
                          <small class="invalid-feedback mx-2" [hidden]="!ssoUrl.errors?.['required']">{{ 'MAIN.SETTINGS.AZURE_AD.SSO_URL_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="cert" class="fs-14 text-dark-grey mt-2 mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.CERTIFICATE' | translate }}<span class="required-field">*</span></label>
                          <textarea #cert="ngModel" class="form-control" rows="3" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.CERTIFICATE' | translate }}" [(ngModel)]="dataModel.cert"
                              name="cert" [ngClass]="{'is-invalid':cert.errors && cert.touched}" required></textarea>
                          <small class="invalid-feedback mx-2" [hidden]="!cert.errors?.['required']">{{ 'MAIN.SETTINGS.AZURE_AD.CERTIFICATE_REQUIRED' | translate }}</small>
                        </div>
                      </form>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.add(samlForm)" [class.disabled]="!samlForm.valid">
                        {{ 'MAIN.SETTINGS.AZURE_AD.ADD' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #configureScimTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.AZURE_AD.ADD_SCIM_CONFIGURATION' | translate }}</div>
                  <div class="">
                    <div class="modal-body pt-4 px-5">
          
                      <div class="fs-14 font-weight-600 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.INFORMATION' | translate }}</div>
                      <form #scimDataForm="ngForm" autocomplete="off">
                        <div class="form-row mt-2">
                          <div class="form-group ">
                            <label for="scimEndpoint" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}<span class="required-field">*</span></label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="scimEndpoint" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}" [value]="azureAdConfig.scimEndpoint" [disabled]="true">
                              <button #copyScimEndpoint class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="azureAdConfig.scimEndpoint" [select-text]="copyScimEndpoint" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                            </div>
                          </div>
                          <!-- <div class="form-group col-2">
                            <label style="opacity: 0;">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                          </div> -->
                        </div>
          
                        <div class="form-row mt-2">
                          <div class="d-flex mt-3 mb-3 justify-content-between">
                            <div class="">
                              <button class="v-btn btn btn-primary vsight-button" type="button" (click)="callbacks.generateToken()" [disabled]="scimConfigured">{{ 'MAIN.SETTINGS.AZURE_AD.GENERATE_TOKEN_FOR_SCIM' | translate }}</button>
                            </div>
                            <div class="">
                              <button #copyScimToken [class.disabled]="!dataModel.scimToken" [disabled]="!dataModel.scimToken" class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="dataModel.scimToken" [select-text]="copyScimToken" (copied)="copied($event)">{{ 'MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                            </div>
                          </div>
                          
                          <div class="form-group col-12 mt-1">
                            <textarea class="form-control" rows="4" placeholder="{{ 'MAIN.SETTINGS.AZURE_AD.GENERATE_TOKEN_FOR_SCIM' | translate }}" name="scimToken" [value]="dataModel.scimToken" [disabled]="true"></textarea>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.close()">
                        {{ scimConfigured ? 'Close' : 'Cancel' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #configureOIDCTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.OAUTH_OIDC.ADD_OIDC' | translate }}</div>
                  <div class="">
                    <div class="modal-body pt-4 px-5" style="max-height: 400px; overflow: scroll;">
          
                      <div class="fs-14 font-weight-600 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.OAUTH_OIDC.ADD_OIDC' | translate }}</div>
                      <form #oidcDataForm="ngForm" autocomplete="off">
                        <div class="form-row mt-2">
                          <div class="form-group ">
                            <label for="initiateLoginUrl" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}</label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="initiateLoginUrl" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}" [value]="oauthOIDCConfig.initiateLoginUrl" [disabled]="true">
                              <button #copyInitiateLoginUrl class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="oauthOIDCConfig.initiateLoginUrl" [select-text]="copyInitiateLoginUrl" (copied)="copied($event)">{{ 'MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group ">
                            <label for="redirectUrl" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}</label>
                            <div class="d-flex">
                              <input class="form-control" type="text" name="redirectUrl" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}" [value]="oauthOIDCConfig.redirectUrl" [disabled]="true">
                              <button #copyRedirectUrl class="v-btn btn btn-primary vsight-button vsight-secondary-btn ms-2" [copy-clipboard]="oauthOIDCConfig.redirectUrl" [select-text]="copyRedirectUrl" (copied)="copied($event)">{{ 'MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
                            </div>
                          </div>
                        </div>
                      </form>
          
                      <div class="fs-14 font-weight-600 text-dark-grey mt-3 mb-2">{{ 'MAIN.SETTINGS.OAUTH_OIDC.INFORMATION_VSIGHT' | translate }}</div>
                      <form #oidcForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.add(oidcForm)">
                        <div class="form-group">
                          <label for="discoveryEndpoint" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.DISCOVERY_ENDPOINT' | translate }}<span class="required-field">*</span></label>
                          <input #discoveryEndpoint="ngModel" class="form-control" type="text" name="discoveryEndpoint" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.DISCOVERY_ENDPOINT' | translate }}"
                              [ngClass]="{'is-invalid':discoveryEndpoint.errors && discoveryEndpoint.touched}" [(ngModel)]="dataModel.discoveryEndpoint" required>
                          <small class="invalid-feedback mx-2" [hidden]="!discoveryEndpoint.errors?.['required']">{{ 'MAIN.SETTINGS.OAUTH_OIDC.DISCOVERY_ENDPOINT_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="clientId" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.CLIENT_ID' | translate }}<span class="required-field">*</span></label>
                          <input #clientId="ngModel" class="form-control" type="text" name="clientId" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.CLIENT_ID' | translate }}"
                              [ngClass]="{'is-invalid':clientId.errors && clientId.touched}" [(ngModel)]="dataModel.clientId" required>
                          <small class="invalid-feedback mx-2" [hidden]="!clientId.errors?.['required']">{{ 'MAIN.SETTINGS.OAUTH_OIDC.CLIENT_ID_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="clientSecret" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.CLIENT_SECRET' | translate }}<span class="required-field">*</span></label>
                          <input #clientSecret="ngModel" class="form-control" type="text" name="clientSecret" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.CLIENT_SECRET' | translate }}"
                              [ngClass]="{'is-invalid':clientSecret.errors && clientSecret.touched}" [(ngModel)]="dataModel.clientSecret" required>
                          <small class="invalid-feedback mx-2" [hidden]="!clientSecret.errors?.['required']">{{ 'MAIN.SETTINGS.OAUTH_OIDC.CLIENT_SECRET_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="responseType" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.RESPONSE_TYPE' | translate }}</label>
                          <select #responseType [(ngModel)]="dataModel.responseType" class="form-control" name="responseType">
                            <option [ngValue]="'code'">{{ 'MAIN.SETTINGS.OAUTH_OIDC.RESPONSE_TYPE_CODE' | translate }}</option>
                          </select>
                        </div>
          
                        <div class="form-group">
                          <label for="logoutUrl" class="mb-2 fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.OAUTH_OIDC.LOGOUT_URL' | translate }}</label>
                          <input #logoutUrl="ngModel" class="form-control" type="text" name="logoutUrl" placeholder="{{ 'MAIN.SETTINGS.OAUTH_OIDC.LOGOUT_URL' | translate }}"
                              [ngClass]="{'is-invalid':logoutUrl.errors && logoutUrl.touched}" [(ngModel)]="dataModel.logoutUrl">
                        </div>
          
                      </form>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.OAUTH_OIDC.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.add(oidcForm)" [class.disabled]="!oidcForm.valid">
                        {{ 'MAIN.SETTINGS.OAUTH_OIDC.ADD' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #removeSamlTemplate let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.AZURE_AD.REMOVE_SAML_CONFIGURATION' | translate }}</div>
                  <div class="">
                    <div class="modal-body padded fs-14 text-dark-grey">
                      {{ 'MAIN.SETTINGS.AZURE_AD.REMOVE_SAML_CONFIGURATION_QUESTION' | translate }}
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.remove()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.REMOVE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #removeScimTemplate let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.AZURE_AD.ADD_SCIM_CONFIGURATION' | translate }}</div>
                  <div class="">
                    <div class="modal-body padded fs-14 text-dark-grey">
                      {{ 'MAIN.SETTINGS.AZURE_AD.REMOVE_SCIM_CONFIGURATION_QUESTION' | translate }}
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.remove()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.REMOVE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #removeOIDCTemplate let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.OAUTH_OIDC.REMOVE_OIDC_CONFIGURATION' | translate }}</div>
                  <div class="">
                    <div class="modal-body padded fs-14 text-dark-grey">
                      {{ 'MAIN.SETTINGS.OAUTH_OIDC.REMOVE_OIDC_CONFIGURATION_QUESTION' | translate }}
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.remove()">
                        {{ 'MAIN.SETTINGS.AZURE_AD.REMOVE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #alwaysArchiveTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ (dataModel.alwaysArchive ? 'MAIN.SETTINGS.ALWAYS_ARCHIVE.AUTO_RECORD' : 'MAIN.SETTINGS.ALWAYS_ARCHIVE.CHANGE_INDIVIDUALLY') | translate }}</div>
                  <div class="">
                    <div class="modal-body padded">
                      <p class="fs-14 text-dark-grey">{{ (dataModel.alwaysArchive ? 'MAIN.SETTINGS.ALWAYS_ARCHIVE.AUTO_MESSAGE' : 'MAIN.SETTINGS.ALWAYS_ARCHIVE.INDIVIDUAL_MESSAGE') | translate }}</p>
                      <p class="mt-2">{{ 'MAIN.SETTINGS.ALWAYS_ARCHIVE.WARNING' | translate }}</p>
                      <p *ngIf="dataModel.p2pCount > 0" class="fs-14 font-weight-600 text-dark-grey text-danger fs-13 mt-2">{{ 'MAIN.SETTINGS.ALWAYS_ARCHIVE.CAUTION' | translate }}</p>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.ALWAYS_ARCHIVE.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.change()">
                        {{ (dataModel.alwaysArchive ? 'MAIN.SETTINGS.ALWAYS_ARCHIVE.PROCEED' : 'MAIN.SETTINGS.ALWAYS_ARCHIVE.CHANGE') | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #alwaysFullHdTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ (dataModel.alwaysFullHd ? 'MAIN.SETTINGS.FULL_HD_MODAL.FULL_HD' : 'MAIN.SETTINGS.FULL_HD_MODAL.CHANGE_INDIVIDUALLY') | translate }}</div>
                  <div class="">
                    <div class="modal-body padded">
                      <p class="fs-14 text-dark-grey">{{ (dataModel.alwaysFullHd ? 'MAIN.SETTINGS.FULL_HD_MODAL.FULL_HD_MESSAGE' : 'MAIN.SETTINGS.FULL_HD_MODAL.INDIVIDUAL_MESSAGE') | translate }}</p>
                      <p class="mt-2 text-danger fs-13">{{ 'MAIN.SETTINGS.FULL_HD_MODAL.WARNING' | translate }}</p>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.FULL_HD_MODAL.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.change()">
                        {{ (dataModel.alwaysFullHd ? 'MAIN.SETTINGS.FULL_HD_MODAL.PROCEED' : 'MAIN.SETTINGS.FULL_HD_MODAL.CHANGE') | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #peerToPeerTemplate let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.WHAT_IS_P2P.TITLE' | translate }}</div>
                  <div class="">
                    <div class="modal-body">
                      <div class="pt-3 px-3 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.WHAT_IS_P2P.BETWEEN_CLIENTS' | translate }}</div>
                      <div class="pt-2 px-3 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.WHAT_IS_P2P.REDUCED_LATENCY' | translate }}</div>
                      <img alt="Peer to Peer" class="w-100" src="assets/img/peertopeer.png">
                      <div class="px-3 text-danger fs-13 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.WHAT_IS_P2P.ARCHIVE_UNAVAILABLE' | translate }}</div>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.close()">
                        {{ 'MAIN.SETTINGS.WHAT_IS_P2P.CLOSE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #addEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.ADD_EMAIL.TITLE' | translate }}</div>
                  <div class="">
                    <div class="modal-body padded">
                      <p class="fs-14 text-dark-grey">{{ (dataModel.forAdmin ? 'MAIN.SETTINGS.ADD_EMAIL.ADMIN' : 'MAIN.SETTINGS.ADD_EMAIL.ALL') | translate }}</p>
                      <div class="mt-2" *ngIf="dataModel.users">
                        <div class="my-2 text-danger fs-13">{{ 'MAIN.SETTINGS.ADD_EMAIL.USER_MAILS' | translate }}</div>
                        <a (click)="callbacks.profile(user.id)" class="fs-14 font-weight-600 text-dark-grey d-block mt-1" style="cursor: pointer" *ngFor="let user of dataModel.users">{{ user.name }}</a>
                      </div>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.close()">
                        {{ 'MAIN.SETTINGS.ADD_EMAIL.CLOSE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #twoFactorAdminTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div *ngIf="dataModel.action === 'enableadmin'" class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.ENABLE_ADMIN' | translate }}</div>
                  <div *ngIf="dataModel.action === 'disableall'" class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.DISABLE_ADMIN' | translate }}</div>
                  <div *ngIf="dataModel.action === 'enableothers'" class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.ENABLE_ALL' | translate }}</div>
                  <div *ngIf="dataModel.action === 'disableexceptadmin'" class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.DISABLE_ALL' | translate }}</div>
          
                  <div class="">
                    <div class="modal-body padded">
                      <div [ngClass]="{'d-none': dataModel.codeSended}">
                        <p class="mx-auto fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.VERIFICATION_SENDED' | translate }}</p>
                      </div>
                      <div [ngClass]="{'d-none': !dataModel.codeSended}">
                        <form #tfaForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.proceed()">
                            <div class="form-group mx-3">
                              <input #oneTimeCode="ngModel" class="form-control" type="text" name="oneTimeCode" placeholder="{{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.VERIFICATION_CODE' | translate }}" [(ngModel)]="dataModel.code"
                                  [ngClass]="{'is-invalid':oneTimeCode.errors && oneTimeCode.touched}" style="font-size: 14px;" required>
                            </div>
                        </form>
                      </div>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.close()">
                        {{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.CANCEL' | translate }}
                      </button>
                      <button type="button" *ngIf="!dataModel.codeSended" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.send()">
                        {{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.SEND_CODE' | translate }}
                      </button>
                      <button type="button" *ngIf="dataModel.codeSended" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="tfaForm.valid ? callbacks.proceed() : null" [class.disabled]="!tfaForm.valid">
                        {{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.PROCEED' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #enableSystemEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.CONFIGURE_CUSTOM_SERVICE_EMAIL' | translate }}</div>
                  <div class="">
                    <div class="modal-body pt-4 px-5">
                      <form #customEmailForm="ngForm" class="system-email-form" autocomplete="off" (ngSubmit)="callbacks.enable(customEmailForm)">
          
                        <div class="form-group">
                          <label for="senderName" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_NAME' | translate }}<span class="required-field">*</span></label>
                          <input #senderName="ngModel" class="form-control" type="text" name="senderName" placeholder="e.g., VSight no-reply"
                              [ngClass]="{'is-invalid':senderName.errors && senderName.touched}" [(ngModel)]="dataModel.name" required>
                          <small class="invalid-feedback mx-2" [hidden]="!senderName.errors?.['required']">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_NAME_IS_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="senderHost" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_HOST' | translate }}<span class="required-field">*</span></label>
                          <input #senderHost="ngModel" class="form-control" type="text" name="senderHost" placeholder="e.g., smtp.gmail.com"
                              [ngClass]="{'is-invalid':senderHost.errors && senderHost.touched}" [(ngModel)]="dataModel.host" required>
                          <small class="invalid-feedback mx-2" [hidden]="!senderHost.errors?.['required']">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_HOST_IS_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="senderPort" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_PORT' | translate }}<span class="required-field">*</span></label>
                          <select #senderPort [(ngModel)]="dataModel.port" class="form-control" name="senderPort">
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="465">465</option>
                            <option [ngValue]="587">587</option>
                            <option [ngValue]="2587">2587</option>
                          </select>
                        </div>
          
                        <div class="form-group">
                          <label for="senderEmail" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_EMAIL' | translate }}<span class="required-field">*</span></label>
                          <input #senderEmail="ngModel" class="form-control" type="text" name="senderEmail" placeholder="e.g., service@vsight.io" [(ngModel)]="dataModel.email"
                              [ngClass]="{'is-invalid': senderEmail.errors && senderEmail.touched }" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$" required>
                          <small class="invalid-feedback mx-2" [hidden]="!senderEmail.errors?.['required']">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_EMAIL_IS_REQUIRED' | translate }}</small>
                          <small class="invalid-feedback mx-2" [hidden]="!senderEmail.errors?.['pattern']">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.EMAIL_FORMAT_IS_INVALID' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="senderUser" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.USER' | translate }}<span class="required-field">*</span></label>
                          <input #senderUser="ngModel" class="form-control" type="text" name="senderUser" placeholder="e.g., service@vsight.io" [(ngModel)]="dataModel.user"
                              [ngClass]="{'is-invalid': senderUser.errors && senderUser.touched }" required>
                          <small class="invalid-feedback mx-2" [hidden]="!senderUser.errors?.['required']">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.USER_IS_REQUIRED' | translate }}</small>
                        </div>
          
                        <div class="form-group">
                          <label for="pass" class="fs-14 text-dark-grey mb-2">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.PASSWORD' | translate }}<span class="required-field">*</span></label>
                          <input #pass="ngModel" class="form-control" type="password" name="pass" placeholder="{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.PASSWORD' | translate }}"
                              [(ngModel)]="dataModel.pass" [ngClass]="{'is-invalid': pass.errors && pass.touched}" required autocomplete="new-password">
                          <small class="invalid-feedback mx-2" [hidden]="!pass.errors?.['required']">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.PASSWORD_IS_REQUIRED' | translate }}</small>
                        </div>
                      </form>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.TWO_FACTOR_MODAL.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.enable(customEmailForm)" [class.disabled]="!customEmailForm.valid">
                        {{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.VERIFY' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #verifySystemEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.VERIFY_CUSTOM_SERVICE_EMAIL' | translate }}</div>
          
                  <div class="">
                    <div class="modal-body padded">
                      <div class="mx-auto fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SEND_VERIFICATION_CODE' | translate }}</div>
                      <form #verifyEmailForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.verify(verifyEmailForm)">
                        <div class="form-group">
                          <input #verificationCode="ngModel" class="form-control" type="text" name="verificationCode" placeholder="{{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SEND_VERIFICATION_CODE' | translate }}"
                              [(ngModel)]="dataModel.code" [ngClass]="{'is-invalid':verificationCode.errors && verificationCode.touched}" required>
                        </div>
                      </form>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.close()">
                        {{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="verifyEmailForm.valid ? callbacks.verify(verifyEmailForm) : null" [class.disabled]="!verifyEmailForm.valid">
                        {{ 'MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.VERIFY' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #disableSystemEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.DISABLE_CUSTOM_SERVICE_EMAIL' | translate }}</div>
          
                  <div class="">
                    <div class="modal-body padded">
                      <div class="mx-auto fs-14 text-dark-grey">{{ 'MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.SYSTEM_EMAILS_SEND' | translate }}</div>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.disable()">
                        {{ 'MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.DISABLE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #newWebhookTemplate let-dataModel="dataModel" let-callbacks="callbacks">
            <div class="modal-dialog modal-dialog-wide" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.TITLE' | translate }}</div>
                  <div class="">
                    <div class="modal-body padded">
                      <form #newWebhookForm="ngForm" autocomplete="off">
                        <div class="form-group d-flex align-items-center w-100 pl-0 mb-2">
                          <div class="fs-14 font-weight-600 text-dark-grey mr-2" style="width: 150px;">{{ 'MAIN.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
                          <div class="fs-14 font-weight-600 text-dark-grey" style="flex-grow: 1;">{{ 'MAIN.SETTINGS.WEBHOOKS.URL' | translate }}</div>
                        </div>
                        <div class="form-group d-flex align-items-center w-100 pl-0 mb-0">
                          <select class="form-control mr-2" style="width: 150px; min-width: 150px;" [(ngModel)]="dataModel.webhook.event" name="webhookEvent">
                            <option [ngValue]="'SessionEnd'">SessionEnd</option>
                          </select>
                          <input class="form-control" [(ngModel)]="dataModel.webhook.url" style="flex-grow: 1;" type="text" name="webhookUrl" required
                            placeholder="{{ 'MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.PLACEHOLDER' | translate }}">
                        </div>
                      </form>
                      <div class="mt-4 fs-14 font-weight-600 text-dark-grey">{{ 'MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.WARNING' | translate }}</div>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-primary ms-2 font-weight-600" (click)="callbacks.add()" [class.disabled]="!newWebhookForm.valid">
                        {{ 'MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.ADD' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <ng-template #removeWebhookTemplate let-callbacks="callbacks">
            <div class="modal-dialog" role="document">
              <div class="modal-content v-card card modal-card">
                <div class="">
                  <div class="modal-header d-flex align-items-center modal-title text-white font-weight-600">{{ 'MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.TITLE' | translate }}</div>
                  <div class="">
                    <div class="modal-body padded fs-14 text-dark-grey">
                      <div>{{ 'MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.MESSAGE' | translate }}</div>
                    </div>
                    <div class="d-flex top-gap-30 justify-content-center mb-4">
                      <button type="button" class="v-btn vsight-button left btn vsight-button-outline-secondary btn-secondary me-2 font-weight-600" (click)="callbacks.cancel()">
                        {{ 'MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.CANCEL' | translate }}
                      </button>
                      <button type="button" class="v-btn btn vsight-button btn-danger ms-2 font-weight-600 vsight-danger" (click)="callbacks.remove()">
                        {{ 'MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.REMOVE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
    </div>
</div>