
<div class="d-flex video-container">
    <div class="workflow-background" *ngIf="aiLoading || splitVideoLoading || transcriptionCheck"></div>
    <div class="loading-process" *ngIf="aiLoading || splitVideoLoading || transcriptionCheck">
       <div>
        <ng-lottie [options]="optionsi" (animationCreated)="animationCreated($event)" style="width: 100%; height: 225px;"></ng-lottie>

        <div class="fs-14 font-weight-500 ai-text">

            <p *ngIf="aiLoading">AI processing is underway <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span></p>
            <p *ngIf="splitVideoLoading">Splitting video into segments <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span></p>
            <p *ngIf="transcriptionCheck">Transcribing video content <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span></p>
            <p *ngIf="aiLoading || splitVideoLoading || transcriptionCheck" class="fixed-text fs-13">
                This process may take up to 10 minutes. Please do not close this window.
            </p>

        </div>
       </div>
    </div>
    
    <div class="col-2" style="width: 240px;height: 100%;z-index: 500;background-color: white;">
        <div class="step-list">
            <app-step-list 
            [type]="type"
            [stepSelected]="stepSelectedByVideoTimeline"
            [(stepList)]="workflowSteps" 
            [connectionEnabled]="false"
            [initialStep]="initialStep"
            [isVideoFlow]= "true"
            (selectedStepChange)="selectedStepChange($event)"
            (stepListChange)="stepListChange($event)"
            [(workflowTranslations)]="workflowTranslations"
            (onStepRename)="onStepRename($event)"
            ></app-step-list>
        </div>
    </div>
    <div class="col-6 pt-4 steps-detail" cdkDropListGroup >
        <div #scrollContainer>
            <div class="d-flex flex-column" *ngFor="let chapter of videoChapters; let chapterIndex = index; trackBy: chapterTrackByFn">
                <div class="d-flex flex-column" style="padding-top: 0px;">
                    <div class="d-flex">
                        <p class="fs-15 font-weight-600" style="padding-left:20px;">{{ chapter.timestampStart }}</p>
                    <p class="fs-14 font-weight-700" style="padding-left:20px;">{{ chapter.title }} 
                        <span class="play-buttons cursor-pointer" (click)="playVideo(chapter)">
                            <img src="assets/img/play-circle.svg" alt="" class="ms-2 play-button">
                            <img src="assets/img/play-circle-hover.svg" alt="" class="ms-2 play-hover">
                        </span>
                    </p>

                    </div>
                    <div class="step-container" 
                    cdkDropList 
                    cdkDropListAxis="y"  
                    [cdkDropListData]="chapter" 
                    (cdkDropListDropped)="dropStep($event)">
                        <div class="step"  *ngFor="let step of chapter.steps; trackBy: trackByFn; let stepIndex = index">
                            <p class="step-description fs-14 font-weight-400" style="padding-left: 96px;
                            padding-right: 45px;">{{ step.text }}</p>
                            
                            <div class="p-relative divider-step" [attr.id]="step.uuid" cdkDrag [cdkDragData]="chapter" [ngClass]="{'has-divider': stepIndex !== chapter.steps.length-1 }" [ngStyle]="{'pointer-events': type == 'published' ? 'none' : ''}">
                                <ng-container *ngIf="stepIndex !== chapter.steps.length-1">
                                    <img src="assets/img/step-cut-icon.svg" alt="" height="20px" class="divider-icon-step-cut" (click)="divideSteps(chapterIndex, stepIndex)">
                                    <hr class="step-line selected-step-line-cut">
                                </ng-container>
                                <ng-container *ngIf="stepIndex == chapter.steps.length-1">
                                    <img src="assets/img/step-divider-icon.svg" alt="" height="20px" class="divider-icon-step">
                                    <hr class="step-line selected-step-line">
                                </ng-container>
                                
                            </div>
                        </div>

                    </div>
                    <!-- <div class="col-2 fs-14 font-weight-600">{{chapter.title}}</div>
                    <div *ngIf="chapter.text" class="col-10 fs-14">{{videoChapters}}</div> -->

                </div>
            <!-- <div class="p-relative divider" cdkDrag>
                <img src="assets/img/step-divider-icon.svg" alt="" height="20px" class="divider-icon">
                <hr class="step-line selected-step-line" >
            </div> -->
        </div>
        </div>
    </div>

    <div class="preview-part col-4" style="background-color: #e1e1e1;">
        <div class="d-flex flex-column">
            <div class="col-6 d-flex align-items-center justify-content-center w-100">
                <div class="mt-2" *ngIf="mainVideoUrl">
                    <!-- <video controls playsinline muted class="form-video" #myVideoElement>
                        <source [src]="videoUrl | safeHtml" type="video/mp4" />
                    </video> -->
                    <video [src]="mainVideoUrl | safeHtml" controls class="form-video" #myVideoElement>
                        <!-- <track kind="subtitles" [src]="captionUrl" srclang="en" label="English"> -->
                    </video>

                </div>
            </div>
        </div>
    </div>
    
</div>