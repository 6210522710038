<div class="h-100 d-flex flex-column justify-content-between align-items-center step-list" [ngClass]="{'section-available': workflowSections, 'video-flow-available':isVideoFlow}">
    <div class="steps-manage-wrapper">
        <div class="d-flex align-items-center justify-content-center fs-14 font-weight-600 manage-steps" (click)="selectModeChange()" *ngIf="stepList.length && !isVideoFlow" [ngStyle]="{'pointer-events': type=='published' ? 'none' : ''}">
            <span class="me-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.TITLE' | translate }}</span>
            <img src="assets/img/expand_less.svg" alt="" *ngIf="selectMode" class="less-icon">
            <img src="assets/img/expand_less_copy.svg" alt="" *ngIf="selectMode" class="less-icon-hover">

            <img src="assets/img/expand_more.svg" alt="" *ngIf="!selectMode" class="less-icon">
            <img src="assets/img/expand_more_copy.svg" alt="" *ngIf="!selectMode" class="less-icon-hover">

        </div>

        <div class="row w-100 ms-0" *ngIf="selectMode" style="padding-inline: 3px;">
            <div class="col-3 step-opt-card" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.DELETE' | translate)" tooltipPosition="bottom" [tooltipDelay]="0">
                <div class="option-single-card">
                    <button class="selected-step-options" (click) = "deleteSelectedSteps()"
                        [disabled]="!selectedStepList?.length || selectedStepList?.length == 0" [ngStyle]="{'pointer-events': (!selectedStepList?.length || selectedStepList?.length == 0) ? 'none' : ''}">
                        <div >
                            <img src="assets/img/delete-step.svg" alt="" class="normal-icon">
                            <img src="assets/img/delete-step-hover.svg" alt="" class="hover-icon">
                        </div>
                    </button>
                </div>
            </div>
            <div class="col-3 step-opt-card">
                <div class="option-single-card" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.DUPLICATE' | translate)" tooltipPosition="bottom" [tooltipDelay]="0">
                    <button class="selected-step-options" (click) = "duplicateSelectedSteps()"
                    [disabled]="!selectedStepList?.length || selectedStepList?.length == 0" [ngStyle]="{'pointer-events': (!selectedStepList?.length || selectedStepList?.length == 0) ? 'none' : ''}">
                        <div >
                            <img src="assets/img/duplicate-step.svg" alt="" class="normal-icon">
                            <img src="assets/img/duplicate-step-hover.svg" alt="" class="hover-icon">
                        </div>
                    </button>
                </div>
            </div>
            <div class="col-3 step-opt-card">
                <div class="option-single-card" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.CLONE' | translate)" tooltipPosition="bottom" [tooltipDelay]="0">
                    <button class="selected-step-options" (click) = "cloneSelectedSteps()"
                    [disabled]="!selectedStepList?.length || selectedStepList?.length == 0" [ngStyle]="{'pointer-events': (!selectedStepList?.length || selectedStepList?.length == 0) ? 'none' : ''}">
                        <div>
                            <img src="assets/img/clone-step.svg" alt="" class="normal-icon">
                            <img src="assets/img/clone-step-hover.svg" alt="" class="hover-icon">
                        </div>
                    </button>
                </div>
            </div>
            <div class="col-3 step-opt-card">
                <div class="option-single-card" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.MOVE' | translate)" tooltipPosition="bottom" [tooltipDelay]="0">
                    <button class="selected-step-options" (click) = "moveSelectedSteps()"
                    [disabled]="!selectedStepList?.length || selectedStepList?.length == 0" [ngStyle]="{'pointer-events': (!selectedStepList?.length || selectedStepList?.length == 0) ? 'none' : ''}">
                        <div>
                            <img src="assets/img/move-step.svg" alt="" class="normal-icon">
                            <img src="assets/img/move-step-hover.svg" alt="" class="hover-icon">
                        </div>
                    </button>
                </div>
            </div>
           
        </div>
    </div>
  
    <div class="steps-wrapper w-100" #stepsWrapper cdkDropList (cdkDropListDropped)="drop($event)" (scroll)="onScrollStepList()" >
        <div class="d-flex flex-column" style="padding: 0.75rem;" *ngIf="!stepList.length && !isVideoFlow">
            <div class="rectangle d-flex">
                <div class="info-p">
                    <img src="assets/img/info.svg">
                </div>
                <div class="inform-text">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.INFORM' | translate }}</div>
            </div>
        </div>
        
        <div class="step-select-options fs-14 font-weight-600" *ngIf="selectMode">
            <div class="font-color-blue" (click)="selectAll()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.SELECT-ALL' | translate }}</div>
            <div class="font-color-blue" (click)="unselectAll()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.MANAGE-STEPS.DESELECT-ALL' | translate }}</div>
        </div>
        <div class="d-flex flex column" *ngFor="let step of stepList;let index = index" style="position: relative;padding-bottom: 4px;" cdkDrag [cdkDragDisabled]="connectionEnabled || type=='published' || step.data.isSection">
            

            <div (click)="onSelectStep(step)" class="step-card" [class.selected]="step?.id === selectedStepId" [class.select-mode-active]="selectMode && step?.selectModeActive && !step.data.isSection" [class.d-none]="step['notShow'] || step?.data['parentWorkflow']">
                <div class="me-2 mb-1" [ngClass]="{'part-of-section':step.data.parentId}" [dynamicTooltip]="(connectionEnabled &&  !step.data.isSection) ? 'Disabled because of connection view' : null">
                    <img *ngIf="!step.data.isSection && !isVideoFlow" class="handle" src="assets/img/step-drag.svg"  alt="" cdkDragHandle>
                    <img *ngIf="step.data.isSection && !step['hide']" src="assets/img/down-icon.svg" style="transform:rotate(360deg)"   (click)="handleSectionSteps(step)" alt="">
                    <img *ngIf="step.data.isSection && step['hide']" src="assets/img/down-icon.svg"  style="transform: rotate(270deg)" (click)="handleSectionSteps(step)" alt="">
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="fs-14 font-weight-400 step-text" *ngIf="!step?.data?.['editNameMode'] && !workflowTranslations" [ngClass]="{'section':step.data.isSection}" title="{{step.data.name}}">{{step.data.name}}</div>
                    <div class="fs-14 font-weight-400 step-text" *ngIf="!step?.data?.['editNameMode'] && workflowTranslations" [ngClass]="{'section':step.data.isSection}" title="{{workflowTranslations[selectedLang][step.data.name]}}">{{workflowTranslations[selectedLang][step.data.name]}}</div>
                    <div *ngIf="step?.data?.['editNameMode']">
                        <input [inputSelectOnCreate]="true" type="text" class="title-input fs-14 font-weight-600" [formControl]="stepName" (keyup.enter)="updateName(step.data)" (blur)="updateName(step.data)">
                        <div *ngIf="stepName.errors?.['required']" class="validation-text mb-0 mt-0">
                            {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.NO-NAME' | translate }}
                        </div>
                    </div>
                    <div *ngIf="!step.valid" [dynamicTooltip]="'Invalid Step'">
                        <img src="assets/img/warning.svg" alt="" height="17px" width="17px">
                    </div>
                    <div class="dropdown-wrapper" dropdown container="body" *ngIf="type=='draft'">
                        <a id="stepListStepOptionsButton{{step.id}}" class="btn btn-default name-wrapper" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                            <img *ngIf="!step.data?.['editNameMode'] && type=='draft'" src="assets/img/more-grey.svg" alt="" height="26px">
                        </a>
                        <div #stepListStepOptions class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink" [ngClass]="{'is-video-flow': isVideoFlow}">
                            <div *ngIf="type=='draft'">
                                <a class="dropdown-item fs-14" (click)="addStepToActivity(step)" *ngIf="step.data.isSection">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.ADD-STEP' | translate }}</a>
                                <a class="dropdown-item fs-14" (click)="rename(step.data)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.RENAME' | translate }}</a>
                                <a class="dropdown-item fs-14" (click)="duplicateStep(step.data)" *ngIf="!step.data.isSection && step.data.layouts[0]?.items[0].type !== 'workflow' && !isVideoFlow">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.DUPLICATE' | translate }}</a>
                                <a class="dropdown-item fs-14" (click)="cloneStep(step.data,'clone')" *ngIf="!step.data.isSection && step.data.layouts[0]?.items[0].type !== 'workflow' && !isVideoFlow">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.CLONE' | translate }}</a>
                                <a class="dropdown-item fs-14" (click)="moveStep(step.data,step.id,'move')" *ngIf="!step.data.isSection && step.data.layouts[0]?.items[0].type !== 'workflow' && !isVideoFlow">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.MOVE' | translate }}</a>
                                <a class="dropdown-item fs-14 text-red" (click)="deleteStep(index,step)" *ngIf="!isVideoFlow">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.DELETE' | translate }}</a>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </div>
    <div class="text-center" style="position: absolute; bottom: 0; z-index: 500; background-color: white;width: 100%;" *ngIf="!isVideoFlow">
        <div class="create-button-group">
            <button class="v-btn btn btn-primary m-2 vsight-button create-button step-create" (click)="createStep(true)" [disabled]="type=='published'" >{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.CREATE' | translate | titlecase }}</button>
            <div>
                <a class="change-layout-link fs-14 font-weight-600 cursor-pointer hover-underline" (click)="createSection()" *ngIf="workflowSections" [ngClass]="{'disabled-link': type=='published'}">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.CREATE-SECTION' | translate }}</a>
            </div>
        </div>
    </div>
</div>
 